import * as token from './token'

import {
  getKeycloakToken,
  isKeycloakAuthenticated,
  updateKeycloakToken
} from 'system/keycloak'

import Alert from 'admin/util/Alert'
import isCrossDomain from 'url/isCrossDomain'
import logger from 'system/logger'
import { logoutQuietly } from 'system/logout'
import { resetInactivityTimeout } from 'api/inactivity'
import t from 'translate'

const onBeforeRequest = async (config) => {
  if (isCrossDomain(config.url)) {
    Alert.alert(
      t('Cross Domain Request'),
      t(
        'The application is configured to use a different domain from that in the browser address bar. Contact your administrator.'
      )
    )
  }

  let newAuthToken

  if (isKeycloakAuthenticated()) {
    try {
      await updateKeycloakToken()
      newAuthToken = `Bearer ${getKeycloakToken()}`
    } catch (err) {
      logger.warn('Your session has expired.')
      return logoutQuietly()
    }
  } else {
    newAuthToken = token.header()
  }

  config.headers = { ...{ authorization: newAuthToken }, ...config.headers }

  resetInactivityTimeout()

  return config
}

export default onBeforeRequest
