const Ext = require('ext')
const t = require('translate')
const LiveSearchCombo = require('admin/component/form/LiveSearchCombo')
const DateField = require('admin/component/form/DateField')
const ReassignToolClarification = require('admin/view/ReassignToolClarification')
const Alert = require('admin/util/Alert').default
const isSuccess = require('api/isSuccess').default

// The purpose of this is to associate two entities in the SMX application
// with each other. The intended workflow is to select what to move, where to move
// it, click "Reassign", and then accept the confirm dialog.
// Being able to only select one entity to move is intentional.

const VALID_MOVES = {
  USER: ['CUSTOMER', 'RESELLER', 'DISTRIBUTOR'],
  CUSTOMER: ['RESELLER', 'DISTRIBUTOR'],
  RESELLER: ['DISTRIBUTOR']
}

const TRANSLATIONS = {
  CUSTOMER: t('Customer'),
  RESELLER: t('Reseller'),
  DISTRIBUTOR: t('Distributor')
}

module.exports = Ext.define(null, {
  extend: Ext.FormPanel,

  title: t('Reassign'),
  buttonAlign: 'center',
  padding: 10,
  layoutConfig: {
    align: 'stretch'
  },

  initComponent() {
    this.items = [
      new Ext.form.Checkbox({
        fieldLabel: t('Include cancelled'),
        listeners: {
          check: (c, checked) => {
            return this.moveSearch.setIncludeCancelled(checked)
          }
        }
      }),

      (this.moveSearch = new LiveSearchCombo({
        allowBlank: false,
        forceSelection: true,
        fieldLabel: t('Reassign this ...'),
        listeners: {
          select: (c, record) => {
            this.moveResult = record
            this.validate()
          }
        }
      })),
      (this.toSearch = new LiveSearchCombo({
        allowBlank: false,
        forceSelection: true,
        fieldLabel: t('... to this'),
        listeners: {
          select: (c, record) => {
            this.toResult = record
            this.validate()
          }
        }
      })),
      this.billTo,

      (this.datePicker = new DateField({
        fieldLabel: t('Effective from'),
        labelSeparator: ' ',
        allowBlank: false,
        name: 'fromDate',
        format: Date.patterns.SHORT,
        value: new Date(),
        triggerConfig: {
          tag: 'div',
          cls: 'x-form-trigger x-form-date-trigger primary x-btn'
        }
      }))
    ]

    this.buttons = [
      new Ext.Button({
        text: t('Clear'),
        handler: () => this.clear()
      }),
      (this.reassignButton = new Ext.Button({
        text: t('Reassign'),
        disabled: true,
        handler: () => this.confirm()
      }))
    ]

    this.callParent()
  },

  validate() {
    let valid = false

    if (this.moveResult && this.toResult) {
      const moveType = this.moveResult.get('type')
      const toType = this.toResult.get('type')
      valid = moveType in VALID_MOVES && VALID_MOVES[moveType].indexOf(toType) >= 0
    }

    return this.reassignButton.setDisabled(!valid)
  },

  clear() {
    this.moveSearch.reset()
    this.toSearch.reset()

    this.moveResult = null
    this.toResult = null

    return this.validate()
  },

  getMoveDescription() {
    return this.moveResult.get('description')
  },

  getToDescription() {
    return this.toResult.get('description')
  },

  getToRole() {
    return TRANSLATIONS[this.toResult.get('type')]
  },

  confirm() {
    return Ext.Msg.confirm(
      t('Reassign?'),
      t('Reassign {moveDescription} to {toRoleName} {toDescription}?', {
        moveDescription: this.getMoveDescription(),
        toDescription: this.getToDescription(),
        toRoleName: this.getToRole()
      }),
      (text) => {
        if (text === 'yes') {
          return this.reassign()
        }
      }
    )
  },

  reassign() {
    return Ext.Ajax.request({
      method: 'PUT',
      url: `/api${this.toResult.get('url')}/${this.moveResult
        .get('type')
        .toLowerCase()}s`,
      params: {
        id: this.moveResult.get('resourceId'),
        fromDate: this.datePicker.getRawValue()
      },
      success: () => {
        Alert.info(
          t('Success'),
          t(
            'Assignment of {moveDescription} to {toRoleName} {toDescription} was successful.',
            {
              moveDescription: this.getMoveDescription(),
              toDescription: this.getToDescription(),
              toRoleName: this.getToRole()
            }
          )
        )
        return this.clear()
      },
      failure: (response) => {
        if (isSuccess(response)) {
          return
        }

        if (response.status === 409) {
          return new ReassignToolClarification({
            from: this.moveResult,
            fromDate: this.datePicker.getRawValue(),
            to: this.toResult,
            success: this.clear.bind(this)
          }).show()
        }
        return Alert.displayResponseError(response)
      }
    })
  }
})
