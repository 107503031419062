import { Form, Modal } from 'react-bootstrap'
import React, { Component } from 'react'
import { formDisabled, providerForm } from '../../subs'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Field from 'components/form/Field'
import Help from 'components/Help'
import Mask from 'components/Mask'
import PropTypes from 'prop-types'
import _s from 'underscore.string'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import t from 'translate'
import validators from 'validators'

const getUniqueArray = (arr) => [...new Set(arr)]
const isUnique = (arr) => getUniqueArray(arr).length === arr.length

const validateName = (value) => {
  const trimmedValue = value.trim()

  if (_s.isBlank(trimmedValue)) {
    return t("White spaces aren't not allowed")
  } else if (trimmedValue.length > 100) {
    return t('Max length is 100 characters')
  }
}

const validateAddresses = (value) => {
  if (!Array.isArray(value)) {
    return t('List is invalid')
  } else if (!isUnique(value)) {
    return t('IP addresses must be unique')
  } else if (!validators.isLinebrokenCidrOrIp(value, { allowEmpty: true })) {
    return t('IP addresses must be in valid CIDR format, e.g. 192.0.2.0/24')
  } else if (removeEmptyAddresses(value).length < 1) {
    return t('Empty addresses are not allowed')
  }
}

const renderAddressesFieldInfo = () => {
  return (
    <div>
      <p>
        <strong>{t('Multiple addresses')}</strong>
      </p>
      <p>{t('Enter each IP address or CIDR on a new line.')}</p>
      <p>{t('To remove an entry, just delete that line.')}</p>
      <p>{t('Example:')}</p>
      <pre>
        <code>
          {`10.0.0.64
192.168.0.0/16
172.16.0.0/8`}
        </code>
      </pre>
    </div>
  )
}

const renderEmptyMappingPolicyOption = () => {
  return <option value=''>{t('Select a mapping policy')}</option>
}

const formatAddresses = (value) => {
  return value ? value.join('\n') : ''
}

const parseAddresses = (value) => {
  return value.split(/\r\n|\r|\n/)
}

const removeEmptyAddresses = (value) => {
  return value.filter((x) => !!x.trim())
}

class ProviderDialog extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired,
    provider: PropTypes.object,
    showAddresses: PropTypes.bool,
    allowPrivate: PropTypes.bool,
    allowMappingPolicy: PropTypes.bool,
    submitButtonText: PropTypes.string
  }

  disableSubmit() {
    // for their meanings see http://redux-form.com/6.8.0/docs/api/Props.md/
    return this.props.pristine || this.props.invalid || this.props.disabled
  }

  values() {
    const registeredFields = this.props.providerForm.registeredFields
    const registeredValues = this.props.providerForm.values

    // reason for this is to set `false` for the private checkbox when it's
    // not in the map of values (weird redux-form behavior and our api
    // won't interpret missing fields as false)
    // todo remove that once this github issue is resolved:
    // https://github.com/erikras/redux-form/issues/3617

    const values = Object.keys(registeredFields).reduce((values, k) => {
      values[k] = k in registeredValues ? registeredValues[k] : false

      return values
    }, {})

    if (this.props.showAddresses) {
      values.addresses = removeEmptyAddresses(values.addresses)
    }

    return values
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.onSubmit(this.values())
  }

  missingMappingPolicySelection() {
    if (this.props.provider) {
      const mappingPolicy = this.props.provider['mapping-policy']
      // also, when it is a different value than these too, mark it as missing
      return mappingPolicy !== 'domain' && mappingPolicy !== 'hosting'
    }

    return true
  }

  renderAddressField() {
    return (
      <Field
        required
        name='addresses'
        store={this.props.store}
        disabled={this.props.disabled}
        label={t('Addresses')}
        componentClass='textarea'
        validate={validateAddresses}
        rows={10}
        help={t(
          'CIDR format can be used e.g. 192.0.2.0/24. Multiples must be on a new line.'
        )}
        info={renderAddressesFieldInfo()}
        format={formatAddresses}
        parse={parseAddresses}
        tabIndex={0}
      />
    )
  }

  renderAddressHelp() {
    return (
      <Help>
        {t(
          'You can add addresses for this provider after creating it. Select Configure Provider when finished'
        )}
      </Help>
    )
  }

  renderPrivateField() {
    return (
      <Field
        name='private'
        store={this.props.store}
        disabled={this.props.disabled}
        label={t('Private?')}
        type='checkbox'
        tabIndex={0}
      />
    )
  }

  renderMappingPolicyField() {
    return (
      <Field
        required
        name='mapping-policy'
        store={this.props.store}
        disabled={this.props.disabled}
        label={t('Mapping Policy')}
        componentClass='select'
        tabIndex={0}
      >
        {this.missingMappingPolicySelection() && renderEmptyMappingPolicyOption()}
        <option value='domain'>{t('Domain')}</option>
        <option value='hosting'>{t('Hosting')}</option>
      </Field>
    )
  }

  render() {
    return (
      <Mask>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Field
                required
                autoFocus
                name='name'
                autoComplete='off'
                // so that it can be tested with a custom store
                store={this.props.store}
                disabled={this.props.disabled}
                validate={validateName}
                label={t('Name')}
                type='text'
                tabIndex={0}
              />
              {this.props.showAddresses && this.renderAddressField()}
              {this.props.allowPrivate && this.renderPrivateField()}
              {this.props.allowMappingPolicy && this.renderMappingPolicyField()}
              {!this.props.showAddresses && this.renderAddressHelp()}
            </Modal.Body>

            <Modal.Footer>
              <CancelSubmitButtons
                disableCancel={this.props.disabled}
                disableSubmit={this.disableSubmit()}
                onCancel={this.props.onCancel}
                submitText={this.props.submitButtonText}
              />
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, props) => ({
  providerForm: providerForm(db),
  disabled: formDisabled(db, props.companyKey),
  initialValues: props.provider
})

const ProviderForm = reduxForm({ form: 'providerForm' })(ProviderDialog)

export default connect(mapDbToProps)(ProviderForm)
