// a company group is an abstract entity for
// vendor, distributor, reseller and customer altogether.
//
// in the backend it's called party-group in their schema.
//
// they do share some common api stuff and have some common
// attributes that can be read and/or written.
//
// that said, this is basically like an abstract class and
// should not be instantiated directly.

const t = require('translate')
const Ext = require('ext')
const Record = require('admin/core/Record')
const User = require('admin/core/User')
const PRODUCT_NAME = require('product/names').default
const CompanyFields = require('admin/data/company/fields/company').default

const AgreementStore = require('admin/data/AgreementStore')
const InvoiceStore = require('admin/data/InvoiceStore')
const InvoiceRunStore = require('admin/data/InvoiceRunStore')
const ResellerAgreementStore = require('admin/data/ResellerAgreementStore')
const CatalogueStore = require('admin/data/CatalogueStore')

const CompanyRecord = Record.define(null, {
  statics: {
    // make it static so that other records can access this
    defaultFields: CompanyFields
  },

  fields: CompanyFields,

  isExpired() {
    return this.get('expired')
  },

  getName() {
    return this.get('displayName')
  },

  getTitle() {
    const title = this.getName()

    if (this.isExpired()) {
      return `${title} [${t('Cancelled')}]`
    }
    return title
  },

  getCountry() {
    return this.get('primaryPostalAddress.countryGeo') || User.getDefaultCountry()
  },

  getParams(options = {}) {
    let params = {}

    if (options.details) {
      params = Ext.apply(params, {
        // todo hope we can change that field name to displayName in backend
        // one day, this to align with all other billable resources
        groupName: this.getName(),
        externalId: this.get('externalId'),

        'primaryPostalAddress.attnName': this.get('primaryPostalAddress.attnName'),
        'primaryPostalAddress.address1': this.get('primaryPostalAddress.address1'),
        'primaryPostalAddress.address2': this.get('primaryPostalAddress.address2'),
        'primaryPostalAddress.city': this.get('primaryPostalAddress.city'),
        'primaryPostalAddress.postalCode': this.get('primaryPostalAddress.postalCode'),
        'primaryPostalAddress.countryGeo': this.getCountry()
      })
    }

    return params
  },

  getAgreementStore() {
    if (!this.agreementStore) {
      this.agreementStore = new AgreementStore({ parentRecord: this })
    }
    return this.agreementStore
  },

  getCustomerStore() {
    if (!this.customerStore) {
      this.customerStore = new (require('admin/data/company/CustomerStore'))({
        parentRecord: this
      })
    }
    return this.customerStore
  },

  getInvoiceStore() {
    if (!this.invoiceStore) {
      this.invoiceStore = new InvoiceStore({ parentRecord: this })
    }
    return this.invoiceStore
  },

  getInvoiceRunStore() {
    if (!this.invoiceRunStore) {
      this.invoiceRunStore = new InvoiceRunStore({ parentRecord: this })
    }
    return this.invoiceRunStore
  },

  getNoteStore() {
    if (!this.noteStore) {
      this.noteStore = new (require('admin/data/NoteStore'))({ parentRecord: this })
    }
    return this.noteStore
  },

  getResellerAgreementStore() {
    if (!this.resellerAgreementStore) {
      this.resellerAgreementStore = new ResellerAgreementStore({ parentRecord: this })
    }
    return this.resellerAgreementStore
  },

  getResellerStore() {
    if (!this.resellerStore) {
      this.resellerStore = new (require('admin/data/company/ResellerStore'))({
        parentRecord: this
      })
    }
    return this.resellerStore
  },

  getUserStore() {
    if (!this.userStore) {
      this.userStore = new (require('admin/data/UserStore'))({ parentRecord: this })
    }
    return this.userStore
  },

  getReportStore() {
    if (!this.reportStore) {
      this.reportStore = new (require('reporting/ReportStore'))({
        parentRecord: this
      })
    }
    return this.reportStore
  },

  getListManagementStore() {
    if (!this.listManagementStore) {
      this.listManagementStore = new (require('smartrules/ListManagementStore'))({
        parentRecord: this
      })
    }
    return this.listManagementStore
  },

  getSystemListManagementStore() {
    if (!this.systemListManagementStore) {
      this.systemListManagementStore = new (require('smartrules/ListManagementStore'))({
        url: this.getUrl() + '/systemdatasets',
        parentRecord: this
      })
    }
    return this.systemListManagementStore
  },

  getCatalogueStore() {
    if (!this.catalogueStore) {
      this.catalogueStore = new CatalogueStore({ parentRecord: this })
    }
    return this.catalogueStore
  },

  getTemplateStore() {
    if (!this.templateStore) {
      this.templateStore = new (require('smartrules/TemplateStore'))({
        parentRecord: this
      })
    }
    return this.templateStore
  },

  // Helper to find product in customer or vendor records
  // Looks at productId AND productCategory because they are not likely to clash.
  findProduct(name) {
    const products = this.get('products')

    if (!products) return null

    const productValues = Object.values(products)

    const product =
      productValues.find((product) => product.productCode === name) ||
      productValues.find((product) => product.productCategory === name)

    if (product && (product.current || User.highestUserRole() !== 'CUSTOMER_USER')) {
      return product
    }

    return null
  },

  // todo add more of those helper fns over time
  hasInboundScrubbing() {
    return this.findProduct(PRODUCT_NAME.INBOUND_SCRUB)
  },

  hasOutboundScrubbing() {
    return this.findProduct(PRODUCT_NAME.OUTBOUND_SCRUB)
  },

  hasSharedServices() {
    return this.findProduct(PRODUCT_NAME.SHARED_SERVICES)
  },

  hasArchiving() {
    return this.findProduct(PRODUCT_NAME.ARCHIVING)
  },

  getHostingProduct() {
    const products = this.get('products')
    if (!products) return
    const productValues = Object.values(products)
    return productValues.find((product) => /^MAIL_HOST/.test(product.productCode))
  },

  hasHosting() {
    return !!this.getHostingProduct()
  }
})

module.exports = CompanyRecord
