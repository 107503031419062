const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ProxyStore = require('admin/data/ProxyStore')
const ComboBox = require('admin/component/form/ComboBox')
const HostingPlatformStore = require('admin/data/HostingPlatformStore')

const HostingPlatformResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.HostingPlatform',
  name: t('Hosting Platform'),
  resourceName: 'hostingplatforms',

  init() {
    return this.getStore().load()
  },

  getComboBox(config) {
    const store = new ProxyStore(this.getStore())
    const combo = new ComboBox(
      Ext.applyIf(config, {
        fieldLabel: t('Platform'),
        editable: false,
        allowBlank: false,
        triggerAction: 'all',
        store,
        valueField: 'relayHost',
        displayField: 'description',
        autoSelect: true,
        typeAhead: true,
        lazyInit: false,
        forceSelection: true,
        mode: 'local'
      })
    )

    const autoSelect = function () {
      // do not auto select when there is more than one
      // we don't want to have migrate mailboxes if people click through
      if (store.totalLength === 1) {
        let value = combo.getValue()

        // do autoselection only when none is selected
        // (probably won't happen when there is only one)
        if (value === '') {
          value = store.data.get(0).data.relayHost
        }

        return combo.setValue(value)
      }
    }

    // when the store already has data, attempt to automatically select
    if (store.totalLength > 0) {
      autoSelect()
      // otherwise do that once the store has loaded the data from the backend
    } else {
      store.on('load', autoSelect)
    }

    combo.on(
      'beforedestroy',
      function () {
        store.un('load', autoSelect, this)
        return store.destroy()
      },
      combo
    )

    return combo
  },

  getStore() {
    if (!this.store) {
      this.store = new HostingPlatformStore()
      this.store.load()
    }
    return this.store
  }
})

module.exports = HostingPlatformResource
