const validators = require('validators').default
const Ext = require('ext')
const t = require('translate')
const ItemGridPanel = require('admin/component/grid/ItemGridPanel').default
const ProductDirection = require('product/direction')
const escape = require('html-escaper').escape

const PolicySetsGridPanel = Ext.define(null, {
  extend: ItemGridPanel,

  constructor(cfg = {}) {
    this.isEmailHosting = cfg.product.productCategory === 'EMAIL_HOSTING_PRODUCTS'

    cfg = Ext.applyIf(cfg, {
      addLabel: t('New Set'),
      addTooltip: t('Add a new policy set'),
      columns: [
        {
          dataIndex: 'name',
          editor: {
            allowBlank: false,
            validator: this.validateName.createDelegate(this),
            xtype: 'textfield'
          },
          header: t('Policy Set'),
          id: 'name',
          menuDisabled: true,
          noHtmlEncode: true,
          resizable: false,
          renderer(v, meta, record) {
            let context
            v = escape(v)
            const isInbound = ProductDirection.isInbound(record.get('policySetType'))

            if (record.get('global')) {
              context = isInbound ? t('All Domains') : t('All Mail Servers')
            } else {
              const count = record.get('contactMechCount') || 0
              const things = isInbound ? t('Domains') : t('Mail Servers')
              context = `${count} ${things}`
            }
            return `${v}<br/>${context}`
          },
          sortable: false,
          width: 100
        }
      ],
      deleteLabel: t('Delete'),
      deleteTooltip: t('Delete the currently selected policy set')
    })

    if (this.isEmailHosting) {
      cfg.view = new Ext.grid.GroupingView({
        forceFit: true,
        hideGroupedColumn: true,
        enableGroupingMenu: false,
        showGroupName: false,
        startCollapsed: false,
        groupTextTpl: '{[values.rs[0].get("policySetType")]}'
      })

      cfg.addButton = {
        text: t('New Set'),
        tooltip: t('Add a new policy set'),
        menu: {
          items: [
            {
              disabled: !cfg.module.canCreate(),
              handler: () => this.addItem(ProductDirection.DIRECTION_INBOUND),
              iconCls: 'add-inbound-policy-set',
              scope: this,
              text: t('Inbound Policy Set'),
              tooltip: t('Add a new inbound policy set')
            },
            {
              disabled: !cfg.module.canCreate(),
              handler: () => this.addItem(ProductDirection.DIRECTION_OUTBOUND),
              iconCls: 'add-outbound-policy-set',
              scope: this,
              text: t('Outbound Policy Set'),
              tooltip: t('Add a new outbound policy set')
            }
          ]
        }
      }

      cfg.columns.unshift({
        id: 'policySetType',
        header: t('Policy Set Type'),
        width: 160,
        sortable: true,
        resizable: false,
        dataIndex: 'policySetType'
      })
    }

    this.callParent([cfg])
  },

  filter(record) {
    return (
      record.get('policySetType') !== 'RELAY' &&
      record.get('policySetType') !== 'SIGNATURE'
    )
  },

  isNameDuplicate(value) {
    const trimmedValue = value.trim()
    const selectedPolicySet = this.getSelectionModel().getSelected()
    const direction = selectedPolicySet.get('policySetType')
    // since SCL-2569, only detect duplicates of the same type (= direction)
    // hence filter first those of the same direction
    return this.getStore()
      .getRange()
      .filter((record) => record.get('policySetType') === direction)
      .some(function (record) {
        return (
          record.get('name') === trimmedValue &&
          !this.getSelectionModel().isSelected(record)
        )
      }, this)
  },

  validateName(value) {
    if (!validators.isSetName(value)) {
      return t(
        'Policy set names must be at least 2 characters long, and cannot start or end with whitespace'
      )
    } else if (value.length > 50) {
      return t('Policy set names must be at most 50 characters long')
    } else if (this.isNameDuplicate(value)) {
      return t('Policy set names must be unique')
    }

    return true
  },

  addItem(policySetType) {
    this.editor.stopEditing(false)

    const PolicySet = this.store.record

    policySetType =
      policySetType || ProductDirection.getDirection(this.product.productCode)

    const record = new PolicySet({
      id: -1,
      name: '',
      global: true,
      policySetType: policySetType.toUpperCase()
    })

    this.store.add(record)

    const index = this.store.indexOf(record)
    this.getSelectionModel().selectRow(index)
    this.editor.startEditing(index, this.isEmailHosting ? 1 : 0)
  },

  deleteItem() {
    if (this.getSelectionModel().hasSelection()) {
      const record = this.getSelectionModel().getSelected()
      const title = t('Delete Policy Set')
      const body = t('Are you sure you want to delete policy set {name}?', {
        name: escape(this.getSelectionModel().getSelected().get('name'))
      })
      return Ext.MessageBox.confirm(title, body, function (result) {
        if (result === 'yes') {
          Ext.MessageBox.wait(t('Saving...'))
          return record.destroy({
            complete() {
              return Ext.MessageBox.hide()
            }
          })
        }
      })
    }
  }
})

module.exports = PolicySetsGridPanel
