const Ext = require('ext')
const t = require('translate')
const ExplorerLoader = require('admin/component/tree/ExplorerLoader')
const User = require('admin/core/User')
const DistributorResource = require('admin/business/DistributorResource')
const ResellerResource = require('admin/business/ResellerResource')
const CustomerResource = require('admin/business/CustomerResource')
const UserResource = require('admin/business/UserResource')
const NoteResource = require('admin/business/NoteResource')
const InvoiceRunResource = require('admin/business/InvoiceRunResource')
const Report = require('reporting/Report')
const Subscription = require('reporting/Subscription')
const browser = require('system/browser')
const store = require('store').default

const {
  isCapableOfInvoicing,
  isCapableOfReporting,
  isCapableOfSubscriptions
} = require('system/capability/subs')

const Explorer = Ext.define(null, {
  singleton: true,

  id: 'SMX.desktop.Explorer',
  resources: {},
  rootResource: null,

  constructor() {
    this.callParent()

    this.panel = new Ext.tree.TreePanel({
      animate: false,
      autoScroll: true,
      flex: 1,
      lines: false,
      loader: new ExplorerLoader({ explorer: this }),
      loadMask: true,
      rootVisible: false,
      cls: 'explorer-panel'
    })

    const openResource = function (node) {
      let record
      if ((record = node.attributes.record)) {
        node.parentNode.attributes.resource.open(record)
        return false
      }
    }

    if (browser.isMobile) {
      this.panel.on('click', openResource, this)
    }

    this.panel.on('dblclick', openResource, this)
    this.panel.on('beforeclick', (node, event) => !event.hasModifier())

    // Set explorer root
    if (User.needExplorer()) {
      this.setRoot(User.getRootRecord())
    }

    // Load resources to be shown in explorer.
    const explorerResources = [
      DistributorResource,
      ResellerResource,
      CustomerResource,
      UserResource,
      NoteResource
    ]

    const appState = store.getState()

    if (isCapableOfInvoicing(appState)) {
      explorerResources.push(InvoiceRunResource)
    }

    if (isCapableOfReporting(appState)) {
      explorerResources.push(Report)
    }

    if (isCapableOfSubscriptions(appState)) {
      explorerResources.push(Subscription)
    }

    explorerResources.map(this.addResource.bind(this))

    // Expand the first child if it isn't distributor.
    const rootNode = this.panel.getRootNode()
    const firstChild = rootNode?.firstChild

    if (firstChild && firstChild.attributes.resource.id !== 'SMX.Distributor') {
      firstChild.expand()
    }
  },

  destroyAsyncTree(node) {
    while (node.firstChild) {
      this.destroyAsyncTree(node.firstChild)
    }

    node.store?.destroy()

    node.destroy()
  },

  setRoot(record) {
    const resource = record.module
    const rootNode = this.panel.getRootNode()
    this.rootResource = resource
    this.resources[resource.id] = { resource, children: [] }

    if (rootNode) {
      this.destroyAsyncTree(rootNode)
    }

    this.panel.setRootNode(
      new Ext.tree.AsyncTreeNode({
        id: record.get('id'),
        loaded: true,
        text: record.getName(),
        record
      })
    )

    this.panel
      .getRootNode()
      .getOwnerTree()
      .on('beforeremove', (tree, parent, remove) =>
        remove.fireEvent('beforethisremove', tree, parent, remove)
      )
  },

  addResource(resource) {
    const folderMenu =
      this.resources[resource.id] && this.resources[resource.id].folderMenu

    folderMenu?.destroy()

    this.resources[resource.id] = {
      resource,
      children: []
    }

    return (() => {
      const result = []
      for (const p of Array.from(resource.getParentResources())) {
        // Add to root node
        if (this.rootResource && p.id === this.rootResource.id && resource.explorable) {
          this.panel.getRootNode().appendChild(
            new Ext.tree.AsyncTreeNode({
              text: resource.translations.plural,
              resource,
              resourceId: resource.id,
              cls: 'explorer-node-' + resource.resourceName,
              listeners: {
                contextmenu: {
                  fn: this.handleContextMenu,
                  scope: this
                }
              }
            })
          )
        }
        // Add to parent's children array
        result.push(this.resources[p.id]?.children.push(resource.id))
      }
      return result
    })()
  },

  handleContextMenu(node, event) {
    // Resource menu (folder of distributors/resellers etc).
    let menu, resource
    if (node.attributes.resource) {
      resource = node.attributes.resource
      const parentRecord = node.parentNode.attributes.record
      const resourceStore = parentRecord.getResourceStore(resource.getCode())

      menu = new Ext.menu.Menu({
        explorer: this,
        id: resource.id + '.listmenu'
      })

      if (resource.canCreate()) {
        menu.add({
          text: resource.translations.new,
          iconCls: resource.getResourceCls('new'),
          handler() {
            resource.create(parentRecord, { store: resourceStore })
          }
        })
      }

      if (resource.canList()) {
        menu.add({
          text: resource.translations.list,
          iconCls: resource.getResourceCls('list'),
          handler() {
            const title = resource.translations.plural + ': ' + parentRecord.getName()
            resource.list(parentRecord, {
              title,
              taskbar: title
            })
          }
        })
      }

      menu.add({
        text: t('Refresh'),
        iconCls: 'next-refresh',
        handler() {
          resourceStore.reload()
        }
      })

      // Record menu
    } else {
      resource = node.parentNode.attributes.resource
      const { record } = node.attributes

      menu = new Ext.menu.Menu({
        explorer: this,
        id: resource.id + '.itemmenu'
      })

      if (resource.canOpen()) {
        menu.add({
          text: resource.translations.open,
          iconCls: resource.getResourceCls('open'),
          disabled: !record.canOpen(),
          handler() {
            resource.open(record)
          }
        })
      }

      if (resource.canRemove({ context: 'explorer' })) {
        menu.add({
          text: resource.translations.remove,
          iconCls: resource.getResourceCls('remove'),
          disabled: !record.canRemove(),
          handler() {
            resource.remove(record)
          }
        })
      }

      this.resources[resource.id].children.forEach((child) => {
        const childResource = this.resources[child].resource

        if (
          childResource.canCreate() &&
          // Temporary hack to stop "create subscription" item appearing in user menus
          !(resource === UserResource && childResource === Subscription)
        ) {
          const store = record.getResourceStore(childResource.getCode())

          menu.add({
            text: childResource.translations.new,
            iconCls: childResource.getResourceCls('new'),
            handler() {
              return childResource.create(record, { store })
            }
          })
        }

        if (
          childResource.canList() &&
          // Temporary hack to stop "list subscription" item appearing in report menus
          !(resource === Report && childResource === Subscription)
        ) {
          return menu.add({
            text: childResource.translations.list,
            iconCls: childResource.getResourceCls('list'),
            handler() {
              const title = childResource.translations.plural + ': ' + record.getName()
              childResource.list(record, {
                title,
                taskbar: title
              })
            }
          })
        }
      })
    }

    event.stopEvent()
    node.select()

    if (menu.items.getCount()) {
      menu.render()
      const xy = event.getXY()
      const h = menu.el.getHeight()
      // Show context menu above click unless it's too high in doc.
      if (xy[1] > h) {
        xy[1] -= h
      }
      menu.showAt(xy)
      menu.on('itemclick', menu.destroy)
      menu.on('hide', menu.destroy)
    }
  }
})

module.exports = Explorer
