const t = require('translate')
const Ext = require('ext')
const ProductDirection = require('product/direction')
const MailStatsChart = require('mail/chart/Stats').default
const MailStatsConfigPanel = require('admin/view/MailStatsConfigPanel')
const Sentry = require('system/sentry').default
const _ = require('lodash')

const configWithoutTime = function (config) {
  const configReduced = _.omit(config, 'timeZone', 'dataField')
  return configReduced
}

const MailStatsPanel = Ext.define(null, {
  extend: Ext.Panel,

  title: t('Mail Statistics'),
  maskMsg: Ext.LoadMask.prototype.msg,
  maskMsgCls: 'x-mask-loading',
  layout: 'border',

  constructor(cfg = {}) {
    this.product = cfg.product
    this.direction = ProductDirection.getDirection(cfg.product.productCode)

    // when no direction is set, set a default one and offer a switch
    if (!this.direction) {
      this.direction = ProductDirection.DIRECTION_INBOUND

      // Use a unique name so radios don't interact with other customers.
      const radioGroupName = 'mail-stats-direction-' + cfg.product.url

      const updateDirection = () => {
        this.configPanel.direction = this.direction
        return this.updateGraph()
      }

      this.tbar = new Ext.Toolbar({
        cls: 'direction-toolbar',
        style: {
          marginBottom: '20px'
        },
        items: [
          new Ext.form.Label({
            text: t('Direction')
          }),
          new Ext.form.Radio({
            boxLabel: t('Inbound'),
            name: radioGroupName,
            inputValue: ProductDirection.DIRECTION_INBOUND,
            checked: ProductDirection.isInbound(this.direction),
            handler: (checkbox, checked) => {
              if (checked) {
                this.direction = checkbox.inputValue
                updateDirection()
              }
            }
          }),
          new Ext.form.Radio({
            boxLabel: t('Outbound'),
            name: radioGroupName,
            inputValue: ProductDirection.DIRECTION_OUTBOUND,
            checked: ProductDirection.isOutbound(this.direction),
            handler: (checkbox, checked) => {
              if (checked) {
                this.direction = checkbox.inputValue
                updateDirection()
              }
            }
          })
        ]
      })
    }

    this.items = [
      (this.graph = new MailStatsChart()),
      (this.configPanel = new MailStatsConfigPanel({
        direction: this.direction,
        product: this.product,
        region: 'south',
        listeners: {
          change: this.handleConfigChange,
          scope: this
        }
      }))
    ]

    this.callParent()
  },

  afterRender() {
    this.callParent(arguments)
    this.updateGraph()
  },

  handleConfigChange() {
    this.updateGraph()
  },

  updateGraph() {
    if (this.isDestroyed) return

    const prevConfig = this.config || {}
    const config = this.configPanel.getConfig()

    if (this.failureEl) {
      this.failureEl.remove()
      this.failureEl = null
    }

    // If data and config that affects data hasn't changed...
    if (
      this.data &&
      JSON.stringify(configWithoutTime(prevConfig)) ===
        JSON.stringify(configWithoutTime(config))
    ) {
      // ... we can redraw with the old data.
      return this.plotGraph(this.data, config)
    }

    this.mask()

    return this.getData(config, (data) => {
      if (this.isDestroyed) return

      // Only update if config still matches
      if (!_.isEqual(config, this.configPanel.getConfig())) {
        return
      }

      this.data = data
      this.plotGraph(data, config)
      this.unmask()
    })
  },

  // First part is to create the search resource.
  getData(config, callback) {
    let params = {
      fromDate: config.fromDate,
      thruDate: config.thruDate,
      granularity: this.getPeriod(config),
      type: config.type,
      domain: config.domain,
      direction: this.direction
    }

    // Exclude falsy values, server can't handle empty params.
    params = Object.keys(params).reduce((memo, key) => {
      if (params[key]) {
        memo[key] = params[key]
      }

      return memo
    }, {})

    return Ext.Ajax.request({
      method: 'POST',
      url: this.product.resources.EVENTS_SUMMARY_SEARCH.url.replace(
        /\/summary\//,
        '/collated/'
      ),
      params,
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this),
      success: (response) => {
        const url = response.getResponseHeader('Location').trim()
        return this.actuallyGetData(url, callback)
      },
      failure: () => {
        this.unmask()
        return this.showFailure(() => {
          this.mask()
          return this.getData(config, callback)
        })
      }
    })
  },

  // Second part is to get data from the created resource.
  actuallyGetData(url, callback) {
    return Ext.Ajax.request({
      method: 'GET',
      url,
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this),
      success: (response) => {
        try {
          const json = JSON.parse(response.responseText)
          const data = json.events || json.results.events
          callback(data[this.direction.toUpperCase()])
        } catch (exc) {
          Sentry.addBreadcrumb({
            category: 'response',
            message: response.responseText
          })
          Sentry.captureException(exc)
        }
      }, // todo: improve within SCL-970
      failure: () => {
        this.unmask()
        this.showFailure(() => {
          this.mask()
          return this.actuallyGetData(url, callback)
        })
      }
    })
  },

  showFailure(callback) {
    if (this.isDestroyed) {
      return
    }

    const failureDom = Ext.DomHelper.insertHtml(
      'beforeEnd',
      this.el.dom,
      `<div class="graph-fail"> \
${t('Mail reporting is currently unavailable. Please try again later.')} \
<a href="#">${t('Refresh')}</a> \
</div>`
    )
    this.failureEl = this.el.appendChild(failureDom)
    this.failureEl.center(this.el)

    this.failureEl.on(
      'click',
      function (e) {
        const target = e.getTarget('a')
        if (target) {
          this.failureEl.remove()
          this.failureEl = null
          return typeof callback === 'function' ? callback() : undefined
        }
      },
      this
    )
  },

  // Period is calculated from fromDate and thruDate.
  getPeriod(config) {
    const interval = 8

    if (config.fromDate) {
      const from = new Date(config.fromDate)
      const thru = new Date(config.thruDate)

      if (from.add(Date.MONTH, interval) < thru) {
        return 'monthly'
      } else if (from.add(Date.DAY, interval) < thru) {
        return 'daily'
      }
      return 'hourly'
    }
    return 'monthly'
  },

  plotGraph(data, config) {
    // Save config so it can be compared in updateGraph
    this.config = config
    this.graph.plotMailStats(data, {
      direction: this.direction,
      unit: config.dataField,
      period: this.getPeriod(config),
      timeZone: config.timeZone
    })
  }
})

module.exports = MailStatsPanel
