import {
  SHAREDSERVICES_PROVIDER_SELECT,
  SHAREDSERVICES_PROVIDER_SET,
  SHAREDSERVICES_PROVIDER_SET_LIST,
  SHAREDSERVICES_PROVIDER_SET_URI,
  SHAREDSERVICES_PROVIDER_START_CONFIGURING,
  SHAREDSERVICES_PROVIDER_START_CONFIRMING_CONFIGURATION,
  SHAREDSERVICES_PROVIDER_START_CONFIRMING_DELETION,
  SHAREDSERVICES_PROVIDER_START_CREATING,
  SHAREDSERVICES_PROVIDER_START_LOADING,
  SHAREDSERVICES_PROVIDER_STOP_CONFIGURING,
  SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_CONFIGURATION,
  SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_DELETION,
  SHAREDSERVICES_PROVIDER_STOP_CREATING,
  SHAREDSERVICES_PROVIDER_STOP_LOADING
} from './actions'

import { createNextState } from '@reduxjs/toolkit'
import deepmerge from 'deepmerge'
import getIn from 'lodash/get'
import initialState from './db'
import setIn from 'lodash/set'

const findIndex = (providers, id) => {
  if (!providers) return
  return providers.findIndex((provider) => provider && provider.id === id)
}

const getBaseKeyPath = (companyKey) => ['companies', companyKey]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getListKeyPath = (companyKey) => getKeyPath(companyKey, 'list')
const getList = (providerDb, companyKey) => getIn(providerDb, getListKeyPath(companyKey))

const reducers = {
  [SHAREDSERVICES_PROVIDER_SELECT]: (providerDb, action) => {
    // because only one can be selected, we are safe just to pop()
    const row = action.providers.pop()
    const providers = getIn(providerDb, getListKeyPath(action.companyKey))

    return setIn(
      providerDb,
      getListKeyPath(action.companyKey),
      providers.map((provider) => {
        if (provider.id === row.id) {
          return Object.assign(provider, { selected: action.selected })
        }

        return Object.assign(provider, { selected: false })
      })
    )
  },

  [SHAREDSERVICES_PROVIDER_SET]: (providerDb, action) => {
    const newProvider = action.provider
    const index = findIndex(getList(providerDb, action.companyKey), newProvider.id)

    if (index >= 0) {
      const providers = getIn(providerDb, getListKeyPath(action.companyKey))

      // do not set right away, we do not want to loose flags like `selected`
      // when overwriting, hence doing a merge with the existing one first
      const existingProvider = providers.find(
        (provider) => provider.id === newProvider.id
      )

      providers[index] = deepmerge(existingProvider, newProvider, {
        // fixes https://youtrack.smxemail.com/issue/QA-2603
        arrayMerge: (destinationArray, sourceArray) => sourceArray
      })

      return setIn(providerDb, getListKeyPath(action.companyKey), providers)
    }

    // not found, just return same state
    return providerDb
  },

  [SHAREDSERVICES_PROVIDER_SET_LIST]: (providerDb, action) => {
    const companyKey = action.companyKey
    const oldList = getList(providerDb, companyKey)

    // remember current selection
    const selectedProvider = oldList && oldList.find((provider) => provider.selected)

    providerDb = setIn(providerDb, getListKeyPath(companyKey), action.newList)

    if (selectedProvider) {
      // restore selection if one was selected before
      const selectedProviderIndex = findIndex(
        getList(providerDb, action.companyKey),
        selectedProvider.id
      )

      if (selectedProviderIndex >= 0) {
        const keyPath = getListKeyPath(companyKey).concat([
          selectedProviderIndex,
          'selected'
        ])
        providerDb = setIn(providerDb, keyPath)
      }
    }

    return providerDb
  },

  [SHAREDSERVICES_PROVIDER_START_LOADING]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [SHAREDSERVICES_PROVIDER_STOP_LOADING]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [SHAREDSERVICES_PROVIDER_START_CONFIGURING]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'configuring'), true)
  },

  [SHAREDSERVICES_PROVIDER_STOP_CONFIGURING]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'configuring'), false)
  },

  [SHAREDSERVICES_PROVIDER_START_CREATING]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'creating'), true)
  },

  [SHAREDSERVICES_PROVIDER_STOP_CREATING]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'creating'), false)
  },

  [SHAREDSERVICES_PROVIDER_START_CONFIRMING_DELETION]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'confirmingDeletion'), true)
  },

  [SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_DELETION]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'confirmingDeletion'), false)
  },

  [SHAREDSERVICES_PROVIDER_START_CONFIRMING_CONFIGURATION]: (providerDb, action) => {
    return setIn(
      providerDb,
      getKeyPath(action.companyKey, 'confirmingConfiguration'),
      action.values
    )
  },

  [SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_CONFIGURATION]: (providerDb, action) => {
    return setIn(
      providerDb,
      getKeyPath(action.companyKey, 'confirmingConfiguration'),
      false
    )
  },

  [SHAREDSERVICES_PROVIDER_SET_URI]: (providerDb, action) => {
    return setIn(providerDb, getKeyPath(action.companyKey, 'uri'), action.uri)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
