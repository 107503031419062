const Ext = require('ext')
const t = require('translate')
const CatalogueStore = require('admin/data/CatalogueStore')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const CurrencyAndPriceField = require('admin/component/form/CurrencyAndPriceField')
const DateRangeFieldSet = require('admin/component/form/DateRangeFieldSet')
const Tooltip = require('admin/util/Tooltip')
const config = require('theming/config')
const escape = require('html-escaper').escape

// Service is the new default since SCL-876
const DEFAULT_TYPE = 'SERVICE'

const AgreementNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'agreement-new-panel',

  module() {
    return require('admin/business/AgreementResource')
  },

  saveText: t('Add Product'),

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getDefaultTrialThruDate() {
    return new Date().add(Date.DAY, 14)
  },

  getNewItems(cfg) {
    let thruValue

    this.catalogueStore = new CatalogueStore({
      parentRecord: cfg.record
    })

    this.catalogueStore.on(
      'load',
      require('admin/business/AgreementResource').filterCatalogue
    )

    this.catalogueStore.load()

    const productCategoryField = new Ext.form.Hidden({
      xtype: 'hidden',
      name: 'product.productCategory',
      value: ''
    })

    this.productCombo = new ComboBox({
      fieldLabel: t('Billable Product'),
      lazyInit: false,
      lastQuery: '',
      triggerAction: 'all',
      hiddenName: 'product',
      valueField: 'code',
      displayField: 'name',
      store: this.catalogueStore,
      editable: false,
      allowBlank: false,
      listWidth: 430,
      /* eslint-disable no-multi-str */
      tpl: new Ext.XTemplate(
        '\
<div style="padding: 2px; font-weight: bold"> \
<div style="float: left; width: 250px;">{[this.escape(this.t("Product"))]}</div> \
<div style="float: left; width: 150px;">{[this.escape(this.t("Billed To"))]}</div> \
</div> \
<div style="clear: both;"</div> \
<tpl for="."> \
<div class="x-combo-list-item"> \
<div style="float: left; width: 250px;">{[this.escape(values["name"])]}</div> \
<div style="float: left; width: 150px;">{[this.escape(values["billToName"])]}</div> \
</div> \
</tpl>\
',
        { escape, t }
      )
    })

    this.billTo = new Ext.form.Hidden({
      name: 'billTo',
      value: cfg.record.get('id')
    })

    this.productCombo.on(
      'select',
      function (combo, record) {
        productCategoryField.setValue(record.get('categoryCode'))
        return this.billTo.setValue(record.get('billToId'))
      },
      this
    )

    this.quantity = cfg.module.createQuantityField()

    this.pricing = new Ext.form.RadioGroup({
      columns: 2,
      fieldLabel: t('Pricing'),
      style: 'margin-bottom: -4px;',
      items: [
        {
          boxLabel: t('Default'),
          name: 'pricing',
          value: 'default',
          checked: true,
          // those margins ensure that the checkbox is seen, not cut
          style: 'margin: 0 0 0 1px;',
          xtype: 'radio'
        },
        {
          boxLabel: t('Custom'),
          name: 'pricing',
          value: 'custom',
          style: 'margin: 0 0 0 1px;',
          xtype: 'radio'
        }
      ]
    })

    // the next three inputs, currency, price and price description
    // are disabled when the default pricing is set by default. but when it is custom
    // then make them editable but mandatory, see scl-1390 and the changePricing fn further down.

    this.currencyAndPrice = new CurrencyAndPriceField({
      disabled: true,
      currencyComboName: 'basePrice.priceCurrencyUom',
      priceAmountFieldName: 'basePrice.priceAmount'
    })

    this.currencyCombo = this.currencyAndPrice.currencyCombo
    this.priceAmountField = this.currencyAndPrice.priceAmountField

    this.description = new Ext.form.TextField({
      fieldLabel: t('Price Description'),
      name: 'basePrice.description',
      disabled: true,
      allowBlank: false
    })

    if (DEFAULT_TYPE === 'TRIAL') {
      thruValue = this.getDefaultTrialThruDate()
    } else {
      thruValue = null
    }

    const dateRange = new DateRangeFieldSet({
      minValue: new Date(),
      from: {
        name: 'fromDate',
        value: new Date(),
        allowBlank: false
      },
      thru: {
        name: 'thruDate',
        value: thruValue,
        allowBlank: true
      },
      both: {
        fieldWidth: config.panel.fieldWidth
      }
    })

    this.catalogueStore.on('load', this.update, this)
    this.productCombo.on('change', this.update, this)
    this.productCombo.on('select', this.update, this)
    this.quantity.on('change', this.update, this)
    this.quantity.on('keyup', this.update, this)
    this.currencyCombo.on('change', this.update, this)
    this.currencyCombo.on('select', this.update, this)
    this.pricing.on('change', this.changePricing, this)

    const items = [
      productCategoryField,
      new ComboBox({
        fieldLabel: t('Type'),
        mode: 'local',
        listeners: {
          select: (combo, record) => {
            if (record.get('field1') === 'TRIAL') {
              return dateRange.setThruValue(this.getDefaultTrialThruDate())
            }
            return dateRange.setThruValue('')
          }
        },
        hiddenName: 'agreementType',
        triggerAction: 'all',
        value: DEFAULT_TYPE,
        store: [
          ['SERVICE', t('Service')],
          ['TRIAL', t('Trial')]
        ],
        editable: false,
        allowBlank: false
      }),
      this.billTo,
      this.productCombo,
      this.quantity,
      this.pricing,
      this.currencyAndPrice,
      this.description,
      dateRange
    ]
    return items.filter(Boolean)
  },

  changePricing(group, checked) {
    if (checked.value === 'default') {
      this.currencyAndPrice.setAllowBlank(true)
      this.currencyAndPrice.clearInvalid()
      this.currencyAndPrice.disable()
      Tooltip.removeRequiredFieldMarker(this.currencyAndPrice.priceAmountField)

      this.description.allowBlank = true
      this.description.disable()
      Tooltip.removeRequiredFieldMarker(this.description)

      return this.update()
    }
    this.currencyAndPrice.setAllowBlank(false)
    this.currencyAndPrice.enable()
    Tooltip.appendRequiredFieldMarker(this.currencyAndPrice.priceAmountField)

    this.description.clearInvalid()
    this.description.allowBlank = false
    this.description.enable()
    return Tooltip.appendRequiredFieldMarker(this.description)
  },

  update() {
    if (this.isDestroyed) return

    const productCode = this.productCombo.getValue()
    const billToId = parseInt(this.billTo.getValue())

    const product = this.catalogueStore.getAt(
      this.catalogueStore.findBy(
        (record) =>
          record.get('code') === productCode && record.get('billToId') === billToId
      )
    )

    const price =
      product &&
      product.findPrice(
        this.currencyCombo.getValue(),
        this.record.getTermValue(),
        this.quantity.getValue()
      )

    const defaultOption = this.pricing.items.items[0]
    const customOption = this.pricing.items.items[1]

    // todo ugh, rewrite this. most of it does not make sense and
    // there are too many states involved and interwoven.
    if (price) {
      if (defaultOption.disabled) {
        defaultOption.setValue(true)
        defaultOption.setDisabled(false)
        customOption.setValue(false)
      }
      if (this.priceAmountField.disabled) {
        this.priceAmountField.setValue(price.get('priceAmount'))
        const prefix = product.get('billToName') || 'Default'
        return this.description.setValue(`${prefix} ${price.get('description')}`)
      }
    } else {
      if (this.priceAmountField.getValue() !== '') {
        this.priceAmountField.setValue('')
        this.description.setValue('')
      }

      if (this.hasRequiredFields()) {
        defaultOption.setValue(false)
        defaultOption.setDisabled(true)
        return customOption.setValue(true)
      }
      defaultOption.setValue(true)
      defaultOption.setDisabled(false)
      return customOption.setValue(false)
    }
  },

  hasRequiredFields() {
    return (
      this.productCombo.getValue() !== '' &&
      this.quantity.getValue() !== '' &&
      this.currencyCombo.getValue() !== ''
    )
  },

  getFormValues() {
    // the default extjs code does not add any disabled inputs to their form values
    // but in our case, we have to, when pricing is default which leads to disabled
    // inputs for currency, price and description. that's why we have to add them
    // manually here
    const formValues = this.callParent()

    if (!formValues['basePrice.priceAmount']) {
      formValues['basePrice.priceAmount'] = this.priceAmountField.getValue()
    }

    if (!formValues['basePrice.priceCurrencyUom']) {
      formValues['basePrice.priceCurrencyUom'] = this.currencyCombo.getValue()
    }

    if (!formValues['basePrice.description']) {
      formValues['basePrice.description'] = this.description.getValue()
    }

    return formValues
  }
})

module.exports = AgreementNewPanel
