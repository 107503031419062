import AbstractCompanyTabPanel from 'admin/view/AbstractCompanyTabPanel'
import Ext from 'ext'
import VendorDetailPanel from 'admin/view/VendorDetailPanel'
import VendorResource from 'admin/business/VendorResource'
import t from 'translate'

const VendorTabPanel = Ext.define(null, {
  extend: AbstractCompanyTabPanel,

  module: VendorResource,
  title: t('Vendor'),
  tabCls: 'vendor-tab',
  cls: 'vendor-panel',

  buildDetailPanel() {
    return new VendorDetailPanel({ record: this.record })
  }
})

export default VendorTabPanel
