const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ListManagementListPanel = require('smartrules/ListManagementListPanel')

const ListManagementListResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.ListManagementList',
  translations: {
    title: t('List Management'),
    new: t('List Management'),
    open: t('List Management'),
    list: t('List Management')
  },
  name: t('List Management'),
  resourceName: 'list-management',
  submitOnEnter: false,

  layout: {
    newResource: {
      width: 600,
      height: 400
    }
  },

  newPanel: ListManagementListPanel
})

module.exports = ListManagementListResource
