import Ext from 'ext'
import Clause from 'smartrules/designer/Clause'
import DropZone from 'smartrules/designer/DropZone'
import t from 'translate'

const DragUtils = Ext.define(null, {
  statics: {
    makeClauseTooltipEl(cls, type, label = t('Drag to move this to its new position')) {
      const html = String.format(
        '<div class="{0} smartrules-drag-tooltip"><p class="{1} smartrules-icon">{2}</p></div>',
        cls || '',
        type,
        label
      )

      const div = document.createElement('div')
      div.innerHTML = html.trim()
      div.id = Ext.id()

      return div.firstChild
    },

    setupHighlightingDragTracker(dragger) {
      DragUtils.setupDragTracker(dragger, true)
    },

    setupDragTracker(dragger, highlightSource = false) {
      // leave them here to avoid circular dependencies
      const ClauseTree = require('smartrules/designer/ClauseTree')
      const GroupHeader = require('smartrules/designer/GroupHeader')
      const Group = require('smartrules/designer/Group')

      if (dragger.dragTracker != null) {
        dragger.dragTracker.destroy()
      }

      dragger.dragTracker = new Ext.dd.DragTracker({
        autostart: 500,

        onStart() {
          const clauseTree = dragger.findParentBy((n) => n instanceof ClauseTree)

          if (!clauseTree) return false

          if (highlightSource) {
            dragger.addClass('smartrules-dd-active-source')
          }

          const clause = clauseTree.findBy((n) => n instanceof Group && n.root)
          const root = clause && clause[0]

          const dzs = root.findBy(
            (n) =>
              (n instanceof Clause && !n.inHeader) ||
              n instanceof DropZone ||
              n instanceof Group
          )

          const parent = dragger.findParentBy((n) => n instanceof Group)

          const getDzParent = (dz) =>
            dz.findParentBy((n) => n instanceof Group && n.id === parent.id)

          const noMatch = (dz) =>
            dz.id === (dragger.clause ? dragger.clause.id : undefined) ||
            (dragger.ownerCt instanceof GroupHeader &&
              parent &&
              !parent.root &&
              getDzParent(dz))

          for (const dz of Array.from(dzs)) {
            if (noMatch(dz)) {
              dz.addClass('non-prospect')
              dz.removeClass('prospect')
            } else {
              dz.addClass('prospect')
              dz.removeClass('non-prospect')
            }
          }
        },

        onEnd() {
          const clauseTree = dragger.findParentBy((n) => n instanceof ClauseTree)
          dragger.removeClass('smartrules-dd-active-source')

          if (!clauseTree) return false

          const clause = clauseTree.findBy((n) => n instanceof Group && n.root)
          const root = clause && clause[0]

          const getProspects = () =>
            root.findBy((n) => {
              const el = n.getEl()
              if (!el) return
              return el.hasClass('prospect') || el.hasClass('non-prospect')
            })

          for (const n of Array.from(getProspects())) {
            n.removeClass('prospect')
            n.removeClass('non-prospect')
          }

          return true
        }
      })

      return dragger.dragTracker.initEl(dragger.getEl())
    }
  }
})

export default DragUtils
