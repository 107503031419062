import React, { Component } from 'react'
import {
  confirmingDeletion,
  confirmingPurge,
  creating,
  editing,
  upgrading
} from 'mailboxes/subs'

import AddForm from 'mailboxes/views/form/Add'
import ConfirmDelete from 'mailboxes/views/confirm/Delete'
import ConfirmPurge from 'mailboxes/views/confirm/Purge'
import EditForm from 'mailboxes/views/form/Edit'
import Grid from 'components/Grid'
import Hint from 'mailboxes/views/Hint'
import PropTypes from 'prop-types'
import Table from 'mailboxes/views/Table'
import Toolbar from 'mailboxes/views/toolbar/Toolbar'
import UpgradeForm from 'mailboxes/views/form/Upgrade'
import { uri as catalogueUri } from 'mailhosting/catalogue/subs'
import { connect } from 'react-redux'
import { refresh as refreshCatalogue } from 'mailhosting/catalogue/actions'

const BOOTSTRAP_PADDING = 5

class MailboxManager extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number
  }

  UNSAFE_componentWillMount() {
    this.props.refreshCatalogue(this.props.catalogueUri)
  }

  showHint() {
    // TODO once https://youtrack.smxemail.com/issue/SSV-3547 is resolved
    // stop using this hack but decide based on backend response in a new subscriber
    return /sparkbusinessmail\.co\.nz/.test(window.location.host)
  }

  renderHint() {
    return <Hint companyKey={this.props.companyKey} />
  }

  renderTable() {
    return (
      <Table
        width={this.props.width - BOOTSTRAP_PADDING}
        height={this.props.height}
        companyKey={this.props.companyKey}
      />
    )
  }

  renderForm() {
    if (this.props.creating) {
      return <AddForm companyKey={this.props.companyKey} />
    } else if (this.props.editing) {
      return <EditForm companyKey={this.props.companyKey} />
    } else if (this.props.upgrading) {
      return <UpgradeForm companyKey={this.props.companyKey} />
    } else if (this.props.confirmingDeletion) {
      return <ConfirmDelete companyKey={this.props.companyKey} />
    } else if (this.props.confirmingPurge) {
      return <ConfirmPurge companyKey={this.props.companyKey} />
    }
  }

  render() {
    return (
      <div className='tab-content'>
        <Grid>
          <Toolbar companyKey={this.props.companyKey} />
          <div>
            {/* Commented out, so that we always see those numbers. See DPT-6468. */}
            {/* {this.props.hasUpgradeLink && this.renderHint()} */}
            {this.renderHint()}
            {this.renderTable()}
          </div>
          {this.renderForm()}
        </Grid>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  catalogueUri: catalogueUri(db, companyKey),
  creating: creating(db, companyKey),
  upgrading: upgrading(db, companyKey),
  editing: editing(db, companyKey),
  confirmingPurge: confirmingPurge(db, companyKey),
  confirmingDeletion: confirmingDeletion(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  refreshCatalogue: (catalogueUri) => {
    dispatch(refreshCatalogue(companyKey, catalogueUri))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(MailboxManager)
