import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import t from 'translate'

import Dialog from 'components/dialog/Confirmation'

import { stopConfirmingDeletion, deleteMailTarget } from 'quarantine/mail_targets/actions'

import {
  loading,
  uri,
  selectedMailTargetName,
  selectedMailTargetLink
} from 'quarantine/mail_targets/subs'

import { uri as quarantineSetsUri } from 'quarantine/quarantine_sets/subs'

class Delete extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.deleteMailTarget(
      this.props.uri,
      this.props.selectedMailTargetLink,
      this.props.quarantineSetsUri
    )
  }

  render() {
    const title = t('Delete Mail Target {0}?', [this.props.selectedMailTargetName])

    const warning = t('Are you sure you want to delete Mail Target {0}?', [
      this.props.selectedMailTargetName
    ])

    return (
      <Dialog
        icon='exclamation'
        modalTitle={title}
        headerText={warning}
        submitButtonText={t('Delete')}
        cancelDisabled={this.props.loading}
        submitDisabled={this.props.loading}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  loading: loading(db, props.companyKey),
  selectedMailTargetName: selectedMailTargetName(db, props.companyKey),
  selectedMailTargetLink: selectedMailTargetLink(db, props.companyKey),
  uri: uri(db, props.companyKey),
  quarantineSetsUri: quarantineSetsUri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopConfirmingDeletion(initialProps.companyKey)),
  deleteMailTarget: (uri, selectedLink, quarantineSetsUri) => {
    dispatch(
      deleteMailTarget(initialProps.companyKey, uri, selectedLink, quarantineSetsUri)
    )
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Delete)
