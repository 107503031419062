const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ProxyStore = require('admin/data/ProxyStore')
const ComboBox = require('admin/component/form/ComboBox')
const lowercaseListeners = require('admin/util/lowercaseListeners')
const validators = require('validators').default
const config = require('theming/config')

const ScrubbingDomainNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'scrubbingdomain-new-panel',

  module() {
    return require('admin/business/ScrubbingDomainResource')
  },

  getFocusId() {
    return 1
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg),
      validateForm: this.module.validateScrubbingDomainForm
    })
  },

  getNewItems(cfg) {
    // ComboBox modifies the store so we need a proxy.
    const { customer } = cfg
    const mailRelayHostStore = customer.getMailRelayHostStore()
    mailRelayHostStore.reload()
    const proxyStore = new ProxyStore(mailRelayHostStore)

    // Refresh the store in case someone created a
    // mail relay host in this window.
    cfg.store.on('add', () => mailRelayHostStore.reload(), this, { single: true })

    const mailRelayHost = new ComboBox({
      allowBlank: false,
      fieldLabel: t('Mail Relay Host'),
      hiddenName: 'relayTo',
      displayField: 'name',
      store: proxyStore,
      triggerAction: 'all',
      width: config.panel.fieldWidth
      // disabled since SCL-3433 due to recursion issues (async and performance)
      // validator:     validators.domainOrIp.withMsg(t('You must enter a valid DNS Name or IP Address'))
    })
    // Force lower case.
    mailRelayHost.on('change', function () {
      return (this.getEl().dom.value = (this.el.dom.value || '').toLowerCase())
    })

    return [
      {
        name: 'approvalStatus',
        value: t('Created'),
        xtype: 'hidden'
      },
      {
        enableKeyEvents: true,
        fieldLabel: t('Domain Name'),
        listeners: lowercaseListeners,
        name: 'name',
        allowBlank: false,
        validator: validators.domain.withMsg(t('You must enter a valid DNS Name')),
        xtype: 'textfield'
      },
      mailRelayHost
    ]
  }
})

module.exports = ScrubbingDomainNewPanel
