import { Form, FormGroup, Modal } from 'react-bootstrap'
import React, { Component } from 'react'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Icon from 'components/Icon'
import Mask from 'components/Mask'
import PropTypes from 'prop-types'

export default class ConfirmationDialog extends Component {
  static propTypes = {
    icon: PropTypes.oneOf(['info', 'exclamation']),
    modalTitle: PropTypes.string.isRequired,
    headerText: PropTypes.string,
    submitButtonText: PropTypes.string.isRequired,
    cancelDisabled: PropTypes.bool.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  renderIcon = () => {
    return (
      <div className='col-xs-2 control-label'>
        <Icon type={this.props.icon} />
      </div>
    )
  }

  renderContent = () => {
    return (
      <FormGroup className='dialog-field'>
        {this.props.icon && this.renderIcon()}
        <h1 className={this.props.icon ? 'col-xs-9' : 'col-xs-9 col-xs-offset-3'}>
          {this.props.headerText}
        </h1>
        {this.props.children}
      </FormGroup>
    )
  }

  render() {
    return (
      <Mask>
        <Modal.Dialog className='confirmation'>
          <Modal.Header>
            <Modal.Title>{this.props.modalTitle}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.props.onSubmit}>
            <Modal.Body>{this.renderContent()}</Modal.Body>
            <Modal.Footer>
              <CancelSubmitButtons
                disableCancel={this.props.cancelDisabled}
                disableSubmit={this.props.submitDisabled}
                onCancel={this.props.onCancel}
                submitText={this.props.submitButtonText}
              />
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}
