import archivingDb from 'archiving/db'
import continuityDb from 'continuity/db'
import tenancyDb from 'integration/tenancy/db'
import mailboxesDb from 'mailboxes/db'
import mailhostingDb from 'mailhosting/db'
import quarantineDb from 'quarantine/db'
import providerDb from 'sharedservices/provider/db'
import capabilityDb from 'system/capability/db'

export default {
  archiving: archivingDb,
  continuity: continuityDb,
  provider: providerDb,
  quarantine: quarantineDb,
  capability: capabilityDb,
  mailboxes: mailboxesDb,
  mailhosting: mailhostingDb,
  tenancy: tenancyDb
}
