const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')
const NoMatcher = require('smartrules/designer/matchers/NoMatcher')
const validators = require('validators').default

const ReplaceSender = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Replace sender with'),
      noSpacer: true,
      type: 'smartrules-replace-sender',
      nestable: false
    })

    const textfield = new Ext.form.TextField({
      allowBlank: false,
      flex: 1,
      name: 'value',
      ref: '../textfield',
      xtype: 'textfield',
      value: cfg.value || '',
      validator: validators.emailTpl.withMsg(
        t(
          'This field should be an e-mail address in the format "user@example.com" ' +
            'and may use the tokens {{{user}}} and {{{domain}}}'
        )
      )
    })
    this.callParent([cfg])
    this.insert(3, {
      flex: 1,
      layout: 'hbox',
      items: textfield
    })
    this.insert(4, {
      bodyStyle: {
        'line-height': '20px'
      },
      border: false,
      padding: '0 10',
      height: 24,
      html: t('in', 'SmartRules Replace Sender Action')
    })

    // Leave the require call here because circular references!
    const MatcherCombo = require('smartrules/designer/MatcherCombo')

    this.scopeCmp = new MatcherCombo({
      matcher: cfg.scope || 'headers-and-envelope',
      matchers: [
        {
          matcher: 'headers-and-envelope',
          name: t('headers and envelope'),
          clazz: NoMatcher,
          dsl: 'in scope headers-and-envelope'
        },
        {
          matcher: 'envelope',
          name: t('envelope only'),
          clazz: NoMatcher,
          dsl: 'in scope envelope'
        }
      ]
    })

    this.insert(5, this.scopeCmp)
  },

  toDsl() {
    return String.format(
      'replace sender with "{0}" {1}',
      this.textfield.getValue().replace(/(["\\])/g, '\\$1'),
      this.scopeCmp.toDsl()
    )
  }
})

module.exports = ReplaceSender
