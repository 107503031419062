import * as hal from 'hal'

import {
  AVAILABLE_MAIL_TARGET_REL,
  QUARANTINE_SET_REL,
  QUARANTINE_TRIGGERS_REL
} from 'product/resources'

import Api from 'api'
import logger from 'system/logger'

export const QUARANTINE_QUARANTINE_SETS_STOP_CREATING =
  'QUARANTINE_QUARANTINE_SETS_STOP_CREATING'
export const QUARANTINE_QUARANTINE_SETS_STOP_CONFIGURING =
  'QUARANTINE_QUARANTINE_SETS_STOP_CONFIGURING'
export const QUARANTINE_QUARANTINE_SETS_START_CONFIGURING =
  'QUARANTINE_QUARANTINE_SETS_START_CONFIGURING'
export const QUARANTINE_QUARANTINE_SETS_SET_LIST = 'QUARANTINE_QUARANTINE_SETS_SET_LIST'
export const QUARANTINE_QUARANTINE_SETS_CLEAR_LIST =
  'QUARANTINE_QUARANTINE_SETS_CLEAR_LIST'
export const QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TRIGGERS =
  'QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TRIGGERS'
export const QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TARGETS =
  'QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TARGETS'
export const QUARANTINE_QUARANTINE_SETS_SELECT = 'QUARANTINE_QUARANTINE_SETS_SELECT'
export const QUARANTINE_QUARANTINE_SETS_START_LOADING =
  'QUARANTINE_QUARANTINE_SETS_START_LOADING'
export const QUARANTINE_QUARANTINE_SETS_STOP_LOADING =
  'QUARANTINE_QUARANTINE_SETS_STOP_LOADING'
export const QUARANTINE_QUARANTINE_SETS_START_CREATING =
  'QUARANTINE_QUARANTINE_SETS_START_CREATING'
export const QUARANTINE_QUARANTINE_SETS_SET_URI = 'QUARANTINE_QUARANTINE_SETS_SET_URI'
export const QUARANTINE_QUARANTINE_SETS_STOP_CONFIRMING_DELETION =
  'QUARANTINE_QUARANTINE_SETS_STOP_CONFIRMING_DELETION'
export const QUARANTINE_QUARANTINE_SETS_START_CONFIRMING_DELETION =
  'QUARANTINE_QUARANTINE_SETS_START_CONFIRMING_DELETION'

const startLoading = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_START_LOADING,
  companyKey
})
const stopLoading = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_STOP_LOADING,
  companyKey
})

function processError(err) {
  logger.error(err)
}

/// ========= INIT DB WITH URI FOR COMPANY KEY ========= ///

const setUri = (companyKey, uri) => ({
  type: QUARANTINE_QUARANTINE_SETS_SET_URI,
  companyKey,
  uri
})

export const init = (companyKey, uri) => (dispatch) => {
  // todo later, initialise db
  // dispatch(initDb(companyKey))
  dispatch(setUri(companyKey, uri))
}

/// ========= LIST SET/REFRESH ========= ///

export const setList = (companyKey, newList) => ({
  type: QUARANTINE_QUARANTINE_SETS_SET_LIST,
  companyKey,
  newList
})

export const clearList = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_CLEAR_LIST,
  companyKey
})

export const setAvailableTriggers = (companyKey, availableTriggers) => ({
  type: QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TRIGGERS,
  companyKey,
  availableTriggers
})

export const setAvailableTargets = (companyKey, availableTargets) => ({
  type: QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TARGETS,
  companyKey,
  availableTargets
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)

    dispatch(setList(companyKey, hal.resource(response.data, QUARANTINE_SET_REL)))

    const triggers = hal.resource(response.data, QUARANTINE_TRIGGERS_REL, 'triggers')
    dispatch(setAvailableTriggers(companyKey, triggers))

    const targets = hal.link(response.data, AVAILABLE_MAIL_TARGET_REL, 'targets')
    dispatch(setAvailableTargets(companyKey, targets))
  } catch (err) {
    dispatch(clearList(companyKey)) // clear existing list to reflect error state
    processError(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

/// ========= SELECTIONS ========= ///

export const select = (companyKey, quarantineSets, selected) => {
  return {
    type: QUARANTINE_QUARANTINE_SETS_SELECT,
    companyKey,
    quarantineSets,
    selected
  }
}

/// ========= CREATION ========= ///

export const startCreating = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_START_CREATING,
  companyKey
})

export const stopCreating = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_STOP_CREATING,
  companyKey
})

export const createQuarantineSet = (companyKey, uri, values) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  let response

  try {
    response = await Api.post(uri, values)
  } catch (err) {
    processError(err)
    return dispatch(stopLoading(companyKey))
  }

  try {
    await dispatch(refresh(companyKey, uri))
    dispatch(select(companyKey, [response.data], true))
  } finally {
    // we always close regardless whether refresh was successful or not
    dispatch(stopCreating(companyKey))
  }
}

/// ========= DELETION ========= ///

export const startConfirmingDeletion = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_START_CONFIRMING_DELETION,
  companyKey
})
export const stopConfirmingDeletion = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_STOP_CONFIRMING_DELETION,
  companyKey
})

export const deleteQuarantineSet = (companyKey, uri, selfLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(selfLink)

    await Api.delete(selfLink, { 'If-Match': response.headers.etag })
    await dispatch(refresh(companyKey, uri))
    dispatch(stopConfirmingDeletion(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= CONFIGURATION ========= ///

export const startConfiguring = (companyKey, quarantineSet, etag) => ({
  type: QUARANTINE_QUARANTINE_SETS_START_CONFIGURING,
  companyKey,
  quarantineSet,
  etag
})

export const stopConfiguring = (companyKey) => ({
  type: QUARANTINE_QUARANTINE_SETS_STOP_CONFIGURING,
  companyKey
})

export const configureQuarantineSet = (companyKey, selfLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(selfLink)
    const configTarget = response.data

    dispatch(startConfiguring(companyKey, configTarget, response.headers.etag))
    dispatch(stopLoading(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
    dispatch(stopConfiguring(companyKey))
  }
}

export const updateQuarantineSet = (companyKey, uri, selfLink, values, etag) => {
  if (!etag) throw new Error('Etag for quarantine set is missing')

  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      await Api.put(selfLink, values, { 'If-Match': etag })
    } catch (err) {
      processError(err)
      return dispatch(stopLoading(companyKey))
    }

    try {
      await dispatch(refresh(companyKey, uri))
    } finally {
      dispatch(stopConfiguring(companyKey))
    }
  }
}

export const doubleClickQuarantineSet = (companyKey, quarantineSet) => (dispatch) => {
  dispatch(select(companyKey, [quarantineSet], true))
  dispatch(configureQuarantineSet(companyKey, hal.self(quarantineSet)))
}
