import t from 'translate'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Field from 'components/form/Field'

class Catalogue extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    catalogue: PropTypes.array.isRequired
  }

  renderCatalogueEntry(name, description) {
    return (
      <option key={name} value={name}>
        {description}
      </option>
    )
  }

  render() {
    return (
      <Field
        required
        disabled={this.props.disabled}
        name='cosName'
        componentClass='select'
        label={t('Hosting Product')}
      >
        <option value=''>{t('Please select a hosting product')}</option>
        {/* TODO if there is only one entry, already select it */}
        {this.props.catalogue.map((entry) =>
          this.renderCatalogueEntry(entry.cosName, entry.description)
        )}
      </Field>
    )
  }
}

export default Catalogue
