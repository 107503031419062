import User from 'admin/core/User'
import WindowMgr from 'admin/desktop/WindowMgr'
import Ext from 'ext'
import t from 'translate'

// A resource is a container for settings, components and functionality that
// relate to a single data type.
const Resource = Ext.define(null, {
  name: '',
  searchable: false,
  explorable: false,
  submitOnEnter: true,
  getParentResources: () => {},

  detailPanel: null,
  listPanel: null,
  newPanel: null,

  resourceStore: {},
  resourceParams: '',

  layout: {},
  operations: {},

  constructor() {
    Ext.applyIf(this.layout, {
      newResource: {
        width: 725,
        height: 540
      },
      openResource: {
        width: 725,
        height: 540
      },
      listResource: {
        width: 725,
        height: 540
      }
    })

    Ext.applyIf(this.operations, {
      create: true,
      open: true,
      remove: true,
      list: true,
      save: true
    })
  },

  getResourceCls(prefix) {
    const cls = (this.id ? this.id.replace(/\./g, '-') : undefined) || ''

    if (prefix) {
      return `${prefix}-${cls}`
    } else if (cls) {
      return cls
    }

    return ''
  },

  generateWindowId(action, uniqueId = '') {
    return `${action}.${this.id}[${uniqueId}]`
  },

  create(record = null, options = {}) {
    let uniqueId

    if (record) {
      uniqueId = record.getId()
    } else if (options.customer) {
      uniqueId = options.customer.getId()
    }

    const windowId = this.generateWindowId('new', uniqueId)
    const win = WindowMgr.select(windowId)

    if (win) return win // already exists

    const createName = this.translations.new
    const NewPanel = this.newPanel
    const panel = new NewPanel(Ext.apply({ record }, options))

    if (panel) {
      let title = `${createName}`

      if (options.customer) {
        title = options.customer.getName() + ': ' + title
      }

      if (record?.getName) {
        title += ` - ${record.getName()}`
      }

      const store = options.store || panel.store
      const url = store.getUrl() + '/create/'

      return WindowMgr.create(
        {
          id: windowId,
          title,
          url,
          taskbar: createName,
          width: this.layout.newResource.width,
          autoHeight: this.layout.newResource.height === 'auto',
          height: this.layout.newResource.height,
          minHeight: this.layout.newResource.minHeight,
          iconCls: 'new',
          shim: false,
          animCollapse: false,
          maximizable: this.layout.newResource.resizable === true,
          resizable: this.layout.newResource.resizable === true,
          resizeHandles: this.layout.newResource.resizeHandles || 'all',
          items: panel,
          layout: 'fit'
        },
        {
          maximized: options.maximized
        }
      )
    }
  },

  // used for window titles, see SCL-3350
  getTitle(record) {
    return record.getTitle()
  },

  refreshTitle(record) {
    const windowId = this.generateWindowId('open', record.getId())
    const title = this.getTitle(record)

    WindowMgr.setTitle(windowId, title)
  },

  open(record, options = {}) {
    if (this.code === 'REPORT' && !record.canOpen()) {
      this.showReportOptions(record, { export: true })
      return
    }

    // only listen to changes when there is an explorer node for this resource
    // see https://youtrack.smxemail.com/issue/SCL-2300
    if (this.explorable) {
      record.on('change', () => {
        this.refreshTitle(record)

        // this will reload the explorer nodes on the left so that name
        // changes are reflected there, see SCL-907

        // todo: instead of refreshing all nodes, do a performance optimization
        // and only refresh the one affected explorer node instead. but i wouldn't worry
        // too much about this as refreshing all nodes is very fast.
        record.store?.reload()
      })
    }

    const windowId = this.generateWindowId('open', record.getId())

    if (options.forceReopen) {
      WindowMgr.close(windowId)
    }

    if (!WindowMgr.select(windowId)) {
      const GroupPanel = this.groupPanel
      let panel

      if (GroupPanel) {
        panel = new GroupPanel(Ext.apply(options, { record }))
      } else {
        const DetailPanel = this.detailPanel
        panel = new DetailPanel(Ext.apply(options, { record }))
      }

      if (panel) {
        let title

        if (this.getTitle) {
          title = this.getTitle(record)
        } else {
          const debugging = User.isTechnicalAdmin() ? `[${record.getId()}]` : ''

          let recordName = record.getName()

          if (recordName instanceof Date && recordName.getTime()) {
            recordName = Ext.util.Format.date(recordName, Date.patterns.SHORT)
          }

          title = `${this.name}${debugging}: ${recordName}`
        }

        const url = record.getUrl()

        return WindowMgr.create(
          {
            id: windowId,
            title,
            taskbar: record.getName(),
            url,
            width: this.layout.openResource.width,
            minWidth: this.layout.openResource.width,
            autoHeight: this.layout.openResource.height === 'auto',
            height: this.layout.openResource.height,
            minHeight: this.layout.openResource.minHeight,
            resizable: this.layout.openResource.resizable !== false,
            resizeHandles: this.layout.openResource.resizeHandles || 'all',
            maximizable: this.layout.openResource.resizable !== false,
            shim: false,
            animCollapse: false,
            items: panel,
            layout: 'fit'
          },
          {
            maximized: options.maximized
          }
        )
      }
    }
  },

  remove(record) {
    const title = t('Remove {0}?', [this.name])
    const text = t('Are you sure you would like to remove the {0} {1}?', [
      this.name,
      record.getName()
    ])

    Ext.MessageBox.confirm(title, text, function (result) {
      if (result === 'yes') {
        Ext.MessageBox.wait(t('Saving...'))
        record.destroy({
          complete() {
            Ext.MessageBox.hide()
          }
        })
      }
    })
  },

  list(record, cfg = {}) {
    const windowId = this.generateWindowId('list', record.getId())
    const win = WindowMgr.select(windowId)

    if (win) return win // already exists

    const store = record.getResourceStore(this.getCode())

    const ListPanel = this.listPanel
    const panel = new ListPanel({
      record,
      store,
      title: false
    })

    const config = Ext.applyIf(cfg, {
      id: windowId,
      url: store.getUrl(),
      title: this.translations.plural,
      taskbar: this.translations.plural,
      width: this.layout.listResource.width,
      minWidth: this.layout.listResource.width,
      height: this.layout.listResource.height,
      minHeight: this.layout.listResource.height,
      resizable: this.layout.listResource.resizable !== false,
      maximizable: this.layout.listResource.resizable !== false,
      shim: false,
      animCollapse: false,
      items: panel,
      layout: 'fit'
    })

    return WindowMgr.create(config)
  },

  getCode() {
    return this.resourceId || this.code || this.id
  },

  findInProduct(product) {
    return product.resources ? product.resources[this.getCode()] : undefined
  },

  hasPermission(action) {
    if (this.code) {
      return User.hasPermission(this.code, action)
    }

    return true
  },

  canOpen() {
    return this.operations.open && this.hasPermission('GET')
  },

  canList() {
    return this.operations.list && this.hasPermission('GET')
  },

  canCreate() {
    return this.operations.create && this.hasPermission('POST')
  },

  canSave() {
    return this.operations.save && this.hasPermission('PUT')
  },

  canRemove() {
    return this.operations.remove && this.hasPermission('DELETE')
  }
})

export default Resource
