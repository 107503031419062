const Ext = require('ext')
const Component = require('smartrules/designer/Component')
const Operator = require('smartrules/designer/Operator')
const ListStore = require('smartrules/designer/ListStore')
const Matcher = require('smartrules/designer/Matcher')
const titleize = require('strings/titleize')
const isStringArrayEqual = require('strings/compare/array')
const compareStrings = require('strings/compare/lexical')
const store = require('store').default
const { getSmartRuleCapabilities } = require('system/capability/subs')

const isRegex = require('smartrules/designer/util/isRegex').default
const hasOr = require('smartrules/designer/util/hasOr').default

const prettifyListItems = function (listItems, options = {}) {
  return (
    listItems != null &&
    listItems.map(function (name) {
      const titleizeOptions = {}

      if (options.convertOrToRegex) {
        titleizeOptions.excludeWord = 'or'
      }

      if (options.titleize) {
        name = titleize(name, titleizeOptions)
      }

      return [name]
    })
  )
}

const dslIsDifferent = (matcher, record) =>
  compareStrings(record.get('dsl'), matcher?.dsl) !== 0

const matcherClassIsDifferent = (matcher, record) =>
  !(matcher instanceof record.get('clazz'))

const matcherTypeIsDifferent = (matcher, record) =>
  !isStringArrayEqual(matcher?.matcherType, record.get('matcherType'))

const matcherNegationIsDifferent = (matcher, record) =>
  matcher?.negated !== record.get('negated')

// destroy when either dsl, matcher, type or negation is different
const destroyCurrentMatcher = function (matcher, record, forceDestroyCurrent = false) {
  // when anything of these have changed, return true for destruction
  return (
    forceDestroyCurrent ||
    dslIsDifferent(matcher, record) ||
    matcherTypeIsDifferent(matcher, record) ||
    matcherClassIsDifferent(matcher, record) ||
    matcherNegationIsDifferent(matcher, record)
  )
}

const skipNewMatcher = function (matcher, record, forceDestroyCurrent = false) {
  if (
    destroyCurrentMatcher(matcher, record, forceDestroyCurrent) ||
    dslIsDifferent(matcher, record)
  ) {
    // when already hidden, no need to go further
    return matcher?.hidden
  }

  return true
}

const reuseMatcherValue = (currentMatcher, record) =>
  !matcherClassIsDifferent(currentMatcher, record) &&
  !matcherTypeIsDifferent(currentMatcher, record)

const MatcherCombo = Ext.define(null, {
  extend: Component,

  constructor(cfg) {
    this.defaultCfg = Ext.applyIf(cfg || {}, {
      cls: 'matcher-panel',
      editable: false,
      flex: 1,
      height: 'auto',
      layout: 'hbox',
      items: []
    })

    this.valueType = cfg.valueType
    this.matcherStore = this.createMatcherStore(
      this.defaultCfg.matchers,
      this.defaultCfg.defaultDsl
    )

    // operators are only needed when there is more than one matcher
    // because, when there is only one matcher, then we are always listing
    // the same matcher and it is independent from the operator.
    if (this.hasMoreThanOneMatcher()) {
      this.defaultCfg.items.push(this._createOperator())
    }

    this.callParent([this.defaultCfg])

    if (this.matcherStore.getCount() !== 0) {
      this.updateValue()
    }

    this.on('fixHeight', this.fixHeight, this)
  },

  _createOperator() {
    if (!this.matcherOperator) {
      this.matcherOperator = new Operator({
        scope: this,
        width: this.defaultCfg.operatorWidth,
        selectMatcher: this.selectMatcher,
        store: this.matcherStore
      })
    }

    return this.matcherOperator
  },

  _removeOperator() {
    if (this.matcherOperator) {
      this.remove(this.matcherOperator)
      delete this.matcherOperator
    }
  },
  regenerateStore(matchers = [], force = false) {
    const arraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false
      }
      return true
    }

    // Determine if the store actually needs to be cleared
    const existingMatchers = this.matcherStore
      .getRange()
      .map((record) => record.get('matcher'))
    const newMatchers = matchers.map((configItem) => configItem.matcher)
    const needsRefresh = force || !arraysEqual(existingMatchers, newMatchers)

    if (needsRefresh) {
      let currentValue = this.matcherOperator ? this.matcherOperator.getValue() : null
      this.matcherStore.suspendEvents()
      this.matcherStore.removeAll()
      matchers.forEach((configItem) => {
        const RecordTypeClass = this.matcherStore.recordType
        this.matcherStore.add(new RecordTypeClass(configItem))
      })

      this.matcherStore.resumeEvents()

      // If the current value is still in the new set of matchers, reset it to maintain selection
      if (currentValue && this.matcherStore.findExact('matcher', currentValue) !== -1) {
        this.matcherOperator.setValue(currentValue)
      } else if (this.matcherStore.getCount() > 0) {
        // If the current value is no longer present, select a default (e.g., the first item)
        this.matcherOperator.setValue(this.matcherStore.getAt(0).get('matcher'))
      }

      if (this.matcherOperator) {
        const recordIndex = this.matcherStore.findExact('matcher', currentValue)
        if (recordIndex !== -1) {
          const record = this.matcherStore.getAt(recordIndex)
          this.matcherOperator.fireEvent('select', this.matcherOperator, record)
        }
      }

      this.updateValue({ forceDestroyCurrent: force })
    }
  },

  _setOperatorIndex(index) {
    index = index || 0
    const record = this.matcherStore.getAt(index)
    const set = record && this.matcherOperator != null

    if (set) {
      this.matcherOperator.setValue(record.get('matcher'))
    }

    return set
  },

  getMatcherKey() {
    return this.matcherOperator && this.matcherOperator.valueField
  },

  getMatcherStoreIndex() {
    let index
    const matcherKey = this.getMatcherKey()

    if (matcherKey) {
      index = this.matcherStore.findBy((matcherRecord) => {
        const matcher = matcherRecord.get(matcherKey)
        return matcher === this.matcher
      }, this)
    }

    if (index < 0) return 0

    return index
  },

  updateValue(options = {}) {
    const index = this.getMatcherStoreIndex()
    const record = this.matcherStore.getAt(index)

    // this is a bit complicated but ensures that always a combo box entry is selected
    // when matcher value has been updated when operator changes.
    // when setting operator index didn't occur, then call selectMatcher() directly otherwise
    // leave it to the select event of the matcher operator, calling selectMatcher() asynchronously.
    if (this._setOperatorIndex(index)) {
      return this.matcherOperator.fireEvent('select', this, record, index, true, options)
    }

    return this.selectMatcher(null, record, index, true, options)
  },

  getListStore(listType) {
    this.listStores = this.listStores || []

    // because list type can be an array (i.E. ListTypes.RULE_ADDRESS_OR_CONTENT), give them special treatment
    // see http://youtrack.smxemail.com/issue/SCL-1419
    if (Array.isArray(listType)) {
      const storeWithMultipleTypes = new ListStore()

      listType.forEach((singleListType) => {
        // copy records of each list store into a grouped store for multiple types
        return storeWithMultipleTypes.add(this.getListStore(singleListType).getRange())
      })

      storeWithMultipleTypes.sort('name', 'ASC')

      return storeWithMultipleTypes
    }

    if (!this.listStores[listType]) {
      this.listStores[listType] = new ListStore()
    }

    return this.listStores[listType]
  },

  getListItemsByDisplayName(displayName) {
    this.listStores = this.listStores || []

    let listItems = []

    this.listStores.find(function (listStore) {
      const index = listStore.findExact('displayName', displayName)
      const found = index > -1

      if (found) {
        const record = listStore.getAt(index)
        listItems = record.get('items').split('\n').sort()
      }

      return found
    })

    return listItems
  },

  toDsl() {
    let value = this.getValue()
    const customItem = this.getCustomItem()
    let dsl = customItem?.getDsl()

    if (!dsl) {
      if (this.matcherOperator) {
        dsl = this.matcherOperator.toDsl()
      } else {
        const matcher = this.getMatcher()
        dsl = matcher?.getDsl()
      }
    }

    if (dsl) {
      if (!Array.isArray(value)) {
        value = [value]
      }

      const sprintfArgs = value
      sprintfArgs.unshift(dsl)

      // like that we can dynamically insert {0} .. {n} values into a defined dsl
      dsl = String.format.apply(null, sprintfArgs)
    }

    return dsl
  },

  getValue(options = {}) {
    const matcher = this.getMatcher()

    if (matcher) {
      options.negated =
        options.negated != null ? options.negated || this.negated : undefined
      return matcher.getValue(options)
    }

    return this.matcherValue || ''
  },

  getCustomItem() {
    const matcher = this.getMatcher()

    if (!matcher) return

    return matcher.getCustomItem && matcher.getCustomItem()
  },

  getMatcher() {
    return this.findBy((n) => n instanceof Matcher)[0]
  },

  hasNotOneMatcher() {
    return this.matcherStore?.getCount() === !1
  },

  hasMoreThanOneMatcher() {
    return this.matcherStore?.getCount() > 1
  },

  rebuildOperator(record, reset) {
    const currentMatcher = this.getMatcher()
    const chosenMatcherClass = record.get('clazz')

    if (this.matcherStore && chosenMatcherClass) {
      if (this.hasMoreThanOneMatcher()) {
        this.add(this._createOperator())

        // automatically select first entry when condition changes, reset flag is set but only when
        // dsl has changed, otherwise leave as it
        if (currentMatcher && reset && dslIsDifferent(currentMatcher, record)) {
          this._setOperatorIndex(0)
        }
      } else {
        this._removeOperator()
      }
    }
  },

  getListItems(record, listIsCapability) {
    const matcherType = record.get('matcherType')

    if (listIsCapability) {
      const capabilities = getSmartRuleCapabilities(store.getState())
      return capabilities != null ? capabilities[matcherType] : undefined
    }

    return this.getListItemsByDisplayName(matcherType)
  },

  // be cautious, this function is sometimes called from other scopes, see i.E. TextItemMatcher :/
  // hence additional checks whether the function exists are required
  selectMatcher(combo, record, index, reset, options = {}) {
    let hiddenConditionsOptions
    const currentMatcher = this.getMatcher()
    const ChosenMatcherClass = record.get('clazz')
    const chosenMatcherOptions = record.get('options') || {}
    let { matcherValue } = this
    const matcherValueRaw = this.getValue({ raw: true })
    const forceDestroyCurrent = options.forceDestroyCurrent || false

    if (currentMatcher) {
      let regexChanged = false

      if (currentMatcher.isRegex != null) {
        if (chosenMatcherOptions.convertOrToRegex && hasOr(matcherValueRaw)) {
          regexChanged = false // leaving this if () condition unoptimized for readibility
        } else {
          regexChanged = currentMatcher.isRegex !== isRegex(matcherValueRaw)
        }
      }

      if (!regexChanged) {
        // when type and class is the same, reuse exactly same value for better ux
        if (reuseMatcherValue(currentMatcher, record)) {
          matcherValue = matcherValueRaw
        }
      }

      // fetch them first for the refreshConditions event further down
      // this before the matcher gets destroyed or changed
      hiddenConditionsOptions = currentMatcher.getHiddenConditionsOptions()

      // question whether a new matcher is required, this before destruction
      // and this will tell whether to exit or not
      const exit = skipNewMatcher(currentMatcher, record, forceDestroyCurrent)

      if (destroyCurrentMatcher(currentMatcher, record, forceDestroyCurrent)) {
        // don't use .destroy()! see http://youtrack.smxemail.com/issue/SCL-1386
        currentMatcher.removeAll(false)
        this.remove(currentMatcher)
      } else {
        // just fire an event for few other matchers to update but don't do anything further
        currentMatcher.fireEvent('matcherSelect', record)
      }

      delete this.currentMatcherOptions

      // under some conditions we exit here and do not build a new matcher
      if (exit) return
    }

    if (typeof this.rebuildOperator === 'function') {
      this.rebuildOperator(record, reset)
    }

    // not sure if @matcherOptions is still needed??
    this.currentMatcherOptions = Ext.apply(
      chosenMatcherOptions,
      this.matcherOptions || {}
    )

    const chosenMatcherType = record.get('matcherType')

    const matcherCfg = Ext.applyIf(
      {
        value: matcherValue,
        matcherType: chosenMatcherType,
        dsl: record.get('dsl'),
        customItems: record.get('customItems'),
        negated: record.get('negated'),
        valueType: this.valueType,
        matcher: this.defaultCfg.matcher,
        listStores: this.listStores,
        postLabel: this.initialConfig.postLabel,
        postLabelWidth: this.initialConfig.postLabelWidth
      },
      this.currentMatcherOptions
    )

    // various options can have an impact how to load items for lists
    // they can be a list defined under system capabilities or else
    // a (system) list the user can manage
    if (ChosenMatcherClass.requiresListItems) {
      let listItems = this.getListItems(
        record,
        this.currentMatcherOptions.listIsCapability
      )
      listItems = prettifyListItems(listItems, this.currentMatcherOptions)

      matcherCfg.listItemStore = new Ext.data.ArrayStore({
        fields: ['name'],
        data: listItems || []
      })
    } else if (chosenMatcherType) {
      matcherCfg.listStore = this.getListStore(chosenMatcherType)
    }

    const newMatcher = new ChosenMatcherClass(matcherCfg)

    this.add(newMatcher)

    if (hiddenConditionsOptions) {
      this.fireEvent('refreshConditions', hiddenConditionsOptions)
    }

    // just delegating further up
    newMatcher.on('refreshConditions', (options) => {
      this.fireEvent('refreshConditions', options)
    })

    newMatcher.fireEvent('matcherSelect', record)

    delete this.matcherValue

    this.matcher = record.get('matcher')

    if (this.rendered) {
      this.doLayout()
      return this.fireEvent('fixHeight', this)
    }

    this.on(
      'afterlayout',
      function () {
        this.fireEvent('fixHeight', this)
      },
      this,
      { single: true }
    )
  },

  getMatcherByName(matcher) {
    const index = this.matcherStore.findBy(
      (matcherRecord) => matcherRecord.get('matcher') === matcher,
      this
    )

    return this.matcherStore.getAt(index)
  },

  setMatcherType(matcher, matcherType) {
    const currentMatcher = this.getMatcher()
    const matcherRecord = this.getMatcherByName(matcher)

    matcherRecord.set('matcherType', matcherType)

    // if it is currently used, reload the list as well
    if (matcherRecord.get('matcher') === this.matcher && currentMatcher) {
      currentMatcher.matcherType = matcherType
      return typeof currentMatcher.setListStore === 'function'
        ? currentMatcher.setListStore(this.getListStore(matcherType))
        : undefined
    }
  },

  getHideConditions() {
    const matcher = this.getMatcher()

    if (!matcher) return

    return matcher.getHideConditions && matcher.getHideConditions()
  },

  fixHeight() {
    const matcher = this.getMatcher()

    if (matcher) {
      matcher.on('heightfix', this.fixHeight, this)
    }

    const h = this.items.getRange().reduce(function (memo, item) {
      const resizeEl = item?.getResizeEl()

      // circumventing an extjs bug: calling item.getHeight() directly can crash. it is possible
      // to have no resize element in it, hence the needed, additional if-check
      if (resizeEl) {
        return Math.max(resizeEl.getHeight(), memo)
      }

      return memo
    }, 0)

    this.setHeight(h)

    if (!this.ownerCt.minHeight) {
      this.ownerCt.minHeight = 0
    }

    this.ownerCt.setHeight(Math.max(this.ownerCt.minHeight, h + 4))
  },

  createMatcherStore(matchers = [], defaultDsl = null) {
    const matcherStore = new Ext.data.JsonStore({
      data: [],
      fields: ['matcher', 'name', 'clazz', 'dsl'],
      idProperty: 'matcher'
    })

    matchers.forEach(function (matcher) {
      // pass on default dsl from step to matcher when necessary
      if (defaultDsl && !matcher.dsl) {
        matcher.dsl = defaultDsl
      }

      const RecordType = matcherStore.recordType
      matcherStore.add(new RecordType(matcher))
    })

    return matcherStore
  },

  isHidden() {
    return this.hidden
  },

  // with cover() we hide and disable any inputs in this matcher combo
  // disable: because extjs skips validation of the disabled ones only (does not check visibility huh)
  cover() {
    this.hide()

    const items = this.findByType(Ext.form.Field)
    items.every((item) => item.setDisabled(true))

    const matcher = this.getMatcher()

    const hiddenConditionsOptions = matcher && matcher.getHiddenConditionsOptions()

    if (hiddenConditionsOptions) {
      this.fireEvent('refreshConditions', hiddenConditionsOptions)
    }
  },

  unCover() {
    this.show()

    const items = this.findByType(Ext.form.Field)
    items.every((item) => item.setDisabled(false))
  },

  skipLookAhead(stepDsl) {
    if (typeof this.currentMatcherOptions?.skipLookAhead !== 'undefined') {
      return this.currentMatcherOptions.skipLookAhead(stepDsl)
    }
  }
})

module.exports = MatcherCombo
