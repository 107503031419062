const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')

const StripAllAttachments = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      all: true,
      label: t('Strip all attachments'),
      type: 'smartrules-strip-all-attachments',
      nestable: true
    })
    this.callParent([cfg])
  },

  toDsl() {
    return 'strip all attachments'
  }
})

module.exports = StripAllAttachments
