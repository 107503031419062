import Ext from 'ext'
import React from 'react'
import ReduxContainerPanel from 'admin/view/ReduxContainerPanel'
import TabsClass from 'sharedservices/provider/views/Tabs'
import { hasModalDialog } from 'sharedservices/provider/subs'
import { setUri } from 'sharedservices/provider/actions'
import t from 'translate'

const SharedServicesTabPanel = Ext.define(null, {
  extend: ReduxContainerPanel,

  title: t('Shared Services'),
  cls: 'shared-services-panel',
  tabCls: 'shared-services-tab',

  initComponent: function () {
    // do not initialise app state when hot reloading for local development
    if (!this.initialConfig.hotReloading) {
      // synchronous db initialisation here, set uri for company key so that
      // we don't have to painfully pass uri along to any react component
      this.store.dispatch(setUri(this.initialConfig.companyKey, this.initialConfig.uri))
    }

    this.component = React.createElement(TabsClass, this.initialConfig)

    this.superclass().initComponent.call(this)
  },

  preventClose: function () {
    return hasModalDialog(this.store.getState(), this.companyKey)
  }
})

export default SharedServicesTabPanel
