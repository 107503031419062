import React, { Component } from 'react'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Field from 'components/form/Field'
import Grid from 'components/Grid'
import Help from 'components/Help'
import Mask from 'components/Mask'
import MembershipList from 'membership/views/List'
import PropTypes from 'prop-types'
import {
  availableTriggersWithLabels,
  formDisabled,
  loading,
  names,
  quarantineSetForm
} from 'quarantine/quarantine_sets/subs'
import { Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { change, reduxForm, touch } from 'redux-form'
import t from 'translate'
import _s from 'underscore.string'

export const FORM_NAME = 'quarantineSetForm'

const validateTriggers = (value) => {
  if (!value || value.length < 1) {
    return 'INVALID_TRIGGERS'
  }
}

const validateTargets = (value) => {
  if (!value || value.length < 1) {
    return 'INVALID_TARGETS'
  }
}

class QuarantineSetDialog extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired,
    quarantineSet: PropTypes.object,
    submitButtonText: PropTypes.string,
    targets: PropTypes.object,
    names: PropTypes.array
  }

  validateName = (value) => {
    const trimmedValue = value.trim()
    const names = this.props.names
    const initialName = this.props.initialValues?.name

    if (_s.isBlank(trimmedValue)) {
      return t("White spaces aren't allowed.")
    } else if (trimmedValue.length > 100) {
      return t('Max length is 100 characters.')
    } else if (names && names.filter((name) => name !== initialName).includes(value)) {
      return t('This name already exists.')
    }
  }

  disableSubmit() {
    // for their meanings see http://redux-form.com/6.8.0/docs/api/Props.md/
    return this.props.pristine || this.props.invalid || this.props.disabled
  }

  values() {
    const registeredFields = this.props.quarantineSetForm.registeredFields
    const registeredValues = this.props.quarantineSetForm.values

    // reason for this is to set `false` for the private checkbox when it's
    // not in the map of values (weird redux-form behavior and our api
    // won't interpret missing fields as false)
    // todo remove that once this github issue is resolved:
    // https://github.com/erikras/redux-form/issues/3617

    return Object.keys(registeredFields).reduce((values, k) => {
      values[k] = k in registeredValues ? registeredValues[k] : false

      return values
    }, {})
  }

  getAllNamesOfEnabledTargets() {
    return this.props.targets && this.props.targets.getAllNamesEnabled()
  }

  onTargetsChange = () => {
    // unfortunately we will have to set this one form value
    // manually since it manages states inside without changing app db
    // furthermore it has to be sorted otherwise we aren't able to compute pristine
    // state, see SCL-2892
    this.props.changeTargets(this.getAllNamesOfEnabledTargets().sort())
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.onSubmit(this.values())
  }

  renderAvailableTriggers() {
    return (
      <div>
        <Field
          required
          inline
          name='triggers'
          // so that it can be tested with a custom store
          store={this.props.store}
          disabled={this.props.disabled}
          label={t('Type')}
          type='checkbox'
          items={this.props.availableTriggersWithLabels}
          validate={validateTriggers}
          // must store in defined order to ensure validation state
          // see https://youtrack.smxemail.com/issue/SCL-2856
          normalize={(value) => value.sort()}
        />
        <Help>{t('Select message classification type(s) for this policy')}</Help>
      </div>
    )
  }

  render() {
    const dialogClassName =
      this.props.submitButtonText && this.props.submitButtonText.toLowerCase()

    return (
      <Mask>
        <Modal.Dialog className={dialogClassName}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Grid>
                <Field
                  required
                  autoFocus
                  name='name'
                  id='quarantine-set-name'
                  autoComplete='off'
                  // so that it can be tested with a custom store
                  store={this.props.store}
                  disabled={this.props.disabled}
                  validate={this.validateName}
                  label={t('Name')}
                  type='text'
                  tabIndex={0}
                  help={t('Enter a unique name for this policy')}
                />
                {this.props.availableTriggersWithLabels && this.renderAvailableTriggers()}
                <Field
                  required
                  name='targets'
                  disabled={this.props.disabled}
                  validate={validateTargets}
                  label={t('Targets')}
                  store={this.props.store}
                  componentClass={MembershipList}
                  input={{
                    collapsible: true,
                    membership: this.props.targets,
                    disabled: this.props.disabled,
                    onChange: this.onTargetsChange,
                    value: this.getAllNamesOfEnabledTargets()
                  }}
                />
              </Grid>
            </Modal.Body>

            <Modal.Footer>
              <Grid>
                <CancelSubmitButtons
                  disableCancel={this.props.disabled}
                  disableSubmit={this.disableSubmit()}
                  onCancel={this.props.onCancel}
                  submitText={this.props.submitButtonText}
                />
              </Grid>
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, props) => ({
  quarantineSetForm: quarantineSetForm(db),
  disabled: formDisabled(db, props.companyKey),
  initialValues: props.quarantineSet,
  loading: loading(db, props.companyKey),
  availableTriggersWithLabels: availableTriggersWithLabels(db, props.companyKey),
  names: names(db, props.companyKey)
})

const mapActionsToProps = (dispatch) => ({
  changeTargets: (members) => {
    dispatch(change(FORM_NAME, 'targets', members))
    dispatch(touch(FORM_NAME, 'targets'))
  }
})

const QuarantineSetForm = reduxForm({ form: FORM_NAME })(QuarantineSetDialog)

export default connect(mapDbToProps, mapActionsToProps)(QuarantineSetForm)
