const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const TrustedIpDetailPanel = require('admin/view/TrustedIpDetailPanel')
const TrustedIpNewPanel = require('admin/view/TrustedIpNewPanel')
const TrustedIpListPanel = require('admin/view/TrustedIpListPanel')
const TrustedIpRecord = require('admin/data/TrustedIpRecord')

const TrustedIpResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.TrustedIp',
  translations: {
    title: t('Mail Servers'),
    plural: t('Mail Servers'),
    new: t('New Mail Server'),
    open: t('Open Mail Server'),
    list: t('List Mail Servers'),
    remove: t('Remove Mail Server')
  },
  name: t('Mail Servers'),
  code: 'TRUSTED_IP',
  resourceId: 'TRUSTED_IPS',
  resourceName: 'trustedips',

  searchable: true,

  operations: {
    create: true,
    open: true,
    remove: true,
    list: true,
    save: true
  },

  layout: {
    newResource: {
      width: 400,
      height: 109
    },
    openResource: {
      width: 400,
      height: 109,
      resizable: false
    }
  },

  detailPanel: TrustedIpDetailPanel,
  newPanel: TrustedIpNewPanel,
  listPanel: TrustedIpListPanel,

  record: TrustedIpRecord
})

module.exports = TrustedIpResource
