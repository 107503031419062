const Ext = require('ext')
const CompanyNewPanel = require('admin/view/CompanyNewPanel')

const ResellerNewPanel = Ext.define(null, {
  extend: CompanyNewPanel,

  module() {
    return require('admin/business/ResellerResource')
  }
})

module.exports = ResellerNewPanel
