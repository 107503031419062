import React, { Component } from 'react'

import PropTypes from 'prop-types'

// todo: consider replacing parts of this code with `require('react-select')`
// we are already using for the timezone input.

class AutoCompleteInput extends Component {
  static propTypes = {
    displayValue: PropTypes.string,
    placeholder: PropTypes.string,
    suggestions: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
  }

  static defaultProps = {
    displayValue: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedSuggestion: null,
      showSuggestions: false,
      mouseOverSuggestions: false
    }
  }

  handleChange = (e) => this.setValue(e.target.value)

  setValue = (v) => {
    this.setState({
      selectedSuggestion: null,
      showSuggestions: true
    })

    // Fake an event object. This because props are immutable.
    // We are setting the state of parent components through callbacks defined in the parent.
    // Todo: get rid of this two-way event handling, that's not react
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: v } })
    }
  }

  getSearchTerms = () => this.getDisplayValue().split(/[\s]+/)

  addSearchTerm = (searchTerm) => {
    const searchTerms = this.getSearchTerms()
    searchTerms[searchTerms.length - 1] = searchTerm

    this.setValue(searchTerms.join(' '))
  }

  handleKeyDown = (e) => {
    if (this.props.suggestions?.length) {
      let selectedSuggestion
      switch (e.keyCode) {
        case 9: // Tab
          if (this.isShowingSuggestions()) {
            this.addSearchTerm(this.props.suggestions[0])
            e.preventDefault()
          }
          break
        case 38: // Up
          if (this.state.selectedSuggestion === null) {
            selectedSuggestion = this.props.suggestions.length - 1
          } else if (this.state.selectedSuggestion === 0) {
            selectedSuggestion = null
          } else {
            selectedSuggestion = this.state.selectedSuggestion - 1
          }

          this.setState({
            showSuggestions: true,
            selectedSuggestion
          })

          e.preventDefault()
          break
        case 40: // Down
          if (this.state.selectedSuggestion === null) {
            selectedSuggestion = 0
          } else if (
            this.state.selectedSuggestion ===
            this.props.suggestions.length - 1
          ) {
            selectedSuggestion = null
          } else {
            selectedSuggestion = this.state.selectedSuggestion + 1
          }

          this.setState({
            showSuggestions: true,
            selectedSuggestion
          })

          e.preventDefault()
          break
        case 27: // Escape
          if (this.isShowingSuggestions()) {
            this.setState({
              selectedSuggestion: null,
              showSuggestions: false
            })

            e.preventDefault()
          }
          break

        case 13: // Enter
          if (this.state.selectedSuggestion === null) {
            this.setState({ showSuggestions: false })
          } else {
            this.addSearchTerm(this.props.suggestions[this.state.selectedSuggestion])
            e.preventDefault()
          }
      }
    }
  }

  handleFocus = () => this.setState({ showSuggestions: true })

  handleBlur = () => {
    // Don't hide suggestions if the mouse is over, a click might be coming.
    // We need to keep track of hover state to do this.
    if (!this.state.mouseOverSuggestions) {
      this.setState({ showSuggestions: false })
    }
  }

  handleMouseEnterSuggestions = () => this.setState({ mouseOverSuggestions: true })

  handleMouseLeaveSuggestions = () => this.setState({ mouseOverSuggestions: false })

  isShowingSuggestions = () =>
    this.state.showSuggestions && this.props.suggestions && this.props.suggestions.length

  getDisplayValue = () => this.props.displayValue

  getShadowValue = () => {
    if (this.isShowingSuggestions()) {
      return this.props.suggestions[0]
    }

    return ''
  }

  renderSuggestion = (suggestion, index) => {
    const selected =
      index === this.state.selectedSuggestion ? 'autocomplete-suggestion-selected' : ''
    return (
      <div
        className={`autocomplete-suggestion ${selected}`}
        onClick={() => this.setValue(suggestion)}
        key={index}
      >
        {suggestion}
      </div>
    )
  }

  render() {
    return (
      <div className='autocomplete-container'>
        <input
          type='text'
          className='autocomplete-input'
          value={this.getDisplayValue()}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={this.props.placeholder}
        />
        <input
          disabled
          type='text'
          className='autocomplete-shadow'
          value={this.getShadowValue()}
        />
        <div
          className='autocomplete-suggestions'
          style={{ display: this.isShowingSuggestions() ? 'block' : 'none' }}
          onMouseEnter={this.handleMouseEnterSuggestions}
          onMouseLeave={this.handleMouseLeaveSuggestions}
        >
          {this.props.suggestions && this.props.suggestions.map(this.renderSuggestion)}
        </div>
      </div>
    )
  }
}

export default AutoCompleteInput
