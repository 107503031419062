const Ext = require('ext')
const t = require('translate')
const ListTypes = require('smartrules/ListTypes')
const Matcher = require('smartrules/designer/Matcher')
const Operator = require('smartrules/designer/Operator')
const MatcherCombo = require('smartrules/designer/MatcherCombo')
const ListMatcher = require('smartrules/designer/matchers/ListMatcher')
const PatternMatcher = require('smartrules/designer/matchers/PatternMatcher')
const RegexpMatcher = require('smartrules/designer/matchers/RegexpMatcher')

const ContentMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg = {}) {
    this.defaultCfg = Ext.applyIf(cfg, {
      cls: 'content-matcher',
      matcherValue: cfg.value
    })

    this.store = new Ext.data.JsonStore({
      data: [],
      fields: ['matcher', 'name', 'clazz', 'matcherType'],
      idProperty: 'matcher'
    })

    this.regenerateStore()

    this.operator = new Operator({
      matcherWidth: this.defaultCfg.matcherWidth,
      scope: this,
      selectMatcher: this.selectMatcher,
      store: this.store
    })

    this.defaultCfg.items = [{ width: 4, xtype: 'spacer' }, this.operator]
    this.callParent([this.defaultCfg])
    this.on('matcherSelect', this.regenerateStore, this)
    if (this.store.getCount() !== 0) {
      return this.updateValue()
    }
  },

  getValue(options) {
    if (options == null) {
      options = {}
    }
    const matcher = this.getMatcher()

    return matcher != null ? matcher.getValue(options) : undefined
  },

  getListStore: MatcherCombo.prototype.getListStore,

  getMatcher: MatcherCombo.prototype.getMatcher,

  regenerateStore(record) {
    // leave it here to avoid circular dependencies
    const TextMatcher = require('smartrules/designer/matchers/TextMatcher')

    const matchers = [
      {
        matcher: 'text',
        name: t('text'),
        clazz: TextMatcher,
        matcherType: 'TEXT'
      },
      {
        matcher: 'list',
        name: t('item in list'),
        clazz: ListMatcher,
        matcherType: ListTypes.RULE_CONTENT
      },
      {
        matcher: 'list-system',
        name: t('item in system list'),
        clazz: ListMatcher,
        matcherType: ListTypes.RULE_CONTENT_SYSTEM
      }
    ]

    if ((record != null ? record.get('matcher') : undefined) !== 'is') {
      matchers.push(
        {
          matcher: 'pattern',
          name: t('pattern'),
          clazz: PatternMatcher,
          matcherType: 'PATTERN'
        },
        {
          matcher: 'regexp',
          name: t('regex'),
          clazz: RegexpMatcher,
          matcherType: 'REGEXP'
        }
      )
    } else {
      const operatorValue = this.operator.getValue()
      if (operatorValue === 'pattern' || operatorValue === 'regexp') {
        this.valueType = 'text'
        this.updateValue()
      }
    }

    this.store.removeAll()
    return matchers.forEach(function (matcher) {
      const RecordTypeClass = this.store.recordType
      return this.store.add(new RecordTypeClass(matcher))
    }, this)
  },

  selectMatcher: MatcherCombo.prototype.selectMatcher,

  hasTextMatcher() {
    return true
  },

  updateValue() {
    let index = this.store.find(this.operator.valueField, this.valueType)
    if (index < 0) {
      index = 0
    }
    const record = this.store.getAt(index)
    if (record) {
      this.operator.fireEvent('select', this, record, index)
      const matcher = record.get('matcher')
      return this.operator.setValue(matcher)
    }
  }
})

module.exports = ContentMatcher
