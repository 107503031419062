import React, { Component } from 'react'

import * as Calendar from 'admin/time/Calendar'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import t from 'translate'

// boo :(
const LUXON_DATE_FORMAT = 'yyyy/MM/dd'
const REACT_PICKER_DAY_FORMAT = 'YYYY/MM/DD'

const formatDate = (dateObject) => {
  // used for displaying the selected date as value of the input field
  return DateTime.fromJSDate(dateObject).toFormat(LUXON_DATE_FORMAT)
}

const parseDate = (anything) => {
  // used for parsing the string typed in the input field
  const parsed = DateTime.fromFormat(anything, LUXON_DATE_FORMAT)

  if (!parsed.isValid) return

  return parsed.toJSDate()
}

function Navbar({ onPreviousClick, onNextClick, className }) {
  return (
    <div className={className}>
      <button
        className='previous btn btn-default'
        onClick={(e) => {
          e.preventDefault()
          onPreviousClick()
        }}
      >
        «
      </button>
      <button
        className='next btn btn-default'
        onClick={(e) => {
          e.preventDefault()
          onNextClick()
        }}
      >
        »
      </button>
    </div>
  )
}

class DatePickerInputComponent extends Component {
  render() {
    const { valid, className, ...otherProps } = this.props
    return (
      <div className={classNames('required', !valid ? 'has-error' : null)}>
        <input
          required
          type='text'
          className={classNames('form-control', className)}
          placeholder={this.props.format}
          {...otherProps}
        />
      </div>
    )
  }
}

const isValid = (date) => {
  if (!date) return false
  if (!(date instanceof Date)) return false
  if (isNaN(date)) return false

  return true
}

const validateAndFormat = (date, modifiers = {}) => {
  if (!isValid(date)) return null

  // modifiers.disabled becomes true when a date outside
  // our range has been chosen
  if (modifiers.disabled) return null

  // midnight cos we don't want the hours and minutes
  // when it comes to dates only
  return Calendar.atMidnight(date)
}

class DateRange extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onShow: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    fromDate: PropTypes.instanceOf(Date),
    thruDate: PropTypes.instanceOf(Date)
  }

  static defaultProps = {
    fromDate: Calendar.atMidnight().minus({ months: 1 }).toUTC().toJSDate(),
    thruDate: Calendar.atMidnight().toUTC().toJSDate(),

    // picking the next month when SMTP was published, see SCL-1209
    startDate: Calendar.atMidnight(new Date('1981-11-01')).toUTC().toJSDate(),
    endDate: Calendar.atMidnight().toUTC().toJSDate()
  }

  constructor(props) {
    super(props)

    this.state = {
      fromDayValid: true,
      thruDayValid: true,

      fromDateShown: false,
      thruDateShown: false
    }
  }

  // do not set when invalid and revert
  // TODO do not clear input when invalid
  onFromDayChange = (fromDate, modifiers) => {
    if (!isValid(this.props.thruDate)) return

    const formattedFromDate = validateAndFormat(fromDate, modifiers)
    const valid = !!formattedFromDate

    this.setState({ fromDayValid: valid })

    if (!valid) return

    this.props.onChange(formattedFromDate, this.props.thruDate)
  }

  // do not set when invalid and revert
  // TODO do not clear input when invalid
  onThruDayChange = (thruDate, modifiers) => {
    if (!isValid(this.props.fromDate)) return

    const formattedThruDate = validateAndFormat(thruDate, modifiers)
    const valid = !!formattedThruDate

    this.setState({ thruDayValid: valid })

    if (!valid) return

    this.props.onChange(this.props.fromDate, formattedThruDate)
  }

  onVisibilityChange = () => {
    if (this.state.fromDateShown || this.state.thruDateShown) {
      this.props.onShow()
    } else {
      this.props.onHide()
    }
  }

  onFromDatePickerShow = () => {
    this.setState(() => {
      return { fromDateShown: true, fromDayValid: isValid(this.props.fromDate) }
    }, this.onVisibilityChange)
  }

  onFromDatePickerHide = () => {
    this.setState(() => {
      return { fromDateShown: false, fromDayValid: isValid(this.props.fromDate) }
    }, this.onVisibilityChange)
  }

  onThruDatePickerShow = () => {
    this.setState(() => {
      return { thruDateShown: true, thruDayValid: isValid(this.props.thruDate) }
    }, this.onVisibilityChange)
  }

  onThruDatePickerHide = () => {
    this.setState(() => {
      return { thruDateShown: false, thruDayValid: isValid(this.props.thruDate) }
    }, this.onVisibilityChange)
  }

  render() {
    const selectedDays = { from: this.props.fromDate, to: this.props.thruDate }
    const modifiers = { start: this.props.fromDate, end: this.props.thruDate }

    return (
      <div className='input-daterange input-group'>
        <DayPickerInput
          // showOverlay // useful for when styling
          value={this.props.fromDate}
          formatDate={formatDate}
          parseDate={parseDate}
          format={REACT_PICKER_DAY_FORMAT}
          onDayChange={this.onFromDayChange}
          onDayPickerShow={this.onFromDatePickerShow}
          onDayPickerHide={this.onFromDatePickerHide}
          inputProps={{
            placeholder: REACT_PICKER_DAY_FORMAT,
            valid: this.state.fromDayValid,
            className: 'fromDate'
          }}
          component={DatePickerInputComponent}
          dayPickerProps={{
            showOutsideDays: true,
            selectedDays,
            modifiers,
            navbarElement: <Navbar />,
            disabledDays: {
              before: this.props.startDate,
              after: this.props.thruDate || this.props.endDate
            }
          }}
        />
        <span className='input-group-addon'>{t('to')}</span>
        <DayPickerInput
          // showOverlay // useful for when styling
          value={this.props.thruDate}
          formatDate={formatDate}
          parseDate={parseDate}
          format={REACT_PICKER_DAY_FORMAT}
          onDayChange={this.onThruDayChange}
          onDayPickerShow={this.onThruDatePickerShow}
          onDayPickerHide={this.onThruDatePickerHide}
          inputProps={{
            placeholder: REACT_PICKER_DAY_FORMAT,
            valid: this.state.thruDayValid,
            className: 'thruDate'
          }}
          component={DatePickerInputComponent}
          dayPickerProps={{
            showOutsideDays: true,
            selectedDays,
            modifiers,
            navbarElement: <Navbar />,
            disabledDays: {
              before: this.props.fromDate || this.props.startDate,
              after: this.props.endDate
            }
          }}
        />
      </div>
    )
  }
}

export default DateRange
