import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Item from './Item'

class MemberList extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    members: PropTypes.array,
    disabled: PropTypes.bool,
    onMemberCheck: PropTypes.func.isRequired
  }

  render() {
    return (
      <ul>
        {this.props.members.map((member) => {
          return (
            <Item
              disabled={this.props.disabled}
              key={this.props.type.concat('-').concat(member.name)}
              name={member.name}
              enabled={Boolean(member.enabled)}
              onChange={this.props.onMemberCheck}
            />
          )
        })}
      </ul>
    )
  }
}

export default MemberList
