const Record = require('admin/core/Record')
const ScrubbingDomainResource = require('admin/business/ScrubbingDomainResource')

const ScrubbingDomainRecord = Record.define(null, {
  module() {
    return ScrubbingDomainResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'relayTo',
      type: 'string'
    },
    {
      name: 'approvalStatus',
      type: 'string'
    }
  ],

  // since https://youtrack.smxemail.com/issue/SCL-2422
  isPersistentOnSave() {
    return true
  },

  getName() {
    return this.get('name')
  },

  getDescription() {
    return this.get('name')
  },

  getParams() {
    const params = {
      name: this.get('name'),
      relayTo: this.get('relayTo')
    }
    if (!this.phantom) {
      params.approvalStatus = this.get('approvalStatus')
    }
    return params
  }
})

module.exports = ScrubbingDomainRecord
