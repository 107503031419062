import React, { Component } from 'react'

import FormGroup from 'components/form/FormGroup'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import t from 'translate'
import validators from 'validators'

class FieldRow extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    componentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    required: PropTypes.bool,
    items: PropTypes.object
  }

  isRequired = (value) => (value ? undefined : 'Required')

  isEmail = (value) => {
    if (!this.props.required && (value === undefined || value === '')) return

    if (this.props.multiple) {
      const emails = validators.linebrokenEmails(value)

      if (!emails) {
        return t('One email per line accepted.')
      }
    } else if (!validators.email(value)) {
      return t('Invalid email format.')
    }
  }

  // wrap it around redux-form's <field> before the above bootstrap-form stuff
  // for some built-in state management and functions
  render() {
    const validate = []

    // beware that order of validators is crucial

    if (this.props.required) {
      validate.push(this.isRequired)
    }

    if (this.props.type === 'email') {
      validate.push(this.isEmail)
    }

    if (this.props.validate) {
      validate.push(this.props.validate)
    }

    return (
      <Row>
        <Field {...this.props} validate={validate} component={FormGroup}>
          {this.props.children}
        </Field>
      </Row>
    )
  }
}

export default FieldRow
