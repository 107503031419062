import * as hal from 'hal'

import Api from 'api'
import { MAILHOSTING_CATALOGUE_REL } from 'product/resources'
import logger from 'system/logger'

export const MAILHOSTING_CATALOGUE_START_LOADING = 'MAILHOSTING_CATALOGUE_START_LOADING'
export const MAILHOSTING_CATALOGUE_STOP_LOADING = 'MAILHOSTING_CATALOGUE_STOP_LOADING'
export const MAILHOSTING_CATALOGUE_SET_LIST = 'MAILHOSTING_CATALOGUE_SET_LIST'
export const MAILHOSTING_CATALOGUE_INIT_DB = 'MAILHOSTING_CATALOGUE_INIT_DB'
export const MAILHOSTING_CATALOGUE_SET_CUSTOMER_URL =
  'MAILHOSTING_CATALOGUE_SET_CUSTOMER_URL'

const startLoading = (companyKey) => ({
  type: MAILHOSTING_CATALOGUE_START_LOADING,
  companyKey
})

const stopLoading = (companyKey) => ({
  type: MAILHOSTING_CATALOGUE_STOP_LOADING,
  companyKey
})

function processError(err) {
  logger.error(err)
}

/// ========= INIT ========= ///

const setCustomerUrl = (companyKey, customerUrl) => ({
  type: MAILHOSTING_CATALOGUE_SET_CUSTOMER_URL,
  companyKey,
  customerUrl
})

const initDb = (companyKey) => ({
  type: MAILHOSTING_CATALOGUE_INIT_DB,
  companyKey
})

export const init = (companyKey, customerUrl) => (dispatch) => {
  dispatch(initDb(companyKey))
  dispatch(setCustomerUrl(companyKey, customerUrl))
}

/// ========= LIST SET/REFRESH ========= ///

const setList = (companyKey, list) => ({
  type: MAILHOSTING_CATALOGUE_SET_LIST,
  companyKey,
  list
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)

    dispatch(setList(companyKey, hal.resource(response.data, MAILHOSTING_CATALOGUE_REL)))
  } catch (err) {
    processError(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}
