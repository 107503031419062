const Ext = require('ext/ext-base')

/**
@override Ext.layout.MenuLayout
*/
Ext.override(Ext.layout.MenuLayout, {
  /**
  Override to render div tags instead of img tags for the menu icons.
  */
  renderItem(c, position, target) {
    if (!this.itemTpl) {
      this.itemTpl = Ext.layout.MenuLayout.prototype.itemTpl = new Ext.XTemplate(
        '<li id="{itemId}" class="{itemCls}">',
        '<tpl if="needsIcon">',
        '<div class="{iconCls}"></div>',
        '</tpl>',
        '</li>'
      )
    }

    if (c && !c.rendered) {
      if (Ext.isNumber(position)) {
        position = target.dom.childNodes[position]
      }
      const a = this.getItemArgs(c)

      c.render(
        (c.positionEl = position
          ? this.itemTpl.insertBefore(position, a, true)
          : this.itemTpl.append(target, a, true))
      )

      c.positionEl.menuItemId = c.getItemId()

      if (!a.isMenuItem && a.needsIcon) {
        c.positionEl.addClass('x-menu-list-item-indent')
      }

      return this.configureItem(c)
    } else if (c && !this.isValidParent(c, target)) {
      if (Ext.isNumber(position)) {
        position = target.dom.childNodes[position]
      }
      return target.dom.insertBefore(c.getActionEl().dom, position || null)
    }
  }
})
