import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Row
} from 'react-bootstrap'
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

// deprecated since SCL-2244, use Field.es instead

const CR = '\n'

class FormInput extends Component {
  static proTypes = {
    validity: PropTypes.object, // allow custom validity objects from parent elements
    repeat: PropTypes.string, // todo: turn this into a bool
    groupClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    getValidity: PropTypes.func,
    getHelp: PropTypes.func,
    stretch: PropTypes.bool,
    controlClass: PropTypes.func,
    controlProps: PropTypes.object,
    // allow validity of input and required status to be passed
    onValidityChange: PropTypes.func
  }

  static defaultProps = {
    controlClass: FormControl
  }

  state = {
    value: null,
    validationState: null,
    help: null,
    validity: null
  }

  wrapRepeatedValue = () => this.props.value.join(CR)

  getValue = (e) => {
    let value

    if (e) {
      e.persist()
      value = e.target.value
    } else if (this.state.value !== null) {
      value = this.state.value
    } else if (this.props.value !== null) {
      if (this.props.repeat) {
        value = this.wrapRepeatedValue()
      } else {
        value = this.props.value
      }
    }

    return value
  }

  hasValue = (e) => !!this.getValue(e)

  getValidity = (e) => {
    if (e) {
      if (this.props.getValidity) {
        return this.props.getValidity(e)
      }

      return e.target.validity
    } else if (this.state.validity) {
      return this.state.validity
    }

    return this.props.validity
  }

  getValidationState = (e, validity) => {
    const hasValue = this.hasValue(e)

    if (!hasValue && this.props.required) {
      return 'warning' // shows the yellow star
    }

    if (validity === undefined) {
      validity = this.getValidity(e)
    }

    if (validity) {
      if (hasValue && !this.props.readOnly && !this.props.disabled) {
        if (validity.valid) {
          return 'success'
        }

        return 'error'
      }
    }

    return this.state.validationState
  }

  // todo: rewrite this, getting ugly with this logic
  getWrapperClassName = () => {
    const gridClasses = []

    if (this.props.wrapperClassName) {
      gridClasses.push(this.props.wrapperClassName)
    } else if (this.props.stretch) {
      // stretch = be wider and have a smaller on the sides (= less offset)
      gridClasses.push('col-xs-7')
    } else {
      // not stretch
      gridClasses.push('col-xs-8')
    }

    return classNames(
      gridClasses,
      this.props.required && !this.getValue() ? 'required' : undefined
    )
  }

  getLabelClassName = () => {
    if (this.props.stretch) {
      return 'col-xs-4'
    }

    return 'col-xs-2 col-xs-offset-1'
  }

  getHelp = (e, validity) => {
    // some input classes prefer to build the help text dynamically, i.E. EmailInput
    if (this.props.getHelp) {
      return this.props.getHelp(e, this.getValidity(e), this.getValue(e))
    } else if (this.state.help) {
      return this.state.help
    } else if (this.props.help) {
      return this.props.help
    } else if (validity?.error) {
      return validity.error
    }
  }

  getCurrentValidity = () => this.state.validity && this.state.validity.valid

  handleValueChange = (e) => {
    e.persist()

    const value = this.getValue(e)

    // call any onChange listeners if any were attached from the parent element
    if (typeof this.props.onChange === 'function') {
      return this.props.onChange(value)
    }

    // otherwise do the standard value change event handling
    const validity = this.getValidity(e)
    const validationState = this.getValidationState(e, validity)
    const validationHasChanged =
      this.getCurrentValidity() !== validity.valid ||
      this.state.validationState !== validationState

    if (this.props.onValidityChange && validationHasChanged) {
      this.props.onValidityChange(validity.valid && validationState !== 'warning')
    }

    return this.setState({
      validity,
      value,
      help: this.getHelp(e, validity, value),
      validationState
    })
  }

  getComponentClass = () => {
    switch (this.props.type) {
      case 'textarea':
        return this.props.type
      default:
        return 'input'
    }
  }

  renderControlLabel = () => {
    return (
      <Col componentClass={ControlLabel} className={this.getLabelClassName()}>
        {this.props.label}
      </Col>
    )
  }

  renderControl = () => {
    return React.createElement(
      this.props.controlClass,
      Object.assign(
        {
          ref: this.props.controlRef,
          name: this.props.name,
          componentClass: this.getComponentClass(),
          required: this.props.required,
          type: this.props.type,
          value: this.getValue(),
          onChange: this.handleValueChange,
          autoComplete: this.props.autoComplete,
          id: this.props.id,
          rows: this.props.rows,
          disabled: this.props.disabled
        },
        this.props.controlProps
      ),
      this.props.children // for when it's a select box
    )
  }

  render() {
    const help = this.getHelp()

    return (
      <Row>
        <FormGroup
          validationState={this.getValidationState()}
          className={this.props.groupClassName}
        >
          {this.renderControlLabel()}
          <Col className={this.getWrapperClassName()}>
            {this.renderControl()}
            <FormControl.Feedback />
            {help && <HelpBlock>{help}</HelpBlock>}
          </Col>
        </FormGroup>
      </Row>
    )
  }
}

export default React.forwardRef((props, ref) => <FormInput controlRef={ref} {...props} />)
