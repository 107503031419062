const t = require('translate')
const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const DomainSummaryResultRecord = require('admin/data/DomainSummaryResultRecord')

const DomainSummaryResultStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: '',
  sortInfo: { field: 'cleanSize', direction: 'DESC' },
  hideError: true,

  record: DomainSummaryResultRecord,

  constructor() {
    this.callParent(arguments)

    this.on(
      'loadexception',
      function (proxy, options, response) {
        // Do another search.
        if (response.status === 401) {
          return this.search()
        }
      },
      this
    )

    return this.on('load', () => {
      // For now we manually filter out domains when all counts
      // together are zero until backend does this for us in a future ticket.
      //
      // See https://youtrack.smxemail.com/issue/SCL-3117
      return this.filterBy(
        (
          domain // filters out when false (all counts are zeroes)
        ) =>
          !(
            domain.get('allowPolicyCount') === 0 &&
            domain.get('cleanCount') === 0 &&
            domain.get('denyPolicyCount') === 0 &&
            domain.get('spamCount1') === 0 &&
            domain.get('threatCount') === 0
          )
      )
    })
  },

  getReader(recordType) {
    return new Ext.data.JsonReader(
      {
        root: 'results',
        totalProperty: 'totalCount',
        id: 'id'
      },
      recordType
    )
  },

  // Searching is a two step process.
  // First we need to POST to create a search resource that we can then GET.
  // This helper function removes the need for a separate search store.
  search() {
    return Ext.Ajax.request({
      method: 'POST',
      url: this.url,
      params: {
        direction: this.direction
      },
      success: (response) => {
        // Get the search url from the location header.
        this.url = response.getResponseHeader('Location').trim()
        return this.load()
      },
      failure() {
        return Ext.Msg.alert(
          t('Unavailable'),
          t('Mail summary is currently unavailable. Please try again later.')
        )
      }
    })
  }
})

module.exports = DomainSummaryResultStore
