const Ext = require('ext')

module.exports = Ext.define(null, {
  extend: Ext.BoxComponent,

  cls: 'provision-status',
  autoScroll: true,

  /* eslint-disable no-multi-str */
  tpl: new Ext.XTemplate(
    '\
<tpl for="."> \
<b>{name:htmlEncode}</b><br> \
<tpl if="values.items"><ul class="provision-status-list"><tpl for="items"> \
<li>{name:htmlEncode} \
<tpl if="values.items"><ul class="provision-status-list"><tpl for="items"> \
<li>{.:htmlEncode}</li> \
</tpl></ul></tpl> \
</li> \
</tpl></ul></tpl> \
</tpl>\
'
  )
})
