const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const PolicySetRecord = require('admin/data/PolicySetRecord')

const PolicySetStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'policysets',
  groupField: 'policySetType',
  sortInfo: {
    field: 'priority',
    direction: 'ASC'
  },
  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      {
        field: 'policySetType',
        direction: 'ASC'
      },
      {
        field: 'priority',
        direction: 'ASC'
      }
    ]
  },

  record: PolicySetRecord,

  module() {
    return require('admin/business/PolicyManagementResource')
  }
})

module.exports = PolicySetStore
