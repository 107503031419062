const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const NoteListPanel = require('admin/view/NoteListPanel')
const NoteNewPanel = require('admin/view/NoteNewPanel')
const NoteDetailPanel = require('admin/view/NoteDetailPanel')
const NoteRecord = require('admin/data/NoteRecord')
const VendorResource = require('admin/business/VendorResource')
const DistributorResource = require('admin/business/DistributorResource')
const ResellerResource = require('admin/business/ResellerResource')
const CustomerResource = require('admin/business/CustomerResource')

const NoteResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Note',
  translations: {
    title: t('Note'),
    plural: t('Notes'),
    new: t('New Note'),
    open: t('Open Note'),
    list: t('List Notes')
  },
  name: t('Note'),
  code: 'NOTE',
  resourceName: 'notes',
  ignoreExpired: true,

  layout: {
    newResource: {
      width: 520,
      height: 'auto'
    },
    openResource: {
      width: 520,
      height: 'auto',
      resizable: false
    }
  },

  operations: {
    create: true,
    save: true,
    open: true,
    remove: false,
    list: true
  },

  listPanel: NoteListPanel,
  newPanel: NoteNewPanel,
  detailPanel: NoteDetailPanel,

  record: NoteRecord,

  getParentResources() {
    return [VendorResource, DistributorResource, ResellerResource, CustomerResource]
  },

  // notes do not require permissions, so override the parent methods who do

  canOpen() {
    return this.operations.open
  },

  canList() {
    return this.operations.list
  },

  canCreate() {
    return this.operations.create
  },

  canSave() {
    return this.operations.save
  }
})

module.exports = NoteResource
