import { Col, Row } from 'react-bootstrap'
import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { list as catalogue } from 'mailhosting/catalogue/subs'
import { connect } from 'react-redux'
import t from 'translate'

class Hint extends PureComponent {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  getHintText = (entry) => {
    return t('{usedCount} of {totalCount} {description} mailboxes used.', {
      usedCount: entry.used,
      totalCount: entry.total,
      description: entry.description
    })
  }

  getClassName = (entry) => {
    if (entry.used === entry.total) {
      return 'full'
    }
  }

  renderEntry(entry) {
    return (
      <p key={entry.cosName} className={this.getClassName(entry)}>
        {this.getHintText(entry)}
      </p>
    )
  }

  render() {
    return (
      <div className='mailbox-hint'>
        <Row>
          <Col xs={12}>
            <div>
              {this.props.catalogue &&
                this.props.catalogue.map((entry) => this.renderEntry(entry))}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  catalogue: catalogue(db, companyKey)
})

export default connect(mapDbToProps)(Hint)
