const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const Time = require('admin/time/Time')
const User = require('admin/core/User')
const ResellerAgreementNewPanel = require('admin/view/ResellerAgreementNewPanel')
const WindowMgr = require('admin/desktop/WindowMgr').default

const ResellerAgreementListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  title: t('Product Catalog'),
  autoExpandColumn: 'productName',

  initComponent() {
    this.module = require('admin/business/ResellerAgreementResource')

    this.view = new Ext.grid.GroupingView({
      forceFit: true,
      hideGroupedColumn: true,
      enableGroupingMenu: false,
      showGroupName: false,
      startCollapsed: false
    })

    this.callParent()

    if (this.record != null) {
      // Enables menu items based on reseller status
      const catalogueStore = this.record.getCatalogueStore()
      catalogueStore.on(
        'load',
        function (store) {
          if (store.find('wholesale', true) !== -1) {
            this.createWholesaleAction?.enable()

            const createButton = this.getCreateButton()
            createButton?.setDisabled(false)
          }

          if (store.find('wholesale', false) !== -1) {
            this.createDirectAction?.enable()

            const createButton = this.getCreateButton()
            createButton?.setDisabled(false)
          }
        },

        this,
        { single: true }
      )
      return catalogueStore.load()
    }
  },

  createColumnModel() {
    const columns = [
      {
        id: 'status',
        header: t('Status'),
        dataIndex: 'status'
      },
      {
        id: 'agreementTypeName',
        header: t('Reseller Type'),
        width: 140,
        sortable: true,
        dataIndex: 'agreementTypeName'
      },
      {
        id: 'productName',
        header: t('Product Name'),
        width: 100,
        sortable: true,
        dataIndex: 'productName'
      },
      {
        id: 'fromDate',
        header: t('From Date'),
        width: 80,
        sortable: true,
        dataIndex: 'fromDate',
        renderer: Time.toDateString
      },
      {
        id: 'thruDate',
        header: t('Thru Date'),
        width: 80,
        sortable: true,
        dataIndex: 'thruDate',
        renderer: Time.toDateString
      }
    ]

    if (User.hasPermission('PRICE_COMPONENT', 'GET')) {
      columns.splice(3, 0, {
        id: 'pricingModel',
        header: t('Pricing Model'),
        width: 140,
        align: 'right',
        sortable: true,
        dataIndex: 'pricingModel'
      })
    }
    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }
    return new Ext.grid.ColumnModel(columns)
  },

  getToolbarItems() {
    return [
      this.getCreateButton(true),
      this.getOpenButton(),
      this.getRemoveButton(),
      '->',
      new Ext.form.Checkbox({
        boxLabel: t('All Historic'),
        disabled: false,
        handler: (checkbox, checked) => {
          this.store.baseParams = checked
            ? {}
            : require('admin/data/ResellerAgreementStore').prototype.baseParams
          return this.store.reload()
        }
      }),
      new Ext.Spacer({ width: 6 }),
      this.getRefreshButton()
    ]
  },

  getCreateButton(disabled = false) {
    if (!(this.store && this.store.canCreate())) return

    if (this.createButton) return this.createButton

    const resource = require('admin/business/ResellerAgreementResource')

    this.createWholesaleAction = new Ext.Action({
      text: t('New Wholesale Product'),
      disabled: true,
      handler: () => {
        const createName = t('New Wholesale Product')
        const windowId = `new.wholesale.product[${
          (this.record != null ? this.record.get('id') : undefined) || ''
        }]`

        if (!WindowMgr.select(windowId)) {
          const panel = new ResellerAgreementNewPanel({
            record: this.record,
            type: 'WHOLESALE',
            store: this.store
          })

          let title = createName
          if (this.record) {
            title += ` - ${this.record.getName()}`
          }

          const url = this.store.getUrl() + '/wholesale/create'

          return WindowMgr.create({
            id: windowId,
            title,
            url,
            taskbar: createName,
            width: resource.layout.newResource.width,
            autoHeight: resource.layout.newResource.height === 'auto',
            height: resource.layout.newResource.height,
            iconCls: 'new',
            shim: false,
            animCollapse: false,
            maximizable: false,
            resizable: false,
            items: panel,
            layout: 'fit',
            listeners: {
              show: {
                fn: panel.focus,
                scope: panel,
                delay: 100
              }
            }
          })
        }
      }
    })

    this.createDirectAction = new Ext.Action({
      text: t('New Direct Product'),
      disabled: true,
      handler: () => {
        const createName = t('New Direct Product')
        const windowId = `new.direct.product[${
          (this.record != null ? this.record.get('id') : undefined) || ''
        }]`

        if (!WindowMgr.select(windowId)) {
          const panel = new ResellerAgreementNewPanel({
            record: this.record,
            type: 'DIRECT',
            store: this.store
          })

          let title = createName
          if (this.record) {
            title += ` - ${this.record.getName()}`
          }

          const url = this.store.getUrl() + '/direct/create'

          return WindowMgr.create({
            id: windowId,
            title,
            url,
            taskbar: createName,
            width: resource.layout.newResource.width,
            autoHeight: resource.layout.newResource.height === 'auto',
            height: resource.layout.newResource.height,
            iconCls: 'new',
            shim: false,
            animCollapse: false,
            maximizable: false,
            resizable: false,
            items: panel,
            layout: 'fit',
            listeners: {
              show: {
                fn: panel.focus,
                scope: panel,
                delay: 100
              }
            }
          })
        }
      }
    })

    // Return pull-down combo
    this.createButton = new Ext.Action({
      id: `SMX.ResellerAgreement.Add_Product[${this.record.id}].menu`,
      text: t('Add'),
      disabled,
      menu: [this.createWholesaleAction, this.createDirectAction]
    })

    return this.createButton
  }
})

module.exports = ResellerAgreementListPanel
