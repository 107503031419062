const Ext = require('ext')
const t = require('translate')
const getVersion = require('system/getVersion').default
const Clipboard = require('clipboard')
const TaskButtonsPanel = require('admin/component/TaskButtonsPanel')
const User = require('admin/core/User')
const CompanyGroupPanel = require('admin/view/CompanyGroupPanel').default
const Messages = require('admin/core/Messages')
const KeyboardShortcut = require('admin/util/KeyboardShortcut')
const Search = require('admin/desktop/Search')
const { logoutQuietly } = require('system/logout')
const isDeveloping = require('system/isDeveloping').default
const store = require('store').default
const getPathSegment = require('url/getPathSegment').default
const { isCapableOfSubscriptions } = require('system/capability/subs')
const stripApi = require('url/stripApi').default
const WindowMgr = require('admin/desktop/WindowMgr').default
const _s = require('underscore.string')

const Desktop = Ext.define(null, {
  singleton: true,

  init() {
    const leftDesktopItems = []

    if (User.showSearchPanel()) {
      this.searchPanel = Search.createQuickSearchPanel()
      leftDesktopItems.push(this.searchPanel)
    }

    if (User.needExplorer()) {
      // todo move this up and rearrange code
      const Explorer = require('admin/desktop/Explorer')
      leftDesktopItems.push(Explorer.panel)
    }

    this.viewport = new Ext.Viewport({
      id: 'next-desktop-viewport',
      hidden: true,
      layout: 'border',
      items: [
        new Ext.Panel({
          id: 'next-desktop-header',
          region: 'north',
          height: 70,
          border: false,
          html: `\
<div id="portal-logo"></div>
<div id="next-desktop-launcher-wrap">
  <div id="next-desktop-launcher-inner">
    <div id="next-desktop-launcher-left">
      <div id="next-desktop-launcher-right">
        <div id="next-desktop-launcher-center"></div>
      </div>
    </div>
  </div>
</div>\
`
        }),
        (this.centerPanel = new Ext.Panel({
          autoScroll: true,
          id: 'next-desktop-center',
          layout: 'fit',
          region: 'center',
          border: false
        })),
        leftDesktopItems.length > 0 &&
          new Ext.Panel({
            id: 'next-desktop-left',
            title: false,
            region: 'west',
            collapsible: true,
            autohide: false,
            collapsed: false,
            floatable: false,
            // todo: this is questionable. examine whether this can be moved to the above if condition
            hidden: !User.needExplorer(),
            width: 290,
            minwidth: 290,
            border: true,
            layout: 'vbox',
            layoutConfig: {
              align: 'stretch'
            },
            items: leftDesktopItems
          }),

        new Ext.Panel({
          layout: 'hbox',
          layoutConfig: {
            align: 'stretch'
          },
          region: 'south',
          height: 34,
          border: false,
          items: [
            new TaskButtonsPanel({
              id: 'next-desktop-footer',
              region: 'south',
              border: false,
              windowGroup: this.windowGroup,
              flex: 1
            }),

            (() => {
              if (User.isVendor()) {
                let versionText = t('Version') + ' ' + getVersion()

                if (isDeveloping()) {
                  versionText += ' [DEV]'
                }

                return (this.versionButton = new Ext.Button({
                  text: versionText,
                  tooltip: t('Click to copy version number to clipboard.'),
                  id: 'version-info',
                  listeners: {
                    click: {
                      fn: () => {
                        return new Clipboard(this.versionButton.getEl().dom, {
                          text: () => this.versionButton.text
                        })
                      }
                    }
                  }
                }))
              }
            })()
          ]
        })
      ]
    })

    this.launcher = new Ext.Toolbar({
      id: 'next-desktop-launcher',
      renderTo: 'next-desktop-launcher-center',
      items: [
        // Support
        new Ext.Action({
          text: t('Support Query'),
          iconCls: 'new-SMX-Support',
          handler: () => this.openSupport()
        }),

        '-',

        // Profile
        new Ext.Action({
          text: t('My Profile'),
          iconCls: 'open-SMX-User',
          handler() {
            const UserResource = require('admin/business/UserResource')
            const record = User.getRecord()
            return UserResource.open(record)
          }
        }),

        '-',

        // Company
        (() => {
          if (User.isVendor()) {
            return new Ext.Action({
              text: t('Vendor'),
              iconCls: 'open-SMX-Vendor',
              handler() {
                return require('admin/business/VendorResource').open(User.getRootRecord())
              }
            })
          } else if (User.getCompanyType() === 'DISTRIBUTOR') {
            return (function () {
              const distributorLauncher = new Ext.Action({
                text: t('Distributor'),
                iconCls: 'open-SMX-Distributor',
                handler() {
                  return require('admin/business/DistributorResource').open(
                    User.getRootRecord()
                  )
                }
              })
              User.getRootRecord().on('sync', () =>
                distributorLauncher.setText(User.getRootName())
              )
              return distributorLauncher
            })()
          } else if (User.getCompanyType() === 'RESELLER') {
            return (function () {
              const resellerLauncher = new Ext.Action({
                text: t('Reseller'),
                iconCls: 'open-SMX-Reseller',
                handler() {
                  return require('admin/business/ResellerResource').open(
                    User.getRootRecord()
                  )
                }
              })
              User.getRootRecord().on('sync', () =>
                resellerLauncher.setText(User.getRootName())
              )
              return resellerLauncher
            })()
          }
        })(),

        '-',

        isCapableOfSubscriptions(store.getState()) &&
          new Ext.Action({
            text: t('My Subscriptions'),
            iconCls: 'list-SMX-Subscription',
            handler: () => this.openMySubscriptions()
          }),

        '-',

        new Ext.Action({
          text: t('Message Log'),
          iconCls: 'show-errors',
          handler() {
            return Messages.show()
          }
        }),

        '-',
        '->',

        // Logout
        new Ext.Action({
          text: t('Logout'),
          iconCls: 'next-logout',
          handler() {
            return logoutQuietly()
          }
        })
      ]
    })

    // Opens Customer detailPanel in centre of desktop
    if (User.belongsToACustomerCompany()) {
      User.getRootRecord().on(
        'sync',
        () => {
          this.centerPanel.add(
            new CompanyGroupPanel({
              record: User.getRootRecord()
            })
          )
          return this.centerPanel.doLayout()
        }, // mh: fix for http://youtrack.smxemail.com/issue/SCL-825
        this,
        { single: true }
      )
    }

    KeyboardShortcut.addShortcut({
      key: 's',
      ctrl: true,
      shift: true,
      handler: () => {
        if (!this.searchPanel) return
        const searchTextField = this.searchPanel.find('name', 'searchTextField')

        if (!searchTextField || !searchTextField[0]) return
        searchTextField.focus(true)
      }
    })

    this.viewport.show()
    return this.viewport.doLayout()
  },

  getCenterPanelWidth() {
    return this.centerPanel.getWidth()
  },

  getViewport() {
    return Ext.getBody()
  },

  getViewportEl() {
    return this.getViewport().dom
  },

  openSupport() {
    const SupportResource = require('admin/business/SupportResource')
    return SupportResource.create()
  },

  openMySubscriptions() {
    return require('reporting/Subscription').list(User.getRecord())
  },

  // shall return a record for the History class to be able
  // to extract its title for google analytics
  openUri(uri) {
    if (_s.startsWith(uri, '/system/support/create')) return this.openSupport()

    const subscriptionApi = User.getRecord().getSubscriptionStore().getUrl()
    if (stripApi(subscriptionApi) === uri) return this.openMySubscriptions()

    if (uri === Messages.getUrl()) return Messages.show()

    const searchWindow = Search.getWindow()
    if (uri === searchWindow?.getPath()) return searchWindow.show()
    if (getPathSegment(uri, -2) === 'search') {
      // automatically run a search then
      return Search.runSearch(getPathSegment(uri, -1))
    }

    const window = WindowMgr.getWindow(uri)
    if (window) {
      window.show()
      return window
    }
  }
})

module.exports = Desktop
