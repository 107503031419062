const Ext = require('ext')
const store = require('store').default
const ResourceStore = require('admin/data/ResourceStore')
const AgreementRecord = require('admin/data/AgreementRecord')
const AgreementResource = require('admin/business/AgreementResource')
const requestPromise = require('admin/util/Promise').default
const encodeForm = require('url/encodeForm').default

const refreshCatalogue = require('mailhosting/catalogue/actions').refresh

const AgreementStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'agreements',
  groupField: 'status',

  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      {
        field: 'status',
        direction: 'ASC'
      },
      {
        field: 'fromDate',
        direction: 'DESC'
      }
    ]
  },

  baseParams: {
    maxProductCount: '2'
  },

  record: AgreementRecord,

  module() {
    return AgreementResource
  },

  constructor() {
    this.callParent(arguments)

    this.on('add', function () {
      // Adding an agreement probably affects product tabs so reload the customer record.
      this.parentRecord.fetch()

      // New record is incomplete so just reload the whole store.
      this.reload()

      return this.refreshMailboxCatalogue()
    })

    this.on('remove', function () {
      return this.refreshMailboxCatalogue()
    })
    this.on('change', function () {
      return this.refreshMailboxCatalogue()
    })

    this.on('load', function (store, records) {
      let finalBillCancellationHeader, standardCancellationHeader
      if (this.linkTemplates != null) {
        const standardLink = this.linkTemplates.rel(
          'smx3:agreement/cancellation/standard'
        )[0]

        standardCancellationHeader = standardLink?.uri

        const finalbillLink = this.linkTemplates.rel(
          'smx3:agreement/cancellation/finalbill'
        )[0]

        finalBillCancellationHeader = finalbillLink?.uri
      }

      const cancellable = !!(standardCancellationHeader || finalBillCancellationHeader)

      records.forEach((record) => record.set('cancellable', cancellable))
    })
  },

  refreshMailboxCatalogue() {
    return store.dispatch(
      refreshCatalogue(this.parentRecord.getCompanyKey(), this.parentRecord.url)
    )
  },

  // Returns promise of summary.
  getCancelSummary(records, date) {
    const params = { date }

    if (records.length) {
      params.id = records.map((record) => record.id)
    }

    return requestPromise({
      method: 'GET',
      url: `${this.url};summary?${encodeForm(params)}`,
      json: true
    })
  },

  // Cancel agreements, returns promise.
  cancel(records, date, finalBilling) {
    const params = { id: records.map((record) => record.id) }

    const dateType = finalBilling ? 'finalDate' : 'thruDate'
    params[dateType] = date

    return requestPromise({
      method: 'POST',
      url: `${this.url};cancel?${encodeForm(params)}`
    }).then(() => this.load())
  }
})

module.exports = AgreementStore
