const Record = require('admin/core/Record')
const DomainStatus = require('admin/util/DomainStatus')
const MailRelayHostResource = require('admin/business/MailRelayHostResource')

const MailRelayHostRecord = Record.define(null, {
  module() {
    return MailRelayHostResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'status',
      type: 'string',
      mapping: 'currentStatus.statusItem.code',
      convert: DomainStatus.translate
    }
  ],

  getName() {
    return this.get('name')
  },

  getDescription() {
    return this.get('name')
  },

  getParams() {
    return { name: this.get('name') }
  }
})

module.exports = MailRelayHostRecord
