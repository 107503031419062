// ideally most of this code would go into a root app.es file for app initialisation
// but since that legacy ui runs on extjs, some hacks are needed to make react/redux work.
//
// furthermore we are trying to make this as close as possible to re-frame.
// each redux namespace has: (see src/modules/sharedservices/provider as an example)
// - a db to define initial app state
// - actions to either trigger further actions (thanks to thunk) and/or to define a single action
// - reducers to actually modify parts of db based on a single action
// - with the help of reselect we have subscribers to fetch a slice of current app state

import Ext from 'ext'
import { Provider } from 'react-redux'
import React from 'react'
import ReactContainerPanel from 'admin/view/ReactContainerPanel'
import store from 'store'

function getReactElement(component) {
  return <Provider store={store}>{component}</Provider>
}

const ReduxContainerPanel = Ext.define(null, {
  extend: ReactContainerPanel,
  store,

  getReactElement: function () {
    return getReactElement(this.component)
  },

  // window management logic:
  // this is queried from /admin/view/Window.coffee within
  // for subclasses to override
  preventClose: () => false
})

export default ReduxContainerPanel
