const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const User = require('admin/core/User')

const MailRelayHostListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'usedByDomains',

  module() {
    return require('admin/business/MailRelayHostResource')
  },

  initComponent() {
    this.callParent()
    // Domain store is used to populate the "used by domain" column.
    return this.domainStore.on('load', this.refresh, this)
  },

  destroy() {
    this.domainStore.un('load', this.refresh, this)
    this.callParent()
  },

  afterRender() {
    this.callParent()
    if (!this.domainStore.getCount()) {
      return this.domainStore.load()
    }
  },

  refresh() {
    return this.view != null ? this.view.refresh() : undefined
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel(
      [
        User.isTechnicalAdmin() && {
          id: 'id',
          header: t('ID'),
          width: 60,
          sortable: true,
          dataIndex: 'id'
        },
        {
          id: 'mailRelayHostName',
          header: t('Mail Relay Host'),
          width: 150,
          sortable: true,
          dataIndex: 'name'
        },
        {
          id: 'usedByDomains',
          header: t('Used By Domains'),
          width: 150,
          sortable: true,
          dataIndex: 'name',
          renderer: this.renderUsedBy.bind(this)
        }
      ].filter(Boolean)
    )
  },

  renderUsedBy(name) {
    return this.domainStore
      .getRange()
      .filter((d) => d.get('relayTo') === name)
      .map((d) => d.get('name'))
      .join(', ')
  },

  getToolbarItems() {
    return [
      this.getCreateButton(),
      this.getOpenButton(),
      this.getRemoveButton(),
      new Ext.Button({
        text: t('Check'),
        disabled: true,
        shouldEnable: () => {
          return this.getSelectionModel().hasSelection()
        },
        handler: () => {
          const record = this.getSelectionModel().getSelected()
          if (record) {
            return this.checkStatus(record)
          }
        }
      }),
      '->',
      this.getRefreshButton()
    ]
  },

  getContextMenuItems(record) {
    const items = this.callParent(arguments)

    items.push({
      text: t('Check Current Status'),
      iconCls: 'MailRelayHost-checkStatus',
      handler: () => {
        return this.checkStatus(record)
      }
    })

    return items
  },

  checkStatus(record) {
    const savedTimeout = Ext.Ajax.timeout
    Ext.Ajax.timeout = 120000
    const transaction = Ext.Ajax.request({
      url: record.getUrl() + '/status',
      success(response) {
        let message
        Ext.Ajax.timeout = savedTimeout
        Ext.Msg.hide()
        switch (response.responseText) {
          case '"UNAVAILABLE"':
            message = t('Mail relay host is unavailable.')
            break
          case '"AVAILABLE"':
            message = t('Mail relay host is available.')
            break
          case '"NOTAPPLICABLE"':
            message = t('Mail relay host checking is not applicable for relay pools.')
            break
          default:
            message = t('Unable to get status of mail relay host.')
        }

        return Ext.Msg.alert(t('Status'), message)
      },
      failure() {
        Ext.Ajax.timeout = savedTimeout
        return Ext.Msg.alert(t('Status'), t('Unable to get status of mail relay host.'))
      }
    })

    return Ext.MessageBox.show({
      msg: t('Getting mail relay host status'),
      buttons: Ext.MessageBox.CANCEL,
      closable: false,
      wait: true,
      modal: true,
      fn(buttonId) {
        if (buttonId === 'cancel') {
          Ext.Ajax.timeout = savedTimeout
          return Ext.Ajax.abort(transaction)
        }
      }
    })
  }
})

module.exports = MailRelayHostListPanel
