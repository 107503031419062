const Ext = require('ext')
const t = require('translate')
const openRecordByUrl = require('admin/desktop/openRecord').openRecordByUrl
const browser = require('system/browser')
const escape = require('html-escaper').escape

const SearchGridPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,

  constructor(cfg = {}) {
    const sm = new Ext.grid.RowSelectionModel({
      singleSelect: cfg.singleSelect
    })
    cfg = Ext.applyIf(cfg, {
      autoScroll: true,
      enableColumnHide: false,
      enableColumnMove: false,
      enableColumnResize: false,
      loadMask: true,
      cm: this.createColumnModel(),
      sm,
      hideHeaders: true,
      autoExpandColumn: 'description',
      keys: [
        {
          key: [Ext.EventObject.ENTER],
          fn() {
            if (this.getSelectionModel().hasSelection()) {
              return this.showResource(this.getSelectionModel().getSelected())
            }
          },
          scope: this
        }
      ],
      view: new Ext.grid.GroupingView({
        forceFit: true,
        hideGroupedColumn: true,
        enableGroupingMenu: false,
        showGroupName: false,
        startCollapsed: false,
        groupTextTpl: `{text} <span style="font-weight:normal">({[values.rs.length]} {[values.rs.length > 1 ? '${t(
          'results'
        )}' : '${t('result')}']})</span>`
      })
    })
    this.callParent([cfg])
    this.relayEvents(sm, ['rowselect', 'selectionchange'])

    if (browser.isMobile) {
      this.on('rowclick', this.openResource, this)
    }

    this.on('rowdblclick', this.openResource, this)
  },

  openResource(grid, rowIndex) {
    if (grid.getStore()) {
      const searchRecord = grid.getStore().getAt(rowIndex)
      this.showResource(searchRecord)
    }
  },

  showResource(searchRecord) {
    openRecordByUrl('/api' + searchRecord.get('url'))
  },

  mask() {
    this.loadMask?.show()
  },
  unmask() {
    this.loadMask?.hide()
  },

  doSearch(options) {
    options.mask = this.mask.bind(this)
    options.unmask = this.unmask.bind(this)

    const store = this.getStore()
    store?.load(options)
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel([
      {
        id: 'typeName',
        header: t('Type'),
        width: 60,
        sortable: true,
        dataIndex: 'typeName'
      },
      {
        id: 'description',
        header: t('Description'),
        width: 200,
        sortable: true,
        dataIndex: 'description',
        renderer: escape
      }
    ])
  }
})

module.exports = SearchGridPanel
