import Ext from 'ext'
import React from 'react'
import ReduxContainerPanel from 'admin/view/ReduxContainerPanel'
import TabsClass from 'continuity/views/Tabs'
import { hasModalDialog } from 'continuity/subs'
import { init as initSearch } from 'continuity/messages/actions'
import t from 'translate'
import { unload as unloadContinuity } from 'continuity/actions'

const ContinuityTabPanel = Ext.define(null, {
  extend: ReduxContainerPanel,

  title: t('Continuity'),
  cls: 'continuity-panel',
  tabCls: 'continuity-tab',

  initComponent: function () {
    // synchronous db initialisation here, set uri for company key so that
    // we don't have to painfully pass uri along to any react component
    this.store.dispatch(
      initSearch(this.initialConfig.companyKey, this.initialConfig.archivingUrl)
    )

    this.component = React.createElement(TabsClass, this.initialConfig)

    if (this.initialConfig.expired) {
      this.tabCls += ' expired-group-tab'
    }

    this.superclass().initComponent.call(this)
  },

  preventClose: function () {
    return hasModalDialog(this.store.getState(), this.companyKey)
  },

  destroy: function () {
    this.superclass().destroy.call(this)

    // cleanup only at the very end when all else has been destroyed
    this.store.dispatch(unloadContinuity(this.companyKey))
  }
})

export default ContinuityTabPanel
