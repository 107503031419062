import t from 'translate'
import _s from 'underscore.string'

const isAllZero = (parts) => parts.every((part) => part === 0)

const isPrivate = function (parts) {
  const [first, second] = parts

  // RFC 1918
  return (
    first === 127 ||
    first === 10 ||
    (first === 192 && second === 168) ||
    (first === 172 && second >= 16 && second <= 31)
  )
}

const validateIpAddress = function (v = '') {
  const parts = []
  const validParts = _s
    .trim(v)
    .split('.')
    .every(function (part) {
      if (part !== '0' && part.substr(0, 1) === '0') {
        return false
      }

      const num = Number(part)

      if (Number.isInteger(num) && num >= 0 && num <= 255) {
        parts.push(num)
        return true
      }

      return false
    })

  if (validParts && parts.length === 4 && !isAllZero(parts)) {
    if (isPrivate(parts)) {
      return t('Private IP address')
    }

    return true
  }

  return t('IP address is invalid')
}

export default validateIpAddress
