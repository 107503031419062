const Ext = require('ext')
const t = require('translate')
const FormPanel = require('admin/component/form/FormPanel')
const confirmDiscardChanges = require('admin/util/confirmDiscardChanges')
const Tooltip = require('admin/util/Tooltip')

const RecordFormPanel = Ext.define(null, {
  extend: FormPanel,

  constructor(cfg = {}) {
    if (cfg.keys == null) {
      cfg.keys = [
        {
          key: [Ext.EventObject.ENTER],
          fn() {
            return this.fireEvent('enter-key-pressed')
          },
          scope: this
        },
        {
          key: [Ext.EventObject.ESC],
          fn() {
            return this.fireEvent('esc-key-pressed')
          },
          scope: this
        }
      ]
    }

    // Recursively modify children widths.
    // If a RecordFormPanel has fieldWidth set and not noDefaultWidth, this code will iterate over
    // all children and set width to fieldWidth.
    const applyDefaultWidth = (rootItem) => {
      if (rootItem.noDefaultWidth) {
        return
      }

      let { items } = rootItem
      if (items) {
        // Items may be an array, a single item or a MixedCollection so we convert to an array.
        if (items instanceof Ext.util.MixedCollection) {
          items = items.getRange()
        } else if (!Array.isArray(items)) {
          items = [items]
        }

        return items.forEach(function (item) {
          if (item) {
            return applyDefaultWidth(item)
          }
        })
      }
      if (!rootItem.width) {
        rootItem.width = cfg.fieldWidth || this.fieldWidth
      }
      return rootItem.width
    }

    applyDefaultWidth(cfg)

    this.callParent([cfg])

    // If form is read-only and user is allowed to flip to corresponding edit form,
    // make all read-only fields highlight and tooltip themselves and fire a 'editmode'
    // event at @ form when clicked on
    if (this.canSave()) {
      const readyViewItems = function (form, rootItem) {
        if (attachEditListener(form, rootItem) === false) {
          // Don't want to recurse into sub-elements of composite field
          if (rootItem.items) {
            let items
            if (Array.isArray(rootItem.items)) {
              items = rootItem.items
            } else if (rootItem.items instanceof Ext.util.MixedCollection) {
              items = rootItem.items.getRange()
            }

            if (items) {
              return items.forEach(function (item) {
                if (item) {
                  return readyViewItems(form, item)
                }
              })
            }
          }
        }
      }

      // Attempts to attach listener to given field, if field is capable of supporting that, returning true
      // on success else false.
      const attachEditListener = function (form, field) {
        // Read-only field is one that can be highlighted. TODO - read-only checkboxes can't be highlighted yet (nothing to highlight)
        if (field.initEdit) {
          field.initEdit(form)
        } else if (field.highlight) {
          field.on(
            'render',
            function () {
              return field.getEl().on(
                'click',
                function () {
                  if (field.isHighlighted()) {
                    return form.fireEvent('editmode', { field })
                  }
                  Tooltip.register(field.getEl(), t('Click to edit this form'))
                  field.highlight()
                  return field.focus()
                },
                field,
                this
              )
            },
            field,
            this
          )

          field.on(
            'blur',
            function () {
              Ext.QuickTips.unregister(field.getEl())
              return field.unHighlight()
            },
            this
          )
          return true
        }
        return false
      }
      readyViewItems(this, cfg)
    }

    this.on('afterlayout', this.addTooltips, this, { single: true })
    this.on('clientvalidation', this.validateForm, this)
    this.loaded = false
  },

  // Recursively adds tooltips to form components.
  // Tooltips should be applied to composite field, not children.
  addTooltips(field) {
    if (!field.rendered) {
      field.on(
        'afterrender',
        function () {
          this.addTooltips(field)
        },
        this,
        { single: true }
      )
      return
    }

    if (Tooltip.fieldNeedsRequiredTooltip(field)) {
      Tooltip.appendRequiredFieldMarker(field)
    }

    if (field.helpText) {
      Tooltip.appendTooltip(field, 'helptext', field.helpText)
    }

    if (field.items && !(field instanceof Ext.form.CompositeField)) {
      if (field.items?.each) {
        field.items.each(function (subField) {
          this.addTooltips(subField)
        }, this)
      }
    }
  },

  isSaving() {
    const parent = this.findParentBy((c) => !!c.formPanel)
    return parent?.saving
  },

  validateForm(form, valid) {
    if (form.saveButton) {
      let disabled
      const saving = this.isSaving()
      const isDirty = form.getForm().isDirty()

      if (isDirty) {
        form.addClass('smx-dirty')
      } else {
        form.removeClass('smx-dirty')
      }

      valid = valid && !saving && (form.operation === 'create' || isDirty)

      if (valid) {
        if (this.record != null) {
          disabled = !this.canSave()
        } else {
          disabled = false
        }
      } else {
        disabled = true
      }

      form.saveButton.setDisabled(disabled)

      if (!valid) {
        return form.getForm().items.each(function (item) {
          // do not validate those without a dom
          // see http://youtrack.smxemail.com/issue/SCL-1366
          // this can happen when in an intermediary flux while adding/removing new ui elements :(
          if ((item.el != null ? item.el.dom : undefined) != null && item.isDirty()) {
            return item.validate()
          }
        })
      }
    }
  },

  afterRender() {
    this.callParent()
    if (this.record) {
      return this.loadRecord()
    }
  },

  isDirty() {
    return (
      this.rendered &&
      (this.operation === 'save' || this.operation === 'create') &&
      this.form?.isDirty()
    )
  },

  preventClose(retry) {
    if (
      !this.allowClose &&
      !(this.record != null ? this.record.deleted : undefined) &&
      this.isDirty()
    ) {
      confirmDiscardChanges(() => {
        this.allowClose = true
        return retry()
      })
      return true
    }
  },

  loadRecord(force) {
    // Reset allowClose in case the form is being shown again
    this.allowClose = false

    if (this.getForm().items.length !== 0 && (!this.loaded || force)) {
      const parent = this.findParentBy((c) => (c != null ? c.record : undefined))

      if (parent != null ? parent.record : undefined) {
        this.record = parent.record
        this.getForm().loadRecord(this.record)
        this.loaded = true
      }
    }
  },

  canSave() {
    return this.record && this.record.canSave()
  }
})

module.exports = RecordFormPanel
