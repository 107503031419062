import React, { Component } from 'react'
import {
  selectedProvider,
  selectedProviderLink,
  selectedProviderName,
  uri
} from 'sharedservices/provider/subs'
import {
  stopConfiguring,
  submitAndConfirmProvider,
  updateProvider
} from 'sharedservices/provider/actions'

import Dialog from './Dialog'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class ConfigureForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    requireAuthentication: PropTypes.bool
  }

  onSubmit = (values) => {
    if (this.props.requireAuthentication) {
      this.props.submitAndConfirmProvider(values)
    } else {
      // submit right away, most likely a delegated keycloak user
      this.props.updateProvider(this.props.uri, this.props.selectedProviderLink, values)
    }
  }

  render() {
    const title = t('Configure shared service provider {0}', [
      this.props.selectedProviderName
    ])

    return (
      <Dialog
        showAddresses
        onSubmit={this.onSubmit}
        onCancel={this.props.onCancel}
        provider={this.props.selectedProvider}
        title={title}
        store={this.props.store}
        submitButtonText={t('Configure')}
        allowPrivate={this.props.allowPrivate}
        allowMappingPolicy={this.props.allowMappingPolicy}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  uri: uri(db, props.companyKey),
  selectedProvider: selectedProvider(db, props.companyKey),
  selectedProviderLink: selectedProviderLink(db, props.companyKey),
  selectedProviderName: selectedProviderName(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  updateProvider: (uri, selfLink, values) =>
    dispatch(updateProvider(initialProps.companyKey, uri, selfLink, values)),
  submitAndConfirmProvider: (values) =>
    dispatch(submitAndConfirmProvider(initialProps.companyKey, values)),
  onCancel: () => dispatch(stopConfiguring(initialProps.companyKey, initialProps.action))
})

export default connect(mapDbToProps, mapActionsToProps)(ConfigureForm)
