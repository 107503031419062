const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const Time = require('admin/time/Time')
const User = require('admin/core/User')
const AgreementCancelWindow = require('admin/view/AgreementCancelWindow')
const formatMoney = require('money/format')

const AgreementListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  module() {
    return require('admin/business/AgreementResource')
  },

  title: t('Products'),
  autoExpandColumn: 'productName',
  cls: 'agreement-list-panel',
  tabCls: 'agreement-list-tab',

  initComponent() {
    this.selModel = new Ext.grid.RowSelectionModel({ singleSelect: false })

    this.callParent()

    this.view = new Ext.grid.GroupingView({
      forceFit: true,
      hideGroupedColumn: true,
      enableGroupingMenu: false,
      showGroupName: false,
      startCollapsed: false
    })

    const { store } = this
    store.on('load', this.updateCols, this)
    this.on('destroy', () => {
      store.un('load', this.updateCols, this)
    })

    if (this.record) {
      // Enables menu items based on reseller status
      const catalogueStore = this.record.getCatalogueStore()
      catalogueStore.on(
        'load',
        function (store, records) {
          if (records?.length > 0) {
            const button = this.getCreateButton()
            // only enable when there are really any products we can add (SCL-3257)
            button?.setDisabled(false)
          }
        },

        this,
        { single: true }
      )
      catalogueStore.load()
    }
  },

  updateCols() {
    // Only show price if it is available.
    const priceAvailable = this.store
      .getRange()
      .some((record) => Number.isFinite(record.get('basePrice.priceAmount')))

    this.getColumnModel().setHidden(
      this.getColumnModel().getIndexById('price'),
      !priceAvailable
    )
  },

  createColumnModel() {
    const columns = [
      {
        id: 'status',
        header: t('Status'),
        dataIndex: 'status'
      },
      {
        id: 'agreementTypeName',
        header: t('Type'),
        width: 70,
        sortable: true,
        dataIndex: 'agreementTypeName'
      },
      {
        id: 'productName',
        header: t('Product Name'),
        width: 100,
        sortable: true,
        dataIndex: 'productName'
      },
      {
        id: 'quantity',
        header: t('Quantity'),
        width: 50,
        sortable: true,
        dataIndex: 'quantity'
      },
      {
        id: 'price',
        hidden: true,
        header: t('Price'),
        width: 80,
        sortable: true,
        dataIndex: 'basePrice.priceAmount',
        renderer(value, metadata, record) {
          if (!Number.isFinite(value)) return ''

          return formatMoney(record.get('basePrice.priceCurrencyUom'), value)
        }
      },
      {
        id: 'billTo',
        header: t('Billed To'),
        width: 80,
        sortable: true,
        dataIndex: 'billToName'
      },
      {
        id: 'fromDate',
        header: t('From Date'),
        width: 65,
        sortable: true,
        dataIndex: 'fromDate',
        renderer: Time.toDateString
      },
      {
        id: 'thruDate',
        header: t('Thru Date'),
        width: 65,
        sortable: true,
        dataIndex: 'thruDate',
        renderer: Time.toDateString
      }
    ]

    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }

    return new Ext.grid.ColumnModel(columns)
  },

  // Because we aren't really creating products but adding these here
  // we are overwriting parent's function to have button text "Add"
  // instead of "New" (see SCL-2770)
  getCreateButtonText() {
    return t('Add')
  },

  getToolbarItems() {
    return [
      this.getCreateButton(true),
      this.getOpenButton(),
      new Ext.Button({
        text: t('Cancel'),
        disabled: true,
        shouldEnable: () => {
          return (
            this.getSelectionModel().getSelections().length &&
            this.getSelectionModel()
              .getSelections()
              .every((record) => record.canCancel())
          )
        },
        handler: () => {
          return this.confirmCancel(this.getSelectionModel().getSelections())
        }
      }),
      new Ext.Button({
        text: t('Cancel All'),
        disabled: true,
        shouldEnable: () => {
          return this.store.getRange().some((record) => record.canCancel())
        },
        handler: () => {
          return this.confirmCancel(
            this.store.getRange().filter((record) => record.canCancel())
          )
        }
      }),
      '->',
      new Ext.form.Checkbox({
        boxLabel: t('All Historic'),
        disabled: false,
        handler: (checkbox, checked) => {
          this.store.baseParams = checked
            ? {}
            : require('admin/data/AgreementStore').prototype.baseParams
          return this.store.reload()
        }
      }),
      new Ext.Spacer({ width: 6 }),
      this.getRefreshButton()
    ]
  },

  confirmCancel(records) {
    let title = t('Cancel') + ' - ' + this.record.get('displayName')
    if (User.isTechnicalAdmin()) {
      title += `[${this.record.get('id')}]`
    }

    return new AgreementCancelWindow({
      title,
      agreementStore: this.store,
      agreements: records
    }).show()
  }
})

module.exports = AgreementListPanel
