// this is the initial continuity app state for each new company when
// search gets opened for first time

// TODO: Technically, it doesn't have to be in app state as it will never
// change but we've decided to put it here in the future, we'll add a keyword
// to adjust.
const LIMIT = 20

export default {
  continuityUri: null,
  attachmentPanelExpanded: false,
  authorizationCount: -1,
  authorizationTimestamp: null,
  // upon resizing, new column widths will be stored here.
  // by default these are based on flex grow props.
  //
  // TODO must be ordered
  columns: {
    sender: {},
    recipients: {},
    subject: {},
    size: {},
    received: {}
  },
  downloadUriTemplate: null,
  // aka "smx-message-id", unique identifier of email we are viewing.
  // null when seeing table
  sid: null,
  fromDate: null,
  highlight: true,
  limit: LIMIT,
  loading: null,
  nextLink: null, // holds the next link for pagination
  offset: 0,
  preview: null,
  query: '', // always the current query input
  results: null,
  resultsQuery: null, // the query at that time a search was made. needed for highlighting.
  scrollToIndex: null, // tells table to which row index to scroll to
  source: null, // when not null we are viewing this mail source
  thruDate: null,
  timeZoneName: null,
  dateRangeShown: false
}
