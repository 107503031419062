const Ext = require('ext')
const t = require('translate')
const CompanyDetailPanel = require('admin/view/CompanyDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const CountryResource = require('admin/business/CountryResource')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')

const CustomerDetailPanel = Ext.define(null, {
  extend: CompanyDetailPanel,
  title: t('Details'),
  tabCls: 'customer-detail-tab',
  module() {
    return require('admin/business/CustomerResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems(cfg) {
    let countryCombo
    const valueWidth = 200

    const defaultResellerFieldConfig = {
      name: 'parentName',
      width: valueWidth,
      text: cfg.record.get('parentName'),
      scope: this
    }

    const parent = this.getParent()
    let resellerField

    if (parent != null) {
      resellerField = {
        xtype: 'container',
        layout: {
          type: 'hbox',
          align: 'middle'
        },
        items: [
          {
            xtype: 'box',
            autoEl: { tag: 'div' },
            html: t('Reseller Name'),
            cls: 'x-form-item-label',
            width: 125,
            style: {
              marginRight: '5px',
              color: '#636363',
              textAlign: 'right',
              padding: '2px 4px',
              left: '0px',
              top: '2.5px'
            }
          },
          Ext.apply(
            {
              xtype: 'link',
              cls: 'fieldset-link parent-name',
              tooltip: t('Click to open reseller'),
              handler: this.gotoParent,
              width: 125
            },
            defaultResellerFieldConfig
          )
        ]
      }
    } else {
      resellerField = new ReadOnlyTextField(
        Ext.apply(
          {
            fieldLabel: t('Reseller Name'),
            locked: true
          },
          defaultResellerFieldConfig
        )
      )
    }

    if (CountryResource.countryChoiceExists()) {
      countryCombo = CountryResource.getComboBox({
        fieldLabel: t('Country'),
        hiddenName: 'primaryPostalAddress.countryGeo',
        width: valueWidth
      })
    }
    return [
      {
        cls: 'customer',
        border: false,
        items: [
          {
            columnWidth: 0.5,
            title: t('Details'),
            xtype: 'fieldset',
            defaultType: 'textfield', // Ensure default types if other fields are added later
            items: [
              {
                fieldLabel: t('Customer Name'),
                name: 'displayName',
                width: valueWidth,
                maxLength: 100,
                allowBlank: false,
                xtype: 'textfield'
              },
              new ReadOnlyTextField({
                fieldLabel: t('Customer Type'),
                name: 'customerTypeName',
                width: valueWidth,
                locked: true
              }),
              {
                fieldLabel: t('Customer Number'),
                name: 'externalId',
                width: valueWidth,
                maxLength: 30,
                xtype: 'textfield'
              },
              resellerField
            ]
          },
          {
            columnWidth: 0.5,
            layout: 'form',
            title: t('Primary Address'),
            items: [
              {
                fieldLabel: t('Attention Name'),
                name: 'primaryPostalAddress.attnName',
                width: valueWidth,
                maxLength: 100,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('Address Line 1'),
                name: 'primaryPostalAddress.address1',
                width: valueWidth,
                maxLength: 255,
                allowBlank: false,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('Address Line 2'),
                name: 'primaryPostalAddress.address2',
                width: valueWidth,
                maxLength: 255,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('City / Town'),
                name: 'primaryPostalAddress.city',
                width: valueWidth,
                maxLength: 100,
                allowBlank: false,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('Post Code'),
                name: 'primaryPostalAddress.postalCode',
                width: valueWidth,
                maxLength: 60,
                xtype: 'textfield'
              },
              countryCombo
            ].filter(Boolean),
            xtype: 'fieldset'
          }
        ]
      }
    ]
  }
})

module.exports = CustomerDetailPanel
