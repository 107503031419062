const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ProxyStore = require('admin/data/ProxyStore')
const User = require('admin/core/User')
const ComboBox = require('admin/component/form/ComboBox')
const Report = require('reporting/Report')

const SubscriptionNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'subscription-new-panel',

  module() {
    return require('reporting/Subscription')
  },

  constructor() {
    this.subscriberStore = new ProxyStore(User.getRootStore())
    this.store = this.subscriberStore.getById(User.getId()).getSubscriptionStore()

    this.callParent(arguments)
  },

  getFocusId() {
    return this.formPanel.getForm().items.findIndex('name', 'description')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems(cfg = {}) {
    User.getRootStore().load()

    const formatOptions = Report.getFormatOptions(cfg.record)

    return [
      {
        name: 'reportId',
        value: cfg.record.get('id'),
        xtype: 'hidden'
      },
      {
        fieldLabel: t('Description'),
        name: 'description',
        maxLength: 255,
        allowBlank: false,
        xtype: 'textfield',
        value: String.format(
          '{0} - {1}',
          cfg.record.get('title'),
          cfg.record.store.parentRecord.get('displayName')
        )
      },
      new ComboBox({
        fieldLabel: t('Format'),
        hiddenName: 'format',
        allowBlank: false,
        store: formatOptions,
        editable: false,
        triggerAction: 'all',
        value: formatOptions[0][0]
      }),
      new ComboBox({
        fieldLabel: t('Delivery'),
        hiddenName: 'delivery',
        allowBlank: false,
        store: [
          ['DAILY', t('Daily')],
          ['WEEKLY', t('Weekly')],
          ['MONTHLY', t('Monthly')]
        ],
        editable: false,
        triggerAction: 'all',
        value: 'MONTHLY'
      }),
      // Options panel populated from report metadata
      Report.getReportOptionsPanel(cfg.record, { subscription: true }, cfg.defaultsMap)
    ]
  },

  handleSave(button, event) {
    // TODO: Clean, messy way to get a url to post to.
    this.store = this.subscriberStore.getById(User.getId()).getSubscriptionStore()
    return this.callParent([button, event])
  },

  getFormValues() {
    const form = this.formPanel.getForm().getFieldValues()
    form.report = this.record.data
    return form
  }
})

module.exports = SubscriptionNewPanel
