const Record = require('admin/core/Record')
const Calendar = require('admin/time/Calendar')

const EventSearchRecord = Record.define(null, {
  fields: [
    {
      name: 'id',
      type: 'string',
      mapping: 'uuid'
    },
    {
      name: 'direction',
      type: 'string',
      mapping: 'form.direction'
    },
    {
      name: 'type',
      type: 'string',
      mapping: 'form.type'
    },
    {
      name: 'status',
      type: 'string',
      mapping: 'form.status'
    },
    {
      name: 'subject',
      type: 'string',
      mapping: 'form.subject'
    },
    {
      name: 'from',
      type: 'string',
      mapping: 'form.from'
    },
    {
      name: 'to',
      type: 'string',
      mapping: 'form.to'
    },
    {
      name: 'fromDate',
      type: 'date',
      mapping: 'form.fromDate'
    },
    {
      name: 'thruDate',
      type: 'date',
      mapping: 'form.thruDate'
    },
    {
      name: 'messageId',
      type: 'string',
      mapping: 'form.messageId'
    },
    {
      name: 'sid',
      type: 'string',
      mapping: 'form.sid'
    },
    {
      name: 'messageSize',
      type: 'string',
      mapping: 'form.messageSize'
    },
    {
      name: 'order',
      type: 'string',
      mapping: 'form.order'
    },
    {
      name: 'limit',
      type: 'int',
      mapping: 'form.limit'
    },
    {
      name: 'offset',
      type: 'int'
    },
    {
      name: 'tag',
      type: 'string',
      mapping: 'form.tag'
    }
  ],

  getName() {
    return this.get('id')
  },

  getDescription() {
    return this.get('id')
  },

  getParams() {
    const timeZoneName = this.get('timeZoneName')

    const fromDate = Calendar.normalizeFromDate(this.get('fromDate'), timeZoneName)
    const thruDate = Calendar.normalizeThruDate(this.get('thruDate'), timeZoneName)

    const searchParams = {
      direction: this.get('direction'),
      type: this.get('type'),
      status: this.get('status'),
      subject: this.get('subject'),
      from: this.get('from'),
      to: this.get('to'),
      fromDate: fromDate.toMillis(),
      thruDate: thruDate.toMillis(),
      messageId: this.get('messageId'),
      sid: this.get('sid'),
      messageSize: this.get('messageSize') === 0 ? '' : this.get('messageSize'),
      order: this.get('order'),
      limit: this.get('limit'),
      offset: this.get('offset')
    }

    const tag = this.get('tag')

    if (tag?.length > 0) {
      searchParams.tag = tag
    }

    return searchParams
  }
})

module.exports = EventSearchRecord
