import { Button, ButtonToolbar, Checkbox, Col, Row } from 'react-bootstrap'
import React, { PureComponent } from 'react'

import Download from './Download'
import Pagination from './Pagination'
import PropTypes from 'prop-types'
import ReleaseButton from 'mail/search/release/views/Button'
import Source from 'mail/details/views/Source'
import t from 'translate'

class SearchToolbar extends PureComponent {
  static propTypes = {
    viewing: PropTypes.bool,
    releasable: PropTypes.bool,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    onDownload: PropTypes.func,
    onOpenConfirmingRelease: PropTypes.func,
    onViewSource: PropTypes.func.isRequired,
    onHighlightingChange: PropTypes.func.isRequired,
    onSourceHide: PropTypes.func.isRequired,
    source: PropTypes.string,
    loading: PropTypes.bool,
    currentResultNumber: PropTypes.number,
    enableHighlighting: PropTypes.bool,
    hasNext: PropTypes.bool,
    hasPrev: PropTypes.bool,
    endReached: PropTypes.bool,
    firstRowNumber: PropTypes.number,
    lastRowNumber: PropTypes.number,
    total: PropTypes.number,
    sourceAvailable: PropTypes.bool,
    downloadable: PropTypes.bool
  }

  onDownloadClick = (e) => {
    e.stopPropagation()
    this.props.onDownload()
  }

  handleHighlightChange = (e) => {
    this.props.onHighlightingChange(e.target.checked)
  }

  hasResults = () => this.props.total > 0

  disablePaginationNext = () => this.props.loading || !this.props.hasNext

  disablePaginationPrev = () => this.props.loading || !this.props.hasPrev

  getResultCount() {
    let total = this.props.total

    if (this.props.hasNext && !this.props.endReached) {
      total += '+'
    }

    if (this.props.viewing) {
      return t('{currentResultNumber} of {total}', {
        currentResultNumber: this.props.currentResultNumber,
        total
      })
    } else if (this.hasResults()) {
      return t('{first}-{last} of {total}', {
        first: this.props.firstRowNumber,
        last: this.props.lastRowNumber,
        total
      })
    } else if (this.props.total === 0) {
      return t('No results')
    }
  }

  renderBackButton() {
    return (
      <Button
        onClick={this.props.onBack}
        className='button-back'
        disabled={this.props.loading || !this.props.viewing}
      >
        {t('Back')}
      </Button>
    )
  }

  renderSearchState() {
    if (this.props.loading) {
      return <span className='loading-indicator'>{t('Loading...')}</span>
    }

    return this.getResultCount()
  }

  renderViewSourceButton() {
    return (
      <Button
        disabled={!this.props.sourceAvailable}
        onClick={this.props.onViewSource}
        className='button-view-source' // needed for unit tests
      >
        {t('View source')}
      </Button>
    )
  }

  renderDownloadButton() {
    return (
      <Download onClick={this.props.onDownload} disabled={!this.props.downloadable} />
    )
  }

  disableReleaseButton = () => {
    return this.props.loading || !this.props.releasable
  }

  renderReleaseButton() {
    return (
      <ReleaseButton
        onClick={this.props.onOpenConfirmingRelease}
        disabled={this.disableReleaseButton()}
      />
    )
  }

  renderSource = () => {
    return <Source source={this.props.source} onHide={this.props.onSourceHide} />
  }

  render() {
    return (
      <Row className='toolbar'>
        {this.props.source && this.renderSource()}
        <Col xs={7}>
          <ButtonToolbar className='pull-left'>
            {this.renderBackButton()}
            {this.props.onDownload && this.renderDownloadButton()}
            {this.props.onOpenConfirmingRelease && this.renderReleaseButton()}
            {this.props.viewing && this.renderViewSourceButton()}
          </ButtonToolbar>
          <Checkbox
            disabled={this.props.loading}
            onChange={this.handleHighlightChange}
            checked={this.props.enableHighlighting}
            className='highlight'
          >
            {t('Highlight matches')}
          </Checkbox>
        </Col>
        <Col xs={5}>
          <div className='pull-right'>
            <div className='mail-state'>{this.renderSearchState()}</div>
            <Pagination
              onNext={this.props.onNext}
              onPrev={this.props.onPrev}
              disableNext={this.disablePaginationNext()}
              disablePrev={this.disablePaginationPrev()}
            />
          </div>
        </Col>
      </Row>
    )
  }
}

export default SearchToolbar
