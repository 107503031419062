import t from 'translate'
import React, { Component } from 'react'
import { Tab } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Tabs from 'components/Tabs'
import Providers from './Providers'
import User from 'admin/core/User'

class SharedServicesTabs extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired,
    allowPrivate: PropTypes.bool, // used for top level vendor tab only (SCL-2441)
    allowMappingPolicy: PropTypes.bool // used for top level vendor tab only (SCL-2441)
  }

  canConfigureProviders() {
    return User.isTechnical() || User.isTechnicalAdmin()
  }

  render() {
    return (
      <Tabs tabsId='shared-services-tabs' defaultActiveKey={1}>
        <Tab eventKey={1} title={t('Providers')}>
          <Providers
            width={this.props.width}
            height={this.props.height}
            configurable={this.canConfigureProviders()}
            companyKey={this.props.companyKey}
            allowPrivate={this.props.allowPrivate}
            allowMappingPolicy={this.props.allowMappingPolicy}
            store={this.props.store}
          />
        </Tab>
      </Tabs>
    )
  }
}

export default SharedServicesTabs
