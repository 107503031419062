import t from 'translate'

// just a little helper function to build the progress text
// based on four variables. used in release confirmation view AND
// in the release action under quarantine/search for notification.

export default function (size, released, skipped, failed) {
  if (!size) return
  if (released + skipped + failed > size) {
    throw new Error('Invalid formatProgress parameters: all counts must add up to `size`')
  }

  if (skipped && failed) {
    return t('Released {0} of {1} messages, {2} failed and {3} skipped', [
      released,
      size,
      failed,
      skipped
    ])
  } else if (skipped && !!released) {
    return t('Released {0} of {1} messages and {2} skipped', [released, size, skipped])
  } else if (failed && !!released) {
    return t('Released {0} of {1} messages and {2} failed', [released, size, failed])
  } else if (released === size) {
    if (size === 1) return t('Released message')
    return t('Released all messages')
  } else if (skipped === size) {
    if (size === 1) return t('Skipped message')
    return t('Skipped all messages')
  } else if (failed === size) {
    if (size === 1) return t('Failed to release message')
    return t('Failed to release all messages')
  }
  if (released) {
    return t('Released {0} of {1} messages', [released, size])
  }

  return t('Queuing {0} messages ...', [size])
}
