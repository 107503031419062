const Ext = require('ext')
const ResourceReader = require('admin/data/ResourceReader')
const PriceRecord = require('admin/data/PriceRecord')

const PriceStore = Ext.define(null, {
  extend: Ext.data.Store,

  constructor() {
    this.callParent(arguments)

    if (!this.reader) {
      this.reader = new ResourceReader(this.record)
    }

    return this.reader
  },

  record: PriceRecord
})

module.exports = PriceStore
