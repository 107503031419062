const Ext = require('ext')
const validators = require('validators').default
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const SmartRule = require('smartrules/designer/SmartRule')

const AddressMatcher = Ext.define(null, {
  extend: Matcher,

  cls: 'address-matcher',
  acceptWildcards: false,

  initComponent() {
    let validator

    if (this.acceptWildcards) {
      validator = validators.wildcard.withMsg(
        t(
          'This field should be an e-mail address in the format "user@example.com" ' +
            'and may include wildcards (*)'
        )
      )
    } else {
      validator = validators.email.withMsg(
        t('This field should be an e-mail address in the format "user@example.com"')
      )
    }

    this.items = new Ext.form.TextField({
      allowBlank: false,
      flex: 3,
      name: 'address',
      ref: 'textfield',
      value: this.value,
      listeners: {
        change: () => {
          const parent = this.findParentByType(SmartRule)
          parent?.fireEvent('dirty')
        }
      },
      validator
    })

    this.callParent()
  },

  setValue(v) {
    return this.textfield.setValue(v)
  },

  getValue(options = {}) {
    const raw = options.raw || false

    const value = this.textfield.getValue()
    let v = (value != null ? value.replace(/(["\\])/g, '\\$1') : undefined) || ''

    // raw means a condition has changed, so don't change value
    // when not raw, then that means we are switching to the source tab
    if (!raw) {
      v = v.trim() // trimming emails since SCL-388
      v = `"${v}"`
    }

    return v
  }
})

module.exports = AddressMatcher
