import React from 'react'

import ReduxContainerPanel from 'admin/view/ReduxContainerPanel'
import Ext from 'ext'
import { unload as unloadQuarantine } from 'quarantine/actions'
import { init as initMailTargets } from 'quarantine/mail_targets/actions'
import {
  init as initNotifications,
  unload as unloadNotifications
} from 'quarantine/notification/actions'
import { init as initQuarantineSets } from 'quarantine/quarantine_sets/actions'
import { init as initSearch } from 'quarantine/search/actions'
import { hasModalDialog } from 'quarantine/subs'
import TabsClass from 'quarantine/views/Tabs'
import t from 'translate'

const QuarantineTabPanel = Ext.define(null, {
  extend: ReduxContainerPanel,

  title: t('Quarantine'),
  cls: 'quarantine-panel',
  tabCls: 'quarantine-tab',

  initComponent: function () {
    // do not initialise app state when hot reloading for local development
    if (!this.initialConfig.hotReloading) {
      // synchronous db initialisation here, set uri for company key so that
      // we don't have to painfully pass uri along to any react component

      if (this.initialConfig.searchUri) {
        this.store.dispatch(
          initSearch(this.initialConfig.companyKey, this.initialConfig.searchUri)
        )
      }

      this.store.dispatch(
        initMailTargets(this.initialConfig.companyKey, this.initialConfig.mailTargetsUri)
      )

      if (this.initialConfig.quarantineSetsUri) {
        this.store.dispatch(
          initQuarantineSets(
            this.initialConfig.companyKey,
            this.initialConfig.quarantineSetsUri
          )
        )
      }

      if (this.initialConfig.notificationUri) {
        this.store.dispatch(
          initNotifications(
            this.initialConfig.companyKey,
            this.initialConfig.notificationUri
          )
        )
      }
    }

    this.component = React.createElement(TabsClass, this.initialConfig)

    if (this.initialConfig.expired) {
      this.tabCls += ' expired-group-tab'
    }

    // trick to call parent extjs function from es6 within
    this.superclass().initComponent.call(this)
  },

  preventClose: function () {
    const state = this.store.getState()
    return hasModalDialog(state, this.companyKey)
  },

  destroy: function () {
    this.superclass().destroy.call(this)

    // cleanup only at the very end when all else has been destroyed
    this.store.dispatch(unloadQuarantine(this.companyKey))
    this.store.dispatch(unloadNotifications(this.companyKey))
  }
})

export default QuarantineTabPanel
