const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const BillingDetailPanel = require('admin/view/BillingDetailPanel')
const User = require('admin/core/User')
const CustomerResource = require('admin/business/CustomerResource')

const BillingResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Billing',
  translations: {
    title: t('Billing')
  },
  name: t('Billing'),
  code: 'CUSTOMER',
  resourceName: '/customer',

  layout: {
    newResource: {
      width: 390,
      height: 'auto'
    },
    openResource: {
      width: 725,
      height: 570
    }
  },

  operations: {
    create: false,
    open: true,
    remove: false,
    list: false,
    save: true
  },

  detailPanel: BillingDetailPanel,

  canSave() {
    return this.callParent() && User.hasRole('BILLING')
  },

  getParentResources() {
    return [CustomerResource]
  },

  getTitle(record) {
    const titleId = User.isTechnicalAdmin() ? `[${record.get('id')}]` : ''
    return `${this.name}${titleId}: ${record.getName()}`
  }
})

module.exports = BillingResource
