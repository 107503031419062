const Ext = require('ext')
const ExplorerNodeUI = require('admin/component/tree/ExplorerNodeUI')
const escape = require('html-escaper').escape

const ExplorerLoader = Ext.define(null, {
  extend: Ext.tree.TreeLoader,

  load(node, callback) {
    if (this.clearOnLoad) {
      while (node.firstChild) {
        node.removeChild(node.firstChild)
      }
    }

    if (this.doPreload(node)) {
      if (callback && typeof callback === 'function') {
        return callback()
      }
    } else {
      if (node.attributes.resourceId) {
        return this.requestData(node, callback)
      }
    }
  },

  requestData(node, callback) {
    if (this.fireEvent('beforeload', this, node, callback) !== false) {
      const resourceNode = this.explorer.resources[node.attributes.resourceId]
      const record = node.attributes.parentRecord || node.parentNode.attributes.record
      const store = record.getResourceStore(resourceNode.resource.getCode())
      node.store = store

      this.transId = store.load({
        params: {
          lite: ''
        },
        callback: this.processResponse,
        scope: this,
        argument: {
          callback,
          node,
          store
        }
      })
      if (!this.transId) {
        // if the load is cancelled, make sure we notify
        // the node that we are done
        if (callback && typeof callback === 'function') {
          return callback()
        }
      }
    } else {
      node.loading = false
      node.loaded = false
      node.ui.afterLoad(node)
    }
  },

  processResponse(records, options, success) {
    this.transId = false
    const { node } = options.argument
    const { callback } = options.argument
    const { store } = options.argument
    const resourceNode = this.explorer.resources[node.attributes.resourceId]

    if (success) {
      this.createNodes(node, store.getRange(), resourceNode, store.resourceName)
      const storeListener = () => {
        return this.dataChanged(store, node)
      }
      store.on('datachanged', storeListener)
      store.on('add', storeListener)
      store.on('remove', storeListener)

      node.storeListener = storeListener
      node.store = store

      if (callback && typeof callback === 'function') {
        callback(this, node)
      }

      return this.fireEvent('load', this, node, records)
    }
    this.fireEvent('loadexception', this, node, false)
    node.loading = false
    node.loaded = false
    node.ui.afterLoad(node)
  },

  dataChanged(store, node) {
    node.suspendEvents()
    while (node.lastChild) {
      this.remove(node, node.lastChild)
    }
    node.resumeEvents()

    const resourceNode = this.explorer.resources[node.attributes.resourceId]
    this.createNodes(node, store.getRange(), resourceNode)
    node.expand()
  },

  remove(parentNode, node) {
    node.suspendEvents()
    while (node.lastChild) {
      this.remove(node, node.lastChild)
    }
    node.resumeEvents()

    if ((node.store != null ? node.store.storeListener : undefined) != null) {
      node.store.removeListener('datachanged', node.storeListener)
    }
    parentNode.removeChild(node)
  },

  createNodes(node, records, resourceNode) {
    node.beginUpdate()
    for (const record of Array.from(records)) {
      const children = []
      for (const child of Array.from(resourceNode.children)) {
        const childResourceNode = this.explorer.resources[child]
        if (childResourceNode.resource.explorable) {
          children.push({
            text: childResourceNode.resource.translations.plural,
            resource: childResourceNode.resource,
            resourceId: childResourceNode.resource.id,
            listeners: {
              contextmenu: {
                fn: this.explorer.handleContextMenu,
                scope: this.explorer
              }
            }
          })
        }
      }

      const n = this.createNode({
        text: escape(record.getName()),
        record,
        leaf: children.length === 0,
        children,
        iconCls: resourceNode.resource.getResourceCls('explorer'),
        listeners: {
          contextmenu: {
            fn: this.explorer.handleContextMenu,
            scope: this.explorer
          }
        },
        uiProvider: ExplorerNodeUI
      })
      if (n) {
        node.appendChild(n)
      }
    }
    return node.endUpdate()
  }
})

module.exports = ExplorerLoader
