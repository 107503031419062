const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const CurrencyAndPriceField = require('admin/component/form/CurrencyAndPriceField')
const DateField = require('admin/component/form/DateField')
const config = require('theming/config')

const InvoiceDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'invoice-detail-panel',

  module() {
    return require('admin/business/InvoiceResource')
  },

  getFormPanel(cfg) {
    delete cfg.bbar // prevent bleed over of config from list panel
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      width: 725,
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    return [
      {
        fieldLabel: t('Reference'),
        name: 'invoiceReference',
        readOnly: true,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Customer'),
        name: 'party.groupName',
        readOnly: true,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Invoice Type'),
        name: 'invoiceType',
        xtype: 'textfield',
        readOnly: true
      },
      new DateField({
        fieldLabel: t('Invoice Date'),
        format: Date.patterns.SHORT,
        name: 'invoiceDate',
        readOnly: true,
        width: config.panel.fieldWidth
      }),
      new CurrencyAndPriceField({
        currencyComboName: 'currencyUom',
        fieldLabel: t('Price'),
        priceAmountFieldName: 'amount'
      }),
      {
        fieldLabel: t('Status'),
        name: 'status',
        readOnly: true,
        xtype: 'textfield'
      }
    ]
  }
})

module.exports = InvoiceDetailPanel
