const Ext = require('ext')
const t = require('translate')
const CompanyResource = require('admin/business/CompanyResource')
const DistributorNewPanel = require('admin/view/DistributorNewPanel')
const DistributorListPanel = require('admin/view/DistributorListPanel')
const DistributorDetailPanel = require('admin/view/DistributorDetailPanel')
const DistributorRecord = require('admin/data/company/DistributorRecord')
const VendorResource = require('admin/business/VendorResource')

const DistributorResource = Ext.define(null, {
  extend: CompanyResource,
  singleton: true,

  id: 'SMX.Distributor',
  translations: {
    title: t('Distributor'),
    plural: t('Distributors'),
    new: t('New Distributor'),
    open: t('Open Distributor'),
    list: t('List Distributors')
  },
  name: t('Distributor'),
  code: 'DISTRIBUTOR',
  resourceName: '/distributor',

  operations: {
    create: true,
    open: true,
    remove: false,
    list: true,
    save: true
  },

  layout: {
    openResource: {
      width: 780,
      height: 570,
      minHeight: 570
    },
    newResource: {
      width: 400,
      height: 'auto'
    }
  },

  newPanel: DistributorNewPanel,
  listPanel: DistributorListPanel,
  detailPanel: DistributorDetailPanel,

  record: DistributorRecord,

  getParentResources() {
    return [VendorResource]
  }
})

module.exports = DistributorResource
