import isDeveloping from 'system/isDeveloping'
import isStaging from 'system/isStaging'

// TODO return SMX 365 Application client ID via API call instead of hardcoding here

function getSmx365ApplicationClientId() {
  if (isDeveloping()) {
    return '572c608a-1fc4-437f-8966-838c19e3a0e9'
  }
  if (isStaging()) {
    return '743105f8-6d19-49be-b46a-074dbe12f65f'
  }
  return '85cbf268-11db-42f6-86fd-b3a698ef8277'
}

export default getSmx365ApplicationClientId
