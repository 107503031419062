const Ext = require('ext')
const t = require('translate')
const CompanyResource = require('admin/business/CompanyResource')
const VendorGroupPanel = require('admin/view/VendorGroupPanel')
const VendorDetailPanel = require('admin/view/VendorDetailPanel')
const VendorNewPanel = require('admin/view/VendorNewPanel')
const VendorRecord = require('admin/data/company/VendorRecord')

const VendorResource = Ext.define(null, {
  extend: CompanyResource,
  singleton: true,

  id: 'SMX.Vendor',
  translations: {
    title: t('Vendor'),
    plural: t('Vendors'),
    new: t('New Vendor'),
    open: t('Open Vendors'),
    list: t('List Vendors')
  },
  name: t('Vendor'),
  code: 'VENDOR',
  resourceName: '/vendor',
  searchable: false,

  layout: {
    newResource: { width: 369, height: 489 },
    openResource: { width: 725, height: 570, minHeight: 570 }
  },

  groupPanel: VendorGroupPanel,
  detailPanel: VendorDetailPanel,
  newPanel: VendorNewPanel,

  record: VendorRecord
})

module.exports = VendorResource
