const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const HostingPlatformResource = require('admin/business/HostingPlatformResource')
const validators = require('validators').default

// TODO port to React. Should be easy since we already have
// the reducers, actions and subscribers in the mailhosting namespace
// see SCL-3318

const HostingDomainNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'hostingdomain-new-panel',

  module() {
    return require('admin/business/HostingDomainResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems() {
    return [
      new Ext.form.TextField({
        fieldLabel: t('Domain Name'),
        name: 'name',
        allowBlank: false,
        validator: validators.domain.withMsg(t('You must enter a valid DNS Name'))
      }),

      HostingPlatformResource.getComboBox({
        hiddenName: 'hostingPlatform'
      })
    ]
  }
})

module.exports = HostingDomainNewPanel
