import { createCachedSelector } from 're-reselect'
import logger from 'system/logger'

// this is a special selector many redux driven namespaces might want to reuse
// the use case is that our legacy ui can have multiple windows for each company
// this factory function returns a selector (aka subscriber) which extracts
// app state by given company

// todo later when our redux code has evolved consider moving this to another
// location, maybe in a new company namespace

const createCompanySelector = (module) => {
  const moduleDb = (db) => db[module]
  const companiesDb = (db) => moduleDb(db).companies

  return createCachedSelector(
    companiesDb,
    (db, companyKey) => companyKey,
    (companies, companyKey) => companies[companyKey]
  )((db, companyKey) => {
    if (!companyKey) {
      // helps you to remind when that crucial companyKey argument is missing
      logger.warn('Company key is missing for the cached company selector.')
    }

    // this is the key this gets cached into
    return companyKey
  })
}

export default createCompanySelector
