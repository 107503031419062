import ChangePassword from 'password/components/ChangePassword'
import Ext from 'ext'
import React from 'react'
import ReactContainerWindow from 'admin/view/ReactContainerWindow'
import t from 'translate'

const ChangePasswordWindow = Ext.define(null, {
  extend: ReactContainerWindow,
  cls: 'change-password-window',
  title: t('Change Password'),
  animCollapse: false,
  closable: false,
  layout: 'fit',
  maximizable: false,
  minimizable: false,
  modal: true,
  resizable: false,
  shim: false,
  width: 600,

  initComponent: function () {
    this.component = React.createElement(ChangePassword, {
      uri: this.initialConfig.uri,
      prompt: this.initialConfig.prompt,
      isOwnProfile: this.initialConfig.isOwnProfile,
      minLength: 8,
      onCancel: () => {
        if (!this.initialConfig.mandatory) {
          this.close()
        }
      },
      onSuccess: () => {
        this.close()
        if (this.initialConfig.successCallback) {
          this.initialConfig.successCallback()
        }
      }
    })

    // trick to call parent extjs function from es6 within
    this.superclass().initComponent.call(this)
  },

  // Disables default window focus so field can be focused instead.
  focus: () => {}
})

export default ChangePasswordWindow
