import * as hal from 'hal'

import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('provider')
const formDb = (db) => db.form

export const uri = createSelector(company, (company) => company?.uri)

export const list = createSelector(company, (company) => company?.list)

export const loading = createSelector(company, (company) => Boolean(company?.loading))

export const creating = createSelector(company, (company) => company?.creating)

export const configuring = createSelector(company, (company) => company?.configuring)

export const confirmingDeletion = createSelector(
  company,
  (company) => company?.confirmingDeletion
)

export const confirmingConfiguration = createSelector(
  company,
  (company) => company?.confirmingConfiguration
)

export const changedProviderName = createSelector(
  confirmingConfiguration,
  (confirmingConfiguration) => {
    return confirmingConfiguration?.name
  }
)

export const selected = createSelector(list, (list) =>
  list?.some((provider) => provider.selected)
)

export const selectedProvider = createSelector(list, (list) =>
  list?.find((provider) => provider.selected)
)

export const selectedProviderName = createSelector(
  selectedProvider,
  (selectedProvider) => selectedProvider && selectedProvider.name
)

export const selectedProviderLink = createSelector(
  selectedProvider,
  (selectedProvider) => selectedProvider && hal.self(selectedProvider)
)

export const size = createSelector(list, (list) => list?.length)

export const addButtonDisabled = createSelector(
  creating,
  configuring,
  loading,
  (creating, configuring, loading) => creating || configuring || loading
)

export const configureButtonDisabled = createSelector(
  creating,
  configuring,
  loading,
  selected,
  (creating, configuring, loading, selected) =>
    creating || configuring || loading || !selected
)

export const deleteButtonDisabled = createSelector(
  creating,
  configuring,
  loading,
  selected,
  (creating, configuring, loading, selected) =>
    creating || configuring || loading || !selected
)

export const refreshButtonDisabled = createSelector(
  creating,
  configuring,
  loading,
  (creating, configuring, loading) => creating || configuring || loading
)

export const formDisabled = createSelector(loading, (loading) => loading)

export const authenticationFormDisabled = createSelector(loading, (loading) => loading)

export const providerForm = createSelector(formDb, (formDb) => formDb.providerForm)

const providerAuthenticationForm = createSelector(
  formDb,
  (formDb) => formDb?.providerAuthenticationForm
)

const providerAuthenticationFormValues = createSelector(
  providerAuthenticationForm,
  (providerAuthenticationForm) => {
    return providerAuthenticationForm?.values
  }
)

export const providerAuthenticationFormPassword = createSelector(
  providerAuthenticationFormValues,
  (providerAuthenticationFormValues) => {
    return providerAuthenticationFormValues?.password
  }
)

export const hasModalDialog = createSelector(
  creating,
  configuring,
  confirmingDeletion,
  confirmingConfiguration,
  (creating, configuring, confirmingDeletion, confirmingConfiguration) => {
    return creating || configuring || confirmingDeletion || confirmingConfiguration
  }
)
