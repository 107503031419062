const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const TemplateRecord = require('smartrules/TemplateRecord')

const TemplateStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'templates',
  sortInfo: {
    field: 'name',
    direction: 'ASC'
  },

  record: TemplateRecord,

  module() {
    return require('smartrules/TemplateManagement')
  }
})

module.exports = TemplateStore
