const Ext = require('ext')

const RowSelectionModel = Ext.define(null, {
  extend: Ext.grid.RowSelectionModel,

  onEditorKey(field, e) {
    const k = e.getKey()
    const ed = this.grid.activeEditor

    if (k === e.TAB) {
      e.stopEvent()
      return ed.completeEdit()
    } else if (k === e.ENTER) {
      return e.stopEvent()
    } else if (k === e.ESC) {
      e.stopEvent()
      return ed.cancelEdit()
    }
  }
})

module.exports = RowSelectionModel
