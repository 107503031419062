const t = require('translate')
const Ext = require('ext')
const validators = require('validators').default
const SizeMatcher = require('smartrules/designer/matchers/SizeMatcher')

const LteMatcher = Ext.define(null, {
  extend: SizeMatcher,

  constructor(cfg) {
    const config = Ext.apply(cfg, { direction: 'less than' })
    this.callParent([config])
  },

  getTextfieldValidator() {
    return validators.isIntegerGreaterThanZero.withMsg(
      t('Please enter a positive number above zero')
    )
  }
})

module.exports = LteMatcher
