import * as hal from 'hal'

import { createSelector } from '@reduxjs/toolkit'

const ARCHIVING = 'smx3:capability/archiving/stable'
const SUBSCRIPTIONS = 'smx3:capability/subscriptions/stable'
const REPORTING = 'smx3:capability/reporting/stable'
const INVOICING = 'smx3:capability/invoicing/stable'
const SMARTRULES = 'smx3:capability/smartrules/stable'
const MAILBOXES = 'smx3:capability/mailboxes/stable'
const SELECTABLE_CUSTOMER_TYPES = 'smx3:capability/selectable-customer-types/stable'

const capabilityDb = (db) => db.capability

const list = createSelector(capabilityDb, (capabilityDb) => capabilityDb.list)

export const loaded = createSelector(list, (list) => !!list)

export const isCapableOfSelectableCustomerTypes = createSelector(list, (list) => {
  return Boolean(hal.href(list, SELECTABLE_CUSTOMER_TYPES))
})

export const isCapableOfSubscriptions = createSelector(list, (list) => {
  return Boolean(hal.href(list, SUBSCRIPTIONS))
})

export const isCapableOfInvoicing = createSelector(list, (list) => {
  return Boolean(hal.href(list, INVOICING))
})

export const isCapableOfReporting = createSelector(list, (list) => {
  return Boolean(hal.href(list, REPORTING))
})

export const isCapableOfMailboxes = createSelector(list, (list) => {
  return Boolean(hal.href(list, MAILBOXES))
})

export const isCapableOfArchiving = createSelector(list, (list) => {
  return Boolean(hal.resource(list, ARCHIVING))
})

export const isArchivingProvisioningCapabilityFlagEnabled = createSelector(
  list,
  (list) => {
    return (
      hal.first(hal.resource(list, ARCHIVING, 'enableArchivingProvisioning')) === 'true'
    )
  }
)

export const getSmartRuleCapabilities = createSelector(list, (list) => {
  if (!list) return
  return hal.first(hal.resource(list, SMARTRULES))
})

export const isSmartRuleNhiNumberPatternCapabilityEnabled = createSelector(
  list,
  (list) => {
    return hal.first(hal.resource(list, SMARTRULES, 'enableNhiMatching')) === 'true'
  }
)
