const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')
const ComboBox = require('admin/component/form/ComboBox')

const PrependPreamble = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Prepend preamble'),
      noSpacer: true,
      type: 'smartrules-prepend-preamble',
      nestable: true
    })

    this.callParent([cfg])

    const { templateStore } = cfg

    if (templateStore?.getCount() === 0) {
      templateStore.load()
    }

    const templateSelect = new ComboBox({
      allowBlank: false,
      displayField: 'name',
      editable: false,
      flex: 1,
      forceSelection: true,
      listEmptyText: `<div class="x-combo-list-item">${t('No templates found.')}</div>`,
      mode: 'local',
      name: 'template',
      ref: '../template',
      store: templateStore,
      triggerAction: 'all',
      value: cfg.value,
      valueField: 'name',
      minWidth: 200
    })

    this.insert(
      3,
      new Ext.Panel({
        flex: 1,
        layout: 'hbox',
        items: [templateSelect]
      })
    )
  },

  toDsl() {
    return `prepend [${this.template.getValue()}] preamble`
  }
})

module.exports = PrependPreamble
