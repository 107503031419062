const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const SupportNewPanel = require('admin/view/SupportNewPanel')

const SupportResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Support',
  translations: {
    title: t('Support'),
    new: t('Send Query'),
    open: t('Open Support'),
    list: t('List Support')
  },
  name: t('Support'),
  resourceName: 'support',
  submitOnEnter: false,

  layout: {
    newResource: {
      width: 416,
      height: 'auto'
    }
  },

  newPanel: SupportNewPanel
})

module.exports = SupportResource
