import React from 'react'

import { Checkbox } from 'react-bootstrap'

export default function FormCheckbox(props) {
  const { input, disabled, tabIndex } = props

  return (
    <Checkbox {...input} tabIndex={tabIndex} disabled={disabled} id={input.name}>
      {props.children}
    </Checkbox>
  )
}
