import Ext from 'ext'
import backendUnavailable from 'api/backendUnavailable'
import defaultHeaders from 'api/defaultHeaders'
import onBeforeRequest from 'api/onBeforeRequest'
import onCompleteRequest from 'api/onCompleteRequest'
import onRequestException from 'api/onRequestException'
import timeout from 'api/timeout'

const onBeforeLegacyRequest = async (conn, config) => await onBeforeRequest(config)

// SCL-3535 user was onboarded when already authenticated.
// logout to refresh with keycloak and AD
const onCompleteLegacyRequest = (conn, response) => onCompleteRequest(response)

const onRequestLegacyException = (conn, response, request) => {
  if (backendUnavailable(response.status)) {
    // so that it won't be processed further down in the extjs chain.
    // the user will have to resubmit the form.
    delete request.failure
    delete request.callback
  }

  onRequestException(response, request)
}

// Just a helper function being called on ExtJS App initialisation
// code, this to ensure it behaves the same as if we were using axios
export const initExtAjax = function () {
  Ext.Ajax.timeout = timeout
  Ext.Ajax.defaultHeaders = defaultHeaders

  // so that backend won't ask for a auth challenge when token is invalid
  // and we can silently redirect to login page
  Ext.lib.Ajax.defaultXhrHeader = 'Ext.basex'

  Ext.Ajax.on('beforerequest', onBeforeLegacyRequest)
  Ext.Ajax.on('requestcomplete', onCompleteLegacyRequest)
  Ext.Ajax.on('requestexception', onRequestLegacyException)
}
