const Ext = require('ext')
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const ComboBox = require('admin/component/form/ComboBox')
const SmartRule = require('smartrules/designer/SmartRule')
const Tooltip = require('admin/util/Tooltip')

const ListMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg) {
    let combo
    this.negated = cfg.negated

    this.combo = combo = new ComboBox({
      allowBlank: false,
      displayField: 'displayName',
      editable: false,
      emptyText: t('Select a list item'),
      flex: 1,
      forceSelection: true,
      listEmptyText: t('No lists found'),
      mode: 'local',
      store: cfg.listStore,
      triggerAction: 'all',
      valueField: 'name'
    })

    combo.on('select', function (combo, record) {
      // only register when quick tip is initialized. during jasmine tests this isn't the case.
      if (Ext.QuickTips.getQuickTip() != null) {
        return Tooltip.register(combo.getEl(), record.get('displayName'))
      }
    })

    const index = combo.store.find(combo.valueField, cfg.value)
    if (index >= 0) {
      combo.setValue(cfg.value)
    }

    this.callParent([
      {
        items: combo,
        matcherType: cfg.matcherType,
        value: cfg.value
      }
    ])

    return combo.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
  },

  setValue(v) {
    return this.combo.setValue(v)
  },

  getValue(options) {
    if (options == null) {
      options = {}
    }
    const raw = options.raw || false

    const { store } = this.combo
    const index = store.find(this.combo.valueField, this.combo.getValue())
    const record = store.getAt(index)
    const system = record != null ? record.get('system') : undefined
    let v = this.combo.getValue() || ''

    if (!raw) {
      v = String.format('{0}[{1}]', system ? '#' : '', v)
    }

    return v
  }
})

module.exports = ListMatcher
