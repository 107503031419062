import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Grid from 'components/Grid'
import Toolbar from './toolbar/Toolbar'
import Table from './Table'
import Create from './form/Create'
import Configure from './form/Configure'
import ConfirmDelete from './confirm/Delete'

import { creating, configuring, confirmingDeletion } from '../subs'

class Tab extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired
  }

  render() {
    let form

    if (this.props.creating) {
      form = <Create companyKey={this.props.companyKey} />
    } else if (this.props.configuring) {
      form = <Configure companyKey={this.props.companyKey} />
    } else if (this.props.confirmingDeletion) {
      form = <ConfirmDelete companyKey={this.props.companyKey} />
    }

    return (
      <Grid>
        <Toolbar store={this.props.store} companyKey={this.props.companyKey} />
        <Table
          width={this.props.width}
          height={this.props.height}
          store={this.props.store}
          companyKey={this.props.companyKey}
        />
        {form}
      </Grid>
    )
  }
}

const mapDbToProps = (db, props) => ({
  configuring: configuring(db, props.companyKey),
  creating: creating(db, props.companyKey),
  confirmingDeletion: confirmingDeletion(db, props.companyKey)
})

export default connect(mapDbToProps)(Tab)
