import User from 'admin/core/User'
import { DateTime, IANAZone } from 'luxon'
import t from 'translate'

const createTimeZone = (name, label) => {
  const tz = IANAZone.create(name)
  tz.label = label
  return tz
}

export const SORTED_TIMEZONES = [
  // ours first
  createTimeZone('Pacific/Auckland', t('Auckland')),
  // OZ by population
  createTimeZone('Australia/Sydney', t('Sydney')),
  createTimeZone('Australia/Melbourne', t('Melbourne')),
  createTimeZone('Australia/Brisbane', t('Brisbane')),
  createTimeZone('Australia/Perth', t('Perth')),
  // now rest alphabetical by city
  createTimeZone('Europe/Amsterdam', t('Amsterdam')),
  createTimeZone('America/Chicago', t('Chicago')),
  createTimeZone('America/Denver', t('Denver')),
  createTimeZone('Asia/Dubai', t('Dubai')),
  createTimeZone('Asia/Jakarta', t('Jakarta')),
  createTimeZone('Africa/Johannesburg', t('Johannesburg')),
  createTimeZone('Asia/Kolkata', t('Kolkata')),
  createTimeZone('Asia/Kuala_Lumpur', t('Kuala Lumpur')),
  createTimeZone('Europe/London', t('London')),
  createTimeZone('America/Los_Angeles', t('Los Angeles')),
  createTimeZone('America/New_York', t('New York')),
  createTimeZone('America/Phoenix', t('Phoenix')),
  createTimeZone('Asia/Singapore', t('Singapore')),
  createTimeZone('Asia/Tokyo', t('Tokyo')),
  createTimeZone('UTC', t('UTC'))
]

export const getTimeZoneByName = function (name) {
  return SORTED_TIMEZONES.find((tz) => tz.zoneName === name)
}

const getServerTimeZone = function () {
  return getTimeZoneByName(User.getDefaultTimezoneName())
}

export const getServerTimeZoneName = function () {
  const tz = getServerTimeZone()
  return tz?.zoneName
}

export const getBrowserTimeZoneName = function () {
  const local = DateTime.local()
  return local.zoneName
}

// this is for comboboxes to retrieve default time zone to select
// server time zone when browser time zone is not supported
export const getDefaultTimeZoneName = function () {
  const browserTimeZoneName = getBrowserTimeZoneName()
  const timeZone = getTimeZoneByName(browserTimeZoneName)
  if (timeZone) return timeZone.zoneName

  return getServerTimeZoneName()
}
