const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validateIpAddress = require('validators/legacyIp')

const TrustedIpNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'trustedip-new-panel',

  module() {
    return require('admin/business/TrustedIpResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems() {
    return [
      {
        fieldLabel: t('Mail Server'),
        name: 'name',
        validator: validateIpAddress,
        allowBlank: false,
        xtype: 'textfield'
      }
    ]
  }
})

module.exports = TrustedIpNewPanel
