const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const UserGroupPanel = require('admin/view/UserGroupPanel')
const UserDetailPanel = require('admin/view/UserDetailPanel')
const UserNewPanel = require('admin/view/UserNewPanel')
const UserListPanel = require('admin/view/UserListPanel')
const UserRecord = require('admin/data/UserRecord')
const VendorResource = require('admin/business/VendorResource')
const DistributorResource = require('admin/business/DistributorResource')
const ResellerResource = require('admin/business/ResellerResource')

const UserResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.User',
  translations: {
    title: t('User'),
    plural: t('Users'),
    new: t('New User'),
    open: t('Open User'),
    list: t('List Users'),
    remove: t('Remove User')
  },
  name: t('User'),
  code: 'USER',
  resourceName: '/user',
  searchable: true,
  explorable: true,

  layout: {
    newResource: {
      width: 440,
      height: 500
    },
    openResource: {
      width: 490,
      height: 500
    }
  },

  groupPanel: UserGroupPanel,
  detailPanel: UserDetailPanel,
  newPanel: UserNewPanel,
  listPanel: UserListPanel,
  record: UserRecord,

  getParentResources() {
    return [VendorResource, DistributorResource, ResellerResource]
  },

  // Override to make sure we have full record before opening.
  // Lite versions are used in lists.
  open(record, options) {
    // Full versions should have a defaultURI property.
    if (record.get('defaultURI')) {
      return this.callParent(arguments)
    }
    record.fetch({
      success: () => {
        this.superclass().open.call(this, record, options)
      }
    })
  }
})

module.exports = UserResource
