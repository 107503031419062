const Ext = require('ext')

const ReadOnlyTextArea = Ext.define(null, {
  extend: Ext.form.TextArea,

  constructor(cfg = {}) {
    cfg.readOnly = true
    if (!cfg.cls) {
      cfg.cls = 'readonly-textarea'
    }

    if (cfg.clone) {
      Ext.applyIf(cfg, {
        helpText: cfg.clone.helpText,
        hideLabel: cfg.clone.hideLabel,
        fieldLabel: cfg.clone.fieldLabel,
        name: cfg.clone.name,
        width: cfg.clone.width,
        height: cfg.clone.height
      })
    }

    this.callParent([cfg])
  },

  setValue(value) {
    if (value != null) {
      this.callParent([value])
    }
  },

  highlighted: false,

  highlight() {
    return (this.highlighted = true)
  },

  unHighlight() {
    return (this.highlighted = false)
  },

  isHighlighted() {
    return this.highlighted
  }
})

module.exports = ReadOnlyTextArea
