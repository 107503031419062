import React, { Component } from 'react'
import { doubleClickProvider, refresh, select } from '../actions'
import { list, loading, uri } from '../subs'

import PropTypes from 'prop-types'
import SelectableTable from 'components/table/SelectableTable'
import _s from 'underscore.string'
import { connect } from 'react-redux'
import t from 'translate'

const BOOTSTRAP_PADDING = 5
const TOOLBAR_MIN_HEIGHT = 65 + 2 * BOOTSTRAP_PADDING

const renderPrivate = (isPrivate) => (isPrivate ? t('Yes') : t('No'))
const renderMappingPolicy = (mappingPolicy) => t(_s.capitalize(mappingPolicy))

class ProviderTable extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    configurable: PropTypes.bool,
    allowMappingPolicy: PropTypes.bool,
    companyKey: PropTypes.string.isRequired
  }

  componentDidMount() {
    this.props.refresh(this.props.uri)
  }

  get width() {
    if (typeof this.props.width === 'number') {
      return this.props.width - BOOTSTRAP_PADDING * 3
    }
    return this.props.width
  }

  get maxHeight() {
    if (typeof this.props.height === 'number') {
      return this.props.height - TOOLBAR_MIN_HEIGHT - BOOTSTRAP_PADDING * 4
    }
    return this.props.height
  }

  handleRowDoubleClick = (index, provider) => {
    if (this.props.configurable) {
      this.props.doubleClickProvider(provider)
    }
  }

  getResults() {
    if (this.props.list) {
      return this.props.list
    }
  }

  render() {
    const columns = [
      {
        name: 'name',
        title: t('Provider'),
        width: 160,
        flexGrow: 3,
        isResizable: true,
        cellClassName: 'truncate-cell'
      },
      {
        name: 'private',
        title: t('Private?'),
        width: 80,
        flexGrow: 1,
        isResizable: true,
        renderCell: renderPrivate
      }
    ]

    if (this.props.allowMappingPolicy) {
      columns.push({
        name: 'mapping-policy',
        title: t('Mapping Policy'),
        width: 130,
        flexGrow: 1,
        isResizable: true,
        renderCell: renderMappingPolicy,
        cellClassName: 'truncate-cell'
      })
    }

    return (
      <SelectableTable
        disabled={this.props.loading}
        width={this.width}
        maxHeight={this.maxHeight}
        results={this.getResults()}
        onRowDoubleClick={this.handleRowDoubleClick}
        onSelect={this.props.onSelect}
        rowClassName='provider-row'
        columns={columns}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  list: list(db, props.companyKey),
  loading: loading(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  refresh: (uri) => dispatch(refresh(initialProps.companyKey, uri)),
  onSelect: (providers, selected) =>
    dispatch(select(initialProps.companyKey, providers, selected)),
  doubleClickProvider: (provider) =>
    dispatch(doubleClickProvider(initialProps.companyKey, provider))
})

export default connect(mapDbToProps, mapActionsToProps)(ProviderTable)
