const Ext = require('ext')

const ListStore = Ext.define(null, {
  extend: Ext.data.JsonStore,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      fields: [
        {
          name: 'id'
        },
        {
          name: 'name'
        },
        {
          name: 'displayName',
          mapping: 'name'
        },
        {
          name: 'system',
          type: 'boolean'
        },
        {
          name: 'items'
        }
      ]
    })
    this.callParent([cfg])
  },

  multiSort: true,

  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      {
        field: 'system',
        direction: 'ASC'
      },
      {
        field: 'name',
        direction: 'ASC'
      }
    ]
  },

  addList(record, system) {
    const data = Ext.applyIf(record.data, {
      displayName: record.get('name'),
      system
    })
    const RecordTypeClass = this.recordType
    this.add(new RecordTypeClass(data, data.id))
  }
})

module.exports = ListStore
