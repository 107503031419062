import setIn from 'lodash/set'
import { NOTIFICATION_REL } from 'product/resources'
import {
  NOTIFICATION_SET_COMPANY_KEY,
  NOTIFICATION_SET_LIST,
  NOTIFICATION_SET_URI,
  NOTIFICATION_START_LOADING,
  NOTIFICATION_STOP_LOADING,
  NOTIFICATION_UNLOAD
} from 'quarantine/notification/actions'
import initialState from 'quarantine/notification/db'

import { createNextState } from '@reduxjs/toolkit'

const getBaseKeyPath = (companyKey) => ['companies', companyKey, NOTIFICATION_REL]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])

const reducers = {
  [NOTIFICATION_SET_URI]: (notificationDb, action) => {
    return setIn(notificationDb, getKeyPath(action.companyKey, 'uri'), action.uri)
  },

  [NOTIFICATION_SET_COMPANY_KEY]: (notificationDb, action) => {
    return setIn(
      notificationDb,
      getKeyPath(action.companyKey, 'companyKey'),
      action.companyKey
    )
  },

  [NOTIFICATION_START_LOADING]: (notificationDb, action) => {
    return setIn(notificationDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [NOTIFICATION_STOP_LOADING]: (notificationDb, action) => {
    return setIn(notificationDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [NOTIFICATION_UNLOAD]: (notificationDb, { companyKey }) => {
    delete notificationDb.companies[companyKey]
    return notificationDb
  },

  [NOTIFICATION_SET_LIST]: (notificationDb, action) => {
    return setIn(notificationDb, getKeyPath(action.companyKey, 'list'), action.newList)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
