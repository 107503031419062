const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const SubscriptionRecord = require('reporting/SubscriptionRecord')
const Subscription = require('reporting/Subscription')

const SubscriptionStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'subscriptions',
  record: SubscriptionRecord,

  module() {
    return Subscription
  }
})

module.exports = SubscriptionStore
