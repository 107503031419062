const Ext = require('ext')
const t = require('translate')
const ProductDirection = require('product/direction')
const Alert = require('admin/util/Alert').default
const User = require('admin/core/User')
const Calendar = require('admin/time/Calendar')
const TimeZone = require('admin/time/TimeZone')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const TimeZoneComboBox = require('admin/component/form/TimeZoneComboBox')
const ComboBox = require('admin/component/form/ComboBox')
const DateRangeFieldSet = require('admin/component/form/DateRangeFieldSet')
const timeout = require('api/timeout')
const encodeForm = require('url/encodeForm').default
const Subscription = require('reporting/Subscription')
const EventResource = require('admin/business/EventResource')
const EventSearchResultStore = require('admin/data/EventSearchResultStore')
const EventGridPanel = require('admin/component/grid/EventGridPanel')
const token = require('api/token')
const isSmxemail = require('system/isSmxemail').default

const getMinFromDate = (timeZoneName) =>
  Calendar.atMidnight(Date.now(), timeZoneName).minus({ years: 1 }).toJSDate()
const getMaxThruDate = (timeZoneName) =>
  Calendar.atMidnight(Date.now(), timeZoneName).toJSDate()

const DAY_TO_MILLISECS = 24 * 60 * 60 * 1000
const DEFAULT_TITLE = t('Messages')

const EventSearchPanel = Ext.define(null, {
  extend: Ext.Panel,

  constructor(cfg = {}) {
    let hasResults = false
    let loading = false

    this.module = EventResource

    let bbar = []
    const thru = new Date()

    let store = new EventSearchResultStore({
      hasSmartRules: cfg.customer.hasProductKey('SMART_RULES')
    })

    const timeZoneCombo = new TimeZoneComboBox({
      name: 'timeZone'
    })

    const eventList = new EventGridPanel({
      hasSmartRules: cfg.customer.hasProductKey('SMART_RULES'),
      timeZone: timeZoneCombo.getTimeZone(),
      getSearchParameters() {
        const searchParameters = Ext.apply({}, this.currentRecord.data)
        delete searchParameters.url
        delete searchParameters.id
        return searchParameters
      },
      store,
      title: cfg.title,
      product: cfg.product,
      customer: cfg.customer
    })

    thru.setHours(0, 0, 0, 0)
    const from = thru.add(Date.DAY, -3)

    const wildcardHelpText = t(
      "Wildcard '*' can be used - " + "'hotmail.*' matches hotmail.com, hotmail.com.tw"
    )

    let searching = false
    const revertTimeout = null

    const statusStore = [
      ['', t('All')],
      ['AAMTA_RECEIVED', t('Received')],
      ['AAMTA_REJECTED', t('Rejected')],
      ['MTA_QUEUED', t('Queued')],
      ['MTA_EXPIRED_FROM_QUEUE', t('Expired From Queue')],
      ['RECIPIENT_MTA_REJECTED', t('Rejected By Recipient')],
      ['MTA_DISCARDED', t('Discarded')],
      ['DELIVERED', t('Delivered')]
    ]

    if (cfg.customer.hasProductKey('QUARANTINE') && User.hasRole('QUARANTINE')) {
      statusStore.push(['QUARANTINED', t('Quarantined')])
    }

    const downloadButton = new Ext.Button({
      handler() {
        return formPanel.exportSearch()
      },
      text: t('Download CSV')
    })

    const subscribeButton = new Ext.Button({
      handler() {
        return formPanel.subscribe()
      },
      text: t('Subscribe')
    })

    bbar = ['->', downloadButton, subscribeButton]

    let tagsFieldSet
    const eventTags = cfg.customer.getEventTags()

    if (eventTags?.length > 0) {
      tagsFieldSet = new Ext.form.FieldSet({
        cls: 'mail-search-tags',
        title: t('Tags'),
        collapsible: true,
        collapsed: true,
        style: `margin-left: 81px;min-height: ${eventTags.length * 35}px`,
        items: [
          new Ext.form.CheckboxGroup({
            name: 'tags',
            hideLabel: true,
            columns: 1,
            vertical: true,
            width: '90%',
            items: eventTags.map(function (eventTag) {
              return { boxLabel: eventTag.name, name: 'tag', inputValue: eventTag.tag }
            })
          })
        ]
      })
    }

    let connectionRejectionsCheckbox
    if (isSmxemail()) {
      // currently only smxemail has the connection rejections logged
      connectionRejectionsCheckbox = new Ext.form.Checkbox({
        boxLabel: t('Include Connection Rejections'),
        name: 'includeConnectionRejections',
        checked: false
      })
    }

    const items = [
      {
        fieldLabel: t('From'),
        name: 'from',
        xtype: 'textfield',
        helpText: wildcardHelpText
      },
      {
        fieldLabel: t('To'),
        name: 'to',
        xtype: 'textfield',
        helpText: wildcardHelpText
      },
      {
        fieldLabel: t('Subject'),
        name: 'subject',
        xtype: 'textfield',
        helpText: wildcardHelpText
      },
      // DateRangeFieldSet  is a wrapper for two DateTimeFields
      new DateRangeFieldSet({
        labelSeparator: '',
        minValue: getMinFromDate(timeZoneCombo.getTimeZone()),
        maxValue: getMaxThruDate(timeZoneCombo.getTimeZone()),
        from: {
          value: from,
          name: 'fromDate'
        },
        thru: {
          value: thru,
          name: 'thruDate'
        },
        both: {
          allowBlank: false,
          labelWidth: 70,
          fieldWidth: 110
        },
        width: 'auto'
      }),
      timeZoneCombo,
      new ComboBox({
        fieldLabel: t('Mail Type'),
        hiddenName: 'type',
        value: '',
        store: this.module.MAIL_TYPES,
        editable: false,
        triggerAction: 'all',
        listWidth: 150
      }),
      new ComboBox({
        itemCls: 'smallerLabel',
        fieldLabel: t('Delivery Status'),
        hiddenName: 'status',
        value: '',
        store: statusStore,
        editable: false,
        triggerAction: 'all',
        listWidth: 150
      }),
      connectionRejectionsCheckbox,
      {
        fieldLabel: t('Message ID'),
        name: 'messageId',
        xtype: 'textfield',
        helpText: wildcardHelpText
      },
      connectionRejectionsCheckbox,
      {
        fieldLabel: t('SMX ID'),
        name: 'sid',
        xtype: 'textfield'
      },
      new ComboBox({
        fieldLabel: t('Size >='),
        width: 80,
        hiddenName: 'messageSize',
        value: 0,
        store: this.module.messageSizeStore,
        editable: false,
        triggerAction: 'all'
      }),
      new ComboBox({
        fieldLabel: t('Page Size'),
        width: 80,
        hiddenName: 'limit',
        value: 100,
        store: [10, 20, 50, 100, 200, 500, 1000],
        editable: false,
        triggerAction: 'all'
      }),
      tagsFieldSet
    ].filter(Boolean)

    if (['OUTBOUND_SCRUB', 'INBOUND_SCRUB'].indexOf(eventList.product.productCode) < 0) {
      items.splice(
        3,
        0,
        new Ext.form.RadioGroup({
          columns: 2,
          fieldLabel: t('Direction'),
          style: 'margin-bottom: -4px;',
          width: '90px',
          items: [
            {
              boxLabel: t('In'),
              inputValue: ProductDirection.DIRECTION_INBOUND,
              name: 'direction',
              checked: false,
              style: 'margin: 0 0 0 1px; vertical-align: middle;',
              xtype: 'radio'
            },
            {
              boxLabel: t('Out'),
              inputValue: ProductDirection.DIRECTION_OUTBOUND,
              name: 'direction',
              checked: true,
              style: 'margin: 0 0 0 1px; vertical-align: middle;',
              xtype: 'radio'
            }
          ],
          name: 'direction'
        })
      )
    }

    // TODO: Shouldn't use RecordFormPanel because it doesn't have a record
    const formPanel = new RecordFormPanel({
      labelSeparator: '', // we do not want that default ugly one `:`
      buttonAlign: 'right',
      buttons: [
        {
          text: t('Reset'),
          handler() {
            return formPanel.getForm().reset()
          }
        },
        {
          handler() {
            return formPanel.doSearch()
          },
          ref: '../searchButton',
          text: t('Search'),
          xtype: 'button'
        }
      ],
      // fine tuned for alignment
      labelWidth: 77,
      fieldWidth: 170,
      items,
      keys: [
        {
          key: [Ext.EventObject.ENTER],
          fn() {
            return formPanel.doSearch()
          }
        }
      ],
      doSearch() {
        if (!this.getForm().isValid()) return

        const search = this.getSearchParameters()

        if (search) {
          this.searchButton?.disable()

          downloadButton.disable()
          subscribeButton.disable()

          resultsPanel.el.mask(Ext.LoadMask.prototype.msg, 'x-mask-loading')

          searching = true
          loading = true

          eventList.setTimeZoneName(search.timeZone)
          // TODO: Clean up like domain summary
          const SearchRecordClass = eventList.eventSearchStore.record
          const searchRecord = new SearchRecordClass(search)
          eventList.eventSearchStore.add(searchRecord)
          searchRecord.save(null, {
            success() {
              eventList.eventSearchStore.fireEvent('searchready', searchRecord)
            },
            error: () => {
              Alert.alert(
                t('Error'),
                t('Mail reporting is currently unavailable. ' + 'Please try again later.')
              )
              this.restoreSearch()
            }
          })

          if (!this.restoreTask) {
            this.restoreTask = new Ext.util.DelayedTask(this.restoreSearch, this)
          }

          this.restoreTask.delay(timeout)
        }
      },
      subscribe() {
        const { customer } = this.findParentBy((container) => !!container.customer)

        store = customer.getReportStore()
        store.load()
        store.on(
          'load',
          function () {
            const record = store.getAt(store.find('id', 'mailevents'))
            const defaultsMap = this.getSearchParameters()

            Ext.applyIf(defaultsMap, {
              type: 'ALL',
              period: 'P3D'
            })
            return Subscription.create(record, { store, defaultsMap })
          },
          this,
          { single: true }
        )
      },
      getSearchParameters() {
        const form = this.getForm()

        const fromDateRaw = form.findField('fromDate').getValue()
        const thruDateRaw = form.findField('thruDate').getValue()

        // do not parse dates when input field is empty (since SCL-3120)
        // (this to prevent them becoming the default of 1970)
        const fromDisplayDate = fromDateRaw ? new Date(fromDateRaw) : undefined
        const thruDisplayDate = thruDateRaw ? new Date(thruDateRaw) : undefined

        const timeZone = form.findField('timeZone').getTimeZone()

        if (fromDisplayDate && thruDisplayDate) {
          if (fromDisplayDate < getMinFromDate(timeZone)) {
            Alert.alert(
              t('Invalid From Date'),
              t('Mail search results are available for the last year only')
            )
          } else {
            const search = this.getForm().getFieldValues(false)
            const tags = search.tags

            if (tags && tags.length > 0) {
              search.tag = search.tags.map(function (field) {
                return field.inputValue
              })
            }

            // do not need them anymore and prevents SCL-3616
            delete search.tags

            if (search.direction) {
              search.direction = search.direction.getRawValue()
            }

            const defaults = {
              limit: 100,
              order: 'DESC',
              sort: 'date',
              direction: ProductDirection.getDirection(eventList.product.productCode)
            }

            Ext.applyIf(search, defaults)
            Ext.apply(search, { timeZoneName: timeZone.zoneName })

            if (search.includeConnectionRejections) {
              search.status = [search.status, 'CONNECTION_REJECTED']
              delete search.includeConnectionRejections
            }

            return search
          }
        } else {
          Alert.alert(
            t('Invalid Date Range'),
            t('A date range must be set for message searching.')
          )
        }
      },

      restoreSearch() {
        let title, titleHtml, titleText

        if (this.isDestroyed) return

        searching = false
        const search = this.getSearchParameters()

        // abort when invalid, see SCL-3120
        if (!search) return

        if (hasResults) {
          let direction, joiningWord
          if (ProductDirection.isOutbound(search.direction)) {
            joiningWord = t('from')
          } else {
            joiningWord = t('to')
          }

          const recordPanel = formPanel.findParentBy((container) => !!container.record)

          const customerName = recordPanel?.record.get('displayName') || ''

          if (ProductDirection.isInbound(search.direction)) {
            direction = t('Inbound')
          } else {
            direction = t('Outbound')
          }

          const tileParams = [
            direction,
            joiningWord,
            customerName,
            TimeZone.getTimeZoneByName(search.timeZoneName).label,
            t('Double click on a message for details')
          ]

          title =
            '<span class="right-hint">{4}</span> Messages:' +
            '{0} {1} {2} <span class="muted">({3} Time Zone)</span>'

          titleHtml = t(title, tileParams)

          titleText = t('Messages: {0} {1} {2} ({3} Time Zone) {4}', tileParams)
        } else if (loading) {
          titleText = titleHtml = 'Loading...'
        } else {
          titleText = titleHtml = t('No events found matching this search criteria')
        }

        resultsPanel.setTitle(titleHtml)
        Ext.fly(resultsPanel.header.dom).set({ title: titleText })

        this.searchButton?.enable()

        downloadButton.enable()
        subscribeButton.enable()

        resultsPanel.el.unmask()
        clearTimeout(revertTimeout || 0)
      },

      exportSearch() {
        const defaultSearchParameters = this.getSearchParameters()

        const basetimestamp = Calendar.normalizeThruDate(
          defaultSearchParameters.thruDate,
          defaultSearchParameters.timeZoneName
        )

        const searchParameters = Ext.applyIf(defaultSearchParameters, {
          basetimestamp: basetimestamp.ts,
          status: '',
          type: 'ALL'
        })

        const difference =
          new Date(defaultSearchParameters.thruDate) -
          new Date(defaultSearchParameters.fromDate)

        const period = Math.round((difference + DAY_TO_MILLISECS) / DAY_TO_MILLISECS)

        searchParameters.period = `P${period}D`
        delete searchParameters.fromDate
        delete searchParameters.thruDate
        delete searchParameters.limit

        // Don't send type param if set to ALL
        if (searchParameters.type === 'ALL') {
          delete searchParameters.type
        }

        Object.keys(searchParameters).forEach(function (key) {
          const value = searchParameters[key]

          if (key !== 'status' && !searchParameters[key]) {
            delete searchParameters[key] // so doesn't send empty params
          }

          if (key === 'messageSize' && value === '0') {
            return delete searchParameters.messageSize
          }
        })

        searchParameters.timezone = defaultSearchParameters.timeZoneName
        delete searchParameters.timeZone
        delete searchParameters.timeZoneName

        const { customer } = this.findParentBy((container) => !!container.customer)

        const authUrl = token.getAuthorisationUrl(customer.getUrl())
        const url = `${authUrl}/reports/mailevents.csv?${encodeForm(searchParameters)}`

        window.open(url)
      }
    })

    const formContainerPanel = new Ext.Panel({
      bbar,
      title: t('Search'),
      region: 'west',
      width: 300,
      minSize: 300,
      collapsible: true,
      autohide: false,
      collapsed: false,
      floatable: true,
      autoScroll: true,
      items: formPanel
    })

    const resultsPanel = new Ext.Panel({
      title: DEFAULT_TITLE,
      region: 'center',
      bodyCssClass: 'left-border',
      layout: 'fit',
      items: [eventList]
    })

    eventList.store.on('load', function (search, results) {
      hasResults = results?.length > 0
      loading = false
      formPanel.restoreSearch()
    })

    eventList.store.on('exception', () => formPanel.restoreSearch())

    formPanel.on(
      'clientvalidation',
      function (form, valid) {
        this.searchButton?.setDisabled(searching || !valid)
        downloadButton.setDisabled(searching || !valid)
        subscribeButton.setDisabled(searching || !valid)
      },
      formPanel
    )

    formPanel.focus = () => eventList.getForm().findField(3).focus(true)

    Ext.applyIf(cfg, {
      title: cfg.title !== false ? this.module.name : null,
      layout: 'border',
      items: [formContainerPanel, resultsPanel]
    })

    this.callParent([cfg])
  }
})

module.exports = EventSearchPanel
