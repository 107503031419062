const Ext = require('ext')
const t = require('translate')
const ResourceUtil = require('admin/util/ResourceUtil')
const logger = require('system/logger').default

const ResourceNewPanel = Ext.define(null, {
  extend: Ext.Panel,

  constructor: function (cfg = {}) {
    if (this.module && typeof this.module === 'function') {
      this.module = this.module()
    }

    cfg.module = this.module = this.module || cfg.module

    if (!cfg.autoHeight && this.module.layout.newResource.height === 'auto') {
      cfg.autoHeight = true
    }

    cfg.layout = cfg.layout || 'fit'
    cfg.store = cfg.store || this.store

    if (!cfg.store) throw Error('No store')

    this.formPanel = this.getFormPanel(cfg)

    this.formPanel.addButton({
      cls: 'e2e-resource-new-panel-cancel',
      text: t('Cancel'),
      handler: this.handleCancel,
      scope: this
    })

    this.formPanel.saveButton = this.formPanel.addButton({
      cls: 'e2e-resource-new-panel-save primary',
      text: this.saveText || t('Save'),
      handler: this.handleSave,
      scope: this,
      formBind: true
    })

    if (this.module.submitOnEnter) {
      this.formPanel.on(
        'enter-key-pressed',
        function () {
          if (!this.formPanel.saveButton.disabled) {
            return this.handleSave()
          }
        },
        this
      )
    }

    cfg.items = [
      {
        autoHeight: true,
        border: false,
        items: this.formPanel
      }
    ]

    this.callParent([cfg])
  },

  getSaveOptions: function () {
    return {}
  },

  disableForm: function () {
    this.formPanel.saveButton?.setDisabled(true)
  },

  enableForm: function () {
    this.saving = false
    this.formPanel.saveButton?.setDisabled(false)
  },

  onSuccessfulSave: function (record) {
    this.store.addSorted(record)
    this.formPanel.allowClose = true
    this.close()
  },

  onSaveComplete: function () {
    this.saving = false
    Ext.MessageBox.hide()
  },

  handleSave: function () {
    if (!this.store) return
    if (!this.formPanel) return
    if (this.saving) return

    this.saving = true

    this.disableForm()

    const formValues = this.getFormValues()
    const Record = this.store.record
    const record = new Record(
      ResourceUtil.reconvertValues(this.store.fields.map, formValues)
    )

    const defaultSaveOptions = this.getSaveOptions(this.record)
    const saveOptions = Object.assign(
      {
        url: this.store.url,
        enableForm: () => this.enableForm(),
        success: () => this.onSuccessfulSave(record),
        error: (record, response, options, err) => logger.error(err),
        complete: () => this.onSaveComplete()
      },
      defaultSaveOptions
    )

    Ext.MessageBox.wait(t('Saving...'))

    return record.save(null, saveOptions)
  },

  getFormValues: function () {
    const fieldValues = this.formPanel.getForm().getFieldValues()

    return Object.keys(fieldValues).reduce(function (formValues, key) {
      const value = fieldValues[key]

      if (value && typeof value.valueOf() === 'string') {
        formValues[key] = value.trim()
      } else {
        formValues[key] = value
      }

      return formValues
    }, {})
  },

  handleCancel: function () {
    this.close()
  },

  close: function () {
    const parentWindow = this.findParentWindow()
    parentWindow?.close()
  },

  getFocusEl: function () {
    return this.formPanel?.get(this.getFocusId())
  },

  focus: function () {
    const el = this.getFocusEl()
    el?.focus()
  },

  getFormPanel: Ext.emptyFn,

  getNewItems: function () {
    return []
  },

  getFocusId: function () {
    return 0
  }
})

module.exports = ResourceNewPanel
