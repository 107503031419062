import React, { Component } from 'react'
import { HelpBlock, Row, Col } from 'react-bootstrap'

class Help extends Component {
  render() {
    return (
      <Row>
        <Col className='col-xs-8 col-xs-offset-3'>
          <HelpBlock>{this.props.children}</HelpBlock>
        </Col>
      </Row>
    )
  }
}

export default Help
