import React, { PureComponent } from 'react'

import DateRange from 'components/form/DateRange'
import TimeZoneInput from 'components/legacy/TimeZoneInput'
import AutoCompleteInput from 'mail/search/form/views/AutoCompleteInput'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup, Radio } from 'react-bootstrap'
import t from 'translate'

class SearchForm extends PureComponent {
  static propTypes = {
    onDateRangeChange: PropTypes.func.isRequired,
    onDateRangeShow: PropTypes.func.isRequired,
    onDateRangeHide: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onTimeZoneNameChange: PropTypes.func.isRequired,
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
    onDirectionChange: PropTypes.func,
    direction: PropTypes.oneOf(['inbound', 'outbound']),
    loading: PropTypes.bool,
    timeZoneName: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    fromDate: PropTypes.instanceOf(Date),
    thruDate: PropTypes.instanceOf(Date)
  }

  hasRequiredParams() {
    return this.props.query && this.props.fromDate && this.props.thruDate
  }

  submit = (e) => {
    e.preventDefault()

    if (this.hasRequiredParams()) {
      this.props.onSearch()
    }
  }

  // move to a separate, pure functional file so that this can be unit-tested
  getSuggestions() {
    const words = this.props.query && this.props.query.split(/\s/)
    const currentWord = words && words[words.length - 1]
    const keywords = this.props.availableKeywords || []

    // Only look for keywords when 2+ letters.
    if (currentWord?.length >= 2) {
      const suggestions = keywords
        .filter((kw) => kw.indexOf(currentWord) === 0)
        .map((kw) => kw + ':')

      return [].concat(...suggestions).filter(Boolean)
    }
  }

  renderDirectionInput() {
    return (
      <div className='mail-searchbox-controls-container'>
        <FormGroup>
          <Radio
            inline
            name='direction'
            value='inbound'
            onChange={this.props.onDirectionChange}
            checked={this.props.direction === 'inbound'}
          >
            In
          </Radio>
          <Radio
            inline
            name='direction'
            value='outbound'
            onChange={this.props.onDirectionChange}
            checked={this.props.direction === 'outbound'}
          >
            Out
          </Radio>
        </FormGroup>
      </div>
    )
  }

  render() {
    return (
      <Form onSubmit={this.submit}>
        <div className='mail-searchbox'>
          <div className='mail-searchbox-input-container'>
            <AutoCompleteInput
              displayValue={this.props.query}
              suggestions={this.getSuggestions()}
              placeholder={t('Enter mail search text')}
              onChange={this.props.onQueryChange}
            />
          </div>
          {this.props.onDirectionChange && this.renderDirectionInput()}
          <div className='mail-searchbox-controls-container'>
            <TimeZoneInput
              timeZoneName={this.props.timeZoneName}
              onChange={this.props.onTimeZoneNameChange}
            />
          </div>
          <div className='mail-searchbox-controls-container'>
            <DateRange
              onChange={this.props.onDateRangeChange}
              startDate={this.props.startDate}
              fromDate={this.props.fromDate}
              thruDate={this.props.thruDate}
              onShow={this.props.onDateRangeShow}
              onHide={this.props.onDateRangeHide}
            />
          </div>
          <div className='mail-searchbox-controls-container'>
            <Button
              bsStyle='primary'
              type='submit'
              className='icon-search'
              disabled={this.props.loading || !this.hasRequiredParams()}
            >
              {t('Search')}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

export default SearchForm
