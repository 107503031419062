const Ext = require('ext')
const t = require('translate')
const WindowMgr = require('admin/desktop/WindowMgr').default

const TaskButton = Ext.define(null, {
  extend: Ext.Button,

  constructor(cfg = {}) {
    const { win, taskbar } = cfg

    cfg = Ext.applyIf(cfg, {
      listeners: {
        afterrender() {
          this.taskbar.find('button').forEach(function (btn) {
            return btn.toggle(btn === this)
          }, this)

          this.el.on(
            'contextmenu',
            function (e) {
              e.stopEvent()
              if (!this.cmenu.rendered) {
                this.cmenu.render()
              }
              let [x, y] = Array.from(e.getXY())
              y -= this.cmenu.getHeight()
              this.cmenu.showAt([x, y])
            },
            this
          )
        },
        click(button) {
          const { win } = button

          if (!button.pressed && win === WindowMgr.windowGroup.getActive()) {
            win.minimize()
          }
        },
        scope: this,
        toggle(button, pressed) {
          const { win } = button
          if (pressed) {
            if (win.minimized) {
              win.show()
            }
            win.setActive(true)
            WindowMgr.select(win.id)
          }
        }
      },
      text: Ext.util.Format.ellipsis(win.taskbar || win.title, 25),
      toggleGroup: 'taskbar'
    })
    const items = []
    if (win && taskbar) {
      if (win.minimizable) {
        items.push(
          {
            cls: 'restore',
            text: t('Restore'),
            handler() {
              if (win.isVisible()) {
                win.restore()
              } else {
                win.show()
              }
              taskbar.updateContextMenus()
            },
            scope: this
          },
          {
            cls: 'minimise',
            text: t('Minimize'),
            handler() {
              win.minimize()
              taskbar.updateContextMenus()
            },
            scope: this
          }
        )
      }
      if (win.maximizable) {
        items.push({
          cls: 'maximise',
          text: t('Maximize'),
          handler() {
            win.maximize()
            taskbar.updateContextMenus()
          },
          scope: this
        })
      }
      items.push(
        '-',
        {
          cls: 'minimiseAll',
          text: t('Minimize all'),
          handler() {
            taskbar.minimiseAll()
          },
          scope: this
        },
        {
          cls: 'closeAll',
          text: t('Close all'),
          handler() {
            taskbar.closeAll()
          },
          scope: this
        },
        {
          cls: 'closeOthers',
          text: t('Close others'),
          handler() {
            taskbar.closeOthers(win)
          },
          scope: this
        },
        '-',
        {
          cls: 'close',
          text: t('Close'),
          handler() {
            if (win.isVisible()) {
              win.restore()
            } else {
              win.show()
            }
            win.close()
          }
        }
      )
    }

    this.cmenu = new Ext.menu.Menu({
      items,
      listeners: {
        beforeshow() {
          const restoreButton = this.cmenu.findButton('restore')
          restoreButton?.setDisabled(win.maximized !== true && win.minimized !== true)

          const minimiseButton = this.cmenu.findButton('minimise')
          minimiseButton?.setDisabled(win.minimized === true)

          const maximiseButton = this.cmenu.findButton('maximise')
          maximiseButton?.setDisabled(win.maximized === true || win.minimized === true)

          const minimiseAllButton = this.cmenu.findButton('minimiseAll')
          minimiseAllButton?.setDisabled(taskbar.allMinimised())
        },
        scope: this
      },
      findButton(cls) {
        return this.items.getRange().find((item) => item.el?.hasClass(cls))
      }
    })
    this.callParent([cfg])
  },

  // special trick to cheat extjs so that it will show up in the menu (see SCL-3467)
  //
  // because we cannot set the xtype directly as that would
  // cause all buttons to be created as a task button
  isXType: function (xtype) {
    return xtype === 'button'
  }
})

module.exports = TaskButton
