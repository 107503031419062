import isDeveloping from 'system/isDeveloping'
import isStaging from 'system/isStaging'

// TODO return azure client ID via API call instead of hardcoding here

function getAzureClientId() {
  if (isDeveloping() || isStaging()) {
    return 'df87fef0-84fd-49f2-9711-c9478b12f1ec'
  }

  return 'b63d81b3-ac07-4c40-a691-96d16a876248'
}

export default getAzureClientId
