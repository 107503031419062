import { createSelector } from '@reduxjs/toolkit'
import { hasModalDialog as hasMailTargetDialog } from 'quarantine/mail_targets/subs'
import { hasModalDialog as hasQuarantineSearchDialog } from 'quarantine/search/subs'
import { hasModalDialog as hasQuarantineSetDialog } from 'quarantine/quarantine_sets/subs'

export const hasModalDialog = createSelector(
  hasMailTargetDialog,
  hasQuarantineSetDialog,
  hasQuarantineSearchDialog,
  (hasMailTargetDialog, hasQuarantineSetDialog, hasQuarantineSearchDialog) => {
    return hasMailTargetDialog || hasQuarantineSetDialog || hasQuarantineSearchDialog
  }
)
