import React, { Component } from 'react'

import {
  authorizeOnce,
  authorizeSession,
  back,
  download,
  enableHighlighting,
  hideSource,
  next,
  prev,
  search,
  select,
  setColumnWidth,
  setDateRange,
  setDateRangeHidden,
  setDateRangeShown,
  setQuery,
  setTimeZoneName,
  toggleAttachmentPanel,
  view,
  viewSource
} from 'continuity/messages/actions'
import AVAILABLE_KEYWORDS from 'continuity/messages/keywords'
import {
  attachmentPanelExpanded,
  authorized,
  columns,
  currentResultNumber,
  downloadable,
  downloadParams,
  endReached,
  firstRowNumber,
  fromDate,
  hasNext,
  hasPrev,
  highlight,
  lastRowNumber,
  loading,
  mail,
  nextParams,
  preview,
  prevParams,
  query,
  resultsQuery,
  rows,
  scrollToIndex,
  searchParameters,
  searchUri,
  selectedCount,
  selectedRows,
  showNoResults,
  showResults,
  source,
  sourceAvailable,
  thruDate,
  timeZoneName,
  total,
  viewing
} from 'continuity/messages/subs'
import Search from 'mail/search/views/Search'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Tab extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired
  }

  handleAttachmentPanelToggle = (expanded) => this.props.toggleAttachmentPanel(expanded)

  handleDateRangeChange = (fromDate, thruDate) =>
    this.props.setDateRange(fromDate.toJSON(), thruDate.toJSON())

  handleDateRangeShow = () => this.props.setDateRangeShown()
  handleDateRangeHide = () => this.props.setDateRangeHidden()

  handleDownload = () => this.props.download(this.props.downloadParams)

  handleHighlightingChange = (enabled) => this.props.enableHighlighting(enabled)

  handleNext = () => this.props.next(this.props.nextParams)

  handlePrev = () => this.props.prev(this.props.prevParams)

  handleQueryChange = (e) => this.props.setQuery(e.target.value)

  handleRowClick = (relativeIndex) =>
    this.props.view(this.props.rows[relativeIndex], relativeIndex)

  handleSearch = () => {
    this.props.search(
      this.props.searchUri,
      this.props.viewing,
      this.props.searchParameters,
      this.props.timeZoneName
    )
  }

  handleSelect = (rows, selected) => this.props.select(rows, selected)

  handleSessionAuthorization = () => this.props.authorizeSession()

  handleSingleMessageAuthorization = () => this.props.authorizeOnce()

  handleSourceHide = () => this.props.hideSource()

  handleTimeZoneNameChange = (timeZoneName) => this.props.setTimeZoneName(timeZoneName)

  handleViewSource = () => this.props.viewSource(this.props.selectedRows)

  render() {
    return (
      <Search
        companyKey={this.props.companyKey}
        attachmentPanelExpanded={this.props.attachmentPanelExpanded}
        authorized={this.props.authorized}
        availableKeywords={AVAILABLE_KEYWORDS}
        columns={this.props.columns}
        currentResultNumber={this.props.currentResultNumber}
        downloadable={this.props.downloadable}
        enableHighlighting={this.props.highlight}
        endReached={this.props.endReached}
        firstRowNumber={this.props.firstRowNumber}
        fromDate={this.props.fromDate}
        hasNext={this.props.hasNext}
        hasPrev={this.props.hasPrev}
        height={this.props.height}
        lastRowNumber={this.props.lastRowNumber}
        loading={this.props.loading}
        mailToView={this.props.mail}
        onAttachmentPanelToggle={this.handleAttachmentPanelToggle}
        onBack={this.props.onBack}
        onDateRangeChange={this.handleDateRangeChange}
        onDateRangeShow={this.handleDateRangeShow}
        onDateRangeHide={this.handleDateRangeHide}
        onDownload={this.handleDownload}
        onHighlightingChange={this.handleHighlightingChange}
        onNext={this.handleNext}
        onPrev={this.handlePrev}
        onQueryChange={this.handleQueryChange}
        onRowClick={this.handleRowClick}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        onSessionAuthorization={this.handleSessionAuthorization}
        onSingleMessageAuthorization={this.handleSingleMessageAuthorization}
        onSourceHide={this.handleSourceHide}
        onTimeZoneNameChange={this.handleTimeZoneNameChange}
        onViewSource={this.handleViewSource}
        preview={this.props.preview}
        query={this.props.query}
        resultsQuery={this.props.resultsQuery}
        rows={this.props.rows}
        scrollToIndex={this.props.scrollToIndex}
        selectedCount={this.props.selectedCount}
        setColumnWidth={this.props.setColumnWidth}
        showNoResults={this.props.showNoResults}
        showResults={this.props.showResults}
        source={this.props.source}
        sourceAvailable={this.props.sourceAvailable}
        thruDate={this.props.thruDate}
        timeZoneName={this.props.timeZoneName}
        total={this.props.total}
        viewing={this.props.viewing}
        width={this.props.width}
      />
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  attachmentPanelExpanded: attachmentPanelExpanded(db, companyKey),
  authorized: authorized(db, companyKey),
  columns: columns(db, companyKey),
  currentResultNumber: currentResultNumber(db, companyKey),
  downloadable: downloadable(db, companyKey),
  downloadParams: downloadParams(db, companyKey),
  endReached: endReached(db, companyKey),
  firstRowNumber: firstRowNumber(db, companyKey),
  fromDate: fromDate(db, companyKey),
  hasNext: hasNext(db, companyKey),
  hasPrev: hasPrev(db, companyKey),
  highlight: highlight(db, companyKey),
  lastRowNumber: lastRowNumber(db, companyKey),
  loading: loading(db, companyKey),
  mail: mail(db, companyKey),
  nextParams: nextParams(db, companyKey),
  prevParams: prevParams(db, companyKey),
  preview: preview(db, companyKey),
  query: query(db, companyKey),
  resultsQuery: resultsQuery(db, companyKey),
  rows: rows(db, companyKey),
  scrollToIndex: scrollToIndex(db, companyKey),
  searchParameters: searchParameters(db, companyKey),
  searchUri: searchUri(db, companyKey),
  selectedCount: selectedCount(db, companyKey),
  selectedRows: selectedRows(db, companyKey),
  showNoResults: showNoResults(db, companyKey),
  showResults: showResults(db, companyKey),
  source: source(db, companyKey),
  sourceAvailable: sourceAvailable(db, companyKey),
  thruDate: thruDate(db, companyKey),
  timeZoneName: timeZoneName(db, companyKey),
  total: total(db, companyKey),
  viewing: viewing(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  authorizeOnce: () => dispatch(authorizeOnce(companyKey)),
  authorizeSession: () => dispatch(authorizeSession(companyKey)),
  onBack: () => dispatch(back(companyKey)),
  download: (downloadParams) => dispatch(download(downloadParams)),
  enableHighlighting: (enabled) => dispatch(enableHighlighting(companyKey, enabled)),
  hideSource: () => dispatch(hideSource(companyKey)),
  next: (params) => dispatch(next(companyKey, params)),
  prev: (params) => dispatch(prev(companyKey, params)),
  search: (searchUri, viewing, parameters, timeZoneName) =>
    dispatch(search(companyKey, searchUri, viewing, parameters, timeZoneName)),
  select: (rows, selected) => dispatch(select(companyKey, rows, selected)),
  setColumnWidth: (width, columnKey) =>
    dispatch(setColumnWidth(companyKey, columnKey, width)),
  setDateRange: (fromDate, thruDate) =>
    dispatch(setDateRange(companyKey, fromDate, thruDate)),
  setDateRangeShown: () => dispatch(setDateRangeShown(companyKey)),
  setDateRangeHidden: () => dispatch(setDateRangeHidden(companyKey)),
  setQuery: (query) => dispatch(setQuery(companyKey, query)),
  setTimeZoneName: (timeZoneName) => dispatch(setTimeZoneName(companyKey, timeZoneName)),
  toggleAttachmentPanel: (expanded) =>
    dispatch(toggleAttachmentPanel(companyKey, expanded)),
  view: (mail, relativeIndex) => dispatch(view(companyKey, mail, relativeIndex)),
  viewSource: (rows) => dispatch(viewSource(companyKey, rows))
})

export default connect(mapDbToProps, mapActionsToProps)(Tab)
