import { Component } from 'react'
import { isSafari } from 'system/browser'

// this copycats some of the code from extjs window related to modals
// for adding an app-wide mask instead of using the one
// react bootstrap comes with because it has issues with edge
export default class Mask extends Component {
  componentDidMount() {
    // very similar to ext-js' mask() method
    document.body.classList.add('x-body-masked')

    // inspired by https://docs.sencha.com/extjs/3.4.0/source/Window.html#Ext-Window-cfg-modal
    this.mask = document.createElement('div')
    this.mask.setAttribute('class', 'ext-el-mask')

    // don't do the following when on safari because of a Safari bug, see
    // src/styles/includes/components/Modal.scss
    if (!isSafari) {
      // this to avoid doubling up grays from extjs and react bootstrap modal
      this.mask.setAttribute('style', 'background-color:transparent')
    }

    document.body.appendChild(this.mask)
  }

  componentWillUnmount() {
    // very similar to ext-js' unmask() method
    document.body.classList.remove('x-body-masked')

    document.body.removeChild(this.mask)
    this.mask = null
  }

  render = () => this.props.children
}
