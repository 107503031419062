import Reorderable from 'admin/component/grid/Reorderable'
import RowEditor from 'admin/component/grid/RowEditor'
import RowSelectionModel from 'admin/component/grid/RowSelectionModel'
import Ext from 'ext'
import logger from 'system/logger'
import t from 'translate'

const escape = require('html-escaper').escape

const ItemGridPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,

  addButton: null,
  deleteButton: null,

  constructor(cfg = {}) {
    if (cfg.columns == null) {
      cfg.columns = []
    }

    this.encodeColumns(cfg.columns)

    const plugins = []

    if (cfg.module.canSave()) {
      this.editor = new RowEditor({
        store: cfg.store
      })

      plugins.push(this.editor)
    }

    if (cfg.module.canRemove() && cfg.reorderable !== false) {
      plugins.push(new Reorderable())
    }

    const sm = new RowSelectionModel({
      listeners: {
        beforerowselect(model, rowIndex, keep, record) {
          if (this.vetoRowSelect(record)) {
            return false
          } else if (this.editor?.rendered && this.editor.isVisible()) {
            return !this.editor?.isDirty()
          }
          return true
        },
        scope: this,
        rowselect: this.onRowSelect
      },
      singleSelect: true
    })

    cfg = Ext.applyIf(cfg, {
      autoExpandColumn: 'name',
      autoScroll: true,
      cm: new Ext.grid.ColumnModel(cfg.columns),
      enableColumnHide: false,
      enableColumnMove: false,
      loadMask: true,
      plugins,
      sm,
      tbar: this.getToolbarItems(cfg),
      view: cfg.view
    })

    ItemGridPanel.superclass.constructor.call(this, cfg)

    // Record being edited may be new.
    if (this.editor) {
      this.editor.on(
        'afteredit',
        function () {
          Ext.MessageBox.wait(t('Saving...'))

          this.editor.record.save(null, {
            error(record, response, options, err) {
              if (record.isNew()) {
                record.destroy()
              }
              logger.error(err)
            },
            complete() {
              Ext.MessageBox.hide()
            }
          })
        },
        this
      )

      this.editor.on('canceledit', () => {
        if (this.editor.record.isNew()) {
          this.editor.record.destroy()
        }
      })
    }

    this.on('contextmenu', () => false)
    this.on('beforeclick', (node, event) => !event.hasModifier())
    this.store.on('remove', this.selectFirst, this)
    this.store.on('load', this.afterLoad, this)
    this.store.on('update', this.onUpdate, this)
  },

  onRowSelect(model) {
    const removable = this.module.canRemove() && model.hasSelection()
    this.deleteButton.setDisabled(!removable)
    this.fireEvent('itemselected', { removable })
  },

  getToolbarItems(cfg) {
    this.addButton = cfg.addButton

    if (!this.addButton) {
      this.addButton = new Ext.Button({
        disabled: !cfg.module.canCreate(),
        handler: () => this.addItem(),
        iconCls: cfg.addIconCls,
        scope: this,
        text: cfg.addLabel,
        tooltip: {
          width: 'auto',
          text: cfg.addTooltip
        }
      })
    }

    this.deleteButton = cfg.deleteButton

    if (!this.deleteButton) {
      this.deleteButton = new Ext.Button({
        disabled: true,
        handler: this.deleteItem.bind(this),
        iconCls: cfg.deleteIconCls,
        scope: this,
        text: cfg.deleteLabel,
        tooltip: {
          width: 'auto',
          text: cfg.deleteTooltip
        }
      })
    }

    return [this.addButton, this.deleteButton]
  },

  encodeColumns(columns) {
    return columns.forEach(function (column) {
      const { renderer } = column

      if (!column.noHtmlEncode) {
        if (renderer) {
          column.renderer = function (
            value,
            metadata,
            record,
            rowIndex,
            colIndex,
            store
          ) {
            const v = renderer(value, metadata, record, rowIndex, colIndex, store)
            if (!v) return
            return escape(v)
          }
        }

        column.renderer = (v) => v && escape(v)
      }
    })
  },

  select: async function (model, index = 0) {
    if (this.isDestroyed) return
    if (!model.grid) return
    if (model.grid.isDestroyed) return

    const count = this.getStore().getCount()
    if (index >= 0 && index < count) {
      await this.fireEvent('itemselected', { removable: true })
      model.selectRow(index)
    } else {
      this.deleteButton.setDisabled(true)
      this.fireEvent('itemselected', { removable: false })
    }
  },

  onUpdate(store, record) {
    this.selectValue = record.get(this.autoExpandColumn)
  },

  selectFirst() {
    this.select(this.getSelectionModel(), 0)
  },

  afterLoad() {
    if (this.rendered) {
      const store = this.getStore()
      const selModel = this.getSelectionModel()

      if (Ext.isFunction(this.filter)) {
        store.filterBy(this.filter)
      }

      if (!selModel.hasSelection()) {
        if (this.selectValue) {
          const index = store.find(this.autoExpandColumn, this.selectValue)

          if (index >= 0) {
            this.select(selModel, index)
          }

          delete this.selectValue
        }
        this.selectFirst()
      }
    } else {
      this.on('render', this.afterLoad, this)
    }
  },

  afterRender() {
    ItemGridPanel.superclass.afterRender.call(this)
    this.store.load()
  },

  onDestroy() {
    this.store.un('remove', this.selectFirst, this)
    this.store.un('load', this.afterLoad, this)
    this.store.un('update', this.onUpdate, this)

    ItemGridPanel.superclass.onDestroy.call(this)
  },

  // Default function to allow for vetoing of changing selection.
  vetoRowSelect() {
    return false
  }
})

export default ItemGridPanel
