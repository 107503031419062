const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const RulePanel = require('smartrules/RulePanel')

const NewRule = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'rule-new-panel',

  constructor(cfg = {}) {
    this.module = require('smartrules/Rule')
    this.party = cfg.record
    this.store = cfg.store
    this.templateId = cfg.templateId
    this.templateName = cfg.templateName
    this.templateDescription = cfg.templateDescription
    this.templateDefinition = cfg.templateDefinition
    this.callParent(arguments)
  },

  getFormPanel() {
    const listManagementStore = this.party.getListManagementStore()
    const systemListManagementStore = this.party.getSystemListManagementStore()

    const listStores = [
      systemListManagementStore,
      this.party.get('type') !== 'VENDOR' && listManagementStore
    ].filter(Boolean)

    const items = [
      {
        fieldLabel: t('Rule Name'),
        helpText: t(
          'Descriptive but broad names are the most helpful here: e.g. "Filter Large Messages" vs "Filter Messages Over 10MB"'
        ),
        name: 'name',
        minLength: 2,
        maxLength: 255,
        allowBlank: false,
        value: this.templateId === 0 ? '' : this.templateName,
        anchor: '50%',
        xtype: 'textfield',

        validator(v) {
          if (v.match(/^\s/)) {
            return t('Names cannot start with a space')
          }
          return true
        }
      },

      {
        fieldLabel: t('Description'),
        name: 'description',
        value: this.templateId === 0 ? '' : this.templateDescription,
        anchor: '100%',
        xtype: 'textarea'
      },

      this.party.get('type') !== 'VENDOR' &&
        new ComboBox({
          fieldLabel: t('Enabled'),
          hiddenName: 'enabled',
          value: 'false',
          editable: false,
          allowBlank: false,
          triggerAction: 'all',
          store: [
            ['false', t('No')],
            ['true', t('Yes')]
          ]
        }),

      (this.designer = new RulePanel({
        definition: this.templateDefinition,
        party: this.party,
        listStores
      })),
      {
        name: 'sequenceNumber',
        value: this.getSequenceNumber(),
        xtype: 'hidden'
      }
    ]

    return new RecordFormPanel({
      module: this.module,
      operation: 'create',
      items,
      isValid: () => this.designer.isValid(),
      validateForm(form, valid) {
        valid = valid && form.isValid()
        return RecordFormPanel.prototype.validateForm.call(this, form, valid)
      }
    })
  },

  getSequenceNumber() {
    let sequenceNumber = 1
    this.store.each(function (record) {
      if (record.get('sequenceNumber') > sequenceNumber) {
        return (sequenceNumber = record.get('sequenceNumber'))
      }
    })
    return sequenceNumber++
  },

  resetDirtyFlags() {
    const items = this.formPanel.findByType(Ext.form.Field)
    delete this.formPanel.dirty
    delete this.formPanel.getForm().dirty
    return items.forEach(function (item) {
      if (item.isFormField) {
        return (item.originalValue = item.getValue())
      }
    })
  },

  getFormValues() {
    const form = this.formPanel.getForm().getFieldValues()
    form.definition = this.designer.getValue()
    return form
  },

  handleCancel() {
    return this.close()
  },

  handleSave(button, event) {
    if (!this.testing) {
      this.testing = true

      button.setDisabled(true)
      const definition = this.designer.getValue()

      return this.designer.evaluate(definition, {
        success: (compilerResult) => {
          if (compilerResult.parserErrors.length === 0) {
            return NewRule.superclass.handleSave.call(this, button, event)
          }
        },
        process: () => {
          this.testing = false
        }
      })
    }
  }
})

module.exports = NewRule
