const Ext = require('ext')
const ActionClause = require('smartrules/designer/ActionClause')
const MatcherCombo = require('smartrules/designer/MatcherCombo')

const AddressableActionClause = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    let matcher

    const value = cfg.value || ''
    cfg = Ext.applyIf(cfg, {
      label: cfg.label,
      noSpacer: true,
      type: cfg.type
    })

    if (cfg.system === true) {
      matcher = 'is-in-system'
    } else if (cfg.system === false) {
      matcher = 'is-in'
    } else {
      matcher = 'is'
    }

    this.combo = cfg.combo = new MatcherCombo({
      noModifier: true,
      listStores: cfg.listStores,
      matcher,
      matchers: cfg.matchers,
      matcherValue: value,
      scope: this
    })
    this.callParent([cfg])
  },

  toDsl() {
    return this.combo.toDsl()
  }
})

module.exports = AddressableActionClause
