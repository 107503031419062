const Ext = require('ext')
const ClauseTreeMember = require('smartrules/designer/ClauseTreeMember')

const PanelButton = Ext.define(null, {
  extend: Ext.Button,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      border: false,
      cls: String.format(
        'smartrules-panel-button smartrules-{0}-button',
        cfg.buttonCls || ''
      ),
      iconCls: String.format('smartrules-{0}-button-icon', cfg.buttonCls),
      overCls: 'hover',
      height: 24,
      width: 24
    })
    this.callParent([cfg])
  },

  getHost() {
    return this.findParentBy((n) => n instanceof ClauseTreeMember)
  }
})

module.exports = PanelButton
