import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Button, Col, FormGroup, Row } from 'react-bootstrap'

import t from 'translate'
import Mask from 'components/Mask'

import Grid from 'components/Grid'

class Source extends Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired
  }

  render() {
    return (
      <Mask>
        <Modal.Dialog bsSize='large'>
          <Modal.Header>
            <Modal.Title>{t('View Source')}</Modal.Title>
          </Modal.Header>

          <div id='mail-source' className='inner-content'>
            <Modal.Body>{this.props.source}</Modal.Body>
          </div>

          <Modal.Footer>
            <Grid>
              <Row>
                <FormGroup>
                  <Col style={{ textAlign: 'center' }}>
                    <Button type='button' onClick={this.props.onHide}>
                      {t('Close')}
                    </Button>
                  </Col>
                </FormGroup>
              </Row>
            </Grid>
          </Modal.Footer>
        </Modal.Dialog>
      </Mask>
    )
  }
}

export default Source
