const t = require('translate')
const Record = require('admin/core/Record')
const Time = require('admin/time/Time')

const SubscriptionRecord = Record.define(null, {
  module() {
    return require('reporting/Subscription')
  },

  fields: [
    {
      name: 'id',
      type: 'int',
      mapping: 'id'
    },
    {
      name: 'reportId',
      type: 'string'
    },
    {
      name: 'description',
      type: 'string'
    },
    {
      name: 'lastSentDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'nextSendDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'format',
      type: 'string',
      mapping: 'format'
    },
    {
      name: 'formatName',
      type: 'string',
      mapping: 'format',
      convert(value) {
        switch (value) {
          case 'PDF':
            return t('PDF')
          case 'HTML':
            return t('HTML')
          case 'CSV':
            return t('CSV')
          default:
            return value
        }
      }
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'period',
      type: 'string'
    },
    {
      name: 'delivery',
      type: 'string',
      mapping: 'period',
      convert(value) {
        switch (value) {
          case 'P1D':
            return 'DAILY'
          case 'P1W':
            return 'WEEKLY'
          default:
            return 'MONTHLY'
        }
      }
    },
    {
      name: 'deliveryName',
      type: 'string',
      mapping: 'period',
      convert(value) {
        switch (value) {
          case 'P1D':
            return t('Daily')
          case 'P1W':
            return t('Weekly')
          default:
            return t('Monthly')
        }
      }
    },
    {
      name: 'subscriber',
      type: 'int'
    },
    {
      name: 'destination',
      type: 'string'
    },
    {
      name: 'options',
      type: 'string'
    },
    {
      name: 'report'
    },
    {
      name: 'url'
    },
    {
      name: 'subscriptionType'
    }
  ],

  canOpen() {
    return this.get('subscriptionType') !== 'WATCH'
  },

  getName() {
    return this.get('description')
  },

  getParams() {
    let acceptableKeys
    const fromDate = new Date()
    const thruDate = null
    const report = this.get('report')
    const reportOptions = report != null ? report.options : undefined
    if (reportOptions) {
      acceptableKeys = reportOptions.map((option) => `reportingoptions.${option.name}`)
    } else {
      acceptableKeys = []
    }

    const options = Object.keys(this.data).reduce((result, key) => {
      if (acceptableKeys.indexOf(key) > -1) {
        result.push(
          [key.substring('reportingoptions'.length + 1), this.get(key)].join('=')
        )
      }
      return result
    }, [])
    this.set('options', options.join('&'))

    const params = {
      id: this.get('id'),
      reportId: this.get('reportId'),
      description: this.get('description'),
      format: this.get('format'),
      fromDate: fromDate ? fromDate.format('Y/m/d') : null,
      thruDate: thruDate ? thruDate.format('Y/m/d') : null, // Putting 'null' means set thruDate to null
      delivery: this.get('delivery'),
      subscriber: this.get('subscriber'),
      options: this.get('options')
    }

    // only include destination when not adding a new subscription, see
    // http://youtrack.smxemail.com/issue/SCL-954
    if (!this.isNew()) {
      params.destination = this.get('destination')
    }

    return params
  }
})

module.exports = SubscriptionRecord
