const _s = require('underscore.string')

module.exports = function (str, options = {}) {
  if (!str || str.length < 1 || !str.toLowerCase) {
    return str
  }

  const { excludeWord } = options

  let expression = '\\b(?:'

  if (excludeWord) {
    expression += `(?!${excludeWord})`
  }

  expression += '\\w)+\\b'

  const regex = new RegExp(expression, 'gi')

  return str.replace(regex, (word) => _s.capitalize(word))
}
