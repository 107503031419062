const Ext = require('ext')
const t = require('translate')
const ProvisionStatusView = require('admin/view/ProvisionStatusView')
const logger = require('system/logger').default

module.exports = Ext.define(null, {
  extend: Ext.Panel,

  title: t('Service Status'),
  autoScroll: true,
  cls: 'customer-status-panel',
  tabCls: 'customer-status-tab',

  initComponent() {
    this.items = [(this.status = new ProvisionStatusView())]
    this.tbar = [
      '->',
      new Ext.Button({
        text: t('Refresh'),
        handler: () => this.refresh()
      })
    ]
    this.callParent()
  },

  afterRender() {
    this.callParent()
    this.refresh()
  },

  refresh() {
    this.mask()
    return this.record
      .getAgreementStore()
      .getCancelSummary([], Date.now())
      .then((summary) => {
        if (summary.length === 0) {
          summary = t('Nothing provisioned.')
        }
        this.status.update(summary)
      })
      .catch((error) => logger.error(error))
      .finally(() => this.unmask())
      .done()
  }
})
