const Ext = require('ext')
const t = require('translate')
const CompanyResource = require('admin/business/CompanyResource')
const CustomerDetailPanel = require('admin/view/CustomerDetailPanel')
const CustomerNewPanel = require('admin/view/CustomerNewPanel')
const CustomerListPanel = require('admin/view/CustomerListPanel')
const CustomerRecord = require('admin/data/company/CustomerRecord')
const VendorResource = require('admin/business/VendorResource')
const DistributorResource = require('admin/business/DistributorResource')
const ResellerResource = require('admin/business/ResellerResource')
const User = require('admin/core/User')

const CustomerResource = Ext.define(null, {
  extend: CompanyResource,
  singleton: true,

  id: 'SMX.Customer',
  translations: {
    title: t('Customer'),
    plural: t('Customers'),
    new: t('New Customer'),
    open: t('Open Customer'),
    list: t('List Customers'),
    remove: t('Remove Customer')
  },
  name: t('Customer'),
  code: 'CUSTOMER',
  resourceName: '/customer',

  layout: {
    newResource: {
      width: 400,
      height: 'auto'
    },
    openResource: {
      width: 725,
      height: 570,
      minHeight: 570
    }
  },

  operations: {
    create: true,
    open: true,
    remove: true,
    list: true,
    save: true
  },

  detailPanel: CustomerDetailPanel,
  newPanel: CustomerNewPanel,
  listPanel: CustomerListPanel,

  record: CustomerRecord,

  getParentResources() {
    return [VendorResource, DistributorResource, ResellerResource]
  },

  getDirections(products) {
    if (!products) return
    const hosting = Object.keys(products).find((name) => /^MAIL_HOST/.test(name))

    return {
      inbound: hosting || products.INBOUND_SCRUB,
      outbound: hosting || products.OUTBOUND_SCRUB
    }
  },

  canRemove(opts = {}) {
    if (opts?.context === 'explorer') {
      // since SCL-1339 always suppress for explorer right context menu
      return false
    }
    return this.hasPermission('DELETE') && User.hasRole('PROVISION_CUSTOMER')
  }
})

module.exports = CustomerResource
