import React, { Component } from 'react'
import { doubleClickQuarantineSet, refresh, select } from '../actions'
import { list, loading, uri } from '../subs'

import PropTypes from 'prop-types'
import SelectableTable from 'components/table/SelectableTable'
import _s from 'underscore.string'
import { connect } from 'react-redux'
import t from 'translate'

const BOOTSTRAP_PADDING = 5
const TOOLBAR_MIN_HEIGHT = 65 + 2 * BOOTSTRAP_PADDING

const renderTriggers = (triggers) => {
  return (
    triggers &&
    triggers
      .map((trigger) => _s.capitalize(trigger, true))
      .sort()
      .join(', ')
  )
}

const renderTargets = (targets) => {
  return targets?.slice().sort().join(', ')
}

class QuarantineSetTable extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired
  }

  componentDidMount() {
    this.props.refresh(this.props.uri)
  }

  get width() {
    if (typeof this.props.width === 'number') {
      return this.props.width - BOOTSTRAP_PADDING * 3
    }
    return this.props.width
  }

  get maxHeight() {
    if (typeof this.props.height === 'number') {
      return this.props.height - TOOLBAR_MIN_HEIGHT - BOOTSTRAP_PADDING * 4
    }
    return this.props.height
  }

  handleRowDoubleClick = (index, quarantineSet) => {
    this.props.doubleClickQuarantineSet(quarantineSet)
  }

  render() {
    const columns = [
      {
        name: 'name',
        title: t('Quarantine Policy'),
        width: 160,
        flexGrow: 3,
        isResizable: true,
        cellClassName: 'truncate-cell',
        rowCellClassName: 'quarantine-set-name'
      },
      {
        name: 'triggers',
        title: t('Type'),
        width: 140,
        flexGrow: 3,
        isResizable: true,
        cellClassName: 'truncate-cell',
        renderCell: renderTriggers
      },
      {
        name: 'targets',
        title: t('Mail Targets'),
        width: 200,
        flexGrow: 5,
        isResizable: true,
        cellClassName: 'truncate-cell',
        renderCell: renderTargets
      }
    ]

    return (
      <SelectableTable
        disabled={this.props.loading}
        width={this.width}
        maxHeight={this.maxHeight}
        results={this.props.list && this.props.list}
        onRowDoubleClick={this.handleRowDoubleClick}
        onSelect={this.props.onSelect}
        rowClassName='quarantine-set-row'
        columns={columns}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  list: list(db, props.companyKey),
  loading: loading(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  refresh: (uri) => dispatch(refresh(companyKey, uri)),
  onSelect: (quarantineSets, selected) =>
    dispatch(select(companyKey, quarantineSets, selected)),
  doubleClickQuarantineSet: (quarantineSet) =>
    dispatch(doubleClickQuarantineSet(companyKey, quarantineSet))
})

export default connect(mapDbToProps, mapActionsToProps)(QuarantineSetTable)
