import React from 'react'

import { Radio } from 'react-bootstrap'

export default function RadioGroup(props) {
  const { disabled, input, items, checked } = props
  const { onChange, onBlur, onFocus } = input

  const radioButtons = Object.keys(items).reduce((data, value) => {
    const label = items[value]

    // special treatment to reformat value before passing it further
    // onto app db (redux)
    const handleChange = (event) => {
      onBlur(event.target.value)
      onChange(event.target.value)
    }

    data.push(
      <Radio
        key={label}
        name={input.name}
        disabled={disabled}
        onChange={handleChange}
        onFocus={onFocus}
        value={value}
        checked={value === checked}
      >
        {label}
      </Radio>
    )

    return data
  }, [])

  return <div>{radioButtons}</div>
}
