import React, { Component } from 'react'

import Grid from 'components/Grid'
import getAzureClientId from 'integration/getAzureClientId'
import getSmx365ApplicationClientId from 'integration/getSmx365ApplicationClientId'
import { refresh } from 'integration/tenancy/actions'
import {
  confirmingDeletion,
  creating,
  renaming,
  showNoResults,
  showResults,
  uri
} from 'integration/tenancy/subs'
import ConfirmDelete from 'integration/tenancy/views/confirm/Delete'
import CreateForm from 'integration/tenancy/views/form/Create'
import RenameForm from 'integration/tenancy/views/form/Rename'
import Table from 'integration/tenancy/views/Table'
import Toolbar from 'integration/tenancy/views/toolbar/Toolbar'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'
import encodeForm from 'url/encodeForm'

function getSmxIdentityGatewayUrl() {
  const params = encodeForm({
    // eslint-disable-next-line camelcase
    client_id: getAzureClientId(),
    // It must exactly match one of the redirect URIs that you registered
    // in the app registration portal. So it can't be dynamic.
    // see https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-admin-consent
    // eslint-disable-next-line camelcase
    redirect_uri: window.location.origin.toString(),
    state: window.location.hash.toString(),
    scope: 'https://graph.microsoft.com/.default'
  })

  return 'https://login.microsoftonline.com/organizations/v2.0/adminconsent?' + params
}

function getSmx365ApplicationUrl() {
  const params = encodeForm({
    // eslint-disable-next-line camelcase
    client_id: getSmx365ApplicationClientId(),
    // It must exactly match one of the redirect URIs that you registered
    // in the app registration portal. So it can't be dynamic.
    // see https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-admin-consent
    // eslint-disable-next-line camelcase
    redirect_uri: window.location.origin.toString(),
    state: window.location.hash.toString(),
    scope: 'https://graph.microsoft.com/.default'
  })

  return 'https://login.microsoftonline.com/organizations/v2.0/adminconsent?' + params
}

class TenancyTab extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    hasProductSmx365: PropTypes.bool
  }

  componentDidMount() {
    this.props.refresh(this.props.uri)
  }

  // Don't forget admin/view/CompanyGroupPanel showNotificationTab if changing
  showSmx365Application() {
    return this.props.hasProductSmx365
  }

  renderNoResults() {
    return <div className='no-results'>{t('No results')}</div>
  }

  renderTable() {
    return (
      <Table
        width={this.props.width}
        height={this.props.height}
        companyKey={this.props.companyKey}
      />
    )
  }

  renderForm() {
    if (this.props.creating) {
      return <CreateForm companyKey={this.props.companyKey} />
    } else if (this.props.renaming) {
      return <RenameForm companyKey={this.props.companyKey} />
    } else if (this.props.confirmingDeletion) {
      return <ConfirmDelete companyKey={this.props.companyKey} />
    }
  }

  render() {
    return (
      <div className='tab-content'>
        <Grid>
          <h4>Microsoft 365</h4>
          <fieldset className='applications'>
            <legend>Applications</legend>
            <dl className='dl-horizontal'>
              <dt>
                <a href={getSmxIdentityGatewayUrl()} className='btn btn-default'>
                  Register SMX Identity Gateway
                </a>
              </dt>
              <dd>
                You must register and configure the SMX Identity Gateway application in
                your Microsoft Tenant before adding your Microsoft Tenant ID below. Click{' '}
                <a
                  href='https://docs.smxemail.com/setup/identity_integrations.html#smx-identity-gateway-guide-for-microsoft-365'
                  rel='noreferrer'
                  target='_blank'
                >
                  HERE
                </a>{' '}
                for help.
              </dd>
              {this.showSmx365Application() && (
                <>
                  <dt>
                    <a href={getSmx365ApplicationUrl()} className='btn btn-default'>
                      Register SMX 365 Application
                    </a>
                  </dt>
                  <dd>
                    <p>
                      Register and approve the SMX 365 Application as an administrator in
                      your Microsoft Tenant. This allows your users to access SMX services
                      such as Quarantine Self Release. Click{' '}
                      <a
                        href='https://docs.smxemail.com/setup/QuarantineSelf-Release_admin.html'
                        rel='noreferrer'
                        target='_blank'
                      >
                        HERE
                      </a>{' '}
                      for help.
                    </p>
                    <p>
                      If you have multiple Microsoft Tenants for the email domains managed
                      by SMX, register the SMX 365 Application in each of those tenants.
                    </p>
                  </dd>
                </>
              )}
            </dl>
          </fieldset>
          <fieldset className='tenancies'>
            <legend>Tenants</legend>
            <Toolbar companyKey={this.props.companyKey} />
            {this.props.showResults && this.renderTable()}
            {this.props.showNoResults && this.renderNoResults()}
            {this.renderForm()}
          </fieldset>
        </Grid>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  showResults: showResults(db, companyKey),
  showNoResults: showNoResults(db, companyKey),
  uri: uri(db, companyKey),
  creating: creating(db, companyKey),
  confirmingDeletion: confirmingDeletion(db, companyKey),
  renaming: renaming(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  refresh: (uri) => dispatch(refresh(companyKey, uri))
})

export default connect(mapDbToProps, mapActionsToProps)(TenancyTab)
