// those fields are common fields used in all our party group types:
// customer, distributor, reseller and vendor
export default [
  {
    name: 'id',
    type: 'int'
  },
  {
    // needed for all subclasses. be careful that a distributor can
    // come with different types, i.E. a distributor can be also a customer.
    name: 'type'
  },
  {
    name: 'externalId',
    type: 'string'
  },
  {
    name: 'expired',
    type: 'boolean'
  },
  {
    name: 'primaryPostalAddress.attnName',
    type: 'string'
  },
  {
    name: 'primaryPostalAddress.address1',
    type: 'string'
  },
  {
    name: 'primaryPostalAddress.address2',
    type: 'string'
  },
  {
    name: 'primaryPostalAddress.city',
    type: 'string'
  },
  {
    name: 'primaryPostalAddress.postalCode',
    type: 'string'
  },
  {
    name: 'primaryPostalAddress.countryGeo',
    type: 'string'
  },
  {
    name: 'displayName',
    type: 'string'
  }
]
