import React, { Component } from 'react'
import { formDisabled, names } from 'quarantine/mail_targets/subs'

import Dialog from 'quarantine/mail_targets/views/form/Dialog'
import Field from 'components/form/Field'
import Help from 'components/Help'
import PropTypes from 'prop-types'
import _s from 'underscore.string'
import { connect } from 'react-redux'
import t from 'translate'

class NameDialog extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    mailTargetName: PropTypes.string,
    names: PropTypes.array
  }

  validateName = (value) => {
    const trimmedValue = value.trim()

    if (_s.isBlank(trimmedValue)) {
      return t('White spaces are not allowed')
    } else if (trimmedValue.length > 100) {
      return t('Max length is 100 characters')
    } else if (this.props.names?.includes(trimmedValue)) {
      return t('This name already exists.')
    }
  }

  render() {
    return (
      <Dialog
        title={this.props.title}
        store={this.props.store}
        onSubmit={this.props.onSubmit}
        onCancel={this.props.onCancel}
        submitButtonText={this.props.submitButtonText}
        companyKey={this.props.companyKey}
        mailTarget={{ name: this.props.mailTargetName }}
      >
        <Field
          required
          autoFocus
          name='name'
          autoComplete='off'
          // so that it can be tested with a custom store
          store={this.props.store}
          disabled={this.props.disabled}
          validate={this.validateName}
          label={t('Name')}
          type='text'
          tabIndex={0}
        />
        <Help>{t('Enter a unique name for this mail target')}</Help>
      </Dialog>
    )
  }
}

const mapDbToProps = (db, props) => ({
  disabled: formDisabled(db, props.companyKey),
  names: names(db, props.companyKey)
})

export default connect(mapDbToProps)(NameDialog)
