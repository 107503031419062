const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ScrubbingDomainDetailPanel = require('admin/view/ScrubbingDomainDetailPanel')
const ScrubbingDomainNewPanel = require('admin/view/ScrubbingDomainNewPanel')
const ScrubbingDomainListPanel = require('admin/view/ScrubbingDomainListPanel')
const ScrubbingDomainRecord = require('admin/data/ScrubbingDomainRecord')

const ScrubbingDomainResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.ScrubbingDomain',
  translations: {
    title: t('Scrubbing Domain'),
    plural: t('Scrubbing Domains'),
    new: t('New Scrubbing Domain'),
    open: t('Open Scrubbing Domain'),
    list: t('List Scrubbing Domains'),
    remove: t('Remove Scrubbing Domain')
  },
  name: t('Scrubbing Domains'),
  code: 'SCRUBBING_DOMAIN',
  resourceId: 'SCRUBBING_DOMAINS',
  resourceName: 'scrubbingdomains',

  searchable: true,

  layout: {
    newResource: {
      width: 400,
      height: 'auto'
    },
    openResource: {
      width: 400,
      height: 'auto'
    }
  },

  detailPanel: ScrubbingDomainDetailPanel,
  newPanel: ScrubbingDomainNewPanel,
  listPanel: ScrubbingDomainListPanel,

  record: ScrubbingDomainRecord,

  validateScrubbingDomainForm(form, valid) {
    let matched
    if (valid) {
      const nameField = form.find('name', 'name')[0]
      const relayToField = form.find('hiddenName', 'relayTo')[0]
      matched = nameField.getValue() === relayToField.getValue()
      form.saveButton.setDisabled(matched)
    }
    if (matched) {
      return form.saveButton.setTooltip({
        cls: 'error',
        text: t('Domain name and mail relay host cannot be the same.')
      })
    }
    return form.saveButton.setTooltip('')
  }
})

module.exports = ScrubbingDomainResource
