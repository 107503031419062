import getBrand from 'system/getBrand'
import getEnv from 'system/getEnv'
import getVersion from 'system/getVersion'

import * as Sentry from '@sentry/browser'

export const init = () => {
  Sentry.init({
    dsn: 'https://8f3c5abab0e24e5f996aebee03e1db67@sentry.io/1810528',
    release: getVersion(),
    environment: getEnv()
  })

  Sentry.configureScope((scope) => {
    // see https://docs.sentry.io/platforms/javascript/config/basics/
    scope.setTag('client', getVersion()) // TODO duplicate but needed. sort out with backend later.
    scope.setTag('brand', getBrand())
  })
}

export const setUser = (username) => {
  // abort when Sentry isn't loaded at all
  if (!Sentry.getCurrentHub().getClient()) return

  Sentry.configureScope(function (scope) {
    scope.setUser({ email: username })
  })
}

export const captureException = (err) => {
  Sentry.captureException(err)
}

export const addBreadcrumb = (context) => {
  // allowed log levels are:
  // "fatal", "critical", "error", "warning", "log", "info", and "debug".
  if (context.category === 'warning') {
    context.level = 'warning'
  } else {
    context.level = context.level || 'info'
  }

  Sentry.addBreadcrumb(context)
}

export default { init, setUser, captureException, addBreadcrumb }
