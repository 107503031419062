import Ext from 'ext/ext-base'

const DOMPurify = require('dompurify')
const makeExtAsync = require('ext/makeExtAsync').default
const isTesting = require('system/isTesting').default
const isObject = require('is-plain-obj')
const escape = require('html-escaper').escape

// making ext js events async breaks karma tests because of its
// jasmine-ajax stubbing we cannot override further
if (!isTesting()) {
  makeExtAsync()
}

// eslint-disable-next-line no-new-func
const NONSENSE_WITH_FN_STR = new Function(
  'values',
  'parent',
  'with(values){ return values; }'
).toString()

// Override clearData impl to avoid disconnected records on store refresh
Ext.override(Ext.data.Store, {
  clearData() {
    this.data.clear()
  }
})

/*
Override createAccessor impl
This handles having nested, "dot" separated field names,
unlike the default Ext reader
@param cfg
*/
Ext.override(Ext.data.JsonReader, {
  createAccessor: (() =>
    function (expr) {
      if (Ext.isEmpty(expr)) {
        return Ext.emptyFn
      } else if (Ext.isFunction(expr)) {
        return expr
      }

      return function (obj) {
        const parts = (expr || '').split('.')
        let result = obj

        while (parts.length > 0 && result) {
          const part = parts.shift()
          const match = part.match(/^(.+?)(\[(\d+)\])?$/)

          result = result[match[1]]

          if (result && match[3]) {
            result = result[match[3]]
          }
        }

        return result
      }
    })()
})

// The default blank field has empty string instead of &nbsp;.
// That means that comboboxes with empty string as a value (a blank value)
// are only the padding high
Ext.override(Ext.form.ComboBox, {
  initList: function () {
    if (!this.tpl) {
      return (this.tpl = new Ext.XTemplate(
        '<tpl for="."><div class="x-combo-list-item">{',
        this.displayField,
        ':this.encode}</div></tpl>',
        {
          encode(value) {
            if (value === '') return '&nbsp'
            return escape(value)
          }
        }
      ))
    }
    // createSequence(secondFunction) is an Ext modification to Function.prototype
    // It will call secondFunction after the first has executed
  }.createSequence(Ext.form.ComboBox.prototype.initList)
})

// Columns with empty strings don't render correctly
Ext.grid.ColumnModel.defaultRenderer = function (value) {
  if (value && typeof value.valueOf() === 'string' && value.length < 1) return ' '

  return value
}

Ext.override(Ext.form.BasicForm, {
  isDirty() {
    let dirty = false

    this.items.each(function (f) {
      if (typeof f.isDirty === 'function' ? f.isDirty() : undefined) {
        dirty = true
        return false
      }
    })

    return dirty
  }
})

Ext.override(Ext.Component, {
  afterRender() {
    // Updating mask needs to be deferred so it can centre.
    setTimeout(() => this.updateMask())
  },

  mask(msg, msgCls) {
    if (msg) {
      this.maskMsg = msg
    }

    if (msgCls) {
      this.maskMsgCls = msgCls
    }

    this.masked = true
    this.updateMask()
  },

  unmask() {
    delete this.maskMsg
    delete this.maskMsgCls
    this.masked = false
    this.updateMask()
  },

  // Using a separate update function allows mask to be
  // set before component is rendered.
  updateMask() {
    if (!this.el) return

    if (this.masked) {
      this.el.mask(this.maskMsg, this.maskMsgCls)
      // Don't remove a mask applied for disable state.
    } else if (!this.disabled) {
      this.el.unmask()
    }
  },

  findParentWindow() {
    return this.findParentBy((component) => component instanceof Ext.Window)
  }
})

Ext.override(Ext.Container, {
  // Override to allow nested arrays and null values for convenience.
  initComponent() {
    Ext.Container.superclass.initComponent.call(this)

    this.addEvents('afterlayout', 'beforeadd', 'beforeremove', 'add', 'remove')

    const { items } = this

    if (items) {
      delete this.items
      this.add(Array.isArray(items) ? [].concat(...items).filter(Boolean) : items)
    }
  }
})

// Overriden since SCL-2768 so that we can change button order
// Original see lib/ext/ux/grid/row-editor.js
Ext.override(Ext.ux.grid.RowEditor, {
  onRender() {
    Ext.ux.grid.RowEditor.superclass.onRender.apply(this, arguments)
    this.el.swallowEvent(['keydown', 'keyup', 'keypress'])
    this.btns = new Ext.Panel({
      baseCls: 'x-plain',
      cls: 'x-btns',
      elements: 'body',
      layout: 'table',
      // width must be specified for IE
      width: this.minButtonWidth * 2 + this.frameWidth * 2 + this.buttonPad * 4,
      items: [
        {
          xtype: 'button',
          text: this.cancelText,
          width: this.minButtonWidth,
          handler: this.stopEditing.createDelegate(this, [false])
        },
        {
          ref: 'saveBtn',
          itemId: 'saveBtn',
          xtype: 'button',
          text: this.saveText,
          width: this.minButtonWidth,
          handler: this.stopEditing.createDelegate(this, [true])
        }
      ]
    })

    this.btns.render(this.bwrap)
  }
})

Ext.override(Ext.Component, {
  getXType: function () {
    return (this.self || this.constructor).xtype
  },

  isXType: function (xtype, shallow) {
    if (Ext.isFunction(xtype)) {
      xtype = xtype.xtype
    } else if (isObject(xtype)) {
      xtype = xtype.constructor.xtype
    }

    return !shallow
      ? ('/' + this.getXTypes() + '/').indexOf('/' + xtype + '/') !== -1
      : (this.self || this.constructor).xtype === xtype
  },

  getXTypes: function () {
    const tc = this.self || this.constructor

    if (!tc.xtypes) {
      const c = []
      let sc = this

      while (sc && (sc.self || sc.constructor).xtype) {
        c.unshift((sc.self || sc.constructor).xtype)
        sc = (sc.self || sc.constructor).superclass
      }

      tc.xtypeChain = c
      tc.xtypes = c.join('/')
    }

    return tc.xtypes
  }
})

Ext.override(Ext.Panel, {
  // As this creates and appends HTML, input needs escaping
  setTitle: function (title, iconCls) {
    const sanitizedTitle = DOMPurify.sanitize(title)

    this.title = sanitizedTitle

    if (this.header && this.headerAsText) {
      this.header.child('span').update(sanitizedTitle)
    }

    if (iconCls) {
      this.setIconClass(iconCls)
    }

    this.fireEvent('titlechange', this, sanitizedTitle)
  }
})

Ext.override(Ext.CompositeElementLite, {
  replaceElement: function (el, replacement, domReplace) {
    const index = !isNaN(el) ? el : this.indexOf(el)

    if (index > -1) {
      replacement = Ext.getDom(replacement)

      if (domReplace) {
        const d = this.elements[index]

        if (d.parentNode) {
          d.parentNode.insertBefore(replacement, d)
        }
        Ext.removeNode(d)
      }

      this.elements.splice(index, 1, replacement)
    }

    return this
  }
})

Ext.override(Ext.XTemplate, {
  applySubTemplate: function (id, values, parent, xindex, xcount) {
    const me = this
    const t = me.tpls[id]
    const buf = []

    if (
      (t.test && !t.test.call(me, values, parent, xindex, xcount)) ||
      (t.exec && t.exec.call(me, values, parent, xindex, xcount))
    ) {
      return ''
    }

    let vs

    // workaround for https://youtrack.smxemail.com/issue/SCL-3412
    if (NONSENSE_WITH_FN_STR === t.target.toString()) {
      vs = values
    } else {
      vs = t.target ? t.target.call(me, values, parent) : values
    }

    const len = vs.length
    parent = t.target ? values : parent

    if (t.target && Array.isArray(vs)) {
      for (let i = 0, len2 = vs.length; i < len; i++) {
        buf[buf.length] = t.compiled.call(me, vs[i], parent, i + 1, len2)
      }
      return buf.join('')
    }

    return t.compiled.call(me, vs, parent, xindex, xcount)
  }
})

Ext.override(Ext.grid.GridView, {
  updateColumnWidth: function (column) {
    const columnWidth = this.getColumnWidth(column)
    const totalWidth = this.getTotalWidth()
    const headerCell = this.getHeaderCell(column)
    const nodes = this.getRows()
    const nodeCount = nodes.length

    let row
    let i
    let firstChild

    this.updateHeaderWidth()
    headerCell.style.width = columnWidth

    for (i = 0; i < nodeCount; i++) {
      row = nodes[i]
      firstChild = row.firstChild

      row.style.width = totalWidth

      // don't adjust every child. there can be one which is just the "show all" link when more than 100 rows.
      if (firstChild?.rows?.length > 0) {
        firstChild.style.width = totalWidth
        firstChild.rows[0].childNodes[column].style.width = columnWidth
      }
    }

    this.onColumnWidthUpdated(column, columnWidth, totalWidth)
  }
})
