const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const DomainStatus = require('admin/util/DomainStatus')
const User = require('admin/core/User')

const ScrubbingDomainListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'domainName',

  module() {
    return require('admin/business/ScrubbingDomainResource')
  },

  createColumnModel() {
    const columns = [
      {
        id: 'domainName',
        header: t('Domain Name'),
        width: 100,
        sortable: true,
        dataIndex: 'name'
      },
      {
        id: 'mailRelayHostName',
        header: t('Mail Relay Host'),
        width: 150,
        sortable: true,
        dataIndex: 'relayTo'
      },
      {
        id: 'approvalStatus',
        header: t('Approval Status'),
        width: 100,
        sortable: true,
        dataIndex: 'approvalStatus',
        renderer: DomainStatus.translateApproval
      }
    ]

    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }

    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = ScrubbingDomainListPanel
