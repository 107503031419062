// Creates a function to return an arbitrarily nested value at [expr].
const getJsonAccessor = function (expr) {
  const pathTest = /[[.]/
  const array = /^(.*)\[(\d+)\]$/

  try {
    if (pathTest.test(expr)) {
      const body = ['if (!obj) return null; var temp = obj;']
      const parts = expr.split('.')

      parts.forEach(function (part) {
        const res = array.exec(part)

        if (res) {
          return body.push(
            'if (temp.' +
              res[1] +
              ' == undefined || temp.' +
              part +
              ' == undefined) return null; temp = temp.' +
              part +
              ';'
          )
        }

        body.push(
          'if (temp.' + part + ' == undefined) return null; temp = temp.' + part + ';'
        )
      })

      body.push('return temp;')

      // eslint-disable-next-line no-new-func
      return new Function('obj', body.join(''))
    }

    return (obj) => obj[expr]
  } catch (err) {
    return Ext.emptyFn
  }
}

export default getJsonAccessor
