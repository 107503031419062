const Ext = require('ext')
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const ComboBox = require('admin/component/form/ComboBox')
const SmartRule = require('smartrules/designer/SmartRule')
const Tooltip = require('admin/util/Tooltip')

// todo: this is very similar to ListMatcher, so consider inheriting from it instead

const ListNameMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg) {
    this.negated = cfg.negated
    this.subDsl = cfg.subDsl
    this.initialValue = cfg.value

    this.combo = new ComboBox({
      allowBlank: false,
      displayField: 'displayName',
      editable: false,
      emptyText: t('Select a list'),
      flex: 1,
      forceSelection: true,
      listEmptyText: cfg.listEmptyText || t('No lists found'),
      mode: 'local',
      triggerAction: 'all',
      valueField: 'name',
      store: cfg.listStore
    })

    this.combo.on('select', function (combo, record) {
      // only register when quick tip is initialized. during jasmine tests this isn't the case.
      if (Ext.QuickTips.getQuickTip() != null) {
        return Tooltip.register(combo.getEl(), record.get('displayName'))
      }
    })

    if (this.getIndex(this.initialValue) >= 0) {
      this.setValue(this.initialValue)
    }

    const items = [this.combo]

    this.callParent([
      {
        items,
        matcherType: cfg.matcherType,
        value: cfg.value,
        dsl: cfg.dsl,
        layoutConfig: {
          padding: cfg.padding || 0
        }
      }
    ])

    return this.combo.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
  },

  setListStore(listStore) {
    // first remember the value we will set again after reloading the combo box with a new store
    let value
    if (this.combo.store) {
      value = this.getRawValue()
    } else {
      value = this.initialValue
    }

    this.combo.bindStore(listStore)

    const index = this.getIndex(value)

    // reset when current value does not exist in list
    if (index < 0) {
      value = ''
    }

    return this.setValue(value)
  },

  setValue(v) {
    return this.combo.setValue(v)
  },

  getIndex(value) {
    if (value == null) {
      value = this.combo.getValue()
    }

    return this.combo.store?.find(this.combo.valueField, value)
  },

  getRawValue() {
    return this.combo.getValue()
  },

  getValue(options) {
    if (options == null) {
      options = {}
    }
    const raw = options.raw || false
    let value = this.getRawValue()

    if (!raw) {
      value = String.format('"{0}"', value || '')

      if (this.subDsl) {
        value = String.format(this.subDsl, value)
      }
    }

    return value
  }
})

module.exports = ListNameMatcher
