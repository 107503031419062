const Ext = require('ext')
const Record = require('admin/core/Record')
const PriceStore = require('admin/data/PriceStore')
const CatalogueResource = require('admin/business/CatalogueResource')

const CatalogueRecord = Record.define(null, {
  module() {
    return CatalogueResource
  },

  fields: [
    {
      name: 'code',
      type: 'string'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'status',
      type: 'string'
    },
    {
      name: 'billToId',
      type: 'int'
    },
    {
      name: 'billToName',
      type: 'string'
    },
    {
      name: 'prices'
    },
    {
      name: 'wholesale',
      type: 'boolean'
    }
  ],

  getName() {
    return this.get('name')
  },

  getPriceStore() {
    const store = new PriceStore({
      proxy: new Ext.data.MemoryProxy(this.get('prices'))
    })
    store.load()
    return store
  },

  findPrice(currency, frequency, quantity) {
    frequency = parseInt(frequency)
    quantity = parseInt(quantity)

    let price = null

    const priceStore = this.getPriceStore()
    priceStore.filter('uom', currency)

    // Find closest matching frequency and quantity.
    priceStore.each(function (record) {
      if (
        frequency >= (record.get('frequencyValue') || 1) &&
        quantity >= (record.get('fromQuantity') || 1)
      ) {
        if (
          !price ||
          ((record.get('frequencyValue') || 1) >= (price.get('frequencyValue') || 1) &&
            (record.get('fromQuantity') || 1) >= (price.get('fromQuantity') || 1))
        ) {
          return (price = record)
        }
      }
    })

    return price
  },

  billToSmx() {
    return /^SMX/.test(this.get('billToName'))
  }
})

module.exports = CatalogueRecord
