import compareStrings from 'strings/compare/lexical'
import escape from 'smartrules/designer/util/escape'
import globToRegex from 'smartrules/designer/util/globToRegex'
import isString from 'strings/isString'
import orToRegex from 'smartrules/designer/util/orToRegex'
import trimWords from 'smartrules/designer/util/trimWords'

// especially needed for the header condition where any `or` in globs might exist,
// which will be converted to a regex, for example = "To or Cc" becomes "/(To|Cc)/i"

// used for when translating from designer back to source
export default function (value, cfg = {}) {
  if (!value) return value
  if (!isString(value)) return

  let convertedValue = value

  // be careful, order is important, don't change it

  if (cfg.convertGlobToRegex) {
    convertedValue = escape(convertedValue)
    convertedValue = globToRegex(convertedValue)
  }

  if (cfg.convertOrToRegex) {
    convertedValue = orToRegex(convertedValue)
    convertedValue = convertedValue.toLowerCase()
  }

  // figure out if value is now a regex
  const isRegex = compareStrings(convertedValue, value) !== 0

  if (cfg.trim) {
    convertedValue = trimWords(convertedValue)
  }

  // only wrap in /.../ when there really was a change when converting to a regex
  if (isRegex) {
    return `/(${convertedValue})/i`
  }

  return `"${convertedValue}"`
}
