const Ext = require('ext')

const Component = Ext.define(null, {
  extend: Ext.Panel,

  // Find the rule that this component is a child of.
  getSmartRule() {
    // Leave the require call here to avoid circular dependency :(
    return this.findParentBy((n) => n instanceof require('smartrules/designer/SmartRule'))
  }
})

module.exports = Component
