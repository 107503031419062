const Record = require('admin/core/Record')
const RuleManagement = require('smartrules/RuleManagement')
const Time = require('admin/time/Time')
const DomainConfigMixin = require('admin/data/DomainConfigMixin')

const RuleSetRecord = Record.define(null, {
  module() {
    return RuleManagement
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'version',
      type: 'int'
    },
    {
      name: 'ruleSetType',
      type: 'string',
      mapping: 'policySetType'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'priority',
      type: 'int'
    },
    {
      name: 'global',
      type: 'boolean'
    },
    {
      name: 'contactMechCount',
      type: 'int'
    }
  ],

  getParams() {
    return {
      name: this.get('name'),
      global: this.get('global'),
      policySetType: this.get('ruleSetType')
    }
  },

  getConfig: DomainConfigMixin.getConfig,
  saveConfig: DomainConfigMixin.saveConfig,

  isPersistentOnSave() {
    return true
  }
})

module.exports = RuleSetRecord
