const Ext = require('ext')
const t = require('translate')
const Format = require('admin/util/Format')
const escape = require('html-escaper').escape
const Time = require('admin/time/Time')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const CustomerResource = require('admin/business/CustomerResource')
const CustomerRecord = require('admin/data/company/CustomerRecord')
const User = require('admin/core/User')
const WindowMgr = require('admin/desktop/WindowMgr').default
const Api = require('api').default
const logger = require('system/logger').default

const RENDERERS = {
  DATE: Time.toDateString,
  TIMESTAMP: Time.toString,
  NUMBER: Format.number,
  COUNT: Format.number,
  FORMATTED_COUNT: Format.number
}

const getRenderer = (type) => RENDERERS[type] || escape

const getAlignment = function (type) {
  switch (type) {
    case 'NUMBER':
      return 'right'
    case 'COUNT':
      return 'right'
    case 'FORMATTED_COUNT':
      return 'right'
    default:
      return 'left'
  }
}

const findLink = (links, type) => links.find((l) => l.rel === type)

const ReportGridPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,

  cls: 'report-panel',

  forceFit: true,
  loadMask: true,
  viewConfig: {
    forceFit: true,
    hideGroupedColumn: true
  },

  initComponent() {
    const ReportDataStore = require('reporting/ReportDataStore')
    this.store = new ReportDataStore({
      groupField: this.record.getGroupBy(),
      record: this.record.getReportItemRecordConstructor(),
      url: this.record.getUrl()
    })
    this.store.on('beforeload', this.onStoreBeforeLoad, this)
    this.store.on('load', this.onStoreLoad, this)

    this.view = new Ext.grid.GroupingView(this.viewConfig)

    this.selModel = new Ext.grid.RowSelectionModel()
    this.selModel.on('selectionchange', this.onSelectionChange, this)

    this.columns = this.record.get('fields').map((field) => ({
      align: getAlignment(field.type),
      dataIndex: field.name,
      header: field.title,
      hidden: field.hide,
      menuDisabled: true,
      renderer: getRenderer(field.type),
      sortable: true
    }))

    this.on('rowdblclick', this.onRowDblClick)

    this.tbar = [
      this.record.hasReportingOptions() &&
        new Ext.Button({
          text: t('Configure'),
          handler: () => {
            const Report = require('reporting/Report')
            return Report.showReportOptions(this.record, {
              currentOptions: this.reportOptions,
              callback: (values) => {
                this.reportOptions = values
                return this.store.load()
              }
            })
          }
        }),

      this.record.isSchedulable() &&
        new Ext.Button({
          text: t('Subscribe'),
          handler: () => {
            const Subscription = require('reporting/Subscription')
            const store = this.record.getSubscriptionStore()
            return Subscription.create(this.record, { store })
          }
        }),

      this.record.get('id') === 'pendingdomains' &&
        new Ext.Button({
          disabled: true,
          text: t('Open Customer'),
          shouldEnable: () => {
            return this.getCustomerLink() != null
          },
          handler: () => {
            const link = this.getCustomerLink()
            if (link != null) {
              const record = new CustomerRecord()
              return record.fetch({
                url: link.href,
                success() {
                  return CustomerResource.open(record)
                }
              })
            }
          }
        }),

      this.record.get('id') === 'pendingdomains' &&
        new Ext.Button({
          disabled: true,
          text: t('Approve Domain'),
          shouldEnable: () => {
            return this.getApprovalLink() != null
          },
          handler: () => {
            const link = this.getApprovalLink()

            // since SCL-2495, we are letting backend decide
            // which method and url to pick for approving a domain
            return Api[link.method.toLowerCase()](link.href)
              .then(() => this.store.reload())
              .catch((err) => logger.error(err))
          }
        }),

      this.record.get('id') === 'cancellations' &&
        new Ext.Button({
          text: t('Open Customer'),
          disabled: true,
          shouldEnable: () => {
            return this.getCustomerId() != null
          },
          handler: () => {
            const id = this.getCustomerId()
            const customer = new CustomerRecord({
              id,
              url: `/api/customer/${id}`
            })
            return customer.fetch().then(() => CustomerResource.open(customer))
          }
        }),

      // only shown under vendor reports in tree / pending cancellation
      this.record.get('id') === 'cancellations' &&
        User.hasRole('PROVISION_CUSTOMER') &&
        new Ext.Button({
          text: t('Cancel Customer'),
          disabled: true,
          shouldEnable: () => {
            return this.getCustomerId() != null
          },
          handler: () => {
            const selectedCustomer = this.selModel.getSelected()
            const customerId = selectedCustomer.get('customerid')
            const customer = new CustomerRecord({
              id: customerId,
              url: `/api/customer/${customerId}`,
              displayName: selectedCustomer.get('companyname')
            })
            customer.on('destroy', () => {
              this.store?.load()
            })
            return CustomerResource.remove(customer)
          }
        }),

      '->',

      (() => {
        if (this.record.isExportable()) {
          const formats = {
            CSV: t('Download CSV'),
            HTML: t('View HTML'),
            PDF: t('Download PDF')
          }

          const items = Object.keys(formats).map((type) => {
            if (this.record.get('reportFormats').indexOf(type) < 0) return undefined
            const text = formats[type]

            return {
              text,
              iconCls: 'export-' + type.toLowerCase(),
              handler: () => {
                const Report = require('reporting/Report')
                return Report.openReportExport(
                  { format: type },
                  this.record,
                  this.reportOptions
                )
              }
            }
          })

          return new Ext.Button({
            text: t('Export'),
            menu: new Ext.menu.Menu({ items })
          })
        }
      })(),

      new Ext.Button({
        text: t('Refresh'),
        handler: () => this.store.load()
      })
    ]

    this.callParent()
  },

  afterRender() {
    this.callParent()
    this.store.load()
  },

  getCustomerId() {
    const record = this.selModel.getSelected()
    return record?.get('customerid')
  },

  getCustomerLink() {
    const record = this.selModel.getSelected()
    return record && findLink(record.get('links'), 'smx3:customer/details')
  },

  getApprovalLink() {
    const record = this.selModel.getSelected()
    return record && findLink(record.get('links'), 'smx3:domains/approve')
  },

  onStoreBeforeLoad(store, options) {
    // Always use the current report options for request params.
    return (options.params = this.reportOptions)
  },

  onStoreLoad() {
    if (!this.isDestroyed) {
      // Set title captured by the store reader.
      this.ownerCt.setTitle(this.store.getTitle())
      // Reset sort state.
      delete this.store.sortInfo
      delete this.store.multiSortInfo
      this.view.clearHeaderSortState()
    }
  },

  onSelectionChange() {
    // Allows buttons to update without setting individual event listeners.
    const topTb = this.getTopToolbar()

    if (!topTb) return
    topTb.items.each(function (item) {
      if (item.shouldEnable && typeof item.shouldEnable === 'function') {
        item.setDisabled(!item.shouldEnable())
      }
    })
  },

  onRowDblClick(grid, rowIndex) {
    // Show row details in a window
    const rowRecord = this.store.getAt(rowIndex)
    const recordId = rowRecord?.get('id') || rowRecord?.id || ''
    const windowId = `show.report.item[${recordId}]`
    if (!WindowMgr.select(windowId)) {
      const url = rowRecord.getUrl() + '/' + recordId
      return WindowMgr.create({
        id: windowId,
        url,
        title: t('{item} Report Item', { item: this.record.get('title') }),
        width: 560,
        autoHeight: true,
        height: 'auto',
        iconCls: 'new',
        shim: false,
        animCollapse: false,
        maximizable: false,
        resizable: false,
        layout: 'fit',
        items: new RecordFormPanel({
          forceLayout: true,
          items: this.record.get('fields').map(
            (field) =>
              new ReadOnlyTextField({
                fieldLabel: field.title,
                name: field.name,
                value: rowRecord.get(field.name),
                width: 400
              })
          )
        })
      })
    }
  }
})

module.exports = ReportGridPanel
