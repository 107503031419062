const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ConvertToolPanel = require('admin/view/ConvertToolPanel')

const ConvertToolResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.ConvertTool',
  translations: {
    title: t('Convert Customers')
  },
  name: t('Convert'),
  resourceId: 'CONVERT_TOOL',

  listPanel: ConvertToolPanel
})

module.exports = ConvertToolResource
