const Ext = require('ext')
const DateRangeFieldSet = require('admin/component/form/DateRangeFieldSet')
const ReadOnlyDateField = require('admin/component/form/ReadOnlyDateField')

const ReadOnlyDateRangeFieldSet = Ext.define(null, {
  extend: DateRangeFieldSet,

  readOnly: true,

  constructor(cfg) {
    // DateRangeFieldSet has a more complicated arguments and we want to pass them all so
    // the object is built correctly. This is different to how the other (more simple)
    // readonly fields are built.
    const cloneCfg = {
      width: cfg.clone.width,
      from: cfg.clone.from,
      thru: cfg.clone.thru,
      both: cfg.clone.both
    }

    this.callParent([cloneCfg])

    // @TODO This is an ugly hack to replace fields similar to substituteReadOnlyItems in resource.js
    if (this.items instanceof Ext.util.MixedCollection) {
      if (this.items.getCount()) {
        for (
          let i = 0, end = this.items.getCount() - 1, asc = end >= 0;
          asc ? i <= end : i >= end;
          asc ? i++ : i--
        ) {
          const item = this.items.itemAt(i)
          const roItem = new ReadOnlyDateField({ clone: item })
          this.items.insert(i, roItem)
          this.items.remove(item)
        }
      }
    }

    // Need to explicitly return nothing so results of array comprehension aren't returned
  }
})

module.exports = ReadOnlyDateRangeFieldSet
