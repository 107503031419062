import React, { Component } from 'react'

import Search from 'mail/search/views/Search'
import PropTypes from 'prop-types'
import {
  back,
  closeReleaseDialog,
  download,
  enableHighlighting,
  hideSource,
  next,
  openReleaseDialog,
  prev,
  release,
  search,
  select,
  setColumnWidth,
  setDateRange,
  setDateRangeHidden,
  setDateRangeShown,
  setDirection,
  setQuery,
  setRecipientsValidity,
  setTimeZoneName,
  toggleAttachmentPanel,
  view,
  viewSource
} from 'quarantine/search/actions'
import AVAILABLE_KEYWORDS from 'quarantine/search/keywords'
import {
  attachmentPanelExpanded,
  columns,
  confirmingRelease,
  currentResultNumber,
  direction,
  downloadable,
  downloadUri,
  firstRowNumber,
  fromDate,
  hasNext,
  hasPrev,
  highlight,
  lastRowNumber,
  loading,
  mailWithDetail,
  nextParams,
  originalRecipients,
  preview,
  prevParams,
  query,
  recipientsValid,
  releasable,
  released,
  releaseFailed,
  releaseSize,
  releaseSkipped,
  releaseSuccessful,
  resultsQuery,
  rows,
  scrollToIndex,
  searchParameters,
  selectedCount,
  selectedRows,
  showNoResults,
  showResults,
  source,
  sourceAvailable,
  startDate,
  thruDate,
  timeZoneName,
  total,
  uri,
  viewing
} from 'quarantine/search/subs'
import { connect } from 'react-redux'

class Tab extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired
  }

  handleHighlightingChange = (enabled) => this.props.enableHighlighting(enabled)

  handleRelease = (emails) => this.props.release(this.props.selectedRows, emails)

  handleOpenConfirmingRelease = () =>
    this.props.openReleaseDialog(this.props.selectedRows)

  handleDownload = () => this.props.download(this.props.downloadUri)

  handleQueryChange = (e) => this.props.setQuery(e.target.value)

  handleDateRangeChange = (fromDate, thruDate) =>
    this.props.setDateRange(fromDate.toJSON(), thruDate.toJSON())

  handleDateRangeShow = () => this.props.setDateRangeShown()
  handleDateRangeHide = () => this.props.setDateRangeHidden()

  handleSearch = () =>
    this.props.search(
      this.props.uri,
      this.props.viewing,
      this.props.searchParameters,
      this.props.timeZoneName
    )

  handleDirectionChange = (e) => this.props.setDirection(e.target.value)

  handleTimeZoneNameChange = (timeZoneName) => this.props.setTimeZoneName(timeZoneName)

  handleSelect = (rows, selected) => this.props.select(rows, selected)

  handleRowClick = (relativeIndex) =>
    this.props.view(this.props.rows[relativeIndex], relativeIndex)

  handleAttachmentPanelToggle = (expanded) => this.props.toggleAttachmentPanel(expanded)

  handleNext = () => this.props.next(this.props.nextParams)

  handlePrev = () => this.props.prev(this.props.prevParams)

  handleRecipientsValidityChange = (valid) => this.props.setRecipientsValidity(valid)

  handleViewSource = () => this.props.viewSource(this.props.downloadUri)

  handleSourceHide = () => this.props.hideSource()

  render() {
    return (
      <Search
        companyKey={this.props.companyKey}
        width={this.props.width}
        height={this.props.height}
        availableKeywords={AVAILABLE_KEYWORDS}
        rows={this.props.rows}
        resultsQuery={this.props.resultsQuery}
        query={this.props.query}
        startDate={this.props.startDate}
        fromDate={this.props.fromDate}
        thruDate={this.props.thruDate}
        timeZoneName={this.props.timeZoneName}
        loading={this.props.loading}
        confirmingRelease={this.props.confirmingRelease}
        direction={this.props.direction}
        enableHighlighting={this.props.highlight}
        onDirectionChange={this.handleDirectionChange}
        onRecipientsValidityChange={this.handleRecipientsValidityChange}
        recipientsValid={this.props.recipientsValid}
        releaseSize={this.props.releaseSize}
        releaseSuccessful={this.props.releaseSuccessful}
        releaseSkipped={this.props.releaseSkipped}
        releaseFailed={this.props.releaseFailed}
        originalRecipients={this.props.originalRecipients}
        mailToView={this.props.mailWithDetail}
        viewing={this.props.viewing}
        currentResultNumber={this.props.currentResultNumber}
        preview={this.props.preview}
        attachmentPanelExpanded={this.props.attachmentPanelExpanded}
        onDownload={this.handleDownload}
        onRelease={this.handleRelease}
        onOpenConfirmingRelease={this.handleOpenConfirmingRelease}
        onCloseConfirmingRelease={this.props.onCloseReleaseDialog}
        onPrev={this.handlePrev}
        onNext={this.handleNext}
        onDateRangeChange={this.handleDateRangeChange}
        onDateRangeShow={this.handleDateRangeShow}
        onDateRangeHide={this.handleDateRangeHide}
        onQueryChange={this.handleQueryChange}
        onSearch={this.handleSearch}
        onTimeZoneNameChange={this.handleTimeZoneNameChange}
        onAttachmentPanelToggle={this.handleAttachmentPanelToggle}
        onSelect={this.handleSelect}
        onRowClick={this.handleRowClick}
        onViewSource={this.handleViewSource}
        onHighlightingChange={this.handleHighlightingChange}
        onBack={this.props.onBack}
        onSourceHide={this.handleSourceHide}
        endReached // because backend does not return next link, we cannot compute that
        releasable={this.props.releasable}
        released={this.props.released}
        scrollToIndex={this.props.scrollToIndex}
        firstRowNumber={this.props.firstRowNumber}
        hasNext={this.props.hasNext}
        hasPrev={this.props.hasPrev}
        lastRowNumber={this.props.lastRowNumber}
        selectedCount={this.props.selectedCount}
        showNoResults={this.props.showNoResults}
        showResults={this.props.showResults}
        total={this.props.total}
        columns={this.props.columns}
        setColumnWidth={this.props.setColumnWidth}
        sourceAvailable={this.props.sourceAvailable}
        source={this.props.source}
        downloadable={this.props.downloadable}
      />
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  query: query(db, companyKey),
  startDate: startDate(db, companyKey),
  fromDate: fromDate(db, companyKey),
  thruDate: thruDate(db, companyKey),
  loading: loading(db, companyKey),
  timeZoneName: timeZoneName(db, companyKey),
  uri: uri(db, companyKey),
  searchParameters: searchParameters(db, companyKey),
  rows: rows(db, companyKey),
  resultsQuery: resultsQuery(db, companyKey),
  direction: direction(db, companyKey),
  highlight: highlight(db, companyKey),
  confirmingRelease: confirmingRelease(db, companyKey),
  nextParams: nextParams(db, companyKey),
  prevParams: prevParams(db, companyKey),
  scrollToIndex: scrollToIndex(db, companyKey),
  releaseSize: releaseSize(db, companyKey),
  releaseSuccessful: releaseSuccessful(db, companyKey),
  releaseSkipped: releaseSkipped(db, companyKey),
  releaseFailed: releaseFailed(db, companyKey),
  recipientsValid: recipientsValid(db, companyKey),
  originalRecipients: originalRecipients(db, companyKey),
  mailWithDetail: mailWithDetail(db, companyKey),
  preview: preview(db, companyKey),
  attachmentPanelExpanded: attachmentPanelExpanded(db, companyKey),
  viewing: viewing(db, companyKey),
  releasable: releasable(db, companyKey),
  released: released(db, companyKey),
  firstRowNumber: firstRowNumber(db, companyKey),
  hasNext: hasNext(db, companyKey),
  hasPrev: hasPrev(db, companyKey),
  lastRowNumber: lastRowNumber(db, companyKey),
  selectedCount: selectedCount(db, companyKey),
  selectedRows: selectedRows(db, companyKey),
  showNoResults: showNoResults(db, companyKey),
  showResults: showResults(db, companyKey),
  currentResultNumber: currentResultNumber(db, companyKey),
  total: total(db, companyKey),
  columns: columns(db, companyKey),
  sourceAvailable: sourceAvailable(db, companyKey),
  source: source(db, companyKey),
  downloadUri: downloadUri(db, companyKey),
  downloadable: downloadable(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  onBack: () => dispatch(back(companyKey)),
  enableHighlighting: (enabled) => dispatch(enableHighlighting(companyKey, enabled)),
  openReleaseDialog: (rows) => dispatch(openReleaseDialog(companyKey, rows)),
  onCloseReleaseDialog: () => dispatch(closeReleaseDialog(companyKey)),
  release: (rows, emails) => dispatch(release(companyKey, rows, emails)),
  setRecipientsValidity: (valid) => dispatch(setRecipientsValidity(companyKey, valid)),
  select: (rows, selected) => dispatch(select(companyKey, rows, selected)),
  prev: (params) => dispatch(prev(companyKey, params)),
  next: (params) => dispatch(next(companyKey, params)),
  toggleAttachmentPanel: (expanded) =>
    dispatch(toggleAttachmentPanel(companyKey, expanded)),
  setDateRange: (fromDate, thruDate) =>
    dispatch(setDateRange(companyKey, fromDate, thruDate)),
  setDateRangeShown: () => dispatch(setDateRangeShown(companyKey)),
  setDateRangeHidden: () => dispatch(setDateRangeHidden(companyKey)),
  setTimeZoneName: (timeZoneName) => dispatch(setTimeZoneName(companyKey, timeZoneName)),
  setDirection: (direction) => dispatch(setDirection(companyKey, direction)),
  setQuery: (query) => dispatch(setQuery(companyKey, query)),
  search: (uri, viewing, parameters, timeZoneName) =>
    dispatch(search(companyKey, uri, viewing, parameters, timeZoneName)),
  view: (mail, relativeIndex) => dispatch(view(companyKey, mail, relativeIndex)),
  setColumnWidth: (width, columnKey) =>
    dispatch(setColumnWidth(companyKey, columnKey, width)),
  viewSource: (downloadUri) => dispatch(viewSource(companyKey, downloadUri)),
  hideSource: () => dispatch(hideSource(companyKey)),
  download: (downloadUrl) => dispatch(download(companyKey, downloadUrl))
})

export default connect(mapDbToProps, mapActionsToProps)(Tab)
