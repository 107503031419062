export default {
  // states must be split by company because
  // they might vary from vendor, distributor and reseller and multiple
  // forms can exist in DOM shadows
  companies: {
    /*

    '/vendor/1004' for example is the unique identifier of a company we're
    calling companyKey

    here an example what the state could look like for one active company:

    '/customer/2014041': {
      'smx3:customer/mail-target': {
        uri: 'https://localhost:3000/api/customer/2014041/mail-targets',
        loading: false,
        list: [ ... ]
      },
      'smx3:mailproducts/quarantine-set': {
        uri: 'https://localhost:3000/api/customer/2014041/quarantine-sets',
        loading: false,
        list: [ ... ],
        availableTriggers: [
          'GREY',
          'SPAM'
        ],
        availableTargets: [
          {
            href: 'https://localhost:3000/api/mail-target/1',
            name: 'target a',
            title: 'Target A'
          },
          {
            href: 'https://localhost:3000/api/mail-target/2',
            name: 'target b',
            title: 'Target B'
          }
        ]
      },
      'search': {
        // see search/db.es
      }
    }
    */
  }
}
