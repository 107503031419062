import * as hal from 'hal'

import {
  MAILBOX_DELETE_REL,
  MAILBOX_PURGE_REL,
  MAILBOX_REL,
  MAILBOX_RESTORE_REL,
  MAILBOX_SUSPEND_REL,
  MAILBOX_UNSUSPEND_REL,
  MAILBOX_UPDATE_ALIASES_REL,
  MAILBOX_UPDATE_FORWARDS_REL,
  MAILBOX_UPDATE_PASSWORD_REL,
  MAILBOX_UPGRADE_CATALOGUE_REL,
  MAILBOX_UPGRADE_COS_REL,
  MAILBOX_UPGRADE_REL
} from 'product/resources'

import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'
import { loading as mailhostLoading } from 'mailhosting/subs'

// this returns a list of links by given relation
const reduceLinkSelections = (rows, rel) => {
  return rows.reduce((links, row) => {
    const link = hal.href(row, rel)
    if (!link) return links
    links.push(link)
    return links
  }, [])
}

const extractHref = (links, rel) => {
  if (!links) return

  const link = links[rel]

  if (!link) return

  return hal.first(link).href
}

const company = createCompanySelector('mailboxes')
const formDb = (db) => db.form

export const mailboxes = createSelector(
  company,
  (company) => company && company[MAILBOX_REL]
)

export const list = createSelector(mailboxes, (mailboxes) => mailboxes?.list)

export const loading = createSelector(mailboxes, (mailboxes) =>
  Boolean(mailboxes?.loading)
)

export const uri = createSelector(
  mailboxes,
  // TODO have backend return the link, see SSV-3537
  (mailboxes) => mailboxes?.customerUrl + '/mailhost/mailboxes'
)

export const size = createSelector(list, (list) => list?.length)

export const creating = createSelector(mailboxes, (mailboxes) =>
  Boolean(mailboxes?.creating)
)

export const editTarget = createSelector(mailboxes, (mailboxes) => mailboxes?.editTarget)

export const upgradeTarget = createSelector(
  mailboxes,
  (mailboxes) => mailboxes?.upgradeTarget
)

const editTargetLinks = createSelector(
  editTarget,
  (editTarget) => editTarget && editTarget._links
)

export const editing = createSelector(editTarget, (editTarget) => Boolean(editTarget))

export const upgrading = createSelector(upgradeTarget, (upgradeTarget) =>
  Boolean(upgradeTarget)
)

export const confirmingDeletion = createSelector(
  mailboxes,
  (mailboxes) => mailboxes?.confirmingDeletion
)

export const confirmingPurge = createSelector(
  mailboxes,
  (mailboxes) => mailboxes?.confirmingPurge
)

export const hasModalDialog = createSelector(
  creating,
  editing,
  upgrading,
  confirmingDeletion,
  (creating, editing, upgrading, confirmingDeletion) => {
    return creating || editing || upgrading || confirmingDeletion
  }
)

export const disableForm = createSelector(
  loading,
  mailhostLoading,
  (loading, mailhostLoading) => Boolean(loading || mailhostLoading)
)

export const disableEmailInput = createSelector(loading, editing, (loading, editing) =>
  Boolean(loading || editing)
)

export const blocked = createSelector(
  hasModalDialog,
  disableForm,
  (hasModalDialog, disableForm) => Boolean(hasModalDialog || disableForm)
)

/// ========= SELECTION ========= ///

export const selectedRows = createSelector(list, (list) => {
  return list?.filter((row) => row.selected)
})

export const selectedRow = createSelector(selectedRows, (selectedRows) => {
  return selectedRows && selectedRows.length === 1 && selectedRows[0]
})

export const selectedCount = createSelector(selectedRows, (selectedRows) => {
  if (!selectedRows) return 0
  return selectedRows.length
})

export const selectedMailboxLink = createSelector(selectedRow, (selectedRow) => {
  return selectedRow && hal.self(selectedRow)
})

export const selectedMailboxUpgradeCatalogueLink = createSelector(
  selectedRow,
  (selectedRow) => {
    return selectedRow && hal.href(selectedRow, MAILBOX_UPGRADE_CATALOGUE_REL)
  }
)

export const selectedMailboxesDeleteLinks = createSelector(
  selectedRows,
  (selectedRows) => {
    if (!selectedRows) return

    return reduceLinkSelections(selectedRows, MAILBOX_DELETE_REL)
  }
)

export const selectedMailboxesRestoreLinks = createSelector(
  selectedRows,
  (selectedRows) => {
    if (!selectedRows) return

    return reduceLinkSelections(selectedRows, MAILBOX_RESTORE_REL)
  }
)

export const selectedMailboxesPurgeLinks = createSelector(
  selectedRows,
  (selectedRows) => {
    if (!selectedRows) return

    return reduceLinkSelections(selectedRows, MAILBOX_PURGE_REL)
  }
)

export const selectedMailboxAddress = createSelector(selectedRow, (selectedRow) => {
  return selectedRow && selectedRow.address
})

/// ========= TOOLBAR ========= ///

export const refreshButtonDisabled = createSelector(blocked, (blocked) => blocked)

const links = createSelector(
  mailboxes,
  // TODO have backend return the link to the mailhost catalogue see SSV-3537
  (mailboxes) => mailboxes?.links
)

export const disableAddButton = createSelector(blocked, links, (blocked, links) => {
  return blocked || !links || !('smx3:general/create' in links)
})

export const disableEditButton = createSelector(
  blocked,
  selectedRow,
  (blocked, selectedRow) => {
    return blocked || !selectedRow || selectedRow.statusCode.toLowerCase() === 'purging'
  }
)

export const suspendAllLink = createSelector(links, (links) => {
  return extractHref(links, MAILBOX_SUSPEND_REL)
})

export const unsuspendAllLink = createSelector(links, uri, (links, uri) => {
  if (!links) return

  const link = links[MAILBOX_UNSUSPEND_REL]

  if (!link) return

  // hardcoded since backend is returning a wrong link,
  // see https://youtrack.smxemail.com/issue/SSV-3545
  return uri + ';unsuspend'

  // that's what it should be when the above is fixed
  // return hal.first(link).href
})

export const disableUnsuspendAllButton = createSelector(
  blocked,
  unsuspendAllLink,
  (blocked, unsuspendAllLink) => {
    return blocked || !unsuspendAllLink
  }
)

export const disableSuspendAllButton = createSelector(
  blocked,
  suspendAllLink,
  (blocked, suspendAllLink) => {
    return blocked || !suspendAllLink
  }
)

export const disableDeleteButton = createSelector(
  blocked,
  selectedMailboxesDeleteLinks,
  (blocked, selectedMailboxesDeleteLinks) => {
    if (blocked || !selectedMailboxesDeleteLinks) return true
    return selectedMailboxesDeleteLinks.length < 1
  }
)

export const disablePurgeButton = createSelector(
  blocked,
  selectedMailboxesPurgeLinks,
  (blocked, selectedMailboxesPurgeLinks) => {
    if (blocked || !selectedMailboxesPurgeLinks) return true
    return selectedMailboxesPurgeLinks.length < 1
  }
)

export const disableRestoreButton = createSelector(
  blocked,
  selectedMailboxesRestoreLinks,
  (blocked, selectedMailboxesRestoreLinks) => {
    if (blocked || !selectedMailboxesRestoreLinks) return true
    return selectedMailboxesRestoreLinks.length < 1
  }
)

export const disableUpgradeButton = createSelector(
  blocked,
  selectedRow,
  (blocked, selectedRow) => {
    return blocked || !selectedRow || !hal.link(selectedRow, MAILBOX_UPGRADE_REL)
  }
)

/// ========= FORMS ========= ///

export const mailboxForm = createSelector(formDb, (formDb) => formDb.mailboxForm)

export const forwardingStyle = createSelector(mailboxForm, (mailboxForm) => {
  const forwardingStyle = mailboxForm?.values?.forwardingStyle

  if (!forwardingStyle) return 'DISABLED' // default

  return forwardingStyle
})

export const requireForward = createSelector(forwardingStyle, (forwardingStyle) => {
  return Boolean(forwardingStyle !== 'DISABLED')
})

const forward = createSelector(mailboxForm, (mailboxForm) => {
  return mailboxForm?.values?.forward
})

export const forwardValid = createSelector(
  requireForward,
  forward,
  (requireForward, forward) => {
    return Boolean(!requireForward || forward)
  }
)

export const password = createSelector(mailboxForm, (mailboxForm) => {
  return mailboxForm?.values?.password
})

const passwordError = createSelector(mailboxForm, (mailboxForm) => {
  return mailboxForm?.asyncErrors?.password
})

const repeatPassword = createSelector(mailboxForm, (mailboxForm) => {
  return mailboxForm?.values?.repeatPassword
})

const repeatPasswordError = createSelector(mailboxForm, (mailboxForm) => {
  return mailboxForm?.asyncErrors?.repeatPassword
})

export const requirePassword = createSelector(
  creating,
  editing,
  repeatPassword,
  (creating, editing, repeatPassword) => {
    return Boolean(creating || (editing && repeatPassword))
  }
)

export const requireRepeatPassword = createSelector(
  creating,
  editing,
  password,
  (creating, editing, password) => {
    return Boolean(creating || (editing && password))
  }
)

export const passwordValid = createSelector(
  requirePassword,
  requireRepeatPassword,
  password,
  repeatPassword,
  passwordError,
  repeatPasswordError,
  (
    requirePassword,
    requireRepeatPassword,
    password,
    repeatPassword,
    passwordError,
    repeatPasswordError
  ) => {
    if (!requirePassword && !requireRepeatPassword) return true
    if (passwordError || repeatPasswordError) return false
    if (requirePassword && password === '') return false
    if (requireRepeatPassword && repeatPassword === '') return false

    return Boolean(password === repeatPassword)
  }
)

export const updateDetailsLink = createSelector(editTarget, (editTarget) => {
  // backend unfortunately not returning that link
  return editTarget && hal.self(editTarget) + ';updateDetails'
})

export const updatePasswordLink = createSelector(editTargetLinks, (editTargetLinks) => {
  return extractHref(editTargetLinks, MAILBOX_UPDATE_PASSWORD_REL)
})

export const updateAliasLink = createSelector(editTargetLinks, (editTargetLinks) => {
  return extractHref(editTargetLinks, MAILBOX_UPDATE_ALIASES_REL)
})

export const updateForwardLink = createSelector(editTargetLinks, (editTargetLinks) => {
  return extractHref(editTargetLinks, MAILBOX_UPDATE_FORWARDS_REL)
})

export const showPasswordInput = createSelector(
  creating,
  editing,
  updatePasswordLink,
  (creating, editing, updatePasswordLink) => {
    if (creating) return true
    return editing && updatePasswordLink
  }
)

const upgradeTargetLinks = createSelector(
  upgradeTarget,
  (upgradeTarget) => upgradeTarget && upgradeTarget._links
)

export const upgradeLink = createSelector(upgradeTargetLinks, (upgradeTargetLinks) => {
  return extractHref(upgradeTargetLinks, MAILBOX_UPGRADE_COS_REL)
})

export const disableCatalogue = createSelector(
  editing,
  disableForm,
  (editing, disableForm) => Boolean(editing || disableForm)
)
