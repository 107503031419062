import _s from 'underscore.string'
import getQueryParam from 'url/getQueryParam'

// have host param optional for unit testing
const isDeveloping = (host = window.location.host) => {
  const dev = getQueryParam('dev')

  // when amending ?dev=1 or ?dev=true before the hashbang #!
  // you can manually override the dev flag
  if (dev) {
    return _s.toBoolean(dev)
  }

  if (/dev\./.test(host)) {
    return true
  }

  return _s.toBoolean(window.DEV)
}

export default isDeveloping
