import React, { Component } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import t from 'translate'
import Format from 'admin/util/Format'

class Info extends Component {
  static propTypes = {
    attachments: PropTypes.array.isRequired,
    displaySizes: PropTypes.bool
  }

  renderTotalSize() {
    const total = this.props.attachments.reduce((total, attachment) => {
      if (attachment.size) {
        return total + attachment.size
      }

      return total
    }, 0)

    return <small>{Format.bytes(total)}</small>
  }

  render() {
    const count = this.props.attachments.length

    // todo add a paperclip icon later as a visual decoration

    return (
      <div>
        {count} {t(pluralize('attachment', count))}
        {this.props.displaySizes && this.renderTotalSize()}
      </div>
    )
  }
}

export default Info
