const Ext = require('ext')

// Overrides the combobox trigger configuration to be a div tag instead of an
// img tag so that font-based icons can be used.
const ComboBox = Ext.define(null, {
  extend: Ext.form.ComboBox,

  xtype: 'smx-combo',

  initComponent() {
    Ext.apply(this, {
      triggerConfig: {
        tag: 'div',
        cls: 'x-form-trigger x-form-combo-trigger primary x-btn'
      }
    })

    this.callParent(arguments)
  }
})

module.exports = ComboBox
