import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import t from 'translate'
import Dialog from 'components/dialog/Confirmation'

import { stopConfirmingDeletion, deleteMailboxes } from 'mailboxes/actions'

import {
  loading,
  uri,
  selectedCount,
  selectedMailboxAddress,
  selectedMailboxesDeleteLinks
} from 'mailboxes/subs'

class Delete extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.deleteMailboxes(this.props.uri, this.props.selectedMailboxesDeleteLinks)
  }

  render() {
    let title, warning

    if (this.props.selectedCount === 1) {
      title = t('Delete mailbox {0}?', [this.props.selectedMailboxAddress])

      warning = t(
        'This will schedule this mailbox and any messages in it for deletion. Are you sure you want to delete mailbox {0}?',
        [this.props.selectedMailboxAddress]
      )
    } else {
      title = t('Delete {0} mailboxes?', [this.props.selectedCount])

      warning = t(
        'This will schedule these selected mailboxes and any messages in it for deletion. Are you sure you want to delete {0} mailboxes?',
        [this.props.selectedCount]
      )
    }

    return (
      <Dialog
        icon='exclamation'
        modalTitle={title}
        headerText={warning}
        submitButtonText={t('Delete')}
        cancelDisabled={this.props.loading}
        submitDisabled={this.props.loading}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  loading: loading(db, props.companyKey),
  selectedCount: selectedCount(db, props.companyKey),
  selectedMailboxAddress: selectedMailboxAddress(db, props.companyKey),
  selectedMailboxesDeleteLinks: selectedMailboxesDeleteLinks(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopConfirmingDeletion(initialProps.companyKey)),
  deleteMailboxes: (uri, selectedLink) => {
    dispatch(deleteMailboxes(initialProps.companyKey, uri, selectedLink))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Delete)
