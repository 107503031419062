import getPathSegment from 'url/getPathSegment'

const ABSOLUTE_REGEX = /^https?:\/\//i

const hasApiPath = (input) => getPathSegment(input, 1) === 'api'
const isAbsolute = (input) => ABSOLUTE_REGEX.test(input)

const toPath = (input) => {
  const parsedUrl = new URL(input, window.location.href)
  const path = parsedUrl.pathname + parsedUrl.searchParams.toString()

  // for absolute urls or paths that already have api in them, return pathname
  //
  // todo this is kinda ugly, rewrite this tech debt and ensure all URLs from
  // backend have API in them.
  if (isAbsolute(input) || hasApiPath(input)) {
    return path
  }

  // amend api first then
  return '/api' + path
}

export default toPath
