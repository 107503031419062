import Ext from 'ext'
import t from 'translate'
import React from 'react'

import ReduxContainerPanel from 'admin/view/ReduxContainerPanel'
import Tab from 'mailboxes/views/Tab'

import store from 'store'
import { init, unload as unloadMailboxes } from 'mailboxes/actions'
import { hasModalDialog } from 'mailboxes/subs'

const MailboxTab = Ext.define(null, {
  extend: ReduxContainerPanel,
  title: t('Mailboxes'),
  cls: 'mailbox-panel',
  tabCls: 'mailbox-tab',
  autoScroll: true,

  initComponent: function () {
    const customer = this.initialConfig.customer

    store.dispatch(init(customer.getCompanyKey(), customer.getUrl()))

    if (this.product && !this.product.current) {
      this.tabCls += ' expired-group-tab'
    }

    this.component = React.createElement(Tab, {
      companyKey: customer.getCompanyKey()
    })

    // trick to call parent extjs function from es6 within
    this.superclass().initComponent.call(this)
  },

  preventClose: function () {
    return hasModalDialog(
      this.store.getState(),
      this.initialConfig.customer.getCompanyKey()
    )
  },

  destroy: function () {
    this.superclass().destroy.call(this)

    // cleanup only at the very end when all else has been destroyed
    this.store.dispatch(unloadMailboxes(this.initialConfig.customer.getCompanyKey()))
  }
})

export default MailboxTab
