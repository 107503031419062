const compareStrings = require('./lexical')
const isString = require('strings/isString').default

// it checks the contents of two arrays and returns true if both are lexically the same
// because you cant pass on a custom comparison fn to underscore's union(),
// we have to write that code below
module.exports = function (anything1, anything2) {
  // first make sure, all parameters are an array
  let stringArray1, stringArray2
  if (!Array.isArray(anything1)) {
    stringArray1 = [anything1]
  } else {
    stringArray1 = anything1
  }

  if (!Array.isArray(anything2)) {
    stringArray2 = [anything2]
  } else {
    stringArray2 = anything2
  }

  let equal = true

  if (stringArray1.length === stringArray2.length) {
    let i = 0

    // does lexical string comparison for both string arrays in same sequence
    while (i < stringArray1.length && equal) {
      if (isString(stringArray1[i]) && isString(stringArray2[i])) {
        equal = compareStrings(stringArray1[i], stringArray2[i]) === 0
      } else {
        // this for i.E. true/false/undefined checks which shouldn't happen
        equal = stringArray1[i] === stringArray2[i]
      }

      i++
    }
  } else {
    equal = false
  }

  return equal
}
