const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')

const QuarantineMessage = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg) {
    cfg = Ext.applyIf(cfg || {}, {
      label: t('Quarantine the message'),
      type: 'smartrules-quarantine',
      nestable: true
    })
    this.callParent([cfg])
  },

  toDsl() {
    return 'quarantine'
  }
})

module.exports = QuarantineMessage
