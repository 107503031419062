const Ext = require('ext')
const t = require('translate')
const Format = require('admin/util/Format')
const Time = require('admin/time/Time')
const logger = require('system/logger').default
const Rule = require('smartrules/Rule')
const RuleRecord = require('smartrules/RuleRecord')
const Dmarc = require('mail/dmarc')

const escape = require('html-escaper').escape

function stapleHtml(v) {
  return '[' + escape(v) + ']'
}

const EventSummaryPanel = Ext.define(null, {
  extend: Ext.Panel,

  layout: 'form',
  layoutConfig: {
    labelAlign: 'left'
  },
  border: false,

  constructor(cfg) {
    this.record = cfg.record
    this.customer = cfg.customer
    this.timeZoneName = cfg.timeZoneName
    this.items = this.getItems()

    this.addClass('summary')

    this.callParent()
  },

  getItems() {
    let sizeString
    const size = this.record.get('size')

    if (size && size !== '') {
      const formattedBytes = Format.bytes(size)
      sizeString = `${size} ${t('bytes')} (${formattedBytes})`
    }

    let recipients

    if (this.record.get('recipients')) {
      recipients = this.record.get('recipients').map((recipient) => recipient.recipient)
    }

    let tags

    if (this.record.get('tags')) {
      tags = this.record.get('tags')
    }
    const items = [
      this.field(t('Message ID'), this.record.get('msgId')),
      this.field(t('SMX ID'), this.record.get('sid')),
      this.field(t('From'), this.record.get('sender')),
      this.field(t('To'), recipients?.join(', ')),
      this.field(t('Subject'), this.record.get('subject')),
      this.field(t('Size'), sizeString),
      this.field(t('From Host'), this.record.get('fromHost')),
      this.field(
        t('Received Date'),
        Time.toString(this.record.get('receivedDate'), this.timeZoneName, {
          includeSeconds: true
        })
      ),
      this.tagField(t('Tags'), tags),
      this.tlsField(
        t('Connection TLS'),
        this.record.get('connectionTlsCipher'),
        this.record.get('connectionTlsProtocol')
      ),

      this.details(this.record.get('details'))
    ].filter(Boolean)

    return items
  },

  field(label, value) {
    return new Ext.form.DisplayField({
      fieldLabel: label,
      value,
      htmlEncode: true
    })
  },
  tagField(label, tags) {
    if (Array.isArray(tags) && tags.length === 0) return
    const CertTags = tags.map((tag) => tag.name).join(', ')
    return this.field(label, CertTags)
  },
  tlsField(label, cipher, protocol) {
    // if theres a cipher, there should always be a protocol
    if (!cipher && !protocol) return

    const tlsDetails = `Protocol: ${protocol} Cipher: ${cipher}`
    return this.field(label, tlsDetails)
  },

  details(details) {
    if (!details) return

    return Object.keys(details).map((key) => {
      const value = details[key]
      switch (key) {
        case 'DMARC_DETAIL': {
          const dmarcPairs = Dmarc.pairs(value)
          return dmarcPairs.map(({ label, value }) => {
            return this.field(label, value)
          })
        }
        case 'SMARTRULES_RESULT':
          return this.smartRules(value)
        case 'LINK_ANALYSIS':
          return this.linkAnalysis(value)
        case 'QUARANTINE_DETAIL':
          return []
        default:
          // hide anything else unknown, see SCL-3097
          return undefined
      }
    })
  },

  linkAnalysis(details) {
    const title = 'URL Analysis'

    // example details:
    // [{"detail":{"warning":"forged HTTPS"},"detailType":"LINK_ANALYSIS"}]
    return details.map((detail) => {
      // fallback until https://youtrack.smxemail.com/issue/SSV-3470 is fixed
      const detailField = detail.detail || detail
      const reasons = Object.keys(detailField).reduce((reasons, key) => {
        reasons.push(detailField[key])
        return reasons
      }, [])

      return this.field(t(title), reasons.join(', '))
    })
  },

  // Renders a field for each rule so there can be a separate
  // click handler for each. Renders actions as a list to make
  // translation easier.
  smartRules(rules) {
    return rules.map((rule, i) => {
      let name
      const actions = [].concat(...rule.actions.map(this.smartRuleAction))

      // Custom SmartRules may not log an ID.
      if (rule.smartRuleId) {
        name = '<a href="#">' + t('Rule {id}', { id: rule.smartRuleId }) + '</a>'
      } else {
        name = t('Custom SmartRule')
      }

      const joinedActions = actions.map((a) => `- ${a}`).join('<br>')

      return new Ext.form.DisplayField({
        fieldLabel: i === 0 ? t('SmartRules') : null,
        value: `<div>${name}:<br>${joinedActions}</div>`,
        // Handle SmartRule click event.
        // This is a bad way to do it but URLs aren't really supported.
        listeners: {
          single: true,
          render: (c) => {
            if (!rule.smartRuleId) return

            c.el.on('click', (e) => {
              e.preventDefault()
              this.handleSmartRuleClick(rule)
            })
          }
        }
      })
    })
  },

  handleSmartRuleClick(rule) {
    const ruleRecord = new RuleRecord(null, {
      url: `${this.customer.get('products').SMART_RULES.resources.RULE_SETS.url}/${
        rule.smartRuleSetId
      }/rules/${rule.smartRuleId}`
    })

    return ruleRecord.fetch({
      success: () => Rule.open(ruleRecord, { customer: this.customer })
    })
  },

  smartRuleAction(a) {
    switch (a.type) {
      case 'recipientsChanged':
        return [
          a.removed &&
            t('removed recipients {recipients}', {
              recipients: stapleHtml(a.removed.join(', '))
            }),

          a.added &&
            t('added recipients {recipients}', {
              recipients: stapleHtml(a.added.join(', '))
            }),

          a.changed &&
            Object.keys(a.changed).map((originalRecipient) => {
              const newRecipient = a.changed[originalRecipient]
              return t('changed recipient from {originalRecipient} to {newRecipient}', {
                originalRecipient: stapleHtml(originalRecipient),
                newRecipient: stapleHtml(newRecipient)
              })
            })
        ].filter(Boolean)

      case 'senderChanged':
        // Headers/envelope not displayed.
        // Defined as a map: changed[originalSender] = newSender
        return Object.keys(a.changed).map((originalSender) => {
          const newSender = a.changed[originalSender]
          return t('replaced sender {originalSender} with {newSender}', {
            originalSender: stapleHtml(originalSender),
            newSender: stapleHtml(newSender)
          })
        })

      case 'messageRejected':
        return t('rejected message with reason {reason}', {
          reason: stapleHtml(a.reason)
        })

      case 'messageDropped':
        return t('dropped message')

      case 'messageWhitelisted':
        return t('whitelisted message')

      case 'notificationSent':
        return t(
          'sent notification to {recipients} from {senders} with subject {subject} and template {template}',
          {
            recipients: stapleHtml(a.recipients.join(', ')),
            senders: stapleHtml(a.senders),
            subject: stapleHtml(a.subject),
            template: stapleHtml(a.templateName)
          }
        )

      case 'preambleAdded':
        return t('added preamble {template}', {
          template: stapleHtml(a.templateName)
        })

      case 'footerAdded':
        return t('added footer {template}', {
          template: stapleHtml(a.templateName)
        })

      case 'attachmentsChanged':
        return t('removed attachments: {removed}', {
          removed: stapleHtml(
            a.removed
              .map((attachment) => {
                return `${attachment.name} (${attachment.mimeType})`
              })
              .join(', ')
          )
        })

      case 'ruleSetProcessingStopped':
        return t('stopped rule processing')

      case 'messageQuarantined':
        return t('quarantined')

      default:
        logger.warn(`Action [${a.type}] not supported.`)
        return `[${a.type}]`
    }
  }
})

module.exports = EventSummaryPanel
