const getIpSum = (ip) => {
  let parts = []

  if (ip?.split) {
    parts = ip.split('.')
  }

  return (
    parts.reduce(function (memo, octet) {
      const int = parseInt(octet)

      // this if check will skip undefined ones coming from invalid parameters in parseInt('a')
      if (int) {
        let ipl = memo << 8
        return (ipl += int)
      }

      return undefined
    }, 0) >>> 0
  )
}

module.exports = (s1, s2) => getIpSum(s1) - getIpSum(s2)
