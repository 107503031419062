import React from 'react'
import PropTypes from 'prop-types'

const MemberType = ({ title, counts }) => {
  return (
    <div className='memberType'>
      <h4>
        {title} <small>{counts}</small>
      </h4>
    </div>
  )
}

MemberType.propTypes = {
  title: PropTypes.string,
  counts: PropTypes.string
}

export default MemberType
