const Record = require('admin/core/Record')

const EventReleaseRecord = Record.define(null, {
  fields: ['customerId', 'savedRecipients', 'sid'],

  getParams() {
    return {
      customerId: this.get('customerId'),
      recipient: this.get('savedRecipients'),
      sid: this.get('sid')
    }
  }
})

module.exports = EventReleaseRecord
