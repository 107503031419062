import * as Time from 'admin/time/Time'
import deepmerge from 'deepmerge'
import * as hal from 'hal'
import {
  EVENT_DETAIL_REL,
  QUARANTINE_DOWNLOAD_REL,
  QUARANTINE_RELEASE_REL,
  QUARANTINE_SEARCH_REL
} from 'product/resources'
import createCompanySelector from 'system/selector/createCompanySelector'

import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('quarantine')

export const quarantineSearch = createSelector(
  company,
  (company) => company && company[QUARANTINE_SEARCH_REL]
)

export const uri = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.uri
)

export const query = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.query
)

export const timeZoneName = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.timeZoneName
)

export const startDate = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch && new Date(quarantineSearch.startDate)
)

export const fromDate = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch && new Date(quarantineSearch.fromDate)
)

export const thruDate = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch && new Date(quarantineSearch.thruDate)
)

export const confirmingRelease = createSelector(quarantineSearch, (quarantineSearch) =>
  Boolean(quarantineSearch?.confirmingRelease)
)

export const results = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.results
)

export const resultsQuery = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.resultsQuery
)

export const loading = createSelector(quarantineSearch, (quarantineSearch) =>
  Boolean(quarantineSearch?.loading)
)

export const scrollToIndex = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.scrollToIndex
)

export const highlight = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.highlight
)

export const direction = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.direction
)

// keep it public for unit tests
export const total = createSelector(results, (results) => {
  if (!results) return 0
  return results.length
})

const limit = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.limit
)

export const searchParameters = createSelector(
  query,
  fromDate,
  thruDate,
  limit,
  direction,
  timeZoneName,
  (query, fromDate, thruDate, limit, direction, timeZoneName) => {
    return {
      direction,
      status: 'QUARANTINED',
      query,
      fromDate:
        fromDate &&
        Time.rezone(fromDate, timeZoneName, {
          // changes timestamp leaving local time untouched
          keepLocalTime: true
        }).toMillis(),
      thruDate:
        thruDate &&
        Time.rezone(thruDate, timeZoneName, {
          // changes timestamp leaving local time untouched
          keepLocalTime: true
        })
          .plus({ days: 1 })
          .toMillis(),
      order: 'DESC',
      limit
    }
  }
)

// keep it public for unit tests
export const offset = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.offset
)

const page = createSelector(offset, limit, (offset, limit) => offset / limit)

export const rows = createSelector(
  total,
  limit,
  results,
  page,
  (total, limit, results, page) => {
    if (total <= limit) return results // no need to slice
    if (!results) return

    return results.slice(page * limit, (page + 1) * limit)
  }
)

export const columns = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.columns
)

/// ========= FOR VIEWING ========= ///

export const detail = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.detail
)

export const preview = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.preview
)

export const attachmentPanelExpanded = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.attachmentPanelExpanded
)

const detailUri = createSelector(
  detail,
  (detail) => detail?.uri // todo grab from self link when avail
)

export const viewing = createSelector(
  detailUri,
  (detailUri) => detailUri !== null && detailUri !== undefined
)

const viewingIndex = createSelector(
  viewing,
  results,
  detailUri,
  (viewing, results, detailUri) => {
    if (!viewing || !results || !detailUri) return

    return results.findIndex((r) => r && hal.href(r, EVENT_DETAIL_REL) === detailUri)
  }
)

export const currentResultNumber = createSelector(viewingIndex, (viewingIndex) =>
  viewingIndex >= 0 ? viewingIndex + 1 : null
)

const mail = createSelector(
  results,
  viewingIndex,
  (results, viewingIndex) => results && results[viewingIndex]
)

export const mailWithDetail = createSelector(mail, detail, (mail, detail) =>
  mail && detail ? deepmerge(mail, detail) : null
)

/// ========= MORE ON PAGINATION / SELECTIONS ========= ///

const rowsSize = createSelector(rows, (rows) => rows && rows.length)

export const showResults = createSelector(
  viewing,
  rowsSize,
  (viewing, rowsSize) => !viewing && rowsSize > 0
)

export const showNoResults = createSelector(
  viewing,
  loading,
  rows,
  rowsSize,
  (viewing, loading, rows, rowsSize) => !viewing && !loading && rows && rowsSize < 1
)

export const selectedCount = createSelector(rows, (rows) =>
  rows?.reduce((total, r) => (r && r.selected ? total + 1 : total), 0)
)

export const selectedRows = createSelector(
  mailWithDetail,
  rows,
  (mailWithDetail, rows) => {
    if (mailWithDetail) {
      return [mailWithDetail]
    }

    return rows?.filter((r) => r?.selected)
  }
)

export const selectedRow = createSelector(selectedRows, (selectedRows) => {
  return selectedRows && selectedRows[0]
})

export const releasable = createSelector(
  mailWithDetail,
  selectedRows,
  (mailWithDetail, selectedRows) => {
    if (mailWithDetail) {
      return Boolean(hal.href(mailWithDetail, QUARANTINE_RELEASE_REL))
    }

    return selectedRows?.some((r) => r && hal.href(r, QUARANTINE_RELEASE_REL))
  }
)

export const released = createSelector(
  mailWithDetail,
  selectedRows,
  (mailWithDetail, selectedRows) => {
    if (mailWithDetail) {
      return mailWithDetail.released
    }

    return selectedRows && selectedRows.some((r) => r.released)
  }
)

export const firstRowNumber = createSelector(
  results,
  rows,
  (results, rows) => results && rows && results.indexOf(rows[0]) + 1
)

export const lastRowNumber = createSelector(
  results,
  rows,
  (results, rows) => results && rows && results.indexOf(rows[rows.length - 1]) + 1
)

const prevIndex = createSelector(viewingIndex, (viewingIndex) => viewingIndex - 1)

export const hasPrev = createSelector(
  viewing,
  prevIndex,
  page,
  (viewing, prevIndex, page) => {
    if (viewing) return prevIndex >= 0

    return page > 0
  }
)

const nextIndex = createSelector(viewingIndex, (viewingIndex) => viewingIndex + 1)

export const hasNext = createSelector(
  total,
  viewing,
  nextIndex,
  results,
  page,
  limit,
  (total, viewing, nextIndex, results, page, limit) => {
    if (!results) return false
    if (!viewing && total <= limit) return false
    if (viewing) return results && !!(nextIndex in results)

    return results && !!((page + 1) * limit in results)
  }
)

export const nextOffset = createSelector(offset, limit, (offset, limit) => {
  return offset + limit
})

export const paginateNext = createSelector(
  viewing,
  nextIndex,
  nextOffset,
  (viewing, nextIndex, nextOffset) => {
    let paginateNext = true

    if (viewing) {
      paginateNext = nextIndex >= nextOffset
    }

    return paginateNext
  }
)

export const prefetchOffset = createSelector(
  paginateNext,
  offset,
  limit,
  (paginateNext, offset, limit) => {
    if (!paginateNext) return
    return offset + limit * 2
  }
)

export const prefetch = createSelector(
  paginateNext,
  prefetchOffset,
  total,
  limit,
  (paginateNext, prefetchOffset, total, limit) => {
    if (!paginateNext) return false

    // only prefetch when data in cache is not enough and when end hasn't been reached yet
    // TODO ask backend for a next link
    return prefetchOffset >= total && total % limit === 0
  }
)

export const nextRelativeIndex = createSelector(
  viewing,
  paginateNext,
  nextIndex,
  offset,
  (viewing, paginateNext, nextIndex, offset) => {
    if (!viewing) return
    return paginateNext ? 0 : nextIndex - offset
  }
)

/// ========= FOR NEXT AND PREV ACTIONs ========= ///

const nextMail = createSelector(
  results,
  nextIndex,
  (results, nextIndex) => results && results[nextIndex]
)

export const location = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.location
)

export const nextParams = createSelector(
  timeZoneName,
  viewing,
  nextMail,
  limit,
  location,
  nextOffset,
  paginateNext,
  prefetchOffset,
  prefetch,
  nextRelativeIndex,
  (
    timeZoneName,
    viewing,
    nextMail,
    limit,
    location,
    nextOffset,
    paginateNext,
    prefetchOffset,
    prefetch,
    nextRelativeIndex
  ) => {
    return {
      timeZoneName,
      viewing,
      nextMail,
      limit,
      location,
      nextOffset,
      paginateNext,
      prefetchOffset,
      prefetch,
      nextRelativeIndex
    }
  }
)

const prevMail = createSelector(
  results,
  prevIndex,
  (results, prevIndex) => results && results[prevIndex]
)

export const prevParams = createSelector(
  viewing,
  prevIndex,
  prevMail,
  offset,
  limit,
  (viewing, prevIndex, prevMail, offset, limit) => {
    return {
      viewing,
      prevIndex,
      prevMail,
      offset,
      limit
    }
  }
)

/// ========= TOOLBAR ACTIONs ========= ///

export const releaseSize = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.releaseSize
)

export const releaseSuccessful = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.releaseSuccessful
)

export const releaseSkipped = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.releaseSkipped
)

export const releaseFailed = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.releaseFailed
)

export const recipientsValid = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.recipientsValid
)

export const originalRecipients = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.originalRecipients
)

export const downloadUri = createSelector(selectedRow, (selectedRow) => {
  if (selectedRow) {
    return hal.href(selectedRow, QUARANTINE_DOWNLOAD_REL)
  }
})

export const downloadable = createSelector(
  loading,
  viewing,
  selectedCount,
  downloadUri,
  (loading, viewing, selectedCount, downloadUri) => {
    if (downloadUri && !loading) {
      return viewing || (!viewing && selectedCount === 1)
    }
  }
)

export const sourceAvailable = createSelector(
  viewing,
  loading,
  downloadUri,
  (viewing, loading, downloadUri) => viewing && !loading && Boolean(downloadUri)
)

export const source = createSelector(
  quarantineSearch,
  (quarantineSearch) => quarantineSearch?.source
)

const dateRangeShown = createSelector(quarantineSearch, (quarantineSearch) =>
  Boolean(quarantineSearch?.dateRangeShown)
)

export const hasModalDialog = createSelector(
  source,
  dateRangeShown,
  (source, dateRangeShown) => {
    return Boolean(!!source || dateRangeShown)
  }
)
