import * as hal from 'hal'

import {
  MAILBOXES_INIT_DB,
  MAILBOXES_SELECT_ROWS,
  MAILBOXES_SET_CUSTOMER_URL,
  MAILBOXES_SET_LINKS,
  MAILBOXES_SET_LIST,
  MAILBOXES_START_CONFIRMING_DELETION,
  MAILBOXES_START_CONFIRMING_PURGE,
  MAILBOXES_START_CREATING,
  MAILBOXES_START_EDITING,
  MAILBOXES_START_LOADING,
  MAILBOXES_START_UPGRADING,
  MAILBOXES_STOP_CONFIRMING_DELETION,
  MAILBOXES_STOP_CONFIRMING_PURGE,
  MAILBOXES_STOP_CREATING,
  MAILBOXES_STOP_EDITING,
  MAILBOXES_STOP_LOADING,
  MAILBOXES_STOP_UPGRADING,
  MAILBOXES_UNLOAD
} from 'mailboxes/actions'
import { MAILBOX_ALIAS_REL, MAILBOX_FORWARD_REL, MAILBOX_REL } from 'product/resources'

import { createNextState } from '@reduxjs/toolkit'
import getIn from 'lodash/get'
import initialState from 'mailboxes/db'
import setIn from 'lodash/set'

const getBaseKeyPath = (companyKey) => ['companies', companyKey, MAILBOX_REL]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getListKeyPath = (companyKey) => getKeyPath(companyKey, 'list')
const getList = (mailboxesDb, companyKey) =>
  getIn(mailboxesDb, getListKeyPath(companyKey))

const reducers = {
  [MAILBOXES_SELECT_ROWS]: (mailboxesDb, action) => {
    const { rows, companyKey, selected, force } = action

    const results = getIn(mailboxesDb, getListKeyPath(companyKey))

    return setIn(
      mailboxesDb,
      getListKeyPath(action.companyKey),
      results.map((result) => {
        const resultSelfLink = hal.self(result)

        const found = rows.find((row) => {
          const self = row && typeof row.valueOf() === 'string' ? row : hal.self(row)
          return self === resultSelfLink
        })

        if (force) {
          return Object.assign(result, { selected: found && selected })
        }

        if (!found) return result

        return Object.assign(result, { selected })
      })
    )
  },

  [MAILBOXES_SET_LIST]: (mailboxesDb, { newList, companyKey }) => {
    const oldList = getList(mailboxesDb, companyKey)
    const newMailboxesDb = setIn(mailboxesDb, getListKeyPath(companyKey), newList)

    // remember current selection
    const selectedMailboxes = oldList && oldList.filter((mailbox) => mailbox.selected)

    if (!selectedMailboxes || selectedMailboxes.length < 1) {
      return newMailboxesDb
    }

    const results = getIn(newMailboxesDb, getListKeyPath(companyKey))

    if (!results) return newMailboxesDb

    // restore selections
    return setIn(
      newMailboxesDb,
      getListKeyPath(companyKey),
      results.map((result) => {
        const resultSelfLink = hal.self(result)

        const found = selectedMailboxes.find((selectedMailbox) => {
          return hal.self(selectedMailbox) === resultSelfLink
        })

        if (found) {
          result.selected = true
        }

        return result
      })
    )
  },

  [MAILBOXES_INIT_DB]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getBaseKeyPath(action.companyKey), {})
  },

  [MAILBOXES_SET_CUSTOMER_URL]: (mailboxesDb, action) => {
    return setIn(
      mailboxesDb,
      getKeyPath(action.companyKey, 'customerUrl'),
      action.customerUrl
    )
  },

  [MAILBOXES_SET_LINKS]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'links'), action.links)
  },

  [MAILBOXES_START_LOADING]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [MAILBOXES_STOP_LOADING]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [MAILBOXES_UNLOAD]: (mailboxesDb, { companyKey }) => {
    delete mailboxesDb.companies[companyKey]
    return mailboxesDb
  },

  [MAILBOXES_START_CREATING]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'creating'), true)
  },

  [MAILBOXES_STOP_CREATING]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'creating'), false)
  },

  [MAILBOXES_START_EDITING]: (mailboxesDb, { companyKey, mailbox }) => {
    const alias = hal.resource(mailbox, MAILBOX_ALIAS_REL, 'address')
    const forward = hal.resource(mailbox, MAILBOX_FORWARD_REL, 'address')

    return setIn(mailboxesDb, getKeyPath(companyKey, 'editTarget'), {
      _links: mailbox._links,
      address: mailbox.address,
      firstName: mailbox.firstName,
      lastName: mailbox.lastName,
      hostingDescription: mailbox.hostingDescription,
      cosName: mailbox.cosName,
      // TODO have redux-form's format/parse do this
      alias: alias?.join('\n'),
      forward: forward?.join('\n'),
      forwardingStyle: mailbox.forwardingStyle
    })
  },

  [MAILBOXES_STOP_EDITING]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'editTarget'), null)
  },

  [MAILBOXES_START_UPGRADING]: (mailboxesDb, { companyKey, mailbox, catalogue }) => {
    return setIn(mailboxesDb, getKeyPath(companyKey, 'upgradeTarget'), {
      _links: mailbox._links,
      catalogue,
      address: mailbox.address
    })
  },

  [MAILBOXES_STOP_UPGRADING]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'upgradeTarget'), null)
  },

  [MAILBOXES_START_CONFIRMING_DELETION]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'confirmingDeletion'), true)
  },

  [MAILBOXES_STOP_CONFIRMING_DELETION]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'confirmingDeletion'), false)
  },

  [MAILBOXES_START_CONFIRMING_PURGE]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'confirmingPurge'), true)
  },

  [MAILBOXES_STOP_CONFIRMING_PURGE]: (mailboxesDb, action) => {
    return setIn(mailboxesDb, getKeyPath(action.companyKey, 'confirmingPurge'), false)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
