import Api from 'api'
import logger from 'system/logger'

export const SYSTEM_CAPABILITY_SET_LIST = 'SYSTEM_CAPABILITIES_SET_LIST'

const setList = (list) => ({
  type: SYSTEM_CAPABILITY_SET_LIST,
  list
})

export const init = () => async (dispatch) => {
  let success = false

  try {
    // TODO have backend return the URL for capability resource instead
    // of manually building that URL here.
    const response = await Api.get('api/system/capabilities')

    // TODO optimize, flatten down states we want to store. Not necessary
    // to keep the whole response in memory.
    dispatch(setList(response.data))

    success = true
  } catch (err) {
    logger.error(err)
  }

  // prevents endless loop in App.coffee in case of an error
  return success
}
