const Ext = require('ext')
const t = require('translate')
const TabPanel = require('admin/view/TabPanel').default
const DomainSummaryGridPanel = require('admin/component/grid/DomainSummaryGridPanel')
const MailStatsPanel = require('admin/view/MailStatsPanel')
const MailboxTab = require('admin/view/MailboxTab').default
const HostingDomainListPanel = require('admin/view/HostingDomainListPanel')
const SignatureManagementPanel = require('admin/view/SignatureManagementPanel')

const store = require('store').default
const { isCapableOfMailboxes } = require('system/capability/subs')
const initHosting = require('mailhosting/actions').init
const unloadHosting = require('mailhosting/actions').unload

// todo rewrite this class, too many wired events and ugly implementation
// very fragile, sometimes doesn't appear when there are race conditions
// when react container panels are involved
// temporary fix is to set the first tab as active manually (since scl-2120)

const MailHostingTabPanel = Ext.define(null, {
  extend: TabPanel,

  title: t('Mail Hosting'),
  tabCls: 'mail-hosting-tab',
  cls: 'mail-hosting-panel',

  constructor(cfg = {}) {
    this.customer = cfg.customer
    this.product = cfg.product
    if (!this.product.current) {
      this.tabCls = this.tabCls + ' expired-group-tab'
    }
    this.items = []
    this.hostingDomainStore = this.customer.getHostingDomainStore()

    if (isCapableOfMailboxes(store.getState())) {
      // synchronous db initialisations here, set uri for company key so that
      // we dont have to painfully pass uri along to any react component
      store.dispatch(initHosting(cfg.customer.getCompanyKey(), cfg.customer.getUrl()))

      this.hostingDomainStore.on('load', this.checkIsMailboxCapable, this)
      this.hostingDomainStore.load()
    } else {
      this.items = this.getItems(false)
    }

    // see http://youtrack.smxemail.com/issue/SCL-958
    // TODO improve this by porting policies to react and redux
    this.hostingDomainStore.on('add', () =>
      this.policyManagementList.refreshHostingDomains()
    )
    this.hostingDomainStore.on('remove', () =>
      this.policyManagementList.refreshHostingDomains()
    )
    this.hostingDomainStore.on('change', () =>
      this.policyManagementList.refreshHostingDomains()
    )

    this.callParent()
  },

  checkIsMailboxCapable() {
    if (this.isDestroyed) {
      return
    } // race condition, see SCL-3356

    const isMailboxCapable =
      this.hostingDomainStore.collect('isMailboxCapable').indexOf(true) >= 0
    if (this.items.length === 0) {
      if (isMailboxCapable) {
        this.hostingDomainStore.un('load', this.checkIsMailboxCapable, this)
      }
      this.removeAll()
      this.add(this.getItems(isMailboxCapable))
      this.setActiveTab(0)
    } else if (
      this.items.length === 6 &&
      this.hostingDomainStore.getCount() > 0 &&
      isMailboxCapable
    ) {
      this.hostingDomainStore.un('load', this.checkIsMailboxCapable, this)
      this.insert(2, new MailboxTab({ customer: this.customer }))
    }
  },

  getItems(hasMailboxes) {
    let items = [
      new DomainSummaryGridPanel({ customer: this.customer, product: this.product }),
      new HostingDomainListPanel({
        customer: this.customer,
        product: this.product,
        store: this.customer.getHostingDomainStore()
      })
    ]

    if (hasMailboxes) {
      items.push(new MailboxTab({ customer: this.customer }))
    }

    const PolicyManagementListPanel = require('admin/view/PolicyManagementListPanel')
    this.policyManagementList = new PolicyManagementListPanel({
      customer: this.customer,
      product: this.product
    })

    const EventSearchPanel = require('admin/view/EventSearchPanel')

    items = items.concat([
      this.policyManagementList,
      new SignatureManagementPanel({ customer: this.customer, product: this.product }),
      new EventSearchPanel({ customer: this.customer, product: this.product }),
      new MailStatsPanel({ customer: this.customer, product: this.product })
    ])

    return items
  },

  destroy() {
    this.superclass().destroy.call(this)

    if (isCapableOfMailboxes(store.getState())) {
      // cleanup only at the very end when all else has been destroyed
      return store.dispatch(unloadHosting(this.customer.getCompanyKey()))
    }
  }
})

module.exports = MailHostingTabPanel
