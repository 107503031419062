const Ext = require('ext')
const t = require('translate')
const Alert = require('admin/util/Alert').default
const logger = require('system/logger').default

const Reorderable = Ext.define(null, {
  extend: Ext.util.Observable,

  constructor(cfg = {}) {
    this.addEvents({
      beforerowmove: true,
      afterrowmove: true
    })
    Ext.apply(this, cfg)
    this.callParent([cfg])
  },

  init(grid) {
    this.grid = grid
    this.grid.enableDragDrop = true

    if (!this.grid.ddGroup) {
      this.grid.ddGroup =
        (this.ddGroup != null ? this.ddGroup : 'ddGroup-') + this.grid.id
    }

    this.grid.addClass('smx-grid-reorderable')
    const columns = this.grid.colModel.config
    // Creates cells with x-grid3-col-reorderable as a class from the id
    columns.splice(
      columns.length,
      0,
      new Ext.grid.TemplateColumn({
        handler: Ext.emptyFn,
        id: 'reorderable',
        menuDisabled: true,
        sortable: false,
        tpl: '<div class="smx-draggable"></div>',
        width: 24
      })
    )
    this.grid.on('render', this.onGridRender, this, { single: true })
  },

  onGridRender(grid) {
    // Restrict to drag handle
    this.grid.view.dragZone.isValidHandleChild = (node) =>
      Ext.fly(node).hasClass('x-grid3-col-reorderable')

    return (this.target = new Ext.dd.DropZone(grid.getEl(), {
      ddGroup: grid.ddGroup || this.ddGroup,
      grid,
      gridDropTarget: this,

      reorder(data, rowIndex) {
        const store = this.grid?.getStore()
        const selection = store?.getById(data.selections[0].id)
        const link = selection?.getUriByRel('reorder')

        const maskGrid = () =>
          this.grid.ownerCt.getEl().mask(Ext.LoadMask.prototype.msg, 'x-mask-loading')

        const unmaskGrid = () => {
          const owner = this.grid?.ownerCt
          owner?.getEl().unmask()
        }

        if (link) {
          maskGrid()

          const ids = store
            .getRange()
            .map((record) => record.get('id'))
            .filter((id) => id !== selection.id)

          ids.splice(rowIndex, 0, selection.id)

          store.clearFilter(true) // filter may be "hiding" some ids
          store.getRange().forEach(function (record) {
            const id = record.get('id')
            if (ids.indexOf(id) < 0) {
              return ids.push(id)
            }
          })

          Ext.Ajax.request({
            url: link,
            method: 'PUT',
            mask: maskGrid.bind(this),
            unmask: unmaskGrid.bind(this),
            params: {
              order: Ext.util.JSON.encode(ids)
            },
            success() {
              unmaskGrid()
              store?.reload()
            },
            failure(response) {
              unmaskGrid()
              store?.reload()

              Alert.displayResponseError(response)
              Alert.alert(t('Error'), t('Reordering failed. Please try again.'))
            }
          })

          this.gridDropTarget.fireEvent(
            'afterrowmove',
            this.gridDropTarget,
            data.rowIndex,
            rowIndex,
            data.selections
          )
        }
      },

      notifyDrop(dd, e, data) {
        this.clearDragLines()

        const target = e.getTarget()
        let rowIndex = this.grid.getView().findRowIndex(target)

        if (rowIndex === false || rowIndex === data.rowIndex) {
          return false
        }

        if (rowIndex > data.rowIndex && this.rowPosition < 0) {
          rowIndex--
        } else if (rowIndex < data.rowIndex && this.rowPosition > 0) {
          rowIndex++
        }

        if (rowIndex === data.rowIndex) {
          return false
        }

        if (
          this.gridDropTarget.fireEvent(
            'beforerowmove',
            this.gridDropTarget,
            data.rowIndex,
            rowIndex,
            data.selections
          ) === false
        ) {
          return false
        }
        this.reorder(data, rowIndex)
        return true
      },

      notifyOver(dd, e) {
        const target = e.getTarget()
        let rowIndex = this.grid.getView().findRowIndex(target)
        if (rowIndex < 0 || rowIndex === false) {
          this.clearDragLines()
          return this.dropNotAllowed
        }
        try {
          const currentRow = this.grid.getView().getRow(rowIndex)
          // Find position of row relative to page
          // (adjusting for grid's scroll position)
          const resolvedRow =
            Ext.fly(currentRow).getY() - this.grid.getView().scroller.dom.scrollTop

          const rowHeight = currentRow.offsetHeight
          this.rowPosition = e.getPageY() - resolvedRow - rowHeight / 2
          this.clearDragLines()
          this.grid.addClass('smx-grid-dragging')
          this.currentRowEl = new Ext.Element(currentRow)
          if (this.rowPosition > 0) {
            // Pointer on bottom half of row
            this.currentRowEl.addClass('grid-row-insert-below')
          } else {
            // Pointer in top half of row
            if (rowIndex - 1 >= 0) {
              const previousRow = this.grid.getView().getRow(rowIndex - 1)
              this.currentRowEl = new Ext.Element(previousRow)
              this.currentRowEl.addClass('grid-row-insert-below')
            } else {
              this.currentRowEl.addClass('grid-row-insert-above')
            }
          }
        } catch (err) {
          logger.warn(err)
          rowIndex = false
        }
        if (rowIndex === false) {
          return this.dropNotAllowed
        }
        return this.dropAllowed
      },

      notifyOut() {
        // Remove drag lines when it leaves the gridview
        this.clearDragLines()
      },

      clearDragLines() {
        this.grid.removeClass('smx-grid-dragging')
        if (this.currentRowEl) {
          this.currentRowEl.removeClass('grid-row-insert-above')
          this.currentRowEl.removeClass('grid-row-insert-below')
        }
      }
    }))
  }
})

module.exports = Reorderable
