import React, { Component } from 'react'

import Format from 'admin/util/Format'
import Field from 'mail/details/views/Field'
import MailHighlight from 'mail/details/views/Highlight'
import PropTypes from 'prop-types'
import t from 'translate'

class Panel extends Component {
  static propTypes = {
    result: PropTypes.object,
    resultsQuery: PropTypes.string,
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    enableHighlighting: PropTypes.bool
  }

  recipients() {
    const recipients = this.props.result.recipients
    return recipients && recipients
  }

  // only shown when a released flag exists (quarantine only, not archiving)
  renderReleasedStatus() {
    return (
      <Field label='Released: ' className='released'>
        {this.props.result.released === true ? t('Yes') : t('No')}
      </Field>
    )
  }

  render() {
    return (
      <div className='mail-summary'>
        <Field label='Sender: ' className='sender'>
          <MailHighlight
            keyword='sender'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
            placeholder={t('[null sender]')}
          >
            {this.props.result.sender}
          </MailHighlight>
        </Field>

        <Field label='Recipients: ' className='recipients'>
          <MailHighlight
            keyword='recipient'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
          >
            {this.recipients()}
          </MailHighlight>
        </Field>

        <Field label='Subject: ' className='subject'>
          <MailHighlight
            keyword='subject'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
          >
            {this.props.result.subject}
          </MailHighlight>
        </Field>

        <Field label='Received: ' className='received'>
          <MailHighlight enabled={this.props.enableHighlighting}>
            {this.props.result.received}
          </MailHighlight>
        </Field>

        <Field label='Size: ' className='size'>
          <MailHighlight placeholder={0} enabled={this.props.enableHighlighting}>
            {Format.bytes(this.props.result.size)}
          </MailHighlight>
        </Field>

        {'released' in this.props.result && this.renderReleasedStatus()}

        <Field label='SMX ID: ' className='smx-message-id'>
          <MailHighlight
            keyword='sid'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
          >
            {this.props.result.sid}
          </MailHighlight>
        </Field>

        <Field label='Message ID: ' className='message-id'>
          <MailHighlight
            keyword='messageId'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
          >
            {this.props.result.messageId}
          </MailHighlight>
        </Field>

        <Field label='Sender MTA IP: ' className='sender-mta-ip'>
          <MailHighlight
            keyword='senderMtaIp'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
            placeholder={<span className='text-muted'>None</span>}
          >
            {this.props.result.senderMtaIp}
          </MailHighlight>
        </Field>
      </div>
    )
  }
}

export default Panel
