const Ext = require('ext')
const Component = require('smartrules/designer/Component')
const Icon = require('smartrules/designer/Icon')
const DragUtils = require('smartrules/designer/DragUtils').default
const getComponent = require('smartrules/designer/util/getComponent')
const Clause = require('smartrules/designer/Clause')
const Group = require('smartrules/designer/Group')
const DropZone = require('smartrules/designer/DropZone')

// A clause tree represents a collection of nestable clause-tree members (including either clauses
// or groups of clauses) as well as metadata such as available clause types in a toolbar with
// drag-n-drop interactions.
const ClauseTree = Ext.define(null, {
  extend: Component,

  constructor(cfg = {}) {
    const items = []
    cfg = Ext.applyIf(cfg, {
      border: false,
      cls: 'clauses',
      clauseCssCls: 'clause',
      items,
      layout: 'auto',
      disabled: cfg.readOnly,
      toolbarItems: [],
      listeners: {
        render: this.activateDragDrop
      }
    })

    this.root = cfg.root

    items.push(
      cfg.toolbarItems.concat([
        {
          items: this.root,
          minHeight: 40,
          region: 'center',
          cls: 'root-clause-tree'
        }
      ])
    )

    this.callParent([cfg])
    this.on('smartrules-drop', this.onDrop)
  },

  onDrop() {},

  activateDragDrop(v) {
    const icons = v.findBy((n) => n instanceof Icon)

    for (const icon of Array.from(icons)) {
      icon.on('render', DragUtils.setupHighlightingDragTracker)
    }

    v.dragZone = new Ext.dd.DragZone(v.getEl(), {
      ddGroup: v.getId() + '-' + this.cls,
      getDragData(e) {
        let sourceCmp
        const extantEl = e.getTarget('.smartrules-draggable-button-icon', 3)
        const toolbarEl = e.getTarget(
          '.smartrules-icon:not(.smartrules-icon-disabled)',
          5
        )

        if (extantEl) {
          sourceCmp = Ext.getCmp(Ext.get(extantEl.id).up('.smartrules-panel-button').id)
          const sourceClause = sourceCmp.findParentBy(
            (c) => c instanceof Clause || c instanceof Group
          )

          return (v.dragData = {
            sourceEl: sourceClause.getEl().dom,
            repairXY: sourceClause.getEl().getXY(),
            ddel: DragUtils.makeClauseTooltipEl(v.cls, sourceClause.type),
            sourceClause,
            isNew: false
          })
        } else if (toolbarEl) {
          sourceCmp = Ext.getCmp(toolbarEl.id)
          if (!sourceCmp) {
            return false
          }

          return (v.dragData = {
            toolbarEl,
            repairXY: Ext.fly(toolbarEl).getXY(),
            ddel: DragUtils.makeClauseTooltipEl(v.cls, sourceCmp.type, sourceCmp.label),
            sourceClauseType: Ext.getCmp(toolbarEl.id).type,
            isNew: true
          })
        }
      },

      getRepairXY() {
        return this.dragData.repairXY
      }
    })

    return (v.dropZone = new Ext.dd.DropZone(v.getEl(), {
      ddGroup: v.getId() + '-' + v.cls,

      getTargetFromEvent(e) {
        return (
          e.getTarget('.smartrules-target-zone', 3) ||
          e.getTarget('.' + v.clauseCssCls, 21) ||
          e.getTarget('.smartrules-group', 21)
        )
      },

      onNodeOut(target) {
        return Ext.fly(target).removeClass([
          'smartrules-target-hover',
          'x-dd-drop-above',
          'x-dd-drop-below'
        ])
      },

      onNodeOver(target, dd, e, data) {
        const targetCmp = Ext.getCmp(target.id)
        const targetEl = targetCmp.getEl()
        const targetHeight = targetEl.getHeight()

        if (targetCmp instanceof Clause && targetCmp.inHeader) {
          return this.onNodeOver(targetCmp.getParentGroup(), dd, e, data)
        }

        const targetParentGroup = targetCmp.findParentBy((n) => n instanceof Group)

        const isTargetParentForData = () =>
          targetCmp.findParentBy((n) => n.id === data.sourceClause.id)

        const isDropNotAllowed = () =>
          !data.isNew &&
          (targetCmp === data.sourceClause ||
            targetParentGroup === data.sourceClause ||
            isTargetParentForData())

        const getHighlighters = () =>
          v.findBy((n) => {
            const el = n.getEl()
            if (!el) return false

            return el.hasClass('x-dd-drop-above') || el.hasClass('x-dd-drop-below')
          })

        if (isDropNotAllowed()) {
          // Cannot drag a component to itself, nor drag a group to one of its
          // descendants
          return Ext.dd.DropZone.prototype.dropNotAllowed
        }
        // Flush all drop highlight styles within this clause tree
        const highlights = getHighlighters()

        for (const highlight of Array.from(highlights)) {
          const el = highlight.getEl()
          el?.removeClass('x-dd-drop-above')
          el?.removeClass('x-dd-drop-below')
        }

        const clauseParentGroup =
          data.sourceClause != null
            ? data.sourceClause.findParentBy((n) => n instanceof Group)
            : undefined
        const targetIndex =
          targetParentGroup != null
            ? targetParentGroup.locateClauseTreeMember(targetCmp)
            : undefined
        const clauseIndex =
          clauseParentGroup != null
            ? clauseParentGroup.locateClauseTreeMember(data.sourceClause)
            : undefined

        // Ensure we are targeting an extant component of the right type
        if (
          targetEl.hasClass(v.clauseCssCls) ||
          (targetEl.hasClass('smartrules-group') && targetParentGroup)
        ) {
          // If we are dropping on the top half, it's above, otherwise below
          const targetDirection =
            e.getPageY() - targetEl.getY() < targetHeight / 2 ? 'above' : 'below'

          if (
            clauseParentGroup === targetParentGroup &&
            clauseIndex != null &&
            clauseIndex - 1 === targetIndex &&
            targetDirection === 'below'
          ) {
            // We are dragging a clause to an adjacent location within the same group,
            // by targeting the lower half of the upper neighbour
            return Ext.dd.DropZone.prototype.dropNotAllowed
          } else if (
            clauseParentGroup === targetParentGroup &&
            clauseIndex != null &&
            clauseIndex + 1 === targetIndex &&
            targetDirection === 'above'
          ) {
            // We are dragging a clause to an adjacent location within the same group,
            // by targeting the upper half of the lower neighbour
            return Ext.dd.DropZone.prototype.dropNotAllowed
          }
          // We are dragging a clause to a non-adjacent location, which may even be in a different group
          targetEl.addClass(String.format('x-dd-drop-{0}', targetDirection))
        } else if (targetEl.hasClass('smartrules-target-zone')) {
          // We are targeting an empty drop zone
          targetEl.addClass('smartrules-target-hover')
        }

        return Ext.dd.DropZone.prototype.dropAllowed
      },

      drop(targetParentGroup, data, index, after) {
        // leave require call here because of circular dependency
        const SmartRule = require('smartrules/designer/SmartRule')
        const smartRule = targetParentGroup.findParentBy((n) => n instanceof SmartRule)

        const Group = require('smartrules/designer/Group')

        if (data.isNew) {
          // TODO: Remove dynamic component creation.
          const ClauseComponent = getComponent(data.sourceClauseType)
          const clause = new ClauseComponent({
            listStores: smartRule.listStores,
            templateStore: smartRule.templateStore
          })
          return targetParentGroup.addClause(clause, after ? index + 1 : index)
        } else if (
          targetParentGroup !== data.sourceClause &&
          (data.sourceClause instanceof Clause || data.sourceClause instanceof Group) &&
          !targetParentGroup.findParentBy((n) => n.id === data.sourceClause.id)
        ) {
          return targetParentGroup.move(data.sourceClause, index, after)
        }
      },

      onNodeDrop(target, dd, e, data) {
        const targetCmp = Ext.getCmp(target.id)
        const targetEl = targetCmp.getEl()

        const groupAboveEl = targetEl.findParent('.x-dd-drop-above', 32, true)

        groupAboveEl?.removeClass('x-dd-drop-above')

        const groupBelowEl = targetEl.findParent('.x-dd-drop-below', 32, true)

        groupBelowEl?.removeClass('x-dd-drop-below')

        if (targetCmp.inHeader) {
          return this.onNodeDrop(targetCmp.getParentGroup(), dd, e, data)
        }

        if (targetCmp === data.sourceClause) {
          return false
        }

        const targetHeight = targetEl.getHeight()
        const targetParentGroup =
          typeof targetCmp.getParentGroup === 'function'
            ? targetCmp.getParentGroup()
            : undefined

        if (!targetParentGroup) {
          return false
        }

        if (targetCmp instanceof DropZone) {
          this.drop(targetParentGroup, data)
          return true
        }
        let clauseIndex
        if (data.sourceClause) {
          clauseIndex = targetParentGroup.locateClauseTreeMember(data.sourceClause)
        }
        const targetIndex = targetParentGroup.locateClauseTreeMember(targetCmp)

        const after = e.getPageY() - targetEl.getY() >= targetHeight / 2

        if (
          clauseIndex != null &&
          clauseIndex >= 0 &&
          clauseIndex - 1 === targetIndex &&
          after
        ) {
          return false
        } else if (
          clauseIndex != null &&
          clauseIndex >= 0 &&
          clauseIndex + 1 === targetIndex &&
          !after
        ) {
          return false
        }
        this.drop(targetParentGroup, data, targetIndex, after)
        return true
      }
    }))
  },

  toDsl() {
    return this.root.toDsl()
  },

  isValid() {
    return this.root.isValid()
  },

  clear() {
    return this.root.clear()
  },

  reflow() {
    return this.root.reflow()
  },

  addClause(clause, index) {
    this.root.addClause(clause, index)
    return clause
  }
})

module.exports = ClauseTree
