const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const CurrencyAndPriceField = require('admin/component/form/CurrencyAndPriceField')
const DateRangeFieldSet = require('admin/component/form/DateRangeFieldSet')
const Tooltip = require('admin/util/Tooltip')
const config = require('theming/config')

const ResellerAgreementNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'reselleragreement-new-panel',

  module() {
    return require('admin/business/ResellerAgreementResource')
  },

  saveText: t('Add Product'),

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems(cfg) {
    let percentageField
    const billTo = new Ext.form.Hidden({
      name: 'billTo',
      value: ''
    })

    const items = [billTo]

    const catalogueStore = cfg.record.getCatalogueStore()
    catalogueStore.on('load', (store) =>
      store.filter('wholesale', cfg.type === 'WHOLESALE')
    )
    const productCombo = new ComboBox({
      fieldLabel: t('Product Name'),
      name: 'productName',
      hiddenName: 'product',
      valueField: 'code',
      displayField: 'name',
      store: catalogueStore,
      editable: false,
      allowBlank: false,
      triggerAction: 'all'
    })

    if (cfg.type === 'WHOLESALE') {
      items.push({
        name: 'agreementTypeName',
        value: t('Wholesale Product'),
        xtype: 'hidden'
      })

      items.push({
        name: 'agreementType',
        value: 'WHOLESALE_RESELLER',
        xtype: 'hidden'
      })

      items.push(productCombo)

      percentageField = new Ext.form.NumberField({
        fieldLabel: t('Discount %'),
        name: 'commission.percentage',
        xtype: 'numberfield',
        allowNegative: false,
        allowBlank: false,
        maxValue: 100,
        listeners: {
          afteraddvalidator: {
            fn: this.module.discountValidator,
            scope: this.module
          }
        }
      })

      const currencyAndPrice = new CurrencyAndPriceField({
        fieldLabel: t('Fixed Buy Price'),
        currencyComboName: 'fixedBuyPrice.priceCurrencyUom',
        priceAmountFieldName: 'fixedBuyPrice.priceAmount',
        disabled: true,
        priceAmountFieldListeners: {
          afteraddvalidator: {
            fn: this.module.fixedPriceValidator
          }
        }
      })

      const priceType = new Ext.form.RadioGroup({
        name: 'pricingModel',
        layout: 'column',
        fieldLabel: t('Pricing Model'),
        itemCls: 'x-check-group-alt',
        columns: 2,
        items: [
          {
            boxLabel: t('Discount %'),
            xtype: 'radio',
            width: 150,
            name: 'rb-auto',
            // those margins ensure that the checkbox is seen, not cut
            style: 'margin: 0 0 0 1px;',
            inputValue: '1',
            checked: true,
            listeners: {
              check: {
                fn(cbox, checked) {
                  if (checked) {
                    percentageField.allowBlank = false
                    percentageField.enable()
                    return Tooltip.appendRequiredFieldMarker(percentageField)
                  }
                  percentageField.clearInvalid()
                  percentageField.allowBlank = true
                  percentageField.disable()
                  return Tooltip.removeRequiredFieldMarker(percentageField)
                },
                scope: this
              }
            }
          },
          {
            boxLabel: t('Fixed'),
            xtype: 'radio',
            width: 150,
            name: 'rb-auto',
            // those margins ensure that the checkbox is seen, not cut
            style: 'margin: 0 0 0 1px;',
            inputValue: '2',
            listeners: {
              check: {
                fn(cbox, checked) {
                  if (checked) {
                    currencyAndPrice.setAllowBlank(false)
                    currencyAndPrice.enable()
                    return Tooltip.appendRequiredFieldMarker(
                      currencyAndPrice.priceAmountField
                    )
                  }
                  currencyAndPrice.setAllowBlank(true)
                  currencyAndPrice.clearInvalid()
                  currencyAndPrice.disable()
                  return Tooltip.removeRequiredFieldMarker(
                    currencyAndPrice.priceAmountField
                  )
                },
                scope: this
              }
            }
          }
        ]
      })
      items.push(priceType)
      items.push(percentageField)
      items.push(currencyAndPrice)

      productCombo.on('select', function (combo, record) {
        if (record.get('billToId') !== cfg.record.get('id')) {
          const priceStore = record.getPriceStore()
          const prices = priceStore.getRange()

          return prices.forEach(function (price) {
            const type = price.get('type')

            if (type === 'COMMISSION') {
              priceType.items.get(0).setValue(true)
              priceType.items.get(1).setValue(false)
              priceType.disable()
              percentageField.setValue(price.get('percentage'))
              currencyAndPrice.currencyCombo.disable()
              currencyAndPrice.priceAmountField.reset()
              return currencyAndPrice.priceAmountField.disable()
            } else if (type === 'FIXED_BUY_PRICE') {
              priceType.items.get(0).setValue(false)
              priceType.items.get(1).setValue(true)
              priceType.disable()
              percentageField.reset()
              percentageField.disable()
              currencyAndPrice.currencyCombo.setValue(price.get('uom'))
              return currencyAndPrice.priceAmountField.setValue(price.get('priceAmount'))
            }
          })
        }
      })
    } else {
      const resellerTypeCombo = new ComboBox({
        fieldLabel: t('Reseller Type'),
        hiddenName: 'agreementType',
        store: [
          ['SILVER_RESELLER', t('Silver Reseller')],
          ['GOLD_RESELLER', t('Gold Reseller')]
        ],
        editable: false,
        allowBlank: false,
        triggerAction: 'all'
      })

      percentageField = new Ext.form.NumberField({
        fieldLabel: t('Commision %'),
        name: 'commission.percentage',
        xtype: 'numberfield',
        allowBlank: false,
        allowNegative: false,
        maxValue: 100,
        minValue: 0,
        listeners: {
          afteraddvalidator: {
            fn: this.module.discountValidator
          }
        }
      })

      resellerTypeCombo.on('select', function (combo, record) {
        if (record.get('value') === 'SILVER_RESELLER') {
          return percentageField.setValue(15)
        } else if (record.get('value') === 'GOLD_RESELLER') {
          return percentageField.setValue(25)
        }
      })

      items.push(resellerTypeCombo)
      items.push(productCombo)
      items.push(percentageField)
    }

    items.push(
      new DateRangeFieldSet({
        minValue: new Date(),
        from: {
          name: 'fromDate',
          value: new Date(),
          allowBlank: false
        },
        thru: {
          name: 'thruDate',
          allowBlank: true
        },
        both: {
          fieldWidth: config.panel.fieldWidth
        }
      })
    )

    return items.filter(Boolean)
  }
})

module.exports = ResellerAgreementNewPanel
