const _s = require('underscore.string')
const Ext = require('ext')
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const SmartRule = require('smartrules/designer/SmartRule')
const isObject = require('is-plain-obj')

// removes any slashes at the beginning and end of regex string.
// it also works when `/i` is used at the end instead of `/`
const trimSlashes = function (value = '') {
  if (isObject(value)) {
    if ('regex' in value) value = value.regex
    else if ('text' in value) value = value.text
    else if ('value' in value) value = value.value
  }

  return value.replace(/(^\/|\/i?$)/g, '')
}

const ignoreCase = (value) =>
  (value != null ? value.ignoreCase : undefined) || _s.endsWith(value, '/i')

const getSlashItemConfig = () => ({
  border: false,
  cls: 'smartrules-slash',
  flex: 0,
  html: '/',
  width: 10
})

const RegexpMatcher = Ext.define(null, {
  extend: Matcher,

  isRegex: true,

  constructor(cfg = {}) {
    const items = []
    const emptyText = cfg.emptyText || t('Enter regex')
    this.alwaysIgnoreCasing = cfg.alwaysIgnoreCasing || false
    this.escape = cfg.escape != null ? cfg.escape : true

    this.textfield = new Ext.form.TextField({
      allowBlank: false,
      flex: 1,
      name: 'value',
      ref: 'textfield',
      emptyText,
      value: trimSlashes(cfg.value),
      validator: function (value) {
        // Check for a trailing backslash
        if (/(\\)$/.test(value)) {
          return 'The regex should not end with a backslash (\\). Please remove the trailing backslash.'
        }
        try {
          // Attempt to compile the regex
          new RegExp(value)
        } catch (e) {
          // If it's not a valid regex, notify the user
          return 'Invalid regex: ' + e.message
        }
        return true
      }
    })

    this.textfield.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )

    items.push(getSlashItemConfig())
    items.push(this.textfield)
    items.push(getSlashItemConfig())

    if (!this.alwaysIgnoreCasing) {
      this.checkbox = new Ext.form.Checkbox({
        boxLabel: t('Ignore case'),
        checked: ignoreCase(cfg.value),
        flex: 1,
        height: 22,
        margins: '2',
        name: 'ignoreCase',
        ref: 'checkbox'
      })

      this.checkbox.on(
        'check',
        function () {
          const parent = this.findParentByType(SmartRule)
          parent?.fireEvent('dirty')
        },
        this
      )

      items.push(this.checkbox)
    }

    cfg = Ext.applyIf(cfg, {
      cls: 'regexp-matcher',
      items,
      layout: 'hbox'
    })

    this.callParent([cfg])
  },

  setValue(value) {
    this.textfield.setValue(trimSlashes(value))
    this.checkbox?.setValue(ignoreCase(value))
  },

  ignoreCase() {
    if (this.checkbox) {
      return this.checkbox.checked
    }

    return this.alwaysIgnoreCasing
  },

  getValue() {
    let v = this.textfield.getValue()

    if (v && this.escape) {
      v = v.replace(/(["\\/])/g, '\\$1')
    }

    return `/${v}/${this.ignoreCase() ? 'i' : ''}`
  }
})

module.exports = RegexpMatcher
