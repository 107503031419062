const Ext = require('ext')

const PriceField = Ext.define(null, {
  extend: Ext.form.NumberField,

  xtype: 'pricefield',

  setValue(v) {
    v =
      typeof v === 'number'
        ? v
        : parseFloat(String(v).replace(this.decimalSeparator, '.'))

    v = isNaN(v) ? '0.00' : String(v).replace('.', this.decimalSeparator)

    return Ext.form.NumberField.superclass.setValue.call(
      this,
      parseFloat(v).toFixed(this.decimalPrecision)
    )
  }
})

module.exports = PriceField
