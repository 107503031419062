import * as hal from 'hal'

import Api from 'api'
import { TENANCY_REL } from 'product/resources'
import axios from 'axios'
import logger from 'system/logger'

export const TENANCY_SET_URI = 'TENANCY_SET_URI'
export const TENANCY_UNLOAD = 'TENANCY_UNLOAD'
export const TENANCY_START_LOADING = 'TENANCY_START_LOADING'
export const TENANCY_STOP_LOADING = 'TENANCY_STOP_LOADING'
export const TENANCY_SET_LIST = 'TENANCY_SET_LIST'
export const TENANCY_SELECT = 'TENANCY_SELECT'
export const TENANCY_START_RENAMING = 'TENANCY_START_RENAMING'
export const TENANCY_STOP_RENAMING = 'TENANCY_STOP_RENAMING'
export const TENANCY_START_CREATING = 'TENANCY_START_CREATING'
export const TENANCY_STOP_CREATING = 'TENANCY_STOP_CREATING'
export const TENANCY_START_CONFIRMING_DELETION = 'TENANCY_START_CONFIRMING_DELETION'
export const TENANCY_STOP_CONFIRMING_DELETION = 'TENANCY_STOP_CONFIRMING_DELETION'

const startLoading = (companyKey) => ({
  type: TENANCY_START_LOADING,
  companyKey
})

const stopLoading = (companyKey) => ({ type: TENANCY_STOP_LOADING, companyKey })

/// ========= INIT ========= ///

const setUri = (companyKey, uri) => ({
  type: TENANCY_SET_URI,
  companyKey,
  uri
})

export const init = (companyKey, uri) => (dispatch) => {
  dispatch(setUri(companyKey, uri))
}

export const unload = (companyKey) => ({
  type: TENANCY_UNLOAD,
  companyKey
})

/// ========= GET ========= ///

export const setList = (companyKey, newList) => ({
  type: TENANCY_SET_LIST,
  companyKey,
  newList
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)
    const data = response ? response.data : null
    const newList = hal.resource(data, TENANCY_REL) || []

    dispatch(setList(companyKey, newList))
  } catch (err) {
    logger.error(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

/// ========= TENANCY SELECTIONS ========= ///

export const select = (companyKey, rows, selected, force) => {
  return {
    type: TENANCY_SELECT,
    companyKey,
    rows,
    selected,
    force
  }
}

/// ========= CREATION ========= ///

export const startCreating = (companyKey) => ({
  type: TENANCY_START_CREATING,
  companyKey
})

export const createTenancy = (companyKey, uri, values) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  let response

  try {
    response = await Api.post(uri, values)
  } catch (err) {
    logger.error(err)
    return dispatch(stopLoading(companyKey))
  }

  try {
    await dispatch(refresh(companyKey, uri))
    dispatch(select(companyKey, [response.headers.location], true, true))
  } finally {
    // we always close regardless whether refresh was successful or not
    dispatch(stopCreating(companyKey))
  }
}

export const stopCreating = (companyKey) => ({
  type: TENANCY_STOP_CREATING,
  companyKey
})

/// ========= RENAMING ========= ///

export const startRenaming = (companyKey, tenancy) => ({
  type: TENANCY_START_RENAMING,
  companyKey,
  tenancy
})

export const stopRenaming = (companyKey) => ({
  type: TENANCY_STOP_RENAMING,
  companyKey
})

export const doubleClickTenancy = (companyKey, tenancy) => {
  return (dispatch) => {
    dispatch(select(companyKey, [tenancy], true, true))
    return dispatch(startRenaming(companyKey, tenancy))
  }
}

export const renameTenancy = (companyKey, uri, renameLink, values) => {
  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      await Api.post(renameLink, values)
    } catch (err) {
      logger.error(err)
      return dispatch(stopLoading(companyKey))
    }

    try {
      await dispatch(refresh(companyKey, uri))
    } finally {
      dispatch(stopRenaming(companyKey))
    }
  }
}

/// ========= DELETION ========= ///

export const startConfirmingDeletion = (companyKey) => ({
  type: TENANCY_START_CONFIRMING_DELETION,
  companyKey
})

export const stopConfirmingDeletion = (companyKey) => ({
  type: TENANCY_STOP_CONFIRMING_DELETION,
  companyKey
})

export const deleteTenancies = (companyKey, uri, selfLinks) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    await axios.all(selfLinks.map((selfLink) => Api.delete(selfLink)))
    await dispatch(refresh(companyKey, uri))
    dispatch(stopConfirmingDeletion(companyKey))
  } catch (err) {
    logger.error(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= ENABLE/DISABLE ========= ///

export const suspendTenancies = (companyKey, uri, links) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    await axios.all(links.map((link) => Api.post(link)))
    await dispatch(refresh(companyKey, uri))
  } catch (err) {
    logger.error(err)
    dispatch(stopLoading(companyKey))
  }
}

export const unsuspendTenancies = (companyKey, uri, links) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    await axios.all(links.map((link) => Api.post(link)))
    await dispatch(refresh(companyKey, uri))
  } catch (err) {
    logger.error(err)
    dispatch(stopLoading(companyKey))
  }
}
