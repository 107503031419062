const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const CurrencyRecord = require('admin/data/CurrencyRecord')

const CurrencyStore = Ext.define(null, {
  extend: ResourceStore,

  baseUrl: '/api/system',
  resourceName: 'currencies',
  sortInfo: {
    field: 'code',
    direction: 'ASC'
  },

  record: CurrencyRecord
})

module.exports = CurrencyStore
