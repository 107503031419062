const Ext = require('ext')
const t = require('translate')
const LiveSearchCombo = require('admin/component/form/LiveSearchCombo')
const Alert = require('admin/util/Alert').default
const getRecordByUrl = require('admin/desktop/openRecord').getRecordByUrl
const Api = require('api').default
const getQueryParam = require('url/getQueryParam').default

// The purpose of this tool is to convert a Party to something that it
// is not, of CUSTOMER, RESELLER, or DISTRIBUTOR.
// Intended workflow is to search for what to convert, and then click one of the buttons to convert.
const ConvertToolPanel = Ext.define(null, {
  extend: Ext.FormPanel,

  title: t('Convert'),
  buttonAlign: 'center',
  padding: 10,
  layoutConfig: {
    align: 'stretch'
  },

  initComponent() {
    this.convertSearch = new LiveSearchCombo({
      fieldLabel: t('... to this'),
      module: require('admin/business/ConvertToolResource'),
      allowBlank: false,
      forceSelection: true,
      listeners: {
        select: this.handleSelect.bind(this)
      }
    })

    this.toolbar = new Ext.Toolbar()

    this.items = [this.convertSearch, this.toolbar]

    this.callParent()
  },

  handleSelect(combo, record) {
    this.toolbar.removeAll()

    if (['CUSTOMER', 'RESELLER', 'DISTRIBUTOR'].indexOf(record.getType()) > -1) {
      record = getRecordByUrl('/api' + record.get('url'))
      record.fetch({
        success: this.updateButtons.bind(this)
      })
    }

    this.toolbar.add({
      text: t('Invalid'),
      xtype: 'displayfield'
    })
    this.toolbar.doLayout()
  },

  updateButtons(record) {
    this.toolbar.removeAll()

    const conversionLink = record.getLinkByRel('conversion')

    if (conversionLink) {
      this.toolbar.addButton({
        text: conversionLink.title,
        handler: () => {
          const uri = conversionLink.uri
          const id = getQueryParam('id', uri)

          Api.put(
            uri,
            { id },
            {
              'content-type': 'application/x-www-form-urlencoded'
            }
          )
            .then(() => {
              Alert.info(t('Conversion successful'))
              record.fetch({
                success: this.updateButtons.bind(this)
              })
            })
            .catch((err) => Alert.displayResponseError(err))
        }
      })
    }

    this.toolbar.addButton({
      text: t('Clear'),
      handler: () => {
        this.toolbar.removeAll()
        this.convertSearch.reset()
      }
    })

    this.toolbar.doLayout()
  }
})

module.exports = ConvertToolPanel
