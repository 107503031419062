import t from 'translate'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Confirmation from 'components/dialog/Confirmation'
import formatProgress from 'mail/search/release/formatProgress'

const TITLE = t('Release Selected Messages')
const DIALOG_TEXT = t('Are you sure you want to release selected messages?')

class ReleaseConfirmation extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,

    releaseSize: PropTypes.number,
    releaseSuccessful: PropTypes.number,
    releaseSkipped: PropTypes.number,
    releaseFailed: PropTypes.number
  }

  handleSubmit = (e) => {
    // this is required as Confirmation uses Form submit
    e.preventDefault()

    this.props.onSubmit()
  }

  render() {
    let text
    let icon

    // When releasing is underway, icon will change from 'i' info icon to
    // '!' exclamation icon
    if (this.props.releaseSize) {
      icon = 'info'
      text =
        `${t('Processing')}: ` +
        formatProgress(
          this.props.releaseSize,
          this.props.releaseSuccessful,
          this.props.releaseSkipped,
          this.props.releaseFailed
        )
    } else {
      icon = 'exclamation'
      text = DIALOG_TEXT
    }

    return (
      <Confirmation
        icon={icon}
        modalTitle={TITLE}
        headerText={text}
        submitButtonText={t('Release')}
        cancelDisabled={this.props.disabled}
        submitDisabled={this.props.disabled}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default ReleaseConfirmation
