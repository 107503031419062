const Ext = require('ext')
const config = require('theming/config')

// Since SCL-959 we are overriding the validator for all ExtJS text fields,
// trimming all text inputs before validation.
Ext.override(Ext.form.TextField, {
  validator(text) {
    return text.length === 0 || Ext.util.Format.trim(text).length !== 0
  }
})

const FormPanel = Ext.define(null, {
  extend: Ext.form.FormPanel,

  autoHeight: true,
  border: false,
  buttonAlign: 'left',
  cls: 'x-left-form-panel',
  fieldWidth: config.panel.fieldWidth,
  labelAlign: 'right',
  labelWidth: config.panel.labelWidth,
  monitorValid: true,
  trackResetOnLoad: true,

  initComponent() {
    // Internal BasicForm is constructed using initialConfig.
    this.initialConfig.trackResetOnLoad = this.trackResetOnLoad
    this.callParent()
  }
})

module.exports = FormPanel
