import _s from 'underscore.string'
import isString from 'strings/isString'

export default function (value) {
  return (
    isString(value) &&
    _s.words(value).reduce(function (memo, word) {
      word = word.trim()
      return memo + word
    }, '')
  )
}
