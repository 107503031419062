const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const WindowMgr = require('admin/desktop/WindowMgr').default
const HostingDomainDetailPanel = require('admin/view/HostingDomainDetailPanel')
const HostingDomainNewPanel = require('admin/view/HostingDomainNewPanel')
const HostingDomainListPanel = require('admin/view/HostingDomainListPanel')
const HostingDomainRecord = require('admin/data/HostingDomainRecord')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const User = require('admin/core/User')
const validators = require('validators').default
const logger = require('system/logger').default

const HostingDomainResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.HostingDomain',
  translations: {
    title: t('Hosting Domains'),
    plural: t('Hosting Domains'),
    new: t('New Hosting Domain'),
    addWildcard: t('Update Wildcard'),
    addWildcardLabel: t('Wildcard Address'),
    open: t('Open Hosting Domain'),
    list: t('List Hosting Domains'),
    remove: t('Remove Hosting Domain'),
    copy: t('Copy Hosting Domain Name')
  },

  name: t('Hosting Domains'),
  code: 'HOSTING_DOMAIN',
  resourceName: 'hostingdomains',
  resourceId: 'HOSTING_DOMAINS',
  searchable: true,

  operations: {
    open: false,
    save: false
  },

  layout: {
    newResource: {
      width: 400,
      height: 140
    },
    wildcardResource: {
      width: 420,
      height: 110
    },
    openResource: {
      width: 380,
      height: 'auto',
      resizable: false
    }
  },

  detailPanel: HostingDomainDetailPanel,
  newPanel: HostingDomainNewPanel,
  listPanel: HostingDomainListPanel,

  record: HostingDomainRecord,

  // since https://youtrack.smxemail.com/issue/SCL-2400
  allowWildcardProvisioning(record) {
    return (
      record.store.canCreate() &&
      User.isVendor() &&
      (User.isTechnical() || User.isTechnicalAdmin())
    )
  },

  getContextMenuItems(record) {
    const items = [
      {
        text: this.translations.copy,
        iconCls: this.getResourceCls('copy'),
        handler() {
          const myButton = Ext.getCmp('hosting-domain-copy-button')
          return myButton.el.dom.click()
        }
      }
    ]

    if (this.allowWildcardProvisioning(record)) {
      items.push({
        text: this.translations.addWildcard,
        iconCls: this.getResourceCls('open'),
        handler() {
          const myButton = Ext.getCmp('hosting-domain-wildcard-button')
          return myButton.el.dom.click()
        }
      })
    }

    return items
  },

  addWildcard(record, options = {}) {
    // curl -u colint@smxemail.com:password  -k -v  'https://localhost:3000/api/customer/2011376/mailhost/hostingdomains/2074073' -X PUT -d "hostingPlatform=#mx7&wildcard=archivetest@smxemail.com"
    let windowId = `wildcard.${this.id}`
    if (record) {
      windowId += `[${record.get('id')}]`
    }

    if (!WindowMgr.select(windowId)) {
      const createName = this.translations.addWildcard
      const field = new Ext.form.TextField({
        name: 'wildcard',
        fieldLabel: this.translations.addWildcardLabel,
        width: 250,
        allowBlank: false,
        validator: validators.email.withMsg(
          t('This field should be an e-mail address in the format "user@example.com"')
        )
      })
      const currentValue = record.get('wildcard')
      if (currentValue?.length > 0) {
        field.setValue(currentValue)
      }

      const items = [field]
      const panel = new RecordFormPanel({
        items,
        forceLayout: true
      })
      if (panel) {
        let title = `${createName}`

        if (record?.getName) {
          title += ` - ${record.getName()}`
        }

        panel.addClass('add-wildcard-panel')

        panel.addButton({
          text: t('Cancel'),
          handler() {
            const parentWindow = panel.findParentWindow()
            parentWindow?.close()
          }
        })

        panel.addButton({
          text: t('Delete'),
          ctCls: 'wildcard-button-remove',
          cls: 'wildcard-button-remove-cls',
          // formBind: true
          disabled: record.get('wildcard').length === 0,
          scope: this,
          handler() {
            record.set('wildcard', '')
            Ext.MessageBox.wait(t('Deleting...'))
            record.save(null, {
              success() {
                return record.store.reload()
              },
              error(record, response, options, err) {
                return logger.error(err)
              },
              complete() {
                return Ext.MessageBox.hide()
              }
            })

            const parentWindow = panel.findParentWindow()
            parentWindow?.close()
          }
        })

        panel.addButton({
          text: t('Save'),
          scope: this,
          formBind: true,
          disabled: true,
          cls: 'primary',
          handler() {
            const values = panel.getForm().getFieldValues() || {}
            record.set('wildcard', values.wildcard)
            Ext.MessageBox.wait(t('Saving...'))
            record.save(null, {
              success() {
                return record.store.reload()
              },
              error(record, response, options, err) {
                return logger.error(err)
              },
              complete() {
                return Ext.MessageBox.hide()
              }
            })

            const parentWindow = panel.findParentWindow()
            parentWindow?.close()
          }
        })

        const url = record.store.getUrl() + '/updateWildcard'

        return WindowMgr.create(
          {
            id: windowId,
            title,
            url,
            taskbar: createName,
            width: this.layout.wildcardResource.width,
            autoHeight: this.layout.wildcardResource.height === 'auto',
            height: this.layout.wildcardResource.height,
            iconCls: 'new',
            shim: false,
            animCollapse: false,
            items: panel,
            layout: 'fit'
          },
          {
            maximized: options.maximized
          }
        )
      }
    }
  }
})

module.exports = HostingDomainResource
