export default {
  // states must be split by company because
  // they might vary from vendor, distributor and reseller and multiple
  // forms can exist in DOM shadows
  companies: {
    /*

    '/vendor/1004' for example is the unique identifier of a company we're
    calling companyKey

    here an example what the state could look like for one active company:

    '/vendor/1004': {
      loading: false,
      creating: false,
      configuring: false,
      confirmingConfiguration: false,
      confirmingDeletion: false,
      uri: 'https://localhost:3000/api/vendor/1004/sharedserviceproviders'
      list: []
    }
    */
  }
}
