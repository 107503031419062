const Ext = require('ext')
const TextRow = require('admin/util/TextRow')

const TextArea = Ext.define(null, {
  extend: Ext.form.TextArea,

  insertAtCursor(value) {
    const domEl = Ext.DomQuery.selectNode('textarea', this.container.dom)
    if (document.selection) {
      // MSIE
      this.focus()
      const sel = document.selection.createRange()
      sel.text = value
      this.focus()
    } else if (Number.isInteger(domEl.selectionStart)) {
      // Webkit / FF etc
      const startPos = domEl.selectionStart
      const endPos = domEl.selectionEnd
      const { scrollTop } = domEl
      this.setValue(
        this.getValue().substring(0, startPos) +
          value +
          this.getValue().substring(endPos, this.getValue().length)
      )
      this.focus()
      domEl.selectionStart = domEl.selectionEnd = startPos + value.length
      domEl.scrollTop = scrollTop
    } else {
      this.setValue(this.getValue() + value)
      this.focus()
    }
  },

  getCoordinates() {
    const domEl = Ext.DomQuery.selectNode('textarea', this.container.dom)
    return TextRow.getCoordinatesByPosition(this.getValue(), domEl.selectionStart)
  }
})

module.exports = TextArea
