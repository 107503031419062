import Ext from 'ext'
import logger from 'system/logger'
import stripApi from 'url/stripApi'

const Window = Ext.define(null, {
  extend: Ext.Window,

  minimizable: true,
  maximizable: true,
  shadow: false, // Use CSS shadow instead

  listeners: {
    beforeclose() {
      // Check if any child components will prevent closing
      // Callback is so implementations of preventClose can retry after user input
      const retry = () => this.close()

      let canClose = true

      this.cascade(function (component) {
        if (!component.preventClose) return
        if (typeof component.preventClose !== 'function') return

        if (component.preventClose(retry)) {
          canClose = false
          return false
        }
      })

      return canClose
    },

    activate() {
      // Used to add CSS box-shadow
      this.el?.addClass('active-window')
    },

    deactivate() {
      this.el?.removeClass('active-window')
    }
  },

  // supposed to return an unique string for whatever resource
  // has been opened, this for google analytics
  getPath() {
    if (this.url) return stripApi(this.url)

    const store = this.getStore()

    if (store) {
      return stripApi(store.getUrl())
    }

    const record = this.getRecord()

    if (record) {
      return stripApi(record.getUrl())
    }

    // TODO should not happen, each window should have an unique path for google analytics
    logger.warn(`Unable to figure path for: ${this.getId()}`)

    return this.getId()
  },

  // use with caution. needed for search windows only where search parameters can change
  // and impact window.location
  setUrl(newUrl) {
    this.url = newUrl
  },

  getRecord() {
    let record

    // Find the first record object in the window's children
    this.cascade(function (comp) {
      if (comp.record) {
        record = comp.record
        return false
      }
    })

    return record
  },

  getStore() {
    let store

    // Find the first store object in the window's children
    this.cascade(function (comp) {
      if (comp.store) {
        store = comp.store
        return false
      }
    })

    return store
  }
})

export default Window
