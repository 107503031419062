const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const InvoiceRecord = require('admin/data/InvoiceRecord')
const InvoiceResource = require('admin/business/InvoiceResource')

const InvoiceStore = Ext.define(null, {
  extend: ResourceStore,

  statics: {
    PRIORITIES: {
      0: 'directDebit',
      10: 'newCustomer',
      20: 'international',
      30: 'changedInvoice',
      100: 'defaultInvoice'
    }
  },

  resourceName: 'invoices',
  groupField: 'invoiceClassificationName',
  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      {
        field: 'invoiceDate',
        direction: 'DESC'
      },
      {
        field: 'displayName',
        direction: 'ASC'
      },
      {
        field: 'invoiceClassificationName',
        direction: 'ASC'
      }
    ]
  },

  record: InvoiceRecord,

  module() {
    return InvoiceResource
  }
})

module.exports = InvoiceStore
