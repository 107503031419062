import React, { Component } from 'react'
import { doubleClickMailTarget, refresh, select } from '../actions'
import { list, loading, uri } from '../subs'

import PropTypes from 'prop-types'
import SelectableTable from 'components/table/SelectableTable'
import { connect } from 'react-redux'
import t from 'translate'

const BOOTSTRAP_PADDING = 5
const TOOLBAR_MIN_HEIGHT = 65 + 2 * BOOTSTRAP_PADDING

class MailTargetTable extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired
  }

  componentDidMount() {
    this.props.refresh(this.props.uri)
  }

  get width() {
    if (typeof this.props.width === 'number') {
      return this.props.width - BOOTSTRAP_PADDING * 3
    }
    return this.props.width
  }

  get maxHeight() {
    if (typeof this.props.height === 'number') {
      return this.props.height - TOOLBAR_MIN_HEIGHT - BOOTSTRAP_PADDING * 4
    }
    return this.props.height
  }

  handleRowDoubleClick = (index, mailTarget) => {
    if (!mailTarget['read-only']) {
      this.props.doubleClickMailTarget(mailTarget)
    }
  }

  rowClassNameGetter = (index, mailTarget) => {
    if (mailTarget['read-only']) {
      return 'read-only'
    }
  }

  getResults() {
    if (this.props.list) {
      return this.props.list
    }
  }

  render() {
    const columns = [
      {
        name: 'name',
        title: t('Name'),
        width: 160,
        flexGrow: 3,
        isResizable: true,
        cellClassName: 'truncate-cell',
        rowCellClassName: 'mail-target-name'
      },
      {
        name: 'member-summary',
        title: t('Targets'),
        width: 300,
        flexGrow: 6,
        isResizable: true,
        cellClassName: 'truncate-cell'
      }
    ]

    return (
      <SelectableTable
        disabled={this.props.loading}
        width={this.width}
        maxHeight={this.maxHeight}
        results={this.getResults()}
        onRowDoubleClick={this.handleRowDoubleClick}
        rowClassNameGetter={this.rowClassNameGetter}
        onSelect={this.props.onSelect}
        rowClassName='mail-target-row'
        columns={columns}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  list: list(db, props.companyKey),
  loading: loading(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  refresh: (uri) => dispatch(refresh(initialProps.companyKey, uri)),
  onSelect: (mailTargets, selected) =>
    dispatch(select(initialProps.companyKey, mailTargets, selected)),
  doubleClickMailTarget: (mailTarget) =>
    dispatch(doubleClickMailTarget(initialProps.companyKey, mailTarget))
})

export default connect(mapDbToProps, mapActionsToProps)(MailTargetTable)
