const Ext = require('ext')
const Component = require('smartrules/designer/Component')
const sprites = require('sprites')

const Icon = Ext.define(null, {
  extend: Component,

  constructor(cfg) {
    cfg = Ext.applyIf(cfg || {}, {
      border: false,
      margins: '2 5',
      cls: 'smartrules-icon ' + cfg.type
    })

    if (sprites.hasSvg(cfg.type)) {
      cfg.html = sprites.toHtml(cfg.type)
    }

    this.callParent([cfg])

    this.on(
      'render',
      function () {
        // note: can't use the register fn of admin/util/Tooltip
        // because tooltip has to be rendered right away when
        // parent component (this one) has alrady been rendered (extjs nature)
        return new Ext.ToolTip({
          width: 'auto',
          target: this.el,
          title: cfg.label
        })
      },
      this
    )
  },

  setEnabled(flag) {
    this.enabled = flag
    return this[flag ? 'removeClass' : 'addClass']('smartrules-icon-disabled')
  }
})

module.exports = Icon
