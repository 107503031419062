const Ext = require('ext')
const t = require('translate')
const SignatureGridPanel = require('admin/component/grid/SignatureGridPanel')
const SignatureFormPanel = require('admin/component/form/SignatureFormPanel')
const DomainFormPanel = require('admin/component/form/DomainFormPanel')

const SignatureManagementPanel = Ext.define(null, {
  extend: Ext.Panel,

  title: t('Mail Footers'),
  layout: 'border',

  initComponent() {
    let tooltip
    this.module = require('admin/business/SignatureManagementResource')

    const isEmailHosting = this.product.productCategory === 'EMAIL_HOSTING_PRODUCTS'

    if (isEmailHosting) {
      tooltip = t('Domains with the given footer activated')
    } else {
      tooltip = t('Mail Servers with the given footer activated')
    }

    this.items = [
      (this.signatures = new SignatureGridPanel({
        region: 'west',
        width: 199,
        hosting: isEmailHosting,
        module: this.module,
        store: this.customer.getSignatureStore(this.product),
        product: this.product
      })),
      new Ext.TabPanel({
        activeTab: 0,
        region: 'center',
        border: false,
        bwrapCfg: {
          cls: 'left-border'
        },
        items: [
          (this.signatureEditor = new SignatureFormPanel({
            title: t('Footer'),
            border: false,
            tooltip: t('The details of the given footer'),
            module: this.module,
            signatures: this.signatures
          })),
          (this.configPanel = new DomainFormPanel({
            tooltip,
            useMailServerStrings: !isEmailHosting
          }))
        ]
      })
    ]

    this.on('beforeload', function () {
      return !this.signatureEditor.isDirty()
    })

    this.signatures.getSelectionModel().on('beforerowselect', () => {
      return !(this.signatureEditor.isDirty() || this.configPanel.isDirty())
    })

    // We need to listen to load to catch new signatures created inline.
    this.signatures.store.on('load', this.updateSignature, this)
    this.signatures.getSelectionModel().on('selectionchange', this.updateSignature, this)

    this.signatures.on('itemselected', ({ removable }) => {
      this.configPanel.setRemovable(removable)
    })

    this.callParent()
  },

  updateSignature() {
    const signature = this.signatures.getSelectionModel().getSelected()
    this.configPanel.setParentRecord(signature)
  }
})

module.exports = SignatureManagementPanel
