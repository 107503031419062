import { Form, Modal } from 'react-bootstrap'
import React, { Component } from 'react'
import { formDisabled, mailTargetForm } from '../../subs'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Grid from 'components/Grid'
import Mask from 'components/Mask'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

export const FORM_NAME = 'mailTargetForm'

class MailTargetDialog extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired,
    mailTarget: PropTypes.object,
    submitButtonText: PropTypes.string,
    allowPristine: PropTypes.bool
  }

  disableSubmit() {
    // for their meanings see http://redux-form.com/6.8.0/docs/api/Props.md/
    return (
      (!this.props.allowPristine && this.props.pristine) ||
      this.props.invalid ||
      this.props.disabled ||
      !this.props.initialValues
    ) // do not enable submit button when there are no fields to enter
  }

  values() {
    const registeredFields = this.props.mailTargetForm.registeredFields
    const registeredValues = this.props.mailTargetForm.values

    // reason for this is to set `false` for the private checkbox when it's
    // not in the map of values (weird redux-form behavior and our api
    // won't interpret missing fields as false)
    // todo remove that once this github issue is resolved:
    // https://github.com/erikras/redux-form/issues/3617

    return Object.keys(registeredFields).reduce((values, k) => {
      values[k] = k in registeredValues ? registeredValues[k] : false

      return values
    }, {})
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.onSubmit(this.values())
  }

  render() {
    const dialogClassName =
      this.props.submitButtonText && this.props.submitButtonText.toLowerCase()

    return (
      <Mask>
        <Modal.Dialog className={dialogClassName}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Grid>{this.props.children}</Grid>
            </Modal.Body>

            <Modal.Footer>
              <Grid>
                <CancelSubmitButtons
                  disableCancel={this.props.disabled}
                  disableSubmit={this.disableSubmit()}
                  onCancel={this.props.onCancel}
                  submitText={this.props.submitButtonText}
                />
              </Grid>
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, props) => ({
  mailTargetForm: mailTargetForm(db),
  disabled: formDisabled(db, props.companyKey),
  initialValues: props.mailTarget
})

const MailTargetForm = reduxForm({ form: FORM_NAME })(MailTargetDialog)

export default connect(mapDbToProps)(MailTargetForm)
