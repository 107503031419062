import Messages from 'admin/core/Messages'
import Ext from 'ext'
import { escape } from 'html-escaper'
import t from 'translate'

// todo rewrite this html markup, relies too much on tooltip structure / styles.
const createAlertBox = (title, s) => `\
<div style="visibility: visible;" class="x-tip">
<div class="x-tip-anchor x-tip-anchor-top" style="visibility: visible;"></div>
  <div class="x-tip-bwrap">
    <div class="x-tip-ml">
      <div class="x-tip-mr">
        <div class="x-tip-mc">
          <div class="x-tip-body">
            <h4>
              ${title}
              <span class="datetime">
               @ ${new Date().format('Y/m/d H:i:s')}
              </span>
            </h4>
            <div>${s}</div>
          </div>
        </div>
    </div>
  </div>
</div>
</div>\
`

const Alert = {
  displayGeneralError() {
    return this.alert(
      t('Error'),
      t('Please try again. If this persists, please contact Technical Support.')
    )
  },

  displayResponseError(response, fn, scope) {
    /*
     Response status code beginning with the digit "5" indicate cases in which the server is aware that it has
     erred or is incapable of performing the request.

     Simply display "Server Error" for all of these cases.
    */
    if (Math.floor(response.status / 100) === 5) {
      return this.alert(
        t('An error has occurred'),
        t('An error has occurred processing your request, please try again later.'),
        fn,
        scope
      )
    } else if (response.status === 408 || response.isTimeout) {
      return this.alert(
        t('Timeout'),
        t(
          'Your query has timed out. We apologize for any inconvenience. Try again, or if searching restrict your search.'
        )
      )
    } else if (response.status === -1) {
      return this.alert(
        t('The server is currently busy'),
        t('Please try again - if this persists, please contact Technical Support')
      )
    } else if (response.statusText === 'communication failure') {
      return this.alert(t('The server is currently unavailable'), t('Unknown Error'))
    } else if (response.statusText || response.responseText) {
      /*
       Do not display the responseText if it appears to contain an HTML page.
       Still allows HTML formatting e.g. p, strong, em, lists etc
      */
      if (response.responseText && /<html|<head|<body/.test(response.responseText)) {
        return this.alert(response.statusText, t('Unknown Error'))
      }

      let text = response.responseText || response.data?.title || response.data

      if (text) text = escape(text)

      return this.alert(
        response.statusText,
        // ugh, backend doesn't always place error messages at the same place
        text,
        fn,
        scope
      )
    }
  },

  alert(title, format, fn, scope) {
    const m = this.info(title, format, fn, scope)
    m?.addClass('error')
  },

  // TODO: Refactor, it has no business being done the way it is. Oh yes!
  info(title, format, fn, scope = this) {
    fn?.call(scope)

    // do not attempt to add an info message when document.body is not here, for example
    // during jasmine tests
    if (!document.body) return

    let s

    if (!this.msgContainer) {
      this.msgContainer = Ext.DomHelper.insertFirst(
        document.body,
        { id: 'alert-msg-div' },
        true
      )
    }

    const nextDesktopHeader = Ext.get('next-desktop-header')

    if (nextDesktopHeader) {
      this.msgContainer.alignTo(nextDesktopHeader, 'tr-br?', [-12, 0])
    } else {
      // so i'm on the login page: no toolbar, so slide in from top
      this.msgContainer.setStyle('top', 0)
      this.msgContainer.setStyle('right', 0)
    }

    this.msgContainer.setStyle('position', 'absolute')

    if (arguments.length > 1 && arguments[1]) {
      s = String.format.apply(String, Array.prototype.slice.call(arguments, 1))
    } else {
      s = ''
    }

    const m = Ext.DomHelper.append(
      this.msgContainer,
      { html: createAlertBox(title, s) },
      true
    )

    Messages.add(
      Ext.fly(m.child('.x-tip-body').dom.cloneNode(true))
        .replaceClass('x-tip-body', 'x-box-mc')
        .set({ id: '' }).dom.innerHTML
    )

    m.slideIn('t')

    // hide after 9s
    ;(function () {
      return m.ghost('t', {
        callback() {
          return this.remove()
        },
        scope: m
      })
    }.defer(9000))
    return m
  }
}

export default Alert
