import React, { Component } from 'react'

import { toString } from 'admin/time/Time'
import CheckableTable from 'components/table/CheckableTable'
import { doubleClickTenancy, select } from 'integration/tenancy/actions'
import { loading, sortedList } from 'integration/tenancy/subs'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

const BOOTSTRAP_PADDING = 5
const TOOLBAR_MIN_HEIGHT = 65 + 2 * BOOTSTRAP_PADDING

const renderStatus = (suspended) => (suspended ? t('Disabled') : t('Active'))
const renderCreated = (created) => toString(created)

class TenancyTable extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    companyKey: PropTypes.string.isRequired
  }

  get width() {
    if (typeof this.props.width === 'number') {
      return this.props.width - BOOTSTRAP_PADDING * 3
    }
    return this.props.width
  }

  get maxHeight() {
    if (typeof this.props.height === 'number') {
      return this.props.height - TOOLBAR_MIN_HEIGHT - BOOTSTRAP_PADDING * 4
    }
    return this.props.height
  }

  handleRowDoubleClick = (index, tenancy) => {
    this.props.doubleClickTenancy(tenancy)
  }

  getResults() {
    if (this.props.sortedList) {
      return this.props.sortedList
    }
  }

  render() {
    const columns = [
      {
        name: 'name',
        title: t('Name'),
        width: 150,
        flexGrow: 2,
        isResizable: true,
        cellClassName: 'truncate-cell'
      },
      {
        name: 'tenant-id',
        title: t('Tenant ID'),
        width: 110,
        flexGrow: 2,
        isResizable: true,
        cellClassName: 'truncate-cell'
      },
      {
        name: 'created',
        title: t('Created'),
        width: 110,
        isResizable: true,
        renderCell: renderCreated
      },
      {
        name: 'suspended',
        title: t('Status'),
        width: 80,
        isResizable: false,
        renderCell: renderStatus
      }
    ]

    return (
      <CheckableTable
        disabled={this.props.loading}
        width={this.width}
        maxHeight={this.maxHeight}
        results={this.getResults()}
        onRowDoubleClick={this.handleRowDoubleClick}
        onSelect={this.props.onSelect}
        rowClassName='tenancy-row'
        columns={columns}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  sortedList: sortedList(db, props.companyKey),
  loading: loading(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  onSelect: (tenancies, selected) => dispatch(select(companyKey, tenancies, selected)),
  doubleClickTenancy: (tenancy) => dispatch(doubleClickTenancy(companyKey, tenancy))
})

export default connect(mapDbToProps, mapActionsToProps)(TenancyTable)
