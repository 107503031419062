const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const SharedServiceListPanel = require('admin/view/SharedServiceListPanel')
const SharedServiceRecord = require('admin/data/SharedServiceRecord')

const SharedServiceResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.SharedService',
  translations: {
    title: t('Shared Services'),
    plural: t('Shared Services'),
    list: t('List Shared Services')
  },

  name: t('Shared Services'),
  code: 'SHARED_SERVICE',
  resourceId: 'SHARED_SERVICES',
  resourceName: 'sharedservices',

  operations: {
    list: true,
    save: true
  },

  listPanel: SharedServiceListPanel,

  record: SharedServiceRecord
})

module.exports = SharedServiceResource
