import isDeveloping from 'system/isDeveloping'
import isTesting from 'system/isTesting'

const getEnv = () => {
  if (isDeveloping()) {
    return 'DEV'
  } else if (isTesting()) {
    return 'TEST'
  }

  return 'PROD'
}

export default getEnv
