const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const CompanyGroupPanel = require('admin/view/CompanyGroupPanel').default
const User = require('admin/core/User')
const logger = require('system/logger').default

// Superclass of Customer, Reseller, Distributor
const CompanyResource = Ext.define(null, {
  extend: Resource,

  name: t('Company'),
  searchable: true,
  explorable: true,

  groupPanel: CompanyGroupPanel,

  // Show ids to technical admins
  getTitle(record) {
    const titleId = User.isTechnicalAdmin()
      ? String.format('[{0}]', record.get('id'))
      : ''
    return String.format('{0}{1}: {2}', this.name, titleId, record.getTitle())
  },

  // Override to make sure we have full record before opening.
  // Lite versions are used in lists.
  open(record, options = {}) {
    // Full versions should have the type property.
    if (record.getType()) {
      // for customers, resellers and distributors, we want to maximize windows by default,
      // see http://youtrack.smxemail.com/issue/SCL-1295
      options.maximized = true

      // due to this function being called from modified ExtJS async event handling
      // we have to manually surround this with a try catch block to trap and
      // display any errors if any, see SCL-3542
      try {
        this.callParent([record, options])
      } catch (exc) {
        logger.error(exc)
      }

      return
    }
    record.fetch({
      success: () => {
        this.superclass().open.call(this, record, options)
      }
    })
  }
})

module.exports = CompanyResource
