const Ext = require('ext')
const Record = require('admin/core/Record')
const ResourceReader = require('admin/data/ResourceReader')
const Alert = require('admin/util/Alert').default
const logger = require('system/logger').default
const LinkHeader = require('http-link-header')

const ResourceStore = Ext.define(null, {
  extend: Ext.data.GroupingStore,

  baseUrl: '/api',

  constructor(cfg = {}) {
    // Allows module to be defined async.
    if (typeof this.module === 'function') {
      this.module = this.module()
    }

    if (cfg.record) {
      this.record = cfg.record
    }

    if (this.url == null) {
      this.url =
        cfg.url || (cfg.parentRecord?.getUrl() || this.baseUrl) + '/' + this.resourceName
    }

    // failsafe protection to trap bad URLs. they never should happen. added since:
    // http://youtrack.smxemail.com/issue/SCL-998
    if (this.url === '/api/') {
      Alert.displayGeneralError()
    }

    this.proxy = new Ext.data.HttpProxy({
      url: this.url,
      method: 'GET'
    })

    // monkey patching the http proxy instance so that we can access the response object
    // see http://youtrack.smxemail.com/issue/SCL-33
    this.proxy.onRead = this.onProxyRead.bind(this)

    this.reader = this.getReader(this.record)

    // calls parent constructor
    Ext.data.GroupingStore.bind(this)(cfg)

    this.addEvents(
      // Use the same events as Backbone: http://backbonejs.org/#Events-catalog
      // Model = record, collection = store.
      // As well as their own events, stores will receive all events from child records.
      'add', // (model, collection, options) — when a model is added to a collection.
      'remove', // (model, collection, options) — when a model is removed from a collection.
      'change', // (model, options) — when a model's attributes have changed.
      'destroy', // (model, collection, options) — when a model is destroyed.
      'request', // (model_or_collection, xhr, options) — when a model or collection has started a request to the server.
      'sync', // (model_or_collection, resp, options) — when a model or collection has been successfully synced with the server.
      'error', // (model_or_collection, resp, options) — when model's or collection's request to remote server has failed.
      'invalid', // (model, error, options) — when a model's validation fails on the client.
      'all' // (eventName, args...) - when any event fires, args are the original event arguments.
    )

    this.on('load', this.handleLoad)
    this.on('exception', this.handleExceptionEvent)
    this.on('destroy', this.handleDestroy)
    this.on('sync', this.handleSync)
  },

  record: Record.define(null, {
    fields: [
      {
        name: 'id',
        type: 'int'
      }
    ]
  }),

  getUrl() {
    return this.url
  },

  load(options) {
    // Set proxy url before each load in case url has changed.
    // TODO: Remove when url is no longer changed.
    if (options == null) {
      options = {}
    }
    this.proxy.setUrl(this.url)

    // TODO: Remove loading state.
    this.loading = true

    return this.callParent([options])
  },

  getReader(recordType) {
    return new ResourceReader(recordType)
  },

  canRemove(opts) {
    // subscription store doesn't have a defined module'
    if (!this.module.canRemove) return
    return this.module.canRemove(opts)
  },

  canCreate() {
    return (
      this.module.canCreate() &&
      (this.module.ignoreExpired || !this.parentRecord?.get('expired'))
    )
  },

  handleLoad() {
    this.loading = false
    this.loaded = true
  },

  // Ext.data.Store has a private method handleException.
  handleExceptionEvent(store, type, action, options, response, exception) {
    this.loading = false

    // it can happen that the request went fine but an exception is thrown right
    // afterwards from i.E. bad code. in that case, dont display the error response
    // but just a general error. see `onProxyRead()` below for more background info.
    if (exception != null) {
      return Alert.displayGeneralError()
    }
    // There shouldn't be a reason to expect a store request to fail.
    // If there is a failure, at least show something went wrong.
    Alert.displayResponseError(response)
  },

  handleDestroy(record) {
    return this.remove(record)
  },

  handleSync(record, response) {
    const location = response.getResponseHeader('Location')
    if (location && record.isPersistentOnSave()) {
      return this.load()
    }
  },

  isLoaded() {
    return this.loaded
  },

  // monkey patching onRead() of ext's http proxy instance
  async onProxyRead(action, o, response) {
    let result

    try {
      result = o.reader.read(response)
    } catch (e) {
      logger.warn(e)

      this.proxy.fireEvent('loadexception', this.proxy, o, response, e)
      this.proxy.fireEvent('exception', this.proxy, 'response', action, o, response, e)

      o.request.callback.call(o.request.scope, null, o.request.arg, false)

      return
    }

    if (result.success === false) {
      this.proxy.fireEvent('loadexception', this.proxy, o, response)

      const res = o.reader.readResponse(action, response)
      this.proxy.fireEvent('exception', this.proxy, 'remote', action, o, res, null)
    } else {
      // Must wait here until all is loaded for handling big data (lots of table rows), see SCL-3552
      await this.proxy.fireEvent('load', this.proxy, o, o.request.arg)

      // this here is the single patch, everything else in this function remains the same
      // like its extjs original function
      if (response) {
        const link = response.getResponseHeader('Link')

        if (link != null) {
          this.links = LinkHeader.parse(link)
        }

        const linkTemplate = response.getResponseHeader('link-template')

        if (linkTemplate != null) {
          this.linkTemplates = LinkHeader.parse(linkTemplate)
        }
      }
    }

    return o.request.callback.call(o.request.scope, result, o.request.arg, result.success)
  }
})

module.exports = ResourceStore
