import React, { Component } from 'react'

import Field from 'components/form/Field'
import PropTypes from 'prop-types'
import t from 'translate'

const hoursOptions = []

for (let i = 0; i < 24; i++) {
  const value = i.toString().padStart(2, 0) + ':00'
  hoursOptions.push({ value, label: value })
}

class StartingField extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    info: PropTypes.object.isRequired
  }

  render() {
    return (
      <Field
        name='startTime'
        label={t('Starting')}
        componentClass='select'
        labelWidth={5}
        inputWidth={6}
        disabled={this.props.disabled}
        info={this.props.info}
      >
        {hoursOptions.map((hoursOption) => (
          <option key={hoursOption.value} value={hoursOption.value}>
            {hoursOption.label}
          </option>
        ))}
      </Field>
    )
  }
}
export default StartingField
