const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const User = require('admin/core/User')

const TrustedIpListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'name',

  module() {
    return require('admin/business/TrustedIpResource')
  },

  createColumnModel() {
    const columns = [
      {
        dataIndex: 'name',
        header: t('Mail Server'),
        id: 'name',
        sortable: true,
        width: 150
      }
    ]

    if (User.isTechnicalAdmin()) {
      columns.unshift({
        dataIndex: 'id',
        header: t('ID'),
        id: 'id',
        sortable: true,
        width: 60
      })
    }

    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = TrustedIpListPanel
