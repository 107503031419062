import React, { Component } from 'react'

import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import t from 'translate'

class Indicator extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    noun: PropTypes.string,
    size: PropTypes.number
  }

  getIndicator() {
    if (this.props.loading) {
      return <span className='loading-indicator'>{t('Loading...')}</span>
    }

    if (!this.props.size) return ['No', pluralize(this.props.noun)].join(' ')

    return [this.props.size, pluralize(this.props.noun, this.props.size)].join(' ')
  }

  render() {
    return <div className='indicator'>{this.getIndicator()}</div>
  }
}

export default Indicator
