import Api from 'api'
import * as hal from 'hal'
import { MAIL_TARGET_REL } from 'product/resources'
import * as quarantineSetsActions from 'quarantine/quarantine_sets/actions'
import logger from 'system/logger'

export const QUARANTINE_MAIL_TARGETS_STOP_CREATING =
  'QUARANTINE_MAIL_TARGETS_STOP_CREATING'
export const QUARANTINE_MAIL_TARGETS_STOP_CONFIGURING =
  'QUARANTINE_MAIL_TARGETS_STOP_CONFIGURING'
export const QUARANTINE_MAIL_TARGETS_START_CONFIGURING =
  'QUARANTINE_MAIL_TARGETS_START_CONFIGURING'
export const QUARANTINE_MAIL_TARGETS_SET_LIST = 'QUARANTINE_MAIL_TARGETS_SET_LIST'
export const QUARANTINE_MAIL_TARGETS_CLEAR_LIST = 'QUARANTINE_MAIL_TARGETS_CLEAR_LIST'
export const QUARANTINE_MAIL_TARGETS_SELECT = 'QUARANTINE_MAIL_TARGETS_SELECT'
export const QUARANTINE_MAIL_TARGETS_START_LOADING =
  'QUARANTINE_MAIL_TARGETS_START_LOADING'
export const QUARANTINE_MAIL_TARGETS_STOP_LOADING = 'QUARANTINE_MAIL_TARGETS_STOP_LOADING'
export const QUARANTINE_MAIL_TARGETS_START_CREATING =
  'QUARANTINE_MAIL_TARGETS_START_CREATING'
export const QUARANTINE_MAIL_TARGETS_START_RENAMING =
  'QUARANTINE_MAIL_TARGETS_START_RENAMING'
export const QUARANTINE_MAIL_TARGETS_STOP_RENAMING =
  'QUARANTINE_MAIL_TARGETS_STOP_RENAMING'
export const QUARANTINE_MAIL_TARGETS_SET_URI = 'QUARANTINE_MAIL_TARGETS_SET_URI'
export const QUARANTINE_MAIL_TARGETS_START_CONFIRMING_DELETION =
  'QUARANTINE_MAIL_TARGETS_START_CONFIRMING_DELETION'
export const QUARANTINE_MAIL_TARGETS_STOP_CONFIRMING_DELETION =
  'QUARANTINE_MAIL_TARGETS_STOP_CONFIRMING_DELETION'
export const QUARANTINE_MAIL_TARGETS_SELECT_BY_LINK =
  'QUARANTINE_MAIL_TARGETS_SELECT_BY_LINK'

const startLoading = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_START_LOADING,
  companyKey
})
const stopLoading = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_STOP_LOADING,
  companyKey
})

function processError(err) {
  logger.error(err)
}

/// ========= INIT DB WITH URI FOR COMPANY KEY ========= ///

const setUri = (companyKey, uri) => ({
  type: QUARANTINE_MAIL_TARGETS_SET_URI,
  companyKey,
  uri
})

export const init = (companyKey, uri) => (dispatch) => {
  // todo later, initialise db
  // dispatch(initDb(companyKey))
  dispatch(setUri(companyKey, uri))
}

/// ========= LIST SET/REFRESH ========= ///

export const setList = (companyKey, newList) => ({
  type: QUARANTINE_MAIL_TARGETS_SET_LIST,
  companyKey,
  newList
})

export const clearList = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_CLEAR_LIST,
  companyKey
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)
    dispatch(setList(companyKey, hal.resource(response.data, MAIL_TARGET_REL)))
  } catch (err) {
    dispatch(clearList(companyKey)) // clear existing list to reflect error state
    processError(err)
    dispatch(stopLoading(companyKey))

    // so that other actions can know whether it errored or not, see createMailTarget()
    return err
  }

  dispatch(stopLoading(companyKey))
}

/// ========= QUARANTINE_MAIL_TARGETS_SELECTIONS ========= ///

export const select = (companyKey, mailTargets, selected) => {
  return {
    type: QUARANTINE_MAIL_TARGETS_SELECT,
    companyKey,
    mailTargets,
    selected
  }
}

const selectByLink = (companyKey, selfLink, selected) => {
  return {
    type: QUARANTINE_MAIL_TARGETS_SELECT_BY_LINK,
    companyKey,
    selfLink,
    selected
  }
}

/// ========= CREATION ========= ///

export const startCreating = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_START_CREATING,
  companyKey
})

export const stopCreating = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_STOP_CREATING,
  companyKey
})

export const createMailTarget =
  (companyKey, uri, values, quarantineSetsUri) => async (dispatch) => {
    dispatch(startLoading(companyKey))

    let response
    let selfLink

    try {
      response = await Api.post(uri, values)
      selfLink = response.headers.location
    } catch (err) {
      processError(err)
      return dispatch(stopLoading(companyKey))
    }

    // now we need to refresh everything so it shows up already in the table
    const err = await dispatch(refresh(companyKey, uri))

    // abort when refreshing failed
    if (err) return dispatch(stopCreating(companyKey))

    // not every company can have quarantine sets ...
    if (quarantineSetsUri) {
      // now we also should refresh quarantine sets in the other namespace (tab)
      // as well so that the new mail target is there too.
      //
      // it is actually an async operation but we are removing 'await' to have this
      // run in parallel for speed which won't matter since this is in a different tab anyway
      dispatch(quarantineSetsActions.refresh(companyKey, quarantineSetsUri))
    }

    // since SCL-2735 we want to show the configure dialogue right away after creation
    try {
      dispatch(selectByLink(companyKey, selfLink, true))
      dispatch(stopCreating(companyKey))

      await dispatch(configureMailTarget(companyKey, selfLink))
    } catch (err) {
      processError(companyKey, err)
    }
  }

/// ========= DELETION ========= ///

export const startConfirmingDeletion = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_START_CONFIRMING_DELETION,
  companyKey
})
export const stopConfirmingDeletion = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_STOP_CONFIRMING_DELETION,
  companyKey
})

export const deleteMailTarget =
  (companyKey, uri, selfLink, quarantineSetsUri) => async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      const response = await Api.get(selfLink)

      await Api.delete(selfLink, { 'If-Match': response.headers.etag })
      await dispatch(refresh(companyKey, uri))

      if (quarantineSetsUri) {
        dispatch(quarantineSetsActions.refresh(companyKey, quarantineSetsUri))
      }

      dispatch(stopConfirmingDeletion(companyKey))
    } catch (err) {
      processError(err)
      dispatch(stopLoading(companyKey))
    }
  }

/// ========= RENAME ========= ///

const startRenaming = (companyKey, mailTarget) => ({
  type: QUARANTINE_MAIL_TARGETS_START_RENAMING,
  companyKey,
  mailTarget
})

export const stopRenaming = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_STOP_RENAMING,
  companyKey
})

export const editMailTargetName = (companyKey, selfLink) => {
  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      const response = await Api.get(selfLink)
      const mailTarget = Object.assign(response.data, { etag: response.headers.etag })

      dispatch(startRenaming(companyKey, mailTarget))
      dispatch(stopLoading(companyKey))
    } catch (err) {
      processError(err)
      dispatch(stopLoading(companyKey))
      dispatch(stopRenaming(companyKey))
    }
  }
}

export const renameMailTarget = (
  companyKey,
  uri,
  selfLink,
  newName,
  etag,
  quarantineSetsUri
) => {
  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      await Api.put(selfLink, { name: newName }, { 'If-Match': etag })
    } catch (err) {
      processError(err)
      return dispatch(stopLoading(companyKey))
    }

    await dispatch(refresh(companyKey, uri))

    if (quarantineSetsUri) {
      dispatch(quarantineSetsActions.refresh(companyKey, quarantineSetsUri))
    }

    dispatch(stopRenaming(companyKey))
  }
}

/// ========= CONFIGURATION ========= ///

export const startConfiguring = (companyKey, mailTarget) => ({
  type: QUARANTINE_MAIL_TARGETS_START_CONFIGURING,
  companyKey,
  mailTarget
})

export const stopConfiguring = (companyKey) => ({
  type: QUARANTINE_MAIL_TARGETS_STOP_CONFIGURING,
  companyKey
})

export const configureMailTarget = (companyKey, selfLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(selfLink)
    const mailTarget = Object.assign(response.data, { etag: response.headers.etag })

    dispatch(startConfiguring(companyKey, mailTarget))
    dispatch(stopLoading(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
    dispatch(stopConfiguring(companyKey))
  }
}

export const updateMailTarget = (companyKey, uri, updateMembersLink, members, etag) => {
  if (!etag) throw new Error('Etag for mail target is missing')

  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      await Api.post(updateMembersLink, members, { headers: { 'If-Match': etag } })
    } catch (err) {
      processError(err)
      return dispatch(stopLoading(companyKey))
    }

    await dispatch(refresh(companyKey, uri))
    dispatch(stopConfiguring(companyKey))
  }
}

export const doubleClickMailTarget = (companyKey, mailTarget) => (dispatch) => {
  dispatch(select(companyKey, [mailTarget], true))
  dispatch(configureMailTarget(companyKey, hal.self(mailTarget)))
}
