const Ext = require('ext')
const t = require('translate')
const PolicySetsGridPanel = require('admin/component/grid/PolicySetsGridPanel')
const PoliciesGridPanel = require('admin/component/grid/PoliciesGridPanel')
const PolicyStore = require('admin/data/PolicyStore')
const DomainFormPanel = require('admin/component/form/DomainFormPanel')
const ProductDirection = require('product/direction')

const PolicyManagementListPanel = Ext.define(null, {
  extend: Ext.Panel,

  title: t('Policies'),
  layout: 'border',

  helpContent: {
    inbound: {
      en: require('PolicyManagement_inbound_en.html').default
    },
    outbound: {
      en: require('PolicyManagement_outbound_en.html').default
    }
  },

  initComponent() {
    this.module = require('admin/business/PolicyManagementResource')

    this.policysets = new PolicySetsGridPanel({
      module: this.module,
      store: this.customer.getPolicySetStore(this.product),
      product: this.product,
      region: 'west',
      border: true,
      width: 235
    })

    this.policiesPanel = new PoliciesGridPanel({
      store: new PolicyStore(),
      title: t('Policies'),
      border: true,
      tooltip: t('Policies assigned to the currently selected policy set')
    })

    this.configPanel = new DomainFormPanel({
      title: t('Configuration'),
      tooltip: t('Domains assigned to the currently selected policy set'),
      useMailServerStrings:
        this.product.productCategory !== 'EMAIL_HOSTING_PRODUCTS' && this.isOutbound()
    })

    this.policyHelpPanel = new Ext.Panel({
      title: t('Help'),

      // removes any ext css class names as we style them with bootstrap (Bs)
      unstyled: true,
      cls: 'Bs',

      // these class names are the same as the react based help containers
      // we have for archiving or quarantine.
      bodyCssClass: 'help panel',
      // ensures full width is used
      autoWidth: true,

      layout: 'fit',
      border: false,
      autoScroll: true,
      // same padding as .Bs .tab-pane to ensure consistency
      style: 'padding: 10px 5px',
      html: ''
    })

    this.policytabs = new Ext.TabPanel({
      activeTab: 0,
      border: false,
      region: 'center',
      bodyCssClass: 'left-border',
      items: [this.policiesPanel, this.configPanel, this.policyHelpPanel]
    })

    this.items = [this.policysets, this.policytabs]

    this.policyHelpPanel.on('render', (view) => {
      const contents = this.getPolicyHelpContent()
      view.update(contents)
    })

    this.policysets.on('itemselected', ({ removable }) => {
      this.policiesPanel.setRemovable(removable)
      this.configPanel.setRemovable(removable)
    })

    this.policysets.getSelectionModel().on('beforerowselect', () => {
      // Prevent changing policy set while editing policy or config is dirty.
      return !(this.policiesPanel.editor.editing || this.configPanel.isDirty())
    })

    // We need to listen to load to catch new policy sets created inline.
    this.policysets.store.on('load', this.updatePolicies, this)

    // with a buffer, it gets fired only once.
    // otherwise the event is triggered twice for deselect and newselect.
    this.policysets
      .getSelectionModel()
      .on('selectionchange', this.updatePolicies, this, { buffer: 10 })

    this.callParent()
  },

  getSelectedPolicy() {
    return this.policysets.getSelectionModel().getSelected()
  },

  isOutbound() {
    return ProductDirection.isOutbound(this.getSelectedProductDirection())
  },

  getSelectedProductDirection() {
    const selected = this.getSelectedPolicy()

    if (selected != null) {
      // todo: fix the mismatch that backend returns the direction in uppercase
      // and UI has that constant in lowercase because in some API responses
      // they are in lowercase. for now, with SCL-995, we lowercase this policy set type.
      return selected.data.policySetType.toLowerCase()
    }
    return ProductDirection.DIRECTION_INBOUND
  }, // default one if nothing is selected

  getPolicyHelpContent() {
    return this.helpContent[this.getSelectedProductDirection()][window.LOCALE]
  },

  // added since http://youtrack.smxemail.com/issue/SCL-958
  refreshHostingDomains() {
    return this.configPanel.updateValues()
  },

  updatePolicies() {
    if (this.isDestroyed) {
      return
    }

    const selected = this.getSelectedPolicy()

    this.policiesPanel.setPolicySet(selected)
    this.configPanel.setParentRecord(selected)
  }
})

module.exports = PolicyManagementListPanel
