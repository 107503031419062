const Ext = require('ext')
const t = require('translate')
const User = require('admin/core/User')
const Alert = require('admin/util/Alert').default
const SourcePanel = require('smartrules/SourcePanel')
const SmartRule = require('smartrules/designer/SmartRule')

const RulePanel = Ext.define(null, {
  extend: Ext.Panel,

  layout: 'fit',

  constructor(cfg) {
    let item

    this.designer = new SmartRule({
      readOnly: cfg.readOnly,
      sandbox: cfg.sandbox,
      templateStore: cfg.templateStore,
      listStores: cfg.listStores,
      hasQuarantine: cfg.hasQuarantine,
      party: cfg.party
    })

    const showSource = (User.isVendor() && User.isTechnical()) || cfg.sandbox

    this.compilerUrl = cfg.party.get('products').SMART_RULES.resources.RULE_COMPILER.url

    const source = new SourcePanel({
      readOnly: cfg.readOnly
    })

    if (!cfg.readOnly) {
      source.on(
        'smartrules-source-changed',
        function () {
          // clear it
          source.setStatus('')
          source.removeClass('smartrules-source-ok')
          source.removeClass('smartrules-source-error')

          this.evaluate(source.getValue(), {
            sandbox: cfg.sandbox,
            // this callback always gets processed after a rule has been posted to the server
            // and will reflect icon/state according to its response.
            process(compilerResult) {
              if (source.isDestroyed) return

              source.valid = compilerResult?.valid != null && compilerResult.valid

              if (source.valid) {
                source.addClass('smartrules-source-ok')
                source.removeClass('smartrules-source-error')
                return source.setStatus(t('OK'))
              }

              source.addClass('smartrules-source-error')
              source.removeClass('smartrules-source-ok')
              source.setStatus(t('Syntax error'))
            }
          })
        },
        this
      )
    }

    if (showSource) {
      item = new Ext.TabPanel({
        activeTab: 0,
        autoHeight: true,
        autoWidth: true,
        border: false,
        items: [this.designer, source],
        ref: 'tabpanel'
      })

      item.on(
        'tabchange',
        function (tabs, newPanel) {
          if (newPanel === this.designer) {
            if (source.valid === true && source.isDirty()) {
              this.evaluate(source.getValue(), {
                success: (compilerResult) => {
                  if (this.designer.isDestroyed) return
                  this.designer.setRule(compilerResult.compiledRule)
                }
              })
            } else if (source.isDirty()) {
              Alert.alert(
                t('SmartRule reverted'),
                t('Invalid SmartRule source. The designer has not been updated.')
              )
            }
          } else if (newPanel === source) {
            source.setValue(this.designer.toDsl())
          }

          tabs.items.each(function (item) {
            const fields = item.findByType(Ext.form.Field)
            fields.forEach((field) => field.setDisabled(item !== newPanel))
          })
        },
        this
      )
    } else {
      item = this.designer
    }

    item.on(
      'afterlayout',
      function () {
        return this.evaluate(cfg.definition, {
          sandbox: cfg.sandbox,
          success: (compilerResult) => {
            let activeTab

            if (this.isDestroyed) {
              return
            } // prevents further processing when already closed

            const container = this.findParentBy((con) => !!con.resetDirtyFlags)

            if (compilerResult.parserErrors.length > 0) {
              source.setValue(compilerResult.rule)
              activeTab = source
              container?.setReady()
            } else {
              container != null &&
                this.designer.on('smartrules-built', () =>
                  typeof container.setReady === 'function'
                    ? container.setReady()
                    : undefined
                )
              this.designer.setRule(compilerResult.compiledRule)
              activeTab = this.designer
            }

            if (this.tabpanel) {
              this.tabpanel.setActiveTab(activeTab)
              this.tabpanel.doLayout()
            }
          }
        })
      },
      this,
      { single: true }
    )

    this.items = item

    this.callParent(arguments)
  },

  maskEl() {
    this.el.mask(Ext.LoadMask.prototype.msg, 'x-mask-loading')
  },

  unmaskEl() {
    this.el.unmask()
  },

  evaluate(definition, options = {}) {
    if (!definition || options.sandbox) return

    const successCallback = options.success
    const failureCallback = options.failure
    const processCallback = options.process

    this.maskEl()

    return Ext.Ajax.request({
      url: this.compilerUrl,
      // useful to know: if we omit the parameter "rule" for this POST
      // then a 400 is returned.
      params: {
        rule: definition
      },
      mask: this.maskEl.bind(this),
      unmask: this.unmaskEl.bind(this),
      success: (response) => {
        let compilerResult

        try {
          compilerResult = JSON.parse(response.responseText)
        } catch (error) {
          compilerResult = { valid: false }
        }

        // this gets always processed, regardless of validity
        if (typeof processCallback === 'function') {
          processCallback(compilerResult)
        }
        if (typeof successCallback === 'function') {
          successCallback(compilerResult)
        }

        this.unmaskEl()
      },
      failure: (response) => {
        const compilerResult = { valid: false }

        // this gets always processed, regardless of validity
        if (typeof processCallback === 'function') {
          processCallback(compilerResult)
        }
        if (typeof failureCallback === 'function') {
          failureCallback(compilerResult)
        }

        this.unmaskEl()

        Alert.displayResponseError(response)
      }
    })
  },

  // Pulls the value out of the designer or the source panel whichever is active
  getValue() {
    if (this.tabpanel) {
      return this.tabpanel.activeTab.getValue()
    }

    return this.designer.getValue()
  },

  isValid() {
    if (this.tabpanel?.activeTab) {
      return this.tabpanel.activeTab.isValid()
    } else if (this.designer) {
      return this.designer.isValid()
    }

    return false
  }
})

module.exports = RulePanel
