import { Cell, Column, Table } from 'fixed-data-table-2'
import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

class TableComponent extends PureComponent {
  static propTypes = {
    columns: PropTypes.array,
    disabled: PropTypes.bool,
    getHeaderTitle: PropTypes.func,
    getRow: PropTypes.func.isRequired,
    maxHeight: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    rowsCount: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    headerHeight: PropTypes.number.isRequired
  }

  static defaultProps = {
    headerHeight: 26,
    rowHeight: 26
  }

  constructor(props) {
    super(props)

    this.state = {
      colWidths: {},
      isColumnResizing: null
    }
  }

  handleColumnResizeEnd = (width, col) => {
    const colWidth = {}
    // TODO fix, broken
    colWidth[col] = { $set: width }

    const colWidths = this.state.colWidths

    this.setState({
      colWidths,
      isColumnResizing: false
    })
  }

  getAvailableWidth = () => {
    // internal width is strictly less than component's width to avoid
    // spurious horizontal scrolling, see SCL-2475
    const internalWidth = this.props.width - 1

    return (
      internalWidth -
      this.props.columns.reduce((memo, columnProps) => {
        if (Number.isInteger(this.state.colWidths[columnProps.name])) {
          return memo + this.state.colWidths[columnProps.name]
        }

        return memo + columnProps.width
      }, 0)
    )
  }

  getFlexGrowSum = () => {
    return this.props.columns.reduce((memo, columnProps) => {
      if (
        columnProps.flexGrow &&
        !Number.isInteger(this.state.colWidths[columnProps.name])
      ) {
        return memo + columnProps.flexGrow
      }

      return memo
    }, 0)
  }

  calculateColWidth = (columnProps) => {
    if (Number.isInteger(this.state.colWidths[columnProps.name])) {
      return this.state.colWidths[columnProps.name]
    } else if (columnProps.flexGrow) {
      return (
        columnProps.width +
        (this.getAvailableWidth() / this.getFlexGrowSum()) * columnProps.flexGrow
      )
    }

    return columnProps.width
  }

  renderCell = (rowIndex, columnProps) => {
    const rowData = this.props.getRow(rowIndex)
    let cellData = rowData[columnProps.name]

    if (columnProps.renderCell) {
      cellData = columnProps.renderCell(cellData, columnProps.name, rowData)
    }

    return <Cell className={columnProps.rowCellClassName}>{cellData}</Cell>
  }

  renderHeaderCell = (rowIndex, columnProps) => {
    let cellData

    if (columnProps.title) {
      cellData = columnProps.title
    } else if (this.props.getHeaderTitle) {
      cellData = this.props.getHeaderTitle(columnProps.name)
    }

    if (columnProps.renderHeader) {
      const rowData = this.props.getRow(rowIndex)
      cellData = columnProps.renderHeader(cellData, columnProps.name, rowData)
    }

    return <Cell>{cellData}</Cell>
  }

  renderColumn = (columnProps) => {
    return (
      <Column
        {...columnProps}
        flexGrow={0}
        key={columnProps.name}
        columnKey={columnProps.name}
        width={this.calculateColWidth(columnProps)}
        header={(cellConfig) => {
          return this.renderHeaderCell(cellConfig.rowIndex, columnProps)
        }}
        cell={(cellConfig) => {
          return this.renderCell(cellConfig.rowIndex, columnProps)
        }}
      />
    )
  }

  render() {
    return (
      <Table
        {...this.props}
        className={classNames({ busy: this.props.disabled })}
        isColumnResizing={this.state.isColumnResizing}
        onColumnResizeEndCallback={this.handleColumnResizeEnd}
        showScrollbarX={false}
      >
        {this.props.columns?.map(this.renderColumn)}
      </Table>
    )
  }
}

export default TableComponent
