const Ext = require('ext')
const GroupTabPanel = require('admin/view/GroupTabPanel')

const UserGroupPanel = Ext.define(null, {
  extend: GroupTabPanel,

  constructor(cfg = {}) {
    this.record = cfg.record

    this.items = [new (require('admin/view/UserTabPanel'))({ record: this.record })]

    this.callParent()
  }
})

module.exports = UserGroupPanel
