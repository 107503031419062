import * as hal from 'hal'
import getIn from 'lodash/get'
import setIn from 'lodash/set'
import { EVENT_DETAIL_REL, QUARANTINE_SEARCH_REL } from 'product/resources'
import initialState from 'quarantine/db'
import initialSearchState from 'quarantine/search/db'

import { createNextState } from '@reduxjs/toolkit'

import {
  QUARANTINE_SEARCH_CLEAR_DETAIL,
  QUARANTINE_SEARCH_CLEAR_ORIGINAL_RECIPIENTS,
  QUARANTINE_SEARCH_CLEAR_PREVIEW,
  QUARANTINE_SEARCH_CLEAR_RELEASE_PROGRESS,
  QUARANTINE_SEARCH_CLEAR_RESULTS,
  QUARANTINE_SEARCH_CLEAR_SCROLL_TO_INDEX,
  QUARANTINE_SEARCH_CLEAR_SELECTION,
  QUARANTINE_SEARCH_CLEAR_SOURCE,
  QUARANTINE_SEARCH_CLOSE_CONFIRMING_RELEASE,
  QUARANTINE_SEARCH_CONCAT_RESULTS,
  QUARANTINE_SEARCH_ENABLE_HIGHLIGHTING,
  QUARANTINE_SEARCH_INIT_DB,
  QUARANTINE_SEARCH_OPEN_CONFIRMING_RELEASE,
  QUARANTINE_SEARCH_SELECT_ROWS,
  QUARANTINE_SEARCH_SET_COLUMN_WIDTH,
  QUARANTINE_SEARCH_SET_DATE_RANGE,
  QUARANTINE_SEARCH_SET_DATE_RANGE_HIDDEN,
  QUARANTINE_SEARCH_SET_DATE_RANGE_SHOWN,
  QUARANTINE_SEARCH_SET_DETAIL,
  QUARANTINE_SEARCH_SET_DIRECTION,
  QUARANTINE_SEARCH_SET_LOCATION,
  QUARANTINE_SEARCH_SET_OFFSET,
  QUARANTINE_SEARCH_SET_ORIGINAL_RECIPIENTS,
  QUARANTINE_SEARCH_SET_PREVIEW,
  QUARANTINE_SEARCH_SET_QUERY,
  QUARANTINE_SEARCH_SET_RECIPIENTS_VALID,
  QUARANTINE_SEARCH_SET_RELEASE_FAILED,
  QUARANTINE_SEARCH_SET_RELEASE_SIZE,
  QUARANTINE_SEARCH_SET_RELEASE_SKIPPED,
  QUARANTINE_SEARCH_SET_RELEASE_SUCCESSFUL,
  QUARANTINE_SEARCH_SET_RESULTS,
  QUARANTINE_SEARCH_SET_RESULTS_QUERY,
  QUARANTINE_SEARCH_SET_SCROLL_TO_INDEX,
  QUARANTINE_SEARCH_SET_SOURCE,
  QUARANTINE_SEARCH_SET_START_DATE,
  QUARANTINE_SEARCH_SET_TIME_ZONE_KEY,
  QUARANTINE_SEARCH_SET_URI,
  QUARANTINE_SEARCH_START_LOADING,
  QUARANTINE_SEARCH_STOP_LOADING,
  QUARANTINE_SEARCH_TOGGLE_ATTACHMENT_PANEL
} from './actions'

const getBaseKeyPath = (companyKey) => ['companies', companyKey, QUARANTINE_SEARCH_REL]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getResultsKeyPath = (companyKey) => getKeyPath(companyKey, 'results')

const reducers = {
  [QUARANTINE_SEARCH_ENABLE_HIGHLIGHTING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'highlight'), action.enabled)
  },

  [QUARANTINE_SEARCH_OPEN_CONFIRMING_RELEASE]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'confirmingRelease'), true)
  },

  [QUARANTINE_SEARCH_CLOSE_CONFIRMING_RELEASE]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'confirmingRelease'), false)
  },

  [QUARANTINE_SEARCH_SET_DIRECTION]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'direction'),
      action.direction
    )
  },

  [QUARANTINE_SEARCH_SET_TIME_ZONE_KEY]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'timeZoneName'),
      action.timeZoneName
    )
  },

  [QUARANTINE_SEARCH_SET_QUERY]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'query'), action.query)
  },

  [QUARANTINE_SEARCH_SET_RESULTS_QUERY]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'resultsQuery'),
      action.query
    )
  },

  [QUARANTINE_SEARCH_INIT_DB]: (quarantineDb, action) => {
    return setIn(quarantineDb, getBaseKeyPath(action.companyKey), initialSearchState)
  },

  [QUARANTINE_SEARCH_SET_URI]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'uri'), action.uri)
  },

  [QUARANTINE_SEARCH_SET_START_DATE]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'startDate'),
      action.startDate
    )
  },

  [QUARANTINE_SEARCH_SET_DATE_RANGE]: (quarantineDb, action) => {
    const messages = getIn(quarantineDb, getBaseKeyPath(action.companyKey))

    messages.fromDate = action.fromDate
    messages.thruDate = action.thruDate

    return setIn(quarantineDb, getBaseKeyPath(action.companyKey), messages)
  },

  [QUARANTINE_SEARCH_SET_DATE_RANGE_SHOWN]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'dateRangeShown'), true)
  },

  [QUARANTINE_SEARCH_SET_DATE_RANGE_HIDDEN]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'dateRangeShown'), false)
  },

  [QUARANTINE_SEARCH_START_LOADING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [QUARANTINE_SEARCH_STOP_LOADING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [QUARANTINE_SEARCH_SET_DETAIL]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'detail'), action.detail)
  },

  [QUARANTINE_SEARCH_CLEAR_DETAIL]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'detail'), null)
  },

  [QUARANTINE_SEARCH_SET_RELEASE_SIZE]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'releaseSize'), action.size)
  },

  [QUARANTINE_SEARCH_SET_RELEASE_SUCCESSFUL]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'releaseSuccessful'),
      action.successful
    )
  },

  [QUARANTINE_SEARCH_SET_RELEASE_FAILED]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'releaseFailed'),
      action.failed
    )
  },

  [QUARANTINE_SEARCH_SET_RELEASE_SKIPPED]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'releaseSkipped'),
      action.skipped
    )
  },

  [QUARANTINE_SEARCH_CLEAR_RELEASE_PROGRESS]: (quarantineDb, action) => {
    const search = getIn(quarantineDb, getBaseKeyPath(action.companyKey))

    search.releaseSize = null
    search.releaseSuccessful = null
    search.releaseFailed = null
    search.releaseSkipped = null

    return setIn(quarantineDb, getBaseKeyPath(action.companyKey), search)
  },

  [QUARANTINE_SEARCH_SET_RECIPIENTS_VALID]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'recipientsValid'),
      action.valid
    )
  },

  [QUARANTINE_SEARCH_SET_ORIGINAL_RECIPIENTS]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'originalRecipients'),
      action.originalRecipients
    )
  },

  [QUARANTINE_SEARCH_CLEAR_ORIGINAL_RECIPIENTS]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'originalRecipients'), null)
  },

  [QUARANTINE_SEARCH_SET_RESULTS]: (quarantineDb, action) => {
    return setIn(quarantineDb, getResultsKeyPath(action.companyKey), action.results)
  },

  [QUARANTINE_SEARCH_CONCAT_RESULTS]: (quarantineDb, action) => {
    const results = getIn(quarantineDb, getResultsKeyPath(action.companyKey))

    let newResults

    if (results) {
      newResults = results.concat(action.moreResults)
    } else {
      newResults = action.moreResults
    }

    return setIn(quarantineDb, getResultsKeyPath(action.companyKey), newResults)
  },

  [QUARANTINE_SEARCH_SET_LOCATION]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'location'), action.location)
  },

  [QUARANTINE_SEARCH_SET_OFFSET]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'offset'), action.offset)
  },

  [QUARANTINE_SEARCH_SET_PREVIEW]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'preview'), action.preview)
  },

  [QUARANTINE_SEARCH_CLEAR_PREVIEW]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'preview'), null)
  },

  [QUARANTINE_SEARCH_CLEAR_RESULTS]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'results'), null)
  },

  [QUARANTINE_SEARCH_TOGGLE_ATTACHMENT_PANEL]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'attachmentPanelExpanded'),
      action.expanded
    )
  },

  [QUARANTINE_SEARCH_SELECT_ROWS]: (quarantineDb, action) => {
    const { companyKey, rows, selected } = action

    const results = getIn(quarantineDb, getResultsKeyPath(companyKey))

    return setIn(
      quarantineDb,
      getResultsKeyPath(action.companyKey),
      results.map((result) => {
        const found = rows.find((row) => {
          return hal.href(row, EVENT_DETAIL_REL) === hal.href(result, EVENT_DETAIL_REL)
        })

        if (!found) return result

        return Object.assign(result, { selected })
      })
    )
  },

  [QUARANTINE_SEARCH_CLEAR_SELECTION]: (quarantineDb, { companyKey }) => {
    const results = getIn(quarantineDb, getResultsKeyPath(companyKey))

    if (!results) return quarantineDb

    return setIn(
      quarantineDb,
      getResultsKeyPath(companyKey),
      results.map((result) => {
        delete result.selected
        return result
      })
    )
  },

  [QUARANTINE_SEARCH_SET_COLUMN_WIDTH]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'columns').concat([action.columnKey, 'width']),
      action.width
    )
  },

  [QUARANTINE_SEARCH_CLEAR_SCROLL_TO_INDEX]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'scrollToIndex'), null)
  },

  [QUARANTINE_SEARCH_SET_SCROLL_TO_INDEX]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'scrollToIndex'),
      action.scrollToIndex
    )
  },

  [QUARANTINE_SEARCH_SET_SOURCE]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'source'), action.source)
  },

  [QUARANTINE_SEARCH_CLEAR_SOURCE]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'source'), null)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
