const isAscii = require('is-ascii')
const Ext = require('ext')
const t = require('translate')
const Alert = require('admin/util/Alert').default
const logger = require('system/logger').default
const confirmDiscardChanges = require('admin/util/confirmDiscardChanges')
const TextRow = require('admin/util/TextRow')
const LimitedTextArea = require('admin/component/form/LimitedTextArea')
const HtmlEditor = require('admin/component/form/HtmlEditor')

// see https://tools.ietf.org/html/rfc5322#section-2.1.1
const PLAIN_TEXT_MAX_LENGTH = 78
const HTML_TEXT_MAX_LENGTH = 998

const VALID_MACROS = ['date', 'recipient', 'sender', 'subject']

const replaceNewLinesWithWhitespace = (anything) =>
  String(anything)
    .replace(/<br>/g, '')
    .replace(/\r\n?|\n/g, ' ')

const templateProperties = function (encoded) {
  if (encoded) {
    return {
      open: '{{',
      close: '}}',
      matches: /\{\{([^}]+)\}\}/g
    }
  }
  return {
    open: '{{{',
    close: '}}}',
    matches: /\{\{\{([^}]+)\}\}\}/g
  }
}

const formatValidMacros = function (cfg = {}) {
  const m = templateProperties(cfg.encoded)
  return VALID_MACROS.map((macro) => `${m.open}${macro}${m.close}`).join(', ')
}

const validateTemplate = function (v = '', cfg = {}) {
  const m = templateProperties(cfg.encoded)
  const matches = v.match(m.matches)
  if (!matches) return true

  return matches.every((match) =>
    VALID_MACROS.some((macro) => `${m.open}${macro}${m.close}` === match)
  )
}

const TemplateEditor = Ext.define(null, {
  extend: Ext.form.FormPanel,

  onSaveClick() {
    if (!isAscii(this.textEditor.getValue()) || !isAscii(this.htmlEditor.getValue())) {
      return this.displayUnicodeConfirmation()
    }
    return this.doSave()
  },

  displayUnicodeConfirmation() {
    return Ext.Msg.show({
      title: t('Warning'),
      msg: t(
        'If this template is to be used as preamble or a footer it might not be applied to some emails because it contains some non-ASCII characters.'
      ),
      buttons: {
        ok: t('Continue'),
        cancel: t('Cancel')
      },
      fn(btn) {
        if (btn === 'ok') {
          return this.doSave()
        }
      },
      scope: this,
      icon: Ext.Msg.WARNING,
      minWidth: Ext.Msg.minWidth
    })
  },

  doSave() {
    const record = this.templates.getSelectionModel().getSelected()
    if (this.htmlEditor.isDirty()) {
      const html = this.htmlEditor.getValue()
      if (validateTemplate(html, { encoded: true })) {
        record.set('html', html)
      } else {
        Alert.alert(
          '',
          t('You can only use the macros {0}', [formatValidMacros({ encoded: true })])
        )
        return
      }
    }

    record.set('text', this.textEditor.getValue())

    Ext.MessageBox.wait(t('Saving...'))
    return record.save(null, {
      success() {
        return record.store.reload()
      },
      error(record, response, options, err) {
        return logger.error(err)
      },
      complete() {
        return Ext.MessageBox.hide()
      }
    })
  },

  constructor(cfg = {}) {
    this.resetButton = new Ext.Button({
      disabled: true,
      text: t('Reset'),
      scope: this,
      handler() {
        const record = this.templates.getSelectionModel().getSelected()
        this.textEditor.setValue(record.get('text'))
        return this.htmlEditor.setValue(record.get('html'))
      }
    })

    this.saveButton = new Ext.Button({
      disabled: true,
      formBind: true,
      handler: this.onSaveClick,
      scope: this,
      text: t('Save'),
      cls: 'primary'
    })

    const updateLineCharCount = () => {
      const coordinates = this.textEditor.getCoordinates()

      if (coordinates) {
        return this.plainTextPanel.setTitle(this.getPlainTextPanelTitle(coordinates))
      }
    }

    const maxTextAreaWidth = TextRow.computeWidth(PLAIN_TEXT_MAX_LENGTH, [
      'x-form-textarea',
      'x-form-field',
      'has-line-max-length'
    ])

    const maxLine = new Ext.BoxComponent({
      width: maxTextAreaWidth,
      cls: 'textarea-max-line'
    })

    this.textEditor = new LimitedTextArea({
      disabled: true,
      lineMaxLength: PLAIN_TEXT_MAX_LENGTH,
      validator(v) {
        updateLineCharCount()

        if (this.isDirty() && this.isOverflown()) {
          return t('Max line width is {0} characters', [this.lineMaxLength])
        }
        if (validateTemplate(v, { encoded: false })) {
          return true
        }
        return t('You can only use the macros {0}', [
          formatValidMacros({ encoded: false })
        ])
      },

      insertMacro(macroName) {
        return this.insertAtCursor(`{{{${macroName}}}}`)
      }
    })

    this.htmlEditor = new HtmlEditor({
      disabled: true,
      isDirty() {
        if (this.disabled || !this.rendered) {
          return false
        }
        let newValue
        const oldValue = replaceNewLinesWithWhitespace(this.originalValue)

        // getValue() can trigger error mentioned in SCL-3124
        // hence have it more robust within a try block
        try {
          newValue = replaceNewLinesWithWhitespace(this.getValue())
        } catch (error) {
          // ignore error
        }

        return newValue !== oldValue
      },
      listeners: {
        beforesync(editor) {
          return editor.validate()
        }
      },
      validateValue(v) {
        const words = v.split(/\s/)

        const overflow =
          words != null ? words.some((l) => l.length > HTML_TEXT_MAX_LENGTH) : undefined

        if (overflow) {
          this.markInvalid(
            t('Max line width is {0} characters of HTML.', [HTML_TEXT_MAX_LENGTH])
          )
          return false
        }
        if (validateTemplate(v, { encoded: true })) {
          return true
        }
        this.markInvalid(
          t('You can only use the macros {0}', [formatValidMacros({ encoded: true })])
        )
        return false
      },
      validate() {
        if (this.disabled || this.validateValue(this.processValue(this.getRawValue()))) {
          this.clearInvalid()
        }
        return true
      },
      insertMacro(macroName) {
        return this.insertAtCursor(`{{${macroName}}}`)
      }
    })

    this.textEditor.on('focus', this.updateFocus, this)
    this.htmlEditor.on('editorevent', this.updateFocus, this)

    this.plainTextPanel = new Ext.Panel({
      title: this.getPlainTextPanelTitle(),
      flex: 0.8,
      layout: 'fit',
      border: false,
      bodyCfg: {
        autoScroll: false,
        style: {
          position: 'relative'
        }
      },
      items: [this.textEditor, maxLine]
    })

    this.htmlPanel = new Ext.Panel({
      title: t('HTML Template'),
      flex: 1,
      layout: 'fit',
      border: false,
      items: this.htmlEditor
    })

    cfg = Ext.applyIf(cfg, {
      layout: 'vbox',
      loadMask: true,
      monitorValid: true,
      trackResetOnLoad: true,
      layoutConfig: {
        align: 'stretch',
        pack: 'start'
      },
      items: [this.plainTextPanel, this.htmlPanel],
      buttonAlign: 'center',
      buttons: [this.resetButton, this.saveButton],
      tbar: [
        {
          disabled: true,
          ref: '../insertMacroButton',
          scope: this,
          text: t('Insert Macro'),
          menu: {
            plain: true,
            showSeparator: false,
            items: [
              { text: t('Sender'), name: 'sender' },
              { text: t('Recipient'), name: 'recipient' },
              { text: t('Subject'), name: 'subject' },
              { text: t('Date'), name: 'date' }
            ],
            defaults: {
              handler(el) {
                if (!this.lastFocus) return
                if (!this.lastFocus.insertMacro) return

                this.lastFocus.insertMacro(el.name)
              },
              iconCls: 'insert-macro',
              scope: this
            }
          }
        }
      ]
    })

    this.callParent([cfg])
    this.on(
      'clientvalidation',
      function (form, valid) {
        const isDirty = form.getForm().isDirty()
        if (isDirty) {
          form.addClass('smx-dirty')
        } else {
          form.removeClass('smx-dirty')
        }
        valid = valid && isDirty
        form.resetButton.setDisabled(!isDirty)
        return form.saveButton.setDisabled(!this.module.canSave() || !valid)
      },
      this
    )

    this.templates.getSelectionModel().on('selectionchange', this.updateValues, this)
    const store = this.templates.getStore()
    store.on('datachanged', this.updateValues, this)
    return this.on(
      'destroy',
      function () {
        this.templates.getSelectionModel().un('selectionchange', this.updateValues, this)
        return store.un('datachanged', this.updateValues, this)
      },
      this
    )
  },

  getPlainTextPanelTitle(coordinates) {
    const title = 'Plain Text Template'

    if (coordinates) {
      const [line, column] = Array.from(coordinates)
      // + 1 because column number is one above length,
      // they start with 1, so will end with 79 when length is 78
      const titleParams = [title, line, column, PLAIN_TEXT_MAX_LENGTH + 1]
      return t(
        '<span class="right-hint">Line {1}, Column {2} (of {3})</span> {0}',
        titleParams
      )
    }
    return t(title)
  },

  isDirty() {
    return this.form != null ? this.form.isDirty() : undefined
  },

  preventClose(retry) {
    if (!this.allowClose && this.isDirty()) {
      confirmDiscardChanges(() => {
        this.allowClose = true
        return retry()
      })
      return true
    }
  },

  updateFocus(lastFocus) {
    this.lastFocus = lastFocus
  },
  // handled in parameter

  updateValues() {
    if (!this.rendered) {
      return
    } // htmlEditor doesn't behave if it's not rendered

    const record = this.templates.getSelectionModel().getSelected()
    if (!record || record.isNew()) {
      this.htmlEditor.setValue('')
      this.htmlEditor.setDisabled(true)
      this.textEditor.setValue('')
      this.textEditor.setDisabled(true)
      return this.insertMacroButton.setDisabled(true)
    }
    const html = record.get('html')
    const text = record.get('text')
    this.htmlEditor.setValue(html)
    this.htmlEditor.originalValue = this.htmlEditor.getValue()
    this.htmlEditor.setDisabled(false)
    this.textEditor.setValue(text)
    this.textEditor.originalValue = this.textEditor.getValue()
    this.textEditor.setDisabled(false)
    return this.insertMacroButton.setDisabled(false)
  }
})

module.exports = TemplateEditor
