const Ext = require('ext')
const CompanyStore = require('admin/data/company/CompanyStore')
const CustomerRecord = require('admin/data/company/CustomerRecord')
const CustomerResource = require('admin/business/CustomerResource')

const CustomerStore = Ext.define(null, {
  extend: CompanyStore,

  resourceName: 'customers',
  sortInfo: {
    field: 'displayName',
    direction: 'ASC'
  },

  record: CustomerRecord,

  module() {
    return CustomerResource
  }
})

module.exports = CustomerStore
