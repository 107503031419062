import Ext from 'ext'
import logger from 'system/logger'

const TabPanel = Ext.define(null, {
  extend: Ext.TabPanel,

  activeTab: 0,
  border: false,
  layoutOnTabChange: true,
  bodyCfg: {
    cls: 'x-tab-panel-body smx3-tab-panel'
  },

  // Adds tabs in the order they are defined
  updateItems() {
    if (this.isDestroyed) return

    // due to this function being called from modified ExtJS async event handling
    // we have to manually surround this with a try catch block to trap and
    // display any errors if any, see SCL-3542
    try {
      const tabs = this.getItems()

      tabs.forEach((tab, i) => {
        if (!this.items.contains(tab)) {
          const previous = tabs[i - 1]

          if (previous) {
            this.insert(this.items.indexOf(previous) + 1, tab)
          } else {
            this.insert(0, tab)
          }
        }
      })

      if (this.getActiveTab() === null) {
        this.setActiveTab(0)
      }
    } catch (exc) {
      logger.error(exc)
    }
  },

  // Useful for replacing an existing tab which has changed during HMR
  // How to use it? See CompanyGroupPanel
  replaceTab(oldTab, newTab) {
    if (!newTab) return // skip

    const index = this.items.findIndexBy((item) => item === oldTab)

    if (oldTab?.unmount) oldTab.unmount() // must be a react container

    this.remove(oldTab, true)

    this.insert(index, newTab)
    this.setActiveTab(index)

    // for chaining
    return newTab
  }
})

export default TabPanel
