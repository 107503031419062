const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const ListManagementRecord = require('smartrules/ListManagementRecord')
const ListManagement = require('smartrules/ListManagement')

const ListManagementStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'datasets',
  sortInfo: {
    field: 'name',
    direction: 'ASC'
  },

  record: ListManagementRecord,

  module() {
    return ListManagement
  }
})

module.exports = ListManagementStore
