import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { Button, ButtonToolbar } from 'react-bootstrap'
import t from 'translate'

class Pagination extends PureComponent {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    disableNext: PropTypes.bool,
    disablePrev: PropTypes.bool
  }

  render() {
    return (
      <div className='pagination'>
        <ButtonToolbar>
          <Button
            onClick={this.props.onPrev}
            disabled={this.props.disablePrev}
            className='button-prev'
          >
            {t('Previous')}
          </Button>
          <Button
            onClick={this.props.onNext}
            disabled={this.props.disableNext}
            className='button-next'
          >
            {t('Next')}
          </Button>
        </ButtonToolbar>
      </div>
    )
  }
}

export default Pagination
