const Ext = require('ext')
const ClauseTreeMember = require('smartrules/designer/ClauseTreeMember')
const Component = require('smartrules/designer/Component')
const GroupHeader = require('smartrules/designer/GroupHeader')
const DropZone = require('smartrules/designer/DropZone')
const Clause = require('smartrules/designer/Clause')

/**
A group is a composition of atomic clauses or other groups, contained in a
header part and a panel part. The header part can contain an atomic clause or an
arbitrary component, for example representing an antecedent or prefix operator.
The panel part can contain other clause-tree members, for example representing
consequent clauses.
*/

const Group = Ext.define(null, {
  extend: ClauseTreeMember,

  constructor(cfg = {}) {
    let dropZone, groupHeader, groupPanel

    const items = []
    this.listStore = cfg.listStore
    cfg = Ext.applyIf(cfg, {
      border: false,
      cls: 'smartrules-group' + (cfg.root ? '' : ' smartrules-nested-group'),
      clauseTreeType: null,
      firstJoinWord: '',
      defaultJoinWord: '',
      members: [],
      defaults: {
        border: false,
        margins: '0'
      },
      padding: '0',
      layout: 'auto',
      items
    })

    this.groupPanel = groupPanel = new Component({
      bodyCssClass: 'smartrules-group-body',
      items: cfg.members
    })

    this.groupHeader = groupHeader =
      cfg.groupHeader ||
      new GroupHeader({
        group: this,
        useSpacer: cfg.useSpacer
      })

    if (cfg.root) {
      dropZone = new DropZone({
        html: cfg.dropLabel
      })
      this.dndHint = dropZone
    } else {
      items.push(groupHeader)
      this.dndHint = groupHeader.dropZone
    }

    items.push(groupPanel)

    if (dropZone) {
      items.push(dropZone)
    }

    this.callParent([cfg])

    this.on('smartrules-drop', this.updateJoinWords, this)
    this.on(
      'smartrules-drop',
      function () {
        this.items.each((item) => item.fireEvent('smartrules-drop'))
      },
      this
    )
  },

  addClause(cmp, index) {
    if (this.isDestroyed) return

    const SmartRule = require('smartrules/designer/SmartRule')

    const { items } = this.groupPanel
    const smartrule = this.findParentBy((n) => n instanceof SmartRule)

    if (Array.isArray(cmp)) {
      cmp.reverse().forEach(function (c) {
        this.addClause(c, index)
      }, this)
      return
    }

    if (index === undefined) {
      index = items.getCount()
    }

    cmp = Ext.applyIf(cmp, { listStores: this.listStores })
    this.groupPanel.insert(index, cmp)

    if (smartrule) {
      smartrule.fireEvent('smartrules-drop')
      smartrule.fireEvent('dirty')
    }

    this.dndHint.hide()
    this.groupPanel.doLayout()
    this.groupHeader.doLayout()
  },

  abandon() {
    const nestables = this.groupHeader.find('nestable', true)
    const grandParentGroup = this.getParentGroup()
    const pos = grandParentGroup.locateClauseTreeMember(this)
    grandParentGroup.addClause(nestables[0], pos)
    nestables[0].adaptToGrouping(false)
    this.destroy()
  },

  move(clause, moveTo, after) {
    const currentIndex = this.locateClauseTreeMember(clause)
    const newIndex = currentIndex >= 0 && moveTo > currentIndex ? moveTo - 1 : moveTo
    if (moveTo !== currentIndex) {
      const parentGroup = clause.getParentGroup()
      this.addClause(clause, after ? newIndex + 1 : newIndex)
      if (!parentGroup.root) {
        const count = parentGroup.countClauseTreeMembers()
        if (count === 1) {
          parentGroup.abandon()
        }
      }
      this.getTree().reflow()
      this.groupPanel.doLayout()
      this.getSmartRule().fireEvent('smartrules-drop')
    }
  },

  countClauseTreeMembers(onlyChildren) {
    if (!onlyChildren) {
      onlyChildren = true
    }
    return this.findBy((n) => n instanceof ClauseTreeMember).length
  },

  locateClauseTreeMember(cmp) {
    const { items } = this.groupPanel
    return items.findIndex('id', cmp.id)
  },

  isEmpty() {
    return this.countClauseTreeMembers() === 0
  },

  setJoinWord(word) {
    this.groupHeader.setJoinWord(word)
  },

  isValid() {
    const len = this.groupPanel.items.length
    return (
      len > 0 && this.groupPanel.items.getRange().every((item) => item.isValid(), this)
    )
  },

  clear() {
    const smartrule = this.getSmartRule()
    this.groupPanel.removeAll()
    this.dndHint.show()
    smartrule.fireEvent('smartrules-drop')
  },

  reflow() {
    const items = this.groupPanel.items.getRange()
    const panelEl = this.groupPanel.getEl().child('> .x-panel-bwrap > .x-panel-body')

    items.forEach(function (item, i) {
      const itemEl = item.getEl()
      if (i === 0) {
        itemEl.appendTo(panelEl)
      } else {
        itemEl.insertAfter(items[i - 1].getEl())
      }
      if (Ext.isFunction(item.reflow)) {
        item.reflow()
      }
    })

    this.dndHint[items.length === 0 ? 'show' : 'hide']()
  },

  updateJoinWords(
    firstJoinWord = this.getFirstJoinWord(),
    joinWord = this.getJoinWord()
  ) {
    let first = true

    this.groupPanel.items.each(function (item) {
      if (first && (item instanceof Clause || item instanceof Group)) {
        if (item && item.setJoinWord) {
          item.setJoinWord(firstJoinWord)
        }

        first = false
      } else {
        if (item && item.setJoinWord) {
          item.setJoinWord(joinWord)
        }
      }

      if (item instanceof Group && item && item.updateJoinWords) {
        item.updateJoinWords()
      }
    })
  },

  getFirstJoinWord() {
    return this.firstJoinWord
  },

  getJoinWord() {
    return this.defaultJoinWord
  }
})

module.exports = Group
