import React, { Component } from 'react'

import { Panel, Table } from 'react-bootstrap'

class Tab extends Component {
  render() {
    return (
      <Panel className='help'>
        <h4>SMX Quarantine Introduction</h4>
        <p>
          You can choose to quarantine inbound messages within SMX Secure Email Gateway.
          Messages which have been classified as Spam or Gray Mail, or messages which
          match a custom rule within SMX Smart Rules can be kept for review prior to being
          released.
          <br />
          By default, filtered messages are rejected, however as an administrator, you can
          set up Quarantine policies to send filtered messages to the quarantine instead.
        </p>

        <h6>Note</h6>
        <ul>
          <li>
            Quarantined messages are stored for 30 days, after which they are removed from
            the quarantine.
          </li>
          <li>
            The SMX Quarantine supports quarantine Spam and Gray Mail – messages
            classified as Threats (viruses) cannot be quarantined.
          </li>
        </ul>

        <h5>Searching for Messages</h5>
        <p>
          To search for quarantined messages, you can enter the term you are looking for
          in the search box and the search engine will attempt to match the query string
          against all metadata associated with your email quarantine.
        </p>
        <p>
          Wildcards are supported in the search box via the special characters
          &quot;*&quot; (asterisk) for multi-character globbing
        </p>
        <p>
          For example, putting the word <code>bob*</code> in the search box will match
          emails from email addresses starting with bob (e.g. bob@example.com as well as
          bob.smith@any.domain.com), as well as any, recipient and/or subject starting
          with &quot;bob&quot;.
        </p>
        <p>
          The following table describes the metadata that is indexed and searchable for
          all emails:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Search Term</th>
              <th>Search String Format</th>
              <th>Parsed Form</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>subject</code>
              </td>
              <td>None</td>
              <td>Email Headers</td>
            </tr>
            <tr>
              <td>
                <code>sender</code>
              </td>
              <td>RFC email address</td>
              <td>Envelope MAIL FROM</td>
            </tr>
            <tr>
              <td>
                <code>recipient</code>
              </td>
              <td>RFC email address</td>
              <td>Envelope RCPT TO</td>
            </tr>
            <tr>
              <td>
                <code>message-id</code>
              </td>
              <td>RFC email address</td>
              <td>Email Headers</td>
            </tr>
          </tbody>
        </Table>
        <p>
          As you type in the search box your browser will attempt to auto-complete valid
          metadata terms to search across. You can also specify the time-zone and date
          range to apply your query to.
        </p>

        <h5>Previewing Quarantined Messages</h5>
        <p>
          Message preview allows users to view and analyse messages before downloading or
          releasing. The message preview contains the complete set of information stored
          for that message.
        </p>
        <p>
          To preview a quarantined message, select the message from the search results to
          open the message preview.
        </p>
        <p>
          Note: to protect the system and users, the message preview blocks remote
          content. This means however that previewed messages may look slightly different
          to the original.
        </p>
        <p>
          Clicking the Back button takes you back to the previous search results view.
        </p>

        <h5>Downloading Quarantined Messages</h5>
        <p>
          To download a quarantined message, select the message from the search results to
          preview the message. From within the message preview select the download button.
          <br />
          From the message preview you can choose to download the message in .eml (RFC822
          MIME) format by clicking the Download button.
          <br />
          Clicking the Back button takes you back to the previous search results view.
        </p>

        <h5>Release Quarantined Messages</h5>
        <p>
          Authorised users can release quarantined messages. Messages can be released
          individually, or multiple messages can be release at once.{' '}
        </p>

        <h6>Releasing a single message: </h6>
        <p>
          Quarantined messages can be released within the search results view by selecting
          the tick box next to the desired message and clicking the Release button.
          Alternatively, a message can be released by previewing the desired message and
          clicking the Release button from preview screen.
        </p>
        <p>
          Note: In both cases, when you release an individual message you are prompted
          with the option to modify the recipient(s) of the released message. This is
          beneficial to administrators when additional message analysis is required prior
          to releasing to original recipients.
        </p>

        <h6>Releasing multiple messages:</h6>
        <p>
          Multiple messages can be released by selecting the messages from the search
          results view. Once the desired messages have been selected click the Release
          button to release.
        </p>
        <p>
          Note: Messages released in bulk are released to the original recipients without
          the option to modify recipients.
        </p>

        <h5>Advanced Searching</h5>
        <p>
          You can confine your query to specific metadata terms and by combining terms you
          can refine your search even further. To search across specific metadata fields,
          enter the search term followed by a colon then your search string. For example,
          the following query will search for all messages from any email address starting
          with &quot;bob&quot; and containing the word &quot;meeting&quot; in the subject:
        </p>
        <p>
          <code>sender:bob* subject:meeting</code>
        </p>
        <p>
          The above query is equivalent to <code>sender:bob*</code> and{' '}
          <code>subject:meeting*</code>.
        </p>
        <p>
          If the search string has space, it needs to be quoted with double quotes
          including the keyword. For example, the following search query will match all
          emails with subjects containing the phrase &quot;monday meeting&quot;:
        </p>
        <p>
          <code>&quot;subject:monday meeting&quot;</code>
        </p>
      </Panel>
    )
  }
}

export default Tab
