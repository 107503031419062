import { ARCHIVING_UNLOAD } from 'archiving/actions'
import { createNextState } from '@reduxjs/toolkit'
import initialState from 'archiving/db'
import membershipReducers from 'archiving/membership/reducers'
import messagesReducers from 'archiving/messages/reducers'

const reducers = {
  [ARCHIVING_UNLOAD]: (archivingDb, { companyKey }) => {
    delete archivingDb.companies[companyKey]
    return archivingDb
  }
}

export default function (moduleDb = initialState, action = {}) {
  const reducer = reducers[action.type]

  if (reducer) {
    return createNextState(moduleDb, (draftState) => reducer(draftState, action))
  }

  // else delegate action further up
  return [messagesReducers, membershipReducers].reduce((db, reducer) => {
    return reducer(db, action)
  }, moduleDb)
}
