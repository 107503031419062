const Ext = require('ext')
const t = require('translate')
const ComboBox = require('admin/component/form/ComboBox')
const TimeZone = require('admin/time/TimeZone')

const TimeZoneComboBox = Ext.define(null, {
  extend: ComboBox,

  displayField: 'name',
  hiddenName: 'timeZone',
  valueField: 'value',
  typeAhead: true,
  mode: 'local',
  lazyInit: false,
  allowBlank: false,
  triggerAction: 'all',
  forceSelection: true,
  selectOnFocus: true,
  fieldLabel: t('Time Zone'),
  width: 110,
  listWidth: 150,

  initComponent() {
    const timezones = TimeZone.SORTED_TIMEZONES.map((tz) => {
      return [tz.zoneName, tz.label]
    })

    this.store = new Ext.data.ArrayStore({
      fields: ['value', 'name'],
      data: timezones
    })

    this.value = TimeZone.getDefaultTimeZoneName()

    this.callParent()
  },

  getTimeZone() {
    return TimeZone.getTimeZoneByName(this.getValue())
  }
})

module.exports = TimeZoneComboBox
