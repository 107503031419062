import React, { Component } from 'react'
import {
  configuring,
  confirmingConfiguration,
  confirmingDeletion,
  creating
} from '../subs'
import keycloak, { isKeycloakAuthenticated } from 'system/keycloak'

import Configure from './form/Configure'
import ConfirmConfigure from './confirm/Configure'
import ConfirmDelete from './confirm/Delete'
import Create from './form/Create'
import Grid from 'components/Grid'
import PropTypes from 'prop-types'
import Table from './Table'
import Toolbar from './toolbar/Toolbar'
import { connect } from 'react-redux'

const requireAuthentication = () => !keycloak || !isKeycloakAuthenticated()

class Providers extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired,
    configurable: PropTypes.bool,
    allowPrivate: PropTypes.bool,
    allowMappingPolicy: PropTypes.bool
  }

  render() {
    let form

    if (this.props.creating) {
      form = (
        <Create
          allowPrivate={this.props.allowPrivate}
          allowMappingPolicy={this.props.allowMappingPolicy}
          companyKey={this.props.companyKey}
        />
      )
    } else if (this.props.configuring) {
      form = (
        <Configure
          allowPrivate={this.props.allowPrivate}
          allowMappingPolicy={this.props.allowMappingPolicy}
          companyKey={this.props.companyKey}
          requireAuthentication={requireAuthentication()}
        />
      )
    } else if (this.props.confirmingConfiguration) {
      form = <ConfirmConfigure companyKey={this.props.companyKey} />
    } else if (this.props.confirmingDeletion) {
      form = <ConfirmDelete companyKey={this.props.companyKey} />
    }

    return (
      <Grid>
        <Toolbar
          configurable={this.props.configurable}
          requireAuthentication={requireAuthentication()}
          store={this.props.store}
          companyKey={this.props.companyKey}
        />
        <Table
          width={this.props.width}
          height={this.props.height}
          configurable={this.props.configurable}
          store={this.props.store}
          companyKey={this.props.companyKey}
          allowMappingPolicy={this.props.allowMappingPolicy}
        />
        {form}
      </Grid>
    )
  }
}

const mapDbToProps = (db, props) => ({
  creating: creating(db, props.companyKey),
  configuring: configuring(db, props.companyKey),
  confirmingConfiguration: confirmingConfiguration(db, props.companyKey),
  confirmingDeletion: confirmingDeletion(db, props.companyKey)
})

export default connect(mapDbToProps)(Providers)
