import Dialog, { FORM_NAME } from 'quarantine/mail_targets/views/form/Dialog'
import React, { Component } from 'react'
import { change, touch } from 'redux-form'
import {
  configTargetEtag,
  configTargetMembership,
  configTargetName,
  configTargetUpdateMembersLink,
  formDisabled,
  loading,
  mailTargetConfiguration,
  uri
} from 'quarantine/mail_targets/subs'
import { stopConfiguring, updateMailTarget } from 'quarantine/mail_targets/actions'

import Field from 'components/form/Field'
import MembershipList from 'membership/views/List'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

// note that the membership list is full with state handling inside
// which goes against the redux principle to have them all stored in app state
// that said, the list of members isn't wired with the redux-form and hence
// values require special treatment, hence more complicated subscribers and the
// onMembersChange callback
// todo: in near future, move membership list states to app db.

class ConfigureForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.updateMailTarget(
      this.props.uri,
      this.props.configTargetUpdateMembersLink,
      // when undefined, pick the defaults
      // todo once we have the real api, probably not necessary anymore
      // like the problem we have in the selectedMailTargetMembership selector
      values.members || this.getAllNamesOfEnabledMembers(),
      this.props.configTargetEtag
    )
  }

  getAllNamesOfEnabledMembers() {
    return this.props.configTargetMembership.getAllNamesEnabled()
  }

  onMembersChange = () => {
    // unfortunately we will have to set this one members form value
    // manually since it manages states inside without changing app db
    this.props.changeMembers(this.getAllNamesOfEnabledMembers())
  }

  render() {
    const title = t('Configure {0}', [this.props.configTargetName])

    return (
      <Dialog
        allowPristine
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        mailTarget={this.props.mailTargetConfiguration}
        title={title}
        store={this.props.store}
        submitButtonText={t('Configure')}
        companyKey={this.props.companyKey}
      >
        <Field
          name='members'
          disabled={this.props.disabled}
          label={t('Members')}
          store={this.props.store}
          componentClass={MembershipList}
          input={{
            collapsible: true,
            membership: this.props.configTargetMembership,
            disabled: this.props.disabled,
            onChange: this.onMembersChange
          }}
        />
      </Dialog>
    )
  }
}

const mapDbToProps = (db, props) => ({
  disabled: formDisabled(db, props.companyKey),
  loading: loading(db, props.companyKey),
  mailTargetConfiguration: mailTargetConfiguration(db, props.companyKey),
  configTargetMembership: configTargetMembership(db, props.companyKey),
  configTargetUpdateMembersLink: configTargetUpdateMembersLink(db, props.companyKey),
  configTargetName: configTargetName(db, props.companyKey),
  configTargetEtag: configTargetEtag(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  changeMembers: (members) => {
    dispatch(change(FORM_NAME, 'members', members))
    dispatch(touch(FORM_NAME, 'members'))
  },

  onCancel: () => dispatch(stopConfiguring(initialProps.companyKey, initialProps.action)),

  updateMailTarget: (uri, updateMembersLink, members, etag) => {
    dispatch(
      updateMailTarget(initialProps.companyKey, uri, updateMembersLink, members, etag)
    )
  }
})

export default connect(mapDbToProps, mapActionsToProps)(ConfigureForm)
