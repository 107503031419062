const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const SignatureManagementPanel = require('admin/view/SignatureManagementPanel')
const SignatureRecord = require('admin/data/SignatureRecord')

const SignatureManagementResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.SignatureManagement',
  translations: {
    title: t('Mail Footer'),
    plural: t('Mail Footers'),
    new: t('New Mail Footer'),
    open: t('Mail Footer'),
    list: t('Mail Footers')
  },
  name: t('Mail Footers'),
  code: 'MAIL_FOOTER',
  resourceId: 'SIGNATURE_MANAGEMENT',
  resourceName: 'policysets',

  listPanel: SignatureManagementPanel,

  record: SignatureRecord
})

module.exports = SignatureManagementResource
