import React, { Component } from 'react'

import Confirmation from 'components/dialog/Confirmation'
import EmailInput from 'components/legacy/Email'
import PropTypes from 'prop-types'
import t from 'translate'

const title = t('Release Message')
const warning = t('Edit recipients')
const help = t('One email address per line.')
const label = t('Recipients')
const submitButtonText = t('Release')

function recipientsToArray(recipients) {
  return recipients.split('\n').filter((r) => r.length > 0)
}

function recipientsToString(recipients) {
  return recipients?.join('\n')
}

class ReleasePrompt extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRecipientsValidityChange: PropTypes.func.isRequired,
    recipientsValid: PropTypes.bool.isRequired,
    originalRecipients: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool.isRequired,
    released: PropTypes.bool
  }

  handleSubmit = (e) => {
    // this is required as Confirmation uses Form submit
    e.preventDefault()

    const emails = e.target.email.value

    this.props.onSubmit(recipientsToArray(emails))
  }

  renderAlreadyReleased = () => {
    return (
      <h2 className='col-xs-9 col-xs-offset-3' style={{ fontStyle: 'italic' }}>
        {t('Warning: this message has already been released')}
      </h2>
    )
  }

  render = () => {
    // pass recipients validity in the form accepted by EmailInput/FormInput
    // this is the only way to initialize the form's validation state
    const validity = { valid: this.props.recipientsValid }

    return (
      <Confirmation
        modalTitle={title}
        headerText={warning}
        submitButtonText={submitButtonText}
        cancelDisabled={this.props.disabled}
        submitDisabled={this.props.disabled || !this.props.recipientsValid}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      >
        {this.props.released && this.renderAlreadyReleased()}
        <EmailInput
          multiple
          required
          validity={validity}
          onValidityChange={this.props.onRecipientsValidityChange}
          value={recipientsToString(this.props.originalRecipients)}
          label={label}
          help={help}
          wrapperClassName='col-sm-8'
        />
      </Confirmation>
    )
  }
}

export default ReleasePrompt
