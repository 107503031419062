const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')

const NoteNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'note-new-panel',

  module() {
    return require('admin/business/NoteResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems() {
    return [
      {
        fieldLabel: t('Summary'),
        name: 'noteName',
        maxLength: 2147483647,
        allowBlank: false,
        xtype: 'textfield',
        width: 350
      },
      {
        fieldLabel: t('Detail'),
        name: 'noteInfo',
        maxLength: 2147483647,
        xtype: 'textarea',
        width: 350
      },
      {
        name: 'noteDate',
        xtype: 'hidden',
        value: new Date(),
        width: 350
      }
    ]
  }
})

module.exports = NoteNewPanel
