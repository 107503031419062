const User = require('admin/core/User')
const CurrencyResource = require('admin/business/CurrencyResource')

// the possibly unusual thing about this function is that it only outputs the currency
// if there is a currency choice
// The rounding could probably be done better
// todo assert input spec (amount must be a number)
// todo port to ES6
const format = function (currency, amount) {
  amount = Math.round(amount * 100) / 100

  if (amount === Math.floor(amount)) {
    amount = `${amount}.00`
  } else if (amount * 10 === Math.floor(amount * 10)) {
    amount = `${amount}0`
  }

  amount = String(amount)

  let [whole, sub] = Array.from(amount.split('.'))
  if (sub == null) {
    sub = '00'
  }
  const r = /(\d+)(\d{3})/
  while (r.test(whole)) {
    whole = whole.replace(r, '$1,$2')
  }

  amount = `${whole}.${sub}`

  // not sure if this is still needed.
  // couldn't find a situation where currency might be null.
  if (currency === null && CurrencyResource.currencyChoiceExists()) {
    currency = User.getDefaultCurrency()
  }

  if (currency != null) {
    return `${currency} ${amount}`
  }
  return amount
}

module.exports = format
