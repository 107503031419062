const Ext = require('ext')
const t = require('translate')

const RowEditor = Ext.define(null, {
  extend: Ext.ux.grid.RowEditor,

  allowBlank: false,
  cancelText: t('Cancel'),
  errorText: t('Errors'),
  saveText: t('Save'),
  hideTooltip: Ext.emptyFn,
  showTooltip: Ext.emptyFn
})

module.exports = RowEditor
