import Ext from 'ext'
import Q from 'q'

// Wrap Ext.Ajax.request in a promise.
// Includes json option to return json instead of response object.

// TODO replace with api/index.es, we do not need this bloated Promise
const request = function (options = {}) {
  const deferred = Q.defer()
  const originalCallback = options.callback

  Ext.Ajax.request(
    Ext.apply(options, {
      callback: async function (opts, success, response) {
        if (originalCallback) {
          await originalCallback.call(options.scope, opts, success, response)
        }

        if (success) {
          // todo ugh, i think we should automatically detect if response
          // is a json or not, this based on the content type of the response
          let json

          if (options.json) {
            try {
              json = JSON.parse(response.responseText)
            } catch (e) {
              return deferred.reject(e)
            }
          }

          return deferred.resolve(json || response)
        }

        const err = new Error('Request failed.')
        err.response = response
        return deferred.reject(err)
      }
    })
  )

  return deferred.promise
}

export default request
