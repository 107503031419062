const Ext = require('ext')
const t = require('translate')
const CompanyNewPanel = require('admin/view/CompanyNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const User = require('admin/core/User')
const ComboBox = require('admin/component/form/ComboBox')
const config = require('theming/config')

const VendorNewPanel = Ext.define(null, {
  extend: CompanyNewPanel,

  module() {
    return require('admin/business/VendorResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems() {
    return [
      {
        fieldLabel: t('Vendor Name'),
        name: 'displayName',
        xtype: 'textfield'
      },
      {
        title: t('Primary Address'),
        width: 'auto',
        height: 'auto',
        defaults: {
          width: config.panel.fieldWidth
        },
        items: [
          {
            fieldLabel: t('Attention Name'),
            name: 'primaryPostalAddress.attnName',
            xtype: 'textfield'
          },
          {
            fieldLabel: t('Address Line 1'),
            name: 'primaryPostalAddress.address1',
            xtype: 'textfield'
          },
          {
            fieldLabel: t('Address Line 2'),
            name: 'primaryPostalAddress.address2',
            xtype: 'textfield'
          },
          {
            fieldLabel: t('City / Town'),
            name: 'primaryPostalAddress.city',
            xtype: 'textfield'
          },
          new ComboBox({
            fieldLabel: t('Country'),
            hiddenName: 'primaryPostalAddress.countryGeo',
            value: User.getDefaultCountry(),
            store: User.getCountries(),
            editable: false,
            triggerAction: 'all'
          }),
          {
            fieldLabel: t('Postal Code'),
            name: 'primaryPostalAddress.postalCode',
            xtype: 'textfield'
          }
        ],
        xtype: 'fieldset'
      }
    ]
  }
})

module.exports = VendorNewPanel
