import t from 'translate'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'react-bootstrap'

class ReleaseButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  render() {
    return (
      <Button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className='button-release'
      >
        {t('Release')}
      </Button>
    )
  }
}

export default ReleaseButton
