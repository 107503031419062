const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ProxyStore = require('admin/data/ProxyStore')
const ComboBox = require('admin/component/form/ComboBox')
const CurrencyStore = require('admin/data/CurrencyStore')
const escape = require('html-escaper').escape

// TODO: Very similar to CountryResource. Refactor shared code.
const CurrencyResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Currency',
  translations: {
    title: t('Currency'),
    new: t('New Currency'),
    open: t('Open Currency'),
    list: t('List Currencies')
  },
  name: t('Currency'),
  resourceName: 'currencies',

  currencyChoiceExists() {
    return this.getStore().data.length > 1
  },

  getComboBox(config) {
    const rs = this.getStore()
    const store = new ProxyStore(rs)
    const combo = new ComboBox(
      Ext.applyIf(config, {
        fieldLabel: t('Currency'),
        name: 'currencyUom',
        typeAhead: true,
        valueField: 'code',
        displayField: 'code',
        store,
        lazyInit: false,
        editable: true,
        triggerAction: 'all',
        forceSelection: 'false',
        mode: 'local',
        allowBlank: false,
        validator(value) {
          if (!this.findRecord(this.displayField, value)) {
            // TODO: internationalise with template
            return escape(value + ' is not a valid currency.')
          }
          return true
        }
      })
    )

    const onLoad = function () {
      return this.setValue(this.getValue())
    }

    store.on('load', onLoad, combo)

    const onBlur = function (field) {
      if (field.getRawValue() === '') {
        return field.setValue('')
      }
    }

    combo.on('blur', onBlur, combo)

    combo.on(
      'beforedestroy',
      function () {
        this.validator = null
        this.un('blur', onBlur, this)
        store.un('load', onLoad, this)
        return store.destroy()
      },
      combo
    )

    return combo
  },

  getStore() {
    if (!this.store) {
      this.store = new CurrencyStore()
      this.store.load()
    }
    return this.store
  },

  isLoaded() {
    return this.getStore().isLoaded()
  },

  // called during app startup
  loadCurrencies(cb) {
    if (!this.isLoaded()) {
      return this.getStore().on('load', cb)
    }
    return cb()
  }
})

module.exports = CurrencyResource
