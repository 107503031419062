import React, { Component } from 'react'

import PropTypes from 'prop-types'
import _s from 'underscore.string'

// Vertical tab, obscure enough it shouldn't clash for the demo.
const DELIMITER = '\u000B'

class Highlight extends Component {
  static propTypes = {
    regex: PropTypes.instanceOf(RegExp),
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }

  highlightMatches = (input) => {
    if (!input) return

    if (Array.isArray(input)) {
      input = input.join(', ')
    } else {
      input = input.toString()
    }

    const { regex } = this.props

    if (regex) {
      try {
        input = input.replace(regex, (match) => DELIMITER + match + DELIMITER)
      } catch (e) {
        // ignore
      }
    }
    // ignore search expression completely on bad regex

    let i = 0

    return input.split(DELIMITER).map(function (match) {
      // Beware that this regex.test might not work on start & finish anchors and lookaheads.
      // But for the moment, it's good enough and works fine.
      if (regex?.test(match) && !_s.isBlank(match)) {
        return (
          <span className='match' key={i++}>
            {match}
          </span>
        )
      }

      return match
    })
  }

  render() {
    return <span className='highlight'>{this.highlightMatches(this.props.children)}</span>
  }
}

export default Highlight
