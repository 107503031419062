const ProductResources = require('product/resources')

const Ext = require('ext')
const GroupTabPanel = require('admin/view/GroupTabPanel')
const VendorTabPanel = require('admin/view/VendorTabPanel').default
const SmartRulesTabPanel = require('smartrules/TabPanel')
const SharedServicesTabPanel = require('admin/view/SharedServicesTabPanel').default
const VendorToolsTabPanel = require('admin/view/VendorToolsTabPanel')

const VendorGroupPanel = Ext.define(null, {
  extend: GroupTabPanel,

  constructor(cfg = {}) {
    this.record = cfg.record

    const smartRulesLink = this.record.getLinkByRel(
      ProductResources.VENDOR_SMART_RULES_REL
    )
    const sspLink = this.record.getUriByRel(ProductResources.SHARED_SERVICE_PROVIDER_REL)

    this.items = [
      new VendorTabPanel({ record: this.record }),
      smartRulesLink && new SmartRulesTabPanel({ vendor: this.record }),
      sspLink &&
        new SharedServicesTabPanel({
          allowPrivate: true,
          allowMappingPolicy: true,
          companyKey: this.record.getCompanyKey(),
          uri: sspLink
        }),
      new VendorToolsTabPanel()
    ]

    this.callParent()
  }
})

module.exports = VendorGroupPanel
