const Ext = require('ext')
const t = require('translate')
const PanelButton = require('smartrules/designer/PanelButton')

const DeleteButton = Ext.define(null, {
  extend: PanelButton,

  constructor(cfg) {
    cfg = Ext.applyIf(cfg || {}, {
      buttonCls: 'delete',
      handler() {
        return this.getHost().removeSelf()
      },

      tooltip: {
        width: 'auto',
        text: t('Click to delete')
      }
    })
    this.callParent([cfg])
  }
})

module.exports = DeleteButton
