export default {
  // states must be split by company because
  // they might vary from vendor, distributor and reseller and multiple
  // forms can exist in DOM shadows
  companies: {
    /*

    '/customer/2015032' for example is the unique identifier of a company we're
    calling companyKey

    here an example what the state could look like for one active company:

    '/customer/2015032': {
      loading: false,
      uri: 'https://localhost:3000/api/customer/2000074/notifications'
      list: []
    }
    */
  }
}
