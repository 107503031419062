const Ext = require('ext/ext-base')

//
// Ext.util.Cookies.set will set the cookie's path to '/' by default but
// Ext.util.Cookies.clear does not specify any path in its own cookie
// assignment.
//

Ext.util.Cookies.clear = function (name) {
  if (!this._clearExpireDate) {
    this._clearExpireDate = new Date(0)
  }
  return this.set(name, '', this._clearExpireDate)
}
