const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const AgreementListPanel = require('admin/view/AgreementListPanel')
const AgreementDetailPanel = require('admin/view/AgreementDetailPanel')
const AgreementNewPanel = require('admin/view/AgreementNewPanel')
const AgreementRecord = require('admin/data/AgreementRecord')

const AgreementResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Agreement',
  translations: {
    title: t('Product'),
    plural: t('Products'),
    new: t('Add Product'),
    open: t('Open Product'),
    list: t('List Products')
  },
  name: t('Product'),
  code: 'AGREEMENT',
  resourceName: 'agreements',

  layout: {
    newResource: {
      width: 400,
      height: 317
    },
    openResource: {
      width: 400,
      height: 'auto'
    }
  },

  operations: {
    create: true,
    save: true,
    open: true,
    remove: false,
    list: true
  },

  listPanel: AgreementListPanel,
  detailPanel: AgreementDetailPanel,
  newPanel: AgreementNewPanel,

  getTitle(record) {
    return `${t('Product')}: ${record.getName()}`
  },

  // TODO: Fix or remove. Catalogue does not include agreementType.
  filterCatalogue(store) {
    return store.filterBy((record) => {
      return (
        // prevents customer of SMX getting billed to reseller
        // (We don't want SMX direct customers to be billed to us,
        // they need to be billed for the service) but we have to have
        // wholesale agreements to provide to SMX resellers
        !record.billToSmx() ||
        ['GOLD_RESELLER', 'SILVER_RESELLER'].indexOf(
          record.get('agreement.agreementType.code')
        ) > -1
      )
    })
  },

  // Returns a number field for quantity input.
  createQuantityField() {
    return new Ext.form.NumberField({
      enableKeyEvents: true,
      fieldLabel: t('Quantity'),
      name: 'quantity',
      allowBlank: false,
      allowNegative: false,
      minValue: 1
    })
  },

  record: AgreementRecord
})

module.exports = AgreementResource
