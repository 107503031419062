const Ext = require('ext')
const t = require('translate')
const ConditionClauseTree = require('smartrules/designer/ConditionClauseTree')
const ActionClauseTree = require('smartrules/designer/ActionClauseTree')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ListStore = require('smartrules/designer/ListStore')
const ConditionGroup = require('smartrules/designer/ConditionGroup')
const getComponent = require('smartrules/designer/util/getComponent')
const logger = require('system/logger').default
const Sentry = require('system/sentry')

const TYPE_SPLITTER = '::'

const areAllListStoresLoaded = function (listStores) {
  if (listStores.length < 1) return true

  return listStores.reduce((memo, store) => memo && store.loaded && !store.loading, true)
}

const SmartRule = Ext.define(null, {
  extend: Ext.Panel,

  autoHeight: true,
  autoWidth: true,
  cls: 'smartrule-designer',
  noDefaultWidth: true,
  title: t('Designer'),
  dropped: false,

  initComponent() {
    this.listStores = this.listStores || []

    // since SCL-3576 we do not check anymore whether
    // user has quarantine role
    this.hasQuarantine =
      this.party.get('type') === 'CUSTOMER' && this.party.hasProductKey('QUARANTINE')

    this.templateStore = this.party.getTemplateStore()

    if (!this.sandbox) {
      this.listStores.forEach(function (store) {
        this.constructListStores(store, /systemdatasets$/.test(store.url))

        store.on(
          'load',
          function () {
            if (areAllListStoresLoaded(this.listStores)) {
              // unmask when all list stores are ready for use
              this.unmask()
            }
          },
          this,
          { single: true }
        )
      }, this)

      // mask when there are stores, see scl-1420
      if (!areAllListStoresLoaded(this.listStores)) {
        this.mask()
      }
    }

    this.conditionsTree = new ConditionClauseTree({
      party: this.party,
      readOnly: this.readOnly
    })

    this.actionsTree = new ActionClauseTree({
      templateStore: this.templateStore,
      hasQuarantine: this.hasQuarantine,
      readOnly: this.readOnly
    })

    this.items = [this.conditionsTree, this.actionsTree]

    this.on('smartrules-drop', this.onSmartRuleDrop, this)
    this.fireEvent('smartrules-drop')
    this.addEvents('dirty')

    this.callParent()

    // Used in tests
    if (this.rule) {
      this.setRule(this.rule)
    }
  },

  toDsl() {
    return `when\n${this.conditionsTree.toDsl()}\nthen\n${this.actionsTree.toDsl()}`
  },

  onSmartRuleDrop() {
    this.dropped = true
    this.actionsTree.fireEvent('smartrules-drop')
    this.conditionsTree.fireEvent('smartrules-drop')
  },

  getValue() {
    return this.toDsl()
  },

  // this is copied from MatcherCombo->getListStore()
  // todo get rid of duplicate code
  getListStore(listType) {
    this.listStores = this.listStores || []

    if (Array.isArray(listType)) {
      const storeWithMultipleTypes = listType.reduce(
        function (memo, singleListType) {
          // copy records of each list store into a grouped store for multiple types
          memo.add(this.getListStore(singleListType).getRange())
          return memo
        },
        new ListStore(),
        this
      )

      storeWithMultipleTypes.sort('name', 'ASC')

      return storeWithMultipleTypes
    }
    if (!this.listStores[listType]) {
      this.listStores[listType] = new ListStore()
    }

    return this.listStores[listType]
  },

  constructListStores(store, systemList) {
    store.load()

    store.on(
      'datachanged',
      function () {
        const records = store.getRange()

        records.forEach(function (record, i) {
          let type = record.get('dataSetType')
          if (systemList) {
            type += '_SYSTEM'
          }

          if (i === 0) {
            this.clearListStore(type)
          }

          const listStore = this.getListStore(type)
          listStore.addList(record, systemList)
        }, this)
      },
      this
    )

    store.fireEvent('datachanged')
  },

  clearListStore(type) {
    const store = this.getListStore(type)
    store.removeAll()
  },

  markDirty() {
    const formpanel = this.findParentByType(RecordFormPanel)
    const form = formpanel?.getForm()
    if (form) {
      formpanel.dirty = form.dirty = true
      return true
    }
  },

  isValid() {
    if (this.dropped) {
      return this.conditionsTree.isValid() && this.actionsTree.isValid()
    }
    return false
  },

  clear() {
    this.actionsTree.clear()
    this.conditionsTree.clear()
  },

  // TODO rename to createAction since it returns a value we process further
  addAction(action) {
    return this.actionsTree.addClause(action)
  },

  addActions(actions, parent) {
    return actions.forEach(function (action) {
      let newParent
      const subActions = action.then
      if (parent) {
        newParent = parent.addSubAction(
          SmartRule.constructAction(action, this.listStores, this.templateStore)
        )
      } else {
        newParent = this.addAction(
          SmartRule.constructAction(action, this.listStores, this.templateStore)
        )
      }
      if (subActions?.length > 0) {
        this.addActions(subActions, newParent)
      }
    }, this)
  },

  addCondition(condition) {
    return this.conditionsTree.addClause(condition)
  },

  build(compiledRule) {
    let rule
    if (compiledRule && typeof compiledRule.valueOf() === 'string') {
      // be robust here and do not crash on bad json's from backend
      try {
        rule = JSON.parse(compiledRule)
      } catch (e) {
        Ext.MessageBox.alert(
          t('An error has occurred'),
          t('Failed to open Smart Rule. Please contact technical support.')
        )

        logger.warn(e)
        Sentry.captureException(e)
      }
    } else {
      rule = compiledRule
    }

    if (rule) {
      const { condition } = rule

      if (condition.type === 'and') {
        const conditions = condition.condition
        conditions.forEach(function (c) {
          this.addCondition(SmartRule.constructCondition(c, this.listStores))
        }, this)
      } else if (condition.type) {
        this.addCondition(SmartRule.constructCondition(condition, this.listStores))
      }

      this.addActions(rule.actions)
    }

    this.fireEvent('smartrules-drop')
    this.fireEvent('smartrules-built')

    // bind dirty handler late so initialisation does not count as dirtying
    if (!this.hasListener('dirty')) {
      this.on('dirty', this.markDirty, this)
    }
  },

  setRule(rule) {
    this.clear()
    let storeCount = 0

    this.listStores.forEach(function (store) {
      if (store.loading) {
        storeCount++

        store.on(
          'datachanged',
          function () {
            storeCount--
            if (storeCount === 0) {
              this.build(rule)
            }
          },
          this,
          { single: true }
        )
      }
    }, this)

    if (storeCount === 0) {
      this.build(rule)
    }
  },

  statics: {
    constructAction(obj, listStores, templateStore) {
      const [type, operator] = Array.from(obj.type.split(TYPE_SPLITTER))

      // TODO: Remove dynamic component creation.
      const Component = getComponent(type)

      return new Component({
        fromOriginalRecipient: obj.fromOriginalRecipient,
        toOriginalRecipient: obj.toOriginalRecipient,
        toMatchedRecipient: obj.toMatchedRecipient,
        listStores: obj.listStores || listStores,
        message: obj.message,
        operator,
        prefixSubject: obj.prefixSubject,
        recipient: obj.recipient,
        sender: obj.sender,
        subject: obj.subject,
        system: obj.system,
        templateStore: obj.templateStore || templateStore,
        toOriginalSender: obj.toOriginalSender,
        value: obj.value,
        scope: obj.scope
      })
    },

    constructCondition(obj, listStores) {
      let group
      const [type, condition, operator, valueType] = Array.from(
        obj.type.split(TYPE_SPLITTER)
      )

      if (type === 'and' || type === 'or') {
        group = new ConditionGroup({ groupOp: obj.negated ? 'not' + type : type })
        const conditions = obj.condition || []
        conditions.forEach(
          (c) => group.addClause(SmartRule.constructCondition(c, listStores)),
          this
        )
        return group
      } else if (type === 'not') {
        obj.condition.negated = true
        return SmartRule.constructCondition(obj.condition, listStores)
      } else if (obj.negated) {
        group = new ConditionGroup({ groupOp: 'notor' })
        delete obj.negated
        group.addClause(SmartRule.constructCondition(obj, listStores))
        return group
      }

      // TODO: Remove dynamic component creation.
      let next
      const Component = getComponent(type)

      if (obj.next) {
        next = obj.next

        // todo migrate those configs to use obj.next instead. but i rather not to do, since
        // these are implemented before the smart rules header ui rewrite. the new obj.next config
        // has been introduced so that existing behaviour can't be broken
      } else if (obj.condition?.type) {
        const [nextCondition] = Array.from(obj.condition.type.split(TYPE_SPLITTER))
        next = {
          condition: nextCondition,
          value: obj.condition.value
        }
      }

      return new Component({
        type,
        condition,
        next,
        flags: obj.flags,
        listStores: obj.listStores || listStores,
        operator,
        pattern: obj.pattern,
        regex: obj.regex,
        system: obj.system,
        value: obj.value,
        valueType: valueType || obj.value?.type,
        quantifier: obj.quantifier
      })
    }
  }
})

module.exports = SmartRule
