import * as Time from 'admin/time/Time'
import * as hal from 'hal'
import { CONTINUITY_ARCHIVED_MESSAGE_REL } from 'product/resources'
import createCompanySelector from 'system/selector/createCompanySelector'

import { createSelector } from '@reduxjs/toolkit'

const TEN_MINUTES = 1000 * 60 * 10
const company = createCompanySelector('continuity')

export const continuityMessages = createSelector(
  company,
  // todo instead of 'messages', it should be a link name from SSV-2936
  (company) => company?.messages
)

const continuityUri = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.continuityUri
)

export const searchUri = createSelector(continuityUri, (continuityUri) => {
  return continuityUri && [continuityUri, 'search'].join('/')
})

export const query = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.query
)

export const timeZoneName = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.timeZoneName
)

export const fromDate = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages && new Date(continuityMessages.fromDate)
)

export const thruDate = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages && new Date(continuityMessages.thruDate)
)

export const results = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.results
)

export const resultsQuery = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.resultsQuery
)

export const loading = createSelector(continuityMessages, (continuityMessages) =>
  Boolean(continuityMessages?.loading)
)

export const scrollToIndex = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.scrollToIndex
)

export const highlight = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.highlight
)

// keep it public for unit tests
export const total = createSelector(results, (results) => results && results.length)

const limit = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.limit
)

export const searchParameters = createSelector(
  query,
  fromDate,
  thruDate,
  timeZoneName,
  (query, fromDate, thruDate, timeZoneName) => {
    return {
      query,
      fromDate:
        fromDate &&
        Time.rezone(fromDate, timeZoneName, {
          // changes timestamp leaving local time untouched
          keepLocalTime: true
        }).toMillis(),
      thruDate:
        thruDate &&
        Time.rezone(thruDate, timeZoneName, {
          // changes timestamp leaving local time untouched
          keepLocalTime: true
        })
          .plus({ days: 1 })
          .toMillis()
    }
  }
)

// keep it public for unit tests
export const offset = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.offset
)

const page = createSelector(offset, limit, (offset, limit) => offset / limit)

export const rows = createSelector(
  total,
  limit,
  results,
  page,
  (total, limit, results, page) => {
    if (total <= limit) return results // no need to slice

    return results?.slice(page * limit, (page + 1) * limit)
  }
)

export const columns = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.columns
)

/// ========= FOR VIEWING ========= ///

export const sid = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.sid
)

export const preview = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.preview
)

export const attachmentPanelExpanded = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.attachmentPanelExpanded
)

export const viewing = createSelector(sid, (sid) => sid !== null && sid !== undefined)

// public only for unit tests
export const viewingIndex = createSelector(
  viewing,
  results,
  sid,
  (viewing, results, sid) => {
    if (!viewing || !results) return

    return results.findIndex((r) => r.sid === sid)
  }
)

export const currentResultNumber = createSelector(viewingIndex, (viewingIndex) =>
  viewingIndex >= 0 ? viewingIndex + 1 : null
)

export const mail = createSelector(
  results,
  viewingIndex,
  (results, viewingIndex) => results && results[viewingIndex]
)

/// ========= MORE ON PAGINATION / SELECTIONS ========= ///

export const showResults = createSelector(
  viewing,
  rows,
  (viewing, rows) => !viewing && rows && rows.length > 0
)

export const showNoResults = createSelector(
  viewing,
  loading,
  rows,
  (viewing, loading, rows) => Boolean(!viewing && !loading && rows && rows.length < 1)
)

export const selectedCount = createSelector(rows, (rows) => {
  if (!rows) return 0

  return rows.reduce((total, r) => (r.selected ? total + 1 : total), 0)
})

export const selectedRows = createSelector(mail, rows, (mail, rows) => {
  if (mail) {
    return [mail]
  }

  return rows && rows.filter((r) => r.selected)
})

export const firstRowNumber = createSelector(
  results,
  rows,
  (results, rows) => results && rows && results.indexOf(rows[0]) + 1
)

export const lastRowNumber = createSelector(
  results,
  rows,
  (results, rows) => results && rows && results.indexOf(rows[rows.length - 1]) + 1
)

const prevIndex = createSelector(viewingIndex, (viewingIndex) => viewingIndex - 1)

export const hasPrev = createSelector(
  viewing,
  prevIndex,
  page,
  (viewing, prevIndex, page) => {
    if (viewing) return prevIndex >= 0

    return page > 0
  }
)

const nextIndex = createSelector(viewingIndex, (viewingIndex) =>
  viewingIndex >= 0 ? viewingIndex + 1 : null
)

export const nextLink = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.nextLink
)

export const hasNext = createSelector(
  viewing,
  nextIndex,
  results,
  nextLink,
  page,
  limit,
  (viewing, nextIndex, results, nextLink, page, limit) => {
    if (viewing) return results && !!(nextIndex in results)
    if (nextLink) return true

    return Boolean(results && !!((page + 1) * limit in results))
  }
)

export const nextOffset = createSelector(offset, limit, (offset, limit) => {
  return offset + limit
})

export const paginateNext = createSelector(
  viewing,
  nextIndex,
  nextOffset,
  (viewing, nextIndex, nextOffset) => {
    let paginateNext = true

    if (viewing) {
      paginateNext = nextIndex >= nextOffset
    }

    return paginateNext
  }
)

export const prefetchOffset = createSelector(
  paginateNext,
  offset,
  limit,
  (paginateNext, offset, limit) => {
    if (!paginateNext) return
    return offset + limit * 2
  }
)

export const prefetch = createSelector(
  paginateNext,
  nextLink,
  prefetchOffset,
  total,
  limit,
  (paginateNext, nextLink, prefetchOffset, total, limit) => {
    if (!paginateNext) return false

    // only prefetch when data in cache is not enough and when end hasn't been reached yet
    return nextLink && prefetchOffset >= total && total % limit === 0
  }
)

export const nextRelativeIndex = createSelector(
  viewing,
  paginateNext,
  nextIndex,
  offset,
  (viewing, paginateNext, nextIndex, offset) => {
    if (!viewing) return
    return paginateNext ? 0 : nextIndex - offset
  }
)

export const endReached = createSelector(
  prefetch,
  total,
  limit,
  (prefetch, total, limit) => {
    if (total <= limit) return true

    return !prefetch
  }
)

/// ========= FOR NEXT AND PREV ACTIONs ========= ///

const nextMail = createSelector(
  results,
  nextIndex,
  (results, nextIndex) => results && results[nextIndex]
)

export const nextParams = createSelector(
  timeZoneName,
  viewing,
  nextMail,
  nextLink,
  nextOffset,
  nextRelativeIndex,
  paginateNext,
  prefetch,
  (
    timeZoneName,
    viewing,
    nextMail,
    nextLink,
    nextOffset,
    nextRelativeIndex,
    paginateNext,
    prefetch
  ) => {
    return {
      timeZoneName,
      viewing,
      nextMail,
      nextLink,
      nextOffset,
      nextRelativeIndex,
      paginateNext,
      prefetch
    }
  }
)

const prevMail = createSelector(
  results,
  prevIndex,
  (results, prevIndex) => results && results[prevIndex]
)

export const prevParams = createSelector(
  viewing,
  prevIndex,
  prevMail,
  offset,
  limit,
  (viewing, prevIndex, prevMail, offset, limit) => {
    return {
      viewing,
      prevIndex,
      prevMail,
      offset,
      limit
    }
  }
)

/// ========= AUTHORISATION stuff ========= ///

const authorizationCount = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.authorizationCount
)

const authorizationTimestamp = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.authorizationTimestamp
)

export const authorized = createSelector(
  authorizationCount,
  authorizationTimestamp,
  (authorizationCount, authorizationTimestamp) => {
    if (authorizationCount > 0) return true

    if (authorizationTimestamp) {
      const diff = Date.now() - authorizationTimestamp
      return diff <= TEN_MINUTES // returns false when timed out
    }

    return false
  }
)

/// ========= TOOLBAR ACTIONs ========= ///

export const sourceAvailable = createSelector(
  authorized,
  viewing,
  loading,
  mail,
  (authorized, viewing, loading, mail) => {
    return (
      authorized &&
      viewing &&
      !loading &&
      mail &&
      Boolean(hal.href(mail, CONTINUITY_ARCHIVED_MESSAGE_REL))
    )
  }
)

export const source = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.source
)

const downloadUriTemplate = createSelector(
  continuityMessages,
  (continuityMessages) => continuityMessages?.downloadUriTemplate
)

const selectedSids = createSelector(
  selectedRows,
  (selectedRows) =>
    selectedRows &&
    selectedRows.reduce((selectedSids, r) => {
      selectedSids.push(r.sid)
      return selectedSids
    }, [])
)

export const downloadParams = createSelector(
  mail,
  downloadUriTemplate,
  selectedSids,
  (mail, downloadUriTemplate, selectedSids) => {
    return {
      mail,
      downloadUriTemplate,
      selectedSids
    }
  }
)

export const downloadable = createSelector(
  loading,
  viewing,
  selectedCount,
  authorized,
  downloadUriTemplate,
  (loading, viewing, selectedCount, authorized, downloadUriTemplate) => {
    if (downloadUriTemplate && !loading) {
      return (viewing && authorized) || (!viewing && selectedCount > 0)
    }

    return false
  }
)

const dateRangeShown = createSelector(continuityMessages, (continuityMessages) =>
  Boolean(continuityMessages?.dateRangeShown)
)

export const hasModalDialog = createSelector(
  source,
  dateRangeShown,
  (source, dateRangeShown) => Boolean(!!source || dateRangeShown)
)
