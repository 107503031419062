import React, { PureComponent } from 'react'

import classNames from 'classnames'
import Table from 'components/table/Table'
import _ from 'lodash'
import PropTypes from 'prop-types'

class SelectableTable extends PureComponent {
  static propTypes = {
    results: PropTypes.arrayOf(PropTypes.object),
    onSelect: PropTypes.func.isRequired,
    getHeaderTitle: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    rowClassNameGetter: PropTypes.func,
    getResults: PropTypes.func,
    rowClassName: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    // this to cover the default on windoze as well
    doubleClickDelay: 500
  }

  constructor(props) {
    super(props)

    this.state = {
      singleClickTimer: null,
      rowClickCount: 0
    }
  }

  getResults() {
    // any "sub"-class like paginable table will want to slice a part of the results.
    // hence this check whether getResults() is already defined further down as a prop
    if (this.props.getResults) {
      return this.props.getResults()
    }

    return this.props.results
  }

  resetClickHandling = () => {
    clearTimeout(this.state.singleClickTimer)

    this.setState({
      singleClickTimer: null,
      rowClickCount: 0
    })
  }

  isNewClick(newIndex) {
    return !this.state.singleClickTimer || this.getFirstSelectedRowIndex() !== newIndex
  }

  onRowClick = (target, newIndex) => {
    if (this.props.disabled) return

    this.setState(
      {
        rowClickCount: this.state.rowClickCount + 1
      },
      () => {
        // only process first clicks when no timer is running or
        // it is a different row than the previous one,
        // so that no two single clicks are made upon double clicks
        if (this.state.rowClickCount === 1 && this.isNewClick(newIndex)) {
          // fixes https://youtrack.smxemail.com/issue/SCL-2431
          // always clear existing timeouts before setting a new one
          clearTimeout(this.state.singleClickTimer)
          this.setState({
            rowClickCount: 0,
            singleClickTimer: setTimeout(
              this.resetClickHandling,
              this.props.doubleClickDelay
            )
          })

          const row = this.getRow(newIndex)
          this.props.onSelect([row], !row.selected)
        } else if (this.state.rowClickCount > 2) {
          // for two row click counts, leave it to the real double click
          // event handler below
          this.resetClickHandling()
        }
      }
    )
  }

  onRealRowDoubleClick = (target, index) => {
    if (this.props.disabled) return

    this.resetClickHandling()

    if (this.props.onRowDoubleClick) {
      const row = this.getRow(index)
      this.props.onRowDoubleClick(index, row)
    }
  }

  getRowsCount() {
    const results = this.getResults()
    return (results && results.length) || 0
  }

  componentWillUnmount() {
    clearTimeout(this.state.singleClickTimer)
  }

  getRowClass = (index) => {
    const row = this.getRow(index)
    const cellClassName =
      this.props.rowClassNameGetter && this.props.rowClassNameGetter(index, row)

    return classNames(cellClassName, this.props.rowClassName, { selected: row?.selected })
  }

  // probably the most important function. it decides when to re-render the table or not.
  // when data at index x does not change, then it won't re-render this row.
  getRow = (index) => {
    const results = this.getResults && this.getResults()
    return results && results[index]
  }

  getFirstSelectedRowIndex() {
    const results = this.getResults()

    if (!results) return null

    const index = results.findIndex((row) => row?.selected === true)

    // do not return negative indices otherwise table scrolls up when unselected
    // see https://youtrack.smxemail.com/issue/SCL-2473
    return index < 0 ? null : index
  }

  render() {
    // we manage double click handling ourselves, see SCL-2410
    const otherProps = _.omit(this.props, 'onRowDoubleClick')

    return (
      <Table
        {...otherProps}
        getRow={this.getRow}
        rowsCount={this.getRowsCount()}
        onRowClick={this.onRowClick}
        onRowDoubleClick={this.onRealRowDoubleClick}
        rowClassNameGetter={this.getRowClass}
        scrollToRow={this.getFirstSelectedRowIndex()}
      />
    )
  }
}

export default SelectableTable
