const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validateIpAddress = require('validators/legacyIp')

const TrustedIpDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'trustedip-detail-panel',

  module() {
    return require('admin/business/TrustedIpResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    return [
      {
        fieldLabel: t('Mail Server'),
        name: 'name',
        validator: validateIpAddress,
        allowBlank: false,
        xtype: 'textfield'
      }
    ]
  }
})

module.exports = TrustedIpDetailPanel
