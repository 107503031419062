const Ext = require('ext')
const t = require('translate')
const PanelButton = require('smartrules/designer/PanelButton')
const DragUtils = require('smartrules/designer/DragUtils').default

const DraggingButton = Ext.define(null, {
  extend: PanelButton,

  constructor(cfg) {
    cfg = Ext.applyIf(cfg || {}, {
      buttonCls: 'draggable',
      handler: Ext.emptyFn,
      tooltip: {
        width: 'auto',
        text: t('Drag to move')
      }
    })
    this.clause = cfg.clause
    this.callParent([cfg])
    if (cfg.clause) {
      return this.on('afterrender', this.onAfterRender)
    }
  },

  onAfterRender: DragUtils.setupDragTracker
})

module.exports = DraggingButton
