const Ext = require('ext')
const t = require('translate')
const ClauseTree = require('smartrules/designer/ClauseTree')
const Icon = require('smartrules/designer/Icon')
const DeleteMessage = require('smartrules/designer/actions/DeleteMessage')
const QuarantineMessage = require('smartrules/designer/actions/QuarantineMessage')
const ReplaceDomain = require('smartrules/designer/actions/ReplaceDomain')
const Whitelist = require('smartrules/designer/actions/Whitelist')
const ReplaceSender = require('smartrules/designer/actions/ReplaceSender')
const StripMatchedAttachments = require('smartrules/designer/actions/StripMatchedAttachments')
const StripAllAttachments = require('smartrules/designer/actions/StripAllAttachments')
const StopProcessing = require('smartrules/designer/actions/StopProcessing')
const PrependPreamble = require('smartrules/designer/actions/PrependPreamble')
const AppendFooter = require('smartrules/designer/actions/AppendFooter')
const ActionClause = require('smartrules/designer/ActionClause')

// Concrete implementation of a clause tree with tools for SmartRules actions.
const ActionClauseTree = Ext.define(null, {
  extend: ClauseTree,

  constructor(cfg = {}) {
    cfg.cls = 'smartrules-actions'
    cfg.clauseCssCls = 'smartrules-action'
    cfg.root = new (require('smartrules/designer/ActionGroup'))({
      items: cfg.members,
      root: true
    })

    const RefuseMessage = require('smartrules/designer/actions/RefuseMessage')
    const RedirectTo = require('smartrules/designer/actions/RedirectTo')
    const CcTo = require('smartrules/designer/actions/CcTo')
    const BccTo = require('smartrules/designer/actions/BccTo')

    const toolbarIconItems = [
      new Icon({
        label: t('CC'),
        type: 'smartrules-cc-to',
        disabledBy: [DeleteMessage, RefuseMessage, QuarantineMessage]
      }),
      new Icon({
        label: t('BCC'),
        type: 'smartrules-bcc-to',
        disabledBy: [DeleteMessage, RefuseMessage, QuarantineMessage]
      }),
      new Icon({
        label: t('Redirect'),
        type: 'smartrules-redirect-to',
        disabledBy: [
          DeleteMessage,
          RefuseMessage,
          QuarantineMessage,
          ReplaceDomain,
          RedirectTo
        ]
      }),
      new Icon({
        label: t('Whitelist'),
        type: 'smartrules-whitelist',
        disabledBy: [DeleteMessage, RefuseMessage, QuarantineMessage, Whitelist]
      }),
      new Icon({
        label: t('Replace matched recipient domains'),
        type: 'smartrules-replace-domain',
        requiredCondition: require('smartrules/designer/conditions/AddressedTo'),
        disabledBy: [
          DeleteMessage,
          RefuseMessage,
          QuarantineMessage,
          ReplaceDomain,
          RedirectTo
        ]
      }),
      new Icon({
        label: t('Replace sender'),
        type: 'smartrules-replace-sender',
        disabledBy: [DeleteMessage, RefuseMessage, QuarantineMessage, ReplaceSender]
      }),
      new Icon({
        label: t('Send Notification'),
        type: 'smartrules-send-notification',
        disabledBy: []
      }),
      new Icon({
        label: t('Preamble'),
        type: 'smartrules-prepend-preamble',
        disabledBy: [DeleteMessage, RefuseMessage, QuarantineMessage]
      }),
      new Icon({
        label: t('Append Footer'),
        type: 'smartrules-append-footer',
        disabledBy: [DeleteMessage, RefuseMessage, QuarantineMessage]
      }),
      new Icon({
        label: t('Strip matched attachments'),
        type: 'smartrules-strip-matched-attachments',
        disabledBy: [
          DeleteMessage,
          RefuseMessage,
          QuarantineMessage,
          StripMatchedAttachments,
          StripAllAttachments
        ],
        requiredCondition: require('smartrules/designer/conditions/Attachment'),
        relatedAction: StripMatchedAttachments
      }),
      new Icon({
        label: t('Strip all attachments'),
        type: 'smartrules-strip-all-attachments',
        disabledBy: [
          DeleteMessage,
          RefuseMessage,
          QuarantineMessage,
          StripMatchedAttachments,
          StripAllAttachments
        ]
      }),
      new Icon({
        label: t('Stop ruleset processing'),
        type: 'smartrules-stop-processing',
        disabledBy: [StopProcessing]
      }),
      new Icon({
        label: t('Refuse message'),
        type: 'smartrules-refuse-message',
        disabledBy: [
          DeleteMessage,
          QuarantineMessage,
          RedirectTo,
          StripMatchedAttachments,
          StripAllAttachments,
          CcTo,
          BccTo,
          RefuseMessage,
          ReplaceDomain,
          ReplaceSender,
          PrependPreamble,
          AppendFooter,
          Whitelist
        ]
      }),
      new Icon({
        label: t('Do not deliver message'),
        type: 'smartrules-delete-message',
        disabledBy: [
          DeleteMessage,
          QuarantineMessage,
          RedirectTo,
          StripMatchedAttachments,
          StripAllAttachments,
          CcTo,
          BccTo,
          RefuseMessage,
          ReplaceDomain,
          ReplaceSender,
          PrependPreamble,
          AppendFooter,
          Whitelist
        ]
      })
    ]

    // Splice icon between refuse and reject
    if (cfg.hasQuarantine) {
      toolbarIconItems.splice(
        -1,
        0,
        new Icon({
          label: t('Quarantine'),
          type: 'smartrules-quarantine',
          disabledBy: [DeleteMessage, QuarantineMessage, RefuseMessage]
        })
      )
    }

    cfg.toolbarItems = [
      {
        border: false,
        height: 40,
        cls: 'smartrules-icon-bar',
        items: [
          toolbarIconItems,
          {
            xtype: 'spacer',
            flex: 1
          },
          {
            xtype: 'button',
            scope: this,
            text: t('Clear'),
            ref: '../clearButton',
            handler() {
              this.root.clear()
            },
            iconCls: 'icon-reset'
          }
        ],
        padding: '0 4',
        region: 'north',
        layout: 'hbox'
      }
    ]

    this.callParent([cfg])
  },

  onDrop() {
    this.checkLocks()
    this.root.fireEvent('smartrules-drop')
    this.clearButton && this.clearButton.setDisabled(this.root.isEmpty())
  },

  /**
  @method checkIcon
  Checks an icon to see if it is locked (@see #checkLocks)
  */
  checkIcon(icon) {
    let disable = false
    const disabledBy = icon.disabledBy || []
    const parent = icon.findParentBy(
      (n) => n instanceof require('smartrules/designer/SmartRule')
    )
    const { requiredCondition } = icon
    if (
      requiredCondition &&
      (parent != null
        ? parent.findBy((n) => n instanceof requiredCondition).length
        : undefined) === 0
    ) {
      disable = true
      this.root
        .findBy((n) => n.xtype != null && n.xtype === icon.relatedAction)
        .forEach((item) => item.removeSelf(true))
    }

    disable =
      disable ||
      this.root
        .findBy((n) => n instanceof ActionClause)
        .some((action) => disabledBy.some((disabler) => action instanceof disabler))
    return icon.setEnabled(!disable)
  },

  /**
  @method checkLocks
  Check all of the disabledBy actions
  */
  checkLocks() {
    const actions = this.root.items.getRange()
    const icons = this.findBy((n) => n instanceof Icon)
    return icons.forEach(function (icon) {
      return this.checkIcon(icon, actions)
    }, this)
  }
})

module.exports = ActionClauseTree
