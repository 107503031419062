const Ext = require('ext')
const t = require('translate')
const Time = require('admin/time/Time')

const EventRecipientsPanel = Ext.define(null, {
  extend: Ext.Panel,

  border: false,

  constructor(cfg) {
    this.record = cfg.record
    this.timeZoneName = cfg.timeZoneName
    this.items = this.getItems()

    this.addClass('recipients')

    this.callParent()
  },

  expandAll() {
    this.findByType(Ext.form.FieldSet).forEach((f) => f.expand())
  },

  collapseAll() {
    this.findByType(Ext.form.FieldSet).forEach((f) => f.collapse())
  },

  getItems() {
    const recipients = this.record.get('recipients')
    let items = []

    if (recipients) {
      items = recipients.map((recipient) => {
        return new Ext.form.FieldSet({
          title: `${recipient.recipient} - ${this.record.eventStatus(recipient)}`,
          collapsed: this.record.get('recipients').length !== 1,
          collapsible: true,
          items: [
            this.field(t('Delivery Status'), this.record.eventStatus(recipient)),
            this.field(t('Mail Type'), this.record.eventType(recipient)),
            recipient.destinationHost &&
              this.field(t('Destination Host'), recipient.destinationHost),
            recipient.deliveredDate &&
              this.field(
                t('Delivered Date'),
                Time.toString(recipient.deliveredDate, this.timeZoneName, {
                  includeSeconds: true
                })
              ),
            this.field(t('Response'), recipient.response),
            this.field(t('Tracking ID'), recipient.trackingId),
            recipient.customer && this.field(t('Customer'), recipient.customer),
            this.tlsField(
              t('Delivery TLS'),
              recipient.deliveryTlsCipher,
              recipient.deliveryTlsProtocol
            )
          ].filter(Boolean)
        })
      })
    }

    return items
  },

  field(label, value) {
    return new Ext.form.DisplayField({
      fieldLabel: label,
      value,
      htmlEncode: true
    })
  },

  tlsField(label, cipher, protocol) {
    // if theres a cipher, there should always be a protocol
    if (!cipher && !protocol) return

    const tlsDetails = `Protocol: ${protocol} Cipher: ${cipher}`
    return this.field(label, tlsDetails)
  }
})

module.exports = EventRecipientsPanel
