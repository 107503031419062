import CompanyFields from 'admin/data/company/fields/company'

// clone default company fields and concat more for billable entities
// those two email address fields can be like '"somename" <a@b.com>',
// including name and address both. this because it's one field
// in the backend database (a contact mech)
export default CompanyFields.concat([
  {
    name: 'primaryBillingEmailAddress',
    type: 'string'
  },
  {
    name: 'secondaryBillingEmailAddress',
    type: 'string'
  }
])
