const Ext = require('ext')
const t = require('translate')
const Group = require('smartrules/designer/Group')
const ConditionClauseTree = require('smartrules/designer/ConditionClauseTree')
const ComboBox = require('admin/component/form/ComboBox')
const GroupHeader = require('smartrules/designer/GroupHeader')

// A group of condition clauses, related by a prefix operation selected by a combo box.

const GROUP_STORE = new Ext.data.ArrayStore({
  fields: ['code', 'label'],
  data: [
    ['and', t('All')],
    ['or', t('Any')],
    ['notor', t('None')],
    ['notand', t('Not All')]
  ]
})

const ConditionGroup = Ext.define(null, {
  extend: Group,

  constructor(cfg) {
    Ext.applyIf(cfg, {
      groupStore: GROUP_STORE,
      clauseTreeType: ConditionClauseTree,
      dropLabel: t('Drag conditions here'),
      useSpacer: true,
      firstJoinWord: t('If'),
      defaultJoinWord: t('Or', 'SmartRules Conditions')
    })

    this.groupCombo = new ComboBox({
      allowBlank: false,
      editable: false,
      lazyInit: false,
      displayField: 'label',
      mode: 'local',
      forceSelection: true,
      triggerAction: 'all',
      valueField: 'code',
      value: cfg.groupOp || 'and',
      store: GROUP_STORE,
      width: 80
    })

    cfg.groupHeader = new GroupHeader({
      items: this.groupCombo,
      margins: '2 0 0 0',
      nest: false,
      droppable: true,
      group: this,
      useSpacer: cfg.useSpacer
    })

    this.callParent([cfg])

    this.groupCombo.on(
      'select',
      function () {
        this.updateJoinWords()
      },
      this
    )

    this.on(
      'render',
      function () {
        this.fireEvent('select')
      },
      this.groupCombo,
      { single: true }
    )
  },

  addClause(cmp, index) {
    this.callParent([cmp, index])
    this.groupCombo.fireEvent('select')
  },

  getJoinWord() {
    const value = this.groupCombo.getValue()

    if (value === 'and' || value === 'notand') {
      return t('And', 'SmartRules Conditions')
    }

    // otherwise ask parent instance for default join word
    return this.callParent()
  },

  toDsl() {
    let joiner
    const items = this.groupPanel.items.getRange()
    const comboValue = this.groupCombo.getValue()

    if (this.root || comboValue === 'and' || comboValue === 'notand') {
      joiner = 'and'
    } else {
      joiner = 'or'
    }

    let conditionText = items
      .map((condition) => condition.toDsl())
      .join(String.format(' {0} ', joiner))

    if (items.length > 1) {
      conditionText = String.format('({0})', conditionText)
    }

    if ((!this.root && comboValue === 'notand') || comboValue === 'notor') {
      return String.format('not ({0})', conditionText)
    }

    return conditionText
  },

  abandon() {}
})

module.exports = ConditionGroup
