const Ext = require('ext')
const _s = require('underscore.string')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const Time = require('admin/time/Time')
const Alert = require('admin/util/Alert').default
const ComboBox = require('admin/component/form/ComboBox')
const User = require('admin/core/User')
const RulePanel = require('smartrules/RulePanel')
const logger = require('system/logger').default

const EditRule = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'rule-detail-panel',
  preferEditOnly: true,

  constructor(cfg = {}) {
    this.module = require('smartrules/Rule')
    this.party = cfg.customer || cfg.vendor
    this.record = cfg.record
    this.currentDefinition = this.record.get('definition')

    this.callParent(arguments)

    this.disableForm()
  },

  getFormPanel() {
    return new RecordFormPanel({
      autoHeight: true,
      items: this.getDetailItems(),
      module: this.module,
      operation: 'save',
      record: this.record,
      isDirty: () => this.isDirty(),
      isValid: () => this.isValid(),
      validateForm(form, valid) {
        valid = valid && form.isValid()
        return RecordFormPanel.prototype.validateForm.call(this, form, valid)
      },
      // override parent canSave() fn to let this one class decide about it
      canSave: () => this.canSave()
    })
  },

  getCompilerUrl() {
    return this.party.get('products').SMART_RULES.resources.RULE_COMPILER.url
  },

  isReadOnly() {
    return !this.canSave()
  },

  getDetailItems() {
    this.designer = new RulePanel({
      dontMakeReadOnlyClone: true,
      readOnly: this.isReadOnly(),
      definition: this.record.get('definition'),
      compilerUrl: this.getCompilerUrl(),
      party: this.party,
      listStores: [
        this.party.getSystemListManagementStore(),
        this.party.get('type') !== 'VENDOR' && this.party.getListManagementStore()
      ].filter(Boolean)
    })

    return [
      this.createRuleName(),

      {
        fieldLabel: t('Description'),
        name: 'description',
        anchor: '100%',
        xtype: this.canSave() ? 'textarea' : 'displayfield'
      },

      this.party.get('type') !== 'VENDOR' &&
        this.canSave() &&
        new ComboBox({
          fieldLabel: t('Enabled'),
          hiddenName: 'enabled',
          store: [
            ['false', t('No')],
            ['true', t('Yes')]
          ],
          editable: false,
          allowBlank: false,
          triggerAction: 'all'
        }),
      this.record.get('thruDate') ? this.createExpiredField() : undefined,

      this.designer,

      {
        name: 'sequenceNumber',
        xtype: 'hidden'
      }
    ]
  },

  createRuleName() {
    const ruleInputOptions = {
      fieldLabel: t('Rule Name'),
      value: this.record.getName(),
      name: 'name',
      anchor: '50%',
      minLength: 2,
      maxLength: 255,
      readOnly: this.isReadOnly()
    }

    if (!this.isReadOnly()) {
      ruleInputOptions.helpText = t(
        'Descriptive but broad names are the most helpful here: e.g. "Filter Large Messages" vs "Filter Messages Over 10MB"'
      )
      ruleInputOptions.allowBlank = false
      ruleInputOptions.validator = function (v) {
        if (v.match(/^\s/)) {
          return t('Names cannot start with a space')
        }
        return true
      }
    }

    return new Ext.form.TextField(ruleInputOptions)
  },

  createExpiredField() {
    return new Ext.form.DisplayField({
      fieldLabel: t('Expired'),
      value: Time.toString(this.record.get('thruDate'), null, {
        includeSeconds: true
      })
    })
  },

  setReady() {
    this.resetDirtyFlags()
    this.ready = true
  }, // avoids race conditions when rendered before compiled

  isValid() {
    if (_s.isBlank(this.designer.getValue())) {
      return false
    }

    return this.designer.isValid()
  },

  isDirty() {
    if (this.layoutPending || !this.ready) return false

    return (
      !!this.formPanel.dirty ||
      this.currentDefinition !== this.designer.getValue() ||
      Ext.form.BasicForm.prototype.isDirty.call(this.formPanel)
    )
  },

  resetDirtyFlags() {
    if (!this.formPanel) return

    const items = this.formPanel.findByType(Ext.form.Field)
    delete this.formPanel.dirty
    delete this.formPanel.getForm().dirty

    items.forEach(function (item) {
      if (item.isFormField) {
        item.originalValue = item.getValue()
      }
    })

    this.currentDefinition = this.designer.getValue()
  },

  handleCancel() {
    if (!this.formPanel) return
    if (!this.formPanel.isVisible) return
    if (!this.formPanel.preventClose) return
    if (!this.formPanel.preventClose(() => this.handleCancel())) {
      this.ready = false
      this.close()
    }
  },

  getThruDate() {
    return this.record.get('thruDate')
  },

  canSave() {
    return (
      User.can('SMART_RULE', 'PUT') &&
      !this.getThruDate() &&
      User.isTechnical() &&
      this.record.canSave()
    )
  },

  // Because the definition needs to be checked, saving is a two step process.
  handleSave() {
    if (this.saving) return

    this.setSaving(true)

    const definition = this.designer.getValue()

    return this.designer.evaluate(definition, {
      failure: () => {
        return this.setSaving(false)
      },
      success: (compilerResult) => {
        if (compilerResult.parserErrors.length) {
          Alert.alert(t('Error'), t('Rule failed to compile.'))
          this.setSaving(false)
          return
        }

        return this.record
          .saveNewVersion(
            Object.assign(this.formPanel.getForm().getFieldValues(), { definition })
          )
          .then(() => {
            this.resetDirtyFlags()
            this.formPanel.loadRecord(true)
            this.ready = false
            this.close()
          })
          .catch((err) => logger.error(err))
          .finally(() => this.setSaving(false))
          .done()
      }
    })
  },

  // Helper to manage saving state.
  setSaving(saving) {
    if (saving === this.saving) {
      return
    }

    this.saving = saving

    if (this.saving) {
      Ext.MessageBox.wait(t('Saving...'))
    } else {
      Ext.MessageBox.hide()
    }

    if (!this.isDestroyed) {
      this.formPanel.saveButton.setDisabled(this.saving)
    }
  }
})

module.exports = EditRule
