const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const EventSearchRecord = require('admin/data/EventSearchRecord')
const EventResource = require('admin/business/EventResource')

const EventSearchStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'events/search',

  record: EventSearchRecord,

  module() {
    return EventResource
  }
})

module.exports = EventSearchStore
