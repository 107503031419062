import { doubleClickMailbox, refresh, selectRows } from 'mailboxes/actions'
import { list, loading, uri } from 'mailboxes/subs'

import CheckableTable from 'components/table/CheckableTable'
import PropTypes from 'prop-types'
import React from 'react'
import User from 'admin/core/User'
import _s from 'underscore.string'
import { list as catalogue } from 'mailhosting/catalogue/subs'
import { connect } from 'react-redux'
import t from 'translate'

const BOOTSTRAP_PADDING = 5
const TOOLBAR_MIN_HEIGHT = 85 + 2 * BOOTSTRAP_PADDING

class Table extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired
  }

  UNSAFE_componentWillMount() {
    this.props.refresh(this.props.uri)
  }

  get width() {
    if (typeof this.props.width === 'number') {
      return this.props.width - BOOTSTRAP_PADDING * 3
    }
    return this.props.width
  }

  get maxHeight() {
    if (typeof this.props.height === 'number') {
      return this.props.height - TOOLBAR_MIN_HEIGHT
    }
    return this.props.height
  }

  renderCosDescription = (cellData) => {
    if (!cellData) return
    if (!this.props.catalogue) return _s.capitalize(cellData)

    const type = this.props.catalogue.find((catalogue) => catalogue.cosName === cellData)

    return _s.capitalize((type && type.description) || cellData)
  }

  renderStatus = (cellData) => t(_s.humanize(cellData))

  handleRowDoubleClick = (index, mailbox) => {
    if (mailbox.statusCode.toLowerCase() === 'purging') return

    this.props.doubleClickMailbox(mailbox)
  }

  getColumns = () => {
    const columns = [
      {
        name: 'address',
        title: t('Address'),
        width: 160,
        flexGrow: 3,
        isResizable: true
      },
      {
        name: 'cosName',
        title: t('Type'),
        width: 105,
        flexGrow: 1,
        isResizable: true,
        renderCell: this.renderCosDescription
      }
    ]

    // since SCL-2292
    if (User.isVendor() || User.isDistributor()) {
      columns.push({
        name: 'hostingDescription',
        title: t('Platform'),
        width: 190,
        flexGrow: 1,
        isResizable: true
      })
    }

    columns.push({
      name: 'statusCode',
      title: t('Status'),
      width: 105,
      flexGrow: 1,
      isResizable: true,
      renderCell: this.renderStatus
    })

    return columns
  }

  render() {
    return (
      <CheckableTable
        disabled={this.props.loading}
        width={this.width}
        maxHeight={this.maxHeight}
        results={this.props.list}
        onRowDoubleClick={this.handleRowDoubleClick}
        onSelect={this.props.onSelectRows}
        rowClassName='mailbox-row'
        columns={this.getColumns()}
      />
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  list: list(db, companyKey),
  loading: loading(db, companyKey),
  uri: uri(db, companyKey),
  catalogue: catalogue(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  refresh: (uri) => dispatch(refresh(companyKey, uri)),
  onSelectRows: (quarantineSets, selected) =>
    dispatch(selectRows(companyKey, quarantineSets, selected)),
  doubleClickMailbox: (quarantineSet) =>
    dispatch(doubleClickMailbox(companyKey, quarantineSet))
})

export default connect(mapDbToProps, mapActionsToProps)(Table)
