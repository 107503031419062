import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import t from 'translate'
import Dialog from 'components/dialog/Confirmation'

import { stopConfirmingPurge, purge } from 'mailboxes/actions'

import {
  loading,
  uri,
  selectedCount,
  selectedMailboxAddress,
  selectedMailboxesPurgeLinks
} from 'mailboxes/subs'

class Purge extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.purge(this.props.uri, this.props.selectedMailboxesPurgeLinks)
  }

  render() {
    let title, warning

    if (this.props.selectedCount === 1) {
      title = t('Purge mailbox {0}?', [this.props.selectedMailboxAddress])

      warning = t(
        'This will irreversibly remove the mailbox and its contents from the system. Are you sure you want to purge mailbox {0}?',
        [this.props.selectedMailboxAddress]
      )
    } else {
      title = t('Purge {0} mailboxes?', [this.props.selectedCount])

      warning = t(
        'This will irreversibly remove selected mailboxes and its contents from the system. Are you sure you want to purge {0} mailboxes?',
        [this.props.selectedCount]
      )
    }

    return (
      <Dialog
        icon='exclamation'
        modalTitle={title}
        headerText={warning}
        submitButtonText={t('Purge')}
        cancelDisabled={this.props.loading}
        submitDisabled={this.props.loading}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  loading: loading(db, props.companyKey),
  selectedCount: selectedCount(db, props.companyKey),
  selectedMailboxAddress: selectedMailboxAddress(db, props.companyKey),
  selectedMailboxesPurgeLinks: selectedMailboxesPurgeLinks(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  onCancel: () => dispatch(stopConfirmingPurge(companyKey)),
  purge: (uri, link) => {
    dispatch(purge(companyKey, uri, link))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Purge)
