const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const User = require('admin/core/User')
const Subscription = require('reporting/Subscription')
const store = require('store').default
const { isCapableOfSubscriptions } = require('system/capability/subs')

const UserListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'emailAddress',
  height: 'auto',
  cls: 'user-list-panel',
  tabCls: 'user-list-tab',

  module() {
    return require('admin/business/UserResource')
  },

  createColumnModel() {
    const columns = [
      {
        id: 'firstName',
        header: t('First Name'),
        width: 100,
        sortable: true,
        dataIndex: 'firstName'
      },
      {
        id: 'lastName',
        header: t('Last Name'),
        width: 100,
        sortable: true,
        dataIndex: 'lastName'
      },
      {
        id: 'emailAddress',
        header: t('Email Address'),
        width: 150,
        sortable: true,
        dataIndex: 'userName'
      },
      {
        id: 'phoneNumber',
        header: t('Phone Number'),
        width: 150,
        sortable: true,
        renderer(value, meta, record) {
          const ext = record.get('extension')
          return (value !== null ? value : '') + (ext ? `x${ext}` : '')
        },
        dataIndex: 'contactNumber'
      },
      {
        id: 'authenticationSource',
        header: t('Authentication'),
        width: 120,
        sortable: true,
        dataIndex: 'authenticationSource',
        renderer(value, meta, record) {
          return record.getHumanAuthenticationSource()
        }
      }
    ]
    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }

    return new Ext.grid.ColumnModel(columns)
  },

  getContextMenuItems(record) {
    const items = this.callParent(arguments)

    if (User.isVendor() && isCapableOfSubscriptions(store.getState())) {
      items.push({
        iconCls: 'list-SMX-Subscription',
        text: t('List Subscriptions'),
        handler() {
          return Subscription.list(record)
        }
      })
    }

    return items
  }
})

module.exports = UserListPanel
