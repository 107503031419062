const Record = require('admin/core/Record')
const Time = require('admin/time/Time')

const TemplateRecord = Record.define(null, {
  module() {
    return require('smartrules/TemplateManagement')
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'html',
      type: 'string'
    },
    {
      name: 'text',
      type: 'string'
    },
    {
      name: 'url',
      type: 'string'
    }
  ],

  getName() {
    return this.get('name')
  },

  getParams() {
    return {
      html: this.get('html'),
      name: this.get('name'),
      text: this.get('text')
    }
  },

  isPersistentOnSave() {
    return true
  }
})

module.exports = TemplateRecord
