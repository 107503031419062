import { getKeycloakToken, isKeycloakAuthenticated } from 'system/keycloak'

import Ext from 'ext' // todo ditch extjs for cookies

export const get = () => Ext.util.Cookies.get('token')
export const header = () => (get() ? 'Basic ' + get() : '')

export const set = (token) => {
  // TODO remove. backend has removed cookie auth ages ago.
  // we only need the token for the authorization header only.
  Ext.util.Cookies.set('token', token, new Date().add(Date.DAY, 1))
}

export const clear = () => {
  Ext.util.Cookies.clear('token')
  // Need to specify domain in order to clear cookie set in cookie-login.
  Ext.util.Cookies.set('token', '', new Date(0), '/', window.location.hostname)
}

export const getAuthorisationUrl = (url) => {
  const token = isKeycloakAuthenticated() ? getKeycloakToken() : get()
  return url && url.replace('/api', '/api/auth/' + token)
}
