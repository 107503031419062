const Ext = require('ext')
const SizeMatcher = require('smartrules/designer/matchers/SizeMatcher')

const EqualsMatcher = Ext.define(null, {
  extend: SizeMatcher,

  constructor(cfg) {
    const config = Ext.apply(cfg, { direction: 'equal to' })
    this.callParent([config])
  }
})

module.exports = EqualsMatcher
