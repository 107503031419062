const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const User = require('admin/core/User')

const ListManagementListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  title: t('Lists'), // this is the tab title
  autoExpandColumn: 'items',

  module() {
    return require('smartrules/ListManagement')
  },

  createColumnModel() {
    const columns = [
      {
        id: 'name',
        header: t('Name'),
        sortable: true,
        dataIndex: 'name'
      },
      {
        id: 'dataSetType',
        header: t('List Type'),
        width: 200,
        sortable: true,
        dataIndex: 'dataSetTypeName'
      },
      {
        id: 'items',
        header: t('Items'),
        width: 200,
        sortable: true,
        dataIndex: 'items',
        renderer(v) {
          return v
            .split('\n')
            .map((item) => item || null)
            .filter(Boolean)
            .join(', ')
        }
      }
    ]

    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('Id'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }
    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = ListManagementListPanel
