import { init as initCatalogue } from 'mailhosting/catalogue/actions'
import { init as initDomains } from 'mailhosting/domain/actions'

export const MAILHOSTING_UNLOAD = 'MAILHOSTING_UNLOAD'

export const unload = (companyKey) => ({
  type: MAILHOSTING_UNLOAD,
  companyKey
})

export const init = (companyKey, customerUrl) => (dispatch) => {
  dispatch(initCatalogue(companyKey, customerUrl))
  dispatch(initDomains(companyKey, customerUrl))
}
