import React, { Component } from 'react'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import { Form } from 'react-bootstrap'
import Grid from 'components/Grid'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import t from 'translate'

// deprecated since SCL-2244, use react-bootstrap's and redux form instead

const NL = '\n'

const collectAllChildren = function (target) {
  let children = []

  React.Children.forEach(target.props.children, (child) => {
    children.push(child)

    if (child.props.children) {
      children = children.concat(collectAllChildren(child))
    }
  })

  return children
}

// copied from FormDataMixin temporarily
const isCheckbox = (el) => el.getAttribute('type') === 'checkbox'

// copied from FormDataMixin temporarily
const isMultiChoice = (checkbox) => checkbox.getAttribute('value')

// copied from FormDataMixin temporarily
const toggleValue = (arr, val) => {
  const valueIndex = arr.indexOf(val)

  if (valueIndex !== -1) {
    arr.splice(valueIndex, 1)
  } else {
    arr.push(val)
  }

  return arr
}

// copied from FormDataMixin temporarily
const getValue = (el, currentValue) => {
  if (!isCheckbox(el)) {
    return el.value
  }

  if (isMultiChoice(el)) {
    currentValue = currentValue || []
    return toggleValue(currentValue, el.value)
  }

  return el.checked
}

class FormComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    submitText: PropTypes.string,
    submittingText: PropTypes.string,
    buttonsRowClassName: PropTypes.string,
    initialFormData: PropTypes.object,
    // means any POST/PUT request is currently run somewhere
    saving: PropTypes.bool,
    // todo: automate this, don't like the idea. just for now, a quick hack...
    validateOnMount: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    disableCancel: PropTypes.bool,
    hideButtons: PropTypes.bool,
    method: PropTypes.string,
    colWidth: PropTypes.number
  }

  static defaultProps = {
    submitText: t('OK'),
    submittingText: t('Saving ...'),
    initialFormData: {},
    buttonsRowClassName: 'cancel-submit-row',
    colWidth: 10,
    disableCancel: false
  }

  static REPEATED_DATA_INVALID =
    'Repeated data is invalid. Must be either an array or a string.'

  static joinRepeatedData = function (data) {
    // initially these are arrays when untouched but upon user actions they become strings
    // TODO add new prop to explicitly define whether repeated data is
    // new line separated text or not.
    if (Array.isArray(data)) {
      return data
    } else if (typeof data === 'string') {
      const joinedData = data
        .split(NL)
        .filter((item) => item.trim().length >= 1)
        .map((item) => item.trim())

      if (joinedData.length < 1) return undefined

      return joinedData
    }

    throw new Error(this.REPEATED_DATA_INVALID)
  }

  constructor(props) {
    super(props)

    this.formRef = React.createRef()

    this.state = {
      invalid: true,
      submitted: false
    }
  }

  // NOTE: this function is not reliable anymore because
  // it may still include data from non-form inputs such as labels.
  getData() {
    const children = collectAllChildren(this)

    children.forEach((child) => {
      if (child.props.readOnly || child.props.disabled) {
        delete this.formData[child.props.name]
      } else if (child.props.name && child.props.repeat) {
        if (this.formData[child.props.name]) {
          this.formData[child.props.name] = this.joinRepeatedData(
            this.formData[child.props.name]
          )
        } else {
          // html textareas representing a list and when empty,
          // produce an empty string like ''
          // we must delete these so that the backend
          // won't complain (accepts null or an array but
          // not an empty string)
          delete this.formData[child.props.name]
        }
      }
    })

    return this.formData
  }

  getInitialFormData() {
    return this.props.initialFormData
  }

  disableSubmit() {
    return !!(this.props.saving || this.state.invalid || this.props.disableSubmit)
  }

  getSubmitText() {
    if (this.props.saving && this.state.submitted) {
      return this.props.submittingText
    }

    return this.props.submitText
  }

  getFormElement() {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this.formRef.current)
  }

  runValidation() {
    // circumvents react's async state handling, because on the change event
    // child react components haven't completed the setState() yet.
    // So, have a timeout here to wait until the next cycle.
    setTimeout(() => {
      const form = this.getFormElement()

      if (form) {
        const valid = form.checkValidity()

        this.setState({ invalid: !valid })
      }
    })
  }

  // copied from FormDataMixin temporarily
  updateFormData = (e) => {
    const t = e.target
    const key = t.getAttribute('name')

    if (key) {
      const newValue = getValue(t, this.formData[key])
      this.formData[key] = newValue
    }
  }

  onChange = (e) => {
    this.updateFormData(e)
    this.runValidation()
  }

  onSubmit = (e) => {
    this.setState({ submitted: true, invalid: true })
    this.props.onSubmit(e)
  }

  focusFirstInput() {
    setTimeout(() => {
      const form = this.getFormElement()

      if (form) {
        const input = Object.values(form.elements).find(
          (element) => element.tagName === 'INPUT'
        )

        input?.focus()
      }
    })
  }

  UNSAFE_componentWillMount() {
    this.formData = this.getInitialFormData()
    this.props.validateOnMount && this.runValidation()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    !nextProps.saving && this.setState({ submitted: false })
  }

  componentDidMount() {
    this.focusFirstInput()
  }

  renderButtons() {
    return (
      <Grid className='formFooter'>
        <CancelSubmitButtons
          colWidth={this.props.colWidth}
          submitText={this.getSubmitText()}
          disableCancel={this.props.disableCancel}
          disableSubmit={this.disableSubmit()}
          onCancel={this.props.onCancel}
        />
      </Grid>
    )
  }

  render() {
    return (
      <Form
        horizontal
        ref={this.formRef}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        className={this.props.className}
        method={this.props.method}
      >
        {this.props.children}
        {!this.props.readOnly && !this.props.hideButtons && this.renderButtons()}
      </Form>
    )
  }
}

export default FormComponent
