const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const User = require('admin/core/User')
const validators = require('validators').default
const config = require('theming/config')
const { logoutQuietly } = require('system/logout')
const Alert = require('admin/util/Alert').default

const UserDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  title: t('Details'),
  height: 'auto',
  closeOnSave: false,
  cls: 'user-detail-panel',
  tabCls: 'user-detail-tab',

  module() {
    return require('admin/business/UserResource')
  },

  getFormPanel() {
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      operation: 'save',
      defaults: {
        width: config.panel.fieldWidth
      },
      readOnly: this.isOwnProfile() ? false : null,
      items: this.getDetailItems(),
      tbar: ['->', this.constructPasswordButtons()]
    })
  },

  isOwnProfile() {
    return this.record.isOwnProfile()
  },

  getDetailItems() {
    return [
      {
        fieldLabel: t('First Name'),
        name: 'firstName',
        maxLength: 100,
        allowBlank: false,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Last Name'),
        name: 'lastName',
        maxLength: 100,
        allowBlank: false,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Email Address'),
        name: 'userName',
        xtype: 'textfield',
        helpText: t('Email address is also the user login name'),
        validator: validators.email.withMsg(
          t('This field should be an e-mail address in the format "user@example.com"')
        ),
        allowBlank: false
      },
      {
        xtype: 'textfield',
        fieldLabel: t('Phone Number'),
        name: 'contactNumber',
        width: 100
      },
      {
        xtype: 'textfield',
        fieldLabel: t('Phone Extension'),
        name: 'extension',
        width: 100,
        validator: validators.positiveInteger.withMsg(t('Only numbers are allowed.')),
        maskRe: /\d/
      }
    ]
  },

  getDirtyEmail() {
    const userName = this.formPanel.find('name', 'userName')

    if (!userName) return false
    if (!userName[0]) return false

    return userName[0].isDirty()
  },

  handleSave() {
    const dirtyEmail = this.getDirtyEmail()

    if (this.isOwnProfile() && dirtyEmail) {
      Ext.Msg.confirm(
        t('Confirm Logout'),
        t(
          'Changing your email address will immediately cause a logout. Are you sure you want to do this?'
        ),
        (btn) => {
          if (btn === 'yes') {
            this.record.on('sync', () => logoutQuietly(), this, { single: true })
            this.superclass().handleSave.call(this)
          }
        }
      )
    }
    return this.callParent()
  },

  getViewPanel() {
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      items: this.getViewItems(),
      tbar: ['->', this.constructPasswordButtons()]
    })
  },

  showChangePasswordWindow(uri) {
    const ChangePasswordWindow = require('admin/component/ChangePasswordWindow').default
    return new ChangePasswordWindow({
      uri,
      isOwnProfile: this.isOwnProfile()
    }).show()
  },

  resetPassword(uri) {
    const title = t('Confirm Reset Password')
    const text = t('Are you sure you want to reset this password?')

    return Ext.Msg.confirm(title, text, function (btn) {
      if (btn === 'yes') {
        Ext.MessageBox.wait(t('Sending password reset email...'))
        return Ext.Ajax.request({
          url: uri,
          method: 'POST',
          params: 'password=',
          callback() {
            return Ext.MessageBox.hide()
          },
          success() {
            return Alert.info(
              t('Password Reset'),
              t('Password reset instructions have been emailed to the user.')
            )
          },
          failure(response) {
            return Alert.displayResponseError(response)
          }
        })
      }
    })
  },

  constructPasswordButtons() {
    const updatePasswordLink = this.record.getUpdatePasswordLink()

    return [
      User.hasRole('MANAGE_USERS') &&
        !this.isOwnProfile() &&
        new Ext.Button({
          disabled: updatePasswordLink == null,
          text: t('Set Password'),
          handler: () => this.showChangePasswordWindow(updatePasswordLink)
        }),

      new Ext.Button({
        disabled: updatePasswordLink == null,
        text: this.isOwnProfile() ? t('Change Password') : t('Reset Password'),
        handler: () => {
          if (this.isOwnProfile()) {
            return this.showChangePasswordWindow(updatePasswordLink)
          }
          this.resetPassword(updatePasswordLink)
        }
      })
    ]
  },

  disableEdit() {
    return this.record.getUpdateDetailsLink() == null
  },

  adjustViewPanel() {
    if (this.record.isDelegated()) {
      this.viewPanel.getFooterToolbar().add(new Ext.Spacer({ width: 2 }))
      return this.viewPanel.getFooterToolbar().add(
        new Ext.form.Label({
          text: t("This user's details are managed under {0}", [
            this.record.getHumanAuthenticationSource()
          ])
        })
      )
    }
  }
})

module.exports = UserDetailPanel
