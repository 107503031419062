import {
  init as initTenancies,
  unload as unloadTenancies
} from 'integration/tenancy/actions'

import Ext from 'ext'
import React from 'react'
import ReduxContainerPanel from 'admin/view/ReduxContainerPanel'
import TenancyTab from 'integration/tenancy/views/Tab'
import { hasModalDialog } from 'integration/tenancy/subs'
import t from 'translate'

const IntegrationTabPanel = Ext.define(null, {
  extend: ReduxContainerPanel,

  title: t('Integrations'),
  cls: 'integration-panel',
  tabCls: 'integration-tab',

  initComponent: function () {
    // do not initialise app state when hot reloading for local development
    if (!this.initialConfig.hotReloading) {
      // synchronous db initialisation here, set uri for company key so that
      // we don't have to painfully pass uri along to any react component
      this.store.dispatch(
        initTenancies(this.initialConfig.companyKey, this.initialConfig.tenancyListUri)
      )
    }

    this.component = React.createElement(TenancyTab, this.initialConfig)

    // trick to call parent extjs function from es6 within
    this.superclass().initComponent.call(this)
  },

  preventClose: function () {
    return hasModalDialog(this.store.getState(), this.companyKey)
  },

  destroy: function () {
    this.superclass().destroy.call(this)

    // cleanup only at the very end when all else has been destroyed
    this.store.dispatch(unloadTenancies(this.companyKey))
  }
})

export default IntegrationTabPanel
