const Record = require('admin/core/Record')
const CompanyRecord = require('admin/data/company/CompanyRecord')
const VendorResource = require('admin/business/VendorResource')
const CompanyFields = require('admin/data/company/fields/company').default
const DistributorStore = require('admin/data/company/DistributorStore')

// clone default company fields and concat more for the vendor record
const fields = CompanyFields.concat([
  {
    name: 'defaultURI',
    type: 'string'
  },
  {
    name: 'products',
    mapping: 'defaultURI',
    convert(url) {
      return {
        INBOUND_SCRUB: {
          stubbed: true
        }, // fake stub for detecting products

        SMART_RULES: {
          current: true,
          description: '',
          fromDate: 0,
          productCategory: 'FILTERING',
          productCode: 'SMART_RULES',
          resources: {
            DATA_SETS: {
              description: '',
              url: `/api${url}/datasets`
            },
            RULE_COMPILER: {
              description: '',
              url: `/api${url}/rulecompiler`
            },
            RULE_SETS: {
              description: '',
              url: `/api${url}/smartrules/rulesets`
            },
            RULES: {
              description: '',
              url: `/api${url}/smartrules/rules`
            },
            TEMPLATES: {
              description: '',
              url: `/api${url}/templates`
            }
          }
        },
        VENDOR_TOOLS: {
          productCode: 'VENDOR_TOOLS',
          productCategory: 'FILTERING',
          resources: {
            REASSIGN_TOOL: {
              description: '',
              url: ''
            },
            CONVERT_TOOL: {
              description: '',
              url: ''
            },
            VENDOR_BRAND_ROLLER: {
              description: '',
              url: ''
            }
          }
        },
        OUTBOUND_SCRUB: {
          stubbed: true
        } // for detecting should be outbound
      }
    }
  }
])

const VendorRecord = Record.define(null, {
  extend: CompanyRecord,

  module() {
    return VendorResource
  },

  fields,

  getDistributorStore() {
    if (!this.distributorStore) {
      this.distributorStore = new DistributorStore({ parentRecord: this })
    }
    return this.distributorStore
  },

  isVendor() {
    return true
  }
})

module.exports = VendorRecord
