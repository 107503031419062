const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const CurrencyAndPriceField = require('admin/component/form/CurrencyAndPriceField')
const DateRangeFieldSet = require('admin/component/form/DateRangeFieldSet')
const config = require('theming/config')

const ResellerAgreementDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'reselleragreement-detail-panel',

  module() {
    return require('admin/business/ResellerAgreementResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems(cfg) {
    const items = [
      new ReadOnlyTextField({
        fieldLabel: t('Reseller Type'),
        locked: true,
        name: 'agreementTypeName'
      }),
      new ReadOnlyTextField({
        fieldLabel: t('Product Name'),
        locked: true,
        name: 'productName'
      })
    ]

    if (cfg.record.get('agreementType') === 'WHOLESALE_RESELLER') {
      // currency means fixed price; needs improved
      const isFixedPrice = !!cfg.record.get('fixedBuyPrice.priceCurrencyUom')
      if (isFixedPrice) {
        items.push(
          new CurrencyAndPriceField({
            currencyComboName: 'fixedBuyPrice.priceCurrencyUom',
            priceAmountFieldName: 'fixedBuyPrice.priceAmount',
            priceAmountFieldListeners: {
              afteraddvalidator: {
                fn: this.module.fixedPriceValidator
              }
            }
          })
        )
      } else {
        items.push({
          fieldLabel: t('Discount %'),
          name: 'commission.percentage',
          xtype: 'numberfield',
          allowNegative: false,
          maxValue: 100,
          allowBlank: false,
          listeners: {
            afteraddvalidator: {
              fn: this.module.discountValidator
            }
          }
        })
      }
    } else if (cfg.record.get('agreementType') !== 'ASSOCIATE_RESELLER') {
      items.push({
        fieldLabel: t('Commission %'),
        name: 'commission.percentage',
        xtype: 'numberfield',
        maxValue: 100,
        minValue: 0,
        listeners: {
          afteraddvalidator: {
            fn: this.module.discountValidator
          }
        }
      })
    }

    items.push(
      new DateRangeFieldSet({
        minValue: cfg.record?.get('fromDate'),
        from: {
          name: 'fromDate',
          allowBlank: false
        },
        thru: {
          name: 'thruDate',
          allowBlank: true
        },
        both: {
          fieldWidth: config.panel.fieldWidth
        }
      })
    )

    return items.filter(Boolean)
  }
})

module.exports = ResellerAgreementDetailPanel
