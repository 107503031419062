const Ext = require('ext')

const ReadOnlyTextField = Ext.define(null, {
  extend: Ext.form.TextField,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      readOnly: true,
      cls: 'readonly-textfield',
      xtype: 'readonly-textfield'
    })

    if (cfg.locked) {
      cfg.cls += ' locked-textfield'
    }

    if (cfg.clone) {
      Ext.applyIf(cfg, {
        selectOnFocus: true,
        helpText: cfg.clone.helpText,
        hideLabel: cfg.clone.hideLabel,
        fieldLabel: cfg.clone.fieldLabel,
        name: cfg.clone.name,
        params: cfg.clone.params,
        width: cfg.clone.width,
        value: cfg.clone.value,
        anchor: cfg.clone.anchor,
        autoHeight: cfg.clone.autoHeight
      })
      if (cfg.clone.renderer) {
        cfg.renderer = cfg.clone.renderer
      }
    }

    this.callParent([cfg])

    this.params = cfg.params || []
    if (!Array.isArray(this.params)) {
      this.params = [this.params]
    }
  },

  renderer(v) {
    return v
  },

  setValue(value) {
    if (value != null) {
      this.callParent([this.renderer.apply(this, [value].concat(this.params))])
    }
  },

  highlighted: false,

  highlight() {
    return (this.highlighted = true)
  },

  unHighlight() {
    return (this.highlighted = false)
  },

  isHighlighted() {
    return this.highlighted
  }
})

module.exports = ReadOnlyTextField
