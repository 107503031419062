const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')
const MatcherCombo = require('smartrules/designer/MatcherCombo')
const NoMatcher = require('smartrules/designer/matchers/NoMatcher')
const AddressMatcher = require('smartrules/designer/matchers/AddressMatcher')
const TextMatcher = require('smartrules/designer/matchers/TextMatcher')
const ComboBox = require('admin/component/form/ComboBox')

const getDefaultMatcher = function (cfg) {
  if (cfg.toOriginalSender) return 'sender'
  if (cfg.toOriginalRecipient) return 'original-recipients'
  if (cfg.toMatchedRecipient) return 'matched-recipients'
  return 'address'
}

const SendNotification = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    let recipientCombo,
      senderMatcherCombo,
      senderPanel,
      subjectMatcher,
      subjectMatcherCombo,
      subjectPanel,
      template

    cfg = Ext.applyIf(cfg, {
      label: t('Send'),
      noSpacer: true,
      type: 'smartrules-send-notification'
    })
    const { templateStore } = cfg
    if (templateStore?.getCount() === 0) {
      templateStore.load()
    }

    this.templateCmp = template = new ComboBox({
      allowBlank: false,
      displayField: 'name',
      editable: false,
      flex: 0.75,
      forceSelection: true,
      mode: 'local',
      listEmptyText: `<div class="x-combo-list-item">${t('No templates found.')}</div>`,
      name: 'template',
      store: templateStore,
      toDsl() {
        return `[${this.getValue()}]`
      },
      triggerAction: 'all',
      value: cfg.value,
      valueField: 'name'
    })

    const defaultMatcher = getDefaultMatcher(cfg)

    this.recipientCmp = recipientCombo = new MatcherCombo({
      border: false,
      matcher: defaultMatcher,
      matchers: [
        {
          matcher: 'sender',
          name: t('the message sender'),
          clazz: NoMatcher,
          dsl: 'original sender'
        },
        {
          matcher: 'address',
          name: t('the address'),
          clazz: AddressMatcher,
          dsl: '{0}'
        },
        {
          matcher: 'original-recipients',
          name: t('the original recipients'),
          clazz: NoMatcher,
          dsl: 'original recipients'
        },
        {
          matcher: 'matched-recipients',
          name: t('matched recipients'),
          clazz: NoMatcher,
          dsl: 'matched recipients'
        }
      ],
      matcherValue: cfg.recipient || ''
    })

    const templatePanel = {
      border: false,
      items: [
        {
          border: false,
          items: template,
          xtype: 'panel'
        },
        {
          bodyStyle: {
            'line-height': '26px'
          },
          border: false,
          padding: '0 10',
          height: 26,
          html: t('to')
        },
        recipientCombo
      ],
      layout: 'hbox'
    }

    this.senderMatcherCombo = senderMatcherCombo = new MatcherCombo({
      border: false,
      matcher: cfg.fromOriginalRecipient ? 'recipient' : 'address',
      matchers: [
        {
          matcher: 'recipient',
          name: t('original recipient'),
          clazz: NoMatcher,
          dsl: 'original recipient'
        },
        {
          matcher: 'address',
          name: t('address'),
          clazz: AddressMatcher,
          dsl: '{0}'
        }
      ],
      matcherValue: cfg.sender || '',
      margins: '3 0 0'
    })

    this.senderCmp = senderPanel = new Ext.Panel({
      border: false,
      cls: 'smartrules-map',
      items: [
        {
          labelStyle: 'text-align: right',
          text: `${t('From')}:`,
          width: 60,
          xtype: 'label'
        },
        senderMatcherCombo
      ],
      layout: 'hbox',
      toDsl() {
        return senderMatcherCombo.toDsl()
      }
    })
    if (cfg.prefixSubject) {
      subjectMatcher = cfg.subject === '' ? 'original' : 'prefix'
    } else {
      subjectMatcher = 'text'
    }
    this.subjectMatcherCombo = subjectMatcherCombo = new MatcherCombo({
      border: false,
      matcher: subjectMatcher,
      matchers: [
        {
          matcher: 'original',
          name: t('original'),
          clazz: NoMatcher,
          dsl: 'prefixed with ""'
        },
        {
          matcher: 'prefix',
          name: t('prefix original with'),
          clazz: TextMatcher,
          dsl: 'prefixed with {0}'
        },
        {
          matcher: 'text',
          name: t('replace original with'),
          clazz: TextMatcher,
          dsl: '{0}'
        }
      ],
      matcherValue: cfg.subject || '',
      margins: '3 0 0'
    })
    this.subjectCmp = subjectPanel = new Ext.Panel({
      border: false,
      cls: 'smartrules-map',
      items: [
        {
          labelStyle: 'text-align: right',
          text: `${t('Subject')}:`,
          width: 60,
          xtype: 'label'
        },
        subjectMatcherCombo
      ],
      layout: 'hbox',
      toDsl() {
        return subjectMatcherCombo.toDsl()
      }
    })
    const wrap = new Ext.Panel({
      flex: 1,
      height: 100, // height calculation not working for IE, so fixing it here
      items: [
        templatePanel,
        {
          cls: 'smartrules-map smx-notification-headers',
          value: t('Headers'),
          xtype: 'displayfield'
        },
        senderPanel,
        subjectPanel
      ],
      layout: {
        align: 'stretch',
        type: 'vbox'
      }
    })

    this.callParent([cfg])
    this.insert(3, wrap)
  },
  toDsl() {
    return `send ${this.templateCmp.toDsl()} notification to ${this.recipientCmp.toDsl()} from ${this.senderCmp.toDsl()} with subject ${this.subjectCmp.toDsl()}`
  }
})

module.exports = SendNotification
