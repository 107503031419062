import { Col } from 'react-bootstrap'
import MemberPanel from 'membership/member/views/Panel'
import PropTypes from 'prop-types'
import React from 'react'
import t from 'translate'

class MembershipList extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    collapsible: PropTypes.bool,
    loading: PropTypes.bool,
    membership: PropTypes.object,
    onChange: PropTypes.func,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  renderType = (type) => {
    const members = this.props.membership.getSortedMembersByType(type)
    // todo: prevent rendering those members which are off-screen
    if (members.length) {
      return (
        <MemberPanel
          defaultExpanded
          key={type}
          type={type}
          membership={this.props.membership}
          collapsible={this.props.collapsible}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        />
      )
    }
  }

  getSortedTypes = () => this.props.membership?.getSortedTypes()

  hasTypes = () => {
    const sortedTypes = this.getSortedTypes()
    return sortedTypes?.length && sortedTypes.map
  }

  renderTypes = () => this.getSortedTypes().map(this.renderType)

  renderNoResults = () => {
    return <div className='no-results'>{t('Nothing to configure')}</div>
  }

  render() {
    return (
      <Col
        className='membership-list'
        style={{
          height: this.props.height
        }}
      >
        {this.hasTypes() && this.renderTypes()}
        {!this.props.loading && !this.hasTypes() && this.renderNoResults()}
      </Col>
    )
  }
}

export default MembershipList
