const Ext = require('ext')
const t = require('translate')
const CompanyDetailPanel = require('admin/view/CompanyDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const CurrencyResource = require('admin/business/CurrencyResource')
const CountryResource = require('admin/business/CountryResource')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')

const DistributorDetailPanel = Ext.define(null, {
  extend: CompanyDetailPanel,

  title: t('Details'),

  module() {
    return require('admin/business/DistributorResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      width: 725,
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    let countryCombo, defaultCurrencyCombo
    const valueWidth = 300

    if (CurrencyResource.currencyChoiceExists()) {
      defaultCurrencyCombo = CurrencyResource.getComboBox({
        fieldLabel: t('Default Currency'),
        hiddenName: 'partyBillings[0].defaultCurrency',
        width: valueWidth
      })
    }

    if (CountryResource.countryChoiceExists()) {
      countryCombo = CountryResource.getComboBox({
        fieldLabel: t('Country'),
        hiddenName: 'primaryPostalAddress.countryGeo',
        width: valueWidth
      })
    }

    return [
      {
        title: t('Details'),
        width: 'auto',
        height: 'auto',
        xtype: 'fieldset',
        items: [
          new ReadOnlyTextField({
            fieldLabel: t('Vendor Name'),
            name: 'vendorName',
            width: valueWidth,
            locked: true
          }),
          {
            fieldLabel: t('Distributor Name'),
            name: 'displayName',
            width: valueWidth,
            maxLength: 100,
            allowBlank: false,
            xtype: 'textfield'
          },
          {
            fieldLabel: t('Distributor Reference'),
            name: 'externalId',
            width: valueWidth,
            maxLength: 30,
            xtype: 'textfield',
            readOnly: false
          },
          defaultCurrencyCombo || undefined,
          {
            title: t('Primary Address'),
            width: 'auto',
            height: 'auto',
            items: [
              {
                fieldLabel: t('Attention Name'),
                name: 'primaryPostalAddress.attnName',
                width: valueWidth,
                maxLength: 100,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('Address Line 1'),
                name: 'primaryPostalAddress.address1',
                width: valueWidth,
                maxLength: 255,
                allowBlank: false,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('Address Line 2'),
                name: 'primaryPostalAddress.address2',
                width: valueWidth,
                maxLength: 255,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('City / Town'),
                name: 'primaryPostalAddress.city',
                width: valueWidth,
                maxLength: 100,
                allowBlank: false,
                xtype: 'textfield'
              },
              {
                fieldLabel: t('Postal Code'),
                name: 'primaryPostalAddress.postalCode',
                width: valueWidth,
                maxLength: 60,
                xtype: 'textfield'
              },
              countryCombo || undefined
            ].filter(Boolean),
            xtype: 'fieldset'
          }
        ].filter(Boolean)
      }
    ]
  }
})

module.exports = DistributorDetailPanel
