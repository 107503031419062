const Ext = require('ext')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const openUri = require('admin/desktop/openUri').default
const logger = require('system/logger').default

const CompanyDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'company-detail-panel',

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      closeOnSave: false,
      openOnSave: true
    })
    this.callParent([cfg])
  },

  getSaveOptions(record) {
    return {
      details: true,
      url: record.getUrl(),
      method: 'PUT'
    }
  },

  getParent() {
    return this.formPanel?.record.getLinkByRelDeferred('parent')
  },

  gotoParent() {
    const parent = this.getParent()

    if (!parent) return

    parent.then((link) => openUri(link)).catch((err) => logger.error(err))
  }
})

module.exports = CompanyDetailPanel
