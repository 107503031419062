const _s = require('underscore.string')
const trimCharacter = require('trim-character')
const escapeRegexString = require('escape-regex-string')
const highlightRegexCache = {}

// Returns one RegExp object based on searchExpressions.
// Some special characters are allowed in search expressions.
// The optional parameter `keyword` is a single keyword this function must respect.
// In short: the given keyword influences the matching algorithm (app logic here)
// todo: give this function a better name
const makeRegexForHighlighting = function (
  searchExpressions,
  keyword,
  availableKeywords
) {
  let algorithm, keywordsRegex, regex, separator

  if (!searchExpressions) return null

  const hashKey = [availableKeywords.toString(), keyword, searchExpressions].join('#')

  if (hashKey in highlightRegexCache) {
    // grab it from cache
    return highlightRegexCache[hashKey]
  }

  const patterns = []

  if (searchExpressions && typeof searchExpressions.valueOf() === 'string') {
    searchExpressions = searchExpressions.split(' ')
  }

  // Algorithm:
  // exact = matches whole text between separators, i.E. one email within recipients
  // whole = highlights whole text, even when only some letters inside do match
  // word boundary (exact) = normal mode, highlight  words whose parts match
  switch (keyword) {
    case 'sender':
      algorithm = 'whole'
      break
    case 'recipient':
      algorithm = 'exact'
      separator = ', '
      break
    default:
      algorithm = 'exact'
      separator = ' '
  }

  if (keyword && availableKeywords) {
    keywordsRegex = new RegExp('(' + availableKeywords.join('|') + '):')
  }

  // Mark positions of all matches and prepare replacements.
  searchExpressions.every(function (searchExpression) {
    let wildcardExpression
    let stop = false

    if (!searchExpression) return !stop

    // Special treatment for keywords
    if (keywordsRegex && keywordsRegex.test(searchExpression)) {
      // Remove keyword from search expression if it matches with the assigned one
      if (_s.startsWith(searchExpression, keyword)) {
        searchExpression = _s.strRight(searchExpression, keyword + ':')
      } else {
        // Abort current expression, keyword does not match
        return !stop
      }
    }

    // Escape some special chars, but not all, to prevent regex abuse
    // But allow *, ? and [] for now.
    searchExpression = escapeRegexString(searchExpression, /[@\-|\\{}()^$+.]/g)

    // Replace any wildcard(s) with the proper regex syntax. Since it's already tokenized,
    // we do not have to worry about whitespaces.
    if (separator) {
      // Beware that on separators we have to exclude some characters from the searchExpression
      // so that these aren't included. For example when search is '*sod*' do not highlight all:
      // eros@sodales.com, eros@sodales.com, sodales@arcu.com, tempus@blandit.com
      // but only highlight this:
      // sodales@arcu.com
      wildcardExpression = '[^' + separator + ']*'
    } else {
      wildcardExpression = '.*'
    }

    searchExpression = searchExpression.replace(/\*/gi, wildcardExpression)

    // ? matches any 1 character but not empty space
    searchExpression = searchExpression.replace(/\?/gi, '\\S')

    // for some algorithms we want to highlight the complete text between separators, hence
    // the need for wildcardExpression.
    // beware not to add multiple wildcard expressions in a row, otherwise we'll risk
    // a weird memory overflow on safari due to unprotected recursion, see SCL-990
    // but honestly, browsers should be robust for /.*.*.*/gi ...
    // so, the following five code lines ensure that no unnecessary wildcards are
    // prepended and amended around the search expression when search expression already has these:

    searchExpression = trimCharacter(searchExpression, wildcardExpression)

    if (searchExpression.length < 1) {
      searchExpression = wildcardExpression
    } else {
      searchExpression = _s.surround(searchExpression, wildcardExpression)
    }

    if (algorithm === 'whole') {
      stop = true
    }

    patterns.push(searchExpression)

    return !stop // exits every() loop when stop is true
  })

  if (!(patterns.length > 0)) return null

  // turn it into one regex, but be robust here
  try {
    const uniquePatterns = [...new Set(patterns)]
    regex = new RegExp(uniquePatterns.join('|'), 'gi')

    // cache it for speed
    highlightRegexCache[hashKey] = regex
  } catch (error) {
    // ignore
  }

  return regex
}

makeRegexForHighlighting.getCache = () => highlightRegexCache

module.exports = makeRegexForHighlighting
