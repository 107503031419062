import compareStrings from 'strings/compare/lexical'
import isString from 'strings/isString'

export const ENVELOPE_SENDER_CONDITION_VALUE = 'envelope-sender'
export const ENVELOPE_RECIPIENT_CONDITION_VALUE = 'envelope-recipients'

export function isEnvelope(anything) {
  if (!anything) return false
  if (!isString(anything)) return false

  return (
    compareStrings(anything, ENVELOPE_SENDER_CONDITION_VALUE) === 0 ||
    compareStrings(anything, ENVELOPE_RECIPIENT_CONDITION_VALUE) === 0
  )
}
