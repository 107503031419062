const Ext = require('ext')
const t = require('translate')
const TabPanel = require('admin/view/TabPanel').default
const DomainSummaryGridPanel = require('admin/component/grid/DomainSummaryGridPanel')
const MailStatsPanel = require('admin/view/MailStatsPanel')

const InboundScrubbingTabPanel = Ext.define(null, {
  extend: TabPanel,

  title: t('Inbound Scrubbing'),
  tabCls: 'inbound-scrubbing-tab',
  cls: 'inbound-scrubbing-panel',

  constructor(cfg = {}) {
    const PolicyManagementListPanel = require('admin/view/PolicyManagementListPanel')
    const EventSearchPanel = require('admin/view/EventSearchPanel')

    this.customer = cfg.customer
    this.product = cfg.product

    if (!this.product.current) {
      this.tabCls = this.tabCls + ' expired-group-tab'
    }

    this.items = [
      new DomainSummaryGridPanel({ customer: this.customer, product: this.product }),
      new (require('admin/view/ScrubbingDomainListPanel'))({
        customer: this.customer,
        product: this.product,
        store: this.customer.getScrubbingDomainStore()
      }),
      new (require('admin/view/MailRelayHostListPanel'))({
        customer: this.customer,
        product: this.product,
        store: this.customer.getMailRelayHostStore(),
        domainStore: this.customer.getScrubbingDomainStore()
      }),

      new PolicyManagementListPanel({ customer: this.customer, product: this.product }),
      new EventSearchPanel({ customer: this.customer, product: this.product }),
      new MailStatsPanel({ customer: this.customer, product: this.product })
    ]

    this.callParent()
  }
})

module.exports = InboundScrubbingTabPanel
