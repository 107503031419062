import React, { Component } from 'react'
import { deleteTenancies, stopConfirmingDeletion } from 'integration/tenancy/actions'
import {
  loading,
  selectedCount,
  selectedTenanciesLinks,
  selectedTenancyName,
  uri
} from 'integration/tenancy/subs'

import Dialog from 'components/dialog/Confirmation'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class Delete extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.deleteTenancies(this.props.uri, this.props.selectedTenanciesLinks)
  }

  render() {
    let title, warning

    if (this.props.selectedCount === 1) {
      title = t('Delete tenancy "{0}"?', [this.props.selectedTenancyName])

      warning = t(
        'This will permanently delete tenancy "{0}". Any delegated user of that tenancy will be unable to login.',
        [this.props.selectedTenancyName]
      )
    } else {
      title = t('Delete {0} tenancies?', [this.props.selectedCount])

      warning = t(
        'This will permanently delete {0} tenancies. Any delegated users of these tenancies will be unable to login.',
        [this.props.selectedCount]
      )
    }

    return (
      <Dialog
        icon='exclamation'
        modalTitle={title}
        headerText={warning}
        submitButtonText={t('Delete')}
        cancelDisabled={this.props.loading}
        submitDisabled={this.props.loading}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  loading: loading(db, props.companyKey),
  selectedCount: selectedCount(db, props.companyKey),
  selectedTenancyName: selectedTenancyName(db, props.companyKey),
  selectedTenanciesLinks: selectedTenanciesLinks(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopConfirmingDeletion(initialProps.companyKey)),
  deleteTenancies: (uri, selectedTenanciesLinks) => {
    dispatch(deleteTenancies(initialProps.companyKey, uri, selectedTenanciesLinks))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Delete)
