import t from 'translate'
import React, { Component } from 'react'
import { Tab } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Tabs from 'components/Tabs'
import Search from 'archiving/messages/views/Tab'
import Configuration from 'archiving/membership/views/Tab'
import Help from 'archiving/help/views/Tab'

const BOOTSTRAP_PADDING = 5

class ArchivingTabs extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    archivingUrl: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    showConfig: PropTypes.bool
  }

  renderSearch() {
    return (
      <Tab
        eventKey={1}
        title={t('Search')}
        tabClassName='archiving-search'
        style={{
          height: this.props.height - 4 * BOOTSTRAP_PADDING,
          overflowY: 'auto'
        }}
      >
        <Search
          companyKey={this.props.companyKey}
          width={this.props.width}
          height={this.props.height}
          store={this.props.store}
        />
      </Tab>
    )
  }

  renderConfiguration() {
    return (
      <Tab eventKey={2} title={t('Configuration')} tabClassName='archiving-config'>
        <Configuration
          companyKey={this.props.companyKey}
          archivingUrl={this.props.archivingUrl}
          height={this.props.height}
          configRef={(el) => {
            this.configEl = el
          }}
        />
      </Tab>
    )
  }

  renderHelp() {
    return (
      <Tab
        eventKey={3}
        title={t('Help')}
        style={{
          height: this.props.height - 6 * BOOTSTRAP_PADDING,
          overflowY: 'auto'
        }}
      >
        <Help />
      </Tab>
    )
  }

  render() {
    // we need to forward the height and sometimes the width to all tabs,
    // this to ensure proper scrollbar functionality. unfortunately.
    // this to ensure scrollbars appear when the above extjs window
    // gets resized, becomes smaller than the required area.
    return (
      <Tabs
        tabsId='archiving-tabs'
        defaultActiveKey={1}
        width={this.props.width}
        height={this.props.height}
      >
        {this.renderSearch()}
        {this.props.showConfig && this.renderConfiguration()}
        {this.renderHelp()}
      </Tabs>
    )
  }
}

export default ArchivingTabs
