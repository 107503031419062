import { toString } from 'admin/time/Time'

export const toMailSearchResults = (messages = [], options = {}) => {
  return messages.map((message) => {
    // TODO: saving original event for further processing
    const converted = { orig: message }

    for (const k in message) {
      const value = message[k]

      switch (k) {
        case 'received':
          converted[k] = toString(value, options.timeZoneName)
          break
        default:
          converted[k] = value
      }
    }

    return converted
  })
}
