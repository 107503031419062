const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const Time = require('admin/time/Time')
const SearchField = require('admin/component/form/SearchField')
const escape = require('html-escaper').escape

const NoteListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  title: t('Notes'),
  autoExpandColumn: 'party',
  cls: 'note-list-panel',
  tabCls: 'note-list-tab',

  initComponent() {
    this.module = require('admin/business/NoteResource')

    this.viewConfig = {
      enableRowBody: true,
      rowHeight: 38,
      getRowClass(record, i, p) {
        const info = escape(record.get('noteInfo'))
        const name = escape(record.get('noteName'))

        let body = name
        if (info) {
          body += `: ${info}`
        }

        p.body = `<p>${body}</p>`
        return ''
      }
    }

    this.on('show', function () {
      if (this.rendered) {
        this.getStore().reload()
      }
    })

    this.callParent()
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel([
      {
        id: 'noteDate',
        header: t('Date'),
        width: 100,
        sortable: true,
        dataIndex: 'noteDate',
        renderer: Time.toString
      },
      {
        id: 'party',
        header: t('User'),
        width: 80,
        sortable: true,
        dataIndex: 'noteTypeName',
        autoEncode: false
      }
    ])
  },

  filterNotes() {
    if (!this.rendered) return

    const tokens = this.search.getValue().toLowerCase().trim().split(/\s+/)

    const includesAllTokens = function (value) {
      value = value.trim().toLowerCase()
      return tokens.every(
        (
          token // using old fashioned indexOf for speed
        ) => value.indexOf(token) >= 0
      )
    }

    return this.store.filter({
      fn(record) {
        // make sure to use exactly the same string representation we have for the grid model above
        const noteDateString = Time.toString(record.get('noteDate'))

        return (
          includesAllTokens(noteDateString) ||
          includesAllTokens(record.get('noteTypeName')) ||
          includesAllTokens(record.get('noteName')) ||
          includesAllTokens(record.get('noteInfo'))
        )
      }
    })
  },

  getToolbarItems() {
    this.search = new SearchField({
      width: 170,
      emptyText: t('Filter'),
      listeners: {
        keyup: () => {
          return this.filterNotes()
        }
      }
    })

    return [
      this.getCreateButton(),
      this.getOpenButton(),
      this.getRemoveButton(),
      new Ext.Spacer({ width: 4 }),
      this.search,
      '->',
      this.getRefreshButton()
    ]
  }
})

module.exports = NoteListPanel
