const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const DateField = require('admin/component/form/DateField')

const InvoiceRunNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'invoicerun-new-panel',

  module() {
    return require('admin/business/InvoiceRunResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: new DateField({
        fieldLabel: t('Invoice Run Date'),
        name: 'invoiceRunDate',
        allowBlank: false,
        format: Date.patterns.SHORT
      })
    })
  }
})

module.exports = InvoiceRunNewPanel
