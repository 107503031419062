const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const User = require('admin/core/User')
const Time = require('admin/time/Time')

const InvoiceRunListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'invoicer',

  module() {
    return require('admin/business/InvoiceRunResource')
  },

  createColumnModel() {
    const columns = [
      {
        id: 'invoicer',
        header: t('Invoicer'),
        width: 100,
        sortable: true,
        dataIndex: 'invoicerRole.party.groupName'
      },
      {
        id: 'invoiceRunDate',
        header: t('Invoice Run Date'),
        width: 120,
        sortable: true,
        dataIndex: 'invoiceRunDate',
        renderer: Time.toDateString
      }
    ]
    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }
    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = InvoiceRunListPanel
