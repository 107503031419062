const Ext = require('ext')
const t = require('translate')
const TabPanel = require('admin/view/TabPanel').default
const ReassignToolPanel = require('admin/view/ReassignToolPanel')
const ConvertToolPanel = require('admin/view/ConvertToolPanel')

const VendorToolsTabPanel = Ext.define(null, {
  extend: TabPanel,

  title: t('Tools'),
  tabCls: 'vendor-tools-tab',
  cls: 'vendor-tools-panel',

  constructor() {
    this.items = [new ReassignToolPanel(), new ConvertToolPanel()]

    this.callParent()
  }
})

module.exports = VendorToolsTabPanel
