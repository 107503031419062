import { Col, FormGroup, Row } from 'react-bootstrap'

import React from 'react'

export default function Block(props) {
  return (
    <Row>
      <FormGroup>
        <Col className='col-xs-8 col-xs-offset-3'>{props.children}</Col>
      </FormGroup>
    </Row>
  )
}
