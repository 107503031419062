const Ext = require('ext')
const _s = require('underscore.string')
const t = require('translate')
const SearchStore = require('admin/data/SearchStore')

module.exports = Ext.define(null, {
  extend: Ext.form.ComboBox,

  cls: 'smx-live-search',
  displayField: 'description',
  forceSelection: true,
  queryParam: 's',
  triggerClass: 'x-form-search-trigger',
  triggerConfig: {
    tag: 'div',
    cls: 'x-form-trigger x-form-search-trigger primary x-btn'
  },
  typeAhead: false,
  loadingText: t('Searching ...'),
  width: 300,

  initComponent() {
    this.store = new SearchStore({
      groupField: 'typeName',
      sortInfo: {
        direction: 'ASC',
        field: 'description'
      }
    })

    this.on('beforequery', (obj) => {
      // Add wildcard if the query doesn't end with one.
      let { query } = obj

      if (query.match(/\*$/)) {
        query += '*'
      }

      if (this.includeCancelled) {
        query += ' include:expired'
      }

      obj.query = query
      return true
    })

    this.callParent()
  },

  tpl: new Ext.XTemplate(
    `\
<div class="smx-live-search-results">
  <tpl for="this.groupTypes(values)">
    <h3 class="group-header">{type:htmlEncode}</h3>
    <tpl for="items">
      <div class="x-combo-list-item">
        {description:htmlEncode}
      </div>
    </tpl>
  </tpl>
</div>\
`,
    {
      // Returns array of objects with type and items properties.
      groupTypes(values) {
        const grouped = values.reduce((grouped, value) => {
          if (!grouped[value.type]) {
            grouped[value.type] = []
          }

          grouped[value.type].push(value)

          return grouped
        }, {})

        return Object.keys(grouped).map((type) => {
          return {
            type: _s.capitalize(type),
            items: grouped[type]
          }
        })
      }
    }
  ),

  setIncludeCancelled(include) {
    this.includeCancelled = include
  }
})
