const Ext = require('ext')
const Component = require('smartrules/designer/Component')
const ComboBox = require('admin/component/form/ComboBox')
const SmartRule = require('smartrules/designer/SmartRule')

const SIZE_STORE = new Ext.data.ArrayStore({
  fields: ['size'],
  data: [['kB'], ['MB']],
  idIndex: 0
})

const SizeCombo = Ext.define(null, {
  extend: Component,

  constructor(cfg = {}) {
    const items = []
    cfg = Ext.applyIf(cfg, {
      border: false,
      items,
      width: 45 + 10
    })

    const combo = new ComboBox({
      allowBlank: false,
      editable: false,
      ref: 'combo',
      valueField: 'size',
      mode: 'local',
      forceSelection: true,
      triggerAction: 'all',
      displayField: 'size',
      store: SIZE_STORE,
      width: 45,
      value: cfg.value || 'kB'
    })
    items.push(combo)
    this.callParent([cfg])

    combo.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.markDirty()
      },
      this
    )
  },

  setValue(v) {
    return this.combo.setValue(v)
  },

  getValue() {
    return this.combo.getValue()
  }
})

module.exports = SizeCombo
