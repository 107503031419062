const Ext = require('ext')
const ResourceReader = require('admin/data/ResourceReader')

const ReportJsonReader = Ext.define(null, {
  extend: ResourceReader,

  convert(data) {
    this.title = data.title
    return data.data
  },

  getTitle() {
    return this.title
  }
})

module.exports = ReportJsonReader
