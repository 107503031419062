const Ext = require('ext/ext-base')

export default function (items) {
  if (!Array.isArray(items)) return false
  if (items.length !== 4) return false

  function isMessageBoxButtonWithText(item, text) {
    return (
      item instanceof Ext.Button &&
      item.getText() === text &&
      item.isXType(Ext.Button, true) && // this to ensure it's not a subclass
      // these are specific message box button signatures, see getDialog() fn in
      // https://docs.sencha.com/extjs/3.4.0/source/MessageBox.html#Ext-MessageBox
      item.hideMode === 'offsets' &&
      Ext.isFunction(item.handler)
    )
  }

  // https://docs.sencha.com/extjs/3.4.0/#!/api/Ext.MessageBox-property-buttonText
  if (!isMessageBoxButtonWithText(items[0], Ext.MessageBox.buttonText.ok)) return false
  if (!isMessageBoxButtonWithText(items[1], Ext.MessageBox.buttonText.yes)) return false
  if (!isMessageBoxButtonWithText(items[2], Ext.MessageBox.buttonText.no)) return false
  if (!isMessageBoxButtonWithText(items[3], Ext.MessageBox.buttonText.cancel)) {
    return false
  }

  return true
}
