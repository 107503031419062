import {
  QUARANTINE_QUARANTINE_SETS_CLEAR_LIST,
  QUARANTINE_QUARANTINE_SETS_SELECT,
  QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TARGETS,
  QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TRIGGERS,
  QUARANTINE_QUARANTINE_SETS_SET_LIST,
  QUARANTINE_QUARANTINE_SETS_SET_URI,
  QUARANTINE_QUARANTINE_SETS_START_CONFIGURING,
  QUARANTINE_QUARANTINE_SETS_START_CONFIRMING_DELETION,
  QUARANTINE_QUARANTINE_SETS_START_CREATING,
  QUARANTINE_QUARANTINE_SETS_START_LOADING,
  QUARANTINE_QUARANTINE_SETS_STOP_CONFIGURING,
  QUARANTINE_QUARANTINE_SETS_STOP_CONFIRMING_DELETION,
  QUARANTINE_QUARANTINE_SETS_STOP_CREATING,
  QUARANTINE_QUARANTINE_SETS_STOP_LOADING
} from './actions'

import { QUARANTINE_SET_REL } from 'product/resources'
import { createNextState } from '@reduxjs/toolkit'
import deepmerge from 'deepmerge'
import getIn from 'lodash/get'
import initialState from 'quarantine/db'
import setIn from 'lodash/set'

const findIndex = (quarantineSets, id) => {
  return quarantineSets?.findIndex((quarantineSet) => quarantineSet.id === id)
}

const getBaseKeyPath = (companyKey) => ['companies', companyKey, QUARANTINE_SET_REL]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getListKeyPath = (companyKey) => getKeyPath(companyKey, 'list')

const getList = (quarantineDb, companyKey) =>
  getIn(quarantineDb, getListKeyPath(companyKey))

const reducers = {
  [QUARANTINE_QUARANTINE_SETS_SELECT]: (quarantineDb, action) => {
    // because only one can be selected, we are safe just to pop()
    const row = action.quarantineSets.pop()
    const quarantineSets = getList(quarantineDb, action.companyKey)

    return setIn(
      quarantineDb,
      getListKeyPath(action.companyKey),
      quarantineSets &&
        quarantineSets.map((quarantineSet) => {
          if (quarantineSet.id === row.id) {
            return setIn(quarantineSet, ['selected'], action.selected)
          }

          return setIn(quarantineSet, ['selected'], false)
        })
    )
  },

  [QUARANTINE_QUARANTINE_SETS_SET_LIST]: (quarantineDb, action) => {
    const companyKey = action.companyKey
    const oldList = getList(quarantineDb, companyKey)
    const newQuarantineDb = setIn(
      quarantineDb,
      getListKeyPath(companyKey),
      action.newList
    )

    // remember current selection
    const selectedQuarantineSet = oldList?.find((quarantineSet) => quarantineSet.selected)

    if (!selectedQuarantineSet) {
      return newQuarantineDb
    }

    // restore selection if one was selected before
    const selectedQuarantineSetIndex = findIndex(
      getList(newQuarantineDb, action.companyKey),
      selectedQuarantineSet.id
    )

    if (selectedQuarantineSetIndex >= 0) {
      const path = getListKeyPath(companyKey).concat([
        selectedQuarantineSetIndex,
        'selected'
      ])
      return setIn(newQuarantineDb, path, true)
    }

    return newQuarantineDb
  },

  [QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TRIGGERS]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'availableTriggers'),
      action.availableTriggers
    )
  },

  [QUARANTINE_QUARANTINE_SETS_SET_AVAILABLE_TARGETS]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'availableTargets'),
      action.availableTargets
    )
  },

  [QUARANTINE_QUARANTINE_SETS_CLEAR_LIST]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'list'), null)
  },

  [QUARANTINE_QUARANTINE_SETS_START_LOADING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [QUARANTINE_QUARANTINE_SETS_STOP_LOADING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [QUARANTINE_QUARANTINE_SETS_START_CONFIGURING]: (quarantineDb, action) => {
    const newQuarantineSet = deepmerge(action.quarantineSet, { etag: action.etag })

    // because backend doesn't return targets or triggers in same order, we have to sort here
    // see https://youtrack.smxemail.com/issue/SCL-2856 and https://youtrack.smxemail.com/issue/SCL-2892

    if (newQuarantineSet.targets) {
      newQuarantineSet.targets = [...newQuarantineSet.targets].sort()
    }

    if (newQuarantineSet.triggers) {
      newQuarantineSet.triggers = [...newQuarantineSet.triggers].sort()
    }

    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'configTarget'),
      newQuarantineSet
    )
  },

  [QUARANTINE_QUARANTINE_SETS_STOP_CONFIGURING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'configTarget'), null)
  },

  [QUARANTINE_QUARANTINE_SETS_START_CREATING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'creating'), true)
  },

  [QUARANTINE_QUARANTINE_SETS_STOP_CREATING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'creating'), false)
  },

  [QUARANTINE_QUARANTINE_SETS_SET_URI]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'uri'), action.uri)
  },

  [QUARANTINE_QUARANTINE_SETS_START_CONFIRMING_DELETION]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'confirmingDeletion'), true)
  },

  [QUARANTINE_QUARANTINE_SETS_STOP_CONFIRMING_DELETION]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'confirmingDeletion'), false)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
