const Ext = require('ext')
const t = require('translate')
const AddressableActionClause = require('smartrules/designer/actions/AddressableActionClause')
const AddressMatcher = require('smartrules/designer/matchers/AddressMatcher')
const ListTypes = require('smartrules/ListTypes')
const ListMatcher = require('smartrules/designer/matchers/ListMatcher')

const BccTo = Ext.define(null, {
  extend: AddressableActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('BCC message to'),
      matchers: [
        {
          matcher: 'is',
          name: t('address'),
          clazz: AddressMatcher,
          dsl: 'bcc copy message to {0}'
        },
        {
          matcher: 'is-in',
          name: t('address list'),
          clazz: ListMatcher,
          matcherType: ListTypes.RULE_ADDRESS,
          dsl: 'bcc copy message to {0}'
        }
      ],
      type: 'smartrules-bcc-to',
      nestable: true
    })
    this.callParent([cfg])
  }
})

module.exports = BccTo
