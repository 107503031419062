const Ext = require('ext')
const Component = require('smartrules/designer/Component')

const ClauseTreeMember = Ext.define(null, {
  extend: Component,

  getTree() {
    // leave the require call here to avoid circular dependency
    return this.findParentBy(
      (n) => n instanceof require('smartrules/designer/ClauseTree')
    )
  },

  getParentGroup() {
    return this.findParentBy((n) => n instanceof require('smartrules/designer/Group'))
  },

  /**
  @method removeSelf
  @param noEvent
  Remove this clause-tree member from the tree, abandoning any singleton groups
  that remain.
  */
  removeSelf(noEvent) {
    const parentGroup = this.getParentGroup()
    const smartrule = this.getSmartRule()
    this.destroy()
    if (!noEvent && smartrule) {
      const count = parentGroup.countClauseTreeMembers()

      // We removed the last remaining item, so just show the default drop zone
      if (count === 0) {
        parentGroup.dndHint.show()
      }

      // We removed the last remaining item nested in a group; only the header item is left
      if (count === 1 && !parentGroup.root) {
        parentGroup.abandon()
      }
    }

    smartrule.fireEvent('smartrules-drop')
    return smartrule.fireEvent('dirty')
  }
})

module.exports = ClauseTreeMember
