import React, { Component } from 'react'
import { renameMailTarget, stopRenaming } from 'quarantine/mail_targets/actions'
import {
  renameTargetEtag,
  renameTargetLink,
  renameTargetName,
  uri
} from 'quarantine/mail_targets/subs'

import Dialog from './Dialog'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { uri as quarantineSetsUri } from 'quarantine/quarantine_sets/subs'
import t from 'translate'

class RenameForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.renameMailTarget(
      this.props.uri,
      this.props.renameTargetLink,
      values.name,
      this.props.renameTargetEtag,
      this.props.quarantineSetsUri
    )
  }

  render() {
    const title = t('Rename Mail Target {0}', [this.props.renameTargetName])

    return (
      <Dialog
        title={title}
        store={this.props.store}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        submitButtonText={t('Rename')}
        companyKey={this.props.companyKey}
        mailTargetName={this.props.renameTargetName}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  renameTargetLink: renameTargetLink(db, props.companyKey),
  renameTargetName: renameTargetName(db, props.companyKey),
  renameTargetEtag: renameTargetEtag(db, props.companyKey),
  uri: uri(db, props.companyKey),
  quarantineSetsUri: quarantineSetsUri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopRenaming(initialProps.companyKey)),
  renameMailTarget: (uri, selfLink, newName, etag, quarantineSetsUri) => {
    dispatch(
      renameMailTarget(
        initialProps.companyKey,
        uri,
        selfLink,
        newName,
        etag,
        quarantineSetsUri
      )
    )
  }
})

export default connect(mapDbToProps, mapActionsToProps)(RenameForm)
