const t = require('translate')
const escape = require('html-escaper').escape

const ListTypes = {
  RULE_ALL: 1,
  RULE_ADDRESS: 'RULE_ADDRESS',
  RULE_ADDRESS_SYSTEM: 'RULE_ADDRESS_SYSTEM',
  RULE_CONTENT: 'RULE_CONTENT',
  RULE_CONTENT_SYSTEM: 'RULE_CONTENT_SYSTEM',
  RULE_HEADER: 'RULE_HEADER',
  RULE_HEADER_SYSTEM: 'RULE_HEADER_SYSTEM',
  RULE_ATTACHMENT_TYPE: 'RULE_ATTACHMENT_TYPE',
  RULE_ATTACHMENT_TYPE_SYSTEM: 'RULE_ATTACHMENT_TYPE_SYSTEM',
  RULE_ATTACHMENT_NAME: 'RULE_ATTACHMENT_NAME',
  RULE_ATTACHMENT_NAME_SYSTEM: 'RULE_ATTACHMENT_NAME_SYSTEM'
}

// two new types, wrapped with multiple types,
// see http://youtrack.smxemail.com/issue/SCL-1419
ListTypes.RULE_ADDRESS_OR_CONTENT = [ListTypes.RULE_ADDRESS, ListTypes.RULE_CONTENT]

ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM = [
  ListTypes.RULE_ADDRESS_SYSTEM,
  ListTypes.RULE_CONTENT_SYSTEM
]

ListTypes.getHelpText = function (listType) {
  switch (listType) {
    case ListTypes.RULE_ADDRESS:
      return escape(
        t('Email addresses: e.g.:info@example.com, *@example.com, bob* <bob@*>')
      )
    case ListTypes.RULE_CONTENT:
      return t('Email body fragments: e.g. offensive words')
    case ListTypes.RULE_HEADER:
      return t('Email headers: e.g. From, Cc, To, Message-Id')
    case ListTypes.RULE_ATTACHMENT_TYPE:
      return t('Attachment types by content-type header: e.g.:image/*application/msword')
    case ListTypes.RULE_ATTACHMENT_NAME:
      return t('Attachment names: e.g.:im_a_virus.ppt*.exe')
  }
}

ListTypes.getExplanation = function (listType) {
  switch (listType) {
    case ListTypes.RULE_ADDRESS:
      return t(
        'Each mailbox is composed of an optional display name and an address enclosed in angle brackets. ' +
          'Valid examples would be: info@example.com, *@example.com, bob* <bob@*>'
      )
  }
}

module.exports = ListTypes
