const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const ReportRecord = require('reporting/ReportRecord')
const Report = require('reporting/Report')

const ReportStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'reports',
  sortInfo: {
    field: 'title',
    direction: 'ASC'
  },

  record: ReportRecord,

  module() {
    return Report
  }
})

module.exports = ReportStore
