export default {
  // states must be split by company because
  // they might vary from vendor, distributor and reseller and multiple
  // forms can exist in DOM shadows
  companies: {
    /*

    here an example what the state could look like for one active company:

    '/customer/2014041': {
      'catalogue': {
        customerUrl: ...
        list: [ ... ]
      }
    }

    */
  }
}
