import { ARCHIVING_CONFIGURATION_REL } from 'product/resources'
import Membership from 'membership/collections/Membership'
import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('archiving')

export const configuration = createSelector(
  company,
  (company) => company && company[ARCHIVING_CONFIGURATION_REL]
)

const types = createSelector(configuration, (configuration) => configuration?.types)

export const membership = createSelector(types, (types) => types && new Membership(types))

export const loading = createSelector(configuration, (configuration) =>
  Boolean(configuration?.loading)
)

export const mutating = createSelector(configuration, (configuration) =>
  Boolean(configuration?.mutating)
)

export const busy = createSelector(loading, mutating, (loading, mutating) =>
  Boolean(loading || mutating)
)

export const isGlobal = createSelector(configuration, (configuration) =>
  Boolean(configuration?.global)
)

const isOriginalGlobal = createSelector(configuration, (configuration) =>
  Boolean(configuration?.originalGlobal)
)

export const disableSelection = createSelector(busy, isGlobal, (busy, isGlobal) =>
  Boolean(busy || isGlobal)
)

export const uri = createSelector(configuration, (configuration) => configuration?.uri)

export const isEmpty = createSelector(membership, (membership) => {
  if (!membership) return true
  return !membership.hasTypes()
})

export const enabledMemberNames = createSelector(
  isEmpty,
  membership,
  (isEmpty, membership) => {
    if (isEmpty) return
    return membership.getAllNamesEnabled()
  }
)

const originalTypes = createSelector(
  configuration,
  (configuration) => configuration?.originalTypes
)

const membersChanged = createSelector(
  types,
  originalTypes,
  (types, originalTypes) => JSON.stringify(types) !== JSON.stringify(originalTypes)
)

export const pristine = createSelector(
  isGlobal,
  isOriginalGlobal,
  membersChanged,
  (isGlobal, isOriginalGlobal, membersChanged) =>
    isGlobal === isOriginalGlobal && !membersChanged
)

export const globalLink = createSelector(
  configuration,
  (configuration) => configuration?.globalLink
)

export const enableLink = createSelector(
  configuration,
  (configuration) => configuration?.enableLink
)
