import initialState from 'continuity/db'
import initialMessagesState from 'continuity/messages/db'
import getIn from 'lodash/get'
import setIn from 'lodash/set'

import { createNextState } from '@reduxjs/toolkit'

import {
  CONTINUITY_MESSAGES_CLEAR_AUTHORIZATION_COUNT,
  CONTINUITY_MESSAGES_CLEAR_DOWNLOAD_URI_TEMPLATE,
  CONTINUITY_MESSAGES_CLEAR_NEXT_LINK,
  CONTINUITY_MESSAGES_CLEAR_PREVIEW,
  CONTINUITY_MESSAGES_CLEAR_RESULTS,
  CONTINUITY_MESSAGES_CLEAR_SCROLL_TO_INDEX,
  CONTINUITY_MESSAGES_CLEAR_SELECTION,
  CONTINUITY_MESSAGES_CLEAR_SID,
  CONTINUITY_MESSAGES_CLEAR_SOURCE,
  CONTINUITY_MESSAGES_CONCAT_RESULTS,
  CONTINUITY_MESSAGES_ENABLE_HIGHLIGHTING,
  CONTINUITY_MESSAGES_INIT_DB,
  CONTINUITY_MESSAGES_SELECT_ROWS,
  CONTINUITY_MESSAGES_SET_AUTHORIZATION_COUNT,
  CONTINUITY_MESSAGES_SET_AUTHORIZATION_TIMESTAMP,
  CONTINUITY_MESSAGES_SET_COLUMN_WIDTH,
  CONTINUITY_MESSAGES_SET_CONTINUITY_URI,
  CONTINUITY_MESSAGES_SET_DATE_RANGE,
  CONTINUITY_MESSAGES_SET_DATE_RANGE_HIDDEN,
  CONTINUITY_MESSAGES_SET_DATE_RANGE_SHOWN,
  CONTINUITY_MESSAGES_SET_DOWNLOAD_URI_TEMPLATE,
  CONTINUITY_MESSAGES_SET_NEXT_LINK,
  CONTINUITY_MESSAGES_SET_OFFSET,
  CONTINUITY_MESSAGES_SET_PREVIEW,
  CONTINUITY_MESSAGES_SET_QUERY,
  CONTINUITY_MESSAGES_SET_RESULTS,
  CONTINUITY_MESSAGES_SET_RESULTS_QUERY,
  CONTINUITY_MESSAGES_SET_SCROLL_TO_INDEX,
  CONTINUITY_MESSAGES_SET_SID,
  CONTINUITY_MESSAGES_SET_SOURCE,
  CONTINUITY_MESSAGES_SET_TIME_ZONE_KEY,
  CONTINUITY_MESSAGES_START_LOADING,
  CONTINUITY_MESSAGES_STOP_LOADING,
  CONTINUITY_MESSAGES_TOGGLE_ATTACHMENT_PANEL
} from './actions'

// todo instead of 'messages', it should be a link name from SSV-2936
const getBaseKeyPath = (companyKey) => ['companies', companyKey, 'messages']
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getResultsKeyPath = (companyKey) => getKeyPath(companyKey, 'results')

const reducers = {
  [CONTINUITY_MESSAGES_CLEAR_AUTHORIZATION_COUNT]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'authorizationCount'), -1)
  },

  [CONTINUITY_MESSAGES_CLEAR_DOWNLOAD_URI_TEMPLATE]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'downloadUriTemplate'), null)
  },

  [CONTINUITY_MESSAGES_CLEAR_NEXT_LINK]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'nextLink'), null)
  },

  [CONTINUITY_MESSAGES_CLEAR_PREVIEW]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'preview'), null)
  },

  [CONTINUITY_MESSAGES_CLEAR_SID]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'sid'), null)
  },

  [CONTINUITY_MESSAGES_CLEAR_RESULTS]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'results'), null)
  },

  [CONTINUITY_MESSAGES_CLEAR_SCROLL_TO_INDEX]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'scrollToIndex'), null)
  },

  [CONTINUITY_MESSAGES_CLEAR_SELECTION]: (continuityDb, { companyKey }) => {
    const results = getIn(continuityDb, getResultsKeyPath(companyKey))

    if (!results) return continuityDb

    return setIn(
      continuityDb,
      getResultsKeyPath(companyKey),
      results.map((result) => {
        delete result.selected
        return result
      })
    )
  },

  [CONTINUITY_MESSAGES_CLEAR_SOURCE]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'source'), null)
  },

  [CONTINUITY_MESSAGES_CONCAT_RESULTS]: (continuityDb, action) => {
    const results = getIn(continuityDb, getResultsKeyPath(action.companyKey))

    return setIn(
      continuityDb,
      getResultsKeyPath(action.companyKey),
      results.concat(action.moreResults)
    )
  },

  [CONTINUITY_MESSAGES_ENABLE_HIGHLIGHTING]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'highlight'), action.enabled)
  },

  [CONTINUITY_MESSAGES_INIT_DB]: (continuityDb, action) => {
    return setIn(continuityDb, getBaseKeyPath(action.companyKey), initialMessagesState)
  },

  [CONTINUITY_MESSAGES_SELECT_ROWS]: (continuityDb, action) => {
    const { companyKey, rows, selected } = action

    const results = getIn(continuityDb, getResultsKeyPath(companyKey))

    return setIn(
      continuityDb,
      getResultsKeyPath(action.companyKey),
      results.map((result) => {
        const found = rows.find((row) => row.sid === result.sid)

        if (!found) return result

        return Object.assign(result, { selected })
      })
    )
  },

  [CONTINUITY_MESSAGES_SET_CONTINUITY_URI]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'continuityUri'),
      action.continuityUri
    )
  },

  [CONTINUITY_MESSAGES_SET_AUTHORIZATION_COUNT]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'authorizationCount'),
      action.count
    )
  },

  [CONTINUITY_MESSAGES_SET_AUTHORIZATION_TIMESTAMP]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'authorizationTimestamp'),
      action.timestamp
    )
  },

  [CONTINUITY_MESSAGES_SET_COLUMN_WIDTH]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'columns').concat([action.columnKey, 'width']),
      action.width
    )
  },

  [CONTINUITY_MESSAGES_SET_DATE_RANGE]: (continuityDb, action) => {
    const messages = getIn(continuityDb, getBaseKeyPath(action.companyKey))

    messages.fromDate = action.fromDate
    messages.thruDate = action.thruDate

    return setIn(continuityDb, getBaseKeyPath(action.companyKey), messages)
  },

  [CONTINUITY_MESSAGES_SET_DATE_RANGE_SHOWN]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'dateRangeShown'), true)
  },

  [CONTINUITY_MESSAGES_SET_DATE_RANGE_HIDDEN]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'dateRangeShown'), false)
  },

  [CONTINUITY_MESSAGES_SET_DOWNLOAD_URI_TEMPLATE]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'downloadUriTemplate'),
      action.downloadUriTemplate
    )
  },

  [CONTINUITY_MESSAGES_SET_SID]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'sid'), action.sid)
  },

  [CONTINUITY_MESSAGES_SET_NEXT_LINK]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'nextLink'), action.nextLink)
  },

  [CONTINUITY_MESSAGES_SET_OFFSET]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'offset'), action.offset)
  },

  [CONTINUITY_MESSAGES_SET_PREVIEW]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'preview'), action.preview)
  },

  [CONTINUITY_MESSAGES_SET_QUERY]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'query'), action.query)
  },

  [CONTINUITY_MESSAGES_SET_RESULTS]: (continuityDb, action) => {
    return setIn(continuityDb, getResultsKeyPath(action.companyKey), action.results)
  },

  [CONTINUITY_MESSAGES_SET_RESULTS_QUERY]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'resultsQuery'),
      action.query
    )
  },

  [CONTINUITY_MESSAGES_SET_SCROLL_TO_INDEX]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'scrollToIndex'),
      action.scrollToIndex
    )
  },

  [CONTINUITY_MESSAGES_SET_SOURCE]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'source'), action.source)
  },

  [CONTINUITY_MESSAGES_SET_TIME_ZONE_KEY]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'timeZoneName'),
      action.timeZoneName
    )
  },

  [CONTINUITY_MESSAGES_START_LOADING]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [CONTINUITY_MESSAGES_STOP_LOADING]: (continuityDb, action) => {
    return setIn(continuityDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [CONTINUITY_MESSAGES_TOGGLE_ATTACHMENT_PANEL]: (continuityDb, action) => {
    return setIn(
      continuityDb,
      getKeyPath(action.companyKey, 'attachmentPanelExpanded'),
      action.expanded
    )
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
