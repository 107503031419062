const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validators = require('validators').default

const UserNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'user-new-panel',

  module() {
    return require('admin/business/UserResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems() {
    return [
      {
        fieldLabel: t('First Name'),
        name: 'firstName',
        maxLength: 100,
        allowBlank: false,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Last Name'),
        name: 'lastName',
        maxLength: 100,
        allowBlank: false,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Email Address'),
        name: 'userName',
        xtype: 'textfield',
        helpText: t('Email address is also the user login name'),
        validator: validators.email.withMsg(
          t('This field should be an e-mail address in the format "user@example.com"')
        ),
        allowBlank: false
      },
      {
        xtype: 'textfield',
        fieldLabel: t('Phone Number'),
        name: 'contactNumber',
        width: 100
      },
      {
        xtype: 'textfield',
        fieldLabel: t('Phone Extension'),
        name: 'extension',
        width: 100,
        validator: validators.positiveInteger.withMsg(t('Only numbers are allowed.')),
        maskRe: /\d/
      }
    ]
  }
})

module.exports = UserNewPanel
