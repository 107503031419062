const Ext = require('ext')
const t = require('translate')
const ResourceUtil = require('admin/util/ResourceUtil')
const ReadOnlyCheckBox = require('admin/component/form/ReadOnlyCheckBox')
const ReadOnlyDateField = require('admin/component/form/ReadOnlyDateField')
const ReadOnlyDateRangeFieldSet = require('admin/component/form/ReadOnlyDateRangeFieldSet')
const ReadOnlyComboBox = require('admin/component/form/ReadOnlyComboBox')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const ReadOnlyTextArea = require('admin/component/form/ReadOnlyTextArea')
const ReadOnlyPriceField = require('admin/component/form/ReadOnlyPriceField')
const ReadOnlyCurrencyAndPriceField = require('admin/component/form/ReadOnlyCurrencyAndPriceField')
const logger = require('system/logger').default

// May contain a view panel, edit panel or both, nested in a card layout panel.
const ResourceDetailPanel = Ext.define(null, {
  extend: Ext.Panel,

  layout: 'fit',
  header: false,

  constructor(cfg = {}) {
    // Allows module to be defined async.
    if (typeof this.module === 'function') {
      this.module = this.module()
    }

    if (this.module == null) {
      this.module = cfg.module
    }

    cfg.module = this.module
    this.record = cfg.record

    if (this.title == null) {
      this.title = this.module.translations.title
    }

    if (this.module.layout.openResource.height === 'auto') {
      this.autoHeight = true
    }

    if (this.record.canSave()) {
      this.formPanel = this.getFormPanel(cfg)
    }

    if (!this.preferEditOnly || !this.formPanel) {
      this.viewPanel = this.getViewPanel(cfg)
      // Class is used in UI tests.
      this.viewPanel.addClass('view-panel')
    }

    if (this.formPanel && !this.viewPanel) {
      this.formPanel.addButton({
        cls: 'e2e-resource-detail-panel-cancel',
        text: t('Cancel'),
        handler: this.handleCancel,
        scope: this
      })
      this.items = this.formPanel
    } else if (this.formPanel && this.viewPanel) {
      this.viewPanel.addButton({
        cls: 'e2e-resource-detail-panel-edit primary',
        disabled: this.disableEdit(),
        text: t('Edit'),
        handler: this.handleEdit,
        scope: this
      })
      this.formPanel.addButton({
        cls: 'e2e-resource-detail-panel-cancel',
        text: t('Back'),
        handler: this.handleCancel,
        scope: this
      })

      this.adjustViewPanel()

      if (!this.disableEdit()) {
        // Double click event on view widgets becomes 'editmode' event on view panel.
        this.viewPanel.on(
          'editmode',
          function (params) {
            return this.handleEdit(params)
          },
          this
        )
      }

      this.cardPanel = new Ext.Panel({
        border: false,
        layout: 'card',
        layoutConfig: {
          // Deferred rendering fixes a combobox rendering bug.
          deferredRender: true
        },
        activeItem: 0,
        items: [this.viewPanel, this.formPanel]
      })
      this.items = this.cardPanel
    } else {
      this.items = this.viewPanel
    }

    if (this.record.canSave()) {
      // Class is used in UI tests.
      this.formPanel.addClass('form-panel')
      this.formPanel.saveButton = this.formPanel.addButton({
        cls: 'e2e-resource-detail-panel-save primary',
        text: t('Save'),
        handler: this.handleSave,
        scope: this,
        formBind: true
      })
      if (this.module.submitOnEnter) {
        this.formPanel.on(
          'enter-key-pressed',
          function () {
            if (!this.formPanel.saveButton.disabled) {
              return this.handleSave()
            }
          },
          this
        )
      }
    }

    this.callParent([cfg])

    this.record.on('sync', this.onRecordSync, this)
    this.record.on('destroy', this.onRecordDelete, this)
    return this.on(
      'destroy',
      function () {
        this.record.un('sync', this.onRecordSync, this)
        return this.record.un('destroy', this.onRecordDelete, this)
      },
      this
    )
  },

  load() {
    if (this.viewPanel) {
      this.viewPanel.loadRecord()
    }
    this.formPanel?.loadRecord()
  },

  // Adds optional fields to the given record prior to save (for dynamically-created form fields).
  addOptionalRecordFields() {},

  // enables sub-classes to pass on optional options when it comes to saving, see i.E. BillingDetailPanel
  getSaveOptions() {
    return {}
  },

  disableForm() {
    if (!this.formPanel) return
    if (!this.formPanel.saveButton) return

    this.formPanel.saveButton.setDisabled(true)
  },

  enableForm() {
    this.saving = false
    if (!this.formPanel) return
    if (!this.formPanel.saveButton) return

    this.formPanel.saveButton.setDisabled(false)
  },

  handleSave() {
    if (this.saving) return

    this.saving = true
    this.disableForm()

    // Write changes in the form to the backing record. Note that the record's structure
    // may be subtly different from the form. e.g. there may be mapped/converted values
    // in the record, and there may be hidden values in the form.
    this.record.beginEdit()
    this.record.fields.each(function (recordField) {
      const formField = this.formPanel.getForm().findField(recordField.name)
      if (formField) {
        return this.record.set(
          recordField.name,
          ResourceUtil.reconvertValue(recordField, formField.getValue())
        )
      }
    }, this)
    this.addOptionalRecordFields(this.record)
    this.record.endEdit()

    Ext.MessageBox.wait(t('Saving...'))
    return this.record.save(
      null,
      Object.assign(
        {
          enableForm: () => this.enableForm(),
          success: () => {
            this.formPanel.loadRecord(true)

            if (this.closeOnSave) {
              return this.close()
            }
            this.store.reload()
            this.showView(true)
          },
          error: (record, response, options, err) => {
            logger.error(err)
            this.store.reload()
          },
          complete: () => {
            this.saving = false
            Ext.MessageBox.hide()
          }
        },
        this.getSaveOptions(this.record)
      )
    )
  },

  handleCancel() {
    if (!this.formPanel) return
    if (!this.formPanel.isVisible()) return
    if (!this.formPanel.preventClose) return

    if (!this.formPanel.preventClose(() => this.handleCancel())) {
      this.formPanel.loadRecord(true)
      this.showView(true)
    }
  },

  handleEdit(params) {
    if (this.disableEdit()) return
    this.showEdit(params)
  },

  showView(needRefresh) {
    const view = function () {
      return this.cardPanel.layout.setActiveItem(0)
    }

    if (typeof this.cardPanel.layout === 'string') {
      this.cardPanel.on('afterlayout', view, this, { single: true })
    } else {
      view.call(this)
    }

    if (needRefresh) {
      this.viewPanel.loadRecord(true)
    }
  },

  showEdit() {
    if (this.disableEdit()) return

    const edit = function () {
      let mask

      const activateFormPanel = function () {
        if (mask) {
          mask.hide()
          mask.destroy()
        }
        this.cardPanel.layout.setActiveItem(this.formPanel)
        this.store = this.store || this.record.store
        return this.doLayout()
      }

      const loadingCombos = this.formPanel.findBy(
        (comp) => comp instanceof Ext.form.ComboBox && comp.store && comp.store.loading
      )

      if (loadingCombos.length) {
        const { store } = loadingCombos[0]
        mask = new Ext.LoadMask(this.cardPanel.getEl(), { store })
        mask.show()
        store.on('load', activateFormPanel, this, { single: true })
        store.on('exception', activateFormPanel, this, { single: true })
      }
      activateFormPanel.call(this)
    }

    if (typeof this.cardPanel.layout === 'string') {
      return this.cardPanel.on('afterlayout', edit, this, { single: true })
    }
    edit.call(this)
  },

  onRecordDelete() {
    this.close()
  },

  onRecordSync() {
    this.viewPanel?.loadRecord(true)
    this.formPanel?.loadRecord(true)
  },

  close() {
    const parentWindow = this.findParentWindow()

    parentWindow?.close()
  },

  getFocusEl() {
    return this.formPanel?.get(this.getFocusId())
  },

  focus() {
    const focusEl = this.getFocusEl()
    focusEl?.focus()
  },

  getFormPanel(cfg) {
    const RecordFormPanel = require('admin/component/form/RecordFormPanel')
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      operation: 'save',
      width: 'auto',
      height: 'auto',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    return []
  },

  getViewPanel(cfg) {
    const RecordFormPanel = require('admin/component/form/RecordFormPanel')
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      items: this.getViewItems(cfg)
    })
  },

  // Returns default non-editable view items, cloned off the editable ones.
  getViewItems(cfg) {
    // Attempts to return read-only version of given item, else null if none available
    const createReadOnlyItem = function (item) {
      if (!item || item.dontMakeReadOnlyClone) return null

      let xtype = null

      if (item.getXType) {
        xtype = item.getXType()
      }

      if (!xtype) {
        xtype = item.xtype
      }

      switch (xtype) {
        case 'checkbox':
        case 'radio':
          return new ReadOnlyCheckBox({ clone: item })

        case 'datefield':
        case 'smx-datefield':
          return new ReadOnlyDateField({ clone: item, width: item.width })

        case 'daterangefieldset':
          return new ReadOnlyDateRangeFieldSet({ clone: item, width: item.width })

        case 'combo':
        case 'smx-combo':
          return new ReadOnlyComboBox({ clone: item })

        case 'textfield':
          return new ReadOnlyTextField({ clone: item })

        case 'textarea':
          return new ReadOnlyTextArea({ clone: item })

        case 'numberfield':
          return new ReadOnlyTextField({ clone: item })

        case 'pricefield':
          return new ReadOnlyPriceField({ clone: item })

        case 'currency-pricefield':
          return new ReadOnlyCurrencyAndPriceField({
            clone: item
          })
      }

      if (item.items) {
        substituteReadOnlyItems(item.items)
      }
    }

    const substituteReadOnlyItems = function (items) {
      if (Array.isArray(items)) {
        items.forEach(function (item, i, list) {
          const roItem = createReadOnlyItem(item)
          if (!roItem) return
          list[i] = roItem
        })
        // TODO: Handle multiple instances of same object in items
      } else if (items instanceof Ext.util.MixedCollection) {
        const range = items.getRange()
        range.forEach(function (item, i) {
          const roItem = createReadOnlyItem(item)
          if (!roItem) return

          items.insert(i, roItem)
          items.remove(item)
        })
      }
      return items
    }
    const items = this.getDetailItems(cfg)
    return substituteReadOnlyItems(items)
  },

  getFocusId() {
    return 0
  },

  // since keycloak
  disableEdit() {
    return false
  },

  // since keycloak
  adjustViewPanel() {}
})

module.exports = ResourceDetailPanel
