import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import React, { Component } from 'react'
import {
  addButtonDisabled,
  configureButtonDisabled,
  deleteButtonDisabled,
  loading,
  refreshButtonDisabled,
  selected,
  selectedProviderLink,
  size,
  uri
} from 'sharedservices/provider/subs'
import {
  configureProvider,
  deleteProvider,
  refresh,
  startConfirmingDeletion,
  startCreating
} from 'sharedservices/provider/actions'

import Indicator from 'components/Indicator'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class Toolbar extends Component {
  static propTypes = {
    configurable: PropTypes.bool,
    requireAuthentication: PropTypes.bool,
    companyKey: PropTypes.string.isRequired
  }

  configure = () => {
    this.props.configureProvider(this.props.selectedProviderLink)
  }

  delete = () => {
    if (this.props.requireAuthentication) {
      this.props.startConfirmingDeletion()
    } else {
      // delete right away, most likely a delegated keycloak user
      this.props.deleteProvider(this.props.uri, this.props.selectedProviderLink)
    }
  }

  refresh = () => {
    this.props.refresh(this.props.uri)
  }

  renderLeftButtons() {
    return (
      <ButtonToolbar className='pull-left'>
        <Button disabled={this.props.addButtonDisabled} onClick={this.props.onClick}>
          {t('New')}
        </Button>
        <Button disabled={this.props.configureButtonDisabled} onClick={this.configure}>
          {t('Configure')}
        </Button>
        <Button disabled={this.props.deleteButtonDisabled} onClick={this.delete}>
          {t('Delete')}
        </Button>
      </ButtonToolbar>
    )
  }

  render() {
    return (
      <div className='toolbar clearfix'>
        <Row className='no-gutter'>
          <Col className='no-gutter'>
            {this.props.configurable && this.renderLeftButtons()}
          </Col>
          <Col className='no-gutter'>
            <div className='pull-right'>
              <Indicator
                loading={this.props.loading}
                size={this.props.size}
                noun='providers'
              />
              <ButtonToolbar className='right-button-toolbar'>
                <Button
                  disabled={this.props.refreshButtonDisabled}
                  onClick={this.refresh}
                >
                  {t('Refresh')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDbToProps = (db, props) => ({
  loading: loading(db, props.companyKey),
  size: size(db, props.companyKey),
  selected: selected(db, props.companyKey),
  selectedProviderLink: selectedProviderLink(db, props.companyKey),
  addButtonDisabled: addButtonDisabled(db, props.companyKey),
  configureButtonDisabled: configureButtonDisabled(db, props.companyKey),
  deleteButtonDisabled: deleteButtonDisabled(db, props.companyKey),
  refreshButtonDisabled: refreshButtonDisabled(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  deleteProvider: (uri, link) =>
    dispatch(deleteProvider(initialProps.companyKey, uri, link)),
  configureProvider: (link) => dispatch(configureProvider(initialProps.companyKey, link)),
  startConfirmingDeletion: () =>
    dispatch(startConfirmingDeletion(initialProps.companyKey)),
  refresh: (uri) => dispatch(refresh(initialProps.companyKey, uri)),
  onClick: () => dispatch(startCreating(initialProps.companyKey))
})

export default connect(mapDbToProps, mapActionsToProps)(Toolbar)
