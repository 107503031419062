import Ext from 'ext'
import React from 'react'
import ReactDOM from 'react-dom'

const ReactContainerWindow = Ext.define(null, {
  extend: Ext.Window,
  autoEl: 'div',

  initComponent: function () {
    this.addClass('Bs')
    this.getParentInstance().initComponent.call(this)
  },

  getParentInstance: function () {
    return ReactContainerWindow.superclass
  },

  getDom: function () {
    return this.body?.dom
  },

  onResize: function (width, height) {
    const clone = React.cloneElement(this.component, {
      width,
      height
    })
    ReactDOM.render(clone, this.getDom())
  },

  destroy: function () {
    const dom = this.getDom()
    dom && ReactDOM.unmountComponentAtNode(dom)

    this.getParentInstance().destroy.call(this)
  }
})

export default ReactContainerWindow
