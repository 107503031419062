const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const Alert = require('admin/util/Alert').default

const ReportsListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'title',

  initComponent() {
    this.module = require('reporting/Report')

    this.on('rowdblclick', function (grid, rowIndex) {
      if (!grid.store.data.get(rowIndex).canOpen()) {
        const title = t('Not a runnable report')
        const message = t('The selected report can only be subscribed to')
        Alert.alert(title, message)
        return false
      }
    })

    this.callParent()
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel([
      {
        id: 'title',
        header: t('Report Title'),
        width: 600,
        sortable: true,
        dataIndex: 'title'
      }
    ])
  },

  // Reports aren't really being opened, a run is needed first before viewing (see SCL-2770)
  getOpenButtonText() {
    return t('Run')
  },

  getToolbarItems() {
    return [
      this.getCreateButton(),
      this.getOpenButton(),
      this.getRemoveButton(),
      new Ext.Button({
        text: t('Subscribe'),
        disabled: true,
        shouldEnable: () => {
          const selected = this.getSelectionModel().getSelected()
          return selected?.isSchedulable()
        },
        handler: () => {
          if (this.getSelectionModel().hasSelection()) {
            const Subscription = require('reporting/Subscription')
            const record = this.getSelectionModel().getSelected()
            const store = record.getSubscriptionStore()
            return Subscription.create(record, { store })
          }
        }
      }),
      new Ext.Button({
        text: t('Export'),
        disabled: true,
        shouldEnable: () => {
          const selected = this.getSelectionModel().getSelected()
          return selected?.isExportable()
        },
        handler: () => {
          if (this.getSelectionModel().hasSelection()) {
            return this.module.showReportOptions(this.getSelectionModel().getSelected(), {
              export: true
            })
          }
        }
      }),
      '->',
      this.getRefreshButton()
    ]
  },

  handleRowDblClick(grid, rowIndex) {
    const record = grid.getStore().getAt(rowIndex)
    return this.module.open(record, { idBase: record.id })
  },

  getContextMenuItems(record) {
    const items = this.callParent(arguments)

    items.push({
      text: t('Subscribe'),
      iconCls: 'new-SMX-Subscription',
      disabled: !record.isSchedulable(),
      handler() {
        const Subscription = require('reporting/Subscription')
        return Subscription.create(record)
      }
    })

    items.push({
      text: t('Export Report'),
      iconCls: 'export-SMX-Report',
      disabled: !record.isExportable(),
      handler: () => {
        return this.module.showReportOptions(record, { export: true })
      }
    })

    return items
  }
})

module.exports = ReportsListPanel
