const Ext = require('ext')
const t = require('translate')
const SearchField = require('admin/component/form/SearchField')
const confirmDiscardChanges = require('admin/util/confirmDiscardChanges')
const logger = require('system/logger').default

const POLICYSET_TYPES = {
  'smxpolicysetoptout/spam': {
    order: 1
  },
  'smxpolicysetoptout/gray': {
    order: 2
  },
  'smxpolicysetoptout/spf': {
    order: 3
  },
  'smxpolicysetoptout/dmarc': {
    order: 4
  }
}

const DomainFormPanel = Ext.define(null, {
  extend: Ext.form.FormPanel,

  border: false,
  buttonAlign: 'left',
  cls: 'smx-domain-panel',
  monitorValid: true,
  layout: 'vbox', // NOT form layout.
  layoutConfig: {
    align: 'stretch'
  },

  initComponent() {
    // remember this state in a different attribute because extjs seems to mess with it somehow
    this.prohibited = this.disabled

    if (this.useMailServerStrings) {
      this.titleString = t('Mail Servers')
      this.applyAllString = t('Apply to all mail servers')
    } else {
      this.titleString = t('Domains')
      this.applyAllString = t('Apply to all domains')
    }

    if (this.title == null) {
      this.title = this.titleString
    }

    this.store = new Ext.data.JsonStore({
      fields: ['id', 'name', 'member'],
      sortInfo: {
        field: 'name',
        direction: 'ASC'
      }
    })

    this.items = [
      (this.global = new Ext.form.Checkbox({
        boxLabel: this.applyAllString,
        checked: false,
        disabled: this.prohibited,
        listeners: {
          check: () => {
            return this.updateGrid()
          }
        }
      })),
      {
        // Needs to be wrapped in form layout so label will render.
        border: false,
        layout: 'form',
        labelAlign: 'right',
        labelWidth: 47,
        items: (this.search = new SearchField({
          fieldLabel: t('Filter'),
          anchor: '100%',
          isDirty() {
            return false
          },
          listeners: {
            keyup: () => this.filter()
          }
        }))
      },
      (this.domainGrid = new Ext.grid.GridPanel({
        flex: 2,
        autoExpandColumn: 'name',
        cls: 'smx-domain-grid',
        enableHdMenu: false,
        enableColumnHide: false,
        enableColumnMove: false,
        enableColumnResize: true,
        store: this.store,
        viewConfig: {
          markDirty: false
        },
        colModel: new Ext.grid.ColumnModel([
          {
            dataIndex: 'member',
            header: t('Apply'),
            id: 'member',
            falseText: '<div class="x-grid3-check-col"></div>',
            trueText: '<div class="x-grid3-check-col-on"></div>',
            width: 50,
            xtype: 'booleancolumn'
          },
          {
            dataIndex: 'name',
            header: this.titleString,
            id: 'name'
          }
        ]),
        listeners: {
          rowclick: (grid, rowIndex) => {
            if (rowIndex < 0 || rowIndex > this.store.getCount() - 1) {
              return
            }
            const record = this.store.getAt(rowIndex)
            return record.set('member', !record.get('member'))
          }
        }
      })),
      (this.attributeContainer = new Ext.Panel({
        flex: 1,
        border: false,
        cls: 'attributes',
        style: {
          padding: '8px 0'
        }
      }))
    ]

    this.domainGrid.setDisabled(this.prohibited)

    this.buttons = new Ext.Toolbar({
      items: [
        (this.refreshButton = new Ext.Button({
          text: t('Refresh'),
          width: 80,
          disabled: this.prohibited,
          handler: () => this.updateValues()
        })),
        '->',
        (this.resetButton = new Ext.Button({
          text: t('Reset'),
          width: 80,
          disabled: this.prohibited,
          handler: () => this.reset()
        })),
        (this.saveButton = new Ext.Button({
          text: t('Save'),
          width: 80,
          disabled: this.prohibited,
          cls: 'primary',
          handler: () => this.save()
        }))
      ]
    })

    this.on('clientvalidation', this.onClientValidation, this)
    this.on('activate', this.forceLayout, this)

    this.callParent()
  },

  isDirty() {
    return (
      this.getForm().isDirty() || this.store.getRange().some((record) => record.dirty)
    )
  },

  setValues(values = {}) {
    if (this.isDestroyed) return

    // Global.
    this.global.setValue(values.global)
    this.global.originalValue = values.global
    // Members.
    this.store.removeAll()
    this.store.loadData(values.members || [])

    // Attributes.
    this.attributeContainer.removeAll()

    if (values.attributes) {
      // Since https://youtrack.smxemail.com/issue/SCL-3035 UI has to
      // order those policyset attributes itself
      values.attributes.sort(function (attr) {
        if (POLICYSET_TYPES[attr.attribute]?.order) {
          return POLICYSET_TYPES[attr.attribute].order
        }
        return -1
        // appear at bottom
      })

      values.attributes.forEach((attr) => {
        return this.attributeContainer.add(
          new Ext.form.Checkbox({
            disabled: this.prohibited,
            attribute: attr.attribute,
            boxLabel: attr.description,
            checked: attr.enabled
          })
        )
      })
    }

    this.updateGrid()
    this.forceLayout()
  },

  setRemovable(removable) {
    this.removable = removable
    this.setDisabled(!removable)
  },

  getValues() {
    return {
      global: this.global.getValue(),
      members: this.store.getRange().map((r) => r.data),
      attributes: this.attributeContainer.find('field').map((field) => ({
        attribute: field.attribute,
        description: field.boxLabel,
        enabled: field.getValue()
      }))
    }
  },

  updateValues() {
    if (this.isDestroyed) {
      return
    }

    if (this.parentRecord) {
      const record = this.parentRecord

      this.mask()

      return this.parentRecord
        .getConfig()
        .then((config) => {
          if (this.isDestroyed) return
          if (this.parentRecord !== record) return

          this.setValues(config)

          // check since SCL-3374 Do not enable policy config tab for non-tech customers
          if (this.removable) {
            return this.enable()
          }
        })
        .catch((error) => logger.error(error))
        .finally(() => this.unmask())
        .done()
    }
  },

  // Generic prevent dirty close.
  preventClose(retry) {
    if (!this.allowClose && this.isDirty()) {
      confirmDiscardChanges(() => {
        this.allowClose = true
        return retry()
      })
      return true
    }
  },

  // Sets the record we are configuring.
  setParentRecord(record) {
    if (!record || record.isNew()) {
      this.parentRecord = null
      this.setValues()
      this.disable()
      return
    }

    if (record !== this.parentRecord) {
      this.parentRecord = record
      this.updateValues()
    }
  },

  // Required when attributeContainer changes size.
  forceLayout() {
    if (!this.rendered) return
    this.attributeContainer.doLayout(true, true)
    this.doLayout(true, true)
  },

  filter() {
    if (!this.rendered || !this.search) return
    const v = this.search.getValue().toLowerCase().trim()
    if (v === '') {
      return this.store.clearFilter()
    }
    this.store.filter({
      anyMatch: true,
      caseSensitive: false,
      property: 'name',
      value: v
    })
  },

  save() {
    Ext.MessageBox.wait(t('Saving...'))
    return this.parentRecord
      .saveConfig(this.getValues())
      .catch((err) => logger.error(err))
      .then(() => this.updateValues())
      .finally(() => Ext.MessageBox.hide())
      .done()
  },

  reset() {
    this.form.reset()
    this.store.getRange().forEach((record) => record.reject())
    this.store.clearFilter()
  },

  updateGrid() {
    return this.domainGrid.setDisabled(this.prohibited || this.global.getValue())
  },

  onClientValidation(form, valid) {
    if (this.disabled) return

    // Update dirty class for warning on page unload.
    const isDirty = this.isDirty()
    if (isDirty) {
      form.addClass('smx-dirty')
      this.isDirty()
    } else {
      form.removeClass('smx-dirty')
    }

    this.saveButton.setDisabled(this.prohibited || !isDirty || !valid)
    return this.resetButton.setDisabled(this.prohibited || !isDirty)
  }
})

module.exports = DomainFormPanel
