// forces a field to be lowercase
const lowercaseListeners = {
  change(field) {
    if (field.xtype === 'textfield') {
      return field.setValue(field.getValue().toLowerCase())
    }
  }
}

export default lowercaseListeners
