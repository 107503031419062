const Ext = require('ext')
const t = require('translate')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')

const TemplateChoiceForm = Ext.define(null, {
  extend: RecordFormPanel,

  forceLayout: true,

  constructor(cfg) {
    const { party } = cfg

    const templateRuleStore = new Ext.data.ArrayStore({
      data: [[0, t('Empty SmartRule'), '', t('Create a new empty SmartRule')]],
      fields: ['id', 'name', 'definition', 'description']
    })

    if (party) {
      const url = String.format('{0}/smartrules/systemrules', party.getUrl())
      const systemRulesStore = new (require('smartrules/RuleStore'))({
        url
      })
      systemRulesStore.on('load', (store) => templateRuleStore.add(store.getRange()))
      systemRulesStore.load()
    }

    const okbutton = new Ext.Button({
      text: t('OK'),
      cls: 'primary',
      handler: () => {
        const field = this.getForm().findField('templateDefinition')
        const templateRecord = field.findRecord('id', field.getValue())
        require('smartrules/Rule').create(cfg.party, {
          party: cfg.party,
          store: cfg.store,
          templateDefined: true,
          templateId: field.getValue(),
          templateName: templateRecord.get('name'),
          templateDefinition: templateRecord.get('definition'),
          templateDescription: templateRecord.get('description')
        })
        return this.findParentWindow().close()
      }
    })

    const template = new ComboBox({
      allowBlank: false,
      displayField: 'name',
      editable: false,
      fieldLabel: t('Base New Rule On'),
      hiddenName: 'templateDefinition',
      listeners: {
        select(combo, record) {
          return description.setValue(record.get('description'))
        }
      },
      mode: 'local',
      store: templateRuleStore,
      triggerAction: 'all',
      validator(v) {
        const valid = v !== ''
        okbutton.setDisabled(!valid)
        return valid
      },
      value: 0,
      valueField: 'id'
    })

    const description = new Ext.form.DisplayField()
    description.setValue(templateRuleStore.getAt(0).get('description'))

    this.buttons = [
      {
        text: t('Cancel'),
        handler: () => {
          return this.findParentWindow().close()
        }
      },
      okbutton
    ]
    this.items = [template, description]

    this.callParent()
  }
})

module.exports = TemplateChoiceForm
