import AbstractCompanyTabPanel from 'admin/view/AbstractCompanyTabPanel'
import DistributorDetailPanel from 'admin/view/DistributorDetailPanel'
import DistributorResource from 'admin/business/DistributorResource'
import Ext from 'ext'
import t from 'translate'

const DistributorTabPanel = Ext.define(null, {
  extend: AbstractCompanyTabPanel,

  module: DistributorResource,
  title: t('Distributor'),
  tabCls: 'distributor-tab',
  cls: 'distributor-panel',

  buildDetailPanel() {
    return new DistributorDetailPanel({ record: this.record })
  }
})

export default DistributorTabPanel
