import rootDb from 'db'
import deepmerge from 'deepmerge'
import appReducers from 'reducers'

import { configureStore } from '@reduxjs/toolkit'

// creates a new root reducer at will. it's a function within a function
// so that we can hot reload reducers anytime.
function getRootReducer(reducers) {
  return (db, action) => {
    if (action.type === 'RESET') {
      // reset whole app state i.E. for unit tests, logout actions etc
      db = deepmerge({}, rootDb)
    } else if (action.type === 'MERGE') {
      // merge existing app state with new app state layout. useful for
      // hot reloading changes of initial app states. existing app states
      // won't be overriden but new initial states will be.
      // to override all app states after changes, hard reloading browser is the only way.
      db = deepmerge(action.newDb, db)
    }

    return reducers(db, action)
  }
}

// note: this will include some default middlewares for dev environments
// https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
const rootStore = configureStore({
  reducer: getRootReducer(appReducers),
  preloadedState: rootDb,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // because some of our reducers and actions are quite large
      immutableCheck: { warnAfter: 64 },
      serializableCheck: { warnAfter: 64 }
    })
})

if (module.hot) {
  module.hot.accept('reducers.js', () => {
    // hot reload reducers. app state won't be lost.
    const newAppReducers = require('reducers').default
    rootStore.replaceReducer(getRootReducer(newAppReducers))
  })

  module.hot.accept('db.js', () => {
    // hot reload app state layout. won't change current app state but
    // can add new initial values if current state doesn't have these.
    const newRootDb = require('db').default
    rootStore.dispatch({ type: 'MERGE', newDb: newRootDb })
  })
}

export default rootStore
