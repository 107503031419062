import { NOTIFICATION_REL } from 'product/resources'
import { FORM_NAME as ADMINISTRATOR_FORM_NAME } from 'quarantine/notification/views/form/Administrator'
import { FORM_NAME as END_USER_FORM_NAME } from 'quarantine/notification/views/form/EndUser'
import createCompanySelector from 'system/selector/createCompanySelector'

import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('quarantine')
const formDb = (db) => db.form

export const notification = createSelector(company, (company) => {
  return company && company[NOTIFICATION_REL]
})

export const companyKey = createSelector(notification, (notification) => {
  return notification?.companyKey
})

export const uri = createSelector(notification, (notification) => {
  return notification?.uri
})

export const loading = createSelector(notification, (notification) =>
  Boolean(notification?.loading)
)

export const list = createSelector(notification, (notification) => {
  return notification?.list
})

export const userQuarantineDigest = createSelector(list, (list) => {
  return list?.find((notification) => {
    if (notification.notificationType === 'USER_QUARANTINE_DIGEST') {
      return notification
    }

    return false
  })
})

export const adminQuarantineDigest = createSelector(list, (list) => {
  return list?.find((notification) => {
    if (notification.notificationType === 'ADMIN_QUARANTINE_DIGEST') {
      return notification
    }

    return false
  })
})

/// ========= FORMS ========= ///

export const endUserForm = createSelector(formDb, companyKey, (formDb, companyKey) => {
  if (companyKey in formDb) {
    return formDb[companyKey][END_USER_FORM_NAME]
  }
})

export const administratorForm = createSelector(
  formDb,
  companyKey,
  (formDb, companyKey) => {
    if (companyKey in formDb) {
      return formDb[companyKey][ADMINISTRATOR_FORM_NAME]
    }
  }
)

export const disableForm = createSelector(loading, (loading) => {
  return Boolean(loading)
})

export const enablePeriodicQuarantineDigestsForEndUser = createSelector(
  endUserForm,
  disableForm,
  (endUserForm, disableForm) => {
    if (disableForm) return false
    return endUserForm?.values?.enabled
  }
)

export const enablePeriodicQuarantineDigestsForAdmin = createSelector(
  administratorForm,
  disableForm,
  (administratorForm, disableForm) => {
    if (disableForm) return false
    return administratorForm?.values?.enabled
  }
)

// ensure this returns a string, not an array
export const selectedAction = createSelector(
  endUserForm,
  loading,
  (endUserForm, loading) => {
    // do not return anything while still loading to avoid glitches
    if (loading) return

    const action = endUserForm?.values?.actions

    if (!action) return 'REVIEW' // default

    // beware that the actions are cumulative. each one adds on the next one.
    // reason for additional checks is because we had checkboxes before but
    // changed to radio buttons later. this code covers both cases to ensure
    // those customers with older data still have the correct UI elements selected

    if (
      action[0] === 'REVIEW' &&
      action[1] === 'RELEASE' &&
      action[2] === 'UNREVIEWED_RELEASE'
    ) {
      // backward compatibility for when we had checkboxes before
      return 'UNREVIEWED_RELEASE'
    }

    if (action[0] === 'REVIEW' && action[1] === 'RELEASE') {
      // backward compatibility for when we had checkboxes before
      return 'RELEASE'
    }

    // turn array into a string, so that the right radio button can be selected
    if (action.length === 1) {
      return action[0]
    }

    // it is already a string, so all good
    return action
  }
)
