const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const InvoiceRunRecord = require('admin/data/InvoiceRunRecord')
const InvoiceRunResource = require('admin/business/InvoiceRunResource')

const InvoiceRunStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'invoiceruns',
  sortInfo: {
    field: 'invoiceRunDate',
    direction: 'DESC'
  },

  record: InvoiceRunRecord,

  module() {
    return InvoiceRunResource
  }
})

module.exports = InvoiceRunStore
