const Record = require('admin/core/Record')

const CurrencyRecord = Record.define(null, {
  fields: [
    { name: 'id', type: 'int' },
    { name: 'abbreviation', type: 'string' },
    { name: 'code', type: 'string' },
    { name: 'description', type: 'string' }
  ],

  getName() {
    return this.get('description')
  },

  getDescription() {
    return this.get('description')
  }
})

module.exports = CurrencyRecord
