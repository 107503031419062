import compareIps from 'strings/compare/ip'
import compareStrings from 'strings/compare/lexical'
import humanize from 'underscore.string/humanize'
import isObject from 'is-plain-obj'

const getDomain = (emailAddress) => {
  return emailAddress?.substring(emailAddress.lastIndexOf('@') + 1)
}

const getEmailAccount = (emailAddress) => {
  return emailAddress?.substring(0, emailAddress.lastIndexOf('@'))
}

const compareEmailAccounts = (email1, email2) => {
  return compareStrings(getEmailAccount(email1), getEmailAccount(email2))
}

const compareDomains = (email1, email2) => {
  return compareStrings(getDomain(email1), getDomain(email2))
}

// function class with encapsulation
const Membership = function (originalServerTypes = {}) {
  let currentServerTypes

  const reset = () => {
    // deep clone
    currentServerTypes = JSON.parse(JSON.stringify(originalServerTypes))
  }

  reset()

  const getMailboxComparator = () => {
    return function (member1, member2) {
      return (
        compareDomains(member1.name, member2.name) ||
        compareEmailAccounts(member1.name, member2.name)
      )
    }
  }

  const getIpComparator = () => {
    return (member1, member2) => compareIps(member1.name, member2.name)
  }

  const getDefaultComparator = () => {
    return (member1, member2) => compareStrings(member1.name, member2.name)
  }

  const hasTypes = () => {
    return isObject(currentServerTypes) && Object.keys(currentServerTypes).length > 0
  }

  const getSortedTypes = () => {
    if (hasTypes()) {
      return Object.keys(currentServerTypes).sort()
    }

    return []
  }

  const getMembersByType = (type) => currentServerTypes[type] || []

  const getComparator = (type) => {
    switch (type) {
      case 'mailboxes':
        return getMailboxComparator()
      case 'mail-servers':
      case 'trusted-ips':
        return getIpComparator()
      default:
        return getDefaultComparator()
    }
  }

  const countMembersOfType = (type) => getMembersByType(type).length

  const getSortedMembersByType = (type) => {
    return getMembersByType(type).sort(getComparator(type))
  }

  const getMembers = () => {
    return getSortedTypes().reduce(
      (allMembers, type) => allMembers.concat(getMembersByType(type)),
      []
    )
  }

  const getAllEnabled = () => getMembers().filter((member) => member.enabled)

  const getAllNamesEnabled = () => {
    return getMembers().reduce((allNamesEnabled, member) => {
      if (member.enabled) {
        allNamesEnabled.push(member.name)
      }

      return allNamesEnabled
    }, [])
  }

  const setEnabled = function (type, enabled, name = null) {
    if (!type) return
    if (!currentServerTypes[type]) return

    currentServerTypes[type] = currentServerTypes[type].map((member) => {
      if (!name || member.name === name) {
        member.enabled = enabled
      }

      return member
    })
  }

  const setEnabledByName = (type, name, enabled) => setEnabled(type, enabled, name)

  const setEnabledByType = (type, enabled) => setEnabled(type, enabled)

  const everyMemberEnabledOfType = (type) => {
    return getMembersByType(type).every((member) => member.enabled)
  }

  const hasChanged = () => {
    return JSON.stringify(currentServerTypes) !== JSON.stringify(originalServerTypes)
  }

  // only those are public
  return {
    hasTypes,
    getTypes: () => currentServerTypes,
    getSortedTypes,
    getMembersByType,
    getSortedMembersByType,
    getTitleForType: humanize,
    getAllEnabled,
    setEnabledByType,
    setEnabledByName,
    everyMemberEnabledOfType,
    hasChanged,
    reset,
    countMembersOfType,
    getAllNamesEnabled
  }
}

export default Membership
