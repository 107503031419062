import {
  QUARANTINE_MAIL_TARGETS_CLEAR_LIST,
  QUARANTINE_MAIL_TARGETS_SELECT,
  QUARANTINE_MAIL_TARGETS_SELECT_BY_LINK,
  QUARANTINE_MAIL_TARGETS_SET_LIST,
  QUARANTINE_MAIL_TARGETS_SET_URI,
  QUARANTINE_MAIL_TARGETS_START_CONFIGURING,
  QUARANTINE_MAIL_TARGETS_START_CONFIRMING_DELETION,
  QUARANTINE_MAIL_TARGETS_START_CREATING,
  QUARANTINE_MAIL_TARGETS_START_LOADING,
  QUARANTINE_MAIL_TARGETS_START_RENAMING,
  QUARANTINE_MAIL_TARGETS_STOP_CONFIGURING,
  QUARANTINE_MAIL_TARGETS_STOP_CONFIRMING_DELETION,
  QUARANTINE_MAIL_TARGETS_STOP_CREATING,
  QUARANTINE_MAIL_TARGETS_STOP_LOADING,
  QUARANTINE_MAIL_TARGETS_STOP_RENAMING
} from './actions'

import { MAIL_TARGET_REL } from 'product/resources'
import { createNextState } from '@reduxjs/toolkit'
import getIn from 'lodash/get'
import initialState from 'quarantine/db'
import setIn from 'lodash/set'

const findIndex = (mailTargets, id) => {
  return mailTargets?.findIndex((mailTarget) => mailTarget.id === id)
}

const getBaseKeyPath = (companyKey) => ['companies', companyKey, MAIL_TARGET_REL]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getListKeyPath = (companyKey) => getKeyPath(companyKey, 'list')

const getList = (quarantineDb, companyKey) =>
  getIn(quarantineDb, getListKeyPath(companyKey))

const select = (quarantineDb, companyKey, selected, matcher) => {
  const mailTargets = getIn(quarantineDb, getListKeyPath(companyKey, 'list'))

  return setIn(
    quarantineDb,
    getListKeyPath(companyKey, 'list'),
    mailTargets &&
      mailTargets.map((mailTarget) => {
        if (matcher(mailTarget)) {
          return Object.assign(mailTarget, { selected })
        }

        return Object.assign(mailTarget, { selected: false })
      })
  )
}

const reducers = {
  [QUARANTINE_MAIL_TARGETS_SELECT]: (quarantineDb, action) => {
    // because only one can be selected, we are safe just to pop()
    const row = action.mailTargets.pop()

    return select(quarantineDb, action.companyKey, action.selected, (mailTarget) => {
      return mailTarget.id === row.id
    })
  },

  [QUARANTINE_MAIL_TARGETS_SELECT_BY_LINK]: (quarantineDb, action) => {
    return select(quarantineDb, action.companyKey, action.selected, (mailTarget) => {
      return mailTarget._links.self.href === action.selfLink
    })
  },

  [QUARANTINE_MAIL_TARGETS_SET_LIST]: (quarantineDb, action) => {
    const companyKey = action.companyKey
    const oldList = getList(quarantineDb, companyKey)
    const newQuarantineDb = setIn(
      quarantineDb,
      getListKeyPath(companyKey),
      action.newList
    )

    // remember current selection
    const selectedMailTarget =
      oldList && oldList.find((mailTarget) => mailTarget.selected)

    if (!selectedMailTarget) {
      return newQuarantineDb
    }

    // restore selection if one was selected before
    const selectedMailTargetIndex = findIndex(
      getList(newQuarantineDb, action.companyKey),
      selectedMailTarget.id
    )

    if (selectedMailTargetIndex >= 0) {
      const path = getListKeyPath(companyKey).concat([
        selectedMailTargetIndex,
        'selected'
      ])
      return setIn(newQuarantineDb, path, true)
    }

    return newQuarantineDb
  },

  [QUARANTINE_MAIL_TARGETS_CLEAR_LIST]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'list'), null)
  },

  [QUARANTINE_MAIL_TARGETS_START_LOADING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [QUARANTINE_MAIL_TARGETS_STOP_LOADING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [QUARANTINE_MAIL_TARGETS_START_CONFIGURING]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'configTarget'),
      action.mailTarget
    )
  },

  [QUARANTINE_MAIL_TARGETS_STOP_CONFIGURING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'configTarget'), null)
  },

  [QUARANTINE_MAIL_TARGETS_START_RENAMING]: (quarantineDb, action) => {
    return setIn(
      quarantineDb,
      getKeyPath(action.companyKey, 'renameTarget'),
      action.mailTarget
    )
  },

  [QUARANTINE_MAIL_TARGETS_STOP_RENAMING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'renameTarget'), null)
  },

  [QUARANTINE_MAIL_TARGETS_START_CREATING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'creating'), true)
  },

  [QUARANTINE_MAIL_TARGETS_STOP_CREATING]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'creating'), false)
  },

  [QUARANTINE_MAIL_TARGETS_SET_URI]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'uri'), action.uri)
  },

  [QUARANTINE_MAIL_TARGETS_START_CONFIRMING_DELETION]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'confirmingDeletion'), true)
  },

  [QUARANTINE_MAIL_TARGETS_STOP_CONFIRMING_DELETION]: (quarantineDb, action) => {
    return setIn(quarantineDb, getKeyPath(action.companyKey, 'confirmingDeletion'), false)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
