const Ext = require('ext')
const t = require('translate')
const Group = require('smartrules/designer/Group')
const ActionClauseTree = require('smartrules/designer/ActionClauseTree')
const GroupHeader = require('smartrules/designer/GroupHeader')
const ClauseTreeMember = require('smartrules/designer/ClauseTreeMember')

// A group of actions.
const ActionGroup = Ext.define(null, {
  extend: Group,

  constructor(cfg = {}) {
    this.groupHeaderItem = cfg.groupHeaderItem
    Ext.applyIf(cfg, {
      clauseTreeType: ActionClauseTree,
      dropLabel: t('Drag actions here'),
      firstJoinWord: t('Do', 'SmartRules Actions'),
      defaultJoinWord: t('And', 'SmartRules Actions'),
      groupHeader: new GroupHeader({
        items: cfg.groupHeaderItem,
        margins: '0 -4 0 -4',
        nest: true,
        group: this,
        useSpacer: cfg.useSpacer
      })
    })
    this.callParent([cfg])
  },

  retractNest() {
    const smartrule = this.getSmartRule()
    this.abandon()
    smartrule.fireEvent('smartrules-drop')
  },

  toDsl() {
    const header = this.groupHeaderItem ? this.groupHeaderItem.toDsl() + ' ' : ''
    const subs = this.groupPanel.items
      .getRange()
      .filter((n) => n instanceof ClauseTreeMember)
      .map((action) => action.toDsl())
    const rest = subs.length > 0 ? (this.root ? '' : 'then ') + subs.join(' ') : ''
    const dsl = `${header}${rest}`
    if (this.root) return dsl
    return `(${dsl})`
  }
})

module.exports = ActionGroup
