import { MAILHOSTING_UNLOAD } from 'mailhosting/actions'
import catalogueReducers from 'mailhosting/catalogue/reducers'
import { createNextState } from '@reduxjs/toolkit'
import domainReducers from 'mailhosting/domain/reducers'
import initialState from 'mailhosting/db'

const reducers = {
  [MAILHOSTING_UNLOAD]: (mailhostingDb, { companyKey }) => {
    delete mailhostingDb.companies[companyKey]
    return mailhostingDb
  }
}

export default function (moduleDb = initialState, action = {}) {
  const reducer = reducers[action.type]

  if (reducer) {
    return createNextState(moduleDb, (draftState) => reducer(draftState, action))
  }

  // else delegate action further up
  return [catalogueReducers, domainReducers].reduce((db, reducer) => {
    return reducer(db, action)
  }, moduleDb)
}
