const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const RuleManagement = require('smartrules/RuleManagement')
const RuleSetRecord = require('smartrules/RuleSetRecord')

const RuleSetStore = Ext.define(null, {
  extend: ResourceStore,

  module() {
    return RuleManagement
  },

  resourceName: 'rulesets',
  sortInfo: {
    field: 'priority',
    direction: 'ASC'
  },

  record: RuleSetRecord
})

module.exports = RuleSetStore
