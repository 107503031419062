const Ext = require('ext')
const DateTimeRangeFieldSet = require('admin/component/form/DateTimeRangeFieldSet')

/**
@class Ext.ux.form.DateRangeFieldSet
@extends Ext.Panel
Provides a from -> thru date fieldset with validations
@TODO Is this subclass really necessary? We could easily remove and replace all instances
with DateTimeRangeFieldSet with disableTime: true. Means fewer classes.
*/

const DateRangeFieldSet = Ext.define(null, {
  extend: DateTimeRangeFieldSet,

  xtype: 'daterangefieldset',

  constructor(cfg = {}) {
    cfg.both = cfg.both || {}
    cfg.both.disableTime = true

    this.callParent([cfg])
  }
})

module.exports = DateRangeFieldSet
