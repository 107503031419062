const t = require('translate')
const Record = require('admin/core/Record')

const RuleRecord = Record.define(null, {
  module() {
    return require('smartrules/Rule')
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'sequenceNumber',
      type: 'int'
    },
    {
      convert(v, record) {
        if (record.enabled !== false) {
          return v
        }
        return String.format('{0} [{1}]', v, t('Disabled'))
      },
      name: 'listName',
      mapping: 'name'
    },
    {
      name: 'name'
    },
    {
      name: 'description',
      type: 'string'
    },
    // Enabled is boolean for customers and null for vendors.
    {
      name: 'enabled',
      type: 'string'
    },
    {
      name: 'definition',
      type: 'string'
    },
    {
      name: 'fromDate'
    },
    {
      name: 'thruDate'
    },
    {
      name: 'editable',
      type: 'boolean'
    }
  ],

  constructor() {
    this.callParent(arguments)

    // Keep listName field in sync.
    return this.on('change', function () {
      let name = this.get('name')
      if (this.get('enabled') === 'false') {
        name = `${name} [${t('Disabled')}]`
      }
      return this.set('listName', name)
    })
  },

  getName() {
    return this.get('name')
  },

  getDescription() {
    return this.get('name')
  },

  isEditable() {
    return this.get('editable')
  },

  getParams(opts) {
    let params = {
      name: this.get('name'),
      description: this.get('description'),
      enabled: this.get('enabled'),
      definition: this.get('definition')
    }

    if (opts.additionalParams) {
      params = Object.assign(params, opts.additionalParams)
    }

    return params
  },

  canSave() {
    return this.callParent() && this.isEditable()
  },

  // Changing a smartrule actually creates a new rule that replaces it.
  saveNewVersion(attrs) {
    const newVersion = new RuleRecord(attrs)
    return newVersion
      .save(null, {
        url: this.getUrl(),
        // @since https://youtrack.smxemail.com/issue/SCL-2199
        // because we only post the sequenceNumber when updating an existing rule.
        // when creating a new one, we don't post it, see getParams()
        additionalParams: {
          sequenceNumber: this.get('sequenceNumber')
        }
      })
      .then(() => {
        return this.fireEvent('new-version')
      })
  },

  // this ensures that after every save operation a subsequent GET
  // for the same resource is made, see SCL-2488
  isPersistentOnSave() {
    return true
  }
})

module.exports = RuleRecord
