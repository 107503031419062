const Ext = require('ext')
const t = require('translate')
const ResourceStore = require('admin/data/ResourceStore')
const PolicyRecord = require('admin/data/PolicyRecord')
const WindowMgr = require('admin/desktop/WindowMgr').default
const Alert = require('admin/util/Alert').default

const PolicyStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'policies',

  hasMultiSort: true,
  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      { field: 'priority', direction: 'ASC' },
      { field: 'operand', direction: 'ASC' }
    ]
  },
  groupField: 'priority',

  record: PolicyRecord,

  // todo this needs to be refactored out (all batch operations)
  progressBar: new Ext.ProgressBar(),
  progress: -1,

  onRemove() {
    if (!this.batchMode) {
      this.callParent(arguments)
    }
  },

  cancelBatch() {
    return (this.cancel = true)
  },

  commitBatch(options, success) {
    if (this.progress === -1) {
      this.batchMode = true
      this.cancel = false
      WindowMgr.create({
        autoShow: true,
        closable: false,
        collapsible: false,
        draggable: false,
        maximizable: false,
        minimizable: false,
        modal: true,
        resizable: false,
        items: this.progressBar,
        width: 400,
        showTaskbar: false,
        buttons: [
          {
            text: t('Stop'),
            handler: this.cancelBatch,
            scope: this
          }
        ],
        callback: function (win) {
          return (this.progressWindow = win)
        }.createDelegate(this)
      })
    } else {
      if (!success) {
        Alert.alert(t('Error'), t('An error has occurred committing the batch'))
        this.resetBatch(true)
        return
      }
      if (this.cancel) {
        this.resetBatch()
        return
      }
    }

    this.progress++

    if (this.progress < this.batch.length) {
      const record = this.batch[this.progress]
      const progressMsg = t('Deleting {type} {operand}', {
        type: record.getType(),
        operand: record.get('operand')
      })
      this.progressBar.updateProgress(
        (this.progress + 1.0) / this.batch.length,
        progressMsg
      )
      this.remove(record)
      return Ext.Ajax.request({
        url: this.url + '/' + record.get('id'),
        delRecord: record,
        method: 'DELETE',
        callback: this.commitBatch,
        scope: this
      })
    }
    return this.resetBatch(false)
  },

  resetBatch(reload) {
    this.progress = -1
    if (this.progressWindow != null) {
      this.progressWindow.hide()
    }
    this.progressBar.reset()
    this.batchMode = false
    this.batch = []
    if (reload) {
      return this.reload()
    }
  }
})

module.exports = PolicyStore
