import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Button, Col, FormGroup, Row } from 'react-bootstrap'
import t from 'translate'

class CancelSubmitButtons extends Component {
  static propTypes = {
    colWidth: PropTypes.number,
    disableCancel: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    onCancel: PropTypes.func,
    submitText: PropTypes.string,
    cancelText: PropTypes.string
  }

  static defaultProps = {
    colWidth: 11,
    disableCancel: true,
    disableSubmit: true,
    submitText: t('Save'),
    cancelText: t('Cancel')
  }

  render() {
    return (
      <Row className='no-gutter'>
        <FormGroup>
          <Col xs={this.props.colWidth}>
            <Button
              type='button'
              className='button-back' // needed for unit tests
              onClick={this.props.onCancel}
              disabled={this.props.disableCancel}
            >
              {this.props.cancelText}
            </Button>
            <Button type='submit' bsStyle='primary' disabled={this.props.disableSubmit}>
              {this.props.submitText}
            </Button>
          </Col>
        </FormGroup>
      </Row>
    )
  }
}

export default CancelSubmitButtons
