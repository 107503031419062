const Ext = require('ext')
const t = require('translate')
let TabPanel = require('admin/view/TabPanel').default
const RuleManagementPanel = require('smartrules/RuleManagementPanel')
const ListManagementListPanel = require('smartrules/ListManagementListPanel')
const TemplateManagementPanel = require('smartrules/TemplateManagementPanel')

TabPanel = Ext.define(null, {
  extend: TabPanel,

  title: t('SmartRules'),
  tabCls: 'smartrules-tab',
  cls: 'smartrules-panel',

  constructor(cfg = {}) {
    // Will receive customer or vendor depending where it is used.
    this.customer = cfg.customer
    this.vendor = cfg.vendor
    this.companyRecord = this.customer || this.vendor
    this.product = this.companyRecord.findProduct('SMART_RULES')

    if (!(this.product && this.product.current)) {
      this.tabCls = this.tabCls + ' expired-group-tab'
    }

    const hosting = this.companyRecord.hasHosting()
    const products = this.companyRecord.get('products')
    const showInbound = hosting || (products && products.INBOUND_SCRUB)
    const showOutbound = hosting || (products && products.OUTBOUND_SCRUB)

    this.items = [
      showInbound &&
        new RuleManagementPanel({
          title: t('Inbound Rules'),
          filter: 'INBOUND_RULE_SET',
          customer: this.customer,
          vendor: this.vendor,
          product: this.product
        }),

      showOutbound &&
        new RuleManagementPanel({
          title: t('Outbound Rules'),
          filter: 'OUTBOUND_RULE_SET',
          customer: this.customer,
          vendor: this.vendor,
          product: this.product
        }),

      new ListManagementListPanel({
        record: this.companyRecord,
        product: this.product,
        store: this.companyRecord.getListManagementStore()
      }),

      // do not show templates when i am a vendor on system level (system config) because
      // as a vendor user i cannot have that product. hence show it only as a customer
      this.customer &&
        new TemplateManagementPanel({
          customer: this.customer,
          product: this.product
        })
    ].filter(Boolean)

    this.callParent()
  }
})

module.exports = TabPanel
