const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const PolicyManagementListPanel = require('admin/view/PolicyManagementListPanel')
const PolicySetRecord = require('admin/data/PolicySetRecord')

const PolicyManagementResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.PolicyManagement',
  translations: {
    title: t('Policy Management'),
    plural: t('Policy Management'),
    new: t('New Policy Management'),
    open: t('Policy Management'),
    list: t('Policy Management')
  },
  name: t('Policy Management'),
  code: 'POLICY',
  resourceId: 'POLICY_SETS',
  resourceName: 'policysets',

  policyTypes: {
    whitelist: [
      {
        action: 'ALLOW',
        matcher: 'ip address',
        text: t('Whitelist ip address')
      },
      {
        action: 'ALLOW',
        matcher: 'from user',
        text: t('Whitelist from user')
      },
      {
        action: 'ALLOW',
        matcher: 'to user',
        text: t('Whitelist to user')
      },
      {
        action: 'ALLOW',
        matcher: 'from domain',
        text: t('Whitelist from domain')
      },
      {
        action: 'ALLOW',
        matcher: 'to domain',
        text: t('Whitelist to domain')
      },
      {
        action: 'ALLOW',
        matcher: 'subject',
        text: t('Whitelist subject')
      }
    ],

    blacklist: [
      {
        action: 'DENY',
        matcher: 'ip address',
        text: t('Blacklist ip address')
      },
      {
        action: 'DENY',
        matcher: 'from user',
        text: t('Blacklist from user')
      },
      {
        action: 'DENY',
        matcher: 'to user',
        text: t('Blacklist to user')
      },
      {
        action: 'DENY',
        matcher: 'from domain',
        text: t('Blacklist from domain')
      },
      {
        action: 'DENY',
        matcher: 'to domain',
        text: t('Blacklist to domain')
      },
      {
        action: 'DENY',
        matcher: 'subject',
        text: t('Blacklist subject')
      }
    ]
  },

  priorities: {
    'ALLOW:IP ADDRESS': 1,
    'DENY:IP ADDRESS': 2,

    'ALLOW:FROM USER': 3,
    'ALLOW:TO USER': 4,

    'DENY:FROM USER': 5,
    'DENY:TO USER': 6,

    'ALLOW:FROM DOMAIN': 7,
    'ALLOW:TO DOMAIN': 8,

    'DENY:FROM DOMAIN': 9,
    'DENY:TO DOMAIN': 10,

    'ALLOW:SUBJECT': 11,
    'DENY:SUBJECT': 12
  },

  listPanel: PolicyManagementListPanel,

  record: PolicySetRecord
})

module.exports = PolicyManagementResource
