import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Col, Button, Panel, Row } from 'react-bootstrap'

import t from 'translate'
import Grid from 'components/Grid'
import Icon from 'components/Icon'

class GuardPanel extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSingleMessageAuthorization: PropTypes.func.isRequired,
    onSessionAuthorization: PropTypes.func.isRequired
  }

  render() {
    return (
      <Panel className='guard'>
        <Grid style={{ padding: '2em' }}>
          <Row>
            <Col xs={2} xsOffset={2} style={{ textAlign: 'center' }}>
              <Button block bsSize='large' onClick={this.props.onCancel}>
                <Icon type='back' />
                <p>{t('Back to search results')}</p>
              </Button>
            </Col>
            <Col xs={2} xsOffset={1} style={{ textAlign: 'center' }}>
              <Button
                block
                bsSize='large'
                className='unlock'
                onClick={this.props.onSingleMessageAuthorization}
              >
                <Icon type='unlock' />
                <p>{t("I'm authorised to preview this message")}</p>
              </Button>
            </Col>
            <Col xs={2} xsOffset={1} style={{ textAlign: 'center' }}>
              <Button
                block
                bsSize='large'
                className='unlockAll'
                onClick={this.props.onSessionAuthorization}
              >
                <Icon type='unlockAll' />
                <p>{t("I'm authorised to preview all messages")}</p>
              </Button>
            </Col>
          </Row>
        </Grid>
      </Panel>
    )
  }
}

export default GuardPanel
