import Ext from 'ext'
import ResourceStore from 'admin/data/ResourceStore'
import ScrubbingDomainRecord from 'admin/data/ScrubbingDomainRecord'
import ScrubbingDomainResource from 'admin/business/ScrubbingDomainResource'
import { refresh as refreshMembership } from 'archiving/membership/actions'
import store from 'store'
const { isArchivingProvisioningCapabilityFlagEnabled } = require('system/capability/subs')

const ScrubbingDomainStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'scrubbingdomains',
  sortInfo: {
    direction: 'ASC',
    field: 'name'
  },

  record: ScrubbingDomainRecord,

  module() {
    return ScrubbingDomainResource
  },

  constructor(cfg = {}) {
    this.superclass().constructor.call(this, cfg)

    this.on('add', function () {
      this.reloadArchivingMembership()
    })

    this.on('remove', function () {
      this.reloadArchivingMembership()
    })

    return this.on('change', function () {
      this.reloadArchivingMembership()
    })
  },

  reloadArchivingMembership() {
    if (!isArchivingProvisioningCapabilityFlagEnabled(store.getState())) return
    if (!this.parentRecord) return // unit testing
    if (!this.parentRecord.hasArchiving) return
    if (typeof this.parentRecord.hasArchiving !== 'function') return
    if (!this.parentRecord.hasArchiving()) return

    store.dispatch(
      refreshMembership(
        this.parentRecord.getCompanyKey(),
        [this.parentRecord.url, 'archiving', 'membership'].join('/')
      )
    )
  },

  canCreate() {
    return this.module.canCreate() && this.product.current
  }
})

export default ScrubbingDomainStore
