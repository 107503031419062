import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'translate'

import Format from 'admin/util/Format'

const getAttachmentName = (attachment) => {
  if (attachment.name) {
    return attachment.name
  }

  return '(' + t('no name') + ')'
}

const getAttachmentType = (attachment) => {
  return '[' + (attachment.type ? attachment.type : t('no type')) + ']'
}

const renderAttachmentSize = (attachment) => {
  return <small>{Format.bytes(attachment.size)}</small>
}

const renderAttachmentContent = (attachment) => {
  return <pre>{attachment.content}</pre>
}

class List extends Component {
  static propTypes = {
    attachments: PropTypes.array.isRequired
  }

  render() {
    return (
      <ul>
        {this.props.attachments.map((attachment, i) => (
          <li key={i} className='attachment'>
            <h5>
              {getAttachmentName(attachment)}
              <small>{getAttachmentType(attachment)}</small>
              {attachment.size && renderAttachmentSize(attachment)}
            </h5>
            {attachment.content && renderAttachmentContent(attachment)}
          </li>
        ))}
      </ul>
    )
  }
}

export default List
