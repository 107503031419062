const Ext = require('ext')
const validators = require('validators').default
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const SizeCombo = require('smartrules/designer/SizeCombo')

const SizeMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg) {
    const items = []
    const uom = (cfg.value != null ? cfg.value.uom : undefined) || 'kB'
    // 0 is a possible value.
    const value = cfg.value ? cfg.value.value : ''
    this.direction = cfg.direction
    const config = {
      forSize: cfg.forSize,
      items,
      layout: 'hbox'
    }

    const textfield = new Ext.form.TextField({
      allowBlank: false,
      name: 'value',
      ref: 'textfield',
      value,
      validator: this.getTextfieldValidator(),
      maskRe: /\d/,
      width: 55,
      xtype: 'textfield',
      maxLength: 4
    })

    items.push(textfield)

    if (cfg.forSize) {
      const combo = new SizeCombo({
        name: 'size',
        ref: 'combo',
        value: uom
      })
      items.push(combo)
    }

    this.callParent([config])
  },

  getTextfieldValidator() {
    return validators.positiveInteger.withMsg(t('Please enter a positive number'))
  },

  getValue() {
    if (this.forSize) {
      return String.format(
        'is {0} {1}{2}',
        this.direction,
        this.textfield.getValue(),
        this.combo.getValue()
      )
    }
    return String.format('is {0} {1}', this.direction, this.textfield.getValue())
  }
})

module.exports = SizeMatcher
