const Ext = require('ext')
const t = require('translate')
const ResourceStore = require('admin/data/ResourceStore')
const EventDetailRecord = require('admin/data/EventDetailRecord')
const EventResource = require('admin/business/EventResource')

const EventDetailStore = Ext.define(null, {
  extend: ResourceStore,

  statics: {
    EVENT_STATUSES: {
      AAMTA_RECEIVED: t('Received'),
      AAMTA_INCOMPLETE: t('Received'),
      AAMTA_PROCESSED: t('Received'),
      AAMTA_REJECTED: t('Rejected'),
      MTA_RECEIVED: t('Received'),
      MTA_QUEUED: t('Queued'),
      MTA_EXPIRED_FROM_QUEUE: t('Expired From Queue'),
      MTA_FAILED: t('Error'),
      MTA_DISCARDED: t('Discarded'),
      RECIPIENT_MTA_REJECTED: t('Rejected By Recipient'),
      DELIVERED: t('Delivered'),
      DELIVERED_FROM_QUEUE: t('Delivered From Queue'),
      DELIVERED_FROM_QUARANTINE: t('Delivered'),
      QUARANTINED: t('Quarantined'),
      CONNECTION_REJECTED: t('Connection Rejected')
    },

    getQuarantineStatus() {
      return this.EVENT_STATUSES.QUARANTINED
    }
  },

  resourceName: 'eventdetail',
  record: EventDetailRecord,

  module() {
    return EventResource
  }
})

module.exports = EventDetailStore
