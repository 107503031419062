import Api from 'api'
import isStringArrayEqual from 'strings/compare/array'
import logger from 'system/logger'

export const NOTIFICATION_SET_URI = 'NOTIFICATION_SET_URI'
export const NOTIFICATION_UNLOAD = 'NOTIFICATION_UNLOAD'
export const NOTIFICATION_START_LOADING = 'NOTIFICATION_START_LOADING'
export const NOTIFICATION_STOP_LOADING = 'NOTIFICATION_STOP_LOADING'
export const NOTIFICATION_SET_LIST = 'NOTIFICATION_SET_LIST'
export const NOTIFICATION_SET_COMPANY_KEY = 'NOTIFICATION_SET_COMPANY_KEY'

const startLoading = (companyKey) => ({
  type: NOTIFICATION_START_LOADING,
  companyKey
})

const stopLoading = (companyKey) => ({ type: NOTIFICATION_STOP_LOADING, companyKey })

/// ========= INIT DB WITH URI FOR COMPANY KEY ========= ///

const setUri = (companyKey, uri) => ({
  type: NOTIFICATION_SET_URI,
  companyKey,
  uri
})

const setCompanyKey = (companyKey) => ({
  type: NOTIFICATION_SET_COMPANY_KEY,
  companyKey
})

export const init = (companyKey, uri) => (dispatch) => {
  dispatch(setUri(companyKey, uri))
  dispatch(setCompanyKey(companyKey))
}

export const unload = (companyKey) => ({
  type: NOTIFICATION_UNLOAD,
  companyKey
})

/// ========= GET ========= ///

export const setList = (companyKey, newList) => ({
  type: NOTIFICATION_SET_LIST,
  companyKey,
  newList
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)
    const newList = response?.data.map((notificationType) => {
      // do some transformations to match with the HTML form input names
      const entry = {
        notificationType: notificationType['notification-type'],
        enabled: notificationType.enabled,
        startTime: notificationType.options['start-time'],
        duration: notificationType.options.duration,
        digestTypes: notificationType.options['digest-types']
      }

      if ('actions' in notificationType.options) {
        entry.actions = notificationType.options.actions
      }

      if ('quarantine-role-recipients' in notificationType.options) {
        entry.quarantineRoleRecipients =
          notificationType.options['quarantine-role-recipients']
      }

      if ('additional-recipients' in notificationType.options) {
        entry.additionalRecipients =
          notificationType.options['additional-recipients']?.join('\n')
      }

      if ('allowed-recipients' in notificationType.options) {
        entry.allowedRecipients =
          notificationType.options['allowed-recipients']?.join('\n')
      }

      return entry
    })

    dispatch(setList(companyKey, newList))
  } catch (err) {
    logger.error(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

/// ========= PUT ========= ///

const normalizeCheckboxes = (values) => {
  if (!values) return []
  return Object.keys(values).reduce((array, key) => {
    const value = values[key]
    if (value) array.push(value)

    return array
  }, [])
}

export const updateNotifications =
  (companyKey, notificationsUri, notifications) => async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      const promises = notifications.map((notification) => {
        const values = {
          'notification-type': notification.notificationType,
          enabled: notification.enabled,
          options: {
            'start-time': notification.startTime,
            duration: notification.duration,
            'digest-types': normalizeCheckboxes(notification.digestTypes)
          }
        }

        if ('actions' in notification && notification.actions) {
          if (
            notification.actions === 'REVIEW' ||
            isStringArrayEqual(notification.actions, ['REVIEW'])
          ) {
            values.options.actions = ['REVIEW']
          } else if (
            notification.actions === 'RELEASE' ||
            isStringArrayEqual(notification.actions, ['REVIEW', 'RELEASE'])
          ) {
            values.options.actions = ['REVIEW', 'RELEASE']
          } else if (
            notification.actions === 'UNREVIEWED_RELEASE' ||
            isStringArrayEqual(notification.actions, [
              'REVIEW',
              'RELEASE',
              'UNREVIEWED_RELEASE'
            ])
          ) {
            values.options.actions = ['REVIEW', 'RELEASE', 'UNREVIEWED_RELEASE']
          }
        }

        if ('quarantineRoleRecipients' in notification) {
          values.options['quarantine-role-recipients'] = Boolean(
            notification.quarantineRoleRecipients
          )
        }

        if ('additionalRecipients' in notification) {
          let additionalRecipients = []

          if (notification.additionalRecipients) {
            additionalRecipients = notification.additionalRecipients
              .trim()
              .split(/\r\n|\r|\n/)
          }

          values.options['additional-recipients'] = additionalRecipients
        }

        if ('allowedRecipients' in notification) {
          let allowedRecipients = []

          if (notification.allowedRecipients) {
            allowedRecipients = notification.allowedRecipients.trim().split(/\r\n|\r|\n/)
          }

          values.options['allowed-recipients'] = allowedRecipients
        }

        const uri = notificationsUri + '/' + values['notification-type']
        return Api.put(uri, values, {
          'content-type': 'application/json'
        })
      })

      // run 'em in parallel for speed
      await Promise.all(promises)
    } catch (err) {
      logger.error(err)
      return dispatch(stopLoading(companyKey))
    }

    try {
      await dispatch(refresh(companyKey, notificationsUri))
    } finally {
      dispatch(stopLoading(companyKey))
    }
  }
