const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const RuleRecord = require('smartrules/RuleRecord')
const Rule = require('smartrules/Rule')
const User = require('admin/core/User')

const RuleStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'rules',
  sortInfo: {
    field: 'sequenceNumber',
    direction: 'ASC'
  },

  record: RuleRecord,

  module() {
    return Rule
  },

  constructor() {
    this.callParent(arguments)

    // Editing a rule actually creates a new version. Reload the store when this happens.
    return this.on('new-version', () => this.load())
  },

  canCreate() {
    if (this.parentRecord.getType() === 'VENDOR') {
      return this.callParent() && User.can('VENDOR_SMART_RULES', 'PUT')
    }
    return this.callParent() && User.hasRole('TECHNICAL')
  },

  canRemove() {
    if (this.parentRecord.getType() === 'VENDOR') {
      return this.callParent() && User.can('VENDOR_SMART_RULES', 'POST')
    }
    return this.callParent() && User.hasRole('TECHNICAL')
  }
})

module.exports = RuleStore
