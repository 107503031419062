import React, { Component } from 'react'

import { getTimeZoneByName, SORTED_TIMEZONES } from 'admin/time/TimeZone'
import PropTypes from 'prop-types'
import Select from 'react-select'

const TIMEZONES = SORTED_TIMEZONES.map((tz) => {
  return { label: tz.label, value: tz.zoneName }
})

class TimeZoneInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    timeZoneName: PropTypes.string,
    timeZones: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    timeZones: TIMEZONES
  }

  handleChange = (selectedTimeZone) => {
    const timeZone = getTimeZoneByName(selectedTimeZone.value)
    this.props.onChange(timeZone.zoneName)
  }

  getValue = () => {
    return this.props.timeZones.find(({ value }) => this.props.timeZoneName === value)
  }

  render = () => {
    return (
      <Select
        className='timeZone'
        classNamePrefix='select'
        options={this.props.timeZones}
        onChange={this.handleChange}
        value={this.getValue()}
        isClearable={false}
        backspaceRemovesValue={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0
        })}
      />
    )
  }
}

export default TimeZoneInput
