const Ext = require('ext')
const t = require('translate')
const ProductDirection = require('product/direction')
const escape = require('html-escaper').escape
const ItemGridPanel = require('admin/component/grid/ItemGridPanel').default
const validators = require('validators').default

const SignatureGridPanel = Ext.define(null, {
  extend: ItemGridPanel,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      addLabel: t('New'),
      addTooltip: t('Add a new footer'),
      columns: [
        {
          dataIndex: 'name',
          editor: {
            allowBlank: false,
            validator: this.validateName.createDelegate(this),
            xtype: 'textfield'
          },
          header: t('Footer Name'),
          id: 'name',
          menuDisabled: true,
          noHtmlEncode: true,
          resizable: false,
          sortable: false,
          width: 100,
          renderer(v, meta, record) {
            let context
            const isInbound = ProductDirection.isInbound(cfg.hosting)

            v = escape(v)

            if (record.get('global')) {
              context = isInbound ? t('All Domains') : t('All Mail Servers')
            } else {
              const count = record.get('contactMechCount') || 0
              const things = isInbound ? t('Domains') : t('Mail Servers')
              context = `${count} ${things}`
            }

            return `${v}<br/>${context}`
          }
        }
      ],
      deleteLabel: t('Delete'),
      deleteTooltip: t('Delete the currently selected footer')
    })
    this.callParent([cfg])
  },

  filter(record) {
    return record.get('policySetType') === 'SIGNATURE'
  },

  isNameDuplicate(value) {
    const trimmedValue = value.trim()

    const index = this.getStore().findBy(function (record) {
      return (
        record.get('name') === trimmedValue &&
        !this.getSelectionModel().isSelected(record)
      )
    }, this)

    return index !== -1
  },

  validateName(value) {
    if (!validators.isSetName(value)) {
      return t(
        'Footer names must be at least 2 characters long, and cannot start or end with whitespace'
      )
    } else if (value.length > 50) {
      return t('Footer names must be at most 50 characters long')
    } else if (this.isNameDuplicate(value)) {
      return t('Footer names must be unique')
    }

    return true
  },

  addItem() {
    this.editor.stopEditing(false)

    const Signature = this.store.record

    const record = new Signature({
      id: -1,
      name: '',
      global: true,
      policySetType: 'SIGNATURE'
    })

    this.store.add(record)

    const index = this.store.indexOf(record)
    this.getSelectionModel().selectRow(index)
    this.editor.startEditing(index, 0)
  },

  deleteItem() {
    if (this.getSelectionModel().hasSelection()) {
      const record = this.getSelectionModel().getSelected()
      const title = t('Delete Footer?')
      const text = t('Are you sure you want to delete {type} {operand}?', {
        type: t('Footer'),
        operand: escape(this.getSelectionModel().getSelected().get('name'))
      })
      Ext.MessageBox.confirm(title, text, function (result) {
        if (result === 'yes') {
          Ext.MessageBox.wait(t('Saving...'))
          return record.destroy({
            complete() {
              Ext.MessageBox.hide()
            }
          })
        }
      })
    }
  }
})

module.exports = SignatureGridPanel
