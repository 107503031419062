const Ext = require('ext')
const t = require('translate')
const CompanyListPanel = require('admin/view/CompanyListPanel')
const User = require('admin/core/User')

const ResellerListPanel = Ext.define(null, {
  extend: CompanyListPanel,
  tabCls: 'reseller-list-tab',
  cls: 'reseller-list',

  module() {
    return require('admin/business/ResellerResource')
  },

  createColumnModel() {
    let columns = []

    if (User.isTechnicalAdmin()) {
      columns.push({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }

    columns = columns.concat([
      {
        id: 'name',
        header: t('Reseller Name'),
        width: 200,
        sortable: true,
        dataIndex: 'displayName'
      },
      {
        id: 'externalId',
        header: t('Reseller No.'),
        width: 80,
        sortable: true,
        dataIndex: 'externalId'
      }
    ])

    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = ResellerListPanel
