const Record = require('admin/core/Record')

const EventQuarantineRecord = Record.define(null, {
  fields: [
    'from',
    'subject',
    'to',
    'message-id',
    'date',
    'text-body',
    'html-body',
    'attachments',
    'previewLink'
  ],

  getUrl() {
    return this.get('previewLink')
  }
})

module.exports = EventQuarantineRecord
