const Ext = require('ext')
const CompanyStore = require('admin/data/company/CompanyStore')
const DistributorRecord = require('admin/data/company/DistributorRecord')
const DistributorResource = require('admin/business/DistributorResource')

const DistributorStore = Ext.define(null, {
  extend: CompanyStore,

  resourceName: 'distributors',
  sortInfo: {
    field: 'displayName',
    direction: 'ASC'
  },
  record: DistributorRecord,

  module() {
    return DistributorResource
  }
})

module.exports = DistributorStore
