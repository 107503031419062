const t = require('translate')
const Ext = require('ext')
const User = require('admin/core/User')
const BillingDetailPanel = require('admin/view/BillingDetailPanel')
const ComboBox = require('admin/component/form/ComboBox')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const CurrencyResource = require('admin/business/CurrencyResource')

// the billing detail panel for customers is a bit different than
// the default billing panel for distributors and resellers.
const CustomerBillingDetailPanel = Ext.define(null, {
  extend: BillingDetailPanel,

  cls: 'customer-billing-detail-panel',

  getSaveOptions(record) {
    return {
      billing: true,
      url: record.getUrl(),
      method: 'PUT'
    }
  },

  getCustomerItems(cfg) {
    let ownCompany
    const companyType = User.getCompanyType()

    if (companyType) {
      const result = companyType.match(/\/(\d+)/)
      ownCompany = result && result[1] === cfg.record.get('id')
    }

    return [
      !ownCompany && // Bill To hidden from logged-in customer
        new ReadOnlyTextField({
          fieldLabel: t('Bill To'),
          locked: true,
          name: 'billToList'
        }),
      new ComboBox({
        fieldLabel: t('Direct Debit'),
        hiddenName: 'billing.directDebit',
        store: [
          ['false', t('No')],
          ['true', t('Yes')]
        ],
        editable: false,
        allowBlank: false,
        triggerAction: 'all'
      }),
      {
        fieldLabel: t('Purchase Order'),
        name: 'billing.purchaseOrder',
        maxLength: 50,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Invoice Frequency (months)'),
        name: 'billing.termValue',
        maxLength: 18,
        xtype: 'numberfield'
      },
      CurrencyResource.currencyChoiceExists() &&
        new ReadOnlyTextField({
          fieldLabel: t('Default Currency'),
          name: 'billing.defaultCurrency'
        })
    ]
  },

  getDetailItems(cfg) {
    const billingItems = [...this.getCustomerItems(cfg), ...this.getEmailItems()].filter(
      Boolean
    )

    return [
      {
        items: billingItems,
        title: t('Details'),
        xtype: 'fieldset'
      }
    ]
  }
})

module.exports = CustomerBillingDetailPanel
