const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ResellerAgreementListPanel = require('admin/view/ResellerAgreementListPanel')
const ResellerAgreementDetailPanel = require('admin/view/ResellerAgreementDetailPanel')
const ResellerAgreementNewPanel = require('admin/view/ResellerAgreementNewPanel')
const ResellerAgreementRecord = require('admin/data/ResellerAgreementRecord')

const ResellerAgreementResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.ResellerAgreement',
  translations: {
    title: t('Reseller Product'),
    plural: t('Reseller Products'),
    new: t('Add Product'),
    open: t('Open Product'),
    list: t('List Reseller Products')
  },
  name: t('Product Catalog'),
  code: 'AGREEMENT',
  resourceName: 'agreements',

  layout: {
    newResource: {
      width: 400,
      height: 'auto'
    },
    openResource: {
      width: 400,
      height: 'auto'
    }
  },

  listPanel: ResellerAgreementListPanel,
  detailPanel: ResellerAgreementDetailPanel,
  newPanel: ResellerAgreementNewPanel,

  operations: {
    create: true,
    save: true,
    open: true,
    remove: false,
    list: true
  },

  fixedPriceValidator(c) {
    // Wrap the price amount field validator to fire only when the field is enabled
    const priceAmountValidator = c.validator
    if (priceAmountValidator) {
      return (c.validator = function (value) {
        if (this.disabled) {
          return true
        } else if (value.trim() === '') {
          return t('Price amount is required for this Type')
        }
        return priceAmountValidator(value)
      })
    }
  },

  discountValidator(c) {
    // Wrap the percentage field validator to fire only when the field is enabled
    const percentageValidator = c.validator
    if (percentageValidator) {
      return (c.validator = function (value) {
        if (this.disabled) {
          return true
        } else if (value.trim() === '') {
          return t('Discount % is required for this Pricing Model')
        } else if (value < 0 || value > 50) {
          return t('Discount % must be between 0 and 50')
        }
        return percentageValidator(value)
      })
    }
  },

  record: ResellerAgreementRecord
})

module.exports = ResellerAgreementResource
