const Ext = require('ext')
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const SmartRule = require('smartrules/designer/SmartRule')
const ComboBox = require('admin/component/form/ComboBox')
let store = require('store').default
const { isSmartRuleNhiNumberPatternCapabilityEnabled } = require('system/capability/subs')

const patterns = [
  ['IP_ADDRESS_PATTERN', t('IP Address')],
  ['CREDIT_CARD_STRONG_PATTERN', t('Credit Card (Strong)')],
  ['CREDIT_CARD_STRONGER_PATTERN', t('Credit Card (Stronger)')]
]

// Since http://youtrack.smxemail.com/issue/SCL-982 we only include NHI pattern
// when the server returns this capability
if (isSmartRuleNhiNumberPatternCapabilityEnabled(store.getState())) {
  patterns.push(['NHI_NUMBER_PATTERN', t('NHI Number')])
}

const PATTERN_STORE = new Ext.data.ArrayStore({
  data: patterns,
  fields: ['code', 'text'],
  idIndex: 0
})

const PatternMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg) {
    cfg = cfg || {}
    cfg = Ext.applyIf(cfg, {
      flex: 1,
      layout: 'hbox',
      items: new ComboBox({
        allowBlank: false,
        displayField: 'text',
        editable: false,
        flex: 1,
        mode: 'local',
        store: PATTERN_STORE,
        forceSelection: true,
        triggerAction: 'all',
        ref: 'combo',
        valueField: 'code'
      })
    })
    this.callParent([cfg])
    this.setValue(cfg.value)
    this.combo.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
  },

  setValue(v) {
    const { combo } = this
    ;({ store } = combo)
    const index = store.find('code', v)
    const record = store.getAt(index)
    if (record) {
      combo.setValue(record.get('code'))
      return combo.fireEvent('select', combo, record)
    }
  },

  getValue() {
    return this.combo.getValue()
  }
})

module.exports = PatternMatcher
