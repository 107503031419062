import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getViewBox, getId } from 'sprites'

// useful export for when wrapping this into a React icon. inspired by:
// https://github.com/kisenka/svg-sprite-loader/tree/master/examples/custom-runtime-generator
class Icon extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  }

  render() {
    return (
      <svg viewBox={getViewBox(this.props.type)} style={this.props.style}>
        <use xlinkHref={'#' + getId(this.props.type)} />
      </svg>
    )
  }
}

export default Icon
