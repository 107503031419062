import { FormControl } from 'react-bootstrap'
import React from 'react'

export default function Control(props) {
  const {
    autoComplete,
    tabIndex,
    input,
    type,
    autoFocus,
    rows,
    componentClass,
    disabled,
    children,
    required,
    multiple
  } = props

  return (
    <FormControl
      componentClass={componentClass}
      type={type}
      rows={rows}
      multiple={multiple}
      disabled={disabled}
      required={required}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      tabIndex={tabIndex}
      id={input.name}
      {...input}
    >
      {children}
    </FormControl>
  )
}
