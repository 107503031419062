const Ext = require('ext')
const t = require('translate')
const ListTypes = require('smartrules/ListTypes')
const AddressableConditionClause = require('smartrules/designer/conditions/AddressableConditionClause')
const AddressWildcardMatcher = require('smartrules/designer/matchers/AddressWildcardMatcher')
const NullSenderMatcher = require('smartrules/designer/matchers/NullSenderMatcher')
const ListMatcher = require('smartrules/designer/matchers/ListMatcher')

const AddressedFrom = Ext.define(null, {
  extend: AddressableConditionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      steps: [
        {
          width: 120,
          defaultValue: cfg.value,
          conditions: [
            [
              {
                name: 'addressed-from',
                label: t('Sender'),

                matchers: [
                  {
                    matcher: 'is',
                    name: t('matches'),
                    clazz: AddressWildcardMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'addressed from {0}'
                  },
                  {
                    matcher: 'is-in',
                    name: t('is in list'),
                    clazz: ListMatcher,
                    matcherType: ListTypes.RULE_ADDRESS,
                    dsl: 'addressed from {0}'
                  },
                  {
                    matcher: 'is-nullsender',
                    name: t('is null sender'),
                    clazz: NullSenderMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'addressed from null sender'
                  },
                  {
                    matcher: 'is-not',
                    name: t('is not'),
                    clazz: AddressWildcardMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'not addressed from {0}'
                  },
                  {
                    matcher: 'is-not-in',
                    name: t('is not in list'),
                    clazz: ListMatcher,
                    matcherType: ListTypes.RULE_ADDRESS,
                    dsl: 'not addressed from {0}'
                  },
                  {
                    matcher: 'is-not-nullsender',
                    name: t('is not null sender'),
                    clazz: NullSenderMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'not addressed from null sender'
                  }
                ]
              }
            ]
          ]
        }
      ]
    })
    this.callParent([cfg])
  }
})

module.exports = AddressedFrom
