import React from 'react'

import { Checkbox } from 'react-bootstrap'

export default function CheckboxGroup(props) {
  const { disabled, input, items, inline } = props

  const { onChange, onBlur, onFocus } = input

  // redux-form doesn't initialise it, so we will have to do this ourselves
  // note: any undefined checkbox input value is always '' by default
  if (input.value === '') {
    input.value = []
  }

  // TODO keep order
  const checkboxes = Object.keys(items).reduce((data, value) => {
    const label = items[value]

    // special treatment to reformat value before passing it further
    // onto app db (redux)
    const handleChange = (event) => {
      let set

      if (Array.isArray(input.value)) {
        set = new Set(input.value)
      } else {
        set = new Set([input.value])
      }

      const value = event.target.value

      if (event.target.checked) {
        set.add(value)
      } else {
        set.delete(value)
      }

      const list = Array.from(set)

      onBlur(list)
      onChange(list)
    }

    const checked = input.value.indexOf(value) > -1

    data.push(
      <Checkbox
        key={label}
        name={`${input.name}[${value}]`}
        disabled={disabled}
        inline={inline}
        checked={checked}
        onChange={handleChange}
        onFocus={onFocus}
        value={value}
      >
        {label}
      </Checkbox>
    )

    return data
  }, [])

  return <div>{checkboxes}</div>
}
