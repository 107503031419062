import React, { Component } from 'react'

import Field from 'components/form/Field'
import Grid from 'components/Grid'
import PropTypes from 'prop-types'
import {
  administratorForm,
  disableForm,
  enablePeriodicQuarantineDigestsForAdmin
} from 'quarantine/notification/subs'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import t from 'translate'

import DurationField from './DurationField'
import StartingField from './StartingField'

export const FORM_NAME = 'administratorConfigurationForm'

class AdministratorConfiguration extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    notification: PropTypes.object,
    showAdminSection: PropTypes.bool
  }

  render() {
    return (
      <fieldset
        className='endUser'
        style={{ display: `${this.props.showAdminSection ? 'block' : 'none'}` }}
      >
        <legend>Administrator Notifications</legend>
        <Grid>
          <h5>Quarantine Digest</h5>
          <Grid>
            <Row>
              <Col xs={3}>
                <Field
                  disabled={this.props.disableForm}
                  name='enabled'
                  type='checkbox'
                  label={false}
                  info={
                    <p>
                      Enable / disable quarantine digests emails for Administrators.
                      Administrators will receive digests reporting on all inbound emails
                      which has been quarantined for the organisation.
                    </p>
                  }
                >
                  {t('Periodic quarantine digests')}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <StartingField
                  info={
                    <p>
                      The starting time when the digest emails will be sent to your
                      admins.
                    </p>
                  }
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <DurationField
                  info={<p>The minimum frequency between quarantine digests emails</p>}
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <Row>
                  <p>Quarantine digests include:</p>
                </Row>
                <Field
                  name='digestTypes'
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                  label={false}
                  type='checkbox'
                  items={{
                    GRAY: 'Graymail',
                    SPAM: 'Spam',
                    DLP: 'DLP (SmartRules)'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <p>Recipients:</p>
            </Row>
            <Row>
              <Col xs={3}>
                <Field
                  name='quarantineRoleRecipients'
                  type='checkbox'
                  label={false}
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                  info={
                    <p>
                      SMX Portal users with the ‘Quarantine’ role will receive digests for
                      your organisation
                    </p>
                  }
                >
                  {t('Quarantine role users')}
                </Field>
              </Col>
            </Row>
            <Row>
              <p>Additional addresses:</p>
            </Row>
            <Field
              multiple
              name='additionalRecipients'
              type='email'
              rows={4}
              componentClass='textarea'
              label={false}
              disabled={!this.props.enablePeriodicQuarantineDigests}
              help={t(
                'Define specific addresses to receive digest emails on each line for this organisation. Typically used when a third party manages your Quarantine. '
              )}
            />
          </Grid>
        </Grid>
      </fieldset>
    )
  }
}

const mapDbToProps = (db, { companyKey, notification }) => ({
  administratorForm: administratorForm(db, companyKey),
  enablePeriodicQuarantineDigests: enablePeriodicQuarantineDigestsForAdmin(
    db,
    companyKey
  ),
  disableForm: disableForm(db, companyKey),
  initialValues: notification
})

const AdministratorForm = reduxForm()(AdministratorConfiguration)

export default connect(mapDbToProps, null, null, { forwardRef: true })(AdministratorForm)
