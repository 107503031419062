import React, { Component } from 'react'

import Tabs from 'components/Tabs'
import PropTypes from 'prop-types'
import Help from 'quarantine/help/views/Tab'
import MailTargets from 'quarantine/mail_targets/views/Tab'
import Notification from 'quarantine/notification/views/Tab'
import QuarantineSets from 'quarantine/quarantine_sets/views/Tab'
import Search from 'quarantine/search/views/Tab'
import { Tab } from 'react-bootstrap'
import t from 'translate'

const PARENT_PADDINGS = 20

class QuarantineTabs extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    companyKey: PropTypes.string.isRequired,
    searchUri: PropTypes.string,
    quarantineSetsUri: PropTypes.string,
    notificationUri: PropTypes.string,
    showAdminSectionInNotification: PropTypes.bool
  }

  renderSearchTab() {
    return (
      <Tab
        eventKey={1}
        title={t('Search')}
        style={{
          height: this.props.height - PARENT_PADDINGS,
          overflowY: 'auto'
        }}
      >
        <Search
          width={this.props.width}
          height={this.props.height}
          companyKey={this.props.companyKey}
          store={this.props.store}
        />
      </Tab>
    )
  }

  renderMailTargets() {
    return (
      <Tab eventKey={2} title={t('Mail Targets')}>
        <MailTargets
          width={this.props.width}
          height={this.props.height}
          companyKey={this.props.companyKey}
          store={this.props.store}
        />
      </Tab>
    )
  }

  renderQuarantineSetsTab() {
    return (
      <Tab eventKey={3} title={t('Quarantine Policies')}>
        <QuarantineSets
          width={this.props.width}
          height={this.props.height}
          companyKey={this.props.companyKey}
          store={this.props.store}
        />
      </Tab>
    )
  }

  renderNotificationTab() {
    return (
      <Tab
        eventKey={4}
        title={t('Notifications')}
        style={{
          height: this.props.height - PARENT_PADDINGS,
          overflowY: 'auto'
        }}
      >
        <Notification
          width={this.props.width}
          height={this.props.height}
          companyKey={this.props.companyKey}
          store={this.props.store}
          showAdminSection={this.props.showAdminSectionInNotification}
        />
      </Tab>
    )
  }

  renderHelpTab() {
    return (
      <Tab
        eventKey={5}
        title={t('Help')}
        style={{
          height: this.props.height - PARENT_PADDINGS,
          overflowY: 'auto'
        }}
      >
        <Help />
      </Tab>
    )
  }

  getActiveKey = () => (this.props.searchUri ? 1 : 2)

  render() {
    return (
      <Tabs tabsId='quarantine-tabs' defaultActiveKey={this.getActiveKey()}>
        {this.props.searchUri && this.renderSearchTab()}
        {this.props.quarantineSetsUri && this.renderQuarantineSetsTab()}
        {this.renderMailTargets()}
        {this.props.notificationUri && this.renderNotificationTab()}
        {this.renderHelpTab()}
      </Tabs>
    )
  }
}

export default QuarantineTabs
