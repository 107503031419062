const Ext = require('ext')
const t = require('translate')

const WindowMgr = require('admin/desktop/WindowMgr').default
const FormPanel = require('admin/component/form/FormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const Alert = require('admin/util/Alert').default
const isSuccess = require('api/isSuccess').default

const TRANSLATIONS = {
  CUSTOMER: t('Customer'),
  RESELLER: t('Reseller'),
  DISTRIBUTOR: t('Distributor')
}

const ReassignToolClarification = Ext.define(null, {
  extend: Ext.Window,

  modal: true,
  title: t('Billing clarification'),
  width: 400,
  minWidth: 400,
  height: 210,
  minHeight: 220,
  resizable: false,

  initComponent() {
    // Needed so window appears at correct z index
    this.manager = WindowMgr.windowGroup

    this.from = this.initialConfig.from
    this.fromDate = this.initialConfig.fromDate
    this.to = this.initialConfig.to
    this.success = this.initialConfig.success

    // todo also a short text label that describes the problem condition would be good in the clarification dialog
    this.items = this.form = new FormPanel({
      items: [
        new Ext.form.Label({
          text: t('Multiple parties available to bill to. Please choose one.'),
          cls: 'help-block-large'
        }),
        new ReadOnlyTextField({
          fieldLabel: t('Going to reassign'),
          value: this.from.get('description')
        }),
        new ReadOnlyTextField({
          fieldLabel: t('into {toRoleName}', { toRoleName: this.getRoleName() }),
          value: this.to.get('description')
        }),
        (this.billTo = new ComboBox({
          allowBlank: false,
          editable: false,
          emptyText: t('Please select one'),
          fieldLabel: t('Bill to'),
          lazyRender: true,
          triggerAction: 'all',
          mode: 'local',
          store: new Ext.data.ArrayStore({
            id: 0,
            fields: ['resourceId', 'description'],
            data: [
              [this.from.get('resourceId'), this.from.get('description')],
              [this.to.get('resourceId'), this.to.get('description')]
            ]
          }),
          valueField: 'resourceId',
          displayField: 'description',
          listeners: {
            select: () => this.confirmButton.setDisabled(false)
          }
        }))
      ],
      buttons: [
        (this.confirmButton = new Ext.Button({
          text: t('Confirm'),
          disabled: true,
          handler: () => this.confirm(this.billTo.getValue())
        })),
        new Ext.Button({
          text: t('Cancel'),
          iconCls: 'icon-cancel',
          handler: () => this.close()
        })
      ]
    })

    this.callParent()
  },

  getRoleName() {
    return TRANSLATIONS[this.to.get('type')]
  },

  confirm(billToId) {
    this.disable()

    return Ext.Ajax.request({
      method: 'PUT',
      url: `/api${this.to.get('url')}/${this.from.get('type').toLowerCase()}s`,
      mask: this.disable.bind(this),
      unmask: this.enable.bind(this),
      params: {
        id: this.from.get('resourceId'),
        fromDate: this.fromDate,
        billTo: billToId
      }, // this is the party id
      success: () => {
        Alert.info(
          t('Success'),
          t(
            'Assignment of {fromDescription} to {toRole} {toDescription} was successful.',
            {
              fromDescription: this.from.get('description'),
              toRole: this.getRoleName(),
              toDescription: this.to.get('description')
            }
          )
        )
        this.close()
        // that's a callback from ReassignToolPanel.coffee
        return this.success()
      },
      failure: (response) => {
        this.enable()
        if (isSuccess(response)) {
          return
        }
        return Alert.displayResponseError(response)
      }
    })
  }
})

module.exports = ReassignToolClarification
