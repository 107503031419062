const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')

const CompanyStore = Ext.define(null, {
  extend: ResourceStore,

  statics: {
    extractParentName(relationships, code) {
      const rel =
        relationships.find &&
        relationships.find(
          (relationship) => relationship?.partyRelationshipType.code === code
        )

      if (!rel || !rel.fromRole || !rel.fromRole.party) return ''
      return rel.fromRole.party.displayName
    }
  }
})

module.exports = CompanyStore
