const Ext = require('ext')
const t = require('translate')

const DropZone = Ext.define(null, {
  extend: Ext.Panel,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      border: false,
      flex: 1,
      cls: 'smartrules-target-zone',
      html: t('Drag here'),
      padding: '6px 6px 6px 41px'
    })

    this.callParent([cfg])
  },

  getClause() {
    // leave the require call here to avoid circular dependency
    return this.findParentBy((n) => n instanceof require('smartrules/designer/Clause'))
  },

  getParentGroup() {
    // leave the require call here to avoid circular dependency
    return this.findParentBy((n) => n instanceof require('smartrules/designer/Group'))
  }
})

module.exports = DropZone
