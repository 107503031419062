const Ext = require('ext')
const t = require('translate')
const WindowMgr = require('admin/desktop/WindowMgr').default

const WINDOW_ID = 'message-log'
const MESSAGES = []
let MESSAGE_LOG_WINDOW = null

const buildWindow = () =>
  WindowMgr.create({
    autoScroll: true,
    id: WINDOW_ID,
    title: t('Message Log'),
    width: 500,
    height: 400,
    constrain: true,
    url: WINDOW_ID,
    items: MESSAGES.map((message) => new Ext.Panel({ html: message })),
    // that OR combination is needed to cover single customer logins
    // that come without the left panel for search
    alignToElement: Ext.get('next-desktop-left') || Ext.get('next-desktop-center')
  })

const Messages = {
  show() {
    if (!WindowMgr.select(WINDOW_ID)) {
      MESSAGE_LOG_WINDOW = buildWindow()
    }

    return MESSAGE_LOG_WINDOW
  },

  add(html) {
    MESSAGES.push(html)

    if (MESSAGE_LOG_WINDOW != null) {
      MESSAGE_LOG_WINDOW.add(new Ext.Panel({ html }))
    }

    MESSAGE_LOG_WINDOW && MESSAGE_LOG_WINDOW.doLayout()
  },

  getUrl() {
    return WINDOW_ID
  }
}

module.exports = Messages
