import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import { Overlay, Tooltip } from 'react-bootstrap'

class InfoTooltip extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    info: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
  }

  constructor(props) {
    super(props)

    this.targetRef = React.createRef()

    this.state = {
      show: false
    }
  }

  handleMouseEnter = () => this.setState({ show: true })

  handleMouseLeave = () => this.setState({ show: false })

  renderTooltip() {
    return <Tooltip id={this.props.name + '-tooltip'}>{this.props.info}</Tooltip>
  }

  render() {
    const tooltip = this.renderTooltip()

    return (
      <span
        ref={this.targetRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className='info-tooltip-icon'
      >
        <Overlay
          show={this.state.show}
          placement='bottom'
          container={this}
          // eslint-disable-next-line react/no-find-dom-node
          target={() => ReactDOM.findDOMNode(this.targetRef.current)}
        >
          {tooltip}
        </Overlay>
      </span>
    )
  }
}

export default InfoTooltip
