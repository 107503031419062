const backendUnavailable = (status) => {
  let unavailable = false

  switch (status) {
    case undefined:
    case -1:
    case 0:
    case 502:
    case 503:
    case 504:
      unavailable = true
      break
  }

  return unavailable
}

export default backendUnavailable
