const Ext = require('ext')
const Q = require('q')
const requestPromise = require('admin/util/Promise').default
const getJsonAccessor = require('admin/util/getJsonAccessor').default
const logger = require('system/logger').default
const isObject = require('is-plain-obj')
const LinkHeader = require('http-link-header')
const Sentry = require('system/sentry').default

function isFunction(func) {
  if (func && typeof func === 'function') {
    return true
  }
  return false
}
// Mixing in Observable to base record allows use of callParent when
// overriding observable methods.
Ext.applyIf(Ext.data.Record.prototype, Ext.util.Observable.prototype)

const Record = Ext.define(null, {
  extend: Ext.data.Record,

  statics: {
    // Define a Record constructor in the same format as Ext.define.
    // See Ext.data.Record.create for original Ext implementation.
    // We can't use Ext.define as-is because a record's fields property needs to be a
    // MixedCollection. It's easier to define records with an array literal and convert it
    // to a MixedCollection here.
    define: function (className, data) {
      const fields = data.fields
      delete data.fields

      if (!fields) throw Error('Fields are required when defining a Record.')

      data.extend = data.extend || Record

      // Extend record using newer define features instead of extend.
      const f = Ext.define(className, data)
      const p = f.prototype

      p.fields = new Ext.util.MixedCollection(false, (field) => field.name)

      let i
      const len = fields.length

      for (i = 0; i < len; i++) {
        p.fields.add(new Ext.data.Field(fields[i]))
      }

      f.getField = (name) => p.fields.get(name)

      return f
    }
  },

  idAttribute: 'id',

  constructor: function (data, options) {
    let id

    if (!isObject(options)) {
      id = options
      options = {}
    } else {
      id = options.id
    }

    if (options.parse) {
      data = this.parse(data)
    }

    if (!id) {
      id = data ? data[this.idAttribute] : undefined
    }

    Ext.data.Record.bind(this)(data, id)

    if (options.url) {
      this.url = options.url
    }

    // Use the same events as Backbone: http://backbonejs.org/#Events-catalog
    // Model = record, collection = store.
    this.addEvents(
      'add',
      'remove',
      'change',
      'destroy',
      'request',
      'sync',
      'error',
      'invalid',
      'all'
    )

    if (isFunction(this.module)) {
      this.module = this.module()
    }
  },

  // Override to add an 'all' event, like Backbone.
  fireEvent: function () {
    const args = arguments.length >= 1 ? [].slice.call(arguments, 0) : []

    this.callParent(arguments)
    this.callParent(['all'].concat(args))

    // Bubble events to store (if it exists).
    // Backbone collections do this by listening to it's model's 'all' event.
    // In Ext, a record should only belong to one store so it is simpler having it here.
    if (this.store) {
      this.store.fireEvent.apply(this.store, arguments)
    }
  },

  parseLinks: function (response) {
    const header = response.getResponseHeader('Link')

    if (!header) return

    this.links = LinkHeader.parse(header)
  },

  parseLinkTemplates: function (response) {
    const header = response.getResponseHeader('link-template')

    if (!header) return

    this.linkTemplates = LinkHeader.parse(header)
  },

  getLinkByRel: function (rel) {
    if (!this.links) return

    return this.links.rel(rel)[0]
  },

  getUriByRel: function (rel) {
    const header = this.getLinkByRel(rel)

    if (!header) return

    return header.uri
  },

  getUriTemplateByRel: function (rel) {
    if (!this.linkTemplates) return

    const linkTemplate = this.linkTemplates.rel(rel)[0]

    if (!linkTemplate) return

    return linkTemplate.uri
  },

  fetch: function (options = {}) {
    const completeCallback = options.complete
    const url = options.url || this.getUrl()

    return this.sync({
      method: 'GET',
      url,
      params: this.get('params'),
      mask: options.mask,
      unmask: options.unmask,
      success: (response) => {
        try {
          this.parseLinks(response)
          this.parseLinkTemplates(response)

          this.set(this.parse(JSON.parse(response.responseText)))
          this.commit(options.silent)

          if (isFunction(options.success)) {
            options.success(this, response, options)
          }

          this.synced = true
          this.fireEvent('sync', this, response, options)
        } catch (exc) {
          logger.warn(exc)
          Sentry.captureException(exc)
        }
      },
      failure: async (response) => {
        const err = new Error('Failed to GET ' + url)
        err.response = response

        isFunction(options.error) && options.error(this, response, options, err)

        await this.fireEvent('error', this, response, options)

        !options.error && logger.error(err)
      },
      callback: function (o, success, response) {
        if (isFunction(completeCallback)) {
          completeCallback(response)
        }
      }
    })
  },

  save: function (attributes, options = {}) {
    attributes && this.set(attributes)

    const completeCallback = options.complete
    const method = options.method || (this.isNew() ? 'POST' : 'PUT')
    const url = options.url || this.getUrl()
    const params = this.getParams(options)

    return this.sync({
      method,
      url,
      params,
      enableForm: options.enableForm,
      success: async (response) => {
        const location = response.getResponseHeader('Location')

        if (location) {
          this.url = location.trim()
          this.id = parseInt(this.url.split('/').pop(), 10)
          this.set({ id: this.id })
        }

        this.commit(options.silent)

        if (isFunction(options.success)) {
          options.success(this, response, options)
        }

        await this.fireEvent('sync', this, response, options)
      },
      failure: async (response) => {
        this.reject()

        const err = new Error(
          `Failed to ${method} to ${url} due to status "${response.status} ${response.statusText}"`
        )
        err.response = response

        isFunction(options.error) && options.error(this, response, options, err)

        await this.fireEvent('error', this, response, options, err)
      },
      callback: (o, success, response) => {
        if (isFunction(completeCallback)) {
          completeCallback(response)
        }
      }
    })
  },

  destroy: function (options = {}) {
    const completeCallback = options.complete

    const onDestroySuccess = (response) => {
      const successCallback = options.success

      this.deleted = true
      this.fireEvent('destroy', this, this.store, options)

      isFunction(successCallback) && successCallback(this, response, options)
    }

    // Don't actually do a request if it doesn't exist on the server.
    if (this.isNew()) {
      onDestroySuccess()

      isFunction(completeCallback) && completeCallback()

      return
    }

    const url = options.url || this.getUrl()

    return this.sync({
      method: 'DELETE',
      url,
      success: (response) => {
        onDestroySuccess(response, options)
        this.fireEvent('sync', this, response, options)
      },
      failure: (response) => {
        isFunction(options.error) && options.error(this, response, options)

        this.fireEvent('error', this, response, options)

        if (!options.error) {
          const err = new Error('Failed to DELETE ' + url)
          err.response = response

          logger.error(err)
        }
      },
      callback: function () {
        if (isFunction(completeCallback)) {
          completeCallback()
        }
      }
    })
  },

  sync: (options) => requestPromise(options),

  parse: function (data) {
    this.json = data

    if (data?.envelope) {
      this.url = data.url
      data = data.data
    }

    const processedData = {}

    this.fields.getRange().forEach((field) => {
      const mapping = field.mapping || field.name
      let value = getJsonAccessor(mapping)(data)
      value = value || field.defaultValue

      // special treatment for empty url values
      // happens only for subscriptions or reports because of
      // their views overriding their default behaviour, see:
      // https://youtrack.smxemail.com/youtrack/issue/SCL-3623
      if (this.data && field.name === 'url' && value === '') {
        value = this.data.url
      }

      processedData[field.name] = field.convert(value, data)
    }, this)

    return processedData
  },

  set: function (attributes) {
    if (!isObject(attributes)) return this.callParent(arguments)

    !this.editing && this.beginEdit()

    for (const [key, value] of Object.entries(attributes)) {
      this.set(key, value)
    }

    !this.editing && this.endEdit()
  },

  commit: function (silent) {
    if (this.dirty && !silent) {
      this.fireEvent('change', this)
    }

    this.callParent(arguments)
  },

  // -1 is used as a placeholder when adding to stores before saving.
  isNew: function () {
    return !this.get('id') || this.get('id') === -1
  },

  getLinkByRelDeferred: function (rel) {
    const deferred = Q.defer()
    const header = this.getUriByRel(rel)

    if (header) {
      deferred.resolve(header)
    } else {
      deferred.resolve(
        requestPromise({
          method: 'HEAD',
          url: this.getUrl()
        })
          .catch(() => {
            throw new Error("Couldn't do HEAD request.")
          })
          .then((response) => {
            this.parseLinks(response)
            return this.getUriByRel(rel)
          })
      )
    }

    return deferred.promise
  },

  requestRel: function (rel, options = {}) {
    return this.getLinkByRelDeferred(rel).then(function (link) {
      if (!link) throw new Error('Rel [' + rel + '] not found.')

      return requestPromise(Ext.apply(options, { url: link }))
    })
  },

  getName: function () {
    return this.getId()
  },

  getTitle: function () {
    const parentRecord = this.getParentRecord()
    const name = this.getName()

    if (parentRecord?.isCompany()) {
      return parentRecord.getTitle() + ': ' + name
    }

    return name
  },

  getDescription: function () {
    return this.getName()
  },

  getUrl: function () {
    if (this.get('url')) return this.get('url')
    if (this.url) return this.url
    if (this.defaultURI) return this.defaultURI
    if (!this.store) return

    const id = this.getId()

    if (this.isNew()) return this.store.url

    const module = this.store.module

    if (module?.resourceName?.indexOf('/') === 0) {
      return '/api' + this.store.module.resourceName + '/' + id
    }

    if (this.store.url) return this.store.url + '/' + id
  },

  getParentRecord: function () {
    return this.store?.parentRecord
  },

  isCompany: function () {
    return (
      this.isVendor() || this.isDistributor() || this.isReseller() || this.isCustomer()
    )
  },

  isVendor: function () {
    return false
  },

  isDistributor: function () {
    return false
  },

  isReseller: function () {
    return false
  },

  isCustomer: function () {
    return false
  },

  getDefaultUri: function () {
    if (this.defaultURI) return this.defaultURI

    return this.get('defaultURI')
  },

  getId: function () {
    return this.get('id')
  },

  getCompanyKey: function () {
    let companyKey = this.getDefaultUri()

    if (!companyKey) {
      companyKey = '/' + this.getType().toLowerCase() + '/' + this.getId()
    }

    return companyKey
  },

  canRemove: function () {
    return this.module.canRemove()
  },

  canOpen: function () {
    return this.module.canOpen()
  },

  open: function (options) {
    this.fetch({
      success: () => {
        this.module.open(this, options)
      }
    })
  },

  canSave: function () {
    return this.module.canSave()
  },

  getType: function () {
    return this.get('type')
  },

  getParams: function () {
    return {}
  },

  isPersistentOnSave: function () {
    return this.explorable
  },

  getResourceStore: function (resourceCode) {
    switch (resourceCode) {
      case 'AGREEMENT':
        return this.getAgreementStore()
      case 'USER':
        return this.getUserStore()
      case 'CUSTOMER':
        return this.getCustomerStore()
      case 'RESELLER':
        return this.getResellerStore()
      case 'DISTRIBUTOR':
        return this.getDistributorStore()
      case 'INVOICE_RUN':
        return this.getInvoiceRunStore()
      case 'INVOICE':
        return this.getInvoiceStore()
      case 'NOTE':
        return this.getNoteStore()
      case 'SUBSCRIPTION':
        return this.getSubscriptionStore()
      case 'REPORT':
        return this.getReportStore()
      default:
        logger.warn(`Unknown code for resource store: ${resourceCode}`)
    }
  }
})

module.exports = Record
