const t = require('translate')
const Ext = require('ext')
const ComboBox = require('admin/component/form/ComboBox')
const SmartRule = require('smartrules/designer/SmartRule')

const DEFAULT_POST_LABEL_WIDTH = 20

// note: do not add spaces after html attributes (extjs template parser is not that tolerant)
/* eslint-disable no-multi-str */
const COMBO_ITEM_TEMPLATE = new Ext.XTemplate(
  '<tpl for="."> \
<tpl if="values.separator"><hr/></tpl> \
<div class="x-combo-list-item">{values.label}</div> \
</tpl>'
)

const ConditionCombo = Ext.define(null, {
  extend: Ext.Panel,

  constructor(cfg = {}) {
    let layout

    const count = cfg.store != null ? cfg.store.getCount() : undefined
    const items = []

    if (count === 1) {
      const record = cfg.store.getAt(0)
      cfg.html = record.get('label')

      // no need to use the configured width, as extjs will calculate it automatically
      delete cfg.width
    } else {
      const allowBlank = cfg.allowBlank || false
      const emptyText = cfg.emptyText || t('Please select')

      this.combo = new ComboBox(
        Ext.applyIf(cfg, {
          emptyText,
          allowBlank,
          editable: false,
          lazyInit: false,
          displayField: 'label',
          mode: 'local',
          forceSelection: true,
          ref: '../../conditionCombo',
          triggerAction: 'all',
          valueField: 'name',
          width: cfg.width,
          tpl: COMBO_ITEM_TEMPLATE,
          validator: cfg.validator
        })
      )

      this.combo.on(
        'select',
        function (combo, record) {
          return this.fireEvent('select', this, record)
        },
        this
      )

      this.combo.on(
        'change',
        function () {
          const parent = this.findParentByType(SmartRule)
          parent?.fireEvent('dirty')
        },
        this
      )

      items.push(this.combo)
    }

    if (cfg.postLabel) {
      layout = 'hbox'
      const labelWidth = cfg.postLabelWidth || DEFAULT_POST_LABEL_WIDTH
      items.push({
        xtype: 'label',
        margins: '4 4',
        width: labelWidth,
        text: cfg.postLabel,
        cls: 'postLabel'
      })

      // adjust width for parent panel
      cfg.width += labelWidth
    }

    cfg = Ext.applyIf(cfg, {
      border: true,
      cls: 'smartrules-condition-combo',
      items,
      layout
    })

    // remember it for hideRest()
    this.hideRestWhenBlank = cfg.hideRestWhenBlank || false

    this.callParent([cfg])
  },

  setStore(newStore) {
    return this.combo.bindStore(newStore)
  },

  getIndex() {
    if (this.combo) {
      return this.store.find(this.combo.valueField, this.getValue())
    }
  },

  isHidden() {
    return this.hidden
  },

  hasValue() {
    return this.getValue().length > 0
  },

  hideRest() {
    const record = this.getRecord()
    let hideRest = record && record.get('hideRest')

    if (!hideRest && this.hideRestWhenBlank) {
      hideRest = !this.hasValue()
    }

    return hideRest
  },

  getRecord() {
    return this.store.getAt(this.getIndex())
  },

  getValue() {
    return this.combo.getValue()
  },

  cover() {
    this.hide()
    return this.setDisabled(true)
  },

  unCover() {
    this.show()
    return this.setDisabled(false)
  },

  toDsl() {
    let dslFormat
    const dslValue = this.getValue()
    const record = this.getRecord()

    if (record) {
      dslFormat = record.get('dsl')
    }

    if (dslFormat) {
      return String.format(dslFormat, dslValue)
    }
    return dslValue
  }
})

module.exports = ConditionCombo
