import * as hal from 'hal'

import {
  TENANCY_SELECT,
  TENANCY_SET_LIST,
  TENANCY_SET_URI,
  TENANCY_START_CONFIRMING_DELETION,
  TENANCY_START_CREATING,
  TENANCY_START_LOADING,
  TENANCY_START_RENAMING,
  TENANCY_STOP_CONFIRMING_DELETION,
  TENANCY_STOP_CREATING,
  TENANCY_STOP_LOADING,
  TENANCY_STOP_RENAMING,
  TENANCY_UNLOAD
} from 'integration/tenancy/actions'

import { createNextState } from '@reduxjs/toolkit'
import getIn from 'lodash/get'
import initialState from 'integration/tenancy/db'
import setIn from 'lodash/set'

const findIndex = (tenancies, id) => {
  if (!tenancies) return
  return tenancies.findIndex((tenancy) => tenancy?.id === id)
}

const getBaseKeyPath = (companyKey) => ['companies', companyKey]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getListKeyPath = (companyKey) => getKeyPath(companyKey, 'list')
const getList = (tenancyDb, companyKey) => getIn(tenancyDb, getListKeyPath(companyKey))

const reducers = {
  [TENANCY_START_CREATING]: (tenancyDb, { companyKey }) => {
    return setIn(tenancyDb, getKeyPath(companyKey, 'creating'), true)
  },

  [TENANCY_STOP_CREATING]: (tenancyDb, { companyKey }) => {
    return setIn(tenancyDb, getKeyPath(companyKey, 'creating'), false)
  },

  [TENANCY_START_RENAMING]: (tenancyDb, { companyKey, tenancy }) => {
    return setIn(tenancyDb, getKeyPath(companyKey, 'renameTarget'), tenancy)
  },

  [TENANCY_STOP_RENAMING]: (tenancyDb, { companyKey }) => {
    return setIn(tenancyDb, getKeyPath(companyKey, 'renameTarget'), null)
  },

  [TENANCY_SELECT]: (tenancyDb, action) => {
    const { rows, companyKey, selected, force } = action

    const results = getIn(tenancyDb, getListKeyPath(companyKey))

    return setIn(
      tenancyDb,
      getListKeyPath(action.companyKey),
      results.map((result) => {
        const resultSelfLink = hal.self(result)

        const found = rows.find((row) => {
          const self = row && typeof row.valueOf() === 'string' ? row : hal.self(row)
          return self === resultSelfLink
        })

        if (force) {
          return Object.assign(result, { selected: found && selected })
        }

        if (!found) return result

        return Object.assign(result, { selected })
      })
    )
  },

  [TENANCY_SET_LIST]: (tenancyDb, action) => {
    const companyKey = action.companyKey
    const oldList = getList(tenancyDb, companyKey)

    // remember current selections
    const selectedTenanciesIndices =
      oldList &&
      oldList.reduce((selectedTenanciesIndices, selectedTenancy) => {
        if (selectedTenancy.selected) {
          selectedTenanciesIndices.push(selectedTenancy.id)
        }

        return selectedTenanciesIndices
      }, [])

    tenancyDb = setIn(tenancyDb, getListKeyPath(companyKey), action.newList)

    if (selectedTenanciesIndices) {
      selectedTenanciesIndices.forEach((selectedTenancyId) => {
        const selectedTenancyIndex = findIndex(
          getList(tenancyDb, action.companyKey),
          selectedTenancyId
        )

        if (selectedTenancyIndex >= 0) {
          const keyPath = getListKeyPath(companyKey).concat([
            selectedTenancyIndex,
            'selected'
          ])
          tenancyDb = setIn(tenancyDb, keyPath, true)
        }
      })
    }

    return tenancyDb
  },

  [TENANCY_START_LOADING]: (tenancyDb, action) => {
    return setIn(tenancyDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [TENANCY_STOP_LOADING]: (tenancyDb, action) => {
    return setIn(tenancyDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [TENANCY_SET_URI]: (tenancyDb, action) => {
    return setIn(tenancyDb, getKeyPath(action.companyKey, 'uri'), action.uri)
  },

  [TENANCY_UNLOAD]: (tenancyDb, { companyKey }) => {
    delete tenancyDb.companies[companyKey]
    return tenancyDb
  },

  [TENANCY_START_CONFIRMING_DELETION]: (tenancyDb, action) => {
    return setIn(tenancyDb, getKeyPath(action.companyKey, 'confirmingDeletion'), true)
  },

  [TENANCY_STOP_CONFIRMING_DELETION]: (tenancyDb, action) => {
    return setIn(tenancyDb, getKeyPath(action.companyKey, 'confirmingDeletion'), false)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
