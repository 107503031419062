const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const Format = require('admin/util/Format')
const EventDetailPanel = require('admin/view/EventDetailPanel')
const EventSearchPanel = require('admin/view/EventSearchPanel')

const EventResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Event',
  translations: {
    title: t('Mail Search'),
    plural: t('Mail Search'),
    open: t('Mail Search'),
    list: t('Mail Search')
  },
  code: 'CUSTOMER', // if you have permissions to Customer you have permissions to Events
  name: t('Mail Search'),
  resourceId: 'EVENTS_SEARCH',
  resourceName: 'events/search',

  messageSizeStore: [
    [0, Format.bytes(0)],
    [10e3, Format.bytes(10e3)],
    [50e3, Format.bytes(50e3)],
    [100e3, Format.bytes(100e3)],
    [250e3, Format.bytes(250e3)],
    [1e6, Format.bytes(1e6)]
  ],

  MAIL_TYPES: [
    ['', t('All')],
    ['CLEAN', t('Clean')],
    ['SPAM', t('Spam')],
    ['SPF_SPAM', t('SPF')],
    ['DMARC_SPAM', t('DMARC')],
    ['THREAT', t('Threat')],
    ['POLICY', t('All Policy')],
    ['ALLOW_POLICY', t('Whitelist')],
    ['DENY_POLICY', t('Blacklist')]
  ],

  layout: {
    newResource: {
      width: 395,
      height: 'auto'
    },
    openResource: {
      width: 620,
      height: 'auto',
      resizable: true,
      resizeHandles: 'e w'
    }
  },

  operations: {
    create: false,
    open: true,
    remove: false,
    list: true,
    save: false
  },

  detailPanel: EventDetailPanel,
  listPanel: EventSearchPanel
})

module.exports = EventResource
