const Ext = require('ext')
const Matcher = require('smartrules/designer/Matcher')
const AutoSizingTextArea = require('admin/component/form/AutoSizingTextArea')

const TextMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg = {}) {
    const textarea = new AutoSizingTextArea({
      allowBlank: false,
      flex: 2,
      name: 'value',
      ref: 'textarea',
      value: cfg.value,
      autoWidth: true
    })

    cfg = Ext.applyIf(cfg, {
      cls: 'text-matcher',
      items: textarea
    })

    this.callParent([cfg])

    this.on(
      'afterlayout',
      function () {
        textarea.on(
          'autosize',
          function () {
            this.fireEvent('heightfix', this)

            const hasTextMatcher =
              typeof this.ownerCt.hasTextMatcher === 'function' &&
              this.ownerCt.hasTextMatcher()

            if (hasTextMatcher) {
              this.ownerCt.fireEvent('heightfix', this.ownerCt)
            }
          },
          this
        )
      },

      this,
      { single: true }
    )
  },

  getValue(options = {}) {
    const raw = options.raw || false
    const tempValue = this.textarea.getValue()

    let v = tempValue?.replace(/(["\\])/g, '\\$1') || ''

    if (!raw) {
      v = `"${v}"`
    }

    return v
  }
})

module.exports = TextMatcher
