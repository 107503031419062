import CustomerResource from 'admin/business/CustomerResource'
import User from 'admin/core/User'
import AbstractCompanyTabPanel from 'admin/view/AbstractCompanyTabPanel'
import CustomerBillingDetailPanel from 'admin/view/CustomerBillingDetailPanel'
import CustomerDetailPanel from 'admin/view/CustomerDetailPanel'
import CustomerStatusPanel from 'admin/view/CustomerStatusPanel'
import DashboardPanel from 'admin/view/DashboardPanel'
import Ext from 'ext'
import t from 'translate'

const CustomerTabPanel = Ext.define(null, {
  extend: AbstractCompanyTabPanel,

  module: CustomerResource,
  title: t('Customer'),
  tabCls: 'customer-tab',
  cls: 'customer-panel',

  showDashboardTab() {
    const directions = this.module.getDirections(this.record.get('products'))
    if (!directions) return false
    return Boolean(directions.inbound || directions.outbound)
  },

  buildDashboardTab() {
    return new DashboardPanel({ record: this.record })
  },

  buildDetailPanel() {
    return new CustomerDetailPanel({ record: this.record })
  },

  buildBillingDetailPanel() {
    return new CustomerBillingDetailPanel({ record: this.record })
  },

  showNotesTab() {
    // todo: improve this with http://youtrack.smxemail.com/issue/SCL-952
    return !User.isUserOfCustomer(this.record.get('id'))
  },

  buildStatusTab() {
    return new CustomerStatusPanel({ record: this.record })
  }
})

export default CustomerTabPanel
