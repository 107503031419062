const Ext = require('ext')
const t = require('translate')
const ConditionClause = require('smartrules/designer/ConditionClause')

const SuspectedWhaling = Ext.define(null, {
  extend: ConditionClause,

  constructor() {
    const config = {
      steps: [
        {
          name: 'suspected whaling email', // this is the dsl
          label: t('Suspected Whaling Email')
        }
      ]
    }

    this.callParent([config])
  }
})

module.exports = SuspectedWhaling
