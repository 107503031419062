const t = require('translate')
const Ext = require('ext')
const validators = require('validators').default
const ListTypes = require('smartrules/ListTypes')
const ConditionClause = require('smartrules/designer/ConditionClause')
const RegexpMatcher = require('smartrules/designer/matchers/RegexpMatcher')
const TextMatcher = require('smartrules/designer/matchers/TextMatcher')
const ListNameMatcher = require('smartrules/designer/matchers/ListNameMatcher')
const EditableCustomList = require('smartrules/designer/matchers/EditableCustomList')
const NoMatcher = require('smartrules/designer/matchers/NoMatcher')

const {
  isEnvelope,
  ENVELOPE_SENDER_CONDITION_VALUE,
  ENVELOPE_RECIPIENT_CONDITION_VALUE
} = require('smartrules/designer/util/isEnvelope')

const NAME_MATCHING_REGEX_LABEL = t('name matching regex')
const MATCHING_NAME_IN_LIST_LABEL = t('matching name in list')
const MATCHING_NAME_IN_SYSTEM_LIST_LABEL = t('matching name in system list')

const ENVELOPE_RECIPIENTS_LABEL = t('Envelope Recipients')
const ENVELOPE_SENDER_LABEL = t('Envelope Sender')

// values can be nested pretty deep :/
const extractValue = function (valueHolder) {
  // special check for envelope-sender/-recipient where type is used instead of value
  let value
  const tempValue = valueHolder?.value
  if (
    valueHolder?.type &&
    isEnvelope(valueHolder && valueHolder.type) &&
    !valueHolder.value
  ) {
    value = valueHolder.type
  } else if (tempValue?.value) {
    value = tempValue.value
  } else if (tempValue) {
    value = tempValue
  } else {
    value = valueHolder
  }

  return value
}

const extractValueType = (valueHolder) => {
  return valueHolder?.value?.type || valueHolder?.type || valueHolder?.condition
}

const extractRightMatcher = function (cfg) {
  let rightValueType
  if (cfg.next) {
    rightValueType = extractValueType(cfg.next)
  }

  // special treatment for envelope-sender/-recipients :/
  if (rightValueType && isEnvelope(rightValueType)) {
    rightValueType = 'headers' // just stick to headers then
  }

  if (cfg.operator) {
    if (rightValueType) {
      return `${cfg.operator}-${rightValueType}`
    }
    return cfg.operator
  }
}

// since scl-1358 we require this special validation procedure
// for all kind of values which are possible for the header name matcher
// @returns error message string when not valid
const headerNameValidator = function (v) {
  let values
  switch (v) {
    case NAME_MATCHING_REGEX_LABEL:
      return true
    case MATCHING_NAME_IN_LIST_LABEL:
      return true
    case MATCHING_NAME_IN_SYSTEM_LIST_LABEL:
      return true
    case ENVELOPE_RECIPIENTS_LABEL:
      return true
    case ENVELOPE_SENDER_LABEL:
      return true
    default:
      values = v.split('or').map((value) => value.trim())

      return values.reduce(function (valid, value) {
        const errorMessage = validators.headerName.withMsg(
          t(
            'Header names can only contain letters, numbers and special characters except colon.'
          )
        )(value)
        return valid && errorMessage
      }, true)
  }
}

const scopeValidator = function (v) {
  if (!v || v.length === 0) {
    return t('Please select a scope')
  }
}

/*

CONSTANTS

*/

// the list of common headers comes as a sub capability from backend,
// see http://youtrack.smxemail.com/issue/SSV-1366
const HEADER_CAPABILITY_LIST_NAME = 'commonHeaderValues'

const LEFT_SCALAR_SELECTOR_NAME = 'leftScalarSelector'

const DISPLAY_NAMES_CONDITION_NAME = 'display-names'
const HEADER_VALUES_CONDITION_NAME = 'header-values'

const HEADER_MATCHER_OPTIONS = {
  editable: true,
  customItemsEditable: false,
  emptyText: t('Type or select a header'),
  blankText: t('You can either select a predefined header or type a custom one.'),
  titleize: true,
  lowerCaseValue: true,
  trim: true, // according to rfc, header names can't have whitespaces at all
  quoteValue: true,
  listIsCapability: true,
  convertOrToRegex: true,
  convertGlobToRegex: true,
  validator: headerNameValidator
}

const HEADER_MATCHER_KEY = 'matches-headers'
const HEADER_NOT_MATCHER_KEY = 'not-matches-headers'

const RIGHT_CUSTOMER_LIST_MATCHER = 'matches-customer-list'
const RIGHT_CUSTOMER_LIST_NOT_MATCHER = 'not-matches-customer-list'

const RIGHT_SYSTEM_LIST_MATCHER = 'matches-system-list'
const RIGHT_SYSTEM_LIST_NOT_MATCHER = 'not-matches-system-list'

const HeadersCondition = Ext.define(null, {
  extend: ConditionClause,

  constructor(cfg = {}) {
    const config = { listStores: cfg.listStores }

    const LEFT_HEADER_LIST_CUSTOM_ITEMS = [
      {
        // not mentioning any value nor dsl here, since this is being delegated to the matcher
        name: NAME_MATCHING_REGEX_LABEL,
        matcherClazz: RegexpMatcher,
        matcherParameters: {
          alwaysIgnoreCasing: true,
          escape: false
        }
      },
      {
        matcher: 'customer-list',
        name: MATCHING_NAME_IN_LIST_LABEL,
        matcherType: ListTypes.RULE_HEADER,
        matcherClazz: ListNameMatcher,
        matcherParameters: {
          subDsl: '(customer-list {0})',
          listEmptyText: t('Please choose a list')
        }
      },
      {
        matcher: 'system-list',
        name: MATCHING_NAME_IN_SYSTEM_LIST_LABEL,
        matcherType: ListTypes.RULE_HEADER_SYSTEM,
        matcherClazz: ListNameMatcher,
        matcherParameters: {
          subDsl: '(system-list {0})',
          listEmptyText: t('Please choose a system list')
        }
      }
    ]

    config.steps = [
      {
        operatorWidth: 100,
        conditions: [
          [
            {
              width: 470,

              defaultValue: extractValue(cfg.value),
              defaultValueType: extractValueType(cfg.value),
              defaultMatcher:
                cfg.type && cfg.quantifier ? `${cfg.quantifier}-${cfg.type}` : undefined,
              matcherOptions: HEADER_MATCHER_OPTIONS,

              matchers: [
                {
                  matcher: 'any-headers',
                  name: t('Any header'),
                  clazz: EditableCustomList,
                  matcherType: HEADER_CAPABILITY_LIST_NAME,
                  dsl: 'any headers ({leftScalarSelector} (headers {0}))',
                  customItems: LEFT_HEADER_LIST_CUSTOM_ITEMS
                },
                {
                  matcher: 'every-headers',
                  name: t('Every header'),
                  clazz: EditableCustomList,
                  matcherType: HEADER_CAPABILITY_LIST_NAME,
                  dsl: 'every headers ({leftScalarSelector} (headers {0}))',
                  customItems: LEFT_HEADER_LIST_CUSTOM_ITEMS
                }
              ]
            }
          ]
        ]
      },
      {
        width: 105,
        dsl: false,
        name: 'leftScalarSelector',

        allowBlank: true,
        hideRestWhenBlank: true,
        emptyText: t('Choose scope'),
        defaultCondition: cfg.condition,
        validator: scopeValidator,

        // grouped conditions!
        // so that we can have separators between condition groups and sort them separately
        conditions: [
          [
            {
              name: 'addresses',
              label: t('address'),
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS
                },
                {
                  matcher: RIGHT_CUSTOMER_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM
                }
              ]
            },
            {
              name: 'domains',
              label: t('domain'),
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_CUSTOMER_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                }
              ]
            },
            {
              name: 'local-parts',
              label: t('local part'),
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_CUSTOMER_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                }
              ]
            },
            {
              name: DISPLAY_NAMES_CONDITION_NAME,
              label: t('display name'),
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_CUSTOMER_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                }
              ],
              hides: [
                {
                  matcher: HEADER_MATCHER_KEY,
                  allCustomItems: true
                },
                {
                  matcher: HEADER_NOT_MATCHER_KEY,
                  allCustomItems: true
                }
              ]
            },
            {
              name: HEADER_VALUES_CONDITION_NAME,
              label: t('text'),
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_CUSTOMER_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_OR_CONTENT
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_NOT_MATCHER,
                  matcherType: ListTypes.RULE_ADDRESS_SYSTEM_OR_CONTENT_SYSTEM
                }
              ],
              hides: [
                {
                  matcher: HEADER_MATCHER_KEY,
                  allCustomItems: true
                },
                {
                  matcher: HEADER_NOT_MATCHER_KEY,
                  allCustomItems: true
                }
              ]
            }
          ],

          [
            {
              name: 'exists',
              dsl: 'exists?',
              label: t('exists'),
              hideRest: true,

              // not sure if that sets option is needed for exists?
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_HEADER
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_HEADER_SYSTEM
                }
              ]
            },
            {
              name: 'not-exists',
              dsl: 'not-exists?',
              label: t('does not exist'),
              hideRest: true,

              // not sure if that sets option is needed for exists?
              sets: [
                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  matcherType: ListTypes.RULE_HEADER
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  matcherType: ListTypes.RULE_HEADER_SYSTEM
                }
              ]
            }
          ]
        ]
      },
      {
        width: 240,
        operatorWidth: 230,

        // there is no default condition since this part only has one condition but many special
        // cases for subsequent matchers

        defaultMatcher: extractRightMatcher(cfg),
        defaultValue: extractValue(cfg.next?.value),
        defaultValueType: extractValueType(cfg.next?.value),

        conditions: [
          [
            {
              dsl: false,

              // todo rename `matcher` property name to `key` and `matcherType` to type
              matchers: [
                {
                  matcher: HEADER_MATCHER_KEY,
                  name: t('matches header'),
                  clazz: EditableCustomList,
                  matcherType: HEADER_CAPABILITY_LIST_NAME,
                  dsl: 'matches ({leftScalarSelector} (headers {0}))',
                  options: HEADER_MATCHER_OPTIONS,
                  customItems: [
                    {
                      name: ENVELOPE_SENDER_LABEL,
                      value: ENVELOPE_SENDER_CONDITION_VALUE,
                      dsl: 'matches ({leftScalarSelector} ({0}))',
                      hideConditions: {
                        step: LEFT_SCALAR_SELECTOR_NAME,
                        conditions: [
                          DISPLAY_NAMES_CONDITION_NAME,
                          HEADER_VALUES_CONDITION_NAME
                        ]
                      }
                    },
                    {
                      name: ENVELOPE_RECIPIENTS_LABEL,
                      value: ENVELOPE_RECIPIENT_CONDITION_VALUE,
                      dsl: 'matches ({leftScalarSelector} ({0}))',
                      hideConditions: {
                        step: LEFT_SCALAR_SELECTOR_NAME,
                        conditions: [
                          DISPLAY_NAMES_CONDITION_NAME,
                          HEADER_VALUES_CONDITION_NAME
                        ]
                      }
                    }
                  ]
                },
                {
                  matcher: HEADER_NOT_MATCHER_KEY,
                  name: t('does not match header'),
                  clazz: EditableCustomList,
                  matcherType: HEADER_CAPABILITY_LIST_NAME,
                  dsl: 'not matches ({leftScalarSelector} (headers {0}))',
                  options: HEADER_MATCHER_OPTIONS,
                  customItems: [
                    {
                      name: ENVELOPE_SENDER_LABEL,
                      value: ENVELOPE_SENDER_CONDITION_VALUE,
                      dsl: 'not matches ({leftScalarSelector} ({0}))',
                      hideConditions: {
                        step: LEFT_SCALAR_SELECTOR_NAME,
                        conditions: [
                          DISPLAY_NAMES_CONDITION_NAME,
                          HEADER_VALUES_CONDITION_NAME
                        ]
                      }
                    },
                    {
                      name: ENVELOPE_RECIPIENTS_LABEL,
                      value: ENVELOPE_RECIPIENT_CONDITION_VALUE,
                      dsl: 'not matches ({leftScalarSelector} ({0}))',
                      hideConditions: {
                        step: LEFT_SCALAR_SELECTOR_NAME,
                        conditions: [
                          DISPLAY_NAMES_CONDITION_NAME,
                          HEADER_VALUES_CONDITION_NAME
                        ]
                      }
                    }
                  ]
                },

                {
                  matcher: 'matches-values',
                  name: t('matches text'),
                  clazz: TextMatcher,
                  matcherType: 'TEXT',
                  dsl: 'matches (values {0})'
                },
                {
                  matcher: 'not-matches-values',
                  name: t('does not match text'),
                  clazz: TextMatcher,
                  matcherType: 'TEXT',
                  dsl: 'not matches (values {0})'
                },

                // the next four matchers provide header lists we can match against the left side
                // ultimately, they grab header values whose names are in that list

                // beware that the type of the list is undefined here but
                // being set by the matcherType of the leftScalarSelector above

                // ==== CUSTOMER LISTS ====

                {
                  matcher: RIGHT_CUSTOMER_LIST_MATCHER,
                  name: t('matches value in list'),
                  clazz: ListNameMatcher,
                  dsl: 'matches ({leftScalarSelector} (customer-list {0}))',
                  options: {
                    skipLookAhead(stepDsl) {
                      return stepDsl === 'header-values'
                    }
                  }
                },
                {
                  matcher: RIGHT_CUSTOMER_LIST_NOT_MATCHER,
                  name: t('does not match value in list'),
                  clazz: ListNameMatcher,
                  dsl: 'not matches ({leftScalarSelector} (customer-list {0}))',
                  options: {
                    skipLookAhead(stepDsl) {
                      return stepDsl === 'header-values'
                    }
                  }
                },

                // ==== SYSTEM LISTS ====

                {
                  matcher: RIGHT_SYSTEM_LIST_MATCHER,
                  name: t('matches value in system list'),
                  clazz: ListNameMatcher,
                  dsl: 'matches ({leftScalarSelector} (system-list {0}))',
                  options: {
                    skipLookAhead(stepDsl) {
                      return stepDsl === 'header-values'
                    }
                  }
                },
                {
                  matcher: RIGHT_SYSTEM_LIST_NOT_MATCHER,
                  name: t('does not match value in system list'),
                  clazz: ListNameMatcher,
                  dsl: 'not matches ({leftScalarSelector} (system-list {0}))',
                  options: {
                    skipLookAhead(stepDsl) {
                      return stepDsl === 'header-values'
                    }
                  }
                },

                {
                  matcher: 'matches-regex',
                  name: t('matches regex'),
                  clazz: RegexpMatcher,
                  dsl: 'matches (values {0})',
                  options: {
                    escape: false
                  }
                },

                {
                  matcher: 'not-matches-regex',
                  name: t('does not match regex'),
                  clazz: RegexpMatcher,
                  dsl: 'not matches (values {0})',
                  options: {
                    escape: false
                  }
                },

                {
                  matcher: 'not-matches-unparseable',
                  name: t('is parseable'),
                  clazz: NoMatcher,
                  dsl: 'not matches (unparseable)'
                },
                {
                  matcher: 'matches-unparseable',
                  name: t('is not parseable'),
                  clazz: NoMatcher,
                  dsl: 'matches (unparseable)'
                }
              ]
            }
          ]
        ]
      }
    ]

    this.callParent([config])
  }
})

module.exports = HeadersCondition
