const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const EditRule = require('smartrules/EditRule')
const NewRule = require('smartrules/NewRule')
const RuleListPanel = require('smartrules/RuleListPanel')
const WindowMgr = require('admin/desktop/WindowMgr').default
const Desktop = require('admin/desktop/Desktop')
const TemplateChoiceForm = require('smartrules/TemplateChoiceForm')
const RuleRecord = require('smartrules/RuleRecord')
const User = require('admin/core/User')

const Rule = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.SmartRules.Rule',
  translations: {
    title: t('SmartRule'),
    new: t('New SmartRule'),
    open: t('Open SmartRule'),
    list: t('List SmartRules'),
    remove: t('Remove SmartRule')
  },
  name: t('SmartRule'),
  code: 'SMART_RULE',
  resourceName: 'rules',
  submitOnEnter: false,

  layout: {
    newResource: {
      width: 'auto',
      height: 'auto',
      resizable: true,
      resizeHandles: 'all',
      layout: 'fit'
    },
    openResource: {
      width: 'auto',
      height: 'auto',
      resizable: true,
      resizeHandles: 'all',
      layout: 'fit'
    }
  },

  detailPanel: EditRule,
  newPanel: NewRule,
  listPanel: RuleListPanel,

  getOptimalWidth() {
    // minus 70 so that there is a little whitespace around,
    // making it easier to resize when needed. Since SCL-1302.
    return Math.min(Desktop.getCenterPanelWidth(), 1119) - 70
  },

  // Override to show template options first.
  create(record, options = {}) {
    this.layout.newResource.width = this.getOptimalWidth()

    if (options.templateDefined) {
      return this.callParent(arguments)
    }

    const windowId = `choose.rule.template[${record?.id}]`
    if (!WindowMgr.select(windowId)) {
      const url = options.store.getUrl() + '/create/'

      return WindowMgr.create({
        id: windowId,
        url,
        title: t('Base New Rule On'),
        autoHeight: true,
        width: 380,
        iconCls: 'new',
        shim: false,
        animCollapse: false,
        maximizable: false,
        resizable: false,
        items: new TemplateChoiceForm({
          party: record,
          store: options.store
        }),
        layout: 'fit'
      })
    }
  },

  open() {
    this.layout.openResource.width = this.getOptimalWidth()
    this.callParent(arguments)
  },

  record: RuleRecord,

  getTemplate() {
    return TEMPLATE
  },

  canCreate() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canSave() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canRemove() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  }
})

module.exports = Rule
