import User from 'admin/core/User'
import Api from 'api'
import * as token from 'api/token'
import * as hal from 'hal'
import { SHARED_SERVICE_PROVIDER_REL } from 'product/resources'
import logger from 'system/logger'
import toPath from 'url/toPath'

export const SHAREDSERVICES_PROVIDER_STOP_CREATING =
  'SHAREDSERVICES_PROVIDER_STOP_CREATING'
export const SHAREDSERVICES_PROVIDER_STOP_CONFIGURING =
  'SHAREDSERVICES_PROVIDER_STOP_CONFIGURING'
export const SHAREDSERVICES_PROVIDER_START_CONFIGURING =
  'SHAREDSERVICES_PROVIDER_START_CONFIGURING'
export const SHAREDSERVICES_PROVIDER_SET = 'SHAREDSERVICES_PROVIDER_SET'
export const SHAREDSERVICES_PROVIDER_SET_LIST = 'SHAREDSERVICES_PROVIDER_SET_LIST'
export const SHAREDSERVICES_PROVIDER_SELECT = 'SHAREDSERVICES_PROVIDER_SELECT'
export const SHAREDSERVICES_PROVIDER_START_LOADING =
  'SHAREDSERVICES_PROVIDER_START_LOADING'
export const SHAREDSERVICES_PROVIDER_STOP_LOADING = 'SHAREDSERVICES_PROVIDER_STOP_LOADING'
export const SHAREDSERVICES_PROVIDER_START_CREATING =
  'SHAREDSERVICES_PROVIDER_START_CREATING'
export const SHAREDSERVICES_PROVIDER_START_CONFIRMING_CONFIGURATION =
  'SHAREDSERVICES_PROVIDER_START_CONFIRMING_CONFIGURATION'
export const SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_CONFIGURATION =
  'SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_CONFIGURATION'
export const SHAREDSERVICES_PROVIDER_START_CONFIRMING_DELETION =
  'SHAREDSERVICES_PROVIDER_START_CONFIRMING_DELETION'
export const SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_DELETION =
  'SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_DELETION'
export const SHAREDSERVICES_PROVIDER_SET_URI = 'SHAREDSERVICES_PROVIDER_SET_URI'

const startLoading = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_START_LOADING,
  companyKey
})
const stopLoading = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_STOP_LOADING,
  companyKey
})

const processError = (companyKey, err) => {
  return (dispatch) => {
    logger.error(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= INIT DB WITH URI FOR COMPANY KEY ========= ///

export const setUri = (companyKey, uri) => ({
  type: SHAREDSERVICES_PROVIDER_SET_URI,
  companyKey,
  uri
})

/// ========= LOGIN ========= ///

const login = (password) => {
  return () => {
    return Api.post(
      toPath('/login'),
      {
        username: User.getEmail(),
        password
      },
      { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
    ).then((response) => token.set(response.data.token))
  }
}

/// ========= SHAREDSERVICES_PROVIDER_SELECTIONS ========= ///

export const select = (companyKey, providers, selected) => {
  return {
    type: SHAREDSERVICES_PROVIDER_SELECT,
    companyKey,
    providers,
    selected
  }
}

/// ========= CREATION ========= ///

export const startCreating = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_START_CREATING,
  companyKey
})
export const stopCreating = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_STOP_CREATING,
  companyKey
})

export const createProvider = (companyKey, uri, values) => {
  return (dispatch) => {
    dispatch(startLoading(companyKey))

    return Api.post(uri, values)
      .then((response) => {
        const newProvider = response.data

        dispatch(refresh(companyKey, uri))
          .then(() => dispatch(select(companyKey, [newProvider], true)))
          .then(() => dispatch(stopCreating(companyKey)))
      })
      .catch((err) => dispatch(processError(companyKey, err)))
  }
}

/// ========= DELETION ========= ///

export const startConfirmingDeletion = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_START_CONFIRMING_DELETION,
  companyKey
})
export const stopConfirmingDeletion = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_DELETION,
  companyKey
})

export const deleteProvider = (companyKey, uri, selfLink) => {
  return (dispatch) => {
    dispatch(startLoading(companyKey))

    return Api.delete(selfLink)
      .then(() => dispatch(refresh(companyKey, uri)))
      .catch((err) => {
        dispatch(processError(companyKey, err))
      })
  }
}

export const authenticateAndDeleteProvider = (companyKey, uri, selfLink, password) => {
  return (dispatch) => {
    dispatch(startLoading(companyKey))
    dispatch(login(password))
      .then(() => {
        return Api.delete(selfLink)
          .then(() => dispatch(refresh(companyKey, uri)))
          .then(() => dispatch(stopConfirmingDeletion(companyKey)))
      })
      .catch((err) => {
        dispatch(processError(companyKey, err))
      })
  }
}

/// ========= CONFIGURATION ========= ///

export const startConfiguring = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_START_CONFIGURING,
  companyKey
})
export const stopConfiguring = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_STOP_CONFIGURING,
  companyKey
})

export const set = (companyKey, provider) => ({
  type: SHAREDSERVICES_PROVIDER_SET,
  companyKey,
  provider
})

const startConfirmingConfiguration = (companyKey, values) => {
  return {
    type: SHAREDSERVICES_PROVIDER_START_CONFIRMING_CONFIGURATION,
    companyKey,
    values
  }
}

const stopConfirmingConfiguration = (companyKey) => ({
  type: SHAREDSERVICES_PROVIDER_STOP_CONFIRMING_CONFIGURATION,
  companyKey
})

export const goBackToConfiguration = (companyKey) => {
  return (dispatch) => {
    dispatch(stopConfirmingConfiguration(companyKey))
    dispatch(startConfiguring(companyKey))
  }
}

export const submitAndConfirmProvider = (companyKey, values) => {
  return (dispatch) => {
    dispatch(stopConfiguring(companyKey))
    dispatch(startConfirmingConfiguration(companyKey, values))
  }
}

export const configureProvider = (companyKey, selfLink) => {
  return (dispatch) => {
    dispatch(startLoading(companyKey))

    return Api.get(selfLink)
      .then((response) => dispatch(set(companyKey, response.data)))
      .then(() => dispatch(startConfiguring(companyKey)))
      .then(() => dispatch(stopLoading(companyKey)))
      .catch((err) => dispatch(processError(companyKey, err)))
  }
}

export const updateProvider = (companyKey, uri, selfLink, values) => {
  return (dispatch) => {
    dispatch(startLoading(companyKey))

    return Api.put(selfLink, values)
      .then(() => dispatch(stopConfiguring(companyKey)))
      .then(() => dispatch(refresh(companyKey, uri)))
      .catch((err) => {
        dispatch(processError(companyKey, err))
      })
  }
}

export const authenticateAndUpdateProvider = (
  companyKey,
  uri,
  selfLink,
  values,
  password
) => {
  return (dispatch) => {
    dispatch(startLoading(companyKey))
    dispatch(login(password))
      .then(() => {
        return Api.put(selfLink, values)
          .then(() => dispatch(refresh(companyKey, uri)))
          .then(() => dispatch(stopConfirmingConfiguration(companyKey)))
      })
      .catch((err) => {
        dispatch(processError(companyKey, err))
      })
  }
}

export const doubleClickProvider = (companyKey, provider) => {
  return (dispatch) => {
    dispatch(select(companyKey, [provider], true))

    dispatch(configureProvider(companyKey, hal.self(provider)))
  }
}

/// ========= GET ========= ///

export const setList = (companyKey, newList) => ({
  type: SHAREDSERVICES_PROVIDER_SET_LIST,
  companyKey,
  newList
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)
    const data = response ? response.data : null

    dispatch(setList(companyKey, hal.resource(data, SHARED_SERVICE_PROVIDER_REL)))
  } catch (err) {
    dispatch(processError(companyKey, err))
  } finally {
    dispatch(stopLoading(companyKey))
  }
}
