const prettyBytes = require('pretty-bytes')

// moved those functions out of the Util class to make it pure functional for easier
// tests and also that other parts of code can reference to it without the need to
// require ExtJS

const SCALES = ['', 'K', 'M', 'G', 'T']

const Format = {
  bytes(b, places = 1) {
    if (!Number.isFinite(b)) return ''

    return prettyBytes(b, {
      minimumFractionDigits: places,
      maximumFractionDigits: places
    })
  },

  // adds commas to a string to prettyprint it ... for English
  addCommas(n) {
    return n.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
  },

  count(c, places = 0, noCompress) {
    if (c !== 0 && !c) return ''

    const scale = Math.max(
      0,
      Math.min(Math.floor(Math.log(c) / Math.log(1000)), SCALES.length - 1)
    )

    if (noCompress || scale === 0) {
      return `${Format.addCommas(Number(c).toFixed(places))}`
    }

    c = Number(c) / Math.pow(1000, scale)

    return `${Format.addCommas(c.toFixed(places))} ${SCALES[scale]}`
  },

  number(n, places = 0) {
    if (n !== 0 && !n) return ''

    return Format.count(n, places, true)
  }
}

module.exports = Format
