const Ext = require('ext')
const t = require('translate')
const PriceField = require('admin/component/form/PriceField')
const config = require('theming/config')
const CurrencyResource = require('admin/business/CurrencyResource')
const User = require('admin/core/User')

const CurrencyAndPriceField = Ext.define(null, {
  extend: Ext.Panel,

  border: false,
  disabled: false,
  xtype: 'currency-pricefield',
  fieldLabel: t('Price'),
  combineErrors: false,
  layout: 'hbox',

  initComponent() {
    const currencyChoiceExists = CurrencyResource.currencyChoiceExists()

    this.currencyCombo = this.getCurrencyCombo()
    this.priceAmountField = new PriceField({
      readOnly: this.readOnly,
      name: this.priceAmountFieldName,
      disabled: this.disabled,
      allowBlank: this.allowBlank,
      width: currencyChoiceExists ? 140 : config.panel.fieldWidth,
      listeners: this.priceAmountFieldListeners,
      style:
        // overriding extjs layout behavior so that the yellow required marker can be seen
        // explanation: it does some absolute positioning and this is messing with inline-block
        // for the yellow marker. hence the static positioning to make it work again.
        currencyChoiceExists && {
          marginLeft: '3px',
          position: 'static'
        }
    })

    this.items = [this.currencyCombo, this.priceAmountField]

    this.callParent()
  },

  getCurrencyCombo() {
    const currencyChoiceExists = CurrencyResource.currencyChoiceExists()

    if (currencyChoiceExists) {
      return CurrencyResource.getComboBox({
        name: this.currencyComboName,
        value: User.getDefaultCurrency(),
        width: 77,
        disabled: this.disabled,
        allowBlank: false
      })
    }

    return new Ext.form.Hidden({
      name: this.currencyComboName,
      value: User.getDefaultCurrency()
    })
  },

  disable() {
    this.disabled = true
    this.currencyCombo.disable()
    this.priceAmountField.disable()
  },

  enable() {
    this.disabled = true
    this.currencyCombo.enable()
    this.priceAmountField.enable()
  },

  clearInvalid() {
    this.currencyCombo.clearInvalid()
    this.priceAmountField.clearInvalid()
  },

  setAllowBlank(allowBlank) {
    this.currencyCombo.allowBlank = allowBlank
    this.priceAmountField.allowBlank = allowBlank
  }
})

module.exports = CurrencyAndPriceField
