import t from 'translate'
import validators from 'validators'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FormInput from 'components/legacy/FormInput'

class EmailInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    onValidityChange: PropTypes.func
  }

  static defaultProps = {
    value: '',
    name: 'email',
    multiple: false,
    label: t('Email Address')
  }

  getEmail(e) {
    // beware of null-strings
    if (e.target.value && e.target.value.length > 0) {
      return e.target.value
    }
  }

  getValidity = (e) => {
    const email = this.getEmail(e)

    if (email === undefined) return { valid: false }

    let error

    if (e.target.validity && e.target.validity.error) {
      error = e.target.validity.error
    } else if (this.props.multiple) {
      const emails = validators.linebrokenEmails(email)

      if (!emails) {
        error = t('One email per line accepted.')
      }
    } else if (!validators.wildcard(email)) {
      error = t('Invalid email format.')
    }

    return {
      valid: error === undefined,
      error
    }
  }

  getHelp = (e, validity, email) => {
    if (email && validity && validity.error) {
      return validity.error
    } else if (this.props.help) {
      return this.props.help
    }
  }

  renderSingleInput() {
    return (
      <FormInput
        value={this.props.value}
        required={this.props.required}
        name={this.props.name}
        label={this.props.label}
        type='email'
        getValidity={this.getValidity}
        getHelp={this.getHelp}
        stretch={this.props.stretch}
        disabled={this.props.disabled}
      />
    )
  }

  renderMultipleInput() {
    return (
      <FormInput
        {...this.props}
        rows='5'
        type='textarea'
        getValidity={this.getValidity}
        getHelp={this.getHelp}
        disabled={this.props.disabled}
      />
    )
  }

  render() {
    if (this.props.multiple) {
      return this.renderMultipleInput()
    }

    return this.renderSingleInput()
  }
}

export default EmailInput
