export default {
  // states must be split by company because
  // they might vary from vendor, distributor and reseller and multiple
  // forms can exist in DOM shadows
  companies: {
    // example:
    // '/customer/2000005': {
    //   'smx3:mailproducts/mailbox': {
    //     customerUrl: 'https://localhost:3000/api/customer/2000005',
    //     loading: false,
    //     list: [{...}]
    //     links: [{...}]
  }
}
