import React from 'react'

import classNames from 'classnames'
import Checkbox from 'components/form/Checkbox'
import CheckboxGroup from 'components/form/CheckboxGroup'
import ControlLabel from 'components/form/ControlLabel'
import Feedback from 'components/form/Feedback'
import FormControl from 'components/form/FormControl'
import RadioGroup from 'components/form/RadioGroup'
import InfoTooltip from 'components/InfoTooltip'
import { Col, FormGroup, HelpBlock } from 'react-bootstrap'
import _s from 'underscore.string'

const isRadio = (type) => type === 'radio'
const isPassword = (type) => type === 'password'
const isRadioOrCheckbox = (type) => isRadio(type) || isCheckbox(type)
const isNotTicked = (input) => !input.checked
const isErrorAfterChange = (meta) => meta.error && !meta.pristine

const isCheckbox = (type) => type === 'checkbox'
const isCheckboxGroup = (props) => isCheckbox(props.type) && props.items

// sometimes it can be an instance of array, having length, or
// it can be a pure string with a length
const getLength = (value) => value.size || value.length || -1
const hasValue = (input) => input.value && getLength(input.value)
const hasNoValue = (input) => !input.value || getLength(input.value) < 1

const isValueMissing = (props) => {
  const { input, type, meta, required } = props

  if (isCheckboxGroup(props)) {
    return !hasValue(input) && (meta.invalid || required)
  } else if (isRadioOrCheckbox(type)) {
    return isNotTicked(input) && (meta.invalid || required)
  } else if (hasNoValue(input)) {
    return required
  }

  return false
}

const getValidationState = (props) => {
  const { input, type, meta, required } = props

  if (isValueMissing(props)) {
    return 'warning'
  } else if (isErrorAfterChange(meta)) {
    return 'error'
  } else if (isRadioOrCheckbox(type) && required) {
    return 'success'
    // for password we don't want to surround the input with green
  } else if (!isPassword(type) && hasValue(input)) {
    return 'success'
  }
}

export default function Group(props) {
  const {
    input,
    label,
    type,
    componentClass,
    help,
    info,
    meta,
    labelWidth,
    disabled,
    inputWidth = 8
  } = props

  let helpText = help
  const valueIsMissing = isValueMissing(props)
  const validationState = getValidationState(props)

  const inputColClasses = []

  if (valueIsMissing) {
    inputColClasses.push('required')
  } else if (meta.error) {
    helpText = meta.error
  }

  let controlElement

  if (isCheckboxGroup(props)) {
    controlElement = CheckboxGroup(props)
  } else if (isCheckbox(type)) {
    controlElement = Checkbox(props)
  } else if (isRadio(type)) {
    controlElement = RadioGroup(props)
  } else {
    controlElement = FormControl(props)
  }

  let formGroupWrapperName = null

  if (type) {
    formGroupWrapperName = type
  } else if (componentClass && typeof componentClass.valueOf() === 'string') {
    formGroupWrapperName = componentClass
  } else {
    formGroupWrapperName = _s.slugify(label)
  }

  const className = formGroupWrapperName + '-form-group-wrapper'

  return (
    <div className={className}>
      <FormGroup controlId={input.id || input.name} validationState={validationState}>
        {label !== false && ControlLabel(label, labelWidth)}
        <Col xs={inputWidth} className={classNames(inputColClasses)}>
          {controlElement}
          {!disabled && label && <Feedback />}
          {info && <InfoTooltip info={info} name={input.name} />}
          {help && <HelpBlock>{helpText}</HelpBlock>}
        </Col>
      </FormGroup>
    </div>
  )
}
