const Record = require('admin/core/Record')
const Time = require('admin/time/Time')

const PolicyRecord = Record.define(null, {
  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'action',
      type: 'string'
    },
    {
      name: 'matcherName',
      type: 'string',
      convert(v) {
        return v.toLowerCase()
      }
    },
    {
      name: 'type',
      type: 'string',
      convert(v, record) {
        const type = record.action === 'ALLOW' ? 'whitelist' : 'blacklist'
        const matcher = record.matcherName.toLowerCase()
        const PolicyManagementResource = require('admin/business/PolicyManagementResource')
        const policyMatcher = PolicyManagementResource.policyTypes[type].find(
          (type) => type.matcher === matcher
        )

        return policyMatcher.text
      }
    },
    {
      name: 'priority',
      type: 'int',
      convert(v, record) {
        const PolicyManagementResource = require('admin/business/PolicyManagementResource')
        return PolicyManagementResource.priorities[
          record.action.toUpperCase() + ':' + record.matcherName.toUpperCase()
        ]
      }
    },
    {
      name: 'operand',
      type: 'string'
    },
    {
      name: 'data',
      type: 'string'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: Time.toJS
    }
  ],

  getParams() {
    if (!this.get('operand') || this.get('operand') === '') {
      return {
        action: this.get('action').toUpperCase(),
        matcherName: this.get('matcherName').toUpperCase(),
        data: this.get('data')
      }
    }
    return {
      action: this.get('action').toUpperCase(),
      matcherName: this.get('matcherName').toUpperCase(),
      operand: this.get('operand'),
      data: this.get('data')
    }
  },

  isPersistentOnSave() {
    return true
  }
})

module.exports = PolicyRecord
