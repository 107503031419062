const Ext = require('ext')
const t = require('translate')
const ConditionClause = require('smartrules/designer/ConditionClause')
const BetweenMatcher = require('smartrules/designer/matchers/BetweenMatcher')
const LteMatcher = require('smartrules/designer/matchers/LteMatcher')
const GteMatcher = require('smartrules/designer/matchers/GteMatcher')

const SizeOf = Ext.define(null, {
  extend: ConditionClause,

  constructor(cfg = {}) {
    const config = {
      steps: [
        {
          width: 120,
          operatorWidth: 115,
          defaultValue: cfg.value,
          defaultMatcher: cfg.value?.type,
          name: 'size-of',

          conditions: [
            [
              {
                label: t('Message size'),
                matcherOptions: {
                  forSize: true
                },

                matchers: [
                  {
                    matcher: 'between',
                    name: t('is between'),
                    clazz: BetweenMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'message size {0}'
                  },
                  {
                    matcher: 'less',
                    name: t('is less than'),
                    matcherType: 'NUMBER',
                    clazz: LteMatcher,
                    dsl: 'message size {0}'
                  },
                  {
                    matcher: 'greater',
                    name: t('is greater than'),
                    clazz: GteMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'message size {0}'
                  }
                ]
              }
            ]
          ]
        }
      ]
    }

    this.callParent([config])
  },

  isValid() {
    const matcher = this.getMatcherComboByStepName('size-of').get(1)
    const sizes = ['kB', 'MB']
    let valid = true

    if (matcher instanceof BetweenMatcher) {
      let lower = Number(matcher.lower.getValue())
      lower = lower * Math.pow(1024, sizes.indexOf(matcher.lowerSizeCombo.getValue()))

      let upper = Number(matcher.upper.getValue())
      upper = upper * Math.pow(1024, sizes.indexOf(matcher.upperSizeCombo.getValue()))

      valid = lower < upper
      const message = valid ? '' : t('Invalid value range')

      matcher.markInvalid(message)
    }

    return valid && ConditionClause.prototype.isValid.call(this)
  }
})

module.exports = SizeOf
