// Validators accept a string and return true on success, false on failure.
// See below for withMsg helper.

const SET_NAME_REGEX = /^\S.*\S$/

// CIDR + IP regexes, introduced in SCL-2120 for managing addresses of shared services
// regex copied from https://github.com/sindresorhus/ip-regex
const IP_SUB_REGEX =
  '(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(?:\\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){3}'
const CIDR_SUB_REGEX = `${IP_SUB_REGEX}(/(3[0-2]|[1-2][0-9]|[0-9]))`
const IP_CIDR_REGEX = new RegExp(`^${IP_SUB_REGEX}$|^${CIDR_SUB_REGEX}$`, 'i')
const IP_CIDR_EMPTY_REGEX = new RegExp(
  `^${IP_SUB_REGEX}$|^${CIDR_SUB_REGEX}$|^$|^[\\r\\n\\s]+$`,
  'i'
)

// allows ascii chars between 33 (hex 21) and 126 (7e), inclusive
// but colon must the excluded, ascii 58 (hex 3a)
const HEADER_NAME_REGEX = /^[\x21-\x39\x3B-\x7E]+$/i

const EMAIL_IP_DOMAIN_SUB_REGEX =
  '\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\]'
const EMAIL_ATOM_SUB_REGEX = "[a-zA-Z0-9!#$%&'+ /= ?^_`{|}~-]"
const DOMAIN_SUB_REGEX = `(${EMAIL_ATOM_SUB_REGEX}+(\\.${EMAIL_ATOM_SUB_REGEX}{2,})+)`
const EMAIL_ADDRESS_REGEX = `${EMAIL_ATOM_SUB_REGEX}+(\\.${EMAIL_ATOM_SUB_REGEX}+)*@(${DOMAIN_SUB_REGEX}|${EMAIL_IP_DOMAIN_SUB_REGEX})`
const EMAIL_REGEX = new RegExp(`^${EMAIL_ADDRESS_REGEX}$`)
// a mailbox address can have any string before the email, like
// “Bob Smith <bob.smith@test.com>”
const MAILBOX_REGEX = new RegExp(`${EMAIL_ADDRESS_REGEX}>?$`)

const WILDCARD_EMAIL_ATOM_SUB_REGEX = "[*a-zA-Z0-9!#$%&'+ /= ?^_`{|}~-]"
const WILDCARD_EMAIL_DOMAIN_SUB_REGEX = `(${WILDCARD_EMAIL_ATOM_SUB_REGEX}+(\\.${WILDCARD_EMAIL_ATOM_SUB_REGEX}+)*`
const WILDCARD_EMAIL_ADDRESS_REGEX = `${WILDCARD_EMAIL_ATOM_SUB_REGEX}+(\\.${WILDCARD_EMAIL_ATOM_SUB_REGEX}+)*@${WILDCARD_EMAIL_DOMAIN_SUB_REGEX}|${EMAIL_IP_DOMAIN_SUB_REGEX})`
const WILDCARD_EMAIL_REGEX = new RegExp(`^${WILDCARD_EMAIL_ADDRESS_REGEX}$`)

// a mailbox address can have any string before the email, like
// “Bob Smith <bob.smith@test.com>”
//const WILDCARD_MAILBOX_REGEX = new RegExp(`${WILDCARD_EMAIL_ADDRESS_REGEX}>?$`)

// Regexes only UI is using which are fine for now.

const POSITIVE_INT_REGEX = /^\d+$/
const SURROUNDING_WHITESPACE_REGEX = /^\s+|\s+$/g

// According to RFC1035 - 2.3.1. Preferred name syntax
// see https://tools.ietf.org/html/rfc1035#section-2.3.1
const DOMAIN_WITH_WILDCARD_REGEX =
  /^([a-z0-9*]([a-z0-9\-*]{0,61}[a-z0-9*])?\.)*[a-z\-*]{1,63}$/i

// Older regexes. Deprecated.
// Todo: We should be using the above ones instead.

const LEGACY_DOMAIN_REGEX =
  /^((([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z\d-_~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z\d-_~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
const LEGACY_DOMAIN_OR_IP_REGEX =
  /^([A-Za-z0-9]([-A-Za-z0-9]*[A-Za-z0-9])*\.)+[A-Za-z]{2,}$|^([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})$/

const LEGACY_EMAIL_AND_GROUP_GLOB_REGEX = /.+(@.+|:.*;)$/

// Obsolete now, replaced with the newer EMAIL_REGEX above to leaving here as a comment for history
// LEGACY_EMAIL_REGEX = /^((([a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d-_~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))\.)+([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d-_~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))$/i

// Null checking trim
const safeTrim = (s) => (s || '').trim()

const splitList = function (v, separator) {
  if (!v) return v
  if (!v.split) return v

  return v
    .split(separator)
    .map(safeTrim)
    .filter((entry) => entry)
}

const extractDomain = function (email) {
  // ignore casing here, see http://youtrack.smxemail.com/issue/SCL-944
  const matches = email.match(/.+@(.+\..+)/)
  return matches && matches[1].toLowerCase()
}

const validators = {
  headerName(v) {
    return HEADER_NAME_REGEX.test(v)
  },

  domain(v) {
    return LEGACY_DOMAIN_REGEX.test(v)
  },

  domainWithWildcard(v) {
    return v.length <= 253 && DOMAIN_WITH_WILDCARD_REGEX.test(v)
  },

  domainOrIp(v) {
    try {
      return LEGACY_DOMAIN_OR_IP_REGEX.test(v)
    } catch (exc) {
      return false
    }
  },
  wildcard(v) {
    return WILDCARD_EMAIL_REGEX.test(v)
  },

  email(v) {
    return EMAIL_REGEX.test(v)
  },

  mailbox(v) {
    return MAILBOX_REGEX.test(v)
  },

  emailTpl(v) {
    return validators.email(
      v.replace('{{{user}}}', 'user').replace('{{{domain}}}', 'domain.com')
    )
  },

  emailList(v) {
    const emails = splitList(v, ',')
    return emails.length > 0 && emails.every(validators.email)
  },
  emailAndGroupGlob(v) {
    return LEGACY_EMAIL_AND_GROUP_GLOB_REGEX.test(v)
  },

  // returns false when not valid, otherwise an array of valid emails
  linebrokenEmails(v) {
    const emails = splitList(v, '\n')

    if (emails.length > 0 && emails.every(validators.email)) {
      return emails
    }

    return false
  },

  invalidDomain(emails, allowedDomains) {
    if (!emails) return false

    return emails.some((email) => allowedDomains.indexOf(extractDomain(email)) < 0)
  },

  // allow empty values, see SCL-1298
  // allow email groups, see SCL-1418
  linebrokenEmailAndGroupGlobs(v) {
    const emails = splitList(v, '\n')

    if (emails.length > 0) {
      return emails.every(validators.emailAndGroupGlob)
    }

    return v.replace(SURROUNDING_WHITESPACE_REGEX, '').length < 1
  },

  positiveInteger(v) {
    return POSITIVE_INT_REGEX.test(v)
  },

  isIntegerGreaterThanZero(v) {
    return v > 0 && POSITIVE_INT_REGEX.test(v)
  },

  isCidrOrIp(v) {
    return IP_CIDR_REGEX.test(v)
  },

  isCidrOrIpOrEmpty(v) {
    return IP_CIDR_EMPTY_REGEX.test(v)
  },

  isLinebrokenCidrOrIp(v, opts = {}) {
    const entries = splitList(v, '\n')

    if (!entries) return false
    if (!entries.length) return false
    if (entries.length < 1) return false

    const allowEmpty = opts.allowEmpty || false

    if (allowEmpty) {
      return entries.every(validators.isCidrOrIpOrEmpty)
    }

    return entries.every(validators.isCidrOrIp)
  },

  // set names can be policy sets, smart rules sets, and mail footer sets
  isSetName(v) {
    return SET_NAME_REGEX.test(v)
  }
}

// Helper function for easy use with Ext components.
// Returns validator function that observes allowBlank and returns given message on failure.
const withMsg = function (msg) {
  const validatorFn = this

  return function (v) {
    // Trim all values and protect against null.
    v = safeTrim(v)

    if (this?.allowBlank && (!v || v === this.emptyText)) {
      return true
    } else if (validatorFn(v) === true) {
      return true
    }

    return msg
  }
}

// Add helper to each validator.
Object.values(validators).forEach((v) => {
  v.withMsg = withMsg
})

export default validators
