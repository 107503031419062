import React, { Component } from 'react'

import Block from 'components/form/Block'
import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Field from 'components/form/Field'
import Grid from 'components/Grid'
import Mask from 'components/Mask'
import { formDisabled, loading, names, tenancyForm } from 'integration/tenancy/subs'
import PropTypes from 'prop-types'
import { Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import t from 'translate'
import _s from 'underscore.string'
import { validate as uuidValidate } from 'uuid'

export const FORM_NAME = 'tenancyForm'

class TenancyDialog extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired,
    tenancy: PropTypes.object,
    renaming: PropTypes.bool,
    submitButtonText: PropTypes.string
  }

  validateName = (value) => {
    const trimmedValue = value.trim()

    if (_s.isBlank(trimmedValue)) {
      return t("White spaces aren't allowed.")
    } else if (trimmedValue.length < 4) {
      return t('Name must have at least four characters.')
    }

    if (!this.props.names) return

    const initialName = this.props.initialValues?.name

    if (this.props.names.filter((name) => name !== initialName).includes(trimmedValue)) {
      return t('This name already exists.')
    }
  }

  validateTenantId = (value) => {
    const trimmedValue = value.trim()

    if (_s.isBlank(trimmedValue)) {
      return t("White spaces aren't allowed.")
    }

    if (!uuidValidate(trimmedValue)) {
      return t('Not a valid Azure Tenant ID.')
    }
  }

  disableSubmit() {
    return this.props.pristine || this.props.invalid || this.props.disabled
  }

  values = () => this.props.tenancyForm.values

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.onSubmit(this.values())
  }

  renderTenantId() {
    return (
      <Field
        required
        name='tenant-id'
        id='tenant-id'
        autoComplete='off'
        // so that it can be tested with a custom store
        store={this.props.store}
        disabled={this.props.disabled}
        validate={this.validateTenantId}
        label={t('Tenant ID')}
        type='text'
        tabIndex={1}
        help={t('Enter your Azure AD Tenant ID')}
      />
    )
  }

  renderAccepted() {
    return (
      <div>
        <Block>
          <h5>
            <strong>
              Using the SMX Identity Gateway for SSO?
              <br />
              Have you configured access?
            </strong>
          </h5>
          <p>
            If you have installed the SMX Identity Gateway application, you must make sure
            you have completed the configuration steps to ensure only authorised users
            within your organisation have access to the SMX Admin Portal before adding
            your Azure AD Tenant ID.
          </p>
          <p>
            Details on how to configure access can be found{' '}
            <a
              href='https://docs.smxemail.com/setup/identity_integrations.html#smx-identity-gateway-guide-for-microsoft-365'
              rel='noreferrer'
              target='_blank'
            >
              HERE
            </a>{' '}
            or please contact&nbsp;
            <a href='mailto:emailsupport@smxemail.com'>emailsupport@smxemail.com</a> for
            assistance.
          </p>
        </Block>
        <Field
          required
          name='accepted'
          store={this.props.store}
          label={t('Access Configured or not applicable')}
          type='checkbox'
          tabIndex={2}
        />
      </div>
    )
  }

  render() {
    const dialogClassName = this.props.submitButtonText?.toLowerCase()

    return (
      <Mask>
        <Modal.Dialog className={dialogClassName}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Grid>
                <Field
                  required
                  autoFocus
                  name='name'
                  id='tenancy-name'
                  autoComplete='off'
                  // so that it can be tested with a custom store
                  store={this.props.store}
                  disabled={this.props.disabled}
                  validate={this.validateName}
                  label={t('Name')}
                  type='text'
                  tabIndex={0}
                  help={t('Enter a unique name for this tenancy')}
                />
                {!this.props.renaming && this.renderTenantId()}
                {!this.props.renaming && this.renderAccepted()}
              </Grid>
            </Modal.Body>

            <Modal.Footer>
              <Grid>
                <CancelSubmitButtons
                  disableCancel={this.props.disabled}
                  disableSubmit={this.disableSubmit()}
                  onCancel={this.props.onCancel}
                  submitText={this.props.submitButtonText}
                />
              </Grid>
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, props) => ({
  tenancyForm: tenancyForm(db),
  disabled: formDisabled(db, props.companyKey),
  initialValues: props.tenancy,
  loading: loading(db, props.companyKey),
  names: names(db, props.companyKey)
})

const TenancyForm = reduxForm({ form: FORM_NAME })(TenancyDialog)

export default connect(mapDbToProps)(TenancyForm)
