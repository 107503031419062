const Ext = require('ext')
const t = require('translate')
const FormPanel = require('admin/component/form/FormPanel')
const DateTimeRangeFieldSet = require('admin/component/form/DateTimeRangeFieldSet')
const TimeZoneComboBox = require('admin/component/form/TimeZoneComboBox')
const ComboBox = require('admin/component/form/ComboBox')
const DomainSummaryResultStore = require('admin/data/DomainSummaryResultStore')
const config = require('theming/config')
const Time = require('admin/time/Time')

const MailStatsConfigPanel = Ext.define(null, {
  extend: Ext.Panel,

  bodyStyle: 'padding-top: 5px',
  height: 0,
  layout: 'hbox',
  layoutConfig: {
    align: 'stretch'
  },

  initComponent() {
    this.items = [
      new FormPanel({
        flex: 1,
        layout: 'form',
        items: [
          (this.dateRange = new DateTimeRangeFieldSet({
            from: {
              value: this.date7DaysAgo()
            },
            thru: {
              value: this.dateNow()
            },
            both: {
              labelWidth: config.panel.labelWidth
            },
            listeners: {
              change: this.handleDateRangeChange,
              scope: this
            }
          })),
          (this.timeZoneCombo = new TimeZoneComboBox({
            width: 'auto',
            listeners: {
              select: this.handleChange,
              scope: this
            }
          }))
        ]
      }),
      (this.configPanel = new FormPanel({
        flex: 1,
        layout: 'form',
        items: [
          (this.domainCombo = new ComboBox({
            displayField: 'name',
            editable: false,
            fieldLabel: t('Domain'),
            forceSelection: true,
            triggerAction: 'all',
            value: 'all',
            valueField: 'value',
            mode: 'local',
            store: new Ext.data.ArrayStore({
              fields: ['value', 'name'],
              data: [['all', t('All Domains')]]
            }),
            listeners: {
              select: this.handleChange,
              scope: this
            }
          })),
          (this.eventTypeCombo = new ComboBox({
            displayField: 'name',
            editable: false,
            fieldLabel: t('Event Type'),
            forceSelection: true,
            mode: 'local',
            triggerAction: 'all',
            value: '*',
            valueField: 'value',
            store: new Ext.data.ArrayStore({
              fields: ['value', 'name'],
              data: [
                ['*', t('All')],
                ['ALL', t('Total')],
                ['CLEAN', t('Clean')],
                ['SPAM', t('Spam')],
                ['THREAT', t('Threat')],
                ['ALLOW_POLICY', t('Whitelist')],
                ['DENY_POLICY', t('Blacklist')]
              ]
            }),
            listeners: {
              select: this.handleChange,
              scope: this
            }
          })),
          (this.dataCombo = new ComboBox({
            displayField: 'name',
            editable: false,
            fieldLabel: t('Data'),
            forceSelection: true,
            mode: 'local',
            triggerAction: 'all',
            valueField: 'value',
            value: 'messageCount',
            store: new Ext.data.ArrayStore({
              fields: ['value', 'name'],
              data: [
                ['messageCount', t('Count')],
                ['messageTraffic', t('Traffic')]
              ]
            }),
            listeners: {
              select: this.handleChange,
              scope: this
            }
          }))
        ]
      }))
    ]

    this.bbar = [
      {
        xtype: 'buttongroup',
        items: [
          {
            xtype: 'label',
            text: t('Choose scale')
          },
          {
            xtype: 'spacer',
            width: 4
          },
          {
            text: t('Latest'),
            xtype: 'splitbutton',
            handler(b) {
              return b.showMenu()
            },
            menu: {
              items: [
                {
                  text: t('24 hours'),
                  handler: () => {
                    return this.setRange(
                      this.date24HoursAgo(),
                      this.dateNow(),
                      t('Showing last 24 hours from now')
                    )
                  }
                },
                {
                  text: t('7 days'),
                  handler: () => {
                    return this.setRange(
                      this.date7DaysAgo(),
                      this.dateNow(),
                      t('Showing last 7 days from now')
                    )
                  }
                },
                {
                  text: t('28 days'),
                  handler: () => {
                    return this.setRange(
                      this.date28DaysAgo(),
                      this.dateNow(),
                      t('Showing last 28 days from now')
                    )
                  }
                }
              ]
            }
          },
          {
            text: t('or'),
            xtype: 'label',
            margins: '8'
          },
          {
            xtype: 'spacer',
            width: 5
          },
          {
            text: t('Period'),
            xtype: 'splitbutton',
            handler(b) {
              return b.showMenu()
            },
            menu: {
              items: [
                {
                  text: t('Today'),
                  handler: () => {
                    return this.setRange(
                      this.dateToday(),
                      this.dateTomorrow(),
                      t('Showing today')
                    )
                  }
                },
                {
                  text: t('Yesterday'),
                  handler: () => {
                    return this.setRange(
                      this.dateYesterday(),
                      this.dateToday(),
                      t('Showing yesterday')
                    )
                  }
                },
                '-',
                {
                  text: t('This month'),
                  handler: () => {
                    return this.setRange(
                      this.dateThisMonth(),
                      this.dateNextMonth(),
                      t('Showing calendar month to date')
                    )
                  }
                },
                {
                  text: t('Last month'),
                  handler: () => {
                    return this.setRange(
                      this.dateLastMonth(),
                      this.dateThisMonth(),
                      t('Showing last full calendar month')
                    )
                  }
                },
                '-',
                {
                  text: t('This year'),
                  handler: () => {
                    return this.setRange(
                      this.dateThisYear(),
                      this.dateNextYear(),
                      t('Showing calendar year to date')
                    )
                  }
                },
                {
                  text: t('Last year'),
                  handler: () => {
                    return this.setRange(
                      this.dateLastYear(),
                      this.dateThisYear(),
                      t('Showing last full calendar year')
                    )
                  }
                }
              ]
            }
          },
          {
            text: t('or'),
            xtype: 'label'
          },
          {
            xtype: 'spacer',
            width: 5
          },
          {
            text: t('Lifetime'),
            handler: () => {
              return this.setRange(null, this.dateNow(), t('Showing lifetime'))
            }
          },
          {
            xtype: 'tbseparator'
          },
          (this.historyLabel = new Ext.form.Label({
            text: t('Showing last 7 days from now')
          }))
        ]
      },
      '->',
      {
        xtype: 'button',
        text: t('Advanced'),
        enableToggle: true,
        handler: this.handleAdvancedToggle,
        scope: this
      },
      {
        width: 8,
        xtype: 'spacer'
      }
    ]

    this.callParent()
  },

  afterRender() {
    this.callParent()

    // Fill domainStore options with the results of a domain summary search.
    const comboStore = this.domainCombo.store
    const domainSummaryStore = new DomainSummaryResultStore({
      url: this.product.resources.DOMAINS_SEARCH.url,
      direction: this.direction
    })

    domainSummaryStore.search()

    domainSummaryStore.on('load', () =>
      domainSummaryStore.each((record) => {
        const Type = comboStore.recordType
        comboStore.add(
          new Type({
            name: record.get('name'),
            value: record.get('name')
          })
        )
      })
    )
  },

  handleChange() {
    return this.fireEvent('change')
  },

  handleDateRangeChange() {
    this.showLifetime = false
    this.historyLabel.setText(t('Showing advanced options date range'))
    this.historyLabel.ownerCt.doLayout()
    this.handleChange()
  },

  handleAdvancedToggle(button) {
    this.setHeight(button.pressed ? 140 : 0)
    this.ownerCt.doLayout()
  },

  setRange(fromDate, thruDate, label) {
    if (fromDate) {
      this.showLifetime = false
      this.dateRange.setFromValue(fromDate)
    } else {
      this.showLifetime = true
    }
    this.dateRange.setThruValue(thruDate)
    this.historyLabel.setText(label)
    this.doLayout()
    this.handleChange()
  },

  getConfig() {
    // Since SCL-3666, carefully shift from/thru dates
    // between browser time zone and selected time zone
    const timeZone = this.timeZoneCombo.getTimeZone()
    const fromValue = this.dateRange.getFromValue().getTime()

    let fromDate = null

    if (!this.showLifetime) {
      fromDate = Time.rezone(fromValue, timeZone.zoneName, {
        keepLocalTime: true
      })
    }

    const thruDate = Time.rezone(
      this.dateRange.getThruValue().getTime(),
      timeZone.zoneName,
      {
        // changes timestamp leaving local time untouched
        keepLocalTime: true
      }
    )

    return {
      fromDate: fromDate?.toMillis(),
      thruDate: thruDate?.toMillis(),
      timeZone,
      domain:
        this.domainCombo.getValue() !== 'all' ? this.domainCombo.getValue() : undefined,
      type: this.eventTypeCombo.getValue(),
      dataField: this.dataCombo.getValue(),
      direction: this.direction
    }
  },

  // Helpers to get meaningful date objects.
  roundHours(d) {
    d.setHours(0, 0, 0, 0)
    return d
  },

  roundMonth(d) {
    d.setDate(1)
    return this.roundHours(d)
  },

  roundYear(d) {
    d.setMonth(0)
    return this.roundMonth(d)
  },

  dateNow() {
    return new Date()
  },

  date24HoursAgo() {
    return this.dateNow().add(Date.HOUR, -24)
  },

  date7DaysAgo() {
    return this.dateNow().add(Date.DAY, -7)
  },

  date28DaysAgo() {
    return this.dateNow().add(Date.DAY, -28)
  },

  dateToday() {
    return this.roundHours(this.dateNow())
  },

  dateTomorrow() {
    return this.roundHours(this.dateNow().add(Date.DAY, 1))
  },

  dateYesterday() {
    return this.roundHours(this.dateNow().add(Date.DAY, -1))
  },

  dateThisMonth() {
    return this.roundMonth(this.dateNow())
  },

  dateNextMonth() {
    return this.roundMonth(this.dateNow().add(Date.MONTH, 1))
  },

  dateLastMonth() {
    return this.roundMonth(this.dateNow().add(Date.MONTH, -1))
  },

  dateThisYear() {
    return this.roundYear(this.dateNow())
  },

  dateNextYear() {
    return this.roundYear(this.dateNow().add(Date.YEAR, 1))
  },

  dateLastYear() {
    return this.roundYear(this.dateNow().add(Date.YEAR, -1))
  }
})

module.exports = MailStatsConfigPanel
