const _s = require('underscore.string')
const isObject = require('is-plain-obj')
const t = require('translate')

// actually private function but exporting it solely for unit tests
export function prettify(value, key = null) {
  if (!value && !key) return

  if (Array.isArray(value)) {
    if (value.length < 1) return
    let output = value.map((subValue) => prettify(subValue)).join(', ') // recursion

    if (value.length > 1) {
      output = '[' + output + ']'
    }

    return `${key}: ` + output
  } else if (isObject(value)) {
    const keys = Object.keys(value)

    if (keys.length < 1) return

    return keys
      .map((subKey) => {
        const subValue = value[subKey]
        return `${subKey}=${subValue}`
      })
      .join(', ')
  }

  const prefix = key !== null ? `${key}: ` : ''

  return prefix + value
}

export function pairs(details) {
  if (!Array.isArray(details)) return

  return details.reduce((fields, { detail }) => {
    const { dmarc } = detail

    if (dmarc === 'quarantine' || dmarc === 'reject') {
      // shift the 'dmarc' value (quarantine or reject) to the front
      delete detail.dmarc

      const detailWithDmarc = Object.assign({ dmarc }, detail)

      const spf = []
      const dkim = []
      const summary = []

      Object.keys(detailWithDmarc).forEach(function (key) {
        const value = detailWithDmarc[key]

        if (_s.startsWith(key, 'spf')) {
          return spf.push(prettify(value, key))
        } else if (_s.startsWith(key, 'dkim')) {
          return dkim.push(prettify(value, key))
        }

        return summary.push(prettify(value, key))
      })

      if (spf.length > 0) {
        fields.push({ label: t('SPF'), value: spf.join('; ') })
      }

      if (dkim.length > 0) {
        fields.push({ label: t('DKIM'), value: dkim.join('; ') })
      }

      if (summary.length > 0) {
        fields.push({ label: t('DMARC'), value: summary.join('; ') })
      }
    }

    return fields
  }, [])
}
