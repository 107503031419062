const Record = require('admin/core/Record')

const PriceRecord = Record.define(null, {
  fields: [
    {
      name: 'description',
      type: 'string'
    },
    {
      name: 'fromQuantity',
      type: 'int'
    },
    {
      name: 'frequencyValue',
      type: 'int'
    },
    {
      name: 'priceAmount',
      type: 'float'
    },
    {
      name: 'uom',
      type: 'string'
    },
    {
      name: 'type',
      type: 'string'
    },
    {
      name: 'percentage',
      type: 'float'
    }
  ]
})

module.exports = PriceRecord
