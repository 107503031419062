const Ext = require('ext')
const ComboBox = require('admin/component/form/ComboBox')
const SmartRule = require('smartrules/designer/SmartRule')

const Operator = Ext.define(null, {
  extend: ComboBox,

  constructor(cfg = {}) {
    this.defaultCfg = Ext.applyIf(cfg, {
      allowBlank: false,
      cls: 'matcher-operator',
      displayField: 'name',
      editable: false,
      forceSelection: true,
      lazyInit: false,
      listeners: {
        scope: cfg.scope,
        select: cfg.selectMatcher
      },
      margins: '2',
      mode: 'local',
      triggerAction: 'all',
      value: cfg.matcher,
      valueField: 'matcher',
      width: cfg.width || 155
    })

    this.callParent([this.defaultCfg])

    this.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
  },

  toDsl() {
    const index = this.store.find(this.valueField, this.getValue())
    const record = this.store.getAt(index)
    return (record && record.get('dsl')) || ''
  }
})

module.exports = Operator
