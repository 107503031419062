const Ext = require('ext')
const t = require('translate')
const ConditionClause = require('smartrules/designer/ConditionClause')
const ContentMatcher = require('smartrules/designer/matchers/ContentMatcher')

const Message = Ext.define(null, {
  extend: ConditionClause,

  constructor(cfg = {}) {
    let value

    if (cfg.valueType === 'regexp' && cfg.regex) {
      value = {
        regex: cfg.regex,
        ignoreCase: !!(cfg.flags || '').match(/i/i)
      }
    } else if (cfg.pattern) {
      value = cfg.pattern
    } else {
      value = cfg.value || ''
    }

    if (cfg.valueType && cfg.system === true) {
      cfg.valueType += '-system'
    }

    const config = {
      listStores: cfg.listStores,
      matcher: cfg.operator,
      matcherValue: value,
      system: cfg.system,
      valueType: cfg.valueType,

      steps: [
        {
          width: 80,
          operatorWidth: 125,
          defaultCondition: cfg.condition,

          conditions: [
            [
              {
                name: 'content',
                label: t('Content'),

                matchers: [
                  {
                    matcher: 'contains',
                    name: t('contains'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'content contains {0}'
                  },
                  {
                    matcher: 'does-not-contain',
                    name: t('does not contain'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'content does not contain {0}'
                  }
                ]
              },
              {
                name: 'subject',
                label: t('Subject'),

                matchers: [
                  {
                    matcher: 'contains',
                    name: t('contains'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'subject contains {0}'
                  },
                  {
                    matcher: 'does-not-contain',
                    name: t('does not contain'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'subject does not contain {0}'
                  },
                  {
                    matcher: 'is',
                    name: t('matches'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'subject is {0}'
                  }
                ]
              }
            ]
          ]
        }
      ]
    }

    this.callParent([config])
  }
})

module.exports = Message
