import { DateTime } from 'luxon'
import isString from 'strings/isString'

const DEFAULT_FORMATS = {
  default: 'yy/MM/dd',
  year: 'yyyy',
  month: 'MMM yyyy',
  day: 'dd MMM',
  hour: 'dd MMM HH:mm',
  minute: 'dd MMM HH:mm',
  second: 'dd MMM HH:mm:ss'
}

// when no timezone is given it will pick browser's timezone automatically
export const parse = function (anything, timeZoneName = null) {
  if (!anything) return
  if (anything.isLuxonDateTime) return anything

  // beware this neglects unix times in seconds so they always
  // should be in milliseconds
  if (Number.isInteger(anything)) {
    return DateTime.fromMillis(anything, { zone: timeZoneName })
  }

  if (anything instanceof Date) {
    return DateTime.fromJSDate(anything, { zone: timeZoneName })
  }

  return DateTime.fromISO(anything, { zone: timeZoneName })
}

export const rezone = function (anything, timeZoneName = null, cfg = {}) {
  const dt = parse(anything)

  // when not string skip invalid arguments
  // coming from ExtJS renderers with lots of crap
  if (dt && timeZoneName && isString(timeZoneName)) {
    return dt.setZone(timeZoneName, cfg)
  }

  return dt
}

export const toString = function (anything, timeZoneName = null, cfg = {}) {
  const dt = rezone(anything, timeZoneName)

  if (!dt) return

  const formats = cfg.formats || DEFAULT_FORMATS

  let dateTimeFormat

  if (cfg.format && typeof cfg.format.valueOf() === 'string') {
    if (cfg.format in formats) {
      dateTimeFormat = formats[cfg.format]
    } else {
      dateTimeFormat = cfg.format
    }
  } else {
    dateTimeFormat = String.format('yyyy/MM/dd HH:mm{0}', cfg.includeSeconds ? ':ss' : '')
  }

  return dt.toFormat(dateTimeFormat)
}

export const toDateString = function (anything, timeZoneName = null, cfg = {}) {
  cfg.format = 'yyyy/MM/dd'
  return toString(anything, timeZoneName, cfg)
}

export const toJS = function (anything) {
  return parse(anything)?.toJSDate()
}
