const Ext = require('ext')
const t = require('translate')
const CompanyListPanel = require('admin/view/CompanyListPanel')
const User = require('admin/core/User')

const CustomerListPanel = Ext.define(null, {
  extend: CompanyListPanel,

  cls: 'customer-list',
  tabCls: 'customer-list-tab',

  module() {
    return require('admin/business/CustomerResource')
  },

  createColumnModel() {
    let columns = []

    if (User.isTechnicalAdmin()) {
      columns.push({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }

    columns = columns.concat([
      {
        id: 'name',
        header: t('Customer Name'),
        width: 200,
        sortable: true,
        dataIndex: 'displayName'
      },
      {
        id: 'type',
        header: t('Type'),
        width: 150,
        sortable: true,
        dataIndex: 'customerTypeName'
      },
      {
        id: 'billTo',
        header: t('Bill To'),
        width: 140,
        sortable: true,
        dataIndex: 'billToList'
      },
      {
        id: 'externalId',
        header: t('Customer No.'),
        width: 90,
        sortable: true,
        dataIndex: 'externalId'
      }
    ])

    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = CustomerListPanel
