import React, { Component } from 'react'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Grid from 'components/Grid'
import PropTypes from 'prop-types'
import { refresh, updateNotifications } from 'quarantine/notification/actions'
import {
  administratorForm,
  adminQuarantineDigest,
  disableForm,
  endUserForm,
  uri,
  userQuarantineDigest
} from 'quarantine/notification/subs'
import AdministratorForm, {
  FORM_NAME as ADMINISTRATOR_FORM_NAME
} from 'quarantine/notification/views/form/Administrator'
import EndUserForm, {
  FORM_NAME as END_USER_FORM_NAME
} from 'quarantine/notification/views/form/EndUser'
import { Form } from 'react-bootstrap'
import { connect } from 'react-redux'

class NotificationTab extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    showAdminSection: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number
  }

  constructor(props) {
    super(props)

    this.endUserFormRef = React.createRef()
    this.adminFormRef = React.createRef()
  }

  componentDidMount() {
    this.props.refresh(this.props.uri)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const notifications = [
      this.props.endUserForm.values,
      this.props.administratorForm.values
    ]

    this.props.updateNotifications(this.props.uri, notifications)
  }

  onCancel = () => {
    this.endUserFormRef?.current?.reset()
    this.adminFormRef?.current?.reset()
  }

  disableSubmit = () => {
    // for their meanings see http://redux-form.com/6.8.0/docs/api/Props.md/
    const endUserFormInvalid = this.endUserFormRef?.current?.invalid
    const adminUserFormInvalid = this.adminFormRef?.current?.invalid
    return Boolean(this.props.disableForm || endUserFormInvalid || adminUserFormInvalid)
  }

  showAdminSection = () => {
    return this.props.showAdminSection
  }

  render() {
    return (
      <div className='tab-content notification-panel'>
        <Form horizontal onSubmit={this.handleSubmit} disabled={this.props.disableForm}>
          <Grid>
            <EndUserForm
              form={[this.props.companyKey, END_USER_FORM_NAME].join('.')}
              companyKey={this.props.companyKey}
              ref={this.endUserFormRef}
              // TODO support multiple notifications (array) in future
              notification={this.props.userQuarantineDigest}
            />
            <AdministratorForm
              form={[this.props.companyKey, ADMINISTRATOR_FORM_NAME].join('.')}
              companyKey={this.props.companyKey}
              ref={this.adminFormRef}
              // TODO support multiple notifications (array) future
              notification={this.props.adminQuarantineDigest}
              showAdminSection={this.showAdminSection()}
            />
          </Grid>
          <Grid className='footer'>
            <CancelSubmitButtons
              colWidth={8}
              disableCancel={this.props.disableForm}
              disableSubmit={this.disableSubmit()}
              onCancel={this.onCancel}
              submitText='Save'
              cancelText='Reset'
            />
          </Grid>
        </Form>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  uri: uri(db, companyKey),
  administratorForm: administratorForm(db, companyKey),
  endUserForm: endUserForm(db, companyKey),
  disableForm: disableForm(db, companyKey),
  userQuarantineDigest: userQuarantineDigest(db, companyKey),
  adminQuarantineDigest: adminQuarantineDigest(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  refresh: (uri) => dispatch(refresh(companyKey, uri)),
  updateNotifications: (uri, notifications) =>
    dispatch(updateNotifications(companyKey, uri, notifications))
})

export default connect(mapDbToProps, mapActionsToProps)(NotificationTab)
