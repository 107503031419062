const Ext = require('ext')
const t = require('translate')
const ItemGridPanel = require('admin/component/grid/ItemGridPanel').default
const escape = require('html-escaper').escape

const TemplateList = Ext.define(null, {
  extend: ItemGridPanel,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      addLabel: t('New'),
      addTooltip: t('Add new template'),
      cls: 'template-list',
      columns: [
        {
          dataIndex: 'name',
          editor: {
            allowBlank: false,
            validator: this.validate.createDelegate(this),
            xtype: 'textfield'
          },
          header: t('Templates'),
          id: 'name',
          menuDisabled: true,
          resizable: false,
          sortable: false,
          width: 100
        }
      ],
      deleteLabel: t('Delete'),
      deleteTooltip: t('Delete the selected template'),
      reorderable: false
    })
    this.callParent([cfg])
  },

  validate(value) {
    if (value) {
      const lowerCasedValue = value.toLowerCase()
      // returns true if that name does not exist in another template (= is unique)
      // since SCL-2283
      return (
        this.store.findBy(
          (record) => record.get('name').toLowerCase() === lowerCasedValue
        ) < 0
      )
    }
    return false
  },

  async addItem() {
    this.editor.stopEditing(false)

    const RecordClass = this.store.record

    const record = new RecordClass({
      id: -1,
      name: ''
    })

    await this.store.add(record)

    const index = this.store.indexOf(record)
    this.getSelectionModel().selectRow(index)
    await this.editor.startEditing(index, 0)
  },

  deleteItem() {
    if (this.getSelectionModel().hasSelection()) {
      const record = this.getSelectionModel().getSelected()
      const title = t('Delete Template')
      const body = t("Are you sure you want to delete the '{name}' template?", {
        name: escape(record.get('name'))
      })
      Ext.MessageBox.confirm(
        title,
        body,
        function (result) {
          if (result === 'yes') {
            Ext.MessageBox.wait(t('Saving...'))
            record.destroy({
              complete() {
                Ext.MessageBox.hide()
              }
            })
          }
        },
        this
      )
    }
  }
})

module.exports = TemplateList
