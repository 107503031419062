import React from 'react'
import ReactDOM from 'react-dom'

import Ext from 'ext'
import logger from 'system/logger'

const ReactContainerPanel = Ext.define(null, {
  extend: Ext.BoxComponent,
  component: null,

  initComponent: function () {
    this.resizeRenderTimeoutId = null

    // always true for the first run of onResize
    this.resizeMounting = true

    this.addClass('Bs')

    this.getParentInstance().initComponent.call(this)
  },

  getParentInstance: function () {
    return ReactContainerPanel.superclass
  },

  // can be overriden in subclasses, i.E. for redux which will surround
  // it with a provider + store to maintain state
  getReactElement: function () {
    return this.component
  },

  getDom: function () {
    const el = this.getEl()

    return el ? el.dom : null
  },

  // pass on new dimensions to the component for internal calculations,
  // regardless whether they need it or now. some calculate new heights,
  // based on these, see Archiving.es for example.
  resizeComponent: function (newWidth, newHeight) {
    this.component = React.cloneElement(this.component, {
      width: newWidth,
      height: newHeight
    })
  },

  renderReactElement: function (reactElement, delay = 0) {
    const dom = this.getDom()

    if (dom) {
      this.resizeRenderTimeoutId = setTimeout(function () {
        try {
          ReactDOM.render(reactElement, dom)
        } catch (err) {
          logger.warn(err)
        }
      }, delay)

      return true
    }

    return false
  },

  // TODO: when in single user mode without the explorer
  // figure out a different mechanism to compute width/height
  //
  // TODO: extjs layout mechanism is very strict and relies on calculated dimensions,
  // and that's not fluid. doesn't get on well with react, grrrr. either get rid of extjs
  // or hack extjs' internals.
  onResize: function (newWidth, newHeight) {
    if (
      newWidth === this.component.props.width &&
      newHeight === this.component.props.height
    ) {
      // no need to resize anything if already the same
      return false
    }

    this.resizeComponent(newWidth, newHeight)

    if (this.resizeMounting) {
      if (this.renderReactElement(this.getReactElement())) {
        this.resizeMounting = false // done with first run
      }
    } else {
      // not the first run, i.e. user is actually resizing things
      // in that case, delay rendering by 100ms so the dimensions are not
      // calculated prematurely. otherwise the size of content components
      // can vary arbitrarily (usually too small)
      this.renderReactElement(this.getReactElement(), 100)
    }
  },

  // called for hot module replacing and for when destroying
  unmount: function () {
    clearTimeout(this.resizeRenderTimeoutId)

    const dom = this.getDom()
    dom && ReactDOM.unmountComponentAtNode(dom)

    this.getParentInstance().destroy.call(this)
  },

  // reason we just delegate this to unmount is because
  // in redux container panels we have a different logic for destroying
  // e.g. we trigger actions there. but when replacing during HMR we do not
  // want to trigger those action to keep state. hence the need for separation
  // between destroy and unmount.
  destroy: function () {
    this.unmount()
  }
})

export default ReactContainerPanel
