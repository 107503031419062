const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ReadOnlyDateField = require('admin/component/form/ReadOnlyDateField')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const ReadOnlyTextArea = require('admin/component/form/ReadOnlyTextArea')

const NoteDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'note-detail-panel',
  closeOnSave: false,

  module() {
    return require('admin/business/NoteResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    return [
      new ReadOnlyDateField({
        fieldLabel: t('Date'),
        locked: true,
        name: 'noteDate',
        format: 'Y/m/d H:i',
        width: 333
      }),
      new ReadOnlyTextField({
        fieldLabel: t('Type'),
        locked: true,
        name: 'noteTypeName',
        width: 350
      }),
      new ReadOnlyTextField({
        fieldLabel: t('User'),
        locked: true,
        name: 'party',
        width: 350
      }),
      {
        fieldLabel: t('Summary'),
        name: 'noteName',
        maxLength: 2147483647,
        allowBlank: false,
        xtype: 'textfield',
        width: 350
      },
      {
        fieldLabel: t('Detail'),
        name: 'noteInfo',
        maxLength: 2147483647,
        xtype: 'textarea',
        width: 350
      }
    ]
  },

  getViewPanel(cfg) {
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      items: this.getViewItems(cfg)
    })
  },

  getViewItems() {
    return [
      new ReadOnlyDateField({
        fieldLabel: t('Date'),
        locked: true,
        name: 'noteDate',
        format: Date.patterns.SHORT,
        value: new Date(),
        width: 350
      }),
      new ReadOnlyTextField({
        locked: true,
        fieldLabel: t('Type'),
        name: 'noteTypeName',
        width: 350
      }),
      new ReadOnlyTextField({
        locked: true,
        fieldLabel: t('User'),
        name: 'party',
        width: 350
      }),
      new ReadOnlyTextArea({
        fieldLabel: t('Summary'),
        locked: true,
        name: 'noteName',
        width: 350
      }),
      new ReadOnlyTextArea({
        locked: true,
        fieldLabel: t('Detail'),
        name: 'noteInfo',
        width: 350
      })
    ]
  }
})

module.exports = NoteDetailPanel
