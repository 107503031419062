const Ext = require('ext')
const TabPanel = require('admin/view/TabPanel').default

const GroupTabPanel = Ext.define(null, {
  extend: TabPanel,

  baseCls: 'n-group-panel'
})

module.exports = GroupTabPanel
