const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')

const DeleteMessage = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg) {
    cfg = Ext.applyIf(cfg || {}, {
      label: t('Do not deliver message'),
      type: 'smartrules-delete-message'
    })
    this.callParent([cfg])
  },

  toDsl() {
    return 'delete the message'
  }
})

module.exports = DeleteMessage
