import * as Calendar from 'admin/time/Calendar'
import { getDefaultTimeZoneName } from 'admin/time/TimeZone'
import Api from 'api'
import * as File from 'api/file'
import * as hal from 'hal'
import {
  ARCHIVING_ARCHIVED_MESSAGE_REL,
  ARCHIVING_DOWNLOAD_REL,
  ARCHIVING_MESSAGE_REL,
  ARCHIVING_PREVIEW_REL
} from 'product/resources'
import logger from 'system/logger'
import { parseTemplate } from 'url-template'

import { toMailSearchResults } from './converters'

export const ARCHIVING_MESSAGES_CLEAR_AUTHORIZATION_COUNT =
  'ARCHIVING_MESSAGES_CLEAR_AUTHORIZATION_COUNT'
export const ARCHIVING_MESSAGES_CLEAR_DOWNLOAD_URI_TEMPLATE =
  'ARCHIVING_MESSAGES_CLEAR_DOWNLOAD_URI_TEMPLATE'
export const ARCHIVING_MESSAGES_CLEAR_NEXT_LINK = 'ARCHIVING_MESSAGES_CLEAR_NEXT_LINK'
export const ARCHIVING_MESSAGES_CLEAR_PREVIEW = 'ARCHIVING_MESSAGES_CLEAR_PREVIEW'
export const ARCHIVING_MESSAGES_CLEAR_RESULTS = 'ARCHIVING_MESSAGES_CLEAR_RESULTS'
export const ARCHIVING_MESSAGES_CLEAR_SCROLL_TO_INDEX =
  'ARCHIVING_MESSAGES_CLEAR_SCROLL_TO_INDEX'
export const ARCHIVING_MESSAGES_CLEAR_SELECTION = 'ARCHIVING_MESSAGES_CLEAR_SELECTION'
export const ARCHIVING_MESSAGES_CLEAR_SID = 'ARCHIVING_MESSAGES_CLEAR_SID'
export const ARCHIVING_MESSAGES_CLEAR_SOURCE = 'ARCHIVING_MESSAGES_CLEAR_SOURCE'
export const ARCHIVING_MESSAGES_CONCAT_RESULTS = 'ARCHIVING_MESSAGES_CONCAT_RESULTS'
export const ARCHIVING_MESSAGES_ENABLE_HIGHLIGHTING =
  'ARCHIVING_MESSAGES_ENABLE_HIGHLIGHTING'
export const ARCHIVING_MESSAGES_INIT_DB = 'ARCHIVING_MESSAGES_INIT_DB'
export const ARCHIVING_MESSAGES_SELECT_ROWS = 'ARCHIVING_MESSAGES_SELECT_ROWS'
export const ARCHIVING_MESSAGES_SET_ARCHIVING_URI = 'ARCHIVING_MESSAGES_SET_ARCHIVING_URI'
export const ARCHIVING_MESSAGES_SET_AUTHORIZATION_COUNT =
  'ARCHIVING_MESSAGES_SET_AUTHORIZATION_COUNT'
export const ARCHIVING_MESSAGES_SET_AUTHORIZATION_TIMESTAMP =
  'ARCHIVING_MESSAGES_SET_AUTHORIZATION_TIMESTAMP'
export const ARCHIVING_MESSAGES_SET_COLUMN_WIDTH = 'ARCHIVING_MESSAGES_SET_COLUMN_WIDTH'
export const ARCHIVING_MESSAGES_SET_DATE_RANGE = 'ARCHIVING_MESSAGES_SET_DATE_RANGE'
export const ARCHIVING_MESSAGES_SET_DATE_RANGE_SHOWN =
  'ARCHIVING_MESSAGES_SET_DATE_RANGE_SHOWN'
export const ARCHIVING_MESSAGES_SET_DATE_RANGE_HIDDEN =
  'ARCHIVING_MESSAGES_SET_DATE_RANGE_HIDDEN'
export const ARCHIVING_MESSAGES_SET_DOWNLOAD_URI_TEMPLATE =
  'ARCHIVING_MESSAGES_SET_DOWNLOAD_URI_TEMPLATE'
export const ARCHIVING_MESSAGES_SET_NEXT_LINK = 'ARCHIVING_MESSAGES_SET_NEXT_LINK'
export const ARCHIVING_MESSAGES_SET_OFFSET = 'ARCHIVING_MESSAGES_SET_OFFSET'
export const ARCHIVING_MESSAGES_SET_PREVIEW = 'ARCHIVING_MESSAGES_SET_PREVIEW'
export const ARCHIVING_MESSAGES_SET_QUERY = 'ARCHIVING_MESSAGES_SET_QUERY'
export const ARCHIVING_MESSAGES_SET_RESULTS = 'ARCHIVING_MESSAGES_SET_RESULTS'
export const ARCHIVING_MESSAGES_SET_RESULTS_QUERY = 'ARCHIVING_MESSAGES_SET_RESULTS_QUERY'
export const ARCHIVING_MESSAGES_SET_SCROLL_TO_INDEX =
  'ARCHIVING_MESSAGES_SET_SCROLL_TO_INDEX'
export const ARCHIVING_MESSAGES_SET_SID = 'ARCHIVING_MESSAGES_SET_SID,'
export const ARCHIVING_MESSAGES_SET_SOURCE = 'ARCHIVING_MESSAGES_SET_SOURCE'
export const ARCHIVING_MESSAGES_SET_TIME_ZONE_KEY = 'ARCHIVING_MESSAGES_SET_TIME_ZONE_KEY'
export const ARCHIVING_MESSAGES_START_LOADING = 'ARCHIVING_MESSAGES_START_LOADING'
export const ARCHIVING_MESSAGES_STOP_LOADING = 'ARCHIVING_MESSAGES_STOP_LOADING'
export const ARCHIVING_MESSAGES_TOGGLE_ATTACHMENT_PANEL =
  'ARCHIVING_MESSAGES_TOGGLE_ATTACHMENT_PANEL'

const startLoading = (companyKey) => ({
  type: ARCHIVING_MESSAGES_START_LOADING,
  companyKey
})
const stopLoading = (companyKey) => ({
  type: ARCHIVING_MESSAGES_STOP_LOADING,
  companyKey
})

/// ========= INIT ========= ///

const setArchivingUri = (companyKey, archivingUri) => ({
  type: ARCHIVING_MESSAGES_SET_ARCHIVING_URI,
  companyKey,
  archivingUri
})

const initDb = (companyKey) => ({
  type: ARCHIVING_MESSAGES_INIT_DB,
  companyKey
})

export const setCompany = (companyKey, archivingUri) => (dispatch) => {
  dispatch(initDb(companyKey))
  dispatch(setArchivingUri(companyKey, archivingUri))
  dispatch(setTimeZoneName(companyKey, getDefaultTimeZoneName()))

  const fromDate = Calendar.atMidnight().minus({ months: 1 }).toUTC().toISO()
  const thruDate = Calendar.atMidnight().toUTC().toISO()

  dispatch(setDateRange(companyKey, fromDate, thruDate))
}

/// ========= SEARCH PARAMETERS ========= ///

export const setTimeZoneName = (companyKey, timeZoneName) => ({
  type: ARCHIVING_MESSAGES_SET_TIME_ZONE_KEY,
  companyKey,
  timeZoneName
})

export const setQuery = (companyKey, query) => ({
  type: ARCHIVING_MESSAGES_SET_QUERY,
  companyKey,
  query
})

export const setDateRange = (companyKey, fromDate, thruDate) => ({
  type: ARCHIVING_MESSAGES_SET_DATE_RANGE,
  companyKey,
  fromDate,
  thruDate
})

export const setDateRangeShown = (companyKey) => ({
  type: ARCHIVING_MESSAGES_SET_DATE_RANGE_SHOWN,
  companyKey
})

export const setDateRangeHidden = (companyKey) => ({
  type: ARCHIVING_MESSAGES_SET_DATE_RANGE_HIDDEN,
  companyKey
})

/// ========= AUTHORISATION stuff ========= ///

const setAuthorizationCount = (companyKey, count) => ({
  type: ARCHIVING_MESSAGES_SET_AUTHORIZATION_COUNT,
  companyKey,
  count
})

// public for unit tests
export const clearAuthorizationCount = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_AUTHORIZATION_COUNT,
  companyKey
})

const setAuthorizationTimestamp = (companyKey, timestamp) => ({
  type: ARCHIVING_MESSAGES_SET_AUTHORIZATION_TIMESTAMP,
  companyKey,
  timestamp
})

export const authorizeOnce = (companyKey) => (dispatch) => {
  dispatch(setAuthorizationCount(companyKey, 1))
}

export const authorizeSession = (companyKey) => (dispatch) => {
  dispatch(setAuthorizationTimestamp(companyKey, Date.now()))
}

/// ========= SEARCH/RESULTS/PAGINATION ========= ///

const clearResults = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_RESULTS,
  companyKey
})

export const setResults = (companyKey, results) => ({
  type: ARCHIVING_MESSAGES_SET_RESULTS,
  companyKey,
  results
})

export const setResultsQuery = (companyKey, query) => ({
  type: ARCHIVING_MESSAGES_SET_RESULTS_QUERY,
  companyKey,
  query
})

const clearDownloadUriTemplate = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_DOWNLOAD_URI_TEMPLATE,
  companyKey
})

// public for unit tests
export const setDownloadUriTemplate = (companyKey, downloadUriTemplate) => ({
  type: ARCHIVING_MESSAGES_SET_DOWNLOAD_URI_TEMPLATE,
  companyKey,
  downloadUriTemplate
})

export const concatResults = (companyKey, moreResults) => ({
  type: ARCHIVING_MESSAGES_CONCAT_RESULTS,
  companyKey,
  moreResults
})

const setNextLink = (companyKey, nextLink) => ({
  type: ARCHIVING_MESSAGES_SET_NEXT_LINK,
  companyKey,
  nextLink
})

const clearNextLink = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_NEXT_LINK,
  companyKey
})

// public only for testability
export const setOffset = (companyKey, offset) => ({
  type: ARCHIVING_MESSAGES_SET_OFFSET,
  companyKey,
  offset
})

export const search =
  (companyKey, searchUri, viewing, parameters, timeZoneName) => async (dispatch) => {
    dispatch(startLoading(companyKey))

    const { query } = parameters

    // always set back to zero when running a new search
    const offset = 0

    dispatch(setOffset(companyKey, offset))

    try {
      // create a search resource first
      const response = await Api.post(searchUri, parameters, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })

      let results = hal.resource(response.data, ARCHIVING_MESSAGE_REL)
      let nextLink = hal.next(response.data)

      const downloadUriTemplate = hal.href(response.data, ARCHIVING_DOWNLOAD_REL)

      if (nextLink) {
        const nextResponse = await Api.post(nextLink)

        results = [...results, ...hal.resource(nextResponse.data, ARCHIVING_MESSAGE_REL)]
        nextLink = hal.next(nextResponse.data)
      }

      // set the query first before results to ensure table wont get rendered twice
      // because it will be shown only when results are set but results query can retrigger a render
      dispatch(setResultsQuery(companyKey, query))
      dispatch(setResults(companyKey, toMailSearchResults(results, { timeZoneName })))

      dispatch(setScrollToIndex(companyKey, 0))
      dispatch(setNextLink(companyKey, nextLink))
      dispatch(setDownloadUriTemplate(companyKey, downloadUriTemplate))
    } catch (err) {
      // remove any existing states to reflect error state
      dispatch(clearResults(companyKey))
      dispatch(clearDownloadUriTemplate(companyKey))
      dispatch(clearNextLink(companyKey))
      logger.error(err)
    } finally {
      if (viewing) {
        dispatch(back(companyKey)) // in case user was viewing before
      }

      dispatch(stopLoading(companyKey))
    }
  }

// technically this is prefetch.
// it behaves a bit differently when paginating a whole table or
// when viewing one entry.
export const next = (companyKey, params) => async (dispatch) => {
  const {
    timeZoneName,
    viewing,
    nextMail,
    nextLink,
    nextOffset,
    nextRelativeIndex,
    paginateNext,
    prefetch
  } = params

  if (viewing) {
    dispatch(clearAuthorizationCount(companyKey))
    await dispatch(view(companyKey, nextMail, nextRelativeIndex))
  }

  if (paginateNext) {
    // set offset to real offset increased by one limit
    dispatch(setOffset(companyKey, nextOffset))
  }

  if (!viewing) {
    dispatch(clearSelection(companyKey))
  }

  if (!prefetch) return

  dispatch(startLoading(companyKey))

  try {
    const response = await Api.post(nextLink)
    const moreResults = toMailSearchResults(
      hal.resource(response.data, ARCHIVING_MESSAGE_REL),
      {
        timeZoneName
      }
    )
    const nextNextLink = hal.next(response.data)

    dispatch(concatResults(companyKey, moreResults))
    dispatch(setNextLink(companyKey, nextNextLink))

    if (paginateNext && !viewing) {
      dispatch(setScrollToIndex(companyKey, 0))
    }
  } catch (err) {
    logger.error(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

// prev is always from cache
export const prev = (companyKey, params) => async (dispatch) => {
  const { viewing, prevIndex, prevMail, offset, limit } = params

  if (viewing) {
    const paginate = prevIndex < offset
    const prevRelativeIndex = paginate ? limit - 1 : prevIndex - offset

    dispatch(clearAuthorizationCount(companyKey))
    await dispatch(view(companyKey, prevMail, prevRelativeIndex))

    if (paginate) {
      dispatch(setOffset(companyKey, offset - limit))
    }
  } else {
    dispatch(setScrollToIndex(companyKey, 0))
    dispatch(setOffset(companyKey, offset - limit))
  }

  dispatch(clearSelection(companyKey))
}

export const setColumnWidth = (companyKey, columnKey, width) => ({
  type: ARCHIVING_MESSAGES_SET_COLUMN_WIDTH,
  companyKey,
  columnKey,
  width
})

/// ========= TABLE actions ========= ///

const clearScrollToIndex = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_SCROLL_TO_INDEX,
  companyKey
})

// public for unit tests
export const setScrollToIndex = (companyKey, scrollToIndex) => ({
  type: ARCHIVING_MESSAGES_SET_SCROLL_TO_INDEX,
  companyKey,
  scrollToIndex
})

const clearSelection = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_SELECTION,
  companyKey
})

const selectRows = (companyKey, rows, selected) => ({
  type: ARCHIVING_MESSAGES_SELECT_ROWS,
  companyKey,
  rows,
  selected
})

export const select = (companyKey, rows, selected) => (dispatch) => {
  dispatch(clearScrollToIndex(companyKey)) // ensures that table doesn't jump
  dispatch(selectRows(companyKey, rows, selected))
}

const setPreview = (companyKey, preview) => ({
  type: ARCHIVING_MESSAGES_SET_PREVIEW,
  companyKey,
  preview
})

const clearPreview = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_PREVIEW,
  companyKey
})

export const view = (companyKey, mail, relativeIndex) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const previewUri = hal.href(mail, ARCHIVING_PREVIEW_REL)
    let previewResponse

    if (previewUri) {
      previewResponse = await Api.get(previewUri)
    }

    // only set both at the end to ensure both appear at the same time
    dispatch(setSid(companyKey, mail.sid))
    if (previewResponse) {
      dispatch(setPreview(companyKey, previewResponse.data))
    }
  } catch (err) {
    logger.error(err)
  } finally {
    // necessary so that table's viewport scrolls to that row when going back
    dispatch(setScrollToIndex(companyKey, relativeIndex))

    dispatch(stopLoading(companyKey))
  }
}

export const back = (companyKey) => (dispatch) => {
  dispatch(clearPreview(companyKey))
  dispatch(clearSid(companyKey))
  dispatch(clearAuthorizationCount(companyKey))
}

/// ========= DETAIL VIEW actions ========= ///

export const toggleAttachmentPanel = (companyKey, expanded) => ({
  type: ARCHIVING_MESSAGES_TOGGLE_ATTACHMENT_PANEL,
  companyKey,
  expanded
})

export const setSid = (companyKey, sid) => ({
  type: ARCHIVING_MESSAGES_SET_SID,
  companyKey,
  sid
})

export const clearSid = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_SID,
  companyKey
})

/// ========= TOOLBAR actions ========= ///

export const enableHighlighting = (companyKey, enabled) => ({
  type: ARCHIVING_MESSAGES_ENABLE_HIGHLIGHTING,
  companyKey,
  enabled
})

const setSource = (companyKey, source) => ({
  type: ARCHIVING_MESSAGES_SET_SOURCE,
  companyKey,
  source
})

export const viewSource = (companyKey, rows) => async (dispatch) => {
  if (rows.length === 1) {
    dispatch(startLoading(companyKey))

    try {
      const mail = rows[0]

      const downloadUri = hal.href(mail, ARCHIVING_ARCHIVED_MESSAGE_REL)
      const response = await Api.get(downloadUri)

      dispatch(setSource(companyKey, response.data))
    } catch (err) {
      logger.error(err)
    } finally {
      dispatch(stopLoading(companyKey))
    }
  }
}

export const hideSource = (companyKey) => ({
  type: ARCHIVING_MESSAGES_CLEAR_SOURCE,
  companyKey
})

export const download = (downloadParams) => () => {
  let url
  let name
  let ext

  const { downloadUriTemplate, mail, selectedSids } = downloadParams
  if (mail) {
    // todo https://youtrack.smxemail.com/issue/SCL-2767
    url = hal.href(mail, ARCHIVING_ARCHIVED_MESSAGE_REL)
    name = mail.sid
    ext = 'eml'
  } else {
    // compose correct url for zip download based on url template which can look like
    // {href: "(removed)/customer/2000074/archiving/download{?fromDate,thruDate,query,sid*}", templated: true}

    const parsedDownloadUriTemplate = parseTemplate(downloadUriTemplate)
    url = parsedDownloadUriTemplate.expand({
      sid: selectedSids
    })
  }

  if (url) {
    return File.download(url, name, ext)
  }
}
