import React from 'react'
import { Checkbox, Col, FormGroup } from 'react-bootstrap'
import PropTypes from 'prop-types'

class MemberItem extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  }

  state = {
    enabled: null
  }

  isEnabled = () => {
    if (this.state.enabled != null) {
      return this.state.enabled
    }

    return this.props.enabled
  }

  // that's a cool react trick on how to adjust a state when props are about to change
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.enabled === !this.state.enabled) {
      this.setState({ enabled: nextProps.enabled })
    }
  }

  onMemberCheck = (e) => {
    this.setState({ enabled: e.target.checked })
    this.props.onChange(this.props.name, e.target.checked)
  }

  renderCheckbox = () => {
    return (
      <Checkbox
        checked={this.isEnabled()}
        type='checkbox'
        disabled={this.props.disabled}
        name={this.props.name}
        onChange={this.onMemberCheck}
      >
        {this.props.name}
      </Checkbox>
    )
  }

  render() {
    return (
      <li>
        <FormGroup>
          <Col sm={12}>{this.renderCheckbox()}</Col>
        </FormGroup>
      </li>
    )
  }
}

export default MemberItem
