import logger from 'system/logger'
import t from 'translate'

import formatMoney from './format'

// centralized since https://youtrack.smxemail.com/issue/SCL-2302

const BAD_PARAM_HINT = '[error]'

function buyPrice(priceAmount, priceCurrencyUom) {
  if (typeof priceAmount !== 'number') {
    logger.warn(`Invalid price amount for pricing model: ${priceAmount}`)
    return BAD_PARAM_HINT
  }

  return t('{amount} Buy Price', {
    amount: formatMoney(priceCurrencyUom, priceAmount)
  })
}

function rrp(commission) {
  if (typeof commission !== 'number') {
    logger.warn(`Invalid commission for pricing model: ${commission}`)
    return BAD_PARAM_HINT
  }

  return t('{commission}% off RRP', { commission })
}

export default (agreementType, commission, priceAmount, priceCurrencyUom) => {
  let toString

  if (agreementType === 'WHOLESALE_RESELLER' && priceCurrencyUom && priceAmount != null) {
    toString = buyPrice.bind(null, priceAmount, priceCurrencyUom)
  } else if (commission != null && commission !== false) {
    toString = rrp.bind(null, commission)
  } else {
    toString = function () {
      return ''
    }
  }

  return {
    toString
  }
}
