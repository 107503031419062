import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import SelectableTable from 'components/table/SelectableTable'

class CheckableTable extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.tableRef = React.createRef()
  }

  getVisibleRows() {
    // TODO this is not good, reference to table does not exist when
    // rendered for first time but for now it is not a problem since
    // we do not have any selections for initial states
    if (this.tableRef.current && this.tableRef.current.getResults) {
      return this.tableRef.current.getResults()
    }

    return this.props.results
  }

  onSelectAllClick = (e) => {
    this.props.onSelect(this.getVisibleRows(), e.target.checked)
  }

  areAllSelected = () => {
    const visibleRows = this.getVisibleRows()
    return Boolean(visibleRows && visibleRows.every((r) => r.selected))
  }

  renderSelectAll = () => {
    return (
      <input
        type='checkbox'
        checked={this.areAllSelected()}
        onChange={this.onSelectAllClick}
      />
    )
  }

  // eslint-disable-next-line default-param-last
  renderSelect = (cellData = false, columnName, rowData) => {
    return (
      <input
        type='checkbox'
        checked={cellData}
        onClick={(e) => e.stopPropagation()}
        onChange={() => this.props.onSelect([rowData], !cellData)}
      />
    )
  }

  render() {
    this.props.columns.unshift({
      name: 'selected',
      width: 26,
      align: 'center',
      renderHeader: this.renderSelectAll,
      renderCell: this.renderSelect
    })

    return <SelectableTable ref={this.tableRef} {...this.props} />
  }
}

export default CheckableTable
