import React, { Component } from 'react'

import Help from 'continuity/help/views/Tab'
import PropTypes from 'prop-types'
import Search from 'continuity/messages/views/Tab'
import { Tab } from 'react-bootstrap'
import Tabs from 'components/Tabs'
import t from 'translate'

const BOOTSTRAP_PADDING = 5

class ContinuityTabs extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    archivingUrl: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number
  }

  // we need to forward the height and sometimes the width to all tabs,
  // this to ensure proper scrollbar functionality. unfortunately.
  // this to ensure scrollbars appear when the above extjs window
  // gets resized, becomes smaller than the required area.
  render() {
    return (
      <Tabs
        tabsId='continuity-tabs'
        defaultActiveKey={1}
        width={this.props.width}
        height={this.props.height}
      >
        <Tab
          eventKey={1}
          title={t('Search')}
          style={{
            height: this.props.height - 3 * BOOTSTRAP_PADDING,
            overflowY: 'auto'
          }}
        >
          <Search
            companyKey={this.props.companyKey}
            width={this.props.width}
            height={this.props.height}
            store={this.props.store}
          />
        </Tab>

        <Tab
          eventKey={2}
          title={t('Help')}
          style={{
            height: this.props.height - 6 * BOOTSTRAP_PADDING,
            overflowY: 'auto'
          }}
        >
          <Help />
        </Tab>
      </Tabs>
    )
  }
}

export default ContinuityTabs
