const Ext = require('ext')
const t = require('translate')
const validators = require('validators').default
const Matcher = require('smartrules/designer/Matcher')
const SizeCombo = require('smartrules/designer/SizeCombo')
const SmartRule = require('smartrules/designer/SmartRule')

const BetweenMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg = {}) {
    const items = []
    cfg = Ext.applyIf(cfg, {
      items,
      layout: 'hbox'
    })

    const value = cfg.value || {}
    const lower = (value != null ? value.lower : undefined) != null ? value.lower : ''
    const upper = (value != null ? value.upper : undefined) != null ? value.upper : ''
    const lowerSize = value.lowerUom
    const upperSize = value.upperUom

    this.lower = new Ext.form.TextField({
      allowBlank: false,
      name: 'lower',
      ref: '../lower',
      xtype: 'textfield',
      value: lower,
      validator: validators.positiveInteger.withMsg(t('Please enter a positive number')),
      maskRe: /\d/,
      maxLength: 4,
      width: 55
    })

    this.upper = new Ext.form.TextField({
      allowBlank: false,
      name: 'upper',
      ref: '../upper',
      xtype: 'textfield',
      value: upper,
      validator: validators.isIntegerGreaterThanZero.withMsg(
        t('Please enter a positive number above zero')
      ),
      maskRe: /\d/,
      maxLength: 4,
      width: 55
    })

    const andJoiner = new Ext.Panel({
      border: false,
      items: {
        html: t('and'),
        xtype: 'displayfield'
      }
    })

    if (cfg.forSize) {
      this.upperSizeCombo = new SizeCombo({
        name: 'upperSize',
        ref: 'upperSizeCombo',
        value: upperSize
      })

      this.lowerSizeCombo = new SizeCombo({
        name: 'lowerSize',
        ref: 'lowerSizeCombo',
        value: lowerSize
      })

      items.push(
        this.lower,
        this.lowerSizeCombo,
        {
          margins: '2 15 0 0',
          items: andJoiner
        },
        this.upper,
        this.upperSizeCombo
      )
    } else {
      items.push(
        {
          margins: '0 10 0 0',
          items: this.lower
        },
        andJoiner,
        {
          margins: '0 0 0 10',
          items: this.upper
        }
      )
    }
    this.callParent([cfg])

    this.upper.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )

    this.lower.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
  },

  setValue(v) {
    v = v || {
      upper: '',
      lower: '',
      upperSize: 'kB',
      lowerSize: 'kB'
    }

    this.lower.setValue(v.lower)
    this.upper.setValue(v.upper)

    if (this.forSize) {
      this.lowerSizeCombo.setValue(v.lowerSize)
      return this.upperSizeCombo.setValue(v.upperSize)
    }
  },

  getValue() {
    const value = `is between ${this.lower.getValue()}`

    if (this.forSize) {
      return (
        value +
        `${this.lowerSizeCombo.getValue()} and ${this.upper.getValue()}${this.upperSizeCombo.getValue()}`
      )
    }
    return value + ` and ${this.upper.getValue()}`
  }
})

module.exports = BetweenMatcher
