const Ext = require('ext/ext-base')

Ext.ux.grid.CheckColumn = Ext.extend(Ext.grid.Column, {
  /**
   * @private
   * Process and refire events routed from the GridView's processEvent method.
   */
  processEvent: function (name, e, grid, rowIndex) {
    if (name === 'mousedown' && !this.doNotProcessEvent) {
      const record = grid.store.getAt(rowIndex)
      record.set(this.dataIndex, !record.data[this.dataIndex])
      return false // Cancel row selection.
    }
    return Ext.grid.ActionColumn.superclass.processEvent.apply(this, arguments)
  },

  renderer: function (v, p) {
    p.css += ' x-grid3-check-col-td'

    let inlineStyle

    if (this.align === 'center') {
      inlineStyle = 'background-position: center'
    }

    return String.format(
      '<div class="x-grid3-check-col{0}" style="{1}">&#160;</div>',
      v ? '-on' : '',
      inlineStyle
    )
  },

  // Deprecate use as a plugin. Remove in 4.0
  init: Ext.emptyFn
})

Ext.preg('checkcolumn', Ext.ux.grid.CheckColumn)
Ext.grid.Column.types.checkcolumn = Ext.ux.grid.CheckColumn
