const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const User = require('admin/core/User')

const CompanyListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'name',

  createColumnModel() {
    const columns = [
      {
        id: 'name',
        header: t('Company Name'),
        width: 200,
        sortable: true,
        dataIndex: 'name'
      }
    ]
    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: t('ID'),
        width: 100,
        sortable: true,
        dataIndex: 'id'
      })
    }
    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = CompanyListPanel
