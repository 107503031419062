import * as hal from 'hal'

import { AVAILABLE_MAIL_TARGET_REL, QUARANTINE_SET_REL } from 'product/resources'

import Membership from 'membership/collections/Membership'
import _s from 'underscore.string'
import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('quarantine')
const formDb = (db) => db.form

export const quarantineSets = createSelector(
  company,
  (company) => company && company[QUARANTINE_SET_REL]
)

export const uri = createSelector(quarantineSets, (quarantineSets) => quarantineSets?.uri)

export const list = createSelector(
  quarantineSets,
  (quarantineSets) => quarantineSets?.list
)

export const loading = createSelector(quarantineSets, (quarantineSets) =>
  Boolean(quarantineSets?.loading)
)

export const creating = createSelector(quarantineSets, (quarantineSets) =>
  Boolean(quarantineSets?.creating)
)

export const configTarget = createSelector(
  quarantineSets,
  (quarantineSets) => quarantineSets?.configTarget
)

export const configTargetName = createSelector(
  configTarget,
  (configTarget) => configTarget && configTarget.name
)

export const configTargetEtag = createSelector(
  configTarget,
  (configTarget) => configTarget && configTarget.etag
)

export const configTargetLink = createSelector(configTarget, (configTarget) => {
  return configTarget && hal.self(configTarget)
})

export const selectedQuarantineSet = createSelector(list, (list) =>
  list?.find((quarantineSet) => quarantineSet.selected)
)

export const selectedQuarantineSetLink = createSelector(
  selectedQuarantineSet,
  (selectedQuarantineSet) => {
    return selectedQuarantineSet && hal.self(selectedQuarantineSet)
  }
)

export const selectedQuarantineSetName = createSelector(
  selectedQuarantineSet,
  (selectedQuarantineSet) => selectedQuarantineSet?.name
)

export const configuring = createSelector(configTarget, (configTarget) =>
  Boolean(configTarget)
)

export const selected = createSelector(list, (list) =>
  list?.some((quarantineSet) => quarantineSet.selected)
)

export const size = createSelector(list, (list) => list?.length)

export const hasModalDialog = createSelector(
  creating,
  configuring,
  (creating, configuring) => {
    return creating || configuring
  }
)

export const blocked = createSelector(
  hasModalDialog,
  loading,
  (hasModalDialog, loading) => hasModalDialog || loading
)

export const addButtonDisabled = createSelector(blocked, (blocked) => blocked)

export const configureButtonDisabled = createSelector(
  blocked,
  selected,
  (blocked, selected) => blocked || !selected
)

export const deleteButtonDisabled = createSelector(
  blocked,
  selected,
  (blocked, selected) => blocked || !selected
)

export const refreshButtonDisabled = createSelector(blocked, (blocked) => blocked)

export const formDisabled = createSelector(loading, (loading) => loading)

export const quarantineSetForm = createSelector(
  formDb,
  (formDb) => formDb.quarantineSetForm
)

const availableTriggers = createSelector(quarantineSets, (quarantineSets) => {
  const availableTriggers = quarantineSets?.availableTriggers
  // make a copy with slice before sorting (read only, immutable)
  return availableTriggers?.slice().sort()
})

export const availableTriggersWithLabels = createSelector(
  availableTriggers,
  (triggers) =>
    triggers &&
    triggers.reduce((triggersWithLabels, trigger) => {
      triggersWithLabels[trigger] = _s.capitalize(trigger, true)
      return triggersWithLabels
    }, {})
)

// defaultAvailableTargets are targets any new quarantine set can have
const defaultAvailableTargets = createSelector(
  quarantineSets,
  (quarantineSets) => quarantineSets?.availableTargets
)

export const availableTargets = createSelector(
  defaultAvailableTargets,
  (defaultAvailableTargets) => {
    if (!defaultAvailableTargets) return

    // transform first to match with membership's class internal structure
    return new Membership({
      'mail-targets': defaultAvailableTargets.map((availableTarget) => {
        return {
          enabled: false,
          name: availableTarget.title
        }
      })
    })
  }
)

export const configTargetTargets = createSelector(configTarget, (configTarget) => {
  if (configTarget) {
    const availableTargets = configTarget._links[AVAILABLE_MAIL_TARGET_REL]

    if (!availableTargets) return

    const enabledTargetTitles = configTarget.targets

    const isEnabled = (availableTarget) => {
      return enabledTargetTitles.some((enabledTargetTitle) => {
        return enabledTargetTitle === availableTarget.title
      })
    }

    // transform to match with membership's class internal structure
    const targets = availableTargets.map((availableTarget) => {
      return {
        enabled: isEnabled(availableTarget),
        name: availableTarget.title
      }
    })

    return configTarget && new Membership({ 'mail-targets': targets })
  }
})

export const confirmingDeletion = createSelector(
  quarantineSets,
  (quarantineSets) => quarantineSets?.confirmingDeletion
)

export const names = createSelector(list, (list) => list?.map((target) => target.name))
