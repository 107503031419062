const isObject = require('is-plain-obj')

let TRANSLATIONS = {}

const translate = function (key, context, values) {
  values = values || (isObject(context) || Array.isArray(context) ? context : {})

  context = context && typeof context.valueOf() === 'string' ? context : ''

  const translation = (() => {
    if (TRANSLATIONS[key]) {
      const data = TRANSLATIONS[key]
      if (Array.isArray(data)) {
        return data[data.length - 1]
      } else if (isObject(data)) {
        if (TRANSLATIONS[key][context]) return TRANSLATIONS[key][context]

        if (isObject(TRANSLATIONS[key])) {
          // If context doesn't match, use the first translation
          return Object.values(TRANSLATIONS[key])[0]
        }
      }
    }
    return key
  })()

  // Interpolate by replacing "{k}" with "v" from values

  const interpolate = function (memo, k) {
    return memo.replace(new RegExp(`\\{${k}\\}`, 'g'), values[k])
  }

  if (Array.isArray(values)) {
    return values.reduce((memo, v, k) => interpolate(memo, k), translation)
  }

  return Object.keys(values).reduce((memo, k) => interpolate(memo, k), translation)
}

translate.getTranslations = () => TRANSLATIONS
translate.setTranslations = (translations) => (TRANSLATIONS = translations)

module.exports = translate
