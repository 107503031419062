const Ext = require('ext')
const CompanyStore = require('admin/data/company/CompanyStore')
const ResellerRecord = require('admin/data/company/ResellerRecord')
const ResellerResource = require('admin/business/ResellerResource')

const ResellerStore = Ext.define(null, {
  extend: CompanyStore,

  resourceName: 'resellers',
  sortInfo: {
    field: 'displayName',
    direction: 'ASC'
  },

  record: ResellerRecord,

  module() {
    return ResellerResource
  }
})

module.exports = ResellerStore
