const Ext = require('ext')
const t = require('translate')
const TabPanel = require('admin/view/TabPanel').default
const UserRolesPanel = require('admin/view/UserRolesPanel')
const UserDetailPanel = require('admin/view/UserDetailPanel')
const NoteListPanel = require('admin/view/NoteListPanel')
const SubscriptionListPanel = require('reporting/SubscriptionListPanel')
const store = require('store').default
const { isCapableOfSubscriptions } = require('system/capability/subs')

const UserTabPanel = Ext.define(null, {
  extend: TabPanel,

  title: t('User'),
  tabCls: 'user-tab',
  cls: 'user-panel',

  initComponent() {
    this.items = [
      new UserDetailPanel({ record: this.record }),
      new UserRolesPanel({ record: this.record }),
      new NoteListPanel({ record: this.record, store: this.record.getNoteStore() })
    ]

    if (isCapableOfSubscriptions(store.getState())) {
      this.items.push(
        new SubscriptionListPanel({
          record: this.record,
          store: this.record.getSubscriptionStore()
        })
      )
    }

    this.callParent()
  }
})

module.exports = UserTabPanel
