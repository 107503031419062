const Ext = require('ext')
const t = require('translate')
const Clipboard = require('clipboard')
const User = require('admin/core/User')
const ResourceListPanel = require('admin/view/ResourceListPanel')

// TODO port to React. Should be easy since we already have
// the reducers, actions and subscribers in the mailhosting namespace
// see SCL-3318

const HostingDomainListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'domainName',

  module() {
    return require('admin/business/HostingDomainResource')
  },

  // since https://youtrack.smxemail.com/issue/SCL-2400
  allowWildcardProvisioning() {
    return (
      this.store.canCreate() &&
      User.isVendor() &&
      (User.isTechnical() || User.isTechnicalAdmin())
    )
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel(
      [
        User.isTechnicalAdmin() && {
          id: 'id',
          header: t('ID'),
          width: 80,
          sortable: true,
          dataIndex: 'id'
        },

        {
          id: 'domainName',
          header: t('Domain Name'),
          width: 150,
          sortable: true,
          dataIndex: 'name'
        },
        this.allowWildcardProvisioning() && {
          id: 'wildcard',
          header: t('Wildcard'),
          width: 150,
          sortable: true,
          dataIndex: 'wildcard'
        },

        User.isTechnicalAdmin() && {
          id: 'hostingDescription',
          header: t('Platform'),
          width: 150,
          sortable: true,
          dataIndex: 'hostingDescription'
        }
      ].filter(Boolean)
    )
  },

  getToolbarItems() {
    return [
      this.getCreateButton(),
      this.getCopyNameButton(),
      this.allowWildcardProvisioning() ? this.getAddWildcardButton() : undefined,
      this.getRemoveButton(),
      '->',
      this.getRefreshButton()
    ]
  },

  getAddWildcardButton() {
    return new Ext.Button({
      id: 'hosting-domain-wildcard-button',
      text: this.module.translations.addWildcard,
      disabled: true,
      shouldEnable: () => {
        const selected = this.getSelectionModel().getSelected()
        return selected?.isWildcardCapable()
      },
      handler: () => {
        return this.module.addWildcard(this.getSelectionModel().getSelected())
      }
    })
  },

  getCopyNameButton() {
    const copyNameButton = new Ext.Button({
      id: 'hosting-domain-copy-button',
      text: t('Copy Name'),
      disabled: true,

      // shouldEnable tells the button when to enable it.
      // in this case, it only gets enabled when one row is selected.
      // wouldn't make sense to have it enabled when nothing is selected.
      shouldEnable: () => this.getSelectionModel().getSelected() != null
    })

    copyNameButton.on('render', () => {
      const clipboard = new Clipboard(copyNameButton.getEl().dom, {
        text: () => this.getSelectionModel().getSelected().getName()
      })

      // keep original
      clipboard.originalOnClick = clipboard.onClick

      // then shim it. caused by http://youtrack.smxemail.com/issue/SCL-1413
      // because the Clipboard library doesn't check whether button is disabled or not and
      // does not cope well with extjs component
      return (clipboard.onClick = function (e) {
        if (copyNameButton.disabled) {
          return e.preventDefault()
        }

        return this.originalOnClick(e)
      })
    })

    return copyNameButton
  }
})

module.exports = HostingDomainListPanel
