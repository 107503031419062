const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const User = require('admin/core/User')
const RuleManagementPanel = require('smartrules/RuleManagementPanel')

const RuleManagement = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.SmartRules.RuleManagement',
  translations: {
    title: t('Rule Management'),
    new: t('New Rule Management'),
    open: t('Rule Management'),
    list: t('Rule Management')
  },
  name: t('Rule Management'),
  resourceId: 'RULE_SETS',
  resourceName: 'rulesets',

  listPanel: RuleManagementPanel,

  canCreate() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canSave() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canRemove() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  }
})

module.exports = RuleManagement
