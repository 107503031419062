const Ext = require('ext')
const t = require('translate')

const EventToolbar = Ext.define(null, {
  extend: Ext.PagingToolbar,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      cls: 'search-event-pagingtoolbar',
      disabled: true,
      displayInfo: true,
      emptyMsg: ''
    })
    this.callParent([cfg])
    this.on(
      'render',
      function () {
        this.inputItem.hide()
        this.afterTextItem.hide()
        this.items.get(2).hide()
        this.items.get(3).hide()
        this.last.hide()
      },
      this
    )
  },

  updateInfo() {
    if (this.displayItem) {
      let msg
      const count = this.store.getCount()

      if (count === 0) {
        msg = this.emptyMsg
      } else {
        msg = t('Displaying events {0} - {1}', [this.cursor + 1, this.cursor + count])
      }
      this.displayItem.setText(msg)
    }
  },

  beforeLoad() {
    if (this.rendered) {
      this.first?.disable()
      this.prev?.disable()
      this.next?.disable()
      this.last?.disable()
      this.refresh?.disable()
    }
  },

  onLoad(store, r, o) {
    if (!this.rendered) {
      this.dsLoaded = [store, r, o]
    } else {
      const count = this.store.getCount()
      const p = this.getParams()
      this.cursor = (o.params && o.params[p.start]) || 0
      const d = this.getPageData()
      const ap = d.activePage

      this.first.setDisabled(ap === 1)
      this.prev.setDisabled(ap === 1)
      this.next.setDisabled(count < this.pageSize)
      this.last.setDisabled(count < this.pageSize)
      this.refresh.setDisabled(!this.store.loaded)

      // only enable the refresh button when a search has been made, not before
      // since http://youtrack.smxemail.com/issue/SCL-998
      if (this.store.loaded) {
        this.refresh.enable()
      }

      this.updateInfo()
      this.fireEvent('change', this, d)
    }
  }
})

module.exports = EventToolbar
