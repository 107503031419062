export const SHARED_SERVICE_PROVIDER_REL = 'smx3:sharedservices/provider'

export const QUARANTINE_SEARCH_REL = 'smx3:mailproducts/quarantine-search'
export const EVENT_DETAIL_REL = 'smx3:mailproducts/event-detail'
export const QUARANTINE_MESSAGE_REL = 'smx3:mailproducts/quarantine-message'
export const QUARANTINE_PREVIEW_REL = 'smx3:mailproducts/quarantine-preview'
export const QUARANTINE_DOWNLOAD_REL = 'smx3:mailproducts/quarantine-download'
export const QUARANTINE_RELEASE_REL = 'smx3:mailproducts/quarantine-release'

export const TENANCY_LIST_REL = 'smx3:customer/tenancy-list'
export const TENANCY_REL = 'smx3:customer/tenancy'
export const TENANCY_RENAME_REL = 'smx3:customer/rename-tenancy'
export const TENANCY_SUSPEND_REL = 'smx3:customer/suspend-tenancy'
export const TENANCY_UNSUSPEND_REL = 'smx3:customer/unsuspend-tenancy'

export const MAIL_TARGET_REL = 'smx3:customer/mail-target'
export const MAIL_TARGETS_REL = 'smx3:customer/mail-targets'

export const NOTIFICATION_REL = 'smx3:customer/notifications'

export const MAIL_EVENT_TAGS_REL = 'smx3:customer/event-tags'

export const VENDOR_SMART_RULES_REL = 'smx3:vendor/smartrules'
export const SMART_RULES_REL = 'smx3:customer/smartrules'

export const AVAILABLE_MAIL_TARGET_REL = 'smx3:mailproducts/available-mail-target'
export const QUARANTINE_SET_REL = 'smx3:mailproducts/quarantine-set'
export const QUARANTINE_SETS_REL = 'smx3:mailproducts/quarantine-sets'
export const QUARANTINE_TRIGGERS_REL = 'smx3:mailproducts/quarantine-triggers'

export const MAILBOX_REL = 'smx3:mailproducts/mailbox'
export const MAILBOX_UPGRADE_REL = 'smx3:mailproducts/upgrade-cos'
export const MAILBOX_RESTORE_REL = 'smx3:mailproducts/restore'
export const MAILBOX_PURGE_REL = 'smx3:mailproducts/purge'
export const MAILBOX_DELETE_REL = 'smx3:mailproducts/delete'
export const MAILBOX_UNSUSPEND_REL = 'smx3:mailproducts/unsuspend'
export const MAILBOX_SUSPEND_REL = 'smx3:mailproducts/suspend'
export const MAILBOX_FORWARD_REL = 'smx3:mailproducts/forward'
export const MAILBOX_ALIAS_REL = 'smx3:mailproducts/alias'
export const MAILBOX_UPDATE_PASSWORD_REL = 'smx3:mailproducts/update-password'
export const MAILBOX_UPDATE_ALIASES_REL = 'smx3:mailproducts/update-aliases'
export const MAILBOX_UPDATE_FORWARDS_REL = 'smx3:mailproducts/update-forwards'
export const MAILBOX_CATALOGUE_REL = 'smx3:mailproducts/cos-catalogue-entry'
export const MAILBOX_UPGRADE_CATALOGUE_REL = 'smx3:mailproducts/upgrade-catalogue'
export const MAILBOX_UPGRADE_COS_REL = 'smx3:mailproducts/upgrade-cos'

export const MAILHOSTING_CATALOGUE_REL = 'smx3:mailproducts/catalogue-entry'
export const MAILHOSTING_DOMAIN_REL = 'smx3:mailproducts/hosting-domains' // todo sort out with backend

export const ARCHIVING_ARCHIVED_MESSAGE_REL = 'smx3:archiving/archived-message'
export const ARCHIVING_MESSAGE_REL = 'smx3:archiving/message'
export const ARCHIVING_PREVIEW_REL = 'smx3:archiving/preview'
export const ARCHIVING_DOWNLOAD_REL = 'smx3:archiving/download'
export const ARCHIVING_CONFIGURATION_REL = 'smx3:archiving/configuration'
export const ARCHIVING_CONFIGURATION_GLOBAL_REL = 'smx3:archiving/global'
export const ARCHIVING_CONFIGURATION_ENABLE_REL = 'smx3:archiving/enable'

export const CONTINUITY_ARCHIVED_MESSAGE_REL = 'smx3:archiving/archived-message'
export const CONTINUITY_MESSAGE_REL = 'smx3:archiving/message'
export const CONTINUITY_PREVIEW_REL = 'smx3:archiving/preview'
export const CONTINUITY_DOWNLOAD_REL = 'smx3:archiving/download'

export const USER_UPDATE_DETAIL_REL = 'smx3:user/update-details'
export const USER_UPDATE_PASSWORD_REL = 'smx3:user/update-password'

export const DISTRIBUTOR_DETAIL_REL = 'smx3:distributor/details'
export const RESELLER_DETAIL_REL = 'smx3:reseller/details'
export const CUSTOMER_DETAIL_REL = 'smx3:customer/details'
