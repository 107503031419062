const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const UserRecord = require('admin/data/UserRecord')
const UserResource = require('admin/business/UserResource')

const UserStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'users',
  sortInfo: {
    field: 'displayName',
    direction: 'ASC'
  },

  record: UserRecord,

  module() {
    return UserResource
  },

  constructor() {
    this.callParent(arguments)
    this.parentRecord?.on('change', () => this.reload())
  }
})

module.exports = UserStore
