import {
  ARCHIVING_MEMBERSHIP_INIT_DB,
  ARCHIVING_MEMBERSHIP_SET_CONFIGURATION,
  ARCHIVING_MEMBERSHIP_SET_GLOBAL,
  ARCHIVING_MEMBERSHIP_SET_TYPES,
  ARCHIVING_MEMBERSHIP_START_LOADING,
  ARCHIVING_MEMBERSHIP_START_MUTATING,
  ARCHIVING_MEMBERSHIP_STOP_LOADING,
  ARCHIVING_MEMBERSHIP_STOP_MUTATING
} from 'archiving/membership/actions.js'

import { ARCHIVING_CONFIGURATION_REL } from 'product/resources'
import { createNextState } from '@reduxjs/toolkit'
import deepmerge from 'deepmerge'
import getIn from 'lodash/get'
import setIn from 'lodash/set'
import initialState from 'archiving/membership/db'

const getBaseKeyPath = (companyKey) => [
  'companies',
  companyKey,
  ARCHIVING_CONFIGURATION_REL
]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])

const reducers = {
  [ARCHIVING_MEMBERSHIP_INIT_DB]: (archivingDb, { companyKey, uri }) => {
    return setIn(
      archivingDb,
      getBaseKeyPath(companyKey),
      deepmerge(initialState, { uri })
    )
  },

  [ARCHIVING_MEMBERSHIP_START_LOADING]: (archivingDb, { companyKey }) => {
    return setIn(archivingDb, getKeyPath(companyKey, 'loading'), true)
  },

  [ARCHIVING_MEMBERSHIP_STOP_LOADING]: (archivingDb, { companyKey }) => {
    return setIn(archivingDb, getKeyPath(companyKey, 'loading'), false)
  },

  [ARCHIVING_MEMBERSHIP_START_MUTATING]: (archivingDb, { companyKey }) => {
    return setIn(archivingDb, getKeyPath(companyKey, 'mutating'), true)
  },

  [ARCHIVING_MEMBERSHIP_STOP_MUTATING]: (archivingDb, { companyKey }) => {
    return setIn(archivingDb, getKeyPath(companyKey, 'mutating'), false)
  },

  [ARCHIVING_MEMBERSHIP_SET_CONFIGURATION]: (
    archivingDb,
    { companyKey, global, types, globalLink, enableLink }
  ) => {
    const membership = getIn(archivingDb, getBaseKeyPath(companyKey))

    const newMembership = deepmerge(
      membership,
      {
        global,
        originalGlobal: global,
        types,
        originalTypes: types,
        globalLink,
        enableLink,
        loading: false,
        mutating: false
      },
      // do not concatenate arrays
      { arrayMerge: (destinationArray, sourceArray) => sourceArray }
    )

    return setIn(archivingDb, getBaseKeyPath(companyKey), newMembership)
  },

  [ARCHIVING_MEMBERSHIP_SET_GLOBAL]: (archivingDb, { companyKey, global }) => {
    return setIn(archivingDb, getKeyPath(companyKey, 'global'), global)
  },

  [ARCHIVING_MEMBERSHIP_SET_TYPES]: (archivingDb, { companyKey, types }) => {
    return setIn(archivingDb, getKeyPath(companyKey, 'types'), types)
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
