const Ext = require('ext')
const t = require('translate')
const validators = require('validators').default

const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const InvoiceListPanel = require('admin/view/InvoiceListPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')

const store = require('store').default
const { isCapableOfInvoicing } = require('system/capability/subs')

const MAILBOX_VALIDATION_MSG =
  'Email address or mailbox format (Bob Smith <bob.smith@test.com>) expected.'

const BillingDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'billing-detail-panel',
  closeOnSave: false,

  module() {
    return require('admin/business/BillingResource')
  },

  constructor() {
    this.callParent(arguments)

    const InvoiceResource = require('admin/business/InvoiceResource')

    // This was hacked to embed the invoices panel.
    // It could almost certainly be done better.
    if (InvoiceResource.canList() && isCapableOfInvoicing(store.getState())) {
      this.layout = 'hbox'
      this.layoutConfig = {
        align: 'stretch',
        extraCls: 'x-box-item smx-side-resource'
      }

      const first = this.get(0)
      if (first) first.flex = 1

      this.add(
        new InvoiceListPanel({
          border: false,
          flex: 1,
          header: false,
          record: this.record,
          store: this.record.getInvoiceStore()
        })
      )
    }
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getSaveOptions(record) {
    // todo get rid of billing flag by creating separate resources for
    // billing contact details alone
    return {
      billing: true,
      url: record.getUrl() + '/billingcontactdetails',
      method: 'POST'
    }
  },

  getEmailItems() {
    return [
      {
        fieldLabel: t('Billing Email'),
        name: 'primaryBillingEmailAddress',
        validator: validators.mailbox.withMsg(t(MAILBOX_VALIDATION_MSG)),
        allowBlank: true,
        xtype: 'textfield'
      },
      {
        fieldLabel: t('Second Billing Email'),
        name: 'secondaryBillingEmailAddress',
        validator: validators.mailbox.withMsg(t(MAILBOX_VALIDATION_MSG)),
        allowBlank: true,
        xtype: 'textfield'
      }
    ]
  },

  getDetailItems() {
    return [
      {
        items: this.getEmailItems().filter(Boolean),
        title: t('Details'),
        xtype: 'fieldset'
      }
    ]
  }
})

module.exports = BillingDetailPanel
