import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Highlight from 'components/Highlight'
import makeRegexForHighlighting from 'mail/makeRegexForHighlighting'

class MessageHighlight extends Component {
  static propTypes = {
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    keyword: PropTypes.string,
    enabled: PropTypes.bool,
    resultsQuery: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    placeholder: PropTypes.PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element
    ])
  }

  isEmpty() {
    return (
      typeof this.props.children === 'undefined' ||
      this.props.children === '' ||
      (Array.isArray(this.props.children) && this.props.children.length < 1)
    )
  }

  showHighlight() {
    return this.props.resultsQuery && this.props.enabled && !this.isEmpty()
  }

  renderHighlight() {
    return (
      <Highlight
        regex={makeRegexForHighlighting(
          this.props.resultsQuery,
          this.props.keyword,
          this.props.availableKeywords
        )}
      >
        {this.props.children}
      </Highlight>
    )
  }

  renderChildren() {
    const str = Array.isArray(this.props.children)
      ? this.props.children.join(', ')
      : this.props.children

    return <span>{str}</span>
  }

  renderPlaceholder() {
    return <span>{this.props.placeholder}</span>
  }

  render() {
    if (this.showHighlight()) {
      return this.renderHighlight()
    } else if (!this.isEmpty()) {
      return this.renderChildren()
    }

    return this.renderPlaceholder()
  }
}

export default MessageHighlight
