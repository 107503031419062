import React from 'react'

import ReduxContainerPanel from 'admin/view/ReduxContainerPanel'
import { unload as unloadArchiving } from 'archiving/actions'
import { setCompany as setCompanyForMembership } from 'archiving/membership/actions.js'
import { setCompany as setCompanyForMessages } from 'archiving/messages/actions'
import { hasModalDialog } from 'archiving/subs'
import TabsClass from 'archiving/views/Tabs'
import Ext from 'ext'
import t from 'translate'

const ArchivingTabPanel = Ext.define(null, {
  extend: ReduxContainerPanel,

  title: t('Archiving'),
  cls: 'archiving-panel',
  tabCls: 'archiving-tab',

  initComponent: function () {
    // do not initialise app state when hot reloading for local development
    if (!this.initialConfig.hotReloading) {
      // synchronous db initialisation here, set uri for company key so that
      // we don't have to painfully pass uri along to any react component
      this.store.dispatch(
        setCompanyForMessages(
          this.initialConfig.companyKey,
          this.initialConfig.archivingUrl
        )
      )

      if (this.initialConfig.showConfig) {
        this.store.dispatch(
          setCompanyForMembership(
            this.initialConfig.companyKey,
            // TODO have backend return the link, see SSV-3537
            this.initialConfig.archivingUrl + '/membership'
          )
        )
      }
    }

    this.component = React.createElement(TabsClass, this.initialConfig)

    if (this.initialConfig.expired) {
      this.tabCls += ' expired-group-tab'
    }

    this.superclass().initComponent.call(this)
  },

  preventClose: function () {
    return hasModalDialog(this.store.getState(), this.companyKey)
  },

  destroy: function () {
    this.superclass().destroy.call(this)

    // cleanup only at the very end when all else has been destroyed
    this.store.dispatch(unloadArchiving(this.companyKey))
  }
})

export default ArchivingTabPanel
