import { keycloakLogout } from 'system/keycloak'

const SMX_AUTH_HEADER = 'smx-authenticated-user'

const onCompleteRequest = (response) => {
  const logout =
    // extjs
    (response.getResponseHeader && response.getResponseHeader(SMX_AUTH_HEADER)) ||
    // axios
    (response.headers && response.headers[SMX_AUTH_HEADER])

  if (logout) {
    // SCL-3535 user was onboarded when already authenticated.
    // logout to refresh with keycloak and AD
    keycloakLogout()
  }
}

export default onCompleteRequest
