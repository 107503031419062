const Ext = require('ext')
const TextArea = require('admin/component/form/TextArea')
const TextRow = require('admin/util/TextRow')

const LimitedTextArea = Ext.define(null, {
  extend: TextArea,

  lineMaxLength: null,
  cls: 'has-line-max-length',

  isOverflown() {
    return TextRow.isOverflown(this.getValue(), this.lineMaxLength)
  }
})

module.exports = LimitedTextArea
