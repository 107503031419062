const t = require('translate')
const Record = require('admin/core/Record')
const Calendar = require('admin/time/Calendar')
const User = require('admin/core/User')
const ResellerAgreementResource = require('admin/business/ResellerAgreementResource')
const pricingModel = require('money/pricingModel').default

const getStatus = function (fromDate, thruDate) {
  const now = new Date().clearTime()

  if (Number.isInteger(fromDate) && now < fromDate) return t('Future')
  if (Number.isInteger(thruDate) && thruDate < now) return t('Historic')

  return t('Current')
}

const ResellerAgreementRecord = Record.define(null, {
  module() {
    return ResellerAgreementResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'billTo.id',
      type: 'int'
    },
    {
      name: 'status',
      type: 'string',
      mapping: 'fromDate',
      convert(fromDate, record) {
        return getStatus(fromDate, record.thruDate)
      }
    },
    {
      name: 'agreementType',
      type: 'string',
      mapping: 'agreementType.code'
    },
    {
      name: 'agreementTypeName',
      type: 'string',
      mapping: 'agreementType.description'
    },
    {
      name: 'description',
      type: 'string',
      mapping: 'description'
    },
    // needed for saving unchanged from date to avoid DST offset manipulations (SCL-3627)
    {
      name: 'originalFromDate',
      type: 'int',
      mapping: 'fromDate'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: (fromDate) => Calendar.denormalizeFromDate(fromDate)?.toJSDate()
    },
    // needed for saving unchanged from date to avoid DST offset manipulations (SCL-3627)
    {
      name: 'originalThruDate',
      type: 'int',
      mapping: 'thruDate'
    },
    {
      name: 'thruDate',
      type: 'date',
      // because we shift to start of tomorrow upon submission,
      // we have to shift back one day when displaying thru date
      convert: (thruDate) => Calendar.denormalizeThruDate(thruDate)?.toJSDate()
    },
    {
      name: 'product',
      type: 'string',
      mapping: 'product.code'
    },
    {
      name: 'productName',
      type: 'string',
      mapping: 'product.name'
    },
    {
      name: 'commission.percentage',
      type: 'float'
    },
    {
      name: 'fixedBuyPrice.priceAmount',
      type: 'float'
    },
    {
      name: 'fixedBuyPrice.priceCurrencyUom',
      type: 'string',
      mapping: 'fixedBuyPrice.priceCurrencyUom.code'
    },
    {
      name: 'pricingModel',
      convert(value, data) {
        return pricingModel(
          data.agreementType.code,
          data.commission?.percentage,
          data.fixedBuyPrice?.priceAmount,
          data.fixedBuyPrice?.priceCurrencyUom?.code
        ).toString()
      }
    },
    {
      name: 'editable',
      type: 'boolean'
    }
  ],

  getName() {
    return this.get('description')
  },

  getDescription() {
    return this.get('description')
  },

  canSave() {
    return this.store.parentRecord.get('id') !== User.getRootId() && this.get('editable')
  },

  getParams() {
    let fromDate, thruDate

    // Reseller agreement dates always must be normalized for our server time zone.
    // ... but not when unchanged. This to ensure DST offsets won't change.

    const changes = this.getChanges()

    if (this.isNew() || changes.fromDate) {
      fromDate = Calendar.normalizeFromDate(this.get('fromDate')).toMillis()
    } else {
      fromDate = this.get('originalFromDate')
    }

    if (this.isNew() || changes.thruDate) {
      thruDate = Calendar.normalizeThruDate(this.get('thruDate'))?.toMillis()
    } else {
      if (this.get('thruDate')) {
        thruDate = this.get('originalThruDate')
      } // else must have been removed
    }

    this.data.status = getStatus(fromDate, thruDate)

    const params = {
      agreementType: this.get('agreementType'),
      description: this.get('description'),
      fromDate,
      thruDate,
      product: this.get('product')
    }

    const billTo = this.get('billTo.id')

    if (!billTo || billTo === '' || billTo === this.store.parentRecord.get('id')) {
      const priceAmount = this.get('fixedBuyPrice.priceAmount')
      const priceCurrencyUom = this.get('fixedBuyPrice.priceCurrencyUom')

      this.data.pricingModel = pricingModel(
        this.get('agreementType'),
        this.get('commission.percentage'),
        priceAmount,
        priceCurrencyUom
      ).toString()

      if (priceCurrencyUom) {
        // currency means fixed price
        params['fixedBuyPrice.priceAmount'] = priceAmount
        params['fixedBuyPrice.priceCurrencyUom'] = priceCurrencyUom
      } else {
        params['commission.percentage'] = this.get('commission.percentage')
      }
    }

    return params
  }
})

module.exports = ResellerAgreementRecord
