const Ext = require('ext')
const SearchRecord = require('admin/data/SearchRecord')
const ResourceReader = require('admin/data/ResourceReader')

const SearchStore = Ext.define(null, {
  extend: Ext.data.GroupingStore,

  record: SearchRecord,

  constructor() {
    this.reader = new ResourceReader(this.record)
    this.proxy = new Ext.data.HttpProxy({
      url: '/api/search',
      timeout: 18e4, // 3 minutes
      method: 'GET'
    })

    this.callParent(arguments)
  }
})

module.exports = SearchStore
