const Ext = require('ext')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')

// Read only replacement for ComboBox.
// Requires actual ComboBox (not object literal with xtype) in order to get
// display value from internal store.
const ReadOnlyComboBox = Ext.define(null, {
  extend: ReadOnlyTextField,

  constructor(cfg = {}) {
    // If readOnlyName is set, use this as the read only fields name.
    // This allows a single widget to edit and display different values.
    // Not ideal because the read only value isn't automatically updated by the widget.
    // Use only when the value will never change eg. edit window closes on save.
    if (cfg.clone.readOnlyName) {
      cfg.name = cfg.clone.readOnlyName
      // Otherwise look up the display value using the ComboBox store.
      // ComboBox uses hiddenName as the canonical name when using a key-value store.
    } else {
      cfg.name = cfg.clone.hiddenName || cfg.clone.name
      this.useStoreDisplayValue = true
    }

    this.callParent([cfg])
  },

  // Overridden to use the display value from ComboBox store.
  setValue(value) {
    if (this.useStoreDisplayValue) {
      // do not show the whole combobox with its label if its source is hidden, i.E.
      // hide the region details if the region combobox is hidden (SCL-902)
      if (this.clone.hidden) {
        this.setVisible(false)
      }

      // Store may be loading, if so, set value again once finished.
      if (this.clone.store != null ? this.clone.store.loading : undefined) {
        this.clone.store.on(
          'load',
          function () {
            return this.setValue(value)
          },
          this,
          { single: true }
        )

        // Showing nothing is better than showing machine value
        this.callParent([''])
        return
      }

      if (this.clone.valueField) {
        const record = this.clone.findRecord(this.clone.valueField, value)
        if (record) {
          value = record.data[this.clone.displayField]
        } else if (Ext.isDefined(this.clone.valueNotFoundText)) {
          value = this.clone.valueNotFoundText
        }
      }
    }

    this.callParent([value])
  }
})

module.exports = ReadOnlyComboBox
