const Ext = require('ext')
const t = require('translate')
const ListTypes = require('smartrules/ListTypes')
const AddressableConditionClause = require('smartrules/designer/conditions/AddressableConditionClause')
const AddressWildcardMatcher = require('smartrules/designer/matchers/AddressWildcardMatcher')
const EqualsMatcher = require('smartrules/designer/matchers/EqualsMatcher')
const LteMatcher = require('smartrules/designer/matchers/LteMatcher')
const GteMatcher = require('smartrules/designer/matchers/GteMatcher')
const ListMatcher = require('smartrules/designer/matchers/ListMatcher')
const BetweenMatcher = require('smartrules/designer/matchers/BetweenMatcher')

const AddressedTo = Ext.define(null, {
  extend: AddressableConditionClause,

  negativeOperators: ['is-not'],

  constructor(cfg = {}) {
    if (cfg.condition === 'recipients') {
      cfg.operator = cfg.value != null ? cfg.value.type : undefined
    }

    const config = {
      condition: cfg.condition, // todo: not sure, maybe obsolete
      quantifier: cfg.quantifier,
      listStores: cfg.listStores,
      valueType: cfg.valueType,
      system: cfg.system,
      negated: this.negativeOperators.indexOf(cfg.operator) > -1,
      operator: cfg.operator,

      steps: [
        {
          defaultValue: cfg.value,
          defaultMatcher: cfg.value?.type,

          conditions: [
            [
              {
                name: 'any',
                label: t('Any recipient'),
                matchers: [
                  {
                    matcher: 'is',
                    name: t('matches'),
                    clazz: AddressWildcardMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'any addressed to {0}'
                  },
                  {
                    matcher: 'is-in',
                    name: t('is in list'),
                    clazz: ListMatcher,
                    matcherType: ListTypes.RULE_ADDRESS,
                    dsl: 'any addressed to {0}'
                  },
                  {
                    matcher: 'is-not',
                    name: t('is not'),
                    clazz: AddressWildcardMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'not any addressed to {0}'
                  },
                  {
                    matcher: 'is-not-in',
                    name: t('is not in list'),
                    clazz: ListMatcher,
                    matcherType: ListTypes.RULE_ADDRESS,
                    dsl: 'not any addressed to {0}'
                  }
                ]
              },
              {
                name: 'every',
                label: t('Every recipient'),
                matchers: [
                  {
                    matcher: 'is',
                    name: t('matches'),
                    clazz: AddressWildcardMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'every addressed to {0}'
                  },
                  {
                    matcher: 'is-in',
                    name: t('is in list'),
                    clazz: ListMatcher,
                    matcherType: ListTypes.RULE_ADDRESS,
                    dsl: 'every addressed to {0}'
                  },
                  {
                    matcher: 'is-not',
                    name: t('is not'),
                    clazz: AddressWildcardMatcher,
                    matcherType: 'ADDRESS',
                    dsl: 'not every addressed to {0}'
                  },
                  {
                    matcher: 'is-not-in',
                    name: t('is not in list'),
                    clazz: ListMatcher,
                    matcherType: ListTypes.RULE_ADDRESS,
                    dsl: 'not every addressed to {0}'
                  }
                ]
              },
              {
                name: 'recipients',
                label: t('Number of recipients'),
                matchers: [
                  {
                    matcher: 'between',
                    name: t('is between'),
                    clazz: BetweenMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of recipients {0}'
                  },
                  {
                    matcher: 'equals',
                    name: t('is equal to'),
                    clazz: EqualsMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of recipients {0}'
                  },
                  {
                    matcher: 'less',
                    name: t('is less than'),
                    clazz: LteMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of recipients {0}'
                  },
                  {
                    matcher: 'greater',
                    name: t('is greater than'),
                    clazz: GteMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of recipients {0}'
                  }
                ]
              }
            ]
          ]
        }
      ]
    }

    this.callParent([config])
  }
})

module.exports = AddressedTo
