const t = require('translate')
const Record = require('admin/core/Record')
const Calendar = require('admin/time/Calendar')
const AgreementResource = require('admin/business/AgreementResource')

const getStatus = function (fromDate, thruDate) {
  const now = new Date().clearTime()

  if (Number.isInteger(fromDate) && now < fromDate) return t('Future')
  if (Number.isInteger(thruDate) && thruDate < now) return t('Historic')

  return t('Current')
}

const AgreementRecord = Record.define(null, {
  module() {
    return AgreementResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'status',
      type: 'string',
      mapping: 'fromDate',
      convert(fromDate, record) {
        return getStatus(fromDate, record.thruDate)
      }
    },
    {
      name: 'billTo',
      type: 'int',
      mapping: 'billTo.id'
    },
    {
      name: 'billToName',
      type: 'string',
      mapping: 'billTo.displayName'
    },
    {
      name: 'agreementType',
      type: 'string',
      mapping: 'agreementType.code'
    },
    {
      name: 'agreementTypeName',
      type: 'string',
      mapping: 'agreementType.description'
    },
    {
      name: 'description',
      type: 'string',
      mapping: 'description'
    },
    // needed for saving unchanged from date to avoid DST offset manipulations (SCL-3627)
    {
      name: 'originalFromDate',
      type: 'int',
      mapping: 'fromDate'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: (fromDate) => Calendar.denormalizeFromDate(fromDate)?.toJSDate()
    },
    // needed for saving unchanged from date to avoid DST offset manipulations (SCL-3627)
    {
      name: 'originalThruDate',
      type: 'int',
      mapping: 'thruDate'
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: (thruDate) => Calendar.denormalizeThruDate(thruDate)?.toJSDate()
    },
    {
      name: 'product',
      type: 'string',
      mapping: 'product.code'
    },
    {
      name: 'productName',
      type: 'string',
      mapping: 'product.description'
    },
    {
      name: 'product.productCategory',
      type: 'string',
      mapping: 'product.productCategory.code'
    },
    {
      name: 'quantity',
      type: 'float'
    },
    {
      name: 'editable',
      type: 'boolean'
    },
    {
      name: 'cancellable',
      type: 'boolean'
    },
    {
      name: 'uom',
      type: 'int',
      mapping: 'uom.code'
    },
    {
      name: 'uomName',
      type: 'string',
      mapping: 'uom.description'
    },
    {
      name: 'basePrice.description',
      type: 'string'
    },
    {
      name: 'basePrice.priceAmount',
      // Price may not be available if user doesn't have permissions. Use null to represent this.
      defaultValue: null
    },
    {
      name: 'basePrice.priceCurrencyUom',
      type: 'string',
      mapping: 'basePrice.priceCurrencyUom.code'
    }
  ],

  getName() {
    return this.get('productName')
  },

  getDescription() {
    return this.get('productName')
  },

  canSave() {
    return this.superclass().canSave.call(this) && this.get('editable')
  },

  getParams() {
    let fromDate, thruDate

    // Agreement dates always must be normalized for our server time zone.
    // ... but not when unchanged. This to ensure DST offsets won't change.

    const changes = this.getChanges()

    if (this.isNew() || changes.fromDate) {
      fromDate = Calendar.normalizeFromDate(this.get('fromDate')).toMillis()
    } else {
      fromDate = this.get('originalFromDate')
    }

    if (this.isNew() || changes.thruDate) {
      thruDate = Calendar.normalizeThruDate(this.get('thruDate'))?.toMillis()
    } else {
      if (this.get('thruDate')) {
        thruDate = this.get('originalThruDate')
      } // else must have been removed
    }

    // todo, ugh, rewrite this, bad to mutate @data when function is meant for something else
    this.data.status = getStatus(fromDate, thruDate)

    if (!this.get('description')) {
      this.data.description = this.get('productName')
    }

    const params = {
      agreementType: this.get('agreementType'),
      fromDate,
      thruDate,
      product: this.get('product'),
      quantity: this.get('quantity'),
      billTo: this.get('billTo'),

      // always post pricing information, regardless whether it's default or custom
      'basePrice.description': this.get('basePrice.description'),
      'basePrice.priceAmount': this.get('basePrice.priceAmount'),
      'basePrice.priceCurrencyUom': this.get('basePrice.priceCurrencyUom')
    }

    return params
  },

  canCancel() {
    return !this.get('thruDate') && this.get('cancellable')
  }
})

module.exports = AgreementRecord
