import React, { Component } from 'react'

import { Alert } from 'react-bootstrap'

import t from 'translate'

class Warning extends Component {
  render() {
    return (
      <Alert bsStyle='warning'>
        {t('To protect your privacy, we have blocked remote content in this message.')}
      </Alert>
    )
  }
}

export default Warning
