const Ext = require('ext')
const t = require('translate')
const ItemGridPanel = require('admin/component/grid/ItemGridPanel').default
const RuleManagement = require('smartrules/RuleManagement')
const RuleSetStore = require('smartrules/RuleSetStore')
const User = require('admin/core/User')
const validators = require('validators').default
const escape = require('html-escaper').escape

const RuleSets = Ext.define(null, {
  extend: ItemGridPanel,

  constructor(cfg = {}) {
    cfg.module = RuleManagement

    const columns = []

    if (User.isVendor()) {
      columns.push({
        dataIndex: 'id',
        header: t('ID'),
        id: 'id',
        sortable: false,
        width: 55
      })
    }

    columns.push({
      dataIndex: 'name',
      editor: {
        allowBlank: false,
        validator: this.validateName.createDelegate(this),
        xtype: 'textfield'
      },
      menuDisabled: true,
      header: t('Rule Sets'),
      id: 'name',
      renderer: (name) => name && escape(name),
      sortable: false
    })

    cfg = Ext.applyIf(cfg, {
      addLabel: t('New Set'),
      addTooltip: t('Add a new rule set'),
      columns,
      deleteLabel: t('Delete'),
      deleteTooltip: t('Delete the currently selected rule set'),
      reorderable: cfg.module.canSave(),
      store: new RuleSetStore({
        url: cfg.url
      })
    })

    this.callParent([cfg])
  },

  isNameDuplicate(value) {
    const trimmedValue = value.trim()

    const index = this.store.findBy(function (record) {
      return (
        record.get('name') === trimmedValue &&
        !this.getSelectionModel().isSelected(record)
      )
    }, this)

    return index !== -1
  },

  validateName(value) {
    if (!validators.isSetName(value)) {
      return t(
        'Rule set names must be at least 2 characters long, and cannot start or end with whitespace'
      )
    } else if (value.length > 50) {
      return t('Rule set names must be at most 50 characters long')
    } else if (this.isNameDuplicate(value)) {
      return t('Rule set names must be unique')
    }

    return true
  },

  addItem() {
    const selModel = this.getSelectionModel()

    this.editor.stopEditing(false)

    const RecordClass = this.store.record

    const record = new RecordClass({
      global: true,
      name: '',
      ruleSetType: this.ruleSetType
    })

    this.store.add(record)

    const index = this.store.indexOf(record)
    selModel.selectRow(index)
    return this.editor.startEditing(index, 0)
  },

  deleteItem() {
    if (this.getSelectionModel().hasSelection()) {
      const record = this.getSelectionModel().getSelected()
      const title = t('Delete')
      const text = t('Are you sure you want to delete the rule set {name}?', {
        name: escape(record.get('name'))
      })

      return Ext.MessageBox.confirm(title, text, function (result) {
        if (result === 'yes') {
          Ext.MessageBox.wait(t('Saving...'))
          return record.destroy({
            complete() {
              return Ext.MessageBox.hide()
            }
          })
        }
      })
    }
  }
})

module.exports = RuleSets
