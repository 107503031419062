const Ext = require('ext')
const t = require('translate')
const PanelButton = require('smartrules/designer/PanelButton')

const NestingButton = Ext.define(null, {
  extend: PanelButton,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      buttonCls: 'nestable',
      enableToggle: true,
      pressed: false,
      toggleHandler(button, pressed) {
        if (pressed) {
          const host = button.getHost()
          if (host?.offerNest) host.offerNest()

          button.addClass('nesting')
          button.setTooltip(t('Click to remove sub-actions'))
          return
        }

        button.setTooltip(t('Click to add sub-actions'))
        button.removeClass('nesting')

        const host = button.getHost()
        if (host?.retractNest) host.retractNest()
      },
      tooltip: t('Click to add sub-actions')
    })

    this.callParent([cfg])
  }
})

module.exports = NestingButton
