import Ext from 'ext/ext-base'
import isMessageBoxButtonsArray from 'ext/isMessageBoxButtonsArray'

Ext.override(Ext.Toolbar, {
  initComponent() {
    // since SCL-2936 order of primary and secondary buttons for
    // dialogs (aka message boxes), which are wrapped in toolbars,
    // is automagically reversed (default behaviour)
    //
    // if a toolbar happens to have the same item order
    // like a message box but order should be kept,
    // then this automatic item reversing can be disabled
    // by adding a `shouldReverseItems` parameter which can be a
    // boolean or a function returning a boolean. for example:
    //
    // @buttons = new Ext.Toolbar(
    //   items: [...]
    //   shouldReverseItems: false
    // )
    //
    // or using it as a function which is already bound to the instance like this:
    //
    // @buttons = new Ext.Toolbar(
    //   items: [...]
    //   shouldReverseItems: () -> @items.length is 3
    // )
    if (Array.isArray(this.items)) {
      if (
        typeof this.shouldReverseItems === 'function'
          ? this.shouldReverseItems(this.items)
          : undefined
      ) {
        this.items.reverse()
      }
    }

    Ext.Toolbar.superclass.initComponent.call(this)

    return this.addEvents('overflowchange')
  },

  shouldReverseItems: isMessageBoxButtonsArray
})
