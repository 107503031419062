const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const Report = require('reporting/Report')
const ComboBox = require('admin/component/form/ComboBox')
const validators = require('validators').default
const config = require('theming/config')

const OPTIONS_PREFIX = 'reportingoptions'

const SubscriptionDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'subscription-detail-panel',

  module() {
    return require('reporting/Subscription')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems(cfg = {}) {
    const optionsStringToMap = function () {
      const options = {}
      const optionsStr = cfg.record.data.options
      if (optionsStr) {
        const tokens = cfg.record.data.options.split('&')
        for (const token of Array.from(tokens)) {
          const keyval = token.split('=')
          options[keyval[0]] = keyval[1]
        }
        return options
      }
    }

    const formatOptions = Report.getFormatOptions(cfg.record.get('report'))

    return [
      {
        name: 'reportId',
        value: cfg.record.get('id'),
        xtype: 'hidden'
      },
      {
        fieldLabel: t('Description'),
        name: 'description',
        maxLength: 255,
        allowBlank: false,
        xtype: 'textfield'
      },
      new ComboBox({
        fieldLabel: t('Format'),
        hiddenName: 'format',
        allowBlank: false,
        displayField: 'formatName',
        value: formatOptions[0][0],
        store: formatOptions,
        editable: false,
        triggerAction: 'all'
      }),
      new ComboBox({
        fieldLabel: t('Delivery'),
        hiddenName: 'delivery',
        displayField: 'deliveryName',
        allowBlank: false,
        store: [
          ['DAILY', t('Daily')],
          ['WEEKLY', t('Weekly')],
          ['MONTHLY', t('Monthly')]
        ],
        editable: false,
        triggerAction: 'all'
      }),
      {
        fieldLabel: t('Destination'),
        helpText: t(
          'Use comma separated addresses to send to multiple email accounts, e.g.: foo@example.com, bar@example.com'
        ),
        name: 'destination',
        maxLength: 255,
        allowBlank: false,
        validator: validators.emailList.withMsg(
          t(
            'This field should be one or more emails separated by commas, e.g. user1@example.com, user2@example.com'
          )
        ),
        xtype: 'textfield',
        width: config.panel.fieldWidth
      },

      // Create from options metadata, populate with user's values where supplied
      Report.getReportOptionsPanel(
        cfg.record.get('report'),
        { subscription: true },
        optionsStringToMap()
      )
    ]
  },

  // Invoked on save to add record fields for dynamically-created options field set
  addOptionalRecordFields() {
    return this.formPanel.items.each(function (item) {
      if (item.getXType() === 'fieldset') {
        return item.items.each(function (optionItem) {
          if (
            optionItem.hiddenName &&
            optionItem.hiddenName.indexOf(OPTIONS_PREFIX + '.') > -1
          ) {
            this.record.set(optionItem.hiddenField.name, optionItem.hiddenField.value)
            return this.record.set(optionItem.name, optionItem.getValue())
          } else if (
            optionItem.name &&
            optionItem.name.indexOf(OPTIONS_PREFIX + '.') > -1
          ) {
            return this.record.set(optionItem.name, optionItem.getValue())
          }
        }, this)
      }
    }, this)
  }
})

module.exports = SubscriptionDetailPanel
