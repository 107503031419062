import React, { Component } from 'react'
import { createTenancy, stopCreating } from 'integration/tenancy/actions'

import Dialog from './Dialog'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'
import { uri } from 'integration/tenancy/subs'

class CreateForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => this.props.createTenancy(this.props.uri, values)

  handleCancel = () => this.props.stopCreating()

  render() {
    return (
      <Dialog
        title={t('Add Tenancy')}
        store={this.props.store}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        submitButtonText={t('Create')}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  stopCreating: () => dispatch(stopCreating(companyKey)),
  createTenancy: (uri, values) => dispatch(createTenancy(companyKey, uri, values))
})

export default connect(mapDbToProps, mapActionsToProps)(CreateForm)
