const Ext = require('ext')
const DateField = require('admin/component/form/DateField')
const TimePicker = require('admin/component/form/TimePicker')

/**
Combines DateField and TimePicker widgets into a single widget.
Mostly used within DateTimeRangeFieldSet but made to be used independently.
disableTime disables the time picker widget.
fieldWidth sets the DateField widget width.
*/

const DateTimeField = Ext.define(null, {
  extend: Ext.form.CompositeField,

  // Prevent default behaviour of CompositeField
  combineErrors: false,
  format: 'Y/m/d H:i',

  constructor(cfg = {}) {
    const items = []

    this.date = new DateField({
      format: Date.patterns.SHORT,
      listeners: {
        select: () => {
          this.fireEvent('change', this)
        },
        change: (field, newValue, oldValue) => {
          if (oldValue !== newValue) {
            this.fireEvent('change', this)
          }
        },
        scope: this
      },
      width: cfg.fieldWidth || 'auto',
      allowBlank: cfg.allowBlank !== undefined ? cfg.allowBlank : true,
      value: cfg.value,
      minValue: cfg.minValue,
      maxValue: cfg.maxValue,
      hideLabel: true, // Label should be set on DateTimeField
      validateMinMax: cfg.disableTime // Validate min/max here if time isn't disabled
    })

    if (cfg.disableTime) {
      // Add short format for when displaying readonly version
      this.format = Date.patterns.SHORT
      // Just add the date
      items.push(this.date)
    } else {
      // Combine date and time in a panel
      this.time = new TimePicker({
        disableSeconds: true,
        value: cfg.value,
        listeners: {
          // TimePicker doesn't have a change event
          select() {
            this.fireEvent('change', this)
          },
          scope: this
        },
        margin: 0
      })

      const panel = {
        items: [this.date, { xtype: 'spacer', width: 4 }, this.time],
        border: false,
        xtype: 'panel',
        layout: 'table',
        layoutConfig: {
          columns: 3
        },
        margin: 0
      }
      items.push(panel)
    }

    this.addEvents('change')

    // Validation needs to be triggered by children
    this.on(
      'change',
      function () {
        return this.validate()
      },
      this
    )

    Ext.apply(this, {
      layout: 'form',
      border: false,
      items
    })

    this.callParent([cfg])
  },

  setValue(value) {
    this.date.setValue(value)
    this.time?.setValue(value)
  },

  getValue() {
    const d = this.date.getValue()
    if (d === '') return null

    const t = this.time?.getValue()
    if (d instanceof Date && d.getTime()) {
      // If there is a time component, set the time
      if (t) {
        d.setHours(t.getHours(), t.getMinutes(), t.getSeconds(), 0)
        // Otherwise set the time to 0
      } else {
        d.setHours(0, 0, 0, 0)
      }
    }
    return d
  },

  setMinValue(value) {
    this.minValue = value
    this.date.setMinValue(value)

    this.date.validate()
  },

  setMaxValue(value) {
    this.maxValue = value
    this.date.setMaxValue(value)

    this.date.validate()
  },

  // Use local getValue instead of checking isDirty of children fields
  isDirty() {
    if (this.disabled || !this.rendered) {
      return false
    }
    return String(this.getValue()) !== String(this.originalValue)
  },

  // Validate using child and local validation.
  validateValue() {
    return (
      Ext.form.CompositeField.prototype.validateValue.apply(this, arguments) &&
      Ext.form.Field.prototype.validateValue.apply(this, arguments)
    )
  },

  // Check date is within min/max values
  getErrors() {
    const errors = []

    if (this.disableTime) {
      // DateField should validate itself
      return errors
    }

    // Get value using our getter, not Exts this.processValue(this.getRawValue())
    const value = this.getValue()

    // Uses DateField.min/maxText to format error string
    if (this.minValue && value < this.minValue) {
      errors.push(
        String.format(this.date.minText, Ext.util.Format.date(this.minValue, this.format))
      )
    }
    if (this.maxValue && value > this.maxValue) {
      errors.push(
        String.format(this.date.maxText, Ext.util.Format.date(this.maxValue, this.format))
      )
    }

    return errors
  }
})

module.exports = DateTimeField
