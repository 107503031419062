const Ext = require('ext')
const AddressMatcher = require('smartrules/designer/matchers/AddressMatcher')

// TODO: Remove when matchers accept arguments.
const AddressWildcardMatcher = Ext.define(null, {
  extend: AddressMatcher,
  acceptWildcards: true
})

module.exports = AddressWildcardMatcher
