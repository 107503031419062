const Ext = require('ext')
const ComboBox = require('admin/component/form/ComboBox')

function range(start, stop, step) {
  const length = Math.max(Math.ceil((stop - start) / step), 0)
  const range = Array(length)

  for (let idx = 0; idx < length; idx++, start += step) {
    range[idx] = start
  }

  return range
}

const TimePicker = Ext.define(null, {
  extend: Ext.Panel,

  padTimeField(v) {
    if (`${v}`.length === 1) {
      return `0${v}`
    }
    return `${v}`
  },

  makeTimeCombo(name, dateSetter, dataRange, picker) {
    return new ComboBox({
      store: new Ext.data.ArrayStore({
        fields: [name, 'text'],
        data: dataRange.map((v) => [v, this.padTimeField(v)])
      }),
      displayField: 'text',
      valuefield: name,
      triggerAction: 'all',
      forceSelection: true,
      editable: false,
      mode: 'local',
      width: 48,
      value: this.padTimeField(Math.min(...Array.from(dataRange || []))),
      listeners: {
        scope: picker,
        // ComboBox doesn't have a change event
        select() {
          return this.fireEvent('select')
        }
      }
    })
  },

  constructor(cfg = {}) {
    let i
    let asc, end
    let asc1, end1
    let asc2, end2

    const items = []

    const hoursStep = cfg.hoursStep || 1
    const minutesStep = cfg.minutesStep || 5
    const secondsStep = cfg.secondsStep || 10

    if (!cfg.disableHours) {
      this.hoursCombo = this.makeTimeCombo(
        'hours',
        'setHours',
        range(0, 24, hoursStep),
        this
      )
      items.push(this.hoursCombo)
    }

    if (!cfg.disableMinutes) {
      this.minutesCombo = this.makeTimeCombo(
        'minutes',
        'setMinutes',
        range(0, 60, minutesStep),
        this
      )
      items.push(this.minutesCombo)
    }

    if (!cfg.disableSeconds) {
      this.secondsCombo = this.makeTimeCombo(
        'seconds',
        'setSeconds',
        range(0, 60, secondsStep),
        this
      )
      items.push(this.secondsCombo)
    }

    const seps = []
    for (
      i = 1, end = items.length, asc = end >= 1;
      asc ? i < end : i > end;
      asc ? i++ : i--
    ) {
      seps.push(new Ext.form.Label({ text: ':' }))
    }

    const leftSpacers = []
    for (
      i = 1, end1 = items.length, asc1 = end1 >= 1;
      asc1 ? i < end1 : i > end1;
      asc1 ? i++ : i--
    ) {
      leftSpacers.push({ xtype: 'spacer', width: 2 })
    }

    const rightSpacers = []
    for (
      i = 1, end2 = items.length, asc2 = end2 >= 1;
      asc2 ? i < end2 : i > end2;
      asc2 ? i++ : i--
    ) {
      rightSpacers.push({ xtype: 'spacer', width: 2 })
    }

    if (cfg.value) {
      this.setValue(cfg.value)
    }
    this.addEvents('select')

    const zipped = items.map((value, key) => [
      value,
      leftSpacers[key],
      seps[key],
      rightSpacers[key]
    ])

    Ext.applyIf(cfg, {
      items: zipped.filter(Boolean),
      border: false,
      layout: 'table',
      layoutConfig: {
        columns: items.length + seps.length + leftSpacers.length + rightSpacers.length
      }
    })
    this.callParent([cfg])
  },

  getValue() {
    const d = new Date()
    const h = this.hoursCombo?.getValue() || 0
    const m = this.minutesCombo?.getValue() || 0
    const s = this.secondsCombo?.getValue() || 0
    d.setHours(+h, +m, +s, 0)
    return d
  },

  setValue(v) {
    if (v == null || v === '') {
      this.hoursCombo?.setValue('')
      this.minutesCombo?.setValue('')
      this.secondsCombo?.setValue('')
    } else {
      const d = v instanceof Date ? v : new Date(v)

      this.hoursCombo?.setValue(this.padTimeField(d.getHours()))
      this.minutesCombo?.setValue(this.padTimeField(d.getMinutes()))
      this.secondsCombo?.setValue(this.padTimeField(d.getSeconds()))
    }
    return this
  },

  disable() {
    this.hoursCombo?.disable()
    this.minutesCombo?.disable()
    this.secondsCombo?.disable()
  },

  enable() {
    this.hoursCombo?.enable()
    this.minutesCombo?.enable()
    this.secondsCombo?.enable()
  }
})

module.exports = TimePicker
