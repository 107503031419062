const Ext = require('ext')
const ReportJsonReader = require('reporting/ReportJsonReader')
const ResourceStore = require('admin/data/ResourceStore')

const ReportDataStore = Ext.define(null, {
  extend: ResourceStore,

  getReader(recordType) {
    return (this.reader = new ReportJsonReader(recordType))
  },

  getTitle() {
    return this.reader != null ? this.reader.getTitle() : undefined
  }
})

module.exports = ReportDataStore
