const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const CatalogueRecord = require('admin/data/CatalogueRecord')
const CatalogueResource = require('admin/business/CatalogueResource')

const CatalogueStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'catalogue',
  sortInfo: {
    field: 'name',
    direction: 'ASC'
  },

  record: CatalogueRecord,

  module() {
    return CatalogueResource
  },

  constructor() {
    this.callParent(arguments)
  }
})

module.exports = CatalogueStore
