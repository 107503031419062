const t = require('translate')
const Record = require('admin/core/Record')
const ListManagement = require('smartrules/ListManagement')

const TRANSLATIONS = {
  RULE_HEADER: t('Headers'),
  RULE_ADDRESS: t('Address'),
  RULE_CONTENT: t('Content'),
  RULE_ATTACHMENT_TYPE: t('Attachment type'),
  RULE_ATTACHMENT_NAME: t('Attachment name')
}

const ListManagementRecord = Record.define(null, {
  module() {
    return ListManagement
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'dataSetType',
      type: 'string',
      mapping: 'dataSetType.code'
    },
    {
      name: 'dataSetTypeName',
      type: 'string',
      mapping: 'dataSetType.code',
      convert(value) {
        return TRANSLATIONS[value]
      }
    },
    {
      name: 'items',
      mapping: 'dataSetValues',
      convert(items) {
        if (items == null) {
          items = []
        }
        return items.map((item) => item.content).join('\n')
      }
    }
  ],

  isPersistentOnSave() {
    return true
  },

  getName() {
    return this.get('name')
  },

  getParams() {
    const values = this.get('items') || ''
    const items = values
      .split('\n')
      .map((val) => val.trim() || null)
      .filter(Boolean)
    this.set('items', items.join('\n'))

    return {
      name: this.get('name'),
      dataSetType: this.get('dataSetType'),
      items
    }
  }
})

module.exports = ListManagementRecord
