const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const MailRelayHostDetailPanel = require('admin/view/MailRelayHostDetailPanel')
const MailRelayHostNewPanel = require('admin/view/MailRelayHostNewPanel')
const MailRelayHostListPanel = require('admin/view/MailRelayHostListPanel')
const MailRelayHostRecord = require('admin/data/MailRelayHostRecord')

const MailRelayHostResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.MailRelayHost',
  translations: {
    title: t('Mail Relay Host'),
    plural: t('Mail Relay Hosts'),
    new: t('New Mail Relay Host'),
    open: t('Open Mail Relay Host'),
    list: t('List Mail Relay Hosts'),
    remove: t('Remove Mail Relay Host')
  },
  name: t('Mail Relay Hosts'),
  code: 'MAIL_RELAY_HOST',
  resourceId: 'MAIL_RELAY_HOSTS',
  resourceName: 'mailrelayhosts',

  searchable: true,

  operations: {
    create: true,
    open: false,
    remove: true,
    list: true,
    save: false
  },

  layout: {
    newResource: {
      width: 400,
      height: 'auto'
    },
    openResource: {
      width: 400,
      height: 71,
      resizable: false
    }
  },

  detailPanel: MailRelayHostDetailPanel,
  newPanel: MailRelayHostNewPanel,
  listPanel: MailRelayHostListPanel,

  record: MailRelayHostRecord
})

module.exports = MailRelayHostResource
