// this is the initial state for each new company when
// search gets opened for first time

// limit in mail search is equal to page size in archiving search
// TODO: Technically, it doesn't have to be in app state as it will never
// change but we've decided to put it here in the future, we'll add a keyword
// to adjust.
const LIMIT = 20

export default {
  query: '', // always the current query input
  loading: null,
  highlight: true,
  results: null,
  resultsQuery: null, // the query at that time a search was made. needed for highlighting.
  location: null,
  preview: null,

  releaseSize: null,
  releaseSuccessful: null,
  releaseSkipped: null,
  releaseFailed: null,

  // an array of original email recipient addresses for releasing a single mail
  originalRecipients: null,
  // recipients email validity for a single mail release with manual user entry
  recipientsValid: false,
  attachmentPanelExpanded: false,
  detail: null, // when not null, then we are in viewing mode
  offset: 0,
  direction: 'inbound',
  confirmingRelease: false,
  limit: LIMIT,
  timeZoneName: null,
  startDate: null, // limits date range
  fromDate: null,
  thruDate: null,
  dateRangeShown: false,
  // upon resizing, new column widths will be stored here.
  // by default these are based on flex grow props.
  //
  // TODO must be ordered
  columns: {
    sender: {},
    subject: {},
    size: {},
    received: {},
    released: {}
  },
  scrollToIndex: null, // tells table to which row index to scroll to
  source: null // when not null we are viewing this mail source
}
