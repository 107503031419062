const Ext = require('ext')

const ExplorerNodeUI = Ext.define(null, {
  extend: Ext.tree.TreeNodeUI,

  onDblClick(e) {
    e.preventDefault()
    if (this.disabled) {
      return
    }
    if (this.checkbox) {
      this.toggleCheck()
    }
    return this.fireEvent('dblclick', this.node, e)
  }
})

module.exports = ExplorerNodeUI
