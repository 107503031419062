const _s = require('underscore.string')
const Ext = require('ext')
const t = require('translate')
const MailStatsChart = require('mail/chart/Stats').default
const ProductDirection = require('product/direction')
const MailStats = require('admin/util/MailStats')
const CustomerResource = require('admin/business/CustomerResource')
const Sentry = require('system/sentry').default

const DashboardMailStatistics = Ext.define(null, {
  extend: Ext.Panel,

  maskMsg: Ext.LoadMask.prototype.msg,
  maskMsgCls: 'x-mask-loading',
  ctCls: 'dashboard',
  border: false,
  layout: 'border',

  initComponent() {
    // Update directions in case customer has a new product
    this.record.on('sync', this.updateDirections, this)
    this.on('destroy', () => this.record.un('sync', this.updateDirections, this))

    this.direction = this.hasInbound()
      ? ProductDirection.DIRECTION_INBOUND
      : ProductDirection.DIRECTION_OUTBOUND
    this.thruDate = new Date().getTime()

    // Use a unique name so they don't mess with other radio buttons
    const radioGroupName = [this.record.get('id'), _s.slugify(this.legend)].join('-')

    this.statsPanel = new Ext.BoxComponent()

    this.outboundRadio = new Ext.form.Radio({
      boxLabel: t('Outbound'),
      name: radioGroupName,
      checked: !this.hasInbound(),
      disabled: !this.hasOutbound()
    })

    this.inboundRadio = new Ext.form.Radio({
      boxLabel: t('Inbound'),
      name: radioGroupName,
      checked: this.hasInbound(),
      disabled: !this.hasInbound(),
      handler: (checkbox, checked) => {
        this.direction = checked
          ? ProductDirection.DIRECTION_INBOUND
          : ProductDirection.DIRECTION_OUTBOUND
        this.updateStats()
      }
    })

    this.graph = new MailStatsChart()

    this.items = [
      new Ext.Panel({
        autoEl: 'div',
        region: 'west',
        width: 200,
        border: false,
        autoScroll: false,
        items: [
          {
            xtype: 'box',
            cls: 'x-panel-header-text',
            style: {
              fontWeight: 'bold'
            },
            autoEl: { tag: 'span', html: t(this.legend) }
          },
          new Ext.Toolbar({
            cls: 'direction-toolbar no-border no-background',
            autoScroll: false,
            style: {
              margin: '5px 0'
            },
            items: [this.inboundRadio, new Ext.Spacer({ width: 8 }), this.outboundRadio]
          }),
          this.statsPanel
        ]
      }),
      this.graph
    ]

    this.callParent()
  },

  afterRender() {
    this.callParent()
    this.updateStats()
  },

  updateStats() {
    this.hideFailure()
    this.mask()

    return Ext.Ajax.request({
      method: 'GET',
      url: this.record.get('resources').COLLATED_EVENTS.url,
      params: {
        fromDate: this.fromDate,
        thruDate: this.thruDate,
        granularity: this.period
      },
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this),
      success: (response) => {
        if (this.isDestroyed) return

        this.unmask()

        let data

        try {
          const json = JSON.parse(response.responseText)
          data = json.events || json.results.events
          data = data[this.direction.toUpperCase()] // todo: improve in SCL-970

          this.graph.plotMailStats(data, {
            direction: this.direction,
            unit: 'messageCount',
            period: this.period
          })

          this.showStats(data)
        } catch (exc) {
          this.showFailure(() => this.updateStats(), exc, response.responseText)
        } finally {
          this.unmask()
        }
      },
      failure: (response) => {
        this.unmask()
        this.showFailure(() => this.updateStats(), null, response.responseText)
      }
    })
  },

  showFailure(callback, err, responseText) {
    if (this.isDestroyed) return

    if (responseText) {
      Sentry.addBreadcrumb({
        category: 'response',
        message: responseText
      })
    }

    if (err) {
      Sentry.captureException(err)
    }

    // clear old one first if any
    this.hideFailure()

    const failureDom = Ext.DomHelper.insertHtml(
      'beforeEnd',
      this.el.dom,
      `<div class="graph-fail"> \
${t('Mail reporting is currently unavailable. Please try again later.')} \
<a href="#">${t('Refresh')}</a> \
</div>`
    )

    this.failureEl = this.el.appendChild(failureDom)
    this.failureEl.center(this.el)

    this.failureEl.on(
      'click',
      function (e) {
        const target = e.getTarget('a')
        if (target) {
          this.hideFailure()
          typeof callback === 'function' && callback()
        }
      },
      this
    )
  },

  hideFailure() {
    this.failureEl?.remove()
  },

  /* eslint-disable no-multi-str */
  statsTemplate: new Ext.XTemplate(
    '<tpl for="."> \
<div class="SMX-Dashboard-MailStatistics-Stats"> \
<div class="StatsLabel">{[this.t(values["label"])]}:</div> \
<div class="StatsValue">{value}</div> \
<div class="StatsPercentage">{percentage}</div> \
</div> \
</tpl>',
    { t }
  ),

  showStats(data) {
    const templateData = MailStats.interpolate(data)
    this.statsTemplate.overwrite(this.statsPanel.el, templateData)
  },

  updateDirections() {
    this.inboundRadio.setDisabled(!this.hasInbound())

    if (!this.hasOutbound()) {
      this.inboundRadio.setValue(true)
    }

    this.outboundRadio.setDisabled(!this.hasOutbound())

    if (!this.hasInbound()) {
      this.outboundRadio.setValue(true)
    }
  },

  getDirections() {
    return CustomerResource.getDirections(this.record.get('products'))
  },

  hasInbound() {
    return !!this.getDirections().inbound
  },

  hasOutbound() {
    return !!this.getDirections().outbound
  }
})

module.exports = DashboardMailStatistics
