const Ext = require('ext')
const t = require('translate')
const ResourceStore = require('admin/data/ResourceStore')
const NoteRecord = require('admin/data/NoteRecord')
const NoteResource = require('admin/business/NoteResource')

const NoteStore = Ext.define(null, {
  extend: ResourceStore,

  statics: {
    NOTE_TYPES: {
      USER_NOTE: t('User'),
      SYSTEM_NOTE: t('System'),
      EVENT_NOTE: t('System'),
      UNKNOWN: t('Unknown')
    }
  },

  resourceName: 'notes',
  sortInfo: {
    field: 'noteDate',
    direction: 'DESC'
  },

  record: NoteRecord,

  module() {
    return NoteResource
  }
})

module.exports = NoteStore
