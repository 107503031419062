const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const HostingPlatformRecord = require('admin/data/HostingPlatformRecord')

const HostingPlatformStore = Ext.define(null, {
  extend: ResourceStore,

  baseUrl: '/api/system',
  resourceName: 'hostingplatforms',
  record: HostingPlatformRecord,
  sortInfo: {
    field: 'description',
    direction: 'ASC'
  }
})

module.exports = HostingPlatformStore
