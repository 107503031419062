const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const MailRelayHostRecord = require('admin/data/MailRelayHostRecord')
const MailRelayHostResource = require('admin/business/MailRelayHostResource')

const MailRelayHostStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'mailrelayhosts',
  sortInfo: {
    field: 'name',
    direction: 'ASC'
  },

  record: MailRelayHostRecord,

  module() {
    return MailRelayHostResource
  }
})

module.exports = MailRelayHostStore
