// an abstract entity for entities which can be billed. these are
// distributor, reseller and customer (but not vendor!)
//
// they do share some common api stuff and have some common
// attributes that can be read and/or written.
//
// that said, this is basically like an abstract class and
// should not be instantiated directly.

const Ext = require('ext')
const Record = require('admin/core/Record')
const User = require('admin/core/User')
const CompanyRecord = require('admin/data/company/CompanyRecord')
const BillableFields = require('admin/data/company/fields/billable').default

const BillableRecord = Record.define(null, {
  statics: {
    // make it static so that other records can access this
    defaultFields: BillableFields
  },

  extend: CompanyRecord,
  fields: BillableFields,

  getCurrency() {
    return User.getDefaultCurrency()
  },

  getParams(options) {
    if (options == null) {
      options = {}
    }
    const params = {}

    // the billing and details options help us to specify which fields
    // to send to server. for customers for example we always include both,
    // see customer record.coffee which inherits this class.

    if (options.billing) {
      params.primaryBillingEmailAddress = this.get('primaryBillingEmailAddress')
      params.secondaryBillingEmailAddress = this.get('secondaryBillingEmailAddress')
    }

    if (options.details) {
      params['partyBillings[0].defaultCurrency'] = this.getCurrency()
    }

    return Ext.apply(this.callParent([options]), params)
  }
})

module.exports = BillableRecord
