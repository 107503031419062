const Record = require('admin/core/Record')
const User = require('admin/core/User')
const requestPromise = require('admin/util/Promise').default
const UserResource = require('admin/business/UserResource')
const NoteStore = require('admin/data/NoteStore')
const SubscriptionStore = require('reporting/SubscriptionStore')
const logger = require('system/logger').default
const ProductResources = require('product/resources')
const compareStrings = require('strings/compare/lexical')

const UserRecord = Record.define(null, {
  module() {
    return UserResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'url',
      type: 'string'
    },
    {
      name: 'authenticationSource',
      type: 'string',
      convert(value) {
        if (!value || value === '') {
          return 'Local'
        } // backward compatibility
        return value
      }
    },
    {
      name: 'displayName',
      type: 'string',
      convert(value, record) {
        return record.displayName || record.firstName + ' ' + record.lastName
      }
    },
    {
      name: 'firstName',
      type: 'string'
    },
    {
      name: 'lastName',
      type: 'string'
    },
    {
      name: 'userName',
      type: 'string'
    },
    {
      name: 'contactNumber',
      type: 'string',
      convert(input) {
        if (input?.indexOf('x') !== -1) {
          return input.substring(0, input.indexOf('x'))
        }
        return input
      }
    },
    {
      name: 'extension',
      type: 'string',
      mapping: 'contactNumber',
      convert(input) {
        if (input?.indexOf('x') !== -1) {
          return input.substring(input.indexOf('x') + 1)
        }
        return ''
      }
    }
  ],

  getName() {
    return this.get('displayName')
  },

  getDescription() {
    return this.get('displayName')
  },

  canRemove() {
    return UserResource.canRemove() && this.get('id') !== User.getRecordId()
  },

  isOwnProfile() {
    return this.get('id') === User.getRecordId()
  },

  // The presence of delegated key tells us management
  // links are being controlled (keycloak)
  delegationEnabled() {
    return this.data.authenticationSource != null
  },

  // Confirms whether user is managed externally or not (e.g. keycloak)
  isDelegated() {
    const authenticationSource = this.get('authenticationSource')
    if (!authenticationSource) {
      return false
    } // backward compatibility
    const result = compareStrings(authenticationSource.toLowerCase(), 'local')
    return result !== 0
  },

  getHumanAuthenticationSource() {
    const authenticationSource = this.get('authenticationSource')
    if (!authenticationSource) {
      return 'Local'
    } // is undefined after creation
    if (authenticationSource.toLowerCase() === 'O365'.toLowerCase()) {
      return 'Microsoft 365'
    }

    return authenticationSource
  },

  getUpdatePasswordLink() {
    const link = this.getUriByRel(ProductResources.USER_UPDATE_PASSWORD_REL)

    if (this.delegationEnabled()) {
      return link
    } else if (link) {
      // backend returning links but there is no keycloak
      return link
    }
    // backward compatibility when there is no keycloak impl or
    // backend is an older version
    return this.getUrl() + '/password'
  },

  getUpdateDetailsLink() {
    const link = this.getUriByRel(ProductResources.USER_UPDATE_DETAIL_REL)

    if (this.delegationEnabled()) {
      return link
    } else if (link != null) {
      // backend returning links but there is no keycloak
      return link
    }
    // backward compatibility when there is no keycloak impl or
    // backend is an older version
    return this.getUrl()
  },

  canSave() {
    return UserResource.canSave() || this.isOwnProfile()
  },

  getContactNumber() {
    const contactNumber = this.get('contactNumber')
    const extension = this.get('extension')

    if (extension) {
      return contactNumber + 'x' + extension
    }

    return contactNumber
  },

  getParams() {
    this.data.displayName = this.get('firstName') + ' ' + this.get('lastName')

    // since http://youtrack.smxemail.com/issue/SCL-943 the username
    // is the same as the email address
    // and we do not submit the email address in another field anymore.

    return {
      firstName: this.get('firstName'),
      lastName: this.get('lastName'),
      contactNumber: this.getContactNumber(),
      userName: this.get('userName')
    }
  },

  getNoteStore() {
    if (!this.noteStore) {
      this.noteStore = new NoteStore({ parentRecord: this })
    }

    return this.noteStore
  },

  getSubscriptionStore() {
    if (!this.subscriptionStore) {
      this.subscriptionStore = new SubscriptionStore({ parentRecord: this })
    }

    return this.subscriptionStore
  },

  // Merge assignable and assigned with corresponding flags, returns promise.
  getRoles() {
    return requestPromise({
      method: 'GET',
      url: this.getUrl() + '/roles',
      json: true
    }).then(
      (envelope) => {
        // ensure backward compatibility, editable flag is a recent addition
        const editable = 'editable' in envelope.data ? envelope.data.editable : true

        // merge assignable and assigned roles into one collection for display
        const roles = []

        // but only when editable
        if (editable) {
          roles.push(
            envelope.data.assignableRoles.map((r) =>
              Object.assign(r, { assignable: true })
            )
          )
        }

        roles.push(
          envelope.data.assignedRoles.map((r) => Object.assign(r, { assigned: true }))
        )

        const flattened = [].concat(...roles)

        // we need them in a sorted map where id is the key
        // for optimal display on UI
        const grouped = flattened.reduce((grouped, value) => {
          if (grouped[value.id]) {
            grouped[value.id] = Object.assign(value, grouped[value.id])
          } else {
            grouped[value.id] = value
          }

          return grouped
        }, {})

        return { roles: grouped, editable }
      },
      function (err) {
        logger.error(err)

        throw err
      }
    )
  },

  // Save roles using the same format as getRoles, returns promise.
  saveRoles(roles) {
    const filtered = Object.values(roles)
      .filter((role) => role.assigned && role.assignable)
      .map((role) => role.code)

    return requestPromise({
      method: 'PUT',
      url: this.getUrl() + '/roles',
      params: {
        assignedRoles: filtered.join(',')
      }
    })
  }
})

module.exports = UserRecord
