import * as Calendar from 'admin/time/Calendar'
import { getDefaultTimeZoneName } from 'admin/time/TimeZone'
import Alert from 'admin/util/Alert'
import Api from 'api'
import * as File from 'api/file'
import * as hal from 'hal'
import formatProgress from 'mail/search/release/formatProgress'
import {
  EVENT_DETAIL_REL,
  QUARANTINE_MESSAGE_REL,
  QUARANTINE_PREVIEW_REL,
  QUARANTINE_RELEASE_REL
} from 'product/resources'
import logger from 'system/logger'

import { toMailSearchDetail, toMailSearchResults } from './converters'

// limiting the from date in the date picker to up to 30 days ago
// because quarantine's ttl is 31 days
const THIRTY_DAYS_IN_DAYS = 30

export const QUARANTINE_SEARCH_ENABLE_HIGHLIGHTING =
  'QUARANTINE_SEARCH_ENABLE_HIGHLIGHTING'
export const QUARANTINE_SEARCH_OPEN_CONFIRMING_RELEASE =
  'QUARANTINE_SEARCH_OPEN_CONFIRMING_RELEASE'
export const QUARANTINE_SEARCH_CLOSE_CONFIRMING_RELEASE =
  'QUARANTINE_SEARCH_CLOSE_CONFIRMING_RELEASE'
export const QUARANTINE_SEARCH_SET_DIRECTION = 'QUARANTINE_SEARCH_SET_DIRECTION'
export const QUARANTINE_SEARCH_SET_TIME_ZONE_KEY = 'QUARANTINE_SEARCH_SET_TIME_ZONE_KEY'
export const QUARANTINE_SEARCH_SET_QUERY = 'QUARANTINE_SEARCH_SET_QUERY'
export const QUARANTINE_SEARCH_SET_RESULTS_QUERY = 'QUARANTINE_SEARCH_SET_RESULTS_QUERY'
export const QUARANTINE_SEARCH_SET_URI = 'QUARANTINE_SEARCH_SET_URI'
export const QUARANTINE_SEARCH_SET_START_DATE = 'QUARANTINE_SEARCH_SET_START_DATE'
export const QUARANTINE_SEARCH_SET_DATE_RANGE = 'QUARANTINE_SEARCH_SET_DATE_RANGE'
export const QUARANTINE_SEARCH_SET_DATE_RANGE_SHOWN =
  'QUARANTINE_SEARCH_SET_DATE_RANGE_SHOWN'
export const QUARANTINE_SEARCH_SET_DATE_RANGE_HIDDEN =
  'QUARANTINE_SEARCH_SET_DATE_RANGE_HIDDEN'
export const QUARANTINE_SEARCH_INIT_DB = 'QUARANTINE_SEARCH_INIT_DB'
export const QUARANTINE_SEARCH_SET_RESULTS = 'QUARANTINE_SEARCH_SET_RESULTS'
export const QUARANTINE_SEARCH_SET_OFFSET = 'QUARANTINE_SEARCH_SET_OFFSET'
export const QUARANTINE_SEARCH_START_LOADING = 'QUARANTINE_SEARCH_START_LOADING'
export const QUARANTINE_SEARCH_STOP_LOADING = 'QUARANTINE_SEARCH_STOP_LOADING'
export const QUARANTINE_SEARCH_SET_LOCATION = 'QUARANTINE_SEARCH_SET_LOCATION'
export const QUARANTINE_SEARCH_SELECT_ROWS = 'QUARANTINE_SEARCH_SELECT_ROWS'
export const QUARANTINE_SEARCH_SET_RELEASE_SUCCESSFUL =
  'QUARANTINE_SEARCH_SET_RELEASE_SUCCESSFUL'
export const QUARANTINE_SEARCH_SET_RELEASE_FAILED = 'QUARANTINE_SEARCH_SET_RELEASE_FAILED'
export const QUARANTINE_SEARCH_SET_RELEASE_SKIPPED =
  'QUARANTINE_SEARCH_SET_RELEASE_SKIPPED'
export const QUARANTINE_SEARCH_SET_RELEASE_SIZE = 'QUARANTINE_SEARCH_SET_RELEASE_SIZE'
export const QUARANTINE_SEARCH_CLEAR_RELEASE_PROGRESS =
  'QUARANTINE_SEARCH_CLEAR_RELEASE_PROGRESS'
export const QUARANTINE_SEARCH_SET_PREVIEW = 'QUARANTINE_SEARCH_SET_PREVIEW'
export const QUARANTINE_SEARCH_CLEAR_PREVIEW = 'QUARANTINE_SEARCH_CLEAR_PREVIEW'
export const QUARANTINE_SEARCH_TOGGLE_ATTACHMENT_PANEL =
  'QUARANTINE_SEARCH_TOGGLE_ATTACHMENT_PANEL'
export const QUARANTINE_SEARCH_CLEAR_SELECTION = 'QUARANTINE_SEARCH_CLEAR_SELECTION'
export const QUARANTINE_SEARCH_CONCAT_RESULTS = 'QUARANTINE_SEARCH_CONCAT_RESULTS'
export const QUARANTINE_SEARCH_CLEAR_RESULTS = 'QUARANTINE_SEARCH_CLEAR_RESULTS'
export const QUARANTINE_SEARCH_SET_RECIPIENTS_VALID =
  'QUARANTINE_SEARCH_SET_RECIPIENTS_VALID'
export const QUARANTINE_SEARCH_SET_ORIGINAL_RECIPIENTS =
  'QUARANTINE_SEARCH_SET_ORIGINAL_RECIPIENTS'
export const QUARANTINE_SEARCH_CLEAR_ORIGINAL_RECIPIENTS =
  'QUARANTINE_SEARCH_CLEAR_ORIGINAL_RECIPIENTS'
export const QUARANTINE_SEARCH_SET_DETAIL = 'QUARANTINE_SEARCH_SET_DETAIL'
export const QUARANTINE_SEARCH_CLEAR_DETAIL = 'QUARANTINE_SEARCH_CLEAR_DETAIL'
export const QUARANTINE_SEARCH_SET_COLUMN_WIDTH = 'QUARANTINE_SEARCH_SET_COLUMN_WIDTH'
export const QUARANTINE_SEARCH_CLEAR_SCROLL_TO_INDEX =
  'QUARANTINE_SEARCH_CLEAR_SCROLL_TO_INDEX'
export const QUARANTINE_SEARCH_SET_SCROLL_TO_INDEX =
  'QUARANTINE_SEARCH_SET_SCROLL_TO_INDEX'
export const QUARANTINE_SEARCH_SET_SOURCE = 'QUARANTINE_SEARCH_SET_SOURCE'
export const QUARANTINE_SEARCH_CLEAR_SOURCE = 'QUARANTINE_SEARCH_CLEAR_SOURCE'

const startLoading = (companyKey) => ({
  type: QUARANTINE_SEARCH_START_LOADING,
  companyKey
})

const stopLoading = (companyKey) => ({ type: QUARANTINE_SEARCH_STOP_LOADING, companyKey })

/// ========= INIT ========= ///

const setUri = (companyKey, uri) => ({
  type: QUARANTINE_SEARCH_SET_URI,
  companyKey,
  uri
})

const initDb = (companyKey) => ({
  type: QUARANTINE_SEARCH_INIT_DB,
  companyKey
})

export const init = (companyKey, uri) => (dispatch) => {
  dispatch(initDb(companyKey))
  dispatch(setUri(companyKey, uri))

  dispatch(setTimeZoneName(companyKey, getDefaultTimeZoneName()))

  const startDate = Calendar.atMidnight(Calendar.daysAgo(THIRTY_DAYS_IN_DAYS))
    .toUTC()
    .toISO()

  dispatch(setStartDate(companyKey, startDate))

  const fromDate = Calendar.atMidnight(Calendar.daysAgo(THIRTY_DAYS_IN_DAYS))
    .toUTC()
    .toISO()

  const thruDate = Calendar.atMidnight().toUTC().toISO()

  dispatch(setDateRange(companyKey, fromDate, thruDate))
}

/// ========= SEARCH PARAMETERS ========= ///

export const setTimeZoneName = (companyKey, timeZoneName) => ({
  type: QUARANTINE_SEARCH_SET_TIME_ZONE_KEY,
  companyKey,
  timeZoneName
})

export const setQuery = (companyKey, query) => ({
  type: QUARANTINE_SEARCH_SET_QUERY,
  companyKey,
  query
})

export const setStartDate = (companyKey, startDate) => ({
  type: QUARANTINE_SEARCH_SET_START_DATE,
  companyKey,
  startDate
})

export const setDateRange = (companyKey, fromDate, thruDate) => ({
  type: QUARANTINE_SEARCH_SET_DATE_RANGE,
  companyKey,
  fromDate,
  thruDate
})

export const setDateRangeShown = (companyKey) => ({
  type: QUARANTINE_SEARCH_SET_DATE_RANGE_SHOWN,
  companyKey
})

export const setDateRangeHidden = (companyKey) => ({
  type: QUARANTINE_SEARCH_SET_DATE_RANGE_HIDDEN,
  companyKey
})

export const setDirection = (companyKey, direction) => ({
  type: QUARANTINE_SEARCH_SET_DIRECTION,
  companyKey,
  direction
})

/// ========= SEARCH/RESULTS/PAGINATION ========= ///

export const setResults = (companyKey, results) => ({
  type: QUARANTINE_SEARCH_SET_RESULTS,
  companyKey,
  results
})

export const setResultsQuery = (companyKey, query) => ({
  type: QUARANTINE_SEARCH_SET_RESULTS_QUERY,
  companyKey,
  query
})

export const clearResults = (companyKey) => ({
  // should be private
  type: QUARANTINE_SEARCH_CLEAR_RESULTS,
  companyKey
})

export const concatResults = (companyKey, moreResults) => ({
  type: QUARANTINE_SEARCH_CONCAT_RESULTS,
  companyKey,
  moreResults
})

const setLocation = (companyKey, location) => ({
  type: QUARANTINE_SEARCH_SET_LOCATION,
  companyKey,
  location
})

const setOffset = (companyKey, offset) => ({
  type: QUARANTINE_SEARCH_SET_OFFSET,
  companyKey,
  offset
})

function fetchResults(location, limit, offset = 0) {
  return Api.get(location, { params: { limit, offset } })
}

function setPage(dispatch, companyKey, query, results, timeZoneName) {
  // set the query first before results to ensure table wont get rendered twice
  // because it will be shown only when results are set but results query can retrigger a render
  dispatch(setResultsQuery(companyKey, query))
  dispatch(setResults(companyKey, toMailSearchResults(results, { timeZoneName })))
  dispatch(setScrollToIndex(companyKey, 0))
}

export const search =
  (companyKey, uri, viewing, parameters, timeZoneName) => async (dispatch) => {
    dispatch(startLoading(companyKey))

    const { limit, query } = parameters

    // always set back to zero when running a new search
    const offset = 0

    dispatch(setOffset(companyKey, offset))

    try {
      // create a search resource first
      const response = await Api.post(uri, parameters, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })

      const location = response.headers.location

      // store it cos we need to remember location for pagination
      dispatch(setLocation(companyKey, location))

      // now fetch search result themselves off that location
      const responseFirstPage = await fetchResults(location, limit)
      let results = hal.resource(responseFirstPage.data, QUARANTINE_MESSAGE_REL)

      if (!results) results = []

      // only fetch next page when necessary
      if (results.length >= limit) {
        // special treatment since SCL-3635 because of poor backend performance:
        // already show the results of first page to the user while loading second page
        setPage(dispatch, companyKey, query, results, timeZoneName)

        if (viewing) {
          dispatch(back(companyKey))
        }

        dispatch(stopLoading(companyKey)) // make UI already available to user

        // now fetch next search result, so that hasNext can work properly
        const responseSecondPage = await fetchResults(location, limit, offset + limit)
        const secondResults = toMailSearchResults(
          hal.resource(responseSecondPage.data, QUARANTINE_MESSAGE_REL),
          {
            timeZoneName
          }
        )

        if (secondResults) {
          dispatch(concatResults(companyKey, secondResults))
        }
      } else {
        setPage(dispatch, companyKey, query, results, timeZoneName)
      }
    } catch (err) {
      dispatch(clearResults(companyKey)) // remove any existing results to reflect error state
      logger.error(err)
    } finally {
      if (viewing) {
        dispatch(back(companyKey)) // in case user was viewing before
      }

      dispatch(stopLoading(companyKey))
    }
  }

// technically this is prefetch.
// it behaves a bit differently when paginating a whole table or
// when viewing one entry.
export const next = (companyKey, params) => async (dispatch) => {
  const {
    timeZoneName,
    viewing,
    nextMail,
    limit,
    location,
    nextOffset,
    paginateNext,
    prefetchOffset,
    prefetch,
    nextRelativeIndex
  } = params

  if (viewing) {
    await dispatch(view(companyKey, nextMail, nextRelativeIndex))
  }

  if (paginateNext) {
    // set offset to real offset increased by one limit
    dispatch(setOffset(companyKey, nextOffset))
  }

  if (!viewing) {
    dispatch(clearSelection(companyKey))
  }

  if (!prefetch) return

  dispatch(startLoading(companyKey))

  try {
    const response = await fetchResults(location, limit, prefetchOffset)
    const moreResults = toMailSearchResults(
      hal.resource(response.data, QUARANTINE_MESSAGE_REL),
      {
        timeZoneName
      }
    )

    // since backend doesn't give us a nextLink we only amend if there are really
    // more results. for archiving and continuity it does hence a bit different there.
    if (moreResults) {
      dispatch(concatResults(companyKey, moreResults))
    }

    if (paginateNext && !viewing) {
      dispatch(setScrollToIndex(companyKey, 0))
    }
  } catch (err) {
    logger.error(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

// prev is always from cache
export const prev = (companyKey, params) => async (dispatch) => {
  const { viewing, prevIndex, prevMail, offset, limit } = params

  if (viewing) {
    const paginate = prevIndex < offset
    const prevRelativeIndex = paginate ? limit - 1 : prevIndex - offset

    await dispatch(view(companyKey, prevMail, prevRelativeIndex))

    if (paginate) {
      dispatch(setOffset(companyKey, offset - limit))
    }
  } else {
    dispatch(setScrollToIndex(companyKey, 0))
    dispatch(setOffset(companyKey, offset - limit))
  }

  dispatch(clearSelection(companyKey))
}

export const setColumnWidth = (companyKey, columnKey, width) => ({
  type: QUARANTINE_SEARCH_SET_COLUMN_WIDTH,
  companyKey,
  columnKey,
  width
})

/// ========= TABLE actions ========= ///

const clearScrollToIndex = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_SCROLL_TO_INDEX,
  companyKey
})

// public for unit tests
export const setScrollToIndex = (companyKey, scrollToIndex) => ({
  type: QUARANTINE_SEARCH_SET_SCROLL_TO_INDEX,
  companyKey,
  scrollToIndex
})

const clearSelection = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_SELECTION,
  companyKey
})

const selectRows = (companyKey, rows, selected) => ({
  type: QUARANTINE_SEARCH_SELECT_ROWS,
  companyKey,
  rows,
  selected
})

export const select = (companyKey, rows, selected) => (dispatch) => {
  dispatch(clearScrollToIndex(companyKey)) // ensures that table doesnt jump
  dispatch(selectRows(companyKey, rows, selected))
}

const setPreview = (companyKey, preview) => ({
  type: QUARANTINE_SEARCH_SET_PREVIEW,
  companyKey,
  preview
})

const clearPreview = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_PREVIEW,
  companyKey
})

export const view = (companyKey, mail, relativeIndex) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    // that mail object is a single entry from the search results
    // we are asking for details and a preview of that message first,
    // then store all that for the view.
    const detailUri = hal.href(mail, EVENT_DETAIL_REL)
    const detailResponse = await Api.get(detailUri)
    const searchDetail = toMailSearchDetail(detailResponse.data)

    // todo grab this from a self link when backend provides one
    searchDetail.uri = detailUri

    const previewUri = hal.href(mail, QUARANTINE_PREVIEW_REL)
    let previewResponse

    if (previewUri) {
      previewResponse = await Api.get(previewUri)
    }

    // only set both at the end to ensure both appear at the same time
    dispatch(setDetail(companyKey, searchDetail))
    if (previewResponse) {
      dispatch(setPreview(companyKey, previewResponse.data))
    }
  } catch (err) {
    logger.error(err)
  } finally {
    // necessary so that table's viewport scrolls to that row when going back
    dispatch(setScrollToIndex(companyKey, relativeIndex))

    dispatch(stopLoading(companyKey))
  }
}

export const back = (companyKey) => (dispatch) => {
  dispatch(clearPreview(companyKey))
  dispatch(clearDetail(companyKey))
}

/// ========= DETAIL VIEW actions ========= ///

export const toggleAttachmentPanel = (companyKey, expanded) => ({
  type: QUARANTINE_SEARCH_TOGGLE_ATTACHMENT_PANEL,
  companyKey,
  expanded
})

export const setDetail = (companyKey, detail) => ({
  type: QUARANTINE_SEARCH_SET_DETAIL,
  companyKey,
  detail
})

export const clearDetail = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_DETAIL,
  companyKey
})

/// ========= TOOLBAR actions ========= ///

export const enableHighlighting = (companyKey, enabled) => ({
  type: QUARANTINE_SEARCH_ENABLE_HIGHLIGHTING,
  companyKey,
  enabled
})

export const openConfirmingRelease = (companyKey) => ({
  type: QUARANTINE_SEARCH_OPEN_CONFIRMING_RELEASE,
  companyKey
})

export const closeConfirmingRelease = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLOSE_CONFIRMING_RELEASE,
  companyKey
})

const setSource = (companyKey, source) => ({
  type: QUARANTINE_SEARCH_SET_SOURCE,
  companyKey,
  source
})

export const download = (companyKey, downloadUri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    File.download(downloadUri)
  } catch (err) {
    logger.error(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

export const viewSource = (companyKey, downloadUri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(downloadUri)

    dispatch(setSource(companyKey, response.data))
  } catch (err) {
    logger.error(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

export const hideSource = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_SOURCE,
  companyKey
})

/// ========= Release-related actions ========= ///

export const setRecipientsValidity = (companyKey, valid) => ({
  type: QUARANTINE_SEARCH_SET_RECIPIENTS_VALID,
  companyKey,
  valid
})

export const setOriginalRecipients = (companyKey, originalRecipients) => ({
  type: QUARANTINE_SEARCH_SET_ORIGINAL_RECIPIENTS,
  companyKey,
  originalRecipients
})

export const clearOriginalRecipients = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_ORIGINAL_RECIPIENTS,
  companyKey
})

export const openReleaseDialog = (companyKey, rows) => async (dispatch) => {
  if (rows.length === 1) {
    const row = rows[0]
    if ('recipients' in row) {
      dispatch(setOriginalRecipients(companyKey, row.recipients))
    } else {
      const recipients = await getMailRecipients(row)
      dispatch(setOriginalRecipients(companyKey, recipients))
    }
    dispatch(setRecipientsValidity(companyKey, true))
  }
  dispatch(openConfirmingRelease(companyKey))
}

export const closeReleaseDialog = (companyKey) => async (dispatch) => {
  dispatch(clearOriginalRecipients(companyKey))
  dispatch(closeConfirmingRelease(companyKey))
}

async function getMailRecipients(mail) {
  const detailUri = hal.href(mail, EVENT_DETAIL_REL)
  const detailResponse = await Api.get(detailUri)
  const searchDetail = toMailSearchDetail(detailResponse.data)
  return searchDetail.recipients
}

async function releaseMail(mail, releaseUri, emails) {
  let recipients = emails || mail.recipients

  if (!recipients) {
    // missing recipients meaning it's invoked from list view
    const detailUri = hal.href(mail, EVENT_DETAIL_REL)
    const detailResponse = await Api.get(detailUri)
    const searchDetail = toMailSearchDetail(detailResponse.data)
    recipients = searchDetail.recipients
  }

  const params = { sid: mail.sid, recipient: recipients }
  return Api.post(releaseUri, params, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  })
}

const setSizeForRelease = (companyKey, size) => ({
  type: QUARANTINE_SEARCH_SET_RELEASE_SIZE,
  companyKey,
  size
})

const setSuccessfulForRelease = (companyKey, successful) => ({
  type: QUARANTINE_SEARCH_SET_RELEASE_SUCCESSFUL,
  companyKey,
  successful
})

const setFailedForRelease = (companyKey, failed) => ({
  type: QUARANTINE_SEARCH_SET_RELEASE_FAILED,
  companyKey,
  failed
})

const setSkippedForRelease = (companyKey, skipped) => ({
  type: QUARANTINE_SEARCH_SET_RELEASE_SKIPPED,
  companyKey,
  skipped
})

const clearReleaseProgress = (companyKey) => ({
  type: QUARANTINE_SEARCH_CLEAR_RELEASE_PROGRESS,
  companyKey
})

export const release = (companyKey, rows, emails) => async (dispatch) => {
  dispatch(startLoading(companyKey))
  dispatch(setSizeForRelease(companyKey, rows.length))

  let skipped = 0
  let failed = 0
  let released = 0

  // reset that to zero first
  dispatch(setSuccessfulForRelease(companyKey, released))

  try {
    for (const [, row] of rows.entries()) {
      const releaseUri = hal.href(row, QUARANTINE_RELEASE_REL)

      if (releaseUri) {
        try {
          await releaseMail(row, releaseUri, emails)
          dispatch(setSuccessfulForRelease(companyKey, ++released))
        } catch (err) {
          logger.error(err)
          dispatch(setFailedForRelease(companyKey, ++failed))
        }
      } else {
        dispatch(setSkippedForRelease(companyKey, ++skipped))
      }
    }

    const progressText = formatProgress(rows.length, released, skipped, failed)

    Alert.info('Quarantine Release', progressText)

    dispatch(closeConfirmingRelease(companyKey))
    dispatch(clearReleaseProgress(companyKey))
  } catch (exc) {
    logger.error(exc)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}
