import React from 'react'

import ReactContainerPanel from 'admin/view/ReactContainerPanel'
import Ext from 'ext'
import MailPanel from 'mail/details/views/Panel'
import t from 'translate'

const EventPreviewTab = Ext.define(null, {
  extend: ReactContainerPanel,
  title: t('Preview'),

  initComponent: function () {
    this.component = React.createElement(MailPanel, {
      preview: this.initialConfig.preview,
      authorized: true
    })

    this.addClass('quarantine-preview')

    // trick to call parent extjs function from es6 within
    this.superclass().initComponent.call(this)
  }
})

export default EventPreviewTab
