const Record = require('admin/core/Record')

const HostingPlatformRecord = Record.define(null, {
  fields: [
    { name: 'relayHost', type: 'string' },
    { name: 'description', type: 'string' }
  ],

  getName() {
    return this.get('relayHost')
  },

  getDescription() {
    return this.get('description')
  }
})

module.exports = HostingPlatformRecord
