const Ext = require('ext')
const Record = require('admin/core/Record')
const Time = require('admin/time/Time')

const InvoiceRunRecord = Record.define(null, {
  module() {
    return require('admin/business/InvoiceRunResource')
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'invoiceRunDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'invoicerRole.party.groupName',
      type: 'string'
    }
  ],

  getName() {
    const invoiceRunDate = this.get('invoiceRunDate')
    if (Ext.isDate(invoiceRunDate)) {
      return invoiceRunDate.format(Date.patterns.SHORT)
    }
    return invoiceRunDate
  },

  getDescription() {
    return String.format(
      '{0}: {1}',
      this.get('invoicerRole.party.groupName'),
      this.get('invoiceRunDate')
    )
  },

  getParams() {
    return { invoiceRunDate: this.get('invoiceRunDate') }
  },

  getInvoiceStore() {
    const InvoiceStore = require('admin/data/InvoiceStore')

    if (!this.invoiceStore) {
      this.invoiceStore = new InvoiceStore({
        parentRecord: this
      })
    }

    return this.invoiceStore
  }
})

module.exports = InvoiceRunRecord
