const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const SubscriptionDetailPanel = require('reporting/SubscriptionDetailPanel')
const SubscriptionNewPanel = require('reporting/SubscriptionNewPanel')
const SubscriptionListPanel = require('reporting/SubscriptionListPanel')
const SubscriptionRecord = require('reporting/SubscriptionRecord')
const UserResource = require('admin/business/UserResource')
const Report = require('reporting/Report')

const Subscription = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Subscription',
  translations: {
    title: t('Subscription'),
    plural: t('Subscriptions'),
    new: t('New Subscription'),
    open: t('Open Subscription'),
    list: t('List Subscriptions'),
    remove: t('Cancel Subscription')
  },
  name: t('Subscription'),

  code: 'SUBSCRIPTION',

  resourceName: 'subscriptions',
  layout: {
    newResource: {
      width: 420,
      height: 'auto'
    },
    openResource: {
      width: 420,
      height: 'auto',
      resizable: false
    }
  },

  operations: {
    create: true,
    save: true,
    open: true,
    remove: true,
    list: true
  },

  detailPanel: SubscriptionDetailPanel,
  newPanel: SubscriptionNewPanel,
  listPanel: SubscriptionListPanel,

  record: SubscriptionRecord,

  getParentResources() {
    return [UserResource, Report]
  },

  // overriden since SCL-2513
  hasPermission(action) {
    // because there are no permissions for subscriptions we're using
    // report code for that check
    return Report.hasPermission(action)
  }
})

module.exports = Subscription
