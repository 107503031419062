const DOMPurify = require('dompurify')
const Ext = require('ext/ext-base')

/**
 * @class Ext.ux.Link
 * @extends Ext.Component
 * Simple Link class
 * @cfg {String} text The link text
 * @cfg {Function} handler A function called when the link is clicked (can be used instead of click event)
 * @cfg {Object} scope The scope of the handler
 * @cfg {String/Object} tooltip The tooltip for the link - can be a string or QuickTips config object
 * @cfg {Boolean} hidden True to start hidden (defaults to false)
 * @cfg {Boolean} disabled True to start disabled (defaults to false)
 * @constructor
 * Create a new link
 * @param {Object} config The config object
 */
Ext.ux.Link = Ext.extend(Ext.BoxComponent, {
  constructor: function (cfg) {
    cfg = cfg || {}
    if (cfg.text) {
      cfg.text = DOMPurify.sanitize(cfg.text)
    }
    Ext.ux.Link.superclass.constructor.call(this, cfg)
  },

  /**
   * Read-only. True if this button is hidden
   * @type Boolean
   */
  hidden: false,
  /**
   * Read-only. True if this button is disabled
   * @type Boolean
   */
  disabled: false,
  /**
   * The Button's owner {@link Ext.Panel} (defaults to undefined, and is set automatically when
   * the Button is added to a container).  Read-only.
   * @type Ext.Panel
   * @property ownerCt
   */

  /**
   * @cfg {String} linkSelector
   * <p>(Optional) A {@link Ext.DomQuery DomQuery} selector which is used to extract the active, clickable element from the
   * DOM structure created.</p>
   * <p>When a custom {@link #template} is used, you  must ensure that this selector results in the selection of
   * a focussable element.</p>
   * <p>Defaults to <b><tt>"a"</tt></b>.</p>
   */
  linkSelector: 'a',

  /**
   * @cfg {Number} tabIndex Set a DOM tabIndex for this button (defaults to undefined)
   */

  /**
   * @cfg {String} clickEvent
   * The type of event to map to the button's event handler (defaults to 'click')
   */
  clickEvent: 'click',

  /**
   * @cfg {String} tooltipType
   * The type of tooltip to use. Either "qtip" (default) for QuickTips or "title" for title attribute.
   */
  tooltipType: 'qtip',

  /**
   * @cfg {Ext.Template} template
   * (Optional) An {@link Ext.Template} with which to create the Link's main element. This Template must
   * contain numeric substitution parameter 0 if it is to display the text property. Changing the template could
   * require code modifications if required elements (e.g. a link) aren't present.
   */
  /**
   * @cfg {String} cls
   * A CSS class string to apply to the button's main element.
   */

  initComponent: function () {
    Ext.ux.Link.superclass.initComponent.call(this)

    this.addEvents(
      /**
       * @event click
       * Fires when this button is clicked
       * @param {Button} this
       * @param {EventObject} e The click event
       */
      'click'
    )
  },

  // private
  onRender: function (ct, position) {
    if (!this.template) {
      if (!Ext.ux.Link.linkTemplate) {
        // hideous table template
        Ext.ux.Link.linkTemplate = new Ext.Template(
          '<div class="next-link-wrap">',
          '<a href="#" class="next-link-text" type="{1}">{0}</a>',
          '</div>'
        )
      }
      this.template = Ext.ux.Link.linkTemplate
    }
    let link
    const targs = [this.text || '&#160;', this.type]

    if (position) {
      link = this.template.insertBefore(position, targs, true)
    } else {
      link = this.template.append(ct, targs, true)
    }
    /**
     * An {@link Ext.Element Element} encapsulating the Button's clickable element. This references
     * a <tt>&lt;a&gt;</tt> element. Read only.
     * @type Ext.Element
     * @property linkEl
     */
    const linkEl = (this.linkEl = link.child(this.linkSelector))
    linkEl.on('focus', this.onFocus, this)
    linkEl.on('blur', this.onBlur, this)

    this.initLinkEl(link, linkEl)
  },

  // private
  initLinkEl: function (link, linkEl) {
    this.el = link
    link.addClass('next-link')

    if (this.id) {
      this.el.dom.id = this.el.id = this.id
    }
    if (this.tabIndex !== undefined) {
      linkEl.dom.tabIndex = this.tabIndex
    }
    if (this.tooltip) {
      if (typeof this.tooltip === 'object') {
        Ext.QuickTips.register(
          Ext.apply(
            {
              target: linkEl.id
            },
            this.tooltip
          )
        )
      } else {
        linkEl.dom[this.tooltipType] = this.tooltip
      }
    }

    link.on(this.clickEvent, this.onClick, this)
  },

  // private
  beforeDestroy: function () {
    if (this.rendered) {
      if (this.linkEl) {
        if (typeof this.tooltip === 'object') {
          Ext.QuickTips.unregister(this.linkEl)
        }
        Ext.destroy(this.linkEl)
      }
    }
  },

  // private
  onDestroy: function () {},

  /**
   * Assigns this button's click handler
   * @param {Function} handler The function to call when the button is clicked
   * @param {Object} scope (optional) Scope for the function passed in
   */
  setHandler: function (handler, scope) {
    this.handler = handler
    this.scope = scope
  },

  /**
   * Sets this button's text
   * @param {String} text The button text
   */
  setText: function (text) {
    this.text = DOMPurify.sanitize(text)
    if (this.el) {
      this.el.child(this.linkSelector).update(DOMPurify.sanitize(text))
    }
  },

  /**
   * Gets the text for this button
   * @return {String} The button text
   */
  getText: function () {
    return DOMPurify.sanitize(this.text)
  },

  /**
   * Focus the button
   */
  focus: function () {
    this.linkEl.focus()
  },

  // private
  onDisable: function () {
    this.onDisableChange(true)
  },

  // private
  onEnable: function () {
    this.onDisableChange(false)
  },

  // private
  onDisableChange: function (disabled) {
    if (this.el) {
      if (!this.text) {
        this.el[disabled ? 'addClass' : 'removeClass'](this.disabledClass)
      }
      this.el.dom.disabled = disabled
    }
    this.disabled = disabled
  },

  // private
  onClick: function (e) {
    if (e) {
      e.preventDefault()
    }
    if (e.button) {
      return
    }
    if (!this.disabled) {
      this.fireEvent('click', this, e)
      if (this.handler) {
        this.handler.call(this.scope || this, this, e)
      }
    }
  },

  // private
  onFocus: function () {
    if (!this.disabled) {
      this.el.addClass('next-link-focus')
    }
  },
  // private
  onBlur: function () {
    this.el.removeClass('next-link-focus')
  },

  // private
  getClickEl: function () {
    return this.el
  }

  /**
   * @cfg {String} autoEl @hide
   */
})

Ext.reg('link', Ext.ux.Link)
