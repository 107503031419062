import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { createMailTarget, stopCreating } from 'quarantine/mail_targets/actions'
import { uri } from 'quarantine/mail_targets/subs'
import { uri as quarantineSetsUri } from 'quarantine/quarantine_sets/subs'
import { connect } from 'react-redux'
import t from 'translate'

import Dialog from './Dialog'

class CreateForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.createMailTarget(this.props.uri, values, this.props.quarantineSetsUri)
  }

  render() {
    return (
      <Dialog
        title={t('Create New Mail Target')}
        store={this.props.store}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        submitButtonText={t('Create')}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  uri: uri(db, props.companyKey),
  quarantineSetsUri: quarantineSetsUri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopCreating(initialProps.companyKey)),
  createMailTarget: (uri, values, quarantineSetsUri) =>
    dispatch(createMailTarget(initialProps.companyKey, uri, values, quarantineSetsUri))
})

export default connect(mapDbToProps, mapActionsToProps)(CreateForm)
