import React from 'react'

import User from 'admin/core/User'
import Alert from 'admin/util/Alert'
import Api from 'api'
import { set as setToken } from 'api/token'
import Form from 'components/legacy/Form'
import PasswordInput from 'components/legacy/PasswordInput'
import Ext from 'ext'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'
import logger from 'system/logger'
import t from 'translate'

class ChangePassword extends React.Component {
  static propTypes = {
    uri: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isOwnProfile: PropTypes.bool,
    prompt: PropTypes.string,
    minLength: PropTypes.number
  }

  state = { saving: false }

  constructor(props) {
    super(props)

    this.formRef = React.createRef()
  }

  // for those who cannot manage users or do but change their own profile,
  // always ask for the current password as well
  requireCurrentPassword = () => {
    return !User.hasRole('MANAGE_USERS') || this.props.isOwnProfile
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({ saving: true })
    Ext.MessageBox.wait(t('Saving...'))

    // beware this doesn't return all the form inputs but also
    // labels and other html elements (known tech debt)
    const formData = this.formRef.current.getData()

    const postData = {
      password: formData.password,
      // http://youtrack.smxemail.com/issue/SCL-113#comment=49-28286
      passwordChangeRequired: false
    }

    if (this.requireCurrentPassword()) {
      postData.currentPassword = formData.currentPassword
    }

    return Api.post(this.props.uri, postData, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    })
      .then((resp) => {
        this.setState({ saving: false })
        if (resp.data) {
          // backend can return new token if the password change was
          // meant for current user on the other hand, when no token
          // is returned, then that was a password change for a
          // different user! see http://youtrack.smxemail.com/issue/SCL-1380
          if (resp.data.token) {
            setToken(resp.data.token)
          }
        }
        Alert.info(t('Success'), t('Password set.'))
        this.props.onSuccess()
      })
      .catch((err) => {
        this.setState({ saving: false })
        logger.error(err)
      })
      .finally(() => {
        Ext.MessageBox.hide()
      })
  }

  render() {
    return (
      <div className='changePassword'>
        {this.props.prompt && (
          <Row>
            <div className='information'>{this.props.prompt}</div>
          </Row>
        )}
        <Form
          ref={this.formRef}
          colWidth={11}
          onSubmit={this.onSubmit}
          onCancel={this.props.onCancel}
          saving={this.state.saving}
          stretch
          method='post'
        >
          <PasswordInput
            stretch
            label={t('New Password')}
            repeatLabel={t('Confirm New Password')}
            requireCurrentPassword={this.requireCurrentPassword()}
            minLength={this.props.minLength}
          />
        </Form>
      </div>
    )
  }
}

export default ChangePassword
