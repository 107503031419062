const Ext = require('ext')
const t = require('translate')
const WindowMgr = require('admin/desktop/WindowMgr').default
const FormPanel = require('admin/component/form/FormPanel')
const ReadOnlyTextArea = require('admin/component/form/ReadOnlyTextArea')
const DateField = require('admin/component/form/DateField')
const escape = require('html-escaper').escape
const ProvisionStatusView = require('admin/view/ProvisionStatusView')
const logger = require('system/logger').default
const store = require('store').default
const { isCapableOfInvoicing } = require('system/capability/subs')

const AgreementCancelWindow = Ext.define(null, {
  extend: Ext.Window,

  modal: true,
  title: t('Cancel'),
  width: 500,
  minWidth: 500,
  height: 460,
  minHeight: 460,

  initComponent() {
    // Needed so window appears at correct z index.
    this.manager = WindowMgr.windowGroup

    // Store summaries for each date.
    this.summaries = []

    // Need to round hours so it matches getValue() result.
    const defaultDate = new Date()
    defaultDate.setHours(0, 0, 0, 0)

    const sortedAgreements = this.agreements.map((a) => a.get('fromDate')).sort()

    this.items = this.form = new FormPanel({
      items: [
        // Need to list product names because not all may be detailed in summary.
        new ReadOnlyTextArea({
          fieldLabel: t('Products'),
          anchor: '100%',
          // Try to guess height to avoid scroll bars.
          height: this.agreements.length * 14 + 6,
          value: this.agreements.map((a) => a.get('productName')).join('\n')
        }),
        (this.date = new DateField({
          fieldLabel: t('Date'),
          value: defaultDate,
          minValue: sortedAgreements[sortedAgreements.length - 1],
          allowBlank: false,
          enableKeyEvents: true,
          listeners: {
            change: () => this.updateComponents(),
            select: () => this.updateComponents(),
            keyup: () => this.updateComponents()
          }
        })),
        isCapableOfInvoicing(store.getState()) &&
          (this.finalBilling = new Ext.form.Checkbox({
            fieldLabel: t('Final billing'),
            listeners: {
              check: () => this.updateComponents()
            }
          })),
        (this.summary = new ProvisionStatusView({
          fieldLabel: t('Summary'),
          anchor: '100%'
        }))
      ],
      buttons: [
        new Ext.Button({
          text: t('Close'),
          handler: () => {
            return this.close()
          }
        }),
        (this.confirm = new Ext.Button({
          text: t('Confirm'),
          disabled: true,
          handler: () => {
            return this.handleConfirm()
          }
        }))
      ]
    })

    this.on('afterlayout', this.resizeSummary)

    this.updateComponents()

    this.callParent()
  },

  updateComponents() {
    if (this.isDestroyed) return

    const dateValue = this.date.getValue()

    if (!dateValue || !dateValue.getTime) {
      this.confirm.setDisabled(true)
      return
    }

    const date = dateValue.getTime()

    let summary = this.summaries.find((summary) => summary.date === date)

    // If there is no summary, create one.
    if (!summary) {
      summary = { date }
      this.summaries.push(summary)
      this.agreementStore
        .getCancelSummary(this.agreements, date)
        .then((info) => {
          summary.info = info
          return this.updateComponents()
        })
        .catch((err) => {
          // Remove blank summary if error
          this.summaries = this.summaries.filter((item) => item === summary)
          logger.error(err)
        })
        .done()
    }

    this.setSummary(summary.info)
    this.confirm.setDisabled(!this.form.getForm().isValid() || !summary.info)
  },

  setSummary(summary) {
    let htmlOrData
    if (Array.isArray(summary)) {
      if (summary.length === 0) {
        // Show at least an OK if no other summary info is provided.
        htmlOrData = t('OK')
      } else {
        htmlOrData = summary
      }
    } else if (summary && typeof summary.valueOf() === 'string') {
      htmlOrData = escape(summary)
    } else {
      htmlOrData = t('Loading...')
    }

    if (this.summary.rendered) {
      return this.summary.update(htmlOrData)
    }
    this.summary.data = htmlOrData
  },

  handleConfirm() {
    this.disable()

    const finalBilling = this.finalBilling?.getValue() || null

    const todayMidnight = this.date.getValue()
    const tomorrowMidnight = todayMidnight.add(Date.DAY, 1)

    return this.agreementStore
      .cancel(this.agreements, tomorrowMidnight.getTime(), finalBilling)
      .then(() => this.close())
      .catch((err) => {
        this.enable()
        logger.error(err)
      })
      .done()
  },

  // Resize the summary to fill remaining space.
  resizeSummary() {
    this.summary.setHeight(
      this.body.getHeight() - (this.form.getHeight() - this.summary.getHeight())
    )
  }
})

module.exports = AgreementCancelWindow
