const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')
const AutoSizingTextArea = require('admin/component/form/AutoSizingTextArea')
const SmartRule = require('smartrules/designer/SmartRule')

const RefuseMessage = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Refuse message and reply with'),
      noSpacer: true,
      type: 'smartrules-refuse-message'
    })
    const textarea = new AutoSizingTextArea({
      allowBlank: false,
      flex: 1,
      name: 'value',
      ref: '../textarea',
      value: cfg.message,
      width: '98%'
    })

    const wrap = new Ext.Panel({
      flex: 1,
      layout: 'hbox',
      items: textarea
    })

    textarea.on(
      'autosize',
      function () {
        const h = textarea.getHeight()
        wrap.setHeight(h)
        this.setHeight(h + 12)
      },
      this
    )
    textarea.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
    this.callParent([cfg])
    this.insert(3, wrap)
  },

  toDsl() {
    const v = this.textarea.getValue().replace(/(["\\])/g, '\\$1')
    return `refuse message and reply with "${v}"`
  }
})

module.exports = RefuseMessage
