import { Button, ButtonToolbar, Checkbox, Col, Row } from 'react-bootstrap'
import React, { Component } from 'react'
import {
  busy,
  enableLink,
  enabledMemberNames,
  globalLink,
  isEmpty,
  isGlobal,
  loading,
  pristine,
  uri
} from 'archiving/membership/subs'
import { refresh, save, setGlobal } from 'archiving/membership/actions'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class Toolbar extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    haveMembersChanged: PropTypes.bool
  }

  onGlobalCheck = (e) => this.props.setGlobal(e.target.checked)
  onRefresh = () => this.props.refresh(this.props.uri)

  onSubmit = () => {
    this.props.save(
      this.props.uri,
      this.props.isGlobal,
      this.props.globalLink,
      this.props.enabledMemberNames,
      this.props.enableLink
    )
  }

  renderArchiveAllBox() {
    return (
      <Checkbox
        inline
        name='global'
        checked={this.props.isGlobal}
        onChange={this.onGlobalCheck}
        disabled={this.props.busy}
      >
        {t('Archive All')}{' '}
        <small>
          {t(
            '(includes future records, must be selected when using Shared Services (e.g. Microsoft 365).)'
          )}
        </small>
      </Checkbox>
    )
  }

  getIndicatorText() {
    return this.props.loading ? 'Loading...' : 'Saving...'
  }

  renderIndicator() {
    return <div className='loading-indicator'>{t(this.getIndicatorText())}</div>
  }

  render() {
    return (
      <div className='toolbar clearfix'>
        <Row>
          <Col xs={6}>{!this.props.isEmpty && this.renderArchiveAllBox()}</Col>
          <Col xs={Col.WIDTH_6}>
            <div className='pull-right'>
              <ButtonToolbar>
                {this.props.busy && this.renderIndicator()}
                <Button
                  className='refresh'
                  disabled={this.props.busy}
                  onClick={this.onRefresh}
                >
                  {t('Refresh')}
                </Button>

                <Button
                  className='save'
                  bsStyle='primary'
                  disabled={this.props.pristine || this.props.busy}
                  onClick={this.onSubmit}
                >
                  {t('Save')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  isEmpty: isEmpty(db, companyKey),
  isGlobal: isGlobal(db, companyKey),
  uri: uri(db, companyKey),
  busy: busy(db, companyKey),
  loading: loading(db, companyKey),
  enabledMemberNames: enabledMemberNames(db, companyKey),
  pristine: pristine(db, companyKey),
  globalLink: globalLink(db, companyKey),
  enableLink: enableLink(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  setGlobal: (global) => dispatch(setGlobal(companyKey, global)),
  refresh: (uri) => dispatch(refresh(companyKey, uri)),
  save: (uri, global, globalLink, enabledMemberNames, enableLink) =>
    dispatch(save(companyKey, uri, global, globalLink, enabledMemberNames, enableLink))
})

export default connect(mapDbToProps, mapActionsToProps)(Toolbar)
