const Ext = require('ext')
const t = require('translate')
const validators = require('validators').default
const RowEditor = require('admin/component/grid/RowEditor')
const logger = require('system/logger').default
const RowSelectionModel = require('admin/component/grid/RowSelectionModel')
const PolicyManagementResource = require('admin/business/PolicyManagementResource')
const validateIpAddress = require('validators/legacyIp').default
const escape = require('html-escaper').escape

const PoliciesGridPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,

  autoExpandColumn: 'operand',
  autoScroll: true,
  enableColumnHide: false,
  enableColumnMove: false,
  enableColumnResize: false,
  loadMask: true,

  initComponent() {
    this.view = new Ext.grid.GroupingView({
      forceFit: true,
      hideGroupedColumn: true,
      enableGroupingMenu: false,
      showGroupName: false,
      startCollapsed: true,
      groupTextTpl:
        '{[values.rs[0].get("type")]} <span style="font-weight:normal">({[values.rs.length]} {[values.rs.length > 1 ? "' +
        t('policies') +
        '" : "' +
        t('policy') +
        '"]})</span>'
    })

    this.editor = new RowEditor({ store: this.store })
    this.plugins = [this.editor]

    // Record being edited may be new.
    this.editor.on(
      'afteredit',
      function () {
        Ext.MessageBox.wait(t('Saving...'))
        return this.editor.record
          .save()
          .catch((err) => {
            if (this.editor.record.isNew()) {
              this.editor.record.destroy()
            }
            logger.error(err)
          })
          .finally(() => {
            this.selModel.selectRecords([this.editor.record])
            Ext.MessageBox.hide()
          })
      },
      this
    )

    this.editor.on('canceledit', () => {
      if (this.editor.record.isNew()) {
        this.editor.record.destroy()
      }
      this.refreshToolbarButtons()
    })

    this.buildToolbar()

    this.colModel = new Ext.grid.ColumnModel([
      {
        id: 'priority',
        header: t('Priority'),
        width: 160,
        sortable: false,
        resizable: false,
        dataIndex: 'priority'
      },
      {
        dataIndex: 'operand',
        editor: {
          allowBlank: false,
          validator: this.validate.createDelegate(this),
          xtype: 'textfield'
        },
        id: 'operand',
        header: t('Policy Detail'),
        width: 200,
        renderer: escape,
        resizable: false,
        sortable: false
      }
    ])

    this.selModel = new RowSelectionModel({
      listeners: {
        scope: this,
        selectionchange: this.onSelectionChange
      }
    })

    this.on('beforeedit', () => PolicyManagementResource.canSave())
    this.store.on('load', () => this.refreshToolbarButtons())
    this.store.on('remove', () => this.refreshToolbarButtons())

    this.callParent()
  },

  buildToolbar() {
    this.whitelistButton = new Ext.Button({
      text: t('Whitelist'),
      tooltip: t('Add a whitelist policy to the currently selected policy set'),
      menu: {
        items: this.getTypeList('whitelist'),
        defaults: {
          handler: this.add,
          scope: this
        }
      }
    })

    this.blacklistButton = new Ext.Button({
      text: t('Blacklist'),
      tooltip: t('Add a blacklist policy to the currently selected policy set'),
      menu: {
        items: this.getTypeList('blacklist'),
        defaults: {
          handler: this.add,
          scope: this
        }
      }
    })

    this.editButton = new Ext.Button({
      text: t('Edit'),
      tooltip: t('Edit the currently selected policy'),
      handler: this.editPolicy,
      scope: this
    })

    this.deleteButton = new Ext.Button({
      text: t('Delete'),
      tooltip: t('Delete the currently selected policies'),
      handler: this.deletePolicies,
      scope: this
    })

    this.selectAllButton = new Ext.Button({
      text: t('Select All'),
      tooltip: t('Select all policies'),
      handler: this.selectAll,
      scope: this
    })

    return (this.tbar = new Ext.Toolbar({
      disabled: true,
      onEnable: () => this.refreshToolbarButtons(),
      buttons: [
        this.whitelistButton,
        this.blacklistButton,
        this.editButton,
        this.deleteButton,
        this.selectAllButton
      ]
    }))
  },

  setRemovable(removable) {
    this.removable = removable
    this.setDisabled(!removable)
  },

  refreshToolbarButtons() {
    if (!this.store) {
      return
    } // since SCL-3127

    const count = this.store.getCount()

    if (count <= 0 || this.isEditing()) {
      this.editButton.disable()
      this.selectAllButton.disable()
      return this.deleteButton.disable()
    }
    if (count === 1 && this.isAdding()) {
      this.selectAllButton.disable()
    } else {
      this.selectAllButton.enable()
    }

    if (this.hasSelection() && !this.isAdding()) {
      if (this.countSelected() === 1) {
        this.editButton.enable()
      } else {
        this.editButton.disable()
      }

      return this.deleteButton.enable()
    }
    this.editButton.disable()
    this.deleteButton.disable()
  },

  setPolicySet(record) {
    this.refreshToolbarButtons()

    if (!record || record.get('id') === -1) {
      // see SCL-3247 Avoid interacting UI elements when already destroyed
      if (!this.isDestroyed) {
        this.whitelistButton.disable()
        this.blacklistButton.disable()
      }

      if (this.store) {
        this.store.removeAll()
      } // see SCL-3258
      this.policySet = null
      return
    }
    if (!this.isDestroyed) {
      this.whitelistButton.enable()
      this.blacklistButton.enable()
    }

    if (record === this.policySet) {
      return
    }

    this.policySet = record
    this.store.url = record.getUrl() + '/policies'
    // Reset groupview toggle state.
    this.getView().state = {}
    this.store.load()
  },

  // validation logic see:
  // http://confluence.smxemail.com/display/dev/Policies+Checklists
  validate(value) {
    const selected = this.getSelectionModel().getSelected()
    const matcherName = selected != null ? selected.get('matcherName') : undefined
    switch (matcherName) {
      case 'from user':
      case 'to user':
        if (value.length === 0) {
          return t('Please enter a value')
        } else if (value.length < 250 && validators.wildcard(value)) {
          return true
        }
        return t('Email address is invalid')

      case 'from domain':
      case 'to domain':
        if (value.length === 0) {
          return t('Please enter a value')
        } else if (validators.domainWithWildcard(value)) {
          return true
        }
        return t('Domain is invalid')

      case 'ip address':
        return validateIpAddress(value)
      case 'subject':
        if (value.length < 250 && value.length >= 1) {
          return true
        }
        return t(
          'Subject must be at least 1 characters long and less than 250 characters'
        )

      default:
        return false
    }
  },

  async add(item) {
    await this.editor.stopEditing(false)

    const priority =
      PolicyManagementResource.priorities[
        item.action.toUpperCase() + ':' + item.matcher.toUpperCase()
      ]

    const Policy = this.store.record

    const newPolicy = new Policy({
      id: -1,
      action: item.action,
      matcherName: item.matcher,
      operand: '',
      type: item.text,
      priority,
      fromDate: new Date(),
      thruDate: null
    })

    await this.store.addSorted(newPolicy)

    const index = this.store.indexOf(newPolicy)
    this.getSelectionModel().selectRow(index)
    this.view.toggleGroup(this.view.getGroupId(priority), true)
    await this.editor.startEditing(index, 1)
  },

  selectAll() {
    this.getSelectionModel().selectAll()
  },

  editPolicy() {
    if (!this.hasSelection()) return

    this.editor.stopEditing(false)
    this.editor.startEditing(this.getStore().indexOf(this.getFirstSelected()), 1)
    this.refreshToolbarButtons()
  },

  isAdding() {
    const selected = this.getFirstSelected()
    return selected?.id === -1
  },

  isEditing() {
    return this.editor.editing
  },

  hasSelection() {
    return this.getSelectionModel().hasSelection()
  },

  getFirstSelected() {
    return this.getSelectionModel().getSelected()
  },

  getAllSelected() {
    return this.getSelectionModel().getSelections()
  },

  countSelected() {
    return this.getSelectionModel().getCount()
  },

  onSelectionChange() {
    this.refreshToolbarButtons()
  },

  deletePolicies() {
    let bodyText, title
    if (!this.hasSelection()) return

    this.refreshToolbarButtons()
    this.editor.stopEditing()

    const count = this.countSelected()

    if (count === 1) {
      const record = this.getFirstSelected()
      title = t('Delete Policy')
      bodyText = t('Are you sure you want to delete {type} {operand}?', {
        type: record.getType(),
        operand: escape(record.get('operand'))
      })
      return Ext.MessageBox.confirm(
        title,
        bodyText,
        (result) => {
          if (result === 'yes') {
            Ext.MessageBox.wait(t('Saving...'))
            return record.destroy({
              complete() {
                return Ext.MessageBox.hide()
              }
            })
          }
          return this.refreshToolbarButtons()
        },
        this
      )
    }
    title = t('Delete Policies')
    bodyText = t('Are you sure you want to delete {count} selected policies?', { count })
    return Ext.MessageBox.confirm(
      title,
      bodyText,
      function (result) {
        if (result === 'yes') {
          const store = this.getStore()
          store.batch = this.getAllSelected()
          return store.commitBatch()
        }
        this.refreshToolbarButtons()
      },
      this
    )
  },

  getTypeList(type) {
    if (
      (PolicyManagementResource.policyTypes &&
        PolicyManagementResource.policyTypes[type]) != null
    ) {
      return PolicyManagementResource.policyTypes[type].map((pType) => ({
        text: pType.text,
        action: pType.action,
        matcher: pType.matcher,
        iconCls: type
      }))
    }
  }
})

module.exports = PoliciesGridPanel
