const Ext = require('ext')
const t = require('translate')
const ClauseTree = require('smartrules/designer/ClauseTree')
const Icon = require('smartrules/designer/Icon')
const ConditionGroup = require('smartrules/designer/ConditionGroup')
const sprites = require('sprites')
const ListManagementListResource = require('smartrules/ListManagementListResource')
const TemplateManagementResource = require('smartrules/TemplateManagementResource')
const User = require('admin/core/User')

// Concrete implementation of a clause tree with tools for SmartRules conditions.
const ConditionClauseTree = Ext.define(null, {
  extend: ClauseTree,

  constructor(cfg = {}) {
    this.party = cfg.party

    cfg.cls = 'smartrules-conditions'
    cfg.clauseCssCls = 'smartrules-condition'

    cfg.root = new ConditionGroup({
      items: cfg.members,
      root: true
    })

    cfg.toolbarItems = [
      {
        border: false,
        height: 40,
        cls: 'smartrules-icon-bar',
        items: [
          // todo: migrate all the icons below using background images to SVG later on, see
          // http://youtrack.smxemail.com/issue/SCL-1270
          new Icon({
            label: t('Sender'),
            type: 'smartrules-addressed-from'
          }),
          new Icon({
            label: t('Recipient'),
            type: 'smartrules-addressed-to'
          }),
          new Icon({
            label: t('Message size'),
            type: 'smartrules-size-of'
          }),
          new Icon({
            label: t('Suspected Whaling'),
            type: 'smartrules-suspected-whaling'
          }),
          User.hasRole('VENDOR_USER') &&
            new Icon({
              label: t('Headers'),
              type: 'smartrules-headers'
            }),
          new Icon({
            label: t('Content'),
            type: 'smartrules-message'
          }),
          new Icon({
            label: t('Attachments'),
            type: 'smartrules-attachment'
          }),
          new Icon({
            label: t('Group conditions'),
            type: 'smartrules-condition-group'
          }),
          {
            xtype: 'spacer',
            flex: 1
          },
          {
            xtype: 'button',
            scope: this,
            cls: 'x-btn-icon',
            text: sprites.toHtml('list'),
            tooltip: t('Click here to show all existing lists in a new window.'),
            iconCls: 'smartrules-open-lists',
            handler() {
              return this.openListManagement()
            }
          },
          {
            xtype: 'spacer',
            width: 4
          },
          this.party.isCustomer()
            ? {
                xtype: 'button',
                scope: this,
                cls: 'x-btn-icon',
                text: sprites.toHtml('template'),
                tooltip: t('Click here to show all existing templates in a new window.'),
                iconCls: 'smartrules-open-templates',
                handler() {
                  return this.openTemplateManagement()
                }
              }
            : undefined,
          {
            xtype: 'spacer',
            width: 6
          },
          {
            xtype: 'button',
            scope: this,
            text: t('Clear'),
            ref: '../clearButton',
            iconCls: 'icon-reset',
            handler() {
              return this.root.clear()
            }
          }
        ],
        padding: '0 4',
        region: 'north',
        layout: 'hbox'
      }
    ]
    this.callParent([cfg])
  },

  openListManagement() {
    return ListManagementListResource.create(this.party, {
      // aka record
      product: this.party.findProduct('SMART_RULES'),
      store: this.party.getListManagementStore()
    })
  },

  openTemplateManagement() {
    return TemplateManagementResource.create(
      this.party, // aka record
      { customer: this.party, store: this.party.getTemplateStore() }
    )
  },

  onDrop() {
    this.root.fireEvent('smartrules-drop')
    this.clearButton?.setDisabled(this.root.isEmpty())
  }
})

module.exports = ConditionClauseTree
