import * as token from 'api/token'

import { expire, logoutQuietly } from 'system/logout'
import { goOffline, isOffline } from 'api/offline'

import _s from 'underscore.string'
import backendUnavailable from 'api/backendUnavailable'
import { isKeycloakAuthenticated } from 'system/keycloak'

export default function (response, request) {
  if (backendUnavailable(response.status)) {
    if (isOffline()) return // already in offline mode waiting and reconnecting
    goOffline(request)
  } else if (response.status === 401) {
    // grab URL from many sources as possible
    const url = request.url || request.responseURL || response.config.url

    if (_s.include(url, 'api/login')) {
      // do nothing, e.g. invalid password from login page or
      // confirming password before deleting a shared service provider etc.
      return false
    } else if (token.get() || isKeycloakAuthenticated()) {
      // this is the first API request we always make
      // if it fails, just show login.
      //
      // TODO have an API for authentication alone
      if (_s.include(url, 'api/userprofile')) {
        // do nothing, App.js getUserProfile failure
        // function will do the rest
        return false
      }

      if (isKeycloakAuthenticated()) {
        return logoutQuietly()
      }

      // any other request mean expiration
      expire()
    } else {
      // otherwise always logout
      logoutQuietly()
    }
  }
}
