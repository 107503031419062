import { CONTINUITY_UNLOAD } from './actions'
import { createNextState } from '@reduxjs/toolkit'
import initialState from './db'
import messagesReducers from './messages/reducers'

const reducers = {
  [CONTINUITY_UNLOAD]: (continuityDb, { companyKey }) => {
    delete continuityDb.companies[companyKey]
    return continuityDb
  }
}

export default function (moduleDb = initialState, action = {}) {
  const reducer = reducers[action.type]

  if (reducer) {
    return createNextState(moduleDb, (draftState) => reducer(draftState, action))
  }

  // else delegate action further up
  return [messagesReducers].reduce((db, reducer) => {
    return reducer(db, action)
  }, moduleDb)
}
