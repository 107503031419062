import backSvg from 'back.svg'
import broomSvg from 'broom.svg'
import censoredSvg from 'censored.svg'
import checkSvg from 'check.svg'
import confirmationSvg from 'confirmation.svg'
import continuitySvg from 'continuity.svg'
import envelopeSvg from 'envelope.svg'
import exclamationSvg from 'exclamation.svg'
import infoSvg from 'info.svg'
import listSvg from 'list.svg'
import quarantineSvg from 'quarantine.svg'
import restoreSvg from 'restore.svg'
import appendFooterSvg from 'smartrules-append-footer.svg'
import prependPreambleSvg from 'smartrules-prepend-preamble.svg'
import smartrulesSuspectedWhalingSvg from 'smartrules-suspected-whaling.svg'
import logger from 'system/logger'
import templateSvg from 'template.svg'
import textSvg from 'text.svg'
import unlockSvg from 'unlock.svg'
import unlockAllSvg from 'unlock_all.svg'
import viewSvg from 'view.svg'

const SPRITES = {
  'smartrules-headers': envelopeSvg,
  'smartrules-prepend-preamble': prependPreambleSvg,
  'smartrules-append-footer': appendFooterSvg,
  'smartrules-quarantine': quarantineSvg,
  'smartrules-suspected-whaling': smartrulesSuspectedWhalingSvg,
  quarantine: quarantineSvg,
  continuity: continuitySvg,
  list: listSvg,
  template: templateSvg,
  restore: restoreSvg,
  broom: broomSvg,
  view: viewSvg,
  censored: censoredSvg,
  back: backSvg,
  unlock: unlockSvg,
  unlockAll: unlockAllSvg,
  text: textSvg,
  check: checkSvg,
  info: infoSvg,
  exclamation: exclamationSvg,
  confirmation: confirmationSvg
}

const getContent = (type) => {
  if (SPRITES[type] && SPRITES[type].node) {
    // cant have new lines in them otherwise css is invalid
    // when using it inline for css background images
    const sprite = SPRITES[type]
    let svgContent

    // special treatment for older browsers that do not have innerHTML
    if (sprite.node?.innerHTML) {
      svgContent = sprite.node.innerHTML
    } else {
      svgContent = sprite.content
    }

    return svgContent.replace(/\n/gi, '')
  }

  logger.warn(`Unable to get content of non-existing sprite type ${type}`)
  return false
}

export const hasSvg = (type) => !!SPRITES[type]

export const getId = (type) => {
  if (SPRITES[type]) {
    return SPRITES[type].id
  }

  logger.warn(`Unable to get ID of non-existing sprite type ${type}`)
  return false
}

export const getViewBox = (type) => {
  if (SPRITES[type]) {
    return SPRITES[type].viewBox
  }

  logger.warn(`Unable to get view box of non-existing sprite type ${type}`)
  return false
}

export const toElement = (type) =>
  `<svg viewBox="${getViewBox(type)}" xmlns="http://www.w3.org/2000/svg">${getContent(
    type
  )}</svg>`

// see https://github.com/kisenka/svg-sprite-loader#runtime-configuration
export const toHtml = (type) =>
  `<svg viewBox="${getViewBox(type)}">
    <use xlink:href="#${getId(type)}"/>
  </svg>`
