const Ext = require('ext')
const formatMoney = require('money/format')

/*
Read-only clone of a CurrencyAndPriceField, which you supply in cfg.clone.
*/
const ReadOnlyCurrencyAndPriceField = Ext.define(null, {
  extend: Ext.form.CompositeField,

  readOnly: true,

  constructor(cfg = {}) {
    const { clone } = cfg
    const { fieldLabel } = clone.currencyCombo
    const currencyName = clone.currencyCombo.name
    const priceName = clone.priceAmountField.name

    const displayField = new Ext.form.DisplayField({
      cls: 'locked-textfield',
      setPrice(price) {
        this.price = price
        this.updateValue()
      },
      setCurrency(currency) {
        this.currency = currency
        this.updateValue()
      },
      updateValue() {
        if (this.currency && (this.price || this.price === 0)) {
          this.setValue(formatMoney(this.currency, this.price))
        }
      }
    })
    const config = {
      fieldLabel,
      items: [
        displayField,
        {
          name: currencyName,
          setValue(v) {
            displayField.setCurrency(v)
          },
          xtype: 'hidden'
        },
        {
          name: priceName,
          setValue(v) {
            displayField.setPrice(v)
          },
          xtype: 'hidden'
        }
      ],
      layout: 'hbox'
    }
    this.callParent([config])
  },

  highlight() {
    this.highlighted = true
  },

  isHighlighted() {
    return this.highlighted
  },

  unhighlight() {
    this.highlighted = false
  }
})

module.exports = ReadOnlyCurrencyAndPriceField
