const Ext = require('ext')
const t = require('translate')
const validators = require('validators').default

const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const CountryResource = require('admin/business/CountryResource')
const CurrencyResource = require('admin/business/CurrencyResource')
const ComboBox = require('admin/component/form/ComboBox')
const User = require('admin/core/User')
const ResourceUtil = require('admin/util/ResourceUtil')
const config = require('theming/config')
const logger = require('system/logger').default
const store = require('store').default
const { isCapableOfSelectableCustomerTypes } = require('system/capability/subs')

const CompanyNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'company-new-panel',

  constructor(cfg) {
    this.record = cfg.record
    this.callParent([cfg])
  },

  getFormPanel() {
    return new RecordFormPanel({
      module: this.module,
      operation: 'create',
      items: this.getNewItems()
    })
  },

  getNewItems() {
    // Used in required user fields to invalidate if incomplete.
    // Hack but gets the job done.
    let countryCombo

    const _this = this

    const errIfUserIncomplete = function (value) {
      const errors = Ext.form.TextField.prototype.getErrors.apply(this, arguments)

      if (!value && _this.someUserValuesFilled()) {
        errors.push(
          t(
            'First name, last name and email address are required if creating an admin user.'
          )
        )

        this.preventMark = false
      }
      return errors
    }

    if (CountryResource.countryChoiceExists()) {
      countryCombo = CountryResource.getComboBox({
        fieldLabel: t('Country'),
        hiddenName: 'primaryPostalAddress.countryGeo',
        value: User.getDefaultCountry()
      })
    }

    return [
      {
        xtype: 'textfield',
        fieldLabel: (() => {
          switch (this.module.code) {
            case 'CUSTOMER':
              return t('Customer Name')
            case 'RESELLER':
              return t('Reseller Name')
            case 'DISTRIBUTOR':
              return t('Distributor Name')
          }
        })(),
        name: 'displayName',
        maxLength: 100,
        allowBlank: false
      },

      (() => {
        if (this.module.code === 'CUSTOMER') {
          return {
            xtype: 'hidden',
            name: 'billing.defaultCurrency',
            // todo clean this up in future. it is asking vendor
            // for default currency but there is no such field for vendor.
            // when creating new customers, there is the fallback to grab
            // the default currency from user profile, see getCurrency() in customer record.
            value: this.record && this.record.get('partyBillings[0].defaultCurrency')
          }
        } else if (CurrencyResource.currencyChoiceExists()) {
          return CurrencyResource.getComboBox({
            fieldLabel: t('Default Currency'),
            hiddenName: 'partyBillings[0].defaultCurrency',
            value: User.getDefaultCurrency()
          })
        }
      })(),

      this.module.code === 'CUSTOMER' && {
        xtype: 'textfield',
        name: 'externalId',
        fieldLabel: t('External Id'),
        allowBlank: true, // todo: have the backend dictate this with a capability resource
        maxLength: 100
      },

      this.module.code === 'CUSTOMER' &&
        isCapableOfSelectableCustomerTypes(store.getState()) &&
        new ComboBox({
          fieldLabel: t('Customer Type'),
          hiddenName: 'customerType',
          triggerAction: 'all',
          editable: false,
          allowBlank: false,
          store: [
            ['CORPORATION', t('Corporation')],
            ['GOVT_EDU', t('Government Organization')],
            ['EDUCATION', t('Educational Institution')],
            ['CHARITY', t('Registered Charity')]
          ]
        }),

      {
        xtype: 'fieldset',
        title: t('Primary Address'),
        width: 'auto',
        height: 'auto',
        defaults: {
          width: config.panel.fieldWidth
        },
        items: [
          {
            xtype: 'textfield',
            fieldLabel: t('Attention Name'),
            name: 'primaryPostalAddress.attnName',
            maxLength: 100
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Address Line 1'),
            name: 'primaryPostalAddress.address1',
            maxLength: 255,
            allowBlank: false
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Address Line 2'),
            name: 'primaryPostalAddress.address2',
            maxLength: 255
          },
          {
            xtype: 'textfield',
            fieldLabel: t('City / Town'),
            name: 'primaryPostalAddress.city',
            maxLength: 100,
            allowBlank: false
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Postal Code'),
            name: 'primaryPostalAddress.postalCode',
            maxLength: 60
          },
          countryCombo
        ]
      },

      User.hasRole('MANAGE_USERS') && {
        xtype: 'fieldset',
        title: t('Admin User'),
        width: 'auto',
        height: 'auto',
        defaults: {
          width: config.panel.fieldWidth
        },
        items: [
          {
            xtype: 'textfield',
            fieldLabel: t('First Name'),
            name: 'adminUser.firstName',
            getErrors: errIfUserIncomplete
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Last Name'),
            name: 'adminUser.lastName',
            getErrors: errIfUserIncomplete
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Email Address'),
            name: 'adminUser.userName',
            helpText: t('Email address is also the user login name'),
            validator: validators.email.withMsg(
              t('This field should be an e-mail address in the format "user@example.com"')
            ),
            getErrors: errIfUserIncomplete
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Phone Number'),
            name: 'adminUser.contactNumber',
            width: 100
          },
          {
            xtype: 'textfield',
            fieldLabel: t('Phone Extension'),
            name: 'adminUser.extension',
            width: 100,
            validator: validators.positiveInteger.withMsg(t('Only numbers are allowed.')),
            maskRe: /\d/
          }
        ]
      }
    ]
  },

  // since https://youtrack.smxemail.com/issue/SCL-2093
  // ensures that correct data will be sent to server,
  // see i.E. BillableRecord.coffee
  getSaveOptions() {
    return {
      details: true
    }
  },

  handleSave() {
    if (this.saving) return
    this.saving = true

    const values = this.getFormValues()
    const userValues = this.getUserValues()

    Ext.MessageBox.wait(t('Saving...'))

    const RecordClass = this.store.record

    const record = new RecordClass(
      ResourceUtil.reconvertValues(this.store.fields.map, values)
    )

    return record
      .save(
        null,
        Object.assign(
          {
            url: this.store.url
          },
          this.getSaveOptions(this.record)
        )
      )
      .then(() => {
        this.store.addSorted(record)
        // Form will still appear dirty so skip unsaved changes prompt.
        this.formPanel.allowClose = true
        this.close()
        this.module.open(record)

        // Create user if needed.
        if (this.someUserValuesFilled()) {
          const UserRecord = require('admin/data/UserRecord')
          const userRecord = new UserRecord(userValues)
          return userRecord.save(null, { url: record.getUserStore().url }).then(
            () => record.getUserStore().add(userRecord),
            (err) => logger.error(err)
          )
        }
      })
      .catch((err) => logger.error(err))
      .finally(() => {
        Ext.MessageBox.hide()
        this.saving = false
      })
      .done()
  },

  getUserValues() {
    const values = this.getFormValues()

    return {
      firstName: values['adminUser.firstName'],
      lastName: values['adminUser.lastName'],
      userName: values['adminUser.userName'],
      contactNumber: values['adminUser.contactNumber'],
      extension: values['adminUser.extension']
    }
  },

  someUserValuesFilled() {
    return Object.values(this.getUserValues()).some((str) => str !== '')
  }
})

module.exports = CompanyNewPanel
