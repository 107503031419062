const COMPANY_TYPES = {
  CUSTOMER: 'CUSTOMER',
  VENDOR: 'VENDOR',
  RESELLER: 'RESELLER',
  DISTRIBUTOR: 'DISTRIBUTOR'
}

// keep them private
let profile = null
let rootRecord = null
let userRecord = null

const fetchRootRecord = function (companyType, companyUri) {
  // Root record is handled by different modules depending on companyType
  const RecordType = (() => {
    switch (companyType) {
      case COMPANY_TYPES.CUSTOMER:
        return require('admin/data/company/CustomerRecord')
      case COMPANY_TYPES.VENDOR:
        return require('admin/data/company/VendorRecord')
      case COMPANY_TYPES.RESELLER:
        return require('admin/data/company/ResellerRecord')
      case COMPANY_TYPES.DISTRIBUTOR:
        return require('admin/data/company/DistributorRecord')
    }
  })()

  rootRecord = new RecordType({
    type: companyType,
    url: '/api' + companyUri
  })

  return rootRecord.fetch()
}

// Manages user profile and permissions
class User {
  // Process user login XHR response
  processUserProfile(newProfile) {
    profile = newProfile

    fetchRootRecord(this.getCompanyType(), this.getCompanyUri())

    const UserRecord = require('admin/data/UserRecord')
    userRecord = new UserRecord(this.getUserProfile(), {
      parse: true,
      url: '/api' + this.getUserDefaultUri()
    })

    return rootRecord.getUserStore().add(userRecord)
  }

  hasPermission(moduleCode, action) {
    // Use module permissions or fall back to ALL permissions
    const permissions = this.getPermissions()
    const modulePermissions = permissions[moduleCode] || permissions.ALL
    if (
      modulePermissions &&
      (modulePermissions.indexOf(action) > -1 || modulePermissions.indexOf('ALL') > -1)
    ) {
      return true
    }
    return false
  }

  // Permission checking written by an insane person
  // Grants if the user has an equal or higher permission (defined as a lower index in levels)
  // TODO: Check if this behaviour is required. Much easier to rely on 1-1 permissions.
  can(type, level) {
    // Ordered permissions, lower index overrides higher
    const levels = ['ALL', 'DELETE', 'POST', 'PUT', 'GET']

    // Get the index of the level we are comparing
    const index = levels.indexOf(level)

    // Return false if the level doesn't exist
    if (index < 0) {
      return false
    }

    // We have a very high number...
    let value = Number.POSITIVE_INFINITY

    // Loop over ALL permissions
    for (const key in this.getPermissions()) {
      // But only run when at permissions[type] or permissions.ALL
      if (key === type || key === 'ALL') {
        // Gets the permission with the lowest index in levels
        value = this.getPermissions()[key].reduce(function (memo, val) {
          const i = levels.indexOf(val)
          if (i < memo) {
            return i
          }
          return memo
        }, Number.POSITIVE_INFINITY)
        // Break means it will only check the first of permissions[type] or permissions.ALL
        break
      }
    }

    // Returns TRUE if the permission found has an index less than or equal to the desired permission
    return value <= index
  }

  highestUserRole() {
    return ['VENDOR_USER', 'DISTRIBUTOR_USER', 'RESELLER_USER', 'CUSTOMER_USER'].find(
      function (role) {
        return this.hasRole(role)
      },
      this
    )
  }

  // Having ADMINISTRATOR role will return true for any input, is this desired?
  // Is role being confused with permission?
  // Should it be explicit instead?: hasRole('ROLE') or hasRole('ADMINISTRATOR')
  // TODO: Investigate use of role in access control
  hasRole(role) {
    if (this.hasUserProfile()) {
      return this.getAssignedRoles().some(
        (r) => r.code === role || r.code === 'ADMINISTRATOR'
      )
    } // it is possible to ask for role while not having loaded the user yet, i.E. during a password reset
    return false
  }

  isTechnical() {
    return this.hasRole('TECHNICAL')
  }

  isTechnicalAdmin() {
    return this.hasRole('TECHNICAL_ADMIN')
  }

  needExplorer() {
    return (
      [COMPANY_TYPES.DISTRIBUTOR, COMPANY_TYPES.RESELLER, COMPANY_TYPES.VENDOR].indexOf(
        this.getCompanyType()
      ) > -1
    )
  }

  showSearchPanel() {
    return (
      this.isVendor() || this.hasRole('RESELLER_USER') || this.hasRole('DISTRIBUTOR_USER')
    )
  }

  belongsToACustomerCompany() {
    return this.getCompanyType() === COMPANY_TYPES.CUSTOMER
  }

  // in the backend a company is called a party, hence this function naming
  belongsToPartyWithId(id) {
    return this.getCompanyUri() === `/${this.getCompanyType().toLowerCase()}/${id}`
  }

  isUserOfCustomer(customerId) {
    return this.hasRole('CUSTOMER_USER') && this.belongsToPartyWithId(customerId)
  }

  isVendor() {
    return this.hasRole('VENDOR_USER')
  }

  isDistributor() {
    return this.hasRole('DISTRIBUTOR_USER')
  }

  getVendorUrl() {
    if (this.getCompanyType() === COMPANY_TYPES.VENDOR) {
      return rootRecord.url
    }
  }

  hasUserProfile() {
    return profile?.user != null
  }

  getUserProfile() {
    return profile.user
  }

  getId() {
    return this.getUserProfile().id
  }

  getEmail() {
    return this.getUserProfile().userLogin.userName
  }

  getAssignedRoles() {
    return this.getUserProfile().assignedRoles
  }

  getUserDefaultUri() {
    return this.getUserProfile().defaultURI
  }

  getCompanyType() {
    return profile?.companyType
  }

  getCompanyUri() {
    // unfortunately backend still names it as companyUri, although
    // this is more like a key, a reference to a company which is composed
    // of type and id
    return profile.companyUri
  }

  getPermissions() {
    return profile.permissions
  }

  getDefaultCountry() {
    return profile.defaultCountry
  }

  getDefaultCurrency() {
    return profile.defaultCurrency
  }

  // that's actually the server time zone
  getDefaultTimezoneName() {
    return profile?.defaultTimezone
  }

  getCountries() {
    return profile.countries
  }

  getRecord() {
    return userRecord
  }

  getRecordId() {
    return this.getRecord().get('id')
  }

  getRootRecord() {
    return rootRecord
  }

  getRootId() {
    return this.getRootRecord().get('id')
  }

  getRootName() {
    return this.getRootRecord().getName()
  }

  getRootType() {
    return this.getRootRecord().getType()
  }

  getRootUrl() {
    return this.getRootRecord().getUrl()
  }

  getRootStore() {
    return this.getRootRecord().getUserStore()
  }
}

module.exports = new User()
