const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')

const StripMatchedAttachments = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Strip matched attachments'),
      type: 'smartrules-strip-matched-attachments',
      nestable: true
    })
    this.callParent([cfg])
  },

  toDsl() {
    return 'strip matched attachments'
  }
})

module.exports = StripMatchedAttachments
