import { isKeycloakAuthenticated, keycloakLogout } from 'system/keycloak'

import Ext from 'ext' // todo ditch extjs for cookies
import { clear as clearToken } from 'api/token'

export const logout = function () {
  clearToken()

  if (isKeycloakAuthenticated()) {
    keycloakLogout()
  } else {
    window.location.reload()
  }
}

export const logoutQuietly = function () {
  window.onbeforeunload = null

  try {
    delete window.onbeforeunload
  } catch (exc) {
    // IE can't handle delete on the window object
  }

  logout()
}

export const expire = function () {
  Ext.util.Cookies.set('expired', true, new Date().add(Date.HOUR, 1))

  logoutQuietly()
}
