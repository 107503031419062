const t = require('translate')
const Ext = require('ext')
const EventSummaryPanel = require('admin/view/EventDetailSummary')
const EventRecipientsPanel = require('admin/view/EventDetailRecipients')
const EventQuarantinePanel = require('admin/view/EventDetailQuarantine')
const EventDetailRecipientsToolbar = require('admin/view/EventDetailRecipientsToolbar')

const EventDetailTab = Ext.define(null, {
  extend: Ext.Panel,

  layout: {
    type: 'vbox',
    align: 'stretch'
  },

  border: false,
  title: t('Details'),

  constructor(cfg) {
    this.addClass('event-detail-tab')

    this.items = []

    const recipientsPanel = new EventRecipientsPanel({
      record: cfg.record,
      timeZoneName: cfg.timeZoneName
    })

    if (cfg.isQuarantineVisible) {
      this.items.push({
        xtype: 'panel',
        flex: 0,
        border: false,
        items: [
          new EventQuarantinePanel({
            record: cfg.record,
            customer: cfg.customer,
            timeZoneName: cfg.timeZoneName,
            releaseLink: cfg.releaseLink
          })
        ]
      })
    }

    this.items.push({
      xtype: 'panel',
      flex: 0,
      items: [
        new EventSummaryPanel({
          record: cfg.record,
          customer: cfg.customer,
          timeZoneName: cfg.timeZoneName
        })
      ]
    })

    this.items.push({
      xtype: 'panel',
      flex: 0,
      border: false,
      items: new EventDetailRecipientsToolbar({
        expandAll: recipientsPanel.expandAll.bind(recipientsPanel),
        collapseAll: recipientsPanel.collapseAll.bind(recipientsPanel)
      })
    })

    this.items.push({
      xtype: 'panel',
      flex: 1,
      items: [recipientsPanel]
    })

    this.callParent()
  }
})

module.exports = EventDetailTab
