import getPathSegment from 'url/getPathSegment'

// the type of a record is always in the second-last path segment like i.E.
// /api/vendor/1004/ <-- vendor is the record type
// /api/customer/2000074/inboundscrub/scrubbingdomains/2087241 <-- scrubbingdomains is the record type
export const extractType = (apiPath) => {
  return getPathSegment(apiPath, -2)
}

// the id of a record is always in the last path segment like i.E.
// /api/vendor/1004/ <-- 1004 is the record id
// /api/customer/2000074/inboundscrub/scrubbingdomains/2087241 <-- 2087241 is the record id
export const extractId = (apiPath) => {
  const idAsString = getPathSegment(apiPath, -1)

  if (idAsString) {
    return parseInt(idAsString, 10)
  }

  return null
}

// the root type of a record is always in the first path segment like i.E.
// /api/vendor/1004/ <-- vendor is the root record type
// /api/customer/2000074/inboundscrub/scrubbingdomains/2087241 <-- customer is the root record type
export const extractRootType = (apiPath) => {
  return getPathSegment(apiPath, 2)
}

// the root id of a record is always in the first path segment like i.E.
// /api/vendor/1004/ <-- 1004 is the root record id
// /api/customer/2000074/inboundscrub/scrubbingdomains/2087241 <-- 2000074 is the root record id
export const extractRootId = (apiPath) => {
  const idAsString = getPathSegment(apiPath, 3)

  if (idAsString) {
    return parseInt(idAsString, 10)
  }

  return null
}
