// polyfills everything the robust way,
// see https://github.com/babel/babel/issues/9853#issuecomment-619587386
import 'core-js'

import App from 'admin/core/App'
import isDeveloping from 'system/isDeveloping'
import { init as initSentry } from 'system/sentry'

if (!isDeveloping()) {
  initSentry()
}

// todo initialize app state here (redux)

App.init()
