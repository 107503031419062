const Ext = require('ext')

/*
 Resettable task. Allows for activating and deactivating Ext.util.DelayedTask without having to create/destroy them
 callback: Function
 delay: minutes
 scope: scope of callback
*/
const ResettableTask = Ext.define(null, {
  extend: Ext.util.DelayedTask,
  //
  //  @param cfg:
  //    @option activated whether the task starts immediately
  //    @option callback what's executed on the delay elapsing
  //    @option delayBy number of seconds to delay task
  //    @option scope scope of the callback
  //
  constructor(cfg = {}) {
    Ext.applyIf(cfg, {
      activated: true,
      callback: Ext.emptyFn,
      delayBy: 1,
      scope: this
    })
    ;({ delayBy: this.delayBy, activated: this.activated } = cfg)
    this.callParent([cfg.callback, cfg.scope])
    return this.reset()
  },
  // reset the timer
  reset() {
    if (this.activated) {
      return this.delay(this.delayBy * 1000)
    }
  },
  // stop responding to reset calls
  deactivate() {
    this.activated = false
    return this.cancel()
  },
  // start responding to reset calls, starting immediately
  activate() {
    this.activated = true
    return this.reset()
  }
})

module.exports = ResettableTask
