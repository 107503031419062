// root reducer for all redux-based namespaces in our legacy ui app
// must be grouped in one file here for easier hot reloading

import archivingReducers from 'archiving/reducers'
import continuityReducers from 'continuity/reducers'
import tenancyReducers from 'integration/tenancy/reducers'
import mailboxesReducers from 'mailboxes/reducers'
import mailhostingReducers from 'mailhosting/reducers'
import quarantineReducers from 'quarantine/reducers'
import { reducer as formReducer } from 'redux-form'
import providerReducers from 'sharedservices/provider/reducers'
import capabilityReducers from 'system/capability/reducers'

import { combineReducers } from '@reduxjs/toolkit'

export default combineReducers({
  archiving: archivingReducers,
  continuity: continuityReducers,
  provider: providerReducers,
  quarantine: quarantineReducers,
  capability: capabilityReducers,
  mailboxes: mailboxesReducers,
  mailhosting: mailhostingReducers,
  tenancy: tenancyReducers,
  form: formReducer
})
