const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const SupportStore = require('admin/data/SupportStore')
const User = require('admin/core/User')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validators = require('validators').default
const ComboBox = require('admin/component/form/ComboBox')

const SupportNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'support-new-panel',

  module() {
    return require('admin/business/SupportResource')
  },

  saveText: t('Send'),

  constructor() {
    // Need store so save handler has something to post to.
    this.store = new SupportStore()
    this.callParent(arguments)
  },

  getFormPanel(cfg) {
    const record = cfg.record || User.getRecord()
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: [
        new ComboBox({
          fieldLabel: t('Type Of Query'),
          name: 'typeOfQuery',
          allowBlank: false,
          value: t('General Query'),
          editable: true,
          triggerAction: 'all',
          store: [t('General Query'), t('Support Query'), t('Contact Me Urgently')]
        }),
        {
          fieldLabel: t('First Name'),
          name: 'firstName',
          allowBlank: false,
          xtype: 'textfield',
          value: record.get('firstName')
        },
        {
          fieldLabel: t('Last Name'),
          name: 'lastName',
          allowBlank: false,
          xtype: 'textfield',
          value: record.get('lastName')
        },
        {
          fieldLabel: t('Email Address'),
          name: 'emailAddress',
          allowBlank: false,
          validator: validators.email.withMsg(
            t('This field should be an e-mail address in the format "user@example.com"')
          ),
          xtype: 'textfield',
          value: User.getEmail()
        },
        {
          fieldLabel: t('Query'),
          name: 'body',
          allowBlank: false,
          xtype: 'textarea',
          height: 120,
          maxLength: 4000
        }
      ]
    })
  }
})

module.exports = SupportNewPanel
