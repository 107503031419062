const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const SharedServiceRecord = require('admin/data/SharedServiceRecord')

const SharedServiceStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'sharedservices',
  record: SharedServiceRecord,
  autoSave: false,

  sortInfo: {
    field: 'name',
    direction: 'ASC'
  },

  module() {
    return require('admin/business/SharedServiceResource')
  }
})

module.exports = SharedServiceStore
