const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const Time = require('admin/time/Time')
const UserResource = require('admin/business/UserResource')
const Report = require('reporting/Report')

const SubscriptionListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  title: t('Report Subscriptions'),
  autoExpandColumn: 'description',
  cls: 'subscription-list-panel',
  tabCls: 'subscription-list-tab',

  module() {
    return require('reporting/Subscription')
  },

  getCreateButton() {
    return null
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel([
      {
        id: 'description',
        header: t('Description'),
        width: 100,
        sortable: true,
        dataIndex: 'description'
      },
      {
        id: 'lastSentDate',
        header: t('Last Sent Date'),
        width: 100,
        sortable: true,
        dataIndex: 'lastSentDate',
        renderer: Time.toString
      },
      {
        id: 'nextSendDate',
        header: t('Next Send Date'),
        width: 100,
        sortable: true,
        dataIndex: 'nextSendDate',
        renderer: Time.toString
      }
    ])
  },

  record: require('reporting/SubscriptionRecord'),

  getParentResources() {
    return [UserResource, Report]
  }
})

module.exports = SubscriptionListPanel
