const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validators = require('validators').default

const MailRelayHostNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'mailrelayhost-new-panel',

  module() {
    return require('admin/business/MailRelayHostResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
  },

  getNewItems() {
    return [
      new Ext.form.TextField({
        fieldLabel: t('Mail Relay Host'),
        name: 'name',
        allowBlank: false,
        validator: validators.domainOrIp.withMsg(
          t('You must enter a valid DNS Name or IP Address')
        )
      }),
      new Ext.form.DisplayField({
        border: false,
        html: t(
          'Mail relay host will only be used when associated with a scrubbing domain.'
        )
      })
    ]
  }
})

module.exports = MailRelayHostNewPanel
