const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ListTypes = require('smartrules/ListTypes')
const ListManagementDetailPanel = require('smartrules/ListManagementDetailPanel')
const ListManagementNewPanel = require('smartrules/ListManagementNewPanel')
const ListManagementListPanel = require('smartrules/ListManagementListPanel')
const User = require('admin/core/User')

const ListManagement = Ext.define(null, {
  extend: Resource,
  singleton: true,
  code: 'SMART_RULE',
  id: 'SMX.SmartRules.ListManagement',

  translations: {
    title: t('List Management'),
    new: t('New List'),
    open: t('Open List'),
    list: t('List Management'),
    remove: t('Delete List')
  },

  layout: {
    newResource: {
      height: 430,
      minHeight: 250,
      width: 510,
      resizable: true
    },
    openResource: {
      height: 430,
      minHeight: 250,
      width: 510,
      resizable: true
    }
  },

  name: t('List Management'),
  resourceId: 'DATA_SETS',
  resourceName: 'datasets',
  submitOnEnter: false,

  detailPanel: ListManagementDetailPanel,
  newPanel: ListManagementNewPanel,
  listPanel: ListManagementListPanel,

  getDataTypeStore() {
    return new Ext.data.ArrayStore({
      fields: ['value', 'description', 'example'],
      data: [
        [
          ListTypes.RULE_ADDRESS,
          t('Address'),
          ListTypes.getHelpText(ListTypes.RULE_ADDRESS)
        ],
        [
          ListTypes.RULE_CONTENT,
          t('Content'),
          ListTypes.getHelpText(ListTypes.RULE_CONTENT)
        ],
        [
          ListTypes.RULE_HEADER,
          t('Header'),
          ListTypes.getHelpText(ListTypes.RULE_HEADER)
        ],
        [
          ListTypes.RULE_ATTACHMENT_TYPE,
          t('Attachment type'),
          ListTypes.getHelpText(ListTypes.RULE_ATTACHMENT_TYPE)
        ],
        [
          ListTypes.RULE_ATTACHMENT_NAME,
          t('Attachment name'),
          ListTypes.getHelpText(ListTypes.RULE_ATTACHMENT_NAME)
        ]
      ]
    })
  },

  updateFieldSize(container) {
    let h = 125
    const fields = container.find('field')
    return fields.forEach(function (field) {
      field.setWidth(container.getWidth() - container.labelWidth - 60)
      if (field.name === 'items') {
        return field.setHeight(container.findParentWindow().getHeight() - h)
      }
      return (h += field.getHeight())
    })
  },

  canCreate() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canSave() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canRemove() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  }
})

module.exports = ListManagement
