export default function (field, urlStr = window.location.href) {
  const url = new URL(urlStr, window.location.href)
  const param = url.searchParams.get(field)

  if (param && typeof param.valueOf() === 'string') {
    return decodeURIComponent(param)
  }

  return param
}
