const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const ProxyStore = require('admin/data/ProxyStore')
const ComboBox = require('admin/component/form/ComboBox')
const CountryStore = require('admin/data/CountryStore')
const escape = require('html-escaper').escape

// TODO: Very similar to CurrencyResource. Refactor shared code.
const CountryResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Country',
  translations: {
    title: t('Country'),
    new: t('New Country'),
    open: t('Open Country'),
    list: t('List Countries')
  },
  name: t('Country'),
  resourceName: 'countries',

  countryChoiceExists() {
    return this.getStore().data.length > 1
  },

  getComboBox(cfg = {}) {
    const store = new ProxyStore(this.getStore())
    cfg = Ext.applyIf(cfg, {
      fieldLabel: t('Country'),
      typeAhead: true,
      valueField: 'code',
      displayField: 'geoName',
      store,
      lazyInit: false,
      editable: true,
      triggerAction: 'all',
      forceSelection: true,
      mode: 'local',
      allowBlank: false,
      validator(value) {
        if (!this.findRecord(this.displayField, value)) {
          return escape(t('Invalid input for country'))
        }
        return true
      }
    })
    const combo = new ComboBox(cfg)

    const onLoad = function () {
      return this.setValue(this.getValue())
    }

    store.on('load', onLoad, combo)

    const onBlur = function (field) {
      if (field.getRawValue() === '') {
        return field.setValue('')
      }
    }

    combo.on('blur', onBlur)

    combo.on(
      'beforedestroy',
      function () {
        this.validator = null
        this.un('blur', onBlur, this)
        store.un('load', onLoad, this)
        return store.destroy()
      },
      combo
    )

    return combo
  },

  getStore() {
    if (!this.store) {
      this.store = new CountryStore()
      this.store.load()
    }
    return this.store
  },

  isLoaded() {
    return this.getStore().isLoaded()
  },

  // called during app startup
  loadCountries(cb) {
    if (!this.isLoaded()) {
      return this.getStore().on('load', cb)
    }
    return cb()
  }
})

module.exports = CountryResource
