const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const InvoiceRunNewPanel = require('admin/view/InvoiceRunNewPanel')
const InvoiceRunListPanel = require('admin/view/InvoiceRunListPanel')
const InvoiceListPanel = require('admin/view/InvoiceListPanel')
const InvoiceRunRecord = require('admin/data/InvoiceRunRecord')
const VendorResource = require('admin/business/VendorResource')

const InvoiceRunResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.InvoiceRun',
  translations: {
    title: t('Invoice Run'),
    plural: t('Invoice Runs'),
    new: t('New Invoice Run'),
    open: t('Open Invoice Run'),
    list: t('List Invoice Runs')
  },
  name: t('Invoice Run'),
  code: 'INVOICE_RUN',
  resourceName: 'invoiceruns',
  searchable: true,
  explorable: true,

  layout: {
    newResource: {
      width: 390,
      height: 109
    }
  },

  operations: {
    create: true,
    open: true,
    remove: false,
    list: true,
    save: false
  },

  newPanel: InvoiceRunNewPanel,
  listPanel: InvoiceRunListPanel,
  detailPanel: InvoiceListPanel,

  record: InvoiceRunRecord,

  getParentResources() {
    return [VendorResource]
  }
})

module.exports = InvoiceRunResource
