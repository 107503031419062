import { createNextState } from '@reduxjs/toolkit'

import { QUARANTINE_UNLOAD } from './actions'
import initialState from './db'
import mailTargetsReducers from './mail_targets/reducers'
import notificationReducers from './notification/reducers'
import quarantineSetsReducers from './quarantine_sets/reducers'
import searchReducers from './search/reducers'

const reducers = {
  [QUARANTINE_UNLOAD]: (quarantineDb, { companyKey }) => {
    delete quarantineDb.companies[companyKey]
    return quarantineDb
  }
}

export default function (moduleDb = initialState, action = {}) {
  const reducer = reducers[action.type]

  if (reducer) {
    return createNextState(moduleDb, (draftState) => reducer(draftState, action))
  }

  // else delegate action further up
  return [
    searchReducers,
    mailTargetsReducers,
    quarantineSetsReducers,
    notificationReducers
  ].reduce((db, reducer) => {
    return reducer(db, action)
  }, moduleDb)
}
