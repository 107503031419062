const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validators = require('validators').default

// TODO port to React. Should be easy since we already have
// the reducers, actions and subscribers in the mailhosting namespace
// see SCL-3318

const HostingDomainDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'hostingdomain-detail-panel',

  module() {
    return require('admin/business/HostingDomainResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    return [
      {
        fieldLabel: t('Domain Name'),
        name: 'name',
        allowBlank: false,
        validator: validators.domain.withMsg(t('You must enter a valid DNS Name')),
        xtype: 'textfield'
      }
    ]
  }
})

module.exports = HostingDomainDetailPanel
