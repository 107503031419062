const Record = require('admin/core/Record')

const DomainSummaryResultRecord = Record.define(null, {
  fields: [
    { name: 'id', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'cleanCount', type: 'int' },
    { name: 'spamCount1', type: 'int', mapping: 'spamCount' },
    {
      name: 'spamCount2',
      type: 'int',
      mapping: 'spamCount',
      convert(value, record) {
        const total =
          record.cleanCount +
          record.spamCount +
          record.allowPolicyCount +
          record.denyPolicyCount +
          record.threatCount
        if (total > 0) {
          return Math.round((value / total) * 1000) / 10
        }
        return 0
      }
    }, // For percentage column, to prevent sorting interference between columns
    { name: 'allowPolicyCount', type: 'int' },
    { name: 'denyPolicyCount', type: 'int' },
    { name: 'threatCount', type: 'int' },
    { name: 'cleanSize', type: 'int' },
    { name: 'spamSize', type: 'int' },
    { name: 'allowPolicySize', type: 'int' },
    { name: 'denyPolicySize', type: 'int' },
    { name: 'threatSize', type: 'int' }
  ]
})

module.exports = DomainSummaryResultRecord
