const t = require('translate')
const Ext = require('ext')

const init = function () {
  Ext.QuickTips.init()
  Ext.QuickTips.enable()

  // this disables shadow on reserved dom element for quick tips
  const el = Ext.QuickTips.getQuickTip().getEl()
  return el.disableShadow()
}

const Tooltip = Ext.define(null, {
  singleton: true,
  id: 'SMX.util.Tooltip',

  // used in App.coffee during startup
  init() {
    if (!Ext.isReady) {
      return Ext.onReady(() => init())
    }
    return init()
  },

  fieldNeedsRequiredTooltip(field) {
    const xtype = field.xtype || field.getXType() || ''

    if (
      field instanceof Ext.form.CompositeField &&
      field.items.find(this.fieldNeedsRequiredTooltip, this)
    ) {
      return true
    }

    // when the field is one of those types, we never show the yellow start to indicate
    // a mandatory input. for example it would not make sense to place a yellow star
    // next do a disabled input field etc.
    if (
      !field.isFormField ||
      xtype === 'checkbox' ||
      xtype === 'displayfield' ||
      xtype.match(/^readonly-/) ||
      field.readOnly ||
      field.hidden ||
      field.disabled
    ) {
      return false
    }

    if (field.allowBlank === false) {
      return true
    }
  },

  // by intention, we have this small function so that subclasses
  // can reuse it for any given field
  appendRequiredFieldMarker(field) {
    return this.appendTooltip(field, 'required-field-marker', t('This field is required'))
  },

  removeRequiredFieldMarker(field) {
    let container

    if (field instanceof Ext.form.CompositeField) {
      container = field.el.child('.x-box-inner')
    } else {
      container = field.container
    }

    const marker = container.select('.required-field-marker').first()
    Ext.QuickTips.unregister(marker)

    marker.remove()
  },

  register(target, text, param = { cls: null }) {
    const { cls } = param
    Ext.QuickTips.register({
      width: 'auto',
      target,
      text,
      cls
    })
  },

  appendTooltip(field, cls, text) {
    let container
    if (field.container.child(cls)) return

    if (field instanceof Ext.form.CompositeField) {
      container = field.el.child('.x-box-inner')
    } else {
      container = field.container
    }

    const marker = container.createChild({
      cls,
      tag: 'div'
    })

    this.register(marker, text)
  }
})

module.exports = Tooltip
