const t = require('translate')
const Ext = require('ext')

const confirmDiscardChanges = (onConfirm) =>
  Ext.Msg.show({
    title: t('Unsaved changes'),
    msg: t('You are about to lose changes that have not been saved.'),
    buttons: {
      yes: t('Discard changes'),
      no: t('Cancel')
    },
    fn(btn) {
      if (btn === 'yes') {
        return onConfirm()
      }
    },
    icon: Ext.Msg.WARNING,
    minWidth: Ext.Msg.minWidth
  })

module.exports = confirmDiscardChanges
