// Sanity-check the conversion and remove this comment.
// some constants for UI elements, unfortunately. ExtJS requires these. Ideally these
// should be in CSS but that's a no-go with ExtJS.

module.exports = {
  panel: {
    fieldWidth: 220,
    labelWidth: 118
  }
}
