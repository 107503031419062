const Ext = require('ext')
const store = require('store').default
const ResourceStore = require('admin/data/ResourceStore')
const HostingDomainRecord = require('admin/data/HostingDomainRecord')
const HostingDomainResource = require('admin/business/HostingDomainResource')

const refreshDomains = require('mailhosting/domain/actions').refresh
const refreshMailboxes = require('mailboxes/actions').refresh
const refreshMembership = require('archiving/membership/actions').refresh

const { isArchivingProvisioningCapabilityFlagEnabled } = require('system/capability/subs')

// TODO: replace this with the new /mailboxes/store/hostingDomainStore
// because this code is duplicate. see SCL-3318
const HostingDomainStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'hostingdomains',
  sortInfo: {
    direction: 'ASC',
    field: 'name'
  },

  record: HostingDomainRecord,

  module() {
    return HostingDomainResource
  },

  constructor() {
    this.callParent(arguments)

    // there is a smx3:general/create link for mailboxes which says
    // whether to enable or disable the "create mailbox" button. hence
    // we must reload the list of mailboxes that includes this link every time
    // we add/change/delete a hosting domain.

    this.on('add', function () {
      this.refreshDomains()
      this.reloadMailboxes()
      this.reloadArchivingMembership()
    })

    this.on('remove', function () {
      this.refreshDomains()
      this.reloadMailboxes()
      this.reloadArchivingMembership()
    })

    this.on('change', function () {
      this.refreshDomains()
      this.reloadMailboxes()
      this.reloadArchivingMembership()
    })
  },

  reloadMailboxes() {
    if (!this.parentRecord) {
      return
    } // unit testing

    // TODO have backend return the link, see SSV-3537
    store.dispatch(
      refreshMailboxes(
        this.parentRecord.getCompanyKey(),
        [this.parentRecord.url, 'mailhost', 'mailboxes'].join('/')
      )
    )
  },

  refreshDomains() {
    if (!this.parentRecord) return // unit testing

    // TODO have backend return the link, see SSV-3537
    store.dispatch(
      refreshDomains(
        this.parentRecord.getCompanyKey(),
        [this.parentRecord.url, 'mailhost', 'hostingdomains'].join('/')
      )
    )
  },

  reloadArchivingMembership() {
    if (!isArchivingProvisioningCapabilityFlagEnabled(store.getState())) return
    if (!this.parentRecord) return // unit testing
    if (!this.parentRecord.hasArchiving) return
    if (typeof this.parentRecord.hasArchiving !== 'function') return
    if (!this.parentRecord.hasArchiving()) return

    store.dispatch(
      refreshMembership(
        this.parentRecord.getCompanyKey(),
        [this.parentRecord.url, 'archiving', 'membership'].join('/')
      )
    )
  }
})

module.exports = HostingDomainStore
