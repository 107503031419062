import Alert from 'admin/util/Alert'
import Api from 'api'
import axios from 'axios'
import * as hal from 'hal'
import { MAILBOX_CATALOGUE_REL, MAILBOX_REL } from 'product/resources'
import logger from 'system/logger'
import t from 'translate'
import _s from 'underscore.string'

export const MAILBOXES_START_LOADING = 'MAILBOXES_START_LOADING'
export const MAILBOXES_STOP_LOADING = 'MAILBOXES_STOP_LOADING'
export const MAILBOXES_INIT_DB = 'MAILBOXES_INIT_DB'
export const MAILBOXES_SET_CUSTOMER_URL = 'MAILBOXES_SET_CUSTOMER_URL'
export const MAILBOXES_UNLOAD = 'MAILBOXES_UNLOAD'
export const MAILBOXES_SET_LIST = 'MAILBOXES_SET_LIST'
export const MAILBOXES_SELECT_ROWS = 'MAILBOXES_SELECT_ROWS'
export const MAILBOXES_START_CREATING = 'MAILBOXES_START_CREATING'
export const MAILBOXES_STOP_CREATING = 'MAILBOXES_STOP_CREATING'
export const MAILBOXES_START_EDITING = 'MAILBOXES_START_EDITING'
export const MAILBOXES_STOP_EDITING = 'MAILBOXES_STOP_EDITING'
export const MAILBOXES_START_UPGRADING = 'MAILBOXES_START_UPGRADING'
export const MAILBOXES_STOP_UPGRADING = 'MAILBOXES_STOP_UPGRADING'
export const MAILBOXES_SET_LINKS = 'MAILBOXES_SET_LINKS'
export const MAILBOXES_START_CONFIRMING_DELETION = 'MAILBOXES_START_CONFIRMING_DELETION'
export const MAILBOXES_STOP_CONFIRMING_DELETION = 'MAILBOXES_STOP_CONFIRMING_DELETION'
export const MAILBOXES_START_CONFIRMING_PURGE = 'MAILBOXES_START_CONFIRMING_PURGE'
export const MAILBOXES_STOP_CONFIRMING_PURGE = 'MAILBOXES_STOP_CONFIRMING_PURGE'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

function processError(err) {
  logger.error(err)
}

const startLoading = (companyKey) => ({ type: MAILBOXES_START_LOADING, companyKey })
const stopLoading = (companyKey) => ({ type: MAILBOXES_STOP_LOADING, companyKey })

export const unload = (companyKey) => ({
  type: MAILBOXES_UNLOAD,
  companyKey
})

/// ========= INIT ========= ///

const setCustomerUrl = (companyKey, customerUrl) => ({
  type: MAILBOXES_SET_CUSTOMER_URL,
  companyKey,
  customerUrl
})

const initDb = (companyKey) => ({
  type: MAILBOXES_INIT_DB,
  companyKey
})

export const init = (companyKey, customerUrl) => (dispatch) => {
  dispatch(initDb(companyKey))
  dispatch(setCustomerUrl(companyKey, customerUrl))
}

/// ========= LIST SET/REFRESH ========= ///

export const setList = (companyKey, newList) => ({
  type: MAILBOXES_SET_LIST,
  companyKey,
  newList
})

export const setLinks = (companyKey, links) => ({
  type: MAILBOXES_SET_LINKS,
  companyKey,
  links
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)

    dispatch(setList(companyKey, hal.resource(response.data, MAILBOX_REL)))

    dispatch(setLinks(companyKey, hal.links(response.data)))
  } catch (err) {
    processError(err)
  } finally {
    dispatch(stopLoading(companyKey))
  }
}

/// ========= SELECTIONS ========= ///

export const selectRows = (companyKey, rows, selected, force) => {
  return {
    type: MAILBOXES_SELECT_ROWS,
    companyKey,
    rows,
    selected,
    force
  }
}

/// ========= CREATION ========= ///

export const startCreating = (companyKey) => ({
  type: MAILBOXES_START_CREATING,
  companyKey
})

export const stopCreating = (companyKey) => ({
  type: MAILBOXES_STOP_CREATING,
  companyKey
})

export const create = (companyKey, uri, values) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  let response

  try {
    // TODO use format/parse in redux-form once we have ditched immutable (too painful)
    values.alias = values.alias?.split(/\r\n|\r|\n/)
    values.forward = values.forward?.split(/\r\n|\r|\n/)

    response = await Api.post(uri, values, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    })
  } catch (err) {
    processError(err)
    return dispatch(stopLoading(companyKey))
  }

  try {
    await dispatch(refresh(companyKey, uri))
    dispatch(selectRows(companyKey, [response.headers.location], true, true))
  } finally {
    // we always close regardless whether refresh was successful or not
    dispatch(stopCreating(companyKey))
  }
}

/// ========= CONFIGURATION ========= ///

export const startEditing = (companyKey, mailbox) => ({
  type: MAILBOXES_START_EDITING,
  companyKey,
  mailbox
})

export const stopEditing = (companyKey) => ({
  type: MAILBOXES_STOP_EDITING,
  companyKey
})

export const edit = (companyKey, selfLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(selfLink)

    dispatch(startEditing(companyKey, response.data))
    dispatch(stopLoading(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
    dispatch(stopEditing(companyKey))
  }
}

export const update = (
  companyKey,
  uri,
  values,
  updateDetailsLink,
  updatePasswordLink,
  updateAliasLink,
  updateForwardLink
) => {
  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      const promises = []

      if (updateDetailsLink) {
        promises.push(
          Api.post(
            updateDetailsLink,
            {
              firstName: values.firstName,
              lastName: values.lastName
            },
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
          )
        )
      }

      if (updatePasswordLink && values.password !== undefined) {
        promises.push(
          Api.post(
            updatePasswordLink,
            {
              password: values.password
            },
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
          )
        )
      }

      if (updateAliasLink && values.alias !== undefined) {
        promises.push(
          Api.post(
            updateAliasLink,
            {
              alias: values.alias.split(/\r\n|\r|\n/)
            },
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
          )
        )
      }

      if (updateForwardLink) {
        const data = {
          forwardingStyle: values.forwardingStyle || 'DISABLED' // ensures it's defined when none is set (SCL-3584)
        }

        if (!_s.isBlank(values.forward)) {
          data.forward = values.forward.split(/\r\n|\r|\n/)
        }

        promises.push(
          Api.post(updateForwardLink, data, {
            headers: {
              'content-type': 'application/x-www-form-urlencoded'
            }
          })
        )
      }

      await Promise.all(promises)
    } catch (err) {
      processError(err)
      return dispatch(stopLoading(companyKey))
    }

    try {
      await dispatch(refresh(companyKey, uri))
    } finally {
      dispatch(stopEditing(companyKey))
    }
  }
}

export const doubleClickMailbox = (companyKey, mailbox) => (dispatch) => {
  dispatch(selectRows(companyKey, [mailbox], true, true))
  dispatch(edit(companyKey, hal.self(mailbox)))
}

/// ========= DELETION ========= ///

export const startConfirmingDeletion = (companyKey) => ({
  type: MAILBOXES_START_CONFIRMING_DELETION,
  companyKey
})

export const stopConfirmingDeletion = (companyKey) => ({
  type: MAILBOXES_STOP_CONFIRMING_DELETION,
  companyKey
})

export const deleteMailboxes = (companyKey, uri, deleteLinks) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    await axios.all(deleteLinks.map((deleteLink) => Api.post(deleteLink)))

    await dispatch(refresh(companyKey, uri))
    dispatch(stopConfirmingDeletion(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= PURGING ========= ///

export const startConfirmingPurge = (companyKey) => ({
  type: MAILBOXES_START_CONFIRMING_PURGE,
  companyKey
})

export const stopConfirmingPurge = (companyKey) => ({
  type: MAILBOXES_STOP_CONFIRMING_PURGE,
  companyKey
})

export const purge = (companyKey, uri, purgeLinks) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    await axios.all(purgeLinks.map((purgeLink) => Api.post(purgeLink)))

    await dispatch(refresh(companyKey, uri))
    dispatch(stopConfirmingPurge(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= RESTORING ========= ///

export const restore = (companyKey, uri, restoreLinks) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    await axios.all(restoreLinks.map((restoreLink) => Api.post(restoreLink)))

    await dispatch(refresh(companyKey, uri))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= UN/SUSPENDING ========= ///

export const suspendAll = (companyKey, uri, suspendAllLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    Alert.info(t('Suspending all mailboxes in progress ...'))
    await Api.post(suspendAllLink)
    await sleep(1500) // backend delay
    await dispatch(refresh(companyKey, uri))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
  }
}

export const unsuspendAll = (companyKey, uri, unsuspendAllLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    Alert.info(t('Unsuspending all mailboxes in progress ...'))
    await Api.post(unsuspendAllLink)
    await sleep(1500) // backend delay
    await dispatch(refresh(companyKey, uri))
  } catch (err) {
    processError(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= UPGRADING ========= ///

export const startUpgrading = (companyKey, mailbox, catalogue) => ({
  type: MAILBOXES_START_UPGRADING,
  companyKey,
  mailbox,
  catalogue
})

export const stopUpgrading = (companyKey) => ({
  type: MAILBOXES_STOP_UPGRADING,
  companyKey
})

export const editProduct = (companyKey, selfLink, catalogueLink) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    // TODO do it in parallel for speed
    const mailbox = await Api.get(selfLink)
    const catalogue = await Api.get(catalogueLink)

    dispatch(
      startUpgrading(
        companyKey,
        mailbox.data,
        hal.resource(catalogue.data, MAILBOX_CATALOGUE_REL)
      )
    )
    dispatch(stopLoading(companyKey))
  } catch (err) {
    processError(err)
    dispatch(stopUpgrading(companyKey))
    dispatch(stopEditing(companyKey))
  }
}

export const upgrade = (companyKey, uri, upgradeLink, values) => {
  return async (dispatch) => {
    dispatch(startLoading(companyKey))

    try {
      await Api.post(upgradeLink, values, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (err) {
      processError(err)
      return dispatch(stopLoading(companyKey))
    }

    try {
      await dispatch(refresh(companyKey, uri))
    } finally {
      dispatch(stopUpgrading(companyKey))
    }
  }
}
