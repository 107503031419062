export const DIRECTION_INBOUND = 'inbound'
export const DIRECTION_OUTBOUND = 'outbound'

export const isInbound = (direction) => {
  if (!direction) return false
  if (typeof direction.valueOf() !== 'string') return false

  return direction.toLowerCase().indexOf(DIRECTION_INBOUND) === 0
}

export const isOutbound = (direction) => {
  if (!direction) return false
  if (typeof direction.valueOf() !== 'string') return false

  return direction.toLowerCase().indexOf(DIRECTION_OUTBOUND) === 0
}

export const getDirection = (code) => {
  if (isOutbound(code)) return DIRECTION_OUTBOUND
  if (isInbound(code)) return DIRECTION_INBOUND
}
