import React, { Component } from 'react'

import { Panel, Table } from 'react-bootstrap'

class Tab extends Component {
  render() {
    return (
      <Panel className='help'>
        <h4>Cloud Email Archiving Introduction</h4>
        <p>
          All emails that have been filtered and classified as clean are stored in your
          archive and the metadata for each email is recorded and stored in our
          distributed database, along with the actual email message (blob). The archiving
          interface allows you to search for archived emails by matching the metadata
          associated with your archive and also allows you to download matched emails to
          your local machine.
        </p>

        <h4>Searching For Messages</h4>
        <p>
          To search for messages you can enter the term you are looking for in the search
          box and the search engine will attempt to match the query string against all
          metadata associated with your email archive. Wildcards are supported in the
          search box via the special characters &quot;*&quot; (asterisk) and &quot;?&quot;
          (question mark) for multi-character and single character globbing respectively.
        </p>
        <p>
          For example, putting the word &quot;bob*&quot; in the search box will match
          emails sent to or from email addresses starting with bob (e.g. bob@example.com
          as well as bob.smith@any.domain.com), as well as any recipients and/or subject
          starting with &quot;bob&quot;.
        </p>
        <p>
          The following table describes the metadata that is indexed and searchable for
          all emails:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Search Term</th>
              <th>Search String Format</th>
              <th>Parsed From</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>subject</code>
              </td>
              <td>None</td>
              <td>Email Headers</td>
            </tr>
            <tr>
              <td>
                <code>sender</code>
              </td>
              <td>RFC email address</td>
              <td>Envelope MAIL FROM</td>
            </tr>
            <tr>
              <td>
                <code>recipient</code>
              </td>
              <td>RFC email address</td>
              <td>Envelope RCPT TO</td>
            </tr>
            <tr>
              <td>
                <code>message-id</code>
              </td>
              <td>
                RFC <code>Message-ID</code> format
              </td>
              <td>Email Headers</td>
            </tr>
            <tr>
              <td>
                <code>smx-id</code>
              </td>
              <td>SMX Message ID format</td>
              <td>SMX Message Identifier</td>
            </tr>
            <tr>
              <td>
                <code>ip</code>
              </td>
              <td>IPv4 address in dot-decimal notation</td>
              <td>Sender MTA IP</td>
            </tr>
            <tr>
              <td>
                <code>size</code>
              </td>
              <td>
                Number of bytes. It can optionally include the SI unit, e.g.{' '}
                <code>K</code>, <code>MB</code> etc
              </td>
              <td>Message Size</td>
            </tr>
          </tbody>
        </Table>
        <p>
          As you type in the search box your browser will attempt to auto-complete valid
          metadata terms to search across. Note these are not search results but valid
          search terms.
          <br />
          You can also specify the time-zone and date range to apply your query to. The
          default date range is the previous calendar month.
        </p>
        <p>
          When the default operator <code>:</code> is used, the <code>size</code> search
          term matches message sizes approximately. For example <code>size:1.3M</code>{' '}
          will also find emails with a size of 1.2 MB or 1.4 MB.
        </p>

        <h4>Advanced Searching</h4>
        <p>
          You can confine your query to specific metadata terms and by combining terms you
          can refine your search even further. To search across specific metadata fields,
          enter the search term followed by a colon then your search string. For example,
          the following query will search for all messages from any email address starting
          with &quot;bob&quot; and containing the word &quot;meeting&quot; in the subject:
        </p>
        <p>
          <code>sender:bob* subject:meeting*</code>
        </p>
        <p>
          The above query is equivalent to <code>sender:bob* and subject:meeting*</code>.
          You can also use <code>or</code>, in which case mails matching any of the terms
          will be returned. The <code>and/or</code> join operators can be combined to
          create complex multi-term queries. Parentheses &quot;( )&quot; can also be used
          to change the precedence of search terms. The following query is such an example
          which will search for all mails either sent or received by
          &quot;bob@example.com&quot;, and containing &quot;meeting&quot; in the subject:{' '}
        </p>
        <p>
          <code>
            (sender:bob@example.com or recipient:bob@example.com) and subject:meeting
          </code>
        </p>
        <p>
          If the search string has space, it needs to be quoted (with either single or
          double quotes). For example, the following search query will match all emails
          with subjects containing both &quot;monday&quot; and &quot;meeting&quot; (in the
          same order):
        </p>
        <p>
          <code>subject:&quot;monday meeting&quot;</code>
        </p>
        <p>
          You can also use square brackets &quot;[ ]&quot; to match a range of characters
          in a glob or curly brackets {'&quot;{ }&quot;'} to match a range of strings
          separated by &quot;,&quot;. For example, <code>sender:[br]ob*</code> will match
          all emails sent from an email address starting with &quot;bob&quot; or
          &quot;rob&quot;, and <code>sender:{'{ga,ro}'}ry*</code> will match addresses
          starting with &quot;gary&quot; or &quot;rory&quot;.
        </p>
        <p>
          Some fields like <code>size</code> support range operators:{' '}
          <code>&gt; &gt;= &lt; &lt;=</code>. Note that you should not put a colon after
          the field name when using these operators. For example, the following search
          query will match all emails with a size (in bytes) between 10240 and 20480:
        </p>
        <p>
          <code>size&gt;10240 and size&lt;20480</code>
        </p>

        <h4>Downloading Archived Emails</h4>
        <p>
          Once you have entered your search query press Enter or click the Search button.
          This will submit your query to the search engine and all emails that match your
          query are displayed in the results table. Clicking emails listed in the results
          table allows you to view the complete set of information stored for that email.
          From the detailed information view you can choose to download the message in{' '}
          <code>.eml</code> (RFC822 MIME) format by clicking the &quot;Download&quot;
          button. Clicking the &quot;Back&quot; button takes you back to the previous
          search results window.
        </p>

        <h4>Zip Archive</h4>
        <p>
          If your search query returns multiple messages you can select one or more to
          download as a single archive in Zip format. Select the messages you want to
          download by ticking the checkbox for each row, and then click
          &quot;Download&quot;. Selection is only valid for one page. This will create an
          archive file in Zip format, including a manifest file describing all the files
          in the Zip file, which will be downloaded by your browser and saved to your
          local machine. Individual emails, in <code>.eml</code> format, can then be
          extracted from the Zip archive as needed and opened in a standard mail client
          such as Outlook or Thunderbird.
        </p>
      </Panel>
    )
  }
}

export default Tab
