const Ext = require('ext')
const ConditionClause = require('smartrules/designer/ConditionClause')

const AddressableConditionClause = Ext.define(null, {
  extend: ConditionClause,

  constructor(cfg = {}) {
    if (cfg.operator && cfg.system === true) {
      cfg.operator += '-system'
    }

    const config = {
      selected: cfg.quantifier || cfg.condition,
      steps: cfg.steps,
      listStores: cfg.listStores,
      system: cfg.system,
      negated: cfg.negated,
      matcher: cfg.operator,
      valueType: cfg.operator || cfg.valueType,
      defaultStepWidth: cfg.defaultStepWidth || 152,
      defaultOperatorWidth: 145
    }

    this.callParent([config])
  }
})

module.exports = AddressableConditionClause
