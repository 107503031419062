import * as hal from 'hal'

import { MAIL_TARGET_REL } from 'product/resources'
import Membership from 'membership/collections/Membership'
import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('quarantine')
const formDb = (db) => db.form

export const mailTargets = createSelector(
  company,
  (company) => company && company[MAIL_TARGET_REL]
)

export const uri = createSelector(mailTargets, (mailTargets) => mailTargets?.uri)

export const list = createSelector(mailTargets, (mailTargets) => mailTargets?.list)

export const loading = createSelector(mailTargets, (mailTargets) =>
  Boolean(mailTargets?.loading)
)

export const creating = createSelector(mailTargets, (mailTargets) =>
  Boolean(mailTargets?.creating)
)

export const renameTarget = createSelector(
  mailTargets,
  (mailTargets) => mailTargets?.renameTarget
)

export const renameTargetLink = createSelector(renameTarget, (renameTarget) => {
  return renameTarget && hal.self(renameTarget)
})

export const renameTargetName = createSelector(
  renameTarget,
  (renameTarget) => renameTarget?.name
)

export const renameTargetEtag = createSelector(
  renameTarget,
  (renameTarget) => renameTarget?.etag
)

export const renaming = createSelector(renameTarget, (renameTarget) =>
  Boolean(renameTarget)
)

export const configTarget = createSelector(
  mailTargets,
  (mailTargets) => mailTargets?.configTarget
)

export const configuring = createSelector(configTarget, (configTarget) =>
  Boolean(configTarget)
)

export const configTargetName = createSelector(
  configTarget,
  (configTarget) => configTarget?.name
)

export const configTargetEtag = createSelector(
  configTarget,
  (configTarget) => configTarget?.etag
)

export const configTargetUpdateMembersLink = createSelector(
  configTarget,
  (configTarget) => {
    return (
      configTarget && hal.href(configTarget, 'smx3:customer/update-mail-target-members')
    )
  }
)

export const selected = createSelector(list, (list) =>
  list?.some((mailTarget) => mailTarget.selected)
)

export const selectedMailTarget = createSelector(list, (list) =>
  list?.find((mailTarget) => mailTarget.selected)
)

export const selectedMailTargetName = createSelector(
  selectedMailTarget,
  (selectedMailTarget) => selectedMailTarget?.name
)

export const selectedMailTargetReadOnly = createSelector(
  selectedMailTarget,
  (selectedMailTarget) => selectedMailTarget && !!selectedMailTarget['read-only']
)

export const selectedMailTargetLink = createSelector(
  selectedMailTarget,
  (selectedMailTarget) => {
    return selectedMailTarget && hal.self(selectedMailTarget)
  }
)

export const size = createSelector(list, (list) => list?.length)

export const hasModalDialog = createSelector(
  creating,
  renaming,
  configuring,
  (creating, renaming, configuring) => {
    return creating || renaming || configuring
  }
)

export const blocked = createSelector(
  hasModalDialog,
  loading,
  (hasModalDialog, loading) => hasModalDialog || loading
)

export const addButtonDisabled = createSelector(blocked, (blocked) => blocked)

export const renameButtonDisabled = createSelector(
  blocked,
  selected,
  selectedMailTargetReadOnly,
  (blocked, selected, selectedMailTargetReadOnly) =>
    blocked || !selected || selectedMailTargetReadOnly
)

export const configureButtonDisabled = createSelector(
  blocked,
  selected,
  selectedMailTargetReadOnly,
  (blocked, selected, selectedMailTargetReadOnly) =>
    blocked || !selected || selectedMailTargetReadOnly
)

export const deleteButtonDisabled = createSelector(
  blocked,
  selected,
  selectedMailTargetReadOnly,
  (blocked, selected, selectedMailTargetReadOnly) =>
    blocked || !selected || selectedMailTargetReadOnly
)

export const refreshButtonDisabled = createSelector(blocked, (blocked) => blocked)

export const formDisabled = createSelector(loading, (loading) => loading)

export const mailTargetForm = createSelector(formDb, (formDb) => formDb.mailTargetForm)

export const configTargetMembership = createSelector(configTarget, (configTarget) => {
  if (configTarget) {
    const members = configTarget.members
    return members && new Membership(members)
  }
})

// this transforms the members field of a selected mail target
// to an acceptable format for backend
const configTargetMembers = createSelector(
  configTargetMembership,
  (configTargetMembership) => {
    return configTargetMembership?.getAllNamesEnabled()
  }
)

export const mailTargetConfiguration = createSelector(
  configTargetMembers,
  (configTargetMembers) => {
    return configTargetMembers && { members: configTargetMembers }
  }
)

export const confirmingDeletion = createSelector(
  mailTargets,
  (mailTargets) => mailTargets?.confirmingDeletion
)

export const names = createSelector(list, (list) => list?.map((target) => target.name))
