export const reconvertValue = function (field, formValue) {
  if (!field) return formValue
  if (!field.reconvert) return formValue

  const reconvertedValue = field.reconvert(formValue)

  return reconvertedValue || formValue
}

export const reconvertValues = function (fieldMap, formValues) {
  return Object.keys(formValues).reduce(function (memo, formKey) {
    const field = fieldMap[formKey]
    const formValue = formValues[formKey]

    memo[formKey] = reconvertValue(field, formValue)

    return memo
  }, {})
}
