import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import React, { Component } from 'react'
import {
  addButtonDisabled,
  deleteButtonDisabled,
  loading,
  refreshButtonDisabled,
  renameButtonDisabled,
  selected,
  selectedTenanciesSuspendLinks,
  selectedTenanciesUnsuspendLinks,
  selectedTenancy,
  size,
  suspendButtonDisabled,
  unsuspendButtonDisabled,
  uri
} from 'integration/tenancy/subs'
import {
  refresh,
  startConfirmingDeletion,
  startCreating,
  startRenaming,
  suspendTenancies,
  unsuspendTenancies
} from 'integration/tenancy/actions'

import Indicator from 'components/Indicator'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class Toolbar extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  startCreating = () => this.props.startCreating()

  startRenaming = () => this.props.startRenaming(this.props.selectedTenancy)

  suspend = () =>
    this.props.suspendTenancies(this.props.uri, this.props.selectedTenanciesSuspendLinks)

  unsuspend = () =>
    this.props.unsuspendTenancies(
      this.props.uri,
      this.props.selectedTenanciesUnsuspendLinks
    )

  refresh = () => this.props.refresh(this.props.uri)

  renderLeftButtons() {
    return (
      <ButtonToolbar className='pull-left'>
        <Button disabled={this.props.addButtonDisabled} onClick={this.startCreating}>
          {t('Add')}
        </Button>
        <Button disabled={this.props.renameButtonDisabled} onClick={this.startRenaming}>
          {t('Rename')}
        </Button>
        <Button disabled={this.props.suspendButtonDisabled} onClick={this.suspend}>
          {t('Disable')}
        </Button>
        <Button disabled={this.props.unsuspendButtonDisabled} onClick={this.unsuspend}>
          {t('Enable')}
        </Button>
        <Button
          disabled={this.props.deleteButtonDisabled}
          onClick={this.props.startConfirmingDeletion}
        >
          {t('Delete')}
        </Button>
      </ButtonToolbar>
    )
  }

  render() {
    return (
      <div className='toolbar clearfix'>
        <Row className='no-gutter'>
          <Col className='no-gutter'>{this.renderLeftButtons()}</Col>
          <Col className='no-gutter'>
            <div className='pull-right'>
              <Indicator
                loading={this.props.loading}
                size={this.props.size}
                noun='tenancy'
              />
              <ButtonToolbar className='right-button-toolbar'>
                <Button
                  disabled={this.props.refreshButtonDisabled}
                  onClick={this.refresh}
                >
                  {t('Refresh')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  selected: selected(db, companyKey),
  selectedTenancy: selectedTenancy(db, companyKey),
  size: size(db, companyKey),
  loading: loading(db, companyKey),
  selectedTenanciesSuspendLinks: selectedTenanciesSuspendLinks(db, companyKey),
  selectedTenanciesUnsuspendLinks: selectedTenanciesUnsuspendLinks(db, companyKey),
  addButtonDisabled: addButtonDisabled(db, companyKey),
  renameButtonDisabled: renameButtonDisabled(db, companyKey),
  suspendButtonDisabled: suspendButtonDisabled(db, companyKey),
  unsuspendButtonDisabled: unsuspendButtonDisabled(db, companyKey),
  deleteButtonDisabled: deleteButtonDisabled(db, companyKey),
  refreshButtonDisabled: refreshButtonDisabled(db, companyKey),
  uri: uri(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  startCreating: () => dispatch(startCreating(companyKey)),
  startConfirmingDeletion: () => dispatch(startConfirmingDeletion(companyKey)),
  startRenaming: (selectedTenancy) =>
    dispatch(startRenaming(companyKey, selectedTenancy)),
  suspendTenancies: (uri, links) => dispatch(suspendTenancies(companyKey, uri, links)),
  unsuspendTenancies: (uri, links) =>
    dispatch(unsuspendTenancies(companyKey, uri, links)),
  refresh: (uri) => dispatch(refresh(companyKey, uri))
})

export default connect(mapDbToProps, mapActionsToProps)(Toolbar)
