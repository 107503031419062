const _s = require('underscore.string')
const isRegex = require('smartrules/designer/util/isRegex').default

// and this converts any detected regex from backend back to words which are globs
// for the user's convenience, i.E. "/(to|cc)/i" becomes "To or Cc"

// used for when translating from source back to designer
export default function (regex, cfg = {}) {
  if (!isRegex(regex)) return

  const words = _s.rtrim(_s.ltrim(regex, '/('), ')/i')

  if (cfg.convertOrToRegex) {
    const orExpressions = words.split('|')

    return orExpressions.map((orExpression) => _s.capitalize(orExpression)).join(' or ')
  }

  return words
}
