import React, { Component } from 'react'

import Frame from 'react-frame-component'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import compareStrings from 'strings/compare/lexical'
import { toElement } from 'sprites'

const svgCensoredImage = toElement('censored')

class Panel extends Component {
  static propTypes = {
    preview: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.htmlBodyRef = React.createRef()
  }

  hasContentChanged(nextProps, contentKey) {
    return (
      compareStrings(this.props.preview[contentKey], nextProps.preview[contentKey]) !== 0
    )
  }

  isHtml() {
    return this.props.preview['html-body']
  }

  shouldComponentUpdate(nextProps) {
    // performance optimisation to avoid unnecessary rendering
    if (this.isHtml() && nextProps.preview['html-body']) {
      return this.hasContentChanged(nextProps, 'html-body')
    } else if (this.props.preview['text-body'] && nextProps.preview['text-body']) {
      return this.hasContentChanged(nextProps, 'text-body')
    }

    return this.props.preview !== nextProps.preview
  }

  adjustIframeHeight = () => {
    // only fetch these nodes here, not inside render, this to keep
    // the render function pure using states only. this to make
    // hot reloading work better on local development.

    // eslint-disable-next-line react/no-find-dom-node
    const iframeDOMNode = ReactDOM.findDOMNode(this)

    // eslint-disable-next-line react/no-find-dom-node
    const htmlBodyDOMNode = ReactDOM.findDOMNode(this.htmlBodyRef.current)

    if (!iframeDOMNode || !htmlBodyDOMNode) {
      return
    }

    const htmlBodyHeight = htmlBodyDOMNode.clientHeight

    // beware, offsetParent can be null
    const scrollHeight =
      htmlBodyDOMNode.offsetParent && htmlBodyDOMNode.offsetParent.scrollHeight

    // scroll height for iframes is never smaller than 150px,
    // hence this additional check
    // default iframe height is specified here:
    // https://www.w3.org/TR/CSS2/visudet.html#inline-replaced-height
    if (scrollHeight > 150) {
      iframeDOMNode.height = scrollHeight + 'px'
    } else {
      const offsetTop = htmlBodyDOMNode.offsetTop
      iframeDOMNode.height = htmlBodyHeight + offsetTop + 'px'
    }
  }

  render() {
    if (this.isHtml()) {
      // need to overwrite the default prop for adding the <base>
      // tag which ensures all link open in new browser tabs
      //
      // svg contents for censored image are pasted from their original
      // source. since it is in an iframe we cant be deploying/referencing
      // it the usual way.
      //
      // unfortunately hot reloading doesnt work when the styles below
      // are changed, see https://github.com/ryanseddon/react-frame-component#initialcontent
      //
      // css inspired by https://bitsofco.de/styling-broken-images/
      const initialContent = `
        <!DOCTYPE html>
        <html>
          <head>
            <base target="_blank" />
            <style>
              html, body {height: 100%; margin: 0; padding: 0; overflow: hidden;}


              img, img:before {
                min-height: 29px !important;
                min-width: 29px !important;
              }

              img {
                position: relative !important;
                text-indent: -999999px !important;
                display: inline-block !important;
                content: url("") !important;
                white-space: nowrap !important;
              }

              img:before {
                background-repeat: no-repeat !important;
                background-image: url('data:image/svg+xml;utf8,${svgCensoredImage}') !important;
                background-color: rgb(240, 240, 240) !important;
                background-size: 25px !important;
                background-position: 1px 0px !important;
                content: attr(alt) !important;
                display: block !important;
                position: absolute !important;
                width: auto !important;
                text-indent: 29px !important;
                left: 0 !important;
                top: 0 !important;
                padding: 2px !important;
                height: 100% !important;
                border: 1px solid rgb(210, 210, 210) !important;
              }
            </style>
          </head>
          <body>
            <div class="frame-root"></div>
          </body>
        </html>
      `

      // with those additional iframe sandbox restrictions we can
      // fine-tune what exactly is allowed to be run (anything else will be forbidden):
      // allow-same-origin = allows our own html contents
      // allow-popups = allows links to be opened in new tabs

      return (
        <Frame
          sandbox='allow-same-origin allow-popups'
          className='htmlBody'
          initialContent={initialContent}
          contentDidMount={() => this.adjustIframeHeight()}
          contentDidUpdate={() => this.adjustIframeHeight()}
        >
          <div
            ref={this.htmlBodyRef}
            dangerouslySetInnerHTML={{ __html: this.props.preview['html-body'] }}
          />
        </Frame>
      )
    }

    return <pre className='textBody'>{this.props.preview['text-body']}</pre>
  }
}

export default Panel
