import Alert from 'admin/util/Alert'
import backendUnavailable from 'api/backendUnavailable'
import isClientError from 'api/isClientError'
import isDeveloping from 'system/isDeveloping'
import isTesting from 'system/isTesting'
import { addBreadcrumb, captureException } from 'system/sentry'

const displayError = function (err) {
  if (err?.response) {
    switch (err.response.status) {
      case 405:
        // since SCL-3388 we agreed to display a general error message
        // instead of cryptic ones backend is returning upon 405s
        Alert.displayGeneralError()
        break
      case 412:
        Alert.info(
          'Update data conflict',
          'This information has been updated on the server, please refresh.'
        )
        break
      default:
        Alert.displayResponseError(err.response)
    }
  } else {
    Alert.displayGeneralError()
  }
}

// note: warning can be an error object or a string
const warn = function (warning, options = {}) {
  if (isDeveloping()) {
    console.warn(warning)
  }

  // In rare cases the caller of this function will already report to Sentry
  // so no need to report again. For example see processGeneralError fn in App.js
  if (options.disableSentry) return

  addBreadcrumb({
    category: 'warning',
    message: warning.toString()
  })
}

const error = function () {
  const err1 = arguments[0]

  // only perform that check if error contains a backend response
  if (err1.response && backendUnavailable(err1.response.status)) {
    // do nothing, already processed in the api namespace
    return
  }

  // this makes sure any errors are always seen when developing or testing
  if (isDeveloping() || isTesting()) {
    // do not display errors we throw ourselves for mocking network errors in jest tests
    if (err1.toString() === 'Error: Network Error (mocked)') return

    console.error.apply(console, arguments)
  } else if (!isClientError(err1)) {
    // otherwise report to Sentry when not a 4xx client error response
    captureException(err1)
  }

  // always display the error message you see on production to ensure
  // same customer experience on all environments
  displayError(err1)
}

export default { warn, error }
