import History from 'admin/util/History'
import Window from 'admin/view/Window'
import Ext from 'ext'
import { escape } from 'html-escaper'
import logger from 'system/logger'
import normaliseUrl from 'url/normalise'
import stripApi from 'url/stripApi'

const markActive = function (win) {
  // already active
  if (win.minimized === false) return

  win.minimized = false
  const tabPanel = Ext.getCmp('next-desktop-footer')
  tabPanel.setActiveWindow(win)

  // Need timeout because clearHash in removeWin callback is called after this.
  setTimeout(() => {
    // this will trigger updateState fn in History.js which
    // will do the GA page view
    Ext.History.add(`!${win.getPath()}`)
  }, 10)
}

const WindowMgr = Ext.define(null, {
  singleton: true,

  windows: {},
  windowGroup: Ext.WindowMgr,

  getId(url) {
    return this.windows[normaliseUrl(url)]
  },

  getWindow(url) {
    return this.windowGroup.get(this.getId(url))
  },

  exists(url) {
    return !!this.getId(url)
  },

  selectByUrl(url) {
    const windowId = this.getId(url)

    if (windowId) {
      return this.select(windowId)
    }
  },

  select(windowId) {
    const win = this.windowGroup.get(windowId)

    if (win) {
      win.show()
      Ext.History.add(`!${win.getPath()}`)
      return win
    }

    return false
  },
  setTitle(windowId, title) {
    const win = this.windowGroup.get(windowId)
    win?.setTitle(title)
  },

  close(windowId) {
    const win = this.windowGroup.get(windowId)
    win?.close()
  },

  create(parameters = {}, options = {}) {
    let desktopCenter

    if (parameters.url) {
      parameters.url = stripApi(normaliseUrl(parameters.url))
    }

    if (parameters.items?.idBase) {
      parameters.id = `${parameters.items.idBase}:${new Date().getTime()}`
    }

    // todo do not place ids in global scope, ugh
    let win = this.windowGroup.get(window.id)

    if (!win) {
      if (parameters.title) {
        parameters.title = escape(parameters.title)
      }

      if (parameters.taskbar) {
        parameters.taskbar = escape(parameters.taskbar)
      }

      const activeWindow = this.windowGroup.getActive()

      parameters = Ext.applyIf(parameters, {
        renderTo: 'next-desktop-center',
        manager: this.windowGroup,
        resizeWhenMaximized() {
          // trap race conditions resizing too early, see SCL-3434
          if (!this.el.dom) return

          this.maximized && this.fitContainer()
        },
        showTaskbar: true
      })

      if (options.maximized && !parameters.maximized) {
        parameters.maximized = options.maximized
      }

      let coordinates

      if (activeWindow && !(activeWindow.noAnchor || activeWindow.modal)) {
        coordinates = {
          x: activeWindow.x + 20,
          y: activeWindow.y + 20
        }
      } else {
        coordinates = {
          x: 10,
          y: 5
        }
      }

      parameters = Ext.applyIf(parameters, coordinates)

      win = new Window(parameters)

      desktopCenter = Ext.getCmp('next-desktop-center')
      desktopCenter.on('resize', win.resizeWhenMaximized, win)

      win.on('beforedestroy', () =>
        desktopCenter.un('resize', win.resizeWhenMaximized, win)
      )

      win.on('move', function (comp, x, y) {
        if (x !== 0 || y < 0) {
          const w = comp.getWidth()
          const dw = desktopCenter.getWidth()

          if (x < 0 || x + w > dw || y < 0) {
            return comp.setPosition(Math.max(0, Math.min(dw - w, x)), Math.max(0, y))
          }
        }
      })

      if (parameters.showTaskbar) {
        const tabPanel = Ext.getCmp('next-desktop-footer')
        tabPanel.addButton(win)

        const minimizeWin = function (win) {
          tabPanel.deactivateWindow(win)
          win.minimized = true
          win.hide()
        }

        const removeWin = function (win) {
          tabPanel.removeWindowButton(win)
          delete this.windows[win.url]
          const newActiveWin = this.windowGroup.getActive()
          if (newActiveWin) {
            Ext.History.add(`!${newActiveWin.getPath()}`)
          } else {
            History.clearHash() // Or set to a default state if no active window exists
          }
        }
        win.on('activate', markActive, this)
        win.on('beforeshow', markActive, this)

        win.on('minimize', minimizeWin, this)
        win.on('close', removeWin, this)
      }
    }

    win.show()

    if (!parameters.url) {
      logger.warn(`Window parameter for URL is missing for: ${win.id}`)
    }

    this.windows[normaliseUrl(parameters.url)] = win.id
    desktopCenter.syncSize()

    Ext.getCmp('next-desktop-viewport').doLayout()

    if (parameters.alignToElement) {
      win.alignTo(parameters.alignToElement, 'tl-tr')
    }

    if (typeof parameters.callback === 'function') {
      parameters.callback(win)
    }

    return win
  }
})

export default WindowMgr
