const Ext = require('ext')
const t = require('translate')
const ResourceNewPanel = require('admin/view/ResourceNewPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const validators = require('validators').default
const ListTypes = require('smartrules/ListTypes')
const Tooltip = require('admin/util/Tooltip')

const ListManagementNewPanel = Ext.define(null, {
  extend: ResourceNewPanel,

  cls: 'listmanagement-new-panel',

  module() {
    return require('smartrules/ListManagement')
  },

  getFormPanel(cfg) {
    const panel = new RecordFormPanel({
      fieldWidth: 'auto',
      module: cfg.module,
      operation: 'create',
      items: this.getNewItems(cfg)
    })
    panel.on('afterlayout', cfg.module.updateFieldSize, cfg.module)
    return panel
  },

  getNewItems() {
    const dataTypeStore = this.module.getDataTypeStore()
    const helpText = ListTypes.getHelpText(ListTypes.RULE_ADDRESS)

    const explanationField = new Ext.form.DisplayField({
      style: 'padding: 8px 0 4px',
      value: ListTypes.getExplanation(ListTypes.RULE_ADDRESS),
      htmlEncode: true
    })

    const dataSetType = new ComboBox({
      allowBlank: false,
      displayField: 'description',
      editable: false,
      helpText,
      hiddenName: 'dataSetType',
      mode: 'local',
      fieldLabel: t('List Type'),
      listeners: {
        scope: this,
        select(combo, record) {
          Tooltip.register(combo.container.child('.helptext'), record.get('example'))
          const listType = record.get('value')
          explanationField.setValue(ListTypes.getExplanation(listType))
          return items.validate()
        }
      },
      store: dataTypeStore,
      triggerAction: 'all',
      value: ListTypes.RULE_ADDRESS,
      valueField: 'value'
    })

    const items = new Ext.form.TextArea({
      height: 250,
      name: 'items',
      fieldLabel: t('Items'),
      xtype: 'textarea',

      validator(v) {
        if (dataSetType.value === ListTypes.RULE_ADDRESS) {
          if (validators.linebrokenEmailAndGroupGlobs(v)) {
            return true
          }
          return t('Address lists can contain email addresses or groups.')
        }
        return true
      }
    })
    return [
      {
        name: 'name',
        maxLength: 255,
        allowBlank: false,
        fieldLabel: t('Name'),
        xtype: 'textfield',
        validator(v) {
          if (v.match(/^\w[\w -]*$/)) {
            return true
          }
          return 'Names can only have alphanumeric characters and spaces'
        }
      },
      dataSetType,
      explanationField,
      items
    ]
  }
})

module.exports = ListManagementNewPanel
