import React, { Component } from 'react'

import FormInput from 'components/legacy/FormInput'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import ReactPasswordStrength from 'react-password-strength'
import _s from 'underscore.string'
import t from 'translate'

const WEAK_SCORE = 0
const ADEQUATE_SCORE = 1
const OK_SCORE = 2
const STRONG_SCORE = 3
const GREAT_SCORE = 4

const TOO_SHORT = t('Too short')

const createEvent = (type) => {
  // treatment for newer and older browsers
  if (typeof Event === 'object') {
    return new Event(type)
  } else if (typeof document.createEvent === 'function') {
    const evt = document.createEvent('HTMLEvents')
    evt.initEvent(type, false, true)
    return evt
  }

  throw new Error('Unable to create event object.')
}

// TODO rewrite this, all that state handling is horrible

class PasswordInput extends Component {
  static propTypes = {
    requirePassword: PropTypes.bool,
    requireRepeatPassword: PropTypes.bool,
    disabled: PropTypes.bool,
    minLength: PropTypes.number,
    onPasswordChange: PropTypes.func,
    onRepeatPasswordChange: PropTypes.func
  }

  static defaultProps = {
    requirePassword: true,
    requireRepeatPassword: true,
    minScore: ADEQUATE_SCORE,
    scoreWords: [t('Weak'), t('Adequate'), t('OK'), t('Strong'), t('Great')],
    label: t('Password'),
    repeatLabel: t('Repeat Password'),
    currentLabel: t('Current Password'),

    // todo: have user/login/mailbox components configure this through capabilities in a new ticket
    // for now, this is just a "common denominator"
    minLength: 6
  }

  static WEAK_SCORE = WEAK_SCORE
  static ADEQUATE_SCORE = ADEQUATE_SCORE
  static OK_SCORE = OK_SCORE
  static STRONG_SCORE = STRONG_SCORE
  static GREAT_SCORE = GREAT_SCORE

  state = {
    currentPassword: '',
    password: '',
    repeatPassword: '',
    currentPasswordValidity: false
  }

  constructor(props) {
    super(props)

    this.currentPasswordRef = React.createRef()
    this.passwordRef = React.createRef()
    this.repeatPasswordRef = React.createRef()
  }

  getMinScoreLabel = () => this.props.scoreWords[this.props.minScore]

  getPasswordHelp = () => {
    const help = []

    // the following is not so nice and requires a rewrite because we are abusing the help
    // logic to display an error instead.
    if (this.state.passwordValidity && this.state.passwordValidity.error) {
      help.push(this.state.passwordValidity.error)
    } else {
      // this one covers all other states,
      // especially when the user hasn't entered anything at all yet   v
      help.push(
        t('Password must have a strength score of at least "{minScore}".', {
          minScore: this.getMinScoreLabel()
        })
      )
    }

    help.push(t('Try multiple words or a phrase e.g. “whales are not fish”;'))
    help.push(t('10+ characters, numbers and symbols e.g. “Real Madrid 6-0”.'))

    return help.join(' ')
  }

  getRepeatPasswordHelp = () => {
    if (
      this.state.repeatPassword &&
      this.state.repeatPasswordValidity &&
      this.state.repeatPasswordValidity.valid
    ) {
      return t('Good, both passwords match')
    } else if (
      this.state.repeatPasswordValidity &&
      this.state.repeatPasswordValidity.error
    ) {
      return this.state.repeatPasswordValidity.error
    }

    return t('Repeat the same password here')
  }

  validateCurrentPassword = (currentPassword) => {
    return {
      valid: currentPassword && !_s.isBlank(currentPassword)
    }
  }

  validateRepeatPassword = (password, repeatPassword) => {
    if (repeatPassword && repeatPassword !== password) {
      // Because the native validity object is readonly, we fake this with a new object
      return {
        valid: false,
        error: t('Both passwords do not match')
      }
    }

    // imagine the scenario when the user types the repeat password first.
    // it is valid when it is not empty but the first password field is empty
    return {
      valid: !!(repeatPassword && repeatPassword.length > 0)
    }
  }

  handleCurrentPasswordChange = (currentPassword) => {
    return this.setState({
      currentPassword,
      currentPasswordValidity: this.validateCurrentPassword(currentPassword)
    })
  }

  handlePasswordChange = (subState) => {
    const { password } = subState
    const passwordValidity = { valid: subState.isValid }

    if (!subState.isValid) {
      if (password.length < this.props.minLength) {
        passwordValidity.error = t('Longer password required.')
      } else {
        passwordValidity.error = t('Stronger password required.')
      }
    }

    const repeatPasswordValidity = this.validateRepeatPassword(
      password,
      this.state.repeatPassword
    )

    this.setState(
      {
        password,
        passwordValidity,
        repeatPasswordValidity
      },
      () => {
        if (this.props.onPasswordChange) {
          this.props.onPasswordChange(password, {
            password: passwordValidity.error,
            repeatPassword: this.state.repeatPasswordValidity.error
          })
        }
      }
    )
  }

  handleRepeatPasswordChange = (repeatPassword) => {
    const repeatPasswordValidity = this.validateRepeatPassword(
      this.state.password,
      repeatPassword
    )

    this.setState(
      {
        repeatPassword,
        repeatPasswordValidity
      },
      () => {
        const passwordError = this.state.passwordValidity
          ? this.state.passwordValidity.error
          : null

        const repeatPasswordError = repeatPasswordValidity
          ? repeatPasswordValidity.error
          : null

        if (this.props.onRepeatPasswordChange) {
          this.props.onRepeatPasswordChange(repeatPassword, {
            password: passwordError,
            repeatPassword: repeatPasswordError
          })
        }
      }
    )
  }

  adjustValidityFor = (element, validity) => {
    let customValidity

    if (validity && validity.error && !validity.valid) {
      customValidity = validity.error
    } else {
      customValidity = ''
    }

    element.setCustomValidity(customValidity)

    const evt = createEvent('change')
    element.dispatchEvent(evt)
  }

  componentDidUpdate() {
    const currentPassword = this.currentPasswordRef.current
    const password = this.passwordRef.current
    const repeatPassword = this.repeatPasswordRef.current

    if (currentPassword) {
      this.adjustValidityFor(
        // eslint-disable-next-line react/no-find-dom-node
        ReactDOM.findDOMNode(currentPassword),
        this.state.currentPasswordValidity
      )
    }

    this.adjustValidityFor(
      password.reactPasswordStrengthInput,
      this.state.passwordValidity
    )
    this.adjustValidityFor(
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(repeatPassword),
      this.state.repeatPasswordValidity
    )
  }

  getPasswordControlProps = () => {
    return {
      ref: this.passwordRef,
      changeCallback: this.handlePasswordChange,
      minScore: this.props.minScore,
      scoreWords: this.props.scoreWords,
      minLength: this.props.minLength,
      tooShortWord: TOO_SHORT,
      inputProps: {
        name: 'password',
        disabled: this.props.disabled,
        autoComplete: 'off',
        value: this.state.password,
        className: 'form-control'
      }
    }
  }

  renderCurrentPasswordInput() {
    return (
      <FormInput
        required
        disabled={this.props.disabled}
        ref={this.currentPasswordRef}
        label={this.props.currentLabel}
        value={this.state.currentPassword}
        validity={this.state.currentPasswordValidity}
        stretch={this.props.stretch}
        name='currentPassword'
        autoComplete='off'
        type='password'
        onChange={this.handleCurrentPasswordChange}
      />
    )
  }

  // todo: figure out how to tell google chrome to shut up
  // it still asks for passwords despite of autoComplete = false
  render() {
    return (
      <div>
        {this.props.requireCurrentPassword && this.renderCurrentPasswordInput()}
        <FormInput
          required={this.props.requirePassword}
          disabled={this.props.disabled}
          groupClassName='password-group'
          label={this.props.label}
          value={this.state.password}
          validity={this.state.passwordValidity}
          help={this.getPasswordHelp()}
          stretch={this.props.stretch}
          controlClass={ReactPasswordStrength}
          controlProps={this.getPasswordControlProps()}
        />
        <FormInput
          required={this.props.requireRepeatPassword}
          disabled={this.props.disabled}
          ref={this.repeatPasswordRef}
          label={this.props.repeatLabel}
          value={this.state.repeatPassword}
          help={this.getRepeatPasswordHelp()}
          validity={this.state.repeatPasswordValidity}
          stretch={this.props.stretch}
          autoComplete='off'
          type='password'
          onChange={this.handleRepeatPasswordChange}
        />
      </div>
    )
  }
}

export default PasswordInput
