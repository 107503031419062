const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const EventSearchResultRecord = require('admin/data/EventSearchResultRecord')

const EventSearchResultStore = Ext.define(null, {
  extend: ResourceStore,

  record: EventSearchResultRecord,

  hideError: true,
  resourceName: 'events',

  constructor(cfg = {}) {
    this.callParent([cfg])
    this.on(
      'loadexception',
      (proxy, options, response) => {
        if (
          response.status === 410 &&
          this.parentRecord != null &&
          this.parentRecord.store != null
        ) {
          // questionable code, probably never really worked. instances of this class
          // never have access to the parent record (see SCL-3385)
          const ParentRecord = this.parentRecord.store.record

          this.parentRecord.store.add([
            new ParentRecord({
              direction: this.parentRecord.get('direction'),
              type: this.parentRecord.get('type'),
              status: this.parentRecord.get('status'),
              subject: this.parentRecord.get('subject'),
              from: this.parentRecord.get('from'),
              to: this.parentRecord.get('to'),
              fromDate: this.parentRecord.get('fromDate'),
              thruDate: this.parentRecord.get('thruDate'),
              messageId: this.parentRecord.get('messageId'),
              sort: this.parentRecord.get('sort'),
              order: this.parentRecord.get('order'),
              limit: this.parentRecord.get('limit')
            })
          ])
        }
      },
      this
    )
  },

  getReader(recordType) {
    return new Ext.data.JsonReader(
      {
        root: 'results',
        totalProperty: 'totalCount'
      },
      recordType
    )
  }
})

module.exports = EventSearchResultStore
