import Ext from 'ext'
import { logoutQuietly } from 'system/logout'
import { addBreadcrumb } from 'system/sentry'

let timeout

export const resetInactivityTimeout = () => {
  timeout?.delay(30 * 60 * 1000) // 30 minutes
}

export const startInactivityTimeout = function () {
  // Timer that reloads page with 'timedout' cookie when it hits zero.
  // Login form displays message when it finds the cookie.
  timeout =
    timeout ||
    new Ext.util.DelayedTask(function () {
      addBreadcrumb({
        category: 'auth',
        message: 'Logging out user due to inactivity timeout.'
      })

      // Cookie expiry is different to timeout delay
      Ext.util.Cookies.set('timedout', 'timedout', new Date().add(Date.HOUR, 8))

      logoutQuietly()
    })

  resetInactivityTimeout()

  Ext.get(document).on('click', resetInactivityTimeout)
  Ext.get(document).on('keydown', resetInactivityTimeout)
}
