const Ext = require('ext')
const t = require('translate')
const Component = require('smartrules/designer/Component')
const JoinWord = require('smartrules/designer/JoinWord')
const DropZone = require('smartrules/designer/DropZone')
const NestingButton = require('smartrules/designer/NestingButton')
const DraggingButton = require('smartrules/designer/DraggingButton')
const DeleteButton = require('smartrules/designer/DeleteButton')

const GroupHeader = Ext.define(null, {
  extend: Component,

  constructor(cfg = {}) {
    let dropZone, joinWord

    cfg = Ext.applyIf(cfg, {
      nest: false,
      margins: '2 0 0 0',
      items: [],
      droppable: false
    })
    this.joinWord = joinWord = new JoinWord()
    this.dropZone = dropZone = new DropZone({
      html: t('Drag conditions here')
    })
    dropZone.hidden = cfg.nest

    const items = [
      joinWord,
      {
        border: false,
        flex: cfg.nest ? 1 : 0,
        items: cfg.items,
        margins: cfg.margins
      }
    ]

    if (cfg.droppable) {
      items.push(dropZone)
    }

    if (cfg.useSpacer) {
      const spacer = new Ext.Spacer({
        flex: 2,
        hidden: true
      })
      dropZone.on('show', () => spacer.hide())
      dropZone.on('hide', () => spacer.show())
      items.push(spacer)
    }

    if (cfg.nest) {
      items.push(new NestingButton())
    }
    items.push(
      new DraggingButton({
        width: 40,
        clause: cfg.group
      })
    )
    items.push(new DeleteButton())

    cfg = {
      cls: 'smartrules-group-header',
      defaults: {
        margins: '2'
      },
      items,
      layout: 'hbox',
      region: 'north',
      padding: '0 4',
      margins: '0',
      border: false
    }
    this.callParent([cfg])
    this.on('render', this.setupEvents, this, { single: true })
  },

  setJoinWord(word) {
    return this.joinWord.update(word)
  },

  setupEvents() {
    return this.getEl().hover(
      function () {
        return this.addClass('hover')
      },
      function () {
        return this.removeClass('hover')
      },
      this
    )
  }
})

module.exports = GroupHeader
