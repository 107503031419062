// Beware that we have the Ext core outside of our source folder, they are in the /lib folder
// To include the Ext core, just prepend the requires with `ext/`, the custom ones with `./`
require('ext/ext-all')
require('ext/locale/ext-lang-en')
require('ext/ux/grid/row-editor')
require('./util/cookie')
require('./ux/link')
require('./tree/TreeNodeUI')
require('./layout/MenuLayout')
require('./menu/Item')
require('./ext-overrides')
require('./ux/grid/CheckColumn')
require('./toolbar-override')

const Ext = require('ext/ext-base')

Ext.USE_NATIVE_JSON = true

// This line must stay here or else Karma tests will fail
Ext.Ajax.disableCaching = false

module.exports = Ext
