import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import t from 'translate'

import Dialog from 'mailboxes/views/form/Dialog'
import { update, stopEditing } from 'mailboxes/actions'

import {
  uri,
  editTarget,
  updateDetailsLink,
  updatePasswordLink,
  updateAliasLink,
  updateForwardLink
} from 'mailboxes/subs'

class EditForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.update(
      this.props.uri,
      values,
      this.props.updateDetailsLink,
      this.props.updatePasswordLink,
      this.props.updateAliasLink,
      this.props.updateForwardLink
    )
  }

  render() {
    return (
      <Dialog
        title={t('Edit Mailbox')}
        onSubmit={this.handleSubmit}
        onCancel={this.props.stopEditing}
        submitButtonText={t('Save')}
        companyKey={this.props.companyKey}
        mailbox={this.props.editTarget}
      />
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  editTarget: editTarget(db, companyKey),
  uri: uri(db, companyKey),
  updateDetailsLink: updateDetailsLink(db, companyKey),
  updatePasswordLink: updatePasswordLink(db, companyKey),
  updateAliasLink: updateAliasLink(db, companyKey),
  updateForwardLink: updateForwardLink(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  stopEditing: () => dispatch(stopEditing(companyKey)),
  update: (
    uri,
    values,
    updateDetailsLink,
    updatePasswordLink,
    updateAliasLink,
    updateForwardLink
  ) =>
    dispatch(
      update(
        companyKey,
        uri,
        values,
        updateDetailsLink,
        updatePasswordLink,
        updateAliasLink,
        updateForwardLink
      )
    )
})

export default connect(mapDbToProps, mapActionsToProps)(EditForm)
