import React, { Component } from 'react'
import { authenticateAndUpdateProvider, goBackToConfiguration } from '../../actions'
import {
  changedProviderName,
  confirmingConfiguration,
  providerAuthenticationFormPassword,
  selectedProviderLink,
  uri
} from 'sharedservices/provider/subs'

import Dialog from './Dialog'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class Configure extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.authenticateAndUpdateProvider(
      this.props.uri,
      this.props.selectedProviderLink,
      this.props.confirmingConfiguration,
      this.props.password
    )
  }

  render() {
    const title = t('Confirm changes for shared service provider {0}?', [
      this.props.changedProviderName
    ])

    const warning = t('Verify your password to confirm changes')

    return (
      <Dialog
        modalTitle={title}
        headerText={warning}
        submitButtonText={t('Configure')}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
        store={this.props.store}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  changedProviderName: changedProviderName(db, props.companyKey),
  confirmingConfiguration: confirmingConfiguration(db, props.companyKey),
  password: providerAuthenticationFormPassword(db),
  selectedProviderLink: selectedProviderLink(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(goBackToConfiguration(initialProps.companyKey)),
  authenticateAndUpdateProvider: (uri, selfLink, values, password) => {
    dispatch(
      authenticateAndUpdateProvider(
        initialProps.companyKey,
        uri,
        selfLink,
        values,
        password
      )
    )
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Configure)
