const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const Reorderable = require('admin/component/grid/Reorderable')
const User = require('admin/core/User')

const RuleListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  // Prevent loading when store url hasn't been set.
  autoLoadStore: false,
  autoExpandColumn: 'name',

  initComponent() {
    this.module = require('smartrules/Rule')

    if (this.module.canSave()) {
      this.plugins = [new Reorderable()]
    }

    this.record = this.customer || this.vendor

    this.viewConfig = {
      headersDisabled: true,
      getRowClass(record) {
        if (record.get('enabled') !== 'false') {
          return ''
        }
        return 'deprecated-content'
      }
    }

    this.callParent()
  },

  afterRender() {
    this.callParent()
    this.updateToolbar()
  },

  createColumnModel() {
    const columns = [
      {
        id: 'name',
        header: t('Rule Name'),
        menuDisabled: true,
        width: 100,
        sortable: false,
        dataIndex: 'listName'
      }
    ]
    if (User.isTechnicalAdmin()) {
      columns.unshift({
        id: 'id',
        header: 'ID',
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }
    return new Ext.grid.ColumnModel(columns)
  },

  updateToolbar() {
    if (this.ruleSet) {
      this.getTopToolbar().enable()
      // Trigger selection change so buttons can update themselves.
      return this.getSelectionModel().fireEvent(
        'selectionchange',
        this.getSelectionModel()
      )
    }
    return this.getTopToolbar().disable()
  },

  setRuleSet(record) {
    if (this.isDestroyed) {
      return
    }

    if (!record || record.get('id') === undefined) {
      if (this.store != null) {
        this.store.removeAll()
      }
      this.ruleSet = null
      this.updateToolbar()
      return
    }

    if (record === this.ruleSet) {
      return
    }

    this.ruleSet = record
    this.store.url = record.getUrl() + '/rules'
    this.store.load()
    return this.updateToolbar()
  }
})

module.exports = RuleListPanel
