const Ext = require('ext')
const t = require('translate')
const SearchStore = require('admin/data/SearchStore')
const SearchGridPanel = require('admin/component/grid/SearchGridPanel')
const WindowMgr = require('admin/desktop/WindowMgr').default
const SearchField = require('admin/component/form/SearchField')

const Search = Ext.define(null, {
  singleton: true,

  createSearchQueryPanel(searchFn, initialSearch = null) {
    const searchField = new SearchField({
      name: 'searchTextField',
      flex: 1,
      value: initialSearch,
      cls: 'global-search primary',
      triggerClass: 'icon-search',
      listeners: {
        keyup: (field, e) => {
          const userSearch = field.getValue()

          if (userSearch.trim().length > 0 && e.getKey() === Ext.EventObject.ENTER) {
            searchFn(userSearch)
          }
        },
        afterrender: function () {
          searchField.focus()
        }
      },
      onTriggerClick: function () {
        searchFn(this.getValue())
        this.focus()
      }
    })

    const queryPanel = new Ext.Panel({
      layout: {
        type: 'hbox',
        align: 'stretchmax'
      },
      border: false,
      items: [searchField],
      cls: 'search-query-panel'
    })

    return this.createSearchForm(queryPanel)
  },

  createFullSearchPanels(initialSearch = null) {
    const User = require('admin/core/User')

    let filterExpiredCheckbox

    if (User.isVendor()) {
      filterExpiredCheckbox = new Ext.form.Checkbox({
        boxLabel: t('Include Cancelled Customers'),
        checked: false
      })
    }

    const store = new SearchStore({
      groupField: 'typeName',
      module: this,
      sortInfo: {
        field: 'description',
        direction: 'ASC'
      }
    })

    const resultsPanel = new SearchGridPanel({
      store,
      flex: 1,
      tbar: filterExpiredCheckbox ? { items: ['->', filterExpiredCheckbox] } : undefined
    })

    const searchFn = (userSearch) => {
      if (userSearch.trim().length > 0) {
        const checked = filterExpiredCheckbox?.getValue()
        const search = this.prepareFilteredSearchString(userSearch, checked)
        const parentWindow = resultsPanel.findParentWindow()
        this.updateSearchWindow(parentWindow, userSearch)
        this.performSearch(search, resultsPanel)
      }
    }

    const searchQueryPanel = this.createSearchQueryPanel(searchFn, initialSearch)
    const searchTextField = searchQueryPanel.find('name', 'searchTextField')[0]

    if (filterExpiredCheckbox) {
      filterExpiredCheckbox.addListener(
        'check',
        function () {
          searchFn(searchTextField.getValue())
          searchTextField.focus()
        },
        this
      )
    }

    return [searchQueryPanel, resultsPanel]
  },

  runSearch(userSearch) {
    if (userSearch.trim().length > 0) {
      const search = this.prepareFilteredSearchString(userSearch, false)
      const fullSearchPanels = this.createFullSearchPanels(search)
      this.searchWindow = this.createSearchWindow(fullSearchPanels, search)
      this.performSearch(search, fullSearchPanels[1], false)
      // needed for History able to get title for GA
      return this.searchWindow
    }
  },

  createQuickSearchPanel() {
    return this.createSearchQueryPanel(this.runSearch.bind(this))
  },

  getWindow() {
    return this.searchWindow
  },

  createSearchForm(queryPanel) {
    return new Ext.Panel({
      bodyStyle: 'padding:5px',
      items: queryPanel,
      border: false
    })
  },

  prepareFilteredSearchString(userSearch, includeExpired) {
    return userSearch.trim() + (includeExpired ? ' include:expired' : '')
  },

  performSearch(search, resultsPanel) {
    resultsPanel.doSearch({ params: { s: search } })
  },

  createWindowTitle(userSearch) {
    return t('Search Results for: {search}', { search: userSearch })
  },

  createUrl(userSearch) {
    return 'search/' + encodeURIComponent(userSearch)
  },

  updateSearchWindow(window, userSearch) {
    if (!window) return
    window.setTitle(this.createWindowTitle(userSearch))
    window.setUrl(this.createUrl(userSearch))
  },

  createSearchWindow(fullSearchPanels, userSearch) {
    return WindowMgr.create({
      id: `next.search.window${new Date().getTime()}`,
      cls: 'search-window',
      title: this.createWindowTitle(userSearch),
      url: this.createUrl(userSearch),
      width: 640,
      height: 480,
      shim: false,
      animCollapse: false,
      constrainHeader: false,
      items: fullSearchPanels,
      layout: {
        type: 'vbox',
        align: 'stretch'
      }
    })
  }
})

module.exports = Search
