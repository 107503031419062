import { toString } from 'admin/time/Time'
import Format from 'admin/util/Format'
import * as echarts from 'echarts'
import Ext from 'ext'
import t from 'translate'
import _s from 'underscore.string'

const NEWLINE = '<br />'

const COLUMNS = {
  CLEAN: {
    label: 'Clean',
    color: '#6BBA70'
  },
  SPAM: {
    label: 'Spam',
    color: '#F09000'
  },
  THREAT: {
    label: 'Threat',
    color: '#B02B2C'
  },
  ALLOW_POLICY: {
    label: 'Whitelist',
    color: '#AAAAFF'
  },
  DENY_POLICY: {
    label: 'Blacklist',
    color: '#000000'
  }
}

const FONT_FAMILY = 'arial, tahoma, helvetica, sans-serif'

export const formatHourlyDate = (ts, timeZoneName = null) => {
  return toString(ts, timeZoneName, { format: 'HH:mm' })
}

export const formatDailyDate = (ts, timeZoneName = null) => {
  return toString(ts, timeZoneName, { format: 'hour' })
}

export const formatMonthlyDate = (ts, timeZoneName = null) => {
  return toString(ts, timeZoneName, { format: 'month' })
}

export const isHourly = (period, points) => {
  // if the number of data points is two-day worth or less, show hour on x-axis
  return period === 'hourly' && points.length <= 48
}

export const isMonthly = (period) => {
  return period === 'monthly'
}

export const generateXAxis = (series, format, timeZoneName = null) => {
  // data coming from backend lists data from new ones to old ones.
  // That's why we need to reverse it.
  // But reverse actually mutates, so slice it before
  return series
    .slice()
    .reverse()
    .map((s) => format(s.ts, timeZoneName))
}

export const downloadFilename = (direction, unit) => {
  return `${direction}-mail-${unit === 'messageCount' ? 'count' : 'traffic'}`
}

export const formatUnit = (v, unit) => {
  return unit === 'messageCount' ? Format.count(v) : Format.bytes(v, 0)
}

export const formatTooltip = (params, unit) => {
  if (params.length === 0) {
    return ''
  }

  // reference: echarts tooltip.formatter
  // https://ecomfe.github.io/echarts-doc/public/en/option.html#tooltip.formatter
  const rows = params.map(({ marker, seriesName, data }) => {
    return `${marker} ${seriesName}: ${formatUnit(data, unit)}`
  })
  const at = params[0].axisValueLabel
  return [at, ...rows].join(NEWLINE)
}

export const canonicalizeKeys = (data) => {
  const admissibleKeys = Object.keys(COLUMNS).concat(['ALL']) // only select known keys
  return Object.keys(data).reduce((results, key) => {
    // return data.reduce((results, value, key) => {
    if (key && typeof key.valueOf() === 'string') {
      admissibleKeys.forEach((admissibleKey) => {
        if (
          _s.startsWith(key, admissibleKey + '::') || // strip domain suffix
          key === admissibleKey
        ) {
          results[admissibleKey] = data[key]
        }
      })
    }
    return results
  }, {})
}

export const removePaddedData = (data) => {
  return Object.keys(data).reduce((results, key) => {
    if (key === 'ALL' || data[key].series.some((d) => d.messageCount !== 0)) {
      results[key] = data[key]
    }
    return results
  }, {})
}

export const formatData = (types, data, unit) => {
  return types.map((t) => {
    // data coming from backend lists data from new ones to old ones.
    // That's why we need to reverse it.
    // But reverse actually mutates, so slice it before
    const raw = data[t].series.slice().reverse()
    return {
      name: COLUMNS[t].label,
      type: 'line',
      smooth: true,
      data: raw.map((d) => d[unit])
    }
  })
}

export const configure = (rawData, { period, unit, direction, timeZone = null }) => {
  // strip domain suffixes (if any) from key names and filter out unknown keys
  const canonicallyKeyedData = canonicalizeKeys(rawData)

  // remove zero-out padded data which is returned by API to fill the empty classified data
  // e.g. clean and whitelist have some traffic but no threats, blacklist, or spam
  // then, we get all classifications with threats, blacklist, and spam having 0 padded data
  const data = removePaddedData(canonicallyKeyedData)

  const types = Object.keys(data).filter((t) => t !== 'ALL')

  const series = formatData(types, data, unit)

  const hourlyAxisOn = isHourly(period, data.ALL.series)

  let xAxis
  let xAxisLabelInterval

  if (hourlyAxisOn) {
    xAxis = generateXAxis(data.ALL.series, formatHourlyDate, timeZone?.zoneName)
    xAxisLabelInterval = 'auto'
  } else if (isMonthly(period)) {
    xAxis = generateXAxis(data.ALL.series, formatMonthlyDate, timeZone?.zoneName)
    xAxisLabelInterval = 'auto'
  } else {
    xAxis = generateXAxis(data.ALL.series, formatDailyDate, timeZone?.zoneName)
    xAxisLabelInterval = 23
  }

  // return chart configuration
  return {
    textStyle: {
      fontSize: 11,
      fontFamily: FONT_FAMILY
    },
    grid: {
      top: 40,
      left: 80,
      right: 105,
      bottom: hourlyAxisOn ? 70 : 80
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => formatTooltip(params, unit),
      textStyle: {
        fontSize: 11,
        fontFamily: FONT_FAMILY
      }
    },
    toolbox: {
      top: 20,
      right: 30,
      orient: 'bottom',
      itemGap: 25,
      feature: {
        restore: {
          title: t('Reset')
        },
        saveAsImage: {
          title: t('Save as image'),
          name: downloadFilename(direction, unit)
        }
      }
    },
    color: types.map((t) => COLUMNS[t].color),
    legend: {
      data: types.map((t) => COLUMNS[t].label)
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxis,
      axisLabel: {
        fontSize: 10,
        fontFamily: FONT_FAMILY,
        interval: xAxisLabelInterval
      }
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        start: 0,
        end: 100
      }
    ],
    yAxis: {
      axisLabel: {
        formatter: (v) => formatUnit(v, unit),
        fontSize: 10,
        fontFamily: FONT_FAMILY
      }
    },
    series
  }
}

export default Ext.define(null, {
  extend: Ext.BoxComponent,
  autoEl: 'div',
  border: false,
  region: 'center',
  style: {
    width: '600px',
    height: '300px'
  },
  plot: function (options) {
    if (!this.chart) {
      this.chart = echarts.init(this.el.dom)
    }

    if (options) {
      this.chart.setOption(options, true)
    }
  },
  plotMailStats: function (data, params) {
    if (this.chart) {
      this.chart.clear()
    }
    this.plot(configure(data, params))
  },
  listeners: {
    resize: function () {
      if (this.chart) {
        this.chart.resize()
      }
    }
  }
})
