import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import t from 'translate'

import Dialog from 'quarantine/quarantine_sets/views/form/Dialog'
import { updateQuarantineSet, stopConfiguring } from 'quarantine/quarantine_sets/actions'

import {
  configTarget,
  configTargetEtag,
  configTargetName,
  configTargetLink,
  configTargetTargets,
  uri
} from 'quarantine/quarantine_sets/subs'

class ConfigureForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.updateQuarantineSet(
      this.props.uri,
      this.props.configTargetLink,
      values,
      this.props.configTargetEtag
    )
  }

  render() {
    const title = t('Configure {0}', [this.props.configTargetName])

    return (
      <Dialog
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        quarantineSet={this.props.configTarget}
        title={title}
        store={this.props.store}
        submitButtonText={t('Configure')}
        companyKey={this.props.companyKey}
        targets={this.props.configTargetTargets}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  configTarget: configTarget(db, props.companyKey),
  configTargetEtag: configTargetEtag(db, props.companyKey),
  configTargetName: configTargetName(db, props.companyKey),
  configTargetLink: configTargetLink(db, props.companyKey),
  configTargetTargets: configTargetTargets(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopConfiguring(initialProps.companyKey)),

  updateQuarantineSet: (uri, selfLink, values, etag) => {
    dispatch(updateQuarantineSet(initialProps.companyKey, uri, selfLink, values, etag))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(ConfigureForm)
