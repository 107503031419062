const Ext = require('ext')
const t = require('translate')

// Only renders the first n rows until told to render all.
const LazyGridView = Ext.define(null, {
  extend: Ext.grid.GridView,

  lazyRenderTriggerLength: 500,
  lazyRenderLength: 100,

  renderBody() {
    if (this.showAll || this.grid.store.getTotalCount() < this.lazyRenderTriggerLength) {
      return this.callParent()
    }

    const markup = [
      this.renderRows(0, this.lazyRenderLength),
      '<div style="text-align:center;padding:4px;"><a id="showall" href="#">',
      t('Show all ({total})', { total: this.grid.store.getTotalCount() }),
      '</a></div>'
    ].join('')

    // when rendering takes longer than the browser repaint, extjs just continues
    // without any rows and later this lazy grid view will do it again. hence the
    // results from rendering is retrieved by either this.templates.body or by
    // the return value (see SCL-3552)
    return this.templates.body.apply({ rows: markup })
  },

  afterRender() {
    this.callParent()

    this.mainBody.on('click', (e) => {
      if (e.target.id === 'showall') {
        e.preventDefault()
        this.showAll = true
        this.grid.mask(Ext.LoadMask.prototype.msg, 'x-mask-loading')
        // Defer to allow mask to appear.
        setTimeout(() => {
          this.refresh()
          this.grid.unmask()
        })
      }
    })
  }
})

module.exports = LazyGridView
