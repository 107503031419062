const Ext = require('ext')
const t = require('translate')
const logger = require('system/logger').default
const validators = require('validators').default

const EventReleaseNewPanel = Ext.define(null, {
  extend: Ext.form.FormPanel,

  height: 200,
  border: false,
  buttonAlign: 'left',
  cls: 'smx-domain-panel',
  monitorValid: true,
  layout: 'vbox',
  layoutConfig: {
    align: 'stretch'
  },

  initComponent() {
    this.store = this.getStore()
    this.items = this.getItems()
    this.buttons = this.getButtons()
    this.callParent()
  },

  getStore() {
    const recipients = this.record.get('candidateRecipients') || []
    const attributes = ['id', 'selected', 'email', 'original', 'valid']
    return new Ext.data.ArrayStore({
      record: Ext.data.Record.create(attributes),
      fields: attributes,
      data: recipients.map((recipient, index) => [index, false, recipient, true, true])
    })
  },

  getButtons() {
    this.addButton = new Ext.Button({
      text: t('Add Recipient'),
      width: 120,
      handler: () => this.handleAdd()
    })

    this.resetButton = new Ext.Button({
      text: t('Cancel'),
      handler: () => this.handleCancel()
    })

    this.saveButton = new Ext.Button({
      text: t('Release'),
      disabled: true,
      handler: () => this.handleSave()
    })

    return new Ext.Toolbar({
      items: [
        this.addButton,
        new Ext.Spacer({ width: 80 }),
        this.resetButton,
        this.saveButton
      ]
    })
  },

  getItems() {
    this.global = new Ext.form.Checkbox({
      boxLabel: t('Release to All Recipients'),
      checked: false,
      disabled: false,
      listeners: {
        check: () => this.handleGlobal()
      }
    })

    return [
      this.global,
      (this.recipientGrid = new Ext.grid.EditorGridPanel({
        editable: true,
        clicksToEdit: 1,
        forceValidation: true,
        trackMouseOver: true,
        flex: 1,
        autoExpandColumn: 'email',
        cls: 'smx-domain-grid',
        enableHdMenu: false,
        enableColumnHide: false,
        enableColumnMove: false,
        enableColumnResize: true,
        disabled: false,
        store: this.store,
        viewConfig: {
          markDirty: false
        },
        colModel: new Ext.grid.ColumnModel([
          {
            dataIndex: 'selected',
            header: t('Release'),
            id: 'selected',
            falseText: '<div class="x-grid3-check-col"></div>',
            trueText: '<div class="x-grid3-check-col-on"></div>',
            width: 60,
            xtype: 'booleancolumn'
          },
          {
            dataIndex: 'email',
            header: t('Recipient'),
            id: 'email',
            editable: true,
            editor: {
              xtype: 'textfield',
              name: 'email',
              id: 'add-recipient-field',
              validator: (value) => {
                // only disable when invalid. for enabling,
                // leave it to the updateSave() fn below which
                // will be called when selection or global changes.
                if (!this.isValid(value) && !this.isEmpty(value)) {
                  this.saveButton.setDisabled(true)
                }

                // return true since we dont want the entered text to disappear
                return true
              }
            }
          }
        ]),
        listeners: {
          beforeedit: (e) => {
            if (e.record.get('original')) {
              e.cancel = true
            }
            if (e.record.get('email') === t('Add release recipient...')) {
              e.record.set('email', '')
              e.record.set('valid', true)
            }
          },
          validateedit: (e) => {
            if (e.value === e.originalValue) {
              this.setEditing(e)
            }
          },
          afteredit: (e) => {
            this.setValid(e.record)
            this.setSelected()
            if (e.value !== e.originalValue) {
              this.setEditing(e)
            }
          },
          rowclick: (grid, rowIndex) => {
            if (this.rowOutOfBounds(rowIndex)) {
              return
            }

            const record = this.store.getAt(rowIndex)
            if (record.get('original')) {
              record.set('selected', !record.get('selected'))
              this.setSelected()
            }
          },
          cellclick: (grid, rowIndex, columnIndex) => {
            if (this.rowOutOfBounds(rowIndex)) {
              return
            }
            const record = this.store.getAt(rowIndex)

            if (columnIndex !== 1 && !record.get('original') && this.isPostable(record)) {
              record.set('selected', !record.get('selected'))
              this.setSelected()
            }
          }
        }
      })),
      (this.attributeContainer = new Ext.Panel({
        border: false
      }))
    ]
  },

  rowOutOfBounds(rowIndex) {
    return rowIndex < 0 || rowIndex > this.store.getCount() - 1
  },

  isEmpty(email) {
    return email.replace(/\s+/, '') === ''
  },

  isValid(email) {
    return validators.email(email)
  },

  isPostable(record) {
    return record.get('valid') && !this.isEmpty(record.get('email'))
  },

  setValid(record) {
    const email = record.get('email')
    const selectable = this.isValid(email)
    const valid = selectable || this.isEmpty(email)
    record.set('valid', valid)
    record.set('selected', selectable)
  },

  setSelected() {
    const selected = []
    const candidates = []
    this.store.each((record) => {
      const email = record.get('email')
      if (this.isPostable(record)) {
        candidates.push(email)
        if (record.get('selected')) {
          return selected.push(email)
        }
      }
    })
    this.record.set('selectedRecipients', selected)
    this.record.set('candidateRecipients', candidates)
    this.updateSave()
    this.updateAdd()
  },

  setEditing(e) {
    // store may or may not be set at this stage so operate on the event record.
    const editor = Ext.get('add-recipient-field').parent()
    if (e.field === 'email' && !(this.isValid(e.value) || this.isEmpty(e.value))) {
      editor.addClass('x-form-invalid')
      return this.recipientGrid.startEditing(e.row, e.column)
    }
    editor.removeClass('x-form-invalid')
  },

  areAllRecipientsValid() {
    return this.store.getRange().reduce((valid, record) => {
      return valid && this.isValid(record.get('email'))
    }, true)
  },

  isGlobal() {
    return this.record.get('global')
  },

  updateSave() {
    let disabled = true

    if (this.areAllRecipientsValid()) {
      const selectedCount = this.record.get('selectedRecipients').length
      disabled = !this.isGlobal() && selectedCount < 1
    }

    this.saveButton.setDisabled(disabled)
  },

  updateAdd() {
    this.addButton.setDisabled(
      this.isGlobal() ||
        this.store.getCount() > this.record.get('candidateRecipients').length
    )
  },

  handleAdd() {
    this.addButton.setDisabled(true)
    const RecordClass = this.store.record
    const record = new RecordClass({
      id: this.store.getCount(),
      email: t('Add release recipient...'),
      selected: false,
      original: false,
      valid: false
    })
    this.store.add([record])
  },

  handleSave() {
    if (this.saving) return
    this.saving = true
    this.saveButton.setDisabled(true)

    if (this.isGlobal()) {
      this.record.set('savedRecipients', this.record.get('candidateRecipients'))
    } else {
      this.record.set('savedRecipients', this.record.get('selectedRecipients'))
    }

    Ext.MessageBox.wait(t('Saving...'))
    return this.record.save(null, {
      url: this.releaseLink,
      success: () => {
        this.store.addSorted(this.record)
        this.close()
      },
      error(record, response, options, err) {
        logger.error(err)
      },
      complete: () => {
        Ext.MessageBox.hide()
        this.saving = false
      }
    })
  },

  handleCancel() {
    this.close()
  },

  close() {
    const parentWindow = this.findParentWindow()
    parentWindow && parentWindow.close()
  },

  handleGlobal() {
    this.record.set('global', this.global.getValue())
    this.updateGrid()
    this.updateSave()
    this.updateAdd()
  },

  updateGrid() {
    this.recipientGrid.setDisabled(this.global.getValue())
  }
})

module.exports = EventReleaseNewPanel
