import Ext from 'ext'
import _s from 'underscore.string'
import detectNewline from 'detect-newline'

export const getCoordinatesByPosition = function (value, position) {
  if (!value && value !== '') return undefined

  const eol = detectNewline(value)
  const eolLength = eol?.length ? eol.length : 0
  const lines = value.split(eol)

  let i = 0
  let beginning = 0
  let end

  while (i < lines.length) {
    end = beginning + lines[i].length

    if (beginning <= position && position <= end) {
      return [i + 1, position - beginning + 1]
    }

    beginning = end + eolLength
    i++
  }
}

export const isOverflown = function (value, lineMaxLength) {
  if (lineMaxLength > -1) {
    const eol = detectNewline(value)
    const lines = value.split(eol)
    return lines.some((l) => l.length > lineMaxLength)
  }
}

export const computeWidth = function (length, classNames) {
  const testTextArea = document.createElement('textarea')
  let shortenBy

  if (Ext.isFirefox || Ext.isGecko) {
    shortenBy = 2
  } else if (Ext.isWindows) {
    shortenBy = 1
  } else {
    // don't shorten, i.E. on Chrome already fine
    shortenBy = 0
  }

  const realChars = _s.repeat('x', length - shortenBy)

  testTextArea.className = classNames.join(' ')
  testTextArea.cols = realChars.length
  testTextArea.style.position = 'absolute'
  testTextArea.style.whiteSpace = 'nowrap'
  testTextArea.style.left = '-1000em'
  testTextArea.style.top = '-1000em'
  testTextArea.style.fontFamily = 'monospace'
  testTextArea.value = realChars

  document.body.appendChild(testTextArea)

  const computedWidth = testTextArea.getBoundingClientRect().width

  document.body.removeChild(testTextArea)

  return computedWidth
}
