import { Cell, Column, Table } from 'fixed-data-table-2'
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import _s from 'underscore.string'
import classNames from 'classnames'

const DEFAULT_ROW_HEIGHT = 26

const renderHeaderCell = (rowData, name, columnProps) => {
  const props = {
    onClick: columnProps.onHeaderClick
  }

  let header = columnProps.title || _s.capitalize(name)

  const renderHeader = columnProps.renderHeader

  if (renderHeader) {
    header = renderHeader(header, name, rowData)
  }

  return <Cell {...props}>{header}</Cell>
}

const renderCell = (rowData, name, columnProps) => {
  const props = {
    className: columnProps.rowCellClassName || name
  }

  let data = rowData[name]

  const renderColumnCell = columnProps.renderCell

  if (renderColumnCell) {
    data = renderColumnCell(data, name, rowData)
  }

  const onCellClick = columnProps.onCellClick

  if (onCellClick) {
    props.onClick = (e) => onCellClick(e, rowData)
  }

  return <Cell {...props}>{data}</Cell>
}

class PaginableTable extends Component {
  static propTypes = {
    onRowClick: PropTypes.func.isRequired,
    setColumnWidth: PropTypes.func.isRequired,

    width: PropTypes.number.isRequired,

    rows: PropTypes.array,
    columns: PropTypes.object.isRequired,

    scrollToIndex: PropTypes.number,
    rowClassName: PropTypes.string,
    className: PropTypes.string,
    headerHeight: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired
  }

  static defaultProps = {
    headerHeight: DEFAULT_ROW_HEIGHT,
    rowHeight: DEFAULT_ROW_HEIGHT
  }

  getRow = (index) => this.props.rows[index]

  handleRowClick = (e, index) => this.props.onRowClick(index)

  columnWidth = (columnProps) => columnProps.width || -1

  handleColumnResizeEndCallback = (width, columnKey) => {
    this.props.setColumnWidth(width, columnKey)
  }

  flexGrow = (columnProps) => {
    // when a width is already set, do not use flex grow
    if (Number.isInteger(columnProps.width)) return 0

    return columnProps.flexGrow
  }

  getRowClassName = (index) => {
    const row = this.getRow(index)

    if (!row) return

    return classNames(this.props.rowClassName, { selected: row.selected })
  }

  renderColumn = (columnProps, name) => {
    return (
      <Column
        key={name}
        columnKey={name}
        cellClassName='truncate-cell'
        align={columnProps.align}
        isResizable={columnProps.isResizable}
        width={this.columnWidth(columnProps)}
        flexGrow={this.flexGrow(columnProps)}
        header={({ rowIndex }) => {
          return renderHeaderCell(this.getRow(rowIndex), name, columnProps)
        }}
        cell={({ rowIndex }) => renderCell(this.getRow(rowIndex), name, columnProps)}
      />
    )
  }

  render() {
    const columnKeys = Object.keys(this.props.columns)
    const rows = columnKeys.map((key) => {
      return this.renderColumn(this.props.columns[key], key)
    })
    return (
      <Table
        headerHeight={this.props.headerHeight}
        maxHeight={this.props.maxHeight}
        onRowClick={this.handleRowClick}
        rowClassNameGetter={this.getRowClassName}
        rowHeight={this.props.rowHeight}
        rowsCount={this.props.rows.length}
        scrollToRow={this.props.scrollToIndex}
        width={this.props.width}
        isColumnResizing={false}
        onColumnResizeEndCallback={this.handleColumnResizeEndCallback}
        className={this.props.className}
      >
        {rows}
        {/* {this.props.columns.map(this.renderColumn).valueSeq().toArray()} */}
      </Table>
    )
  }
}

export default PaginableTable
