const Ext = require('ext')

const GridSummary = Ext.define(null, {
  extend: Ext.util.Observable,

  statics: {
    /*
    All Calculation methods are called on each Record in the Store
    with the following 5 parameters:
    v - cell value
    record - reference to the current Record
    colName - column name (i.e. the ColumnModel's dataIndex)
    data - the cumulative data for the current column + summaryType up to the current Record
    rowIdx - current row index
    */
    calculations: {
      sum(v, record, colName, data) {
        return data[colName] + Ext.num(v, 0)
      },

      count(v, record, colName, data, rowIdx) {
        return rowIdx + 1
      },

      max(v, record, colName, data) {
        return Math.max(Ext.num(v, 0), data[colName])
      },

      min(v, record, colName, data) {
        return Math.min(Ext.num(v, 0), data[colName])
      },

      average(v, record, colName, data, rowIdx) {
        const t = data[colName] + Ext.num(v, 0)
        const count = record.store.getCount()
        if (rowIdx === count - 1) {
          return t / count
        }
        return t
      }
    }
  },

  constructor(config) {
    return Ext.apply(this, config)
  },

  init(grid) {
    this.grid = grid
    this.cm = grid.getColumnModel()
    this.view = grid.getView()

    const v = this.view
    // Override GridView's onLayout() method
    v.onLayout = this.onLayout
    v.afterMethod('render', this.refreshSummary, this)
    v.afterMethod('refresh', this.refreshSummary, this)
    v.afterMethod('syncScroll', this.syncSummaryScroll, this)
    v.afterMethod('onColumnWidthUpdated', this.doWidth, this)
    v.afterMethod('onAllColumnWidthsUpdated', this.doAllWidths, this)
    v.afterMethod('onColumnHiddenUpdated', this.doHidden, this)
    v.afterMethod('onUpdate', this.refreshSummary, this)
    v.afterMethod('onRemove', this.refreshSummary, this)

    // Update summary row on store's add / remove / clear events
    grid.store.on('add', this.refreshSummary, this)
    grid.store.on('remove', this.refreshSummary, this)
    grid.store.on('clear', this.refreshSummary, this)
    if (!this.rowTpl) {
      this.rowTpl = new Ext.Template(
        '<div class="x-grid3-summary-row x-grid3-gridsummary-row-offset">',
        '<table class="x-grid3-summary-table" border="0" cellspacing="0" cellpadding="0" style="{tstyle}">',
        '<tbody><tr>{cells}</tr></tbody>',
        '</table>',
        '</div>'
      )
      this.rowTpl.disableFormats = true
    }
    this.rowTpl.compile()
    if (!this.cellTpl) {
      this.cellTpl = new Ext.Template(
        '<td class="x-grid3-col x-grid3-cell x-grid3-td-{id} {css}" style="{style}">',
        '<div class="x-grid3-cell-inner x-grid3-col-{id}" unselectable="on" {attr}>{value}</div>',
        '</td>'
      )
      this.cellTpl.disableFormats = true
    }
    return this.cellTpl.compile()
  },

  calculate(rs, cm) {
    const data = {}
    const cfg = cm.config

    // Loop through all columns in ColumnModel
    for (const cf of Array.from(cfg)) {
      // Get column dataIndex
      const cname = cf.dataIndex
      // Initialise grid summary row data for the current column being worked on
      data[cname] = 0

      if (cf.summaryType) {
        for (const r of Array.from(rs)) {
          // Get a single Record
          data[cname] = GridSummary.calculations[cf.summaryType](
            r.get(cname),
            r,
            cname,
            data,
            rs.indexOf(r)
          )
        }
      }
    }

    return data
  },

  // Note: this method is scoped to the GridView
  onLayout(vw, vh) {
    // Handles grid's height:'auto' config
    if (Ext.type(vh) !== 'number') {
      return
    }

    if (!this.grid.getGridEl().hasClass('x-grid-hide-gridsummary')) {
      // Readjust gridview's height only if grid summary row is visible
      return this.scroller.setHeight(vh - this.summary.getHeight())
    }
  },

  syncSummaryScroll() {
    const mb = this.view.scroller.dom
    this.view.summaryWrap.dom.scrollLeft = mb.scrollLeft
    // Second time for IE (1/2 time first fails, other browsers ignore)
    this.view.summaryWrap.dom.scrollLeft = mb.scrollLeft
  },

  doWidth(col, w, tw) {
    const s = this.view.summary.dom
    s.firstChild.style.width = tw
    s.firstChild.rows[0].childNodes[col].style.width = w
  },

  doAllWidths(ws, tw) {
    const s = this.view.summary.dom
    s.firstChild.style.width = tw
    const cells = s.firstChild.rows[0].childNodes
    return Array.from(cells).map((cell) => (cell.style.width = ws[cells.indexOf(cell)]))
  },

  doHidden(col, hidden, tw) {
    const s = this.view.summary.dom
    const display = hidden ? 'none' : ''
    s.firstChild.style.width = tw
    s.firstChild.rows[0].childNodes[col].style.display = display
  },

  renderSummary(o, cs, cm) {
    if (!cs) {
      cs = this.view.getColumnData()
    }
    const cfg = cm.config
    const buf = []

    for (let i = 0; i < cs.length; i++) {
      const c = cs[i]
      const cf = cfg[i]

      const p = {
        id: c.id,
        style: c.style,
        css: (() => {
          switch (i) {
            case 0:
              return 'x-grid3-cell-first '
            case cs.length - 1:
              return 'x-grid3-cell-last '
            default:
              return ''
          }
        })()
      }

      if (cf.summaryType || cf.summaryRenderer) {
        p.value = (cf.summaryRenderer || c.renderer)(o.data[c.name], p, o)
      } else {
        p.value = ''
      }

      if (!p.value) {
        p.value = ' '
      }
      buf.push(this.cellTpl.apply(p))
    }

    return this.rowTpl.apply({
      tstyle: 'width:' + this.view.getTotalWidth() + ';',
      cells: buf.join('')
    })
  },

  refreshSummary() {
    if (this.view.cm) {
      const g = this.grid
      const { store } = g
      const cs = this.view.getColumnData()
      const { cm } = this
      const rs = store.getRange()
      const data = this.calculate(rs, cm)
      const buf = this.renderSummary({ data }, cs, cm)
      if (!this.view.summaryWrap) {
        this.view.summaryWrap = Ext.DomHelper.insertBefore(
          this.view.scroller,
          {
            tag: 'div',
            cls: 'x-grid3-gridsummary-row-inner'
          },
          true
        )
      } else {
        this.view.summary.remove()
      }
      this.view.summary = this.view.summaryWrap.insertHtml('afterbegin', buf, true)
    }
  },

  // Set visible to true to display summary row
  toggleSummary(visible) {
    const el = this.grid.getGridEl()
    if (el) {
      if (visible == null) {
        visible = el.hasClass('x-grid-hide-gridsummary')
      }
      el[visible ? 'removeClass' : 'addClass']('x-grid-hide-gridsummary')
      // Readjust gridview height
      this.view.layout()
    }
  },

  getSummaryNode() {
    return this.view.summary
  }
})

module.exports = GridSummary
