import Analytics from 'analytics'
import getVersion from 'system/getVersion'
import isDeveloping from 'system/isDeveloping'
import isStaging from 'system/isStaging'
import isIntegration from 'system/isIntegration'

import googleAnalyticsPlugin from '@analytics/google-analytics'
import googleAnalyticsV3Plugin from '@analytics/google-analytics-v3'

import { originalSourcePlugin } from '@analytics/original-source-plugin'

function getTrackingId() {
  if (isDeveloping()) return 'UA-194899110-2'
  if (isStaging()) return 'UA-194899110-3'
  if (isIntegration()) return 'UA-194899110-3'

  return 'UA-194899110-4' // prod!
}

const gaPlugin = googleAnalyticsV3Plugin({
  trackingId: getTrackingId(),
  debug: isDeveloping() // prints a lot more noise
})

//@see https://getanalytics.io/plugins/google-analytics/#using-ga3-and-ga4-together
function getMeasurementId() {
  if (isDeveloping()) return 'G-YMHC8VJBX2'
  if (isStaging()) return 'G-VW5LRJYXME'
  if (isIntegration()) return 'G-VW5LRJYXME'

  return 'G-WEWJQD826F' // prod!
}

const ga4Plugin = googleAnalyticsPlugin({
  trackingId: getMeasurementId(),
  debug: isDeveloping()
})

// @see https://getanalytics.io/api/#configuration
const analytics = Analytics({
  app: 'SMX3 Legacy UI',
  version: getVersion(), // API supports it but GA never displays it. meh.
  debug: isDeveloping(), // adds useful information in our redux devtool but overwrites anything else
  resetCustomDimensionsOnPage: true, // recommended for SPAs
  plugins: [
    ga4Plugin,
    gaPlugin,
    // tracks the initial traffic source of visitors to our site
    originalSourcePlugin()
  ]
})

// /* Track a custom event */
// analytics.track('playedVideo', {
//   category: 'Videos',
//   label: 'Fall Campaign',
//   value: 42
// })

export default analytics
