import React, { Component } from 'react'
import PropTypes from 'prop-types'

import compareStrings from 'strings/compare/lexical'

import Field from 'mail/details/views/Field'
import MailHighlight from 'mail/details/views/Highlight'

class Panel extends Component {
  static propTypes = {
    resultsQuery: PropTypes.string,
    preview: PropTypes.object.isRequired,
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    enableHighlighting: PropTypes.bool
  }

  renderEmailAddress(emailAddress) {
    if (emailAddress.name) {
      return emailAddress.name + ' <' + emailAddress.address + '>'
    }

    return emailAddress.address
  }

  renderEmailAddresses(emailAddresses) {
    return emailAddresses && emailAddresses.map(this.renderEmailAddress).join(', ')
  }

  renderEmailField(label, key) {
    return (
      <Field label={label} className={key}>
        <MailHighlight
          keyword={key}
          resultsQuery={this.props.resultsQuery}
          availableKeywords={this.props.availableKeywords}
          enabled={this.props.enableHighlighting}
        >
          {this.renderEmailAddresses(this.props.preview[key])}
        </MailHighlight>
      </Field>
    )
  }

  renderCc() {
    return (
      <Field label='CC'>
        <MailHighlight
          keyword='cc'
          resultsQuery={this.props.resultsQuery}
          availableKeywords={this.props.availableKeywords}
          enabled={this.props.enableHighlighting}
        >
          {this.renderEmailAddresses(this.props.preview.cc)}
        </MailHighlight>
      </Field>
    )
  }

  shouldDisplayReplyTo() {
    const replyToSerialized = this.renderEmailAddresses(this.props.preview['reply-to'])
    const fromSerialized = this.renderEmailAddresses(this.props.preview.from)

    return compareStrings(replyToSerialized, fromSerialized) !== 0
  }

  render() {
    return (
      <div className='mail-preview-header'>
        {this.props.preview.from && this.renderEmailField('From', 'from')}

        <Field label='Subject' className='subject'>
          <MailHighlight
            keyword='subject'
            resultsQuery={this.props.resultsQuery}
            availableKeywords={this.props.availableKeywords}
            enabled={this.props.enableHighlighting}
          >
            {this.props.preview.subject}
          </MailHighlight>
        </Field>

        {this.props.preview.to && this.renderEmailField('To', 'to')}
        {this.props.preview.cc && this.renderEmailField('CC', 'cc')}

        {
          // on a side note bcc is only avail for outbound messages
          this.props.preview.bcc && this.renderEmailField('BCC', 'bcc')
        }

        {this.shouldDisplayReplyTo() && this.renderEmailField('Reply-To', 'reply-to')}
      </div>
    )
  }
}

export default Panel
