const Ext = require('ext')
const t = require('translate')
const DOMPurify = require('dompurify')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const formatMoney = require('money/format')
const ComboBox = require('admin/component/form/ComboBox')
const SearchField = require('admin/component/form/SearchField')
const File = require('api/file')
const logger = require('system/logger').default
const Api = require('api').default
const LinkHeader = require('http-link-header')
const Time = require('admin/time/Time')

const InvoiceListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'reference',
  cls: 'invoice-list-panel',
  enableHdMenu: false,

  initComponent() {
    this.module = require('admin/business/InvoiceResource')

    // This is created from an open/detail handler so store needs to be set manually.
    this.store = this.record.getInvoiceStore()

    this.selModel = new Ext.grid.CheckboxSelectionModel({
      noHtmlEncode: true
    })

    this.cm = new Ext.grid.ColumnModel({
      defaults: {
        falseText: t('No'),
        sortable: false,
        trueText: t('Yes')
      },
      columns: [
        this.selModel,
        {
          dataIndex: 'party.groupName',
          header: t('Reference'),
          id: 'reference',
          noHtmlEncode: true,
          renderer: (v, p, record) => {
            const value = DOMPurify.sanitize(v)
            const ref = DOMPurify.sanitize(record.get('invoiceReference'))
            const classification = DOMPurify.sanitize(
              record.get('billingClassificationName')
            )
            if (this.isOverview()) {
              return `<strong>${value}</strong><br/>${ref} - ${classification}`
            }
            return ref
          },
          width: 90
        },
        this.isOverview() && {
          dataIndex: 'invoiceClassificationName',
          header: t('Classification'),
          id: 'invoiceClassificationName',
          width: 80
        },
        {
          dataIndex: 'status',
          header: t('Status'),
          id: 'status',
          width: 100
        },
        {
          dataIndex: 'invoiceDate',
          header: t('Date'),
          id: 'invoiceDate',
          renderer: Time.toDateString,
          width: 70
        },
        {
          dataIndex: 'amount',
          header: t('Amount'),
          id: 'amount',
          renderer(value, metadata, record) {
            if (!value && value !== 0) return ''
            return formatMoney(record.get('currencyUom'), value)
          },
          width: 80
        }
      ].filter(Boolean)
    })

    this.bbar = new Ext.Toolbar({
      enableOverflow: true,
      height: 36
    })

    this.viewConfig = { rowHeight: 32 }

    this.classificationStore = new Ext.data.ArrayStore({
      fields: ['name'],
      idProperty: 'name',
      sortInfo: {
        field: 'name',
        direction: 'ASC'
      }
    })

    const { store } = this
    store.on('load', this.updateClassifications, this)
    this.on(
      'destroy',
      function () {
        store.un('load', this.updateClassifications, this)
      },
      this
    )

    this.callParent()

    this.getSelectionModel().on('selectionchange', this.updateButtons, this)
  },

  handleRowDblClick(grid, rowIndex) {
    const record = grid.getStore().getAt(rowIndex)
    if (record.canOpen()) {
      this.downloadPdf(record)
    }
  },

  isOverview() {
    return !this.record.getType()
  },

  updateClassifications() {
    const Type = this.classificationStore.recordType
    this.classificationStore.removeAll()
    this.classificationStore.add(
      new Type({
        name: 'All'
      })
    )
    this.store.getRange().forEach(function (record) {
      const classification = record.get('invoiceClassificationName')
      if (this.classificationStore.find('name', classification) < 0) {
        return this.classificationStore.add(
          new Type({
            name: classification
          })
        )
      }
    }, this)
    return this.filterInvoices()
  },

  getContextMenuItems(record) {
    const items = this.callParent(arguments)

    items.push({
      text: t('Download PDF'),
      iconCls: 'export-pdf',
      handler: () => this.downloadPdf(record)
    })

    return items
  },

  async downloadPdf(record) {
    const name = record.get('displayName')
    return File.download(record.getUrl() + '/' + name, name, 'pdf')
  },

  getToolbarItems() {
    return [
      this.getCreateButton(),
      this.getOpenButton(),
      this.getRemoveButton(),
      new Ext.Button({
        text: t('Download PDF'),
        disabled: true,
        shouldEnable: () => {
          const selected = this.getSelectionModel().getSelected()
          return selected?.canOpen()
        },
        handler: () => {
          const selected = this.getSelectionModel().getSelected()
          if (selected) {
            return this.downloadPdf(selected)
          }
        }
      }),
      this.isOverview() && [
        new Ext.Spacer({ width: 8 }),
        new Ext.form.Label({ text: t('Filter') }),
        new Ext.Spacer({ width: 8 }),
        (this.invoiceType = new ComboBox({
          displayField: 'name',
          editable: false,
          forceSelection: true,
          listeners: {
            select: () => {
              return this.filterInvoices()
            }
          },
          mode: 'local',
          store: this.classificationStore,
          triggerAction: 'all',
          value: 'All',
          valueField: 'name',
          width: 120
        })),
        (this.search = new SearchField({
          listeners: {
            keyup: () => {
              return this.filterInvoices()
            }
          },
          width: 180
        }))
      ],
      '->',
      this.getRefreshButton()
    ]
  },

  startLoading() {
    this.getBottomToolbar().addClass('x-toolbar-load-hint')
  },

  stopLoading() {
    this.getBottomToolbar().removeClass('x-toolbar-load-hint')
    delete this.cancelToken
  },

  removeDynamicButtons() {
    this.getBottomToolbar().removeAll(true)
    this.startLoading()
  },

  async performStatusChange(uri, records) {
    const maskEl = () => this.getEl().mask(Ext.LoadMask.prototype.msg, 'x-mask-loading')
    const unmaskEl = () => this.getEl().unmask()

    maskEl()

    const ids = []

    this.removeDynamicButtons()

    records.forEach(function (record) {
      ids.push(record.get('id'))
    })

    try {
      await Api.post(
        uri,
        { id: ids },
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          timeout: 0
        }
      )
    } catch (exc) {
      logger.warn(exc)
    } finally {
      this.getSelectionModel().clearSelections()
      this.store.reload()
      unmaskEl()
    }
  },

  addBottomButton(header) {
    this.getBottomToolbar().add({
      text: header.title,
      dynamicButton: true,
      id: header.rel, // ensures there won't be duplicate buttons
      handler: async () => {
        Ext.MessageBox.confirm('', t('Are you sure?'), (result) => {
          const records = this.getSelectionModel().getSelections()

          if (result === 'yes') {
            this.performStatusChange(header.uri, records)
          }
        })
      }
    })
  },

  async updateButtons() {
    this.removeDynamicButtons()

    const selections = this.getSelectionModel().getSelections()

    if (!selections.length) {
      return this.stopLoading()
    }

    const ids = selections.map((selection) => selection.get('id'))

    if (this.cancelToken) {
      this.cancelToken.cancel()
      delete this.cancelToken
    }

    try {
      this.startLoading()

      this.cancelToken = Api.CancelToken.source()

      const relation = this.store.links.rel('smx3:invoice/actions')[0]
      const uri = relation.uri

      const response = await Api.post(
        uri,
        { id: ids },
        {
          cancelToken: this.cancelToken.token,
          timeout: 0, // since SCL-3579
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )

      const linkHeader = response.headers.link

      if (linkHeader) {
        const links = LinkHeader.parse(linkHeader)
        links.refs.forEach((header) => this.addBottomButton(header))
      }

      this.stopLoading()
    } catch (err) {
      // when cancelled, do nothing and leave it to the
      // next response to process further.
      if (Api.isCancel(err)) return

      this.stopLoading()
      logger.warn(err)
    } finally {
      this.getBottomToolbar().doLayout()
    }
  },

  filterInvoices() {
    if (!this.rendered || !this.search) return
    const v = this.search.getValue().toLowerCase()
    const type = this.invoiceType.getValue()

    const filters = []
    if (type !== 'All') {
      filters.push({
        property: 'invoiceClassificationName',
        value: type,
        anyMatch: false,
        caseInsensitive: true
      })
    }
    if (v === '0.00') {
      filters.push({
        property: 'amount',
        value: '0'
      })
    } else {
      const res = v.split(' ').map((tok) => new RegExp(tok.replace(/[.\\]/g, '$1'), 'i'))
      filters.push({
        fn(record) {
          const money = formatMoney(record.get('currencyUom'), record.get('amount'))
          return (
            new RegExp(` ${v}`, 'i').test(money) ||
            res.every((re) => re.test(record.get('invoiceReference'))) ||
            res.every((re) => re.test(record.get('party.groupName')))
          )
        },
        scope: this
      })
    }
    return this.store.filter(filters)
  }
})

module.exports = InvoiceListPanel
