import React from 'react'

import classNames from 'classnames'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'
import t from 'translate'
import titleize from 'underscore.string/titleize'

import List from './List'
import Type from './Type'

class MemberPanel extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    membership: PropTypes.object.isRequired,
    defaultExpanded: PropTypes.bool,
    collapsible: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
  }

  state = { title: '', counts: '' }

  componentDidMount() {
    this.setState({
      title: this.getTitle(),
      counts: this.getCounts()
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newCounts = this.getCounts(nextProps.membership)

    if (newCounts !== this.state.counts) {
      this.setState({
        title: this.getTitle(nextProps.membership),
        counts: newCounts
      })
    }
  }

  onMemberCheck = (name, enabled) => {
    this.props.membership.setEnabledByName(this.props.type, name, enabled)
    this.refreshMemberTypeHeader()
  }

  pluralizeTitle = (title, count) => {
    // pluralize wont work when there are two words, hence extract the last one
    title = title.replace(/\w+$/, (lastWord) => pluralize(lastWord))

    return t(titleize(title), count)
  }

  getTitle = (membership = this.props.membership) => {
    const typeTitle = membership.getTitleForType(this.props.type)
    const count = membership.countMembersOfType(this.props.type)
    const pluralizedTitle = this.pluralizeTitle(typeTitle, count)
    return pluralizedTitle.replace(/\sIps/, ' IPs')
  }

  getCounts = (membership = this.props.membership) => {
    let notEnabledCount
    let enabledCount = (notEnabledCount = 0)

    membership.getMembersByType(this.props.type).forEach(function (member) {
      if (member.enabled) {
        return enabledCount++
      }
      return notEnabledCount++
    })

    const total = enabledCount + notEnabledCount

    return t('{0} of {1} selected', [enabledCount, total])
  }

  onSelectAll = (e) => {
    e.stopPropagation()

    this.props.membership.setEnabledByType(this.props.type, e.target.checked)

    this.refreshMemberTypeHeader()
  }

  onPanelSelect = (eventKey, e) => {
    // do not process the collapsing of panels any further if user has clicked on
    // the selectAll checkbox
    if (e.target.name === 'selectAll') {
      return (e.selected = false)
    }
  }

  refreshMemberTypeHeader = () => {
    this.setState({
      title: this.getTitle(),
      counts: this.getCounts()
    })

    if (typeof this.props.onChange === 'function') {
      this.props.onChange()
    }
  }

  isAllSelected = () => {
    return this.props.membership.everyMemberEnabledOfType(this.props.type)
  }

  ignoreOnChange = () => {}
  // do nothing, just here to satisfy React which requires onChange to be defined
  // when the checked property is used

  render() {
    const members = this.props.membership.getSortedMembersByType(this.props.type)

    return (
      <Panel
        key={this.props.type}
        bsStyle='primary'
        className={classNames(
          this.props.type,
          'collapsible',
          this.props.disabled ? 'disabled' : undefined
        )}
        defaultExpanded={this.props.defaultExpanded}
        onSelect={this.onPanelSelect}
      >
        <Panel.Heading>
          <Panel.Title toggle={this.props.collapsible}>
            <input
              checked={this.isAllSelected()}
              type='checkbox'
              name='selectAll'
              className='selectAll'
              onClick={this.onSelectAll}
              onChange={this.ignoreOnChange}
              disabled={this.props.disabled}
            />
            <Type title={this.state.title} counts={this.state.counts} />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <List
              members={members}
              type={this.props.type}
              disabled={this.props.disabled}
              onMemberCheck={this.onMemberCheck}
            />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    )
  }
}

export default MemberPanel
