import Keycloak from 'keycloak-js'
import isAzure from 'system/isAzure'
import isDeveloping from 'system/isDeveloping'
import isSmxemail from 'system/isSmxemail'
import isStaging from 'system/isStaging'
import isIntegration from 'system/isIntegration'

const WITH_KEYCLOAK_KEY = 'smxkc'

export function authenticateWithKeycloak() {
  // currently only au/nz uses keycloak
  return isSmxemail()
}

export function withKeycloak() {
  return window.localStorage.getItem(WITH_KEYCLOAK_KEY) || false
}

export function setWithKeycloak() {
  window.localStorage.setItem(WITH_KEYCLOAK_KEY, true)
}

export function removeWithKeycloak() {
  window.localStorage.removeItem(WITH_KEYCLOAK_KEY)
}

// Also, we do not build environment separate builds which we would need to add this.
// Leaving for containerisation and automated builds.
function getKeycloakUrl() {
  if (window.KEYCLOAK_URL && window.KEYCLOAK_URL !== 'auto') {
    return window.KEYCLOAK_URL
  }

  if (isAzure()) {
    if (isDeveloping()) {
      return 'https://aziam.dev.smxemail.com/auth'
    }

    if (isStaging()) {
      return 'https://aziam.staging.smxemail.com/auth'
    }

    return 'https://aziam.smxemail.com/auth'
  }

  if (isDeveloping()) {
    return 'https://iam.dev.smxemail.com/auth/'
  }

  if (isStaging()) {
    return 'https://iam.staging.smxemail.com/auth/'
  }

  if (isIntegration()) {
    return 'https://iam.test.smxemail.com/auth/'
  }

  return 'https://iam.smxemail.com/auth/'
}

let keycloak

if (authenticateWithKeycloak()) {
  const keycloakUrl = getKeycloakUrl()

  if (keycloakUrl) {
    keycloak = new Keycloak({
      url: keycloakUrl,
      realm: 'smx',
      clientId: 'smx'
    })

    keycloak.createLogoutUrl = (function (original) {
      return function () {
        let url = original()

        // undocumented trick which prevents from brutally logging out from
        // all microsoft apps (since SCL-3492)
        // note: oidc is an alias for the microsoft identity provider
        // configured under keycloak admin
        url += '&initiating_idp=' + encodeURIComponent('oidc')

        return url
      }
    })(keycloak.createLogoutUrl)
  }
}

export function wantsKeycloakLogin() {
  return Boolean(keycloak && !keycloak.authenticated)
}

export function requiresSSOCheck() {
  return Boolean(withKeycloak() && wantsKeycloakLogin() && !keycloak.clientId)
}

export function isKeycloakAuthenticated() {
  return Boolean(keycloak?.authenticated)
}

export function getKeycloakToken() {
  return keycloak?.token
}

export function getKeycloakLoginUrl() {
  return window.location.origin
}

export async function updateKeycloakToken() {
  return keycloak?.updateToken()
}

export function keycloakLogout() {
  keycloak.logout({ redirectUri: getKeycloakLoginUrl() })
}

// can export undefined when not on *.smxemail.com, see isSmxemail() fn above
export default keycloak
