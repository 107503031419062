import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import t from 'translate'

import Dialog from './Dialog'
import { createProvider, stopCreating } from '../../actions'

import { uri } from '../../subs'

class CreateForm extends Component {
  static propTypes = {
    allowPrivate: PropTypes.bool,
    allowMappingPolicy: PropTypes.bool,
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.createProvider(this.props.uri, values)
  }

  render() {
    return (
      <Dialog
        title={t('Create new shared service provider')}
        store={this.props.store}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        submitButtonText={t('Create')}
        allowPrivate={this.props.allowPrivate}
        allowMappingPolicy={this.props.allowMappingPolicy}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopCreating(initialProps.companyKey)),
  createProvider: (uri, values) =>
    dispatch(createProvider(initialProps.companyKey, uri, values))
})

export default connect(mapDbToProps, mapActionsToProps)(CreateForm)
