import Api from 'api'
import EventEmitter from 'events'
import Ext from 'ext'
import logger from 'system/logger'
import pluralize from 'pluralize'
import t from 'translate'

const RECONNECT_TIMEOUT = 10 // that's in seconds

let offline = false

const emitter = new EventEmitter()

const loadingMask = new Ext.LoadMask(Ext.getBody(), {
  msgCls: 'x-mask-loading',
  msg: 'Loading …'
})

export function whenBackOnline(listener) {
  emitter.once('online', listener)
}

const progressText = (reconnectingInSeconds) => {
  return t('Reconnecting in {number} {unit}', {
    number: reconnectingInSeconds,
    unit: pluralize('second', reconnectingInSeconds)
  })
}

const mask = (request) => {
  const mask = request?.mask || request?.request?.arg?.mask

  if (mask) {
    mask()
  } else {
    loadingMask.show()
  }
}

const unmask = (request) => {
  const unmask = request?.unmask || request?.request?.arg?.unmask

  if (unmask) {
    unmask()
  }

  loadingMask.hide()

  if (request) delete request.mask
}

const enableForm = (request) => {
  const enableForm =
    request.enableForm || (request.request && request.request.arg.enableForm)

  if (enableForm) enableForm()
}

const reconnect = async (request) => {
  try {
    mask(request)

    await Api.get('/api/system/translations', { timeout: 12000 })

    unmask(request)
    enableForm(request)

    offline = false

    emitter.emit('online')
  } catch (err) {
    unmask(request)
    logger.warn(err)
    goOffline(request) // beware recursion
  }
}

export function isOffline() {
  return offline
}

export function goOffline(request) {
  let reconnectingInSeconds = RECONNECT_TIMEOUT

  offline = true

  unmask(request)

  Ext.MessageBox.hide() // hide any existing ones

  const messageBox = Ext.MessageBox.progress(
    t('Service unavailable'),
    t(
      'There was a problem connecting to the Email Admin Portal. This may be due to a connection issue or scheduled maintenance.'
    ),
    progressText(reconnectingInSeconds)
  )

  const intervalId = setInterval(() => {
    reconnectingInSeconds--

    messageBox.updateProgress(
      (RECONNECT_TIMEOUT - reconnectingInSeconds) / RECONNECT_TIMEOUT,
      progressText(reconnectingInSeconds)
    )

    if (reconnectingInSeconds === 0) {
      clearInterval(intervalId)
      messageBox.hide()
      reconnect(request)
    }
  }, 1000)
}
