import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import t from 'translate'

import Dialog from 'mailboxes/views/form/Dialog'
import { create, stopCreating } from 'mailboxes/actions'

import { uri } from 'mailboxes/subs'

class AddForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.create(this.props.uri, values)
  }

  render() {
    return (
      <Dialog
        title={t('Create New Mailbox')}
        onSubmit={this.handleSubmit}
        onCancel={this.props.stopCreating}
        submitButtonText={t('Create')}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  stopCreating: () => dispatch(stopCreating(companyKey)),
  create: (uri, values) => dispatch(create(companyKey, uri, values))
})

export default connect(mapDbToProps, mapActionsToProps)(AddForm)
