import User from 'admin/core/User'
import { getRecordByUrl } from 'admin/desktop/openRecord'
import ArchivingTabPanel from 'admin/view/ArchivingTabPanel'
import ContinuityTabPanel from 'admin/view/ContinuityTabPanel'
import GroupTabPanel from 'admin/view/GroupTabPanel'
import InboundScrubbingTabPanel from 'admin/view/InboundScrubbingTabPanel'
import MailHostingTabPanel from 'admin/view/MailHostingTabPanel'
import OutboundScrubbingTabPanel from 'admin/view/OutboundScrubbingTabPanel'
import QuarantineTabPanel from 'admin/view/QuarantineTabPanel'
import SharedServicesTabPanel from 'admin/view/SharedServicesTabPanel'
import Api from 'api'
import Ext from 'ext'
import {
  CUSTOMER_DETAIL_REL,
  DISTRIBUTOR_DETAIL_REL,
  MAIL_EVENT_TAGS_REL,
  MAIL_TARGETS_REL,
  NOTIFICATION_REL,
  QUARANTINE_SEARCH_REL,
  QUARANTINE_SETS_REL,
  RESELLER_DETAIL_REL,
  SHARED_SERVICE_PROVIDER_REL,
  SMART_RULES_REL
} from 'product/resources'
import * as sprites from 'sprites'
import store from 'store'
import {
  isArchivingProvisioningCapabilityFlagEnabled,
  isCapableOfArchiving
} from 'system/capability/subs'
import logger from 'system/logger'
import _s from 'underscore.string'

const CompanyGroupPanel = Ext.define(null, {
  extend: GroupTabPanel,

  cls: 'company-panel',

  constructor(cfg = {}) {
    this.record = cfg.record
    this.distributorRecord = this.loadRel(DISTRIBUTOR_DETAIL_REL, 'DISTRIBUTOR')
    this.resellerRecord = this.loadRel(RESELLER_DETAIL_REL, 'RESELLER')
    this.customerRecord = this.loadRel(CUSTOMER_DETAIL_REL, 'CUSTOMER')

    this.items = this.getItems()

    this.superclass().constructor.apply(this, arguments)

    if (this.record === this.distributorRecord) this.setActiveTab(this.distributorTab)
    if (this.record === this.resellerRecord) this.setActiveTab(this.resellerTab)
    if (this.record === this.customerRecord) this.setActiveTab(this.customerTab)

    if (this.customerRecord?.synced) {
      this.customerRecordSynced()
    } else {
      this.customerRecord?.on('sync', this.customerRecordSynced, this)
    }

    this.resellerRecord?.on('sync', this.updateItems, this)
    this.distributorRecord?.on('sync', this.updateItems, this)

    this.on('destroy', function () {
      this.customerRecord?.un('sync', this.customerRecordSynced, this)
      this.resellerRecord?.un('sync', this.updateItems, this)
      this.distributorRecord?.un('sync', this.updateItems, this)
      this.distributorRecord?.un('change', this.refreshRecords, this)
      this.resellerRecord?.un('change', this.refreshRecords, this)
      this.customerRecord?.un('change', this.refreshRecords, this)
    })

    if (module.hot) {
      module.hot.accept('admin/view/SharedServicesTabPanel.js', this.reloadSharedServices)
      module.hot.accept('admin/view/QuarantineTabPanel.js', this.reloadQuarantine)
      module.hot.accept('admin/view/ContinuityTabPanel.js', this.reloadContinuity)
      module.hot.accept('admin/view/ArchivingTabPanel.js', this.reloadArchiving)
    }
  },

  customerRecordSynced() {
    const mailEventTagsUri = this.customerRecord.getUriByRel(MAIL_EVENT_TAGS_REL)

    if (!mailEventTagsUri) {
      this.eventTagsProcessed = true
      this.updateItems()
      return
    }

    Api.get(mailEventTagsUri)
      .then((response) => {
        this.customerRecord.setEventTags(response.data)
        this.eventTagsProcessed = true
        this.updateItems()
      })
      .catch(function (err) {
        logger.error(err)
      })
  },

  async reloadQuarantine() {
    const NewQuarantineTabPanel = await import('admin/view/QuarantineTabPanel')
    this.quarantineTab = this.replaceTab(
      this.quarantineTab,
      this.buildQuarantineTab(true, NewQuarantineTabPanel.default)
    )
  },

  async reloadSharedServices() {
    const NewSharedServicesTabPanel = await import('admin/view/SharedServicesTabPanel')
    this.sharedServicesTab = this.replaceTab(
      this.sharedServicesTab,
      this.buildSharedServicesTab(true, NewSharedServicesTabPanel.default)
    )
  },

  async reloadContinuity() {
    const NewContinuityTabPanel = await import('admin/view/ContinuityTabPanel')
    this.continuityTab = this.replaceTab(
      this.continuityTab,
      this.buildContinuityTab(NewContinuityTabPanel.default)
    )
  },

  async reloadArchiving() {
    const NewArchivingTabPanel = await import('admin/view/ArchivingTabPanel')
    this.archivingTab = this.replaceTab(
      this.archivingTab,
      this.buildArchivingTab(true, NewArchivingTabPanel.default)
    )
  },

  // Helper to identify and load records for each of the three company types.
  loadRel(rel, type) {
    const link = this.record.getUriByRel(rel)

    if (link) {
      let record = getRecordByUrl(link)

      if (!record) return

      if (record.module === this.record.module) {
        // We already have the appropriate record
        record = this.record
        record.on('change', this.refreshRecords, this)
      } else {
        // We create a new record for this company type
        record.fetch({
          success: () => {
            return record.on('change', this.refreshRecords, this)
          }
        })
      }

      return record
      // Handle case where record doesn't have linkheaders
    } else if (this.record.getType() === type) {
      return this.record
    }
  },

  // Changing one record should prompt refresh of others
  refreshRecords(changedRecord) {
    // Condition prevents infinite loop
    if (changedRecord.isBeingRefreshedByRel) return

    return [this.distributorRecord, this.resellerRecord, this.customerRecord].forEach(
      function (record) {
        if (record && record !== changedRecord && !record.isBeingRefreshedByRel) {
          record.isBeingRefreshedByRel = true
          record.on('sync', () => delete record.isBeingRefreshedByRel, this, {
            single: true
          })

          return record.fetch()
        }
      }
    )
  },

  // isNotificationCompany and isAdminNotificationCompany are identical for now but
  // that's intentional for future adjustments
  isAdminNotificationCompany() {
    const companyKey = this.record.getCompanyKey()
    return (
      companyKey.includes('2000074') || // SMX Limited
      companyKey.includes('2016443') || // SMX Test
      companyKey.includes('2000351') // Roy test
    )
  },

  // 29/7/22 https://youtrack.smxemail.com/youtrack/issue/SCL-3783
  // future developer feel free to remove this commented code
  // isNotificationCompany() {
  //   const companyKey = this.record.getCompanyKey()
  //   return (
  //     companyKey.includes('2000074') ||
  //     companyKey.includes('2016443') ||
  //     companyKey.includes('2000351')
  //   )
  // },

  showNotificationTab() {
    // Don't forget integrations/tenancy/view/Tab showSmx365Application
    return (
      this.customerRecord.isCustomer() && this.customerRecord.hasProductKey('SMX_365')
    )
  },

  showAdminSectionInNotificationTab() {
    return this.isAdminNotificationCompany()
  },

  buildQuarantineTab(hotReloading = false, Class = QuarantineTabPanel) {
    // only the customer resource has these links
    // but don't forget, a distributor is a customer too, see SCL-2919
    const searchUri = this.customerRecord.getUriByRel(QUARANTINE_SEARCH_REL)
    const quarantineSetsUri = this.customerRecord.getUriByRel(QUARANTINE_SETS_REL)
    const mailTargetsUri = this.customerRecord.getUriByRel(MAIL_TARGETS_REL)
    const notificationUri = this.showNotificationTab()
      ? this.customerRecord.getUriByRel(NOTIFICATION_REL)
      : undefined

    // no need to test for mailTargetsUri since it is always present
    // regardless of the user having quarantine
    if (searchUri && quarantineSetsUri) {
      const quarantine = this.findProduct('QUARANTINE')
      const showAdminSectionInNotification = this.showAdminSectionInNotificationTab()

      return new Class({
        companyKey: this.customerRecord.getCompanyKey(),
        searchUri,
        mailTargetsUri,
        quarantineSetsUri,
        notificationUri,
        showAdminSectionInNotification,
        hotReloading,
        expired: !quarantine.current
      })
    }
  },

  buildArchivingTab(hotReloading = false, Class = ArchivingTabPanel) {
    const archiving = this.getArchiving()

    if (archiving) {
      return new Class({
        companyKey: this.record.getCompanyKey(),
        archivingUrl: archiving.url,
        showConfig: this.canArchive() && this.canArchiveProvision(),
        hotReloading,
        expired: !archiving.current
      })
    }
  },

  buildContinuityTab(Class = ContinuityTabPanel) {
    const archiving = this.getArchiving()
    const continuity = this.findProduct('CONTINUITY')

    if (archiving && continuity) {
      return new Class({
        companyKey: this.record.getCompanyKey(),
        // get it from archiving product instead since there is
        // no api endpoint for the community product
        archivingUrl: archiving.url,
        expired: !continuity.current
      })
    }
  },

  buildSharedServicesTab(hotReloading = false, Class = SharedServicesTabPanel) {
    let sspLink = this.record.getUriByRel(SHARED_SERVICE_PROVIDER_REL)

    // since SCL-3457, scan again from reseller resource if customer is a reseller too.
    if (!sspLink && this.resellerRecord) {
      sspLink = this.resellerRecord.getUriByRel(SHARED_SERVICE_PROVIDER_REL)
    }

    if (!sspLink) return

    return new Class({
      companyKey: this.record.getCompanyKey(),
      uri: sspLink,
      hotReloading
    })
  },

  canArchive() {
    return isCapableOfArchiving(store.getState())
  },

  canArchiveProvision() {
    return isArchivingProvisioningCapabilityFlagEnabled(store.getState())
  },

  getArchiving() {
    if (User.hasPermission('ARCHIVING', 'GET') && this.canArchive()) {
      return this.findProduct('ARCHIVING')
    }
  },

  getItems() {
    if (this.distributorRecord && !this.distributorTab) {
      const DistributorTabPanel = require('admin/view/DistributorTabPanel').default
      this.distributorTab = new DistributorTabPanel({
        record: this.distributorRecord
      })
    }

    if (this.resellerRecord && !this.resellerTab) {
      const ResellerTabPanel = require('admin/view/ResellerTabPanel').default
      this.resellerTab = new ResellerTabPanel({
        record: this.resellerRecord
      })
    }

    if (this.customerRecord) {
      const hosting = this.findProduct('EMAIL_HOSTING_PRODUCTS')
      const outbound = this.customerRecord.hasOutboundScrubbing()

      if (!this.customerTab) {
        const CustomerTabPanel = require('admin/view/CustomerTabPanel').default
        this.customerTab = new CustomerTabPanel({
          record: this.customerRecord
        })
      }

      // ensures those tabs won't be built until customer record
      // is fully synced with event tags
      if (this.eventTagsProcessed) {
        const inbound = this.customerRecord.hasInboundScrubbing()

        if (inbound && !this.inboundScrubbingTab) {
          this.inboundScrubbingTab = new InboundScrubbingTabPanel({
            customer: this.customerRecord,
            product: inbound
          })
        }

        if (outbound && !this.outboundScrubbingTab) {
          this.outboundScrubbingTab = new OutboundScrubbingTabPanel({
            customer: this.customerRecord,
            product: outbound
          })
        }

        if (hosting && !this.hostingTab) {
          this.hostingTab = new MailHostingTabPanel({
            customer: this.customerRecord,
            product: hosting
          })
        }
      }

      const smartRulesLink = this.customerRecord.getLinkByRel(SMART_RULES_REL)

      if (smartRulesLink && !this.smartrulesTab) {
        const SmartRulesTabPanel = require('smartrules/TabPanel')
        this.smartrulesTab = new SmartRulesTabPanel({
          customer: this.customerRecord
        })
      }

      const archiving = this.getArchiving()

      if (archiving) {
        // hack since SCL-3187 (backend still has to sort out how to provision this)
        const continuity = this.findProduct('CONTINUITY')

        if (continuity) {
          if (!this.continuityTab) {
            this.continuityTab = this.buildContinuityTab()
          }
        } else if (!this.archivingTab) {
          this.archivingTab = this.buildArchivingTab()
        }
      }

      if (!this.quarantineTab) {
        this.quarantineTab = this.buildQuarantineTab()
      }
    }

    if (!this.sharedServicesTab) {
      this.sharedServicesTab = this.buildSharedServicesTab()
    }

    return [
      this.distributorTab,
      this.resellerTab,
      this.customerTab,
      this.inboundScrubbingTab,
      this.outboundScrubbingTab,
      this.hostingTab,
      this.smartrulesTab,
      this.sharedServicesTab,
      this.quarantineTab,
      this.archivingTab,
      this.continuityTab
    ].filter(Boolean)
  },

  findProduct(name) {
    // just delegate ...
    return this.customerRecord.findProduct(name)
  },

  // overwriting default slightly to enable svg icons in tabs
  itemTpl: new Ext.XTemplate(
    // eslint-disable-next-line no-multi-str
    '<li class="{cls}" id="{id}"> \
      <a class="x-tab-right" href="#"> \
        <em class="x-tab-left"> \
          <span class="x-tab-strip-inner"> \
            <tpl if="svg"> \
              {svg} \
            </tpl> \
            <span class="x-tab-strip-text {iconCls}">{text}</span> \
          </span> \
        </em> \
      </a> \
    </li>'
  ),

  getTemplateArgs(item) {
    let svg

    const result = Ext.TabPanel.prototype.getTemplateArgs.call(this, item)

    if (_s.include(item.tabCls, 'quarantine-tab')) {
      svg = sprites.toHtml('quarantine')
    } else if (_s.include(item.tabCls, 'continuity-tab')) {
      svg = sprites.toHtml('continuity')
    }

    return Ext.apply(result, { svg })
  },

  destroy: function () {
    if (module.hot) {
      // dirty hack to remove them since webpack doesn't offer an API to remove these listener
      delete module.hot._acceptedDependencies[
        './src/modules/admin/view/QuarantineTabPanel.js'
      ]
      delete module.hot._acceptedDependencies[
        './src/modules/admin/view/ContinuityTabPanel.js'
      ]
      delete module.hot._acceptedDependencies[
        './src/modules/admin/view/ArchivingTabPanel.js'
      ]
    }

    this.superclass().destroy.call(this)
  }
})

export default CompanyGroupPanel
