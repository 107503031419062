import AbstractCompanyTabPanel from 'admin/view/AbstractCompanyTabPanel'
import Ext from 'ext'
import ResellerDetailPanel from 'admin/view/ResellerDetailPanel'
import ResellerResource from 'admin/business/ResellerResource'
import t from 'translate'

const ResellerTabPanel = Ext.define(null, {
  extend: AbstractCompanyTabPanel,

  module: ResellerResource,
  title: t('Reseller'),
  tabCls: 'reseller-tab',
  cls: 'reseller-panel',

  buildDetailPanel() {
    return new ResellerDetailPanel({ record: this.record })
  }
})

export default ResellerTabPanel
