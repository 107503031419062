const Ext = require('ext')
const t = require('translate')
const TemplateList = require('smartrules/TemplateList')
const Alert = require('admin/util/Alert').default
const TemplateEditor = require('smartrules/TemplateEditor')

const TemplateManagementPanel = Ext.define(null, {
  extend: Ext.Panel,

  constructor(cfg = {}) {
    this.module = require('smartrules/TemplateManagement')
    this.customer = cfg.customer

    const templates = new TemplateList({
      module: this.module,
      region: 'west',
      store: this.customer.getTemplateStore(),
      vetoRowSelect() {
        const dirty = this.module.canSave() && templateEditor.isDirty()

        // since SCL-2402
        // prevents from the alert below to appear when being deleted anyway
        const selectedTemplate = this.getSelectionModel().getSelected()
        const beingDeleted =
          selectedTemplate != null ? selectedTemplate.deleted : undefined

        if (dirty && !beingDeleted) {
          Alert.alert(t('Changes were made'), t('Either save or reset to continue.'))
        }

        return dirty
      },
      width: 195
    })

    const templateEditor = new TemplateEditor({
      module: this.module,
      region: 'center',
      bwrapCfg: {
        cls: 'left-border'
      },
      templates
    })

    cfg = Ext.applyIf(cfg, {
      title: this.module.name,
      layout: 'border',
      items: [templates, templateEditor]
    })

    this.callParent([cfg])

    return this.on('beforeload', () => !templateEditor.isDirty())
  }
})

module.exports = TemplateManagementPanel
