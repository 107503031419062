const Ext = require('ext')
const t = require('translate')
const WindowMgr = require('admin/desktop/WindowMgr').default
const EventReleaseNewPanel = require('admin/view/EventReleaseNewPanel')
const EventDetailStore = require('admin/data/EventDetailStore')
const EventReleaseRecord = require('admin/data/EventReleaseRecord')
const EventReleaseStore = require('admin/data/EventReleaseStore')

const MAX_QUARANTINE_DAYS = 30
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24

// todo most of this should live in the time module
const isExpired = function (receivedDate) {
  const quarantinedDays = (Date.now() - receivedDate) / ONE_DAY_IN_MS
  return quarantinedDays > MAX_QUARANTINE_DAYS
}

const EventQuarantinePanel = Ext.define(null, {
  extend: Ext.Panel,

  constructor(cfg) {
    this.record = cfg.record
    this.customer = cfg.customer
    this.timeZoneName = cfg.timeZoneName
    this.releaseLink = cfg.releaseLink
    this.items = this.getItems()

    this.addClass('quarantine')

    this.callParent()
  },

  getItems() {
    let item
    const items = []

    if (isExpired(this.record.get('receivedDate'))) {
      item = new Ext.Toolbar({
        items: [`<b>${t('Quarantined (Expired)')}</b>`]
      })
    } else {
      const toolbarItems = [`<b>${t('Quarantined')}</b>`]

      if (this.releaseLink != null) {
        toolbarItems.push('->')
        toolbarItems.push(
          new Ext.Action({
            text: t('Release...'),
            handler: () => this.showEventReleasePanel()
          })
        )
      }

      item = new Ext.Toolbar({ items: toolbarItems })
    }

    items.unshift(item)

    return items
  },

  closeEventReleasePanel(win) {
    return win.on('close', () => {
      return this.record.fetch({
        success: () => {
          this.removeAll()
          this.add(this.getItems())
          this.doLayout()
        }
      })
    })
  },

  getQuarantineId(details) {
    return details.QUARANTINE_DETAIL[0].quarantineId
  },

  showEventReleasePanel() {
    const quarantineId = this.getQuarantineId(this.record.get('details'))
    const windowId = 'eventRelease-' + quarantineId
    if (!WindowMgr.select(windowId)) {
      const releaseData = this.getReleaseData(quarantineId)
      const releaseRecord = new EventReleaseRecord(releaseData)
      const releaseStore = new EventReleaseStore({ url: releaseData.url })
      const releasePanel = new EventReleaseNewPanel({
        record: releaseRecord,
        store: releaseStore,
        releaseLink: this.releaseLink,
        timeZoneName: this.timeZoneName
      })
      return WindowMgr.create({
        id: windowId,
        title: `${t('Release From Quarantine')}: ${this.record.get('subject')}`,
        taskbar: releaseRecord.getName(),
        url: releaseRecord.getUrl(),
        width: 400,
        items: [releasePanel],
        callback: this.closeEventReleasePanel.bind(this)
      })
    }
  },

  getReleaseData(quarantineId) {
    const releaseRecipients = this.getReleaseRecipients()
    return {
      url: 'api/quarantine/' + quarantineId + '/release',
      customerId: this.customer.get('id'),
      sid: this.record.get('sid'),
      originalRecipients: releaseRecipients,
      candidateRecipients: releaseRecipients,
      selectedRecipients: []
    }
  },

  getReleaseRecipients() {
    return this.record
      .get('recipients')
      .filter(
        (recipient) =>
          this.record.eventStatus(recipient) === EventDetailStore.getQuarantineStatus()
      )
      .map((recipient) => recipient.recipient)
  }
})

module.exports = EventQuarantinePanel
