import React, { Component } from 'react'
import {
  renameTarget,
  renameTargetLink,
  renameTargetName,
  uri
} from 'integration/tenancy/subs'
import { renameTenancy, stopRenaming } from 'integration/tenancy/actions'

import Dialog from 'integration/tenancy/views/form/Dialog'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class RenameForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.renameTenancy(this.props.uri, this.props.renameTargetLink, {
      name: values.name
    })
  }

  render() {
    const title = t('Rename "{0}"', [this.props.renameTargetName])

    return (
      <Dialog
        renaming
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        tenancy={this.props.renameTarget}
        title={title}
        store={this.props.store}
        submitButtonText={t('Rename')}
        companyKey={this.props.companyKey}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  renameTarget: renameTarget(db, props.companyKey),
  renameTargetName: renameTargetName(db, props.companyKey),
  renameTargetLink: renameTargetLink(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  onCancel: () => dispatch(stopRenaming(companyKey)),

  renameTenancy: (uri, selfLink, values) => {
    dispatch(renameTenancy(companyKey, uri, selfLink, values))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(RenameForm)
