const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const SignatureRecord = require('admin/data/SignatureRecord')

const SignatureStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'footers',
  sortInfo: {
    field: 'priority',
    direction: 'ASC'
  },
  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      { field: 'policySetType', direction: 'ASC' },
      { field: 'priority', direction: 'ASC' }
    ]
  },

  record: SignatureRecord,

  module() {
    return require('admin/business/SignatureManagementResource')
  }
})

module.exports = SignatureStore
