const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const validators = require('validators').default

const MailRelayHostDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'mailrelayhost-detail-panel',

  module() {
    return require('admin/business/MailRelayHostResource')
  },

  getFormPanel(cfg) {
    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems() {
    return [
      {
        fieldLabel: t('Mail Relay Host'),
        name: 'name',
        allowBlank: false,
        validator: validators.domainOrIp.withMsg(
          t('You must enter a valid DNS Name or IP Address')
        ),
        xtype: 'textfield'
      }
    ]
  }
})

module.exports = MailRelayHostDetailPanel
