const Ext = require('ext')
const store = require('store').default
const t = require('translate')
const words = require('en/default.po')
const User = require('admin/core/User')

const ChangePasswordWindow = require('admin/component/ChangePasswordWindow').default
const History = require('admin/util/History').default
const Tooltip = require('admin/util/Tooltip')
const CountryResource = require('admin/business/CountryResource')
const CurrencyResource = require('admin/business/CurrencyResource')

const Desktop = require('admin/desktop/Desktop')
const openUri = require('admin/desktop/openUri').default
const Login = require('login/Login')
const UnavailableLogin = require('login/Unavailable').default
const token = require('api/token')
const Api = require('api').default
const { initExtAjax } = require('api/extjs')
const { startInactivityTimeout } = require('api/inactivity')

const getQueryParam = require('url/getQueryParam').default
const stripQuery = require('url/stripQuery').default
const logger = require('system/logger').default
const Sentry = require('system/sentry')
const initCapabilities = require('system/capability/actions').init
const capabilitiesLoaded = require('system/capability/subs').loaded
const isDeveloping = require('system/isDeveloping').default
const isStaging = require('system/isStaging').default
const backendUnavailable = require('api/backendUnavailable').default
const whenBackOnline = require('api/offline').whenBackOnline
const analytics = require('system/ga').default

const keycloak = require('system/keycloak').default
const {
  isKeycloakAuthenticated,
  withKeycloak,
  setWithKeycloak,
  wantsKeycloakLogin,
  requiresSSOCheck,
  removeWithKeycloak,
  keycloakLogout
} = require('system/keycloak')

const processGeneralError = function (err, cb) {
  logger.warn(err, { disableSentry: true })
  Sentry.captureException(err)

  let errorText = 'Please try again - if this persists, please contact Technical Support'

  // Authentication errors first show themselves on the fetch to userProfile.
  // We haven't standardised on a message format so this hack surfaces the most common
  // and confusing errors
  if (err.response?.data) {
    // https://youtrack.smxemail.com/issue/SCL-3477 converting AD users no tenantID
    if (err.response.data.match(/^No active, or accepted tenants found for/)) {
      errorText =
        'No configured Tenancy ID was found. Contact your SMX Portal Administrator'
    }
    // https://youtrack.smxemail.com/issue/SCL-3487 creating AD users no tenantID
    if (err.response.data.match(/^Unable to create user/)) {
      errorText =
        'Unable to create user as no configured Tenancy ID was found. Contact your SMX Portal Administrator'
    }
  }

  Ext.MessageBox.alert(t('An error has occurred'), t(errorText), function () {
    if (!cb) return
    cb()
  })
}

const App = Ext.define(null, {
  extend: Ext.util.Observable,
  singleton: true,

  init() {
    t.setTranslations(words)

    initExtAjax()

    this.startApp()
  },

  // This is designed to be run multiple times until all requirements are met.
  startApp() {
    // Wrap boot in a try/catch so user will know if something went wrong instead of seeing blank page.
    try {
      if (this.loginUnavailable) {
        return this.showLoginUnavailable()
      }

      if (requiresSSOCheck()) {
        return this.keycloakCheckSSO()
      }

      // This authenticates a user who wants to use keycloak.
      // There is a keycloak clientID so we've already checked the SSO status
      // of the user. If they were authenticated they were reloaded without
      // any URL params so will bypass this step.
      // If they were not authenticated and have already logged in with keycloak
      // in the past we know that via the smxkc_* key value in localstorage and
      // they have been redirected to this page to relogin.
      if (wantsKeycloakLogin()) {
        return this.keycloakLogin()
      }

      // any keycloak user is here without query params
      const passwordreset = getQueryParam('passwordreset')

      // Handle password reset urls
      if (passwordreset) {
        return this.resetPassword(passwordreset)
      }

      // any keycloak user is here without query params
      const forgotpassword = getQueryParam('forgotpassword')

      // Handle forgotten password urls
      if (forgotpassword) {
        Ext.util.Cookies.set('forgotpassword', 'true', new Date().add(Date.DAY, 1))
        return this.reloadAppWithoutQueryString()
      }

      // any keycloak user is authenticated by this stage so bypasses this step
      if (!(token.get() || isKeycloakAuthenticated())) {
        // Login also handles forgotten password UI.
        return this.showLogin()
      }

      // Load user profile, it is the first authenticated request
      if (!this.profile) {
        return this.getUserProfile()
      }

      if (!isDeveloping()) {
        Sentry.setUser(this.profile.user.userLogin.userName)
      }

      if (this.profile.passwordChangeRequired) {
        if (isKeycloakAuthenticated()) {
          // TODO add error description on logout why
          return keycloakLogout()
        }

        return this.showChangePasswordWindow()
      }

      // Load system capabilities once for the whole session
      if (!capabilitiesLoaded(store.getState())) {
        return store.dispatch(initCapabilities()).then((success) => {
          if (success) {
            this.startApp()
          } // prevents endless loops
        })
      }

      // Load countries once for the whole session
      if (!CountryResource.isLoaded()) {
        return CountryResource.loadCountries(this.startApp.bind(this))
      }

      // Load currencies once for the whole session
      if (!CurrencyResource.isLoaded()) {
        return CurrencyResource.loadCurrencies(this.startApp.bind(this))
      }

      // We now have an authenticated user and can load the rest of the app.
      User.processUserProfile(this.profile)

      startInactivityTimeout()

      this.registerDirtyCloseCheck()

      Tooltip.init()
      Desktop.init()

      // state param is from azure and tells us which customer to open (return to)
      // see getSmxIdentityGatewayUrl() fn under integration
      const hash = getQueryParam('state') || window.location.hash

      // at this stage we are safe to remove any query strings we don't need (SCL-3452)
      this.removeQueryString()

      History.init(openUri, hash)

      // must be executed after history init
      // only works when userID is enabled inside GA3
      // see https://support.google.com/analytics/answer/3123666?hl=en&ref_topic=3123660#zippy=%2Cin-this-article
      analytics.identify(
        this.profile.user.userLogin.userName,
        {
          firstName: this.profile.user.firstName,
          lastName: this.profile.user.lastName,
          displayName: this.profile.user.displayName
        },
        () => {
          // trigger dummy event to immediately send user id
          // because we never know when the next event will happen.
          analytics.track('userIdentified')
        }
      )

      // now can free this up
      this.profile = null
    } catch (e) {
      processGeneralError(e, () => {
        window.location.reload()
      })
    }
  },

  async keycloakCheckSSO() {
    token.clear()
    try {
      const timeout = isDeveloping() || isStaging() ? 10000 : 5000
      const authenticated = await new Promise((resolve, reject) => {
        keycloak
          .init({
            onLoad: 'check-sso',
            enableLogging: isDeveloping(),
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
          })
          .then((authenticated) => {
            resolve(authenticated)
          })
          .catch((err) => {
            reject(err)
          })
        setTimeout(reject, timeout)
      })
      if (authenticated) {
        setWithKeycloak()
        this.reloadAppWithoutQueryString({ keepState: true })
      } else if (withKeycloak()) {
        if (wantsKeycloakLogin()) {
          return this.startApp()
        }
        window.location = keycloak.createLoginUrl()
      } else {
        this.startApp()
      }
    } catch (err) {
      this.loginUnavailable = true
      this.startApp()
    }
  },
  async keycloakLogin() {
    token.clear()
    if (withKeycloak()) {
      try {
        await new Promise((resolve, reject) => {
          if (keycloak.didInitialize) {
            // Check if Keycloak is already initialized
            keycloak.login({ prompt: 'login' }).then(resolve).catch(reject)
          } else {
            keycloak
              .init({
                onLoad: 'login-required',
                enableLogging: isDeveloping()
              })
              .then(resolve)
              .catch(reject)
          }
          setTimeout(() => reject(new Error('Login Timeout')), 5000)
        })
        this.reloadAppWithoutQueryString({ keepState: true })
      } catch (err) {
        console.error(err)
        this.loginUnavailable = true
        this.startApp()
      }
    } else {
      setWithKeycloak()
      this.startApp()
    }
  },

  resetPassword(newToken) {
    const sendingMask = new Ext.LoadMask(Ext.getBody(), {
      msgCls: 'x-mask-loading',
      msg: 'Sending …'
    })

    sendingMask.show()

    Ext.Ajax.request({
      url: '/api/system/passwordreset',
      method: 'POST',
      mask: () => sendingMask.show(),
      unmask: () => sendingMask.hide(),
      params: {
        token: newToken
      },
      callback: (opts, success) => {
        Ext.util.Cookies.set(
          'passwordreset',
          success ? 'success' : 'error',
          new Date().add(Date.DAY, 1)
        )
        sendingMask.hide()
        this.reloadAppWithoutQueryString()
      }
    })
  },

  showLogin() {
    removeWithKeycloak()

    if (this.login) return

    this.login = new Login({
      onLogin: () => {
        this.login?.destroy()
        this.login = null
        this.startApp()
      }
    })
  },

  showLoginUnavailable() {
    this.unavailableLoginWindow = new UnavailableLogin()
  },

  async getUserProfile() {
    const loadingMask = new Ext.LoadMask(Ext.getBody(), {
      msgCls: 'x-mask-loading',
      msg: 'Loading …'
    })

    let loadingMaskTimeoutID

    try {
      // little trick to make it show only when taking more than one second
      // like that the loading spinner won't flicker when UI is already loading fast
      loadingMaskTimeoutID = setTimeout(() => {
        loadingMask.show()
      }, 1000)

      // only here we have a much shorter timeout of 12 seconds than the usual
      // default of 60 seconds so that users wont have to wait that too long
      // whenever one of our servers are down. this APi call is usually
      // responding fast.
      const response = await Api.get('/api/userprofile', { timeout: 12000 })
      const smxAuthenticatedUser = response.headers['smx-authenticated-user']

      loadingMask.hide()
      clearTimeout(loadingMaskTimeoutID)

      if (smxAuthenticatedUser) {
        // this header is provided when a user is onboarded in smx3 following a browser
        // refresh, reload or redirect (as userprofile is loaded first)
        // this could happen for two reasons
        // 1) a federated user is being converted and SMX3 has deleted the user in keycloak
        // 2) a delegated user was deleted by an admin in smx3 and keycloak and while
        // still logged in refreshed their browser.
        // To ensure that keycloak and azure both are retouched the user is put back through the login process

        // for usability, tell user what's happening (since SCL-3491)
        Ext.MessageBox.show({
          width: 200,
          height: 130,
          closable: false,
          title: t('Welcome to the SMX Portal'),
          buttons: Ext.Msg.OK,
          fn: () => this.keycloakCheckSSO(),
          msg: t('Click OK to complete secure login')
        })
      } else {
        this.profile = response.data
        this.startApp()
      }
    } catch (err) {
      this.profile = null

      loadingMask.hide()
      clearTimeout(loadingMaskTimeoutID)

      if (err.request && backendUnavailable(err.request.status)) {
        whenBackOnline(() => {
          // now this will do the rest, do the login again
          this.startApp()
        })
      } else {
        processGeneralError(err, () => {
          token.clear()

          if (isKeycloakAuthenticated()) {
            return keycloakLogout()
          }

          // now this will do the rest, do the login again
          this.startApp()
        })
      }
    }
  },

  showChangePasswordWindow() {
    new ChangePasswordWindow({
      mandatory: true,
      uri: `/api${this.profile.user.defaultURI}/password`,
      prompt: t('For your security, we need you to change your password'),
      successCallback: () => {
        this.profile.passwordChangeRequired = false
        this.startApp()
      }
    }).show()
  },

  registerDirtyCloseCheck() {
    // Warn before closing window when in dirty state.
    window.onbeforeunload = function (e = window.event) {
      const dirtyWindows = Ext.query('.smx-dirty')
      const message = t('You are about to lose changes that have not been saved.')

      if (dirtyWindows.length > 0) {
        if (e) {
          e.returnValue = message
        }
        return message
      }
    }
  },

  removeQueryString(opts = {}) {
    opts.keepDev = true // do not loose DEV parameter if any
    const newUrl = stripQuery(window.location.href, opts)

    History.replaceCurrentUrl(newUrl)
  },

  reloadAppWithoutQueryString(opts = {}) {
    this.removeQueryString(opts)
    this.startApp()
  }
})

module.exports = App
