const Ext = require('ext')
const t = require('translate')
const escape = require('html-escaper').escape
const isString = require('strings/isString').default

const ResourceListPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,

  header: false,
  autoLoadStore: true,
  autoScroll: true,
  enableColumnHide: false,
  enableColumnMove: false,
  enableColumnResize: true,
  loadMask: true,

  initComponent() {
    // Allows module to be defined async.
    if (typeof this.module === 'function') {
      this.module = this.module()
    }

    if (!this.store) {
      throw Error('No store')
    }

    if (this.title == null) {
      this.title = this.module.translations.plural
    }

    // TODO: Remove passing of initialConfig to child components
    if (!isString(this.initialConfig.header)) {
      delete this.initialConfig.header
    }

    // Prevent plugin property being applied elsewhere
    delete this.initialConfig.plugins

    if (this.cm == null) {
      this.cm = this.createColumnModel(this.record)
    }

    let i = 0
    while (i < this.cm.getColumnCount()) {
      if (!this.cm.getColumnById(this.cm.getColumnId(i)).noHtmlEncode) {
        // TODO: At present this wraps even the default renderer, this is messy
        const renderer = this.cm.getRenderer(i)
        if (renderer) {
          this.cm.setRenderer(
            i,
            function (
              originalRenderer,
              value,
              metadata,
              record,
              rowIndex,
              colIndex,
              store
            ) {
              const displayValue = originalRenderer(
                value,
                metadata,
                record,
                rowIndex,
                colIndex,
                store
              )

              if (!displayValue) return
              return escape(displayValue)
            }.createDelegate(this.cm, [renderer], 0)
          )
        } else {
          this.cm.setRenderer(i, escape)
        }
      }
      i++
    }

    if (this.selModel == null) {
      this.selModel = new Ext.grid.RowSelectionModel({ singleSelect: true })
    }

    // TODO: Remove, will not render if there is no id column
    if (!this.autoExpandColumn && !this.autoExpandColumn) {
      this.autoExpandColumn = 'id'
    }

    if (this.tbar == null) {
      this.tbar = new Ext.Toolbar({
        enableOverflow: true,
        items: [].concat(...this.getToolbarItems()).filter(Boolean)
      })
    }

    if (this.keys == null) {
      this.keys = [
        {
          key: [Ext.EventObject.ENTER],
          fn() {
            if (
              this.getSelectionModel().hasSelection() &&
              this.getSelectionModel().getSelected().canOpen()
            ) {
              this.module.open(this.getSelectionModel().getSelected())
            }
          },
          scope: this
        }
      ]
    }

    this.viewConfig = Ext.applyIf(this.viewConfig || {}, {
      sortAscText: t('Sort Ascending'),
      sortDescText: t('Sort Descending')
    })

    this.on('rowdblclick', this.handleRowDblClick)
    this.on('rowcontextmenu', this.handleRowContextMenu)

    this.selModel.on('selectionchange', this.updateToolbars, this)
    this.store.on('load', this.updateToolbars, this)

    this.callParent()
  },

  onDestroy() {
    this.store.un('load', this.updateToolbars, this)
    this.callParent()
  },

  handleRowDblClick(grid, rowIndex) {
    const record = grid.getStore().getAt(rowIndex)

    if (record.canOpen()) {
      this.module.open(record, Object.assign({}, this.initialConfig))
    }
  },

  handleRowContextMenu(grid, rowIndex, event) {
    grid.getSelectionModel().selectRow(rowIndex)

    const record = grid.getStore().getAt(rowIndex)
    const items = this.getContextMenuItems(record)

    if (items.length) {
      const menu = new Ext.menu.Menu({
        cls: `x-menu ${this.module.id}`,
        id: this.module.id + '.itemmenu.' + this.id,
        items
      })

      menu.on('itemclick', menu.destroy)
      menu.on('hide', menu.destroy)

      event.stopEvent()
      menu.render()
      const xy = event.getXY()
      xy[1] -= menu.el.getHeight()
      menu.showAt(xy)
    }
  },

  getContextMenuItems(record) {
    const items = this.module.getContextMenuItems
      ? this.module.getContextMenuItems(record)
      : []

    if (this.module.canOpen()) {
      items.push({
        text: this.module.translations.open,
        iconCls: this.module.getResourceCls('open'),
        disabled: !record.canOpen(),
        handler: () => {
          this.module.open(record, Object.assign({}, this.initialConfig))
        }
      })
    }

    if (this.module.canRemove()) {
      items.push({
        text: this.module.translations.remove,
        iconCls: this.module.getResourceCls('remove'),
        disabled: !record.canRemove(),
        handler: () => this.module.remove(record)
      })
    }

    return items
  },

  afterRender() {
    this.callParent()
    if (this.autoLoadStore) {
      this.refreshStore()
    }
  },

  getToolbarItems() {
    return [
      this.getCreateButton(),
      this.getOpenButton(),
      this.getRemoveButton(),
      '->',
      this.getRefreshButton()
    ]
  },

  getCreateButtonText() {
    return t('New')
  },

  getCreateButton(disabled = false) {
    if (!(this.store && this.store.canCreate())) return

    if (this.createButton == null) {
      this.createButton = new Ext.Action({
        cls: 'e2e-resource-list-panel-new',
        text: this.getCreateButtonText(),
        disabled,
        handler: () => {
          this.module.create(
            this.record,
            {
              product: this.product,
              url: this.url,
              defaultsMap: this.defaultsMap, // Needed in SubscriptionNewPanel
              customer: this.customer, // Needed in NewRule
              vendor: this.vendor, // Needed in NewRule
              store: this.store
            } // Needed in NewRule
          )
        }
      })
    }

    return this.createButton
  },

  getOpenButtonText() {
    return t('Open')
  },

  getOpenButton() {
    if (!this.module.canOpen()) return

    return new Ext.Button({
      cls: 'e2e-resource-list-panel-open',
      text: this.getOpenButtonText(),
      disabled: true,
      shouldEnable: () => {
        const selected = this.getSelectionModel().getSelected()
        return selected?.canOpen()
      },
      handler: () => {
        if (this.getSelectionModel().hasSelection()) {
          return this.module.open(
            this.getSelectionModel().getSelected(),
            Object.assign({}, this.initialConfig)
          )
        }
      }
    })
  },

  getRemoveButton() {
    if (!this.store.canRemove()) return

    return new Ext.Button({
      cls: 'e2e-resource-list-panel-remove',
      text: t('Remove'),
      disabled: true,
      shouldEnable: () => {
        const selected = this.getSelectionModel().getSelected()
        return selected?.canRemove()
      },
      handler: () => {
        if (this.getSelectionModel().hasSelection()) {
          this.module.remove(this.getSelectionModel().getSelected())
        }
      }
    })
  },

  getRefreshButton() {
    return new Ext.Button({
      cls: 'e2e-resource-list-panel-refresh',
      text: t('Refresh'),
      handler: () => {
        this.refreshStore()
      }
    })
  },

  mask() {
    this.loadMask?.show()
  },

  unmask() {
    this.loadMask?.hide()
  },

  refreshStore() {
    this.getStore().load({
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this)
    })
  },

  updateToolbars() {
    const topBar = this.getTopToolbar()
    this.updateToolbarItems(topBar && topBar.items)
    const bottomBar = this.getBottomToolbar()
    this.updateToolbarItems(bottomBar && bottomBar.items)
  },

  updateToolbarItems(items) {
    if (!items) return

    items.each(function (item) {
      if (item.shouldEnable && typeof item.shouldEnable === 'function') {
        item.setDisabled(!item.shouldEnable())
      }
    })
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel([
      {
        id: 'id',
        header: t('ID'),
        width: 200,
        sortable: true,
        dataIndex: 'id'
      }
    ])
  },

  initEvents() {
    this.callParent()
    if (this.getStore().loading) {
      this.mask()
    }
  }
})

module.exports = ResourceListPanel
