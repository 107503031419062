const Ext = require('ext')
const t = require('translate')
const ConditionClause = require('smartrules/designer/ConditionClause')
const EqualsMatcher = require('smartrules/designer/matchers/EqualsMatcher')
const LteMatcher = require('smartrules/designer/matchers/LteMatcher')
const GteMatcher = require('smartrules/designer/matchers/GteMatcher')
const ContentMatcher = require('smartrules/designer/matchers/ContentMatcher')
const TextItemMatcher = require('smartrules/designer/matchers/TextItemMatcher')
const BetweenMatcher = require('smartrules/designer/matchers/BetweenMatcher')
const NoMatcher = require('smartrules/designer/matchers/NoMatcher')

const Attachment = Ext.define(null, {
  extend: ConditionClause,

  negativeOperators: ['is-not'],

  matcherTranslations: {
    name: 'attachment-name',
    type: 'attachment-type',
    contains: 'contains',
    'does-not-contain': 'does-not-contain'
  },

  constructor(cfg = {}) {
    let value

    if (cfg.valueType === 'regexp' && cfg.regex) {
      value = {
        regex: cfg.regex,
        ignoreCase: !!(cfg.flags || '').match(/i/i)
      }
    } else if (cfg.pattern) {
      value = cfg.pattern
    } else {
      value = cfg.value || ''
    }

    if (cfg.valueType && cfg.system === true) {
      cfg.valueType += '-system'
    }
    if (cfg.condition === 'attachments') {
      cfg.operator = value.type
    }

    const defaultMatcher = this.matcherTranslations[cfg.condition] || cfg.operator

    // only then, after defaultMatcher is set, amend the "-system" part (since SCL-2196)
    // todo rewrite this, getting messy
    if (cfg.operator && cfg.system === true) {
      cfg.operator += '-system'
    }

    const config = {
      selected: cfg.quantifier || cfg.condition,
      listStores: cfg.listStores,
      valueType: cfg.valueType || cfg.operator,
      system: cfg.system,
      negated: this.negativeOperators.indexOf(cfg.operator) > -1,

      steps: [
        {
          defaultValue: value,
          defaultMatcher,
          width: 155,
          operatorWidth: 155,

          conditions: [
            [
              {
                name: 'any',
                label: t('Any attachment'),

                matchers: [
                  {
                    matcher: 'contains',
                    name: t('contains'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'any attachment contains {0}'
                  },
                  {
                    matcher: 'does-not-contain',
                    name: t('does not contain'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'any attachment does not contain {0}'
                  },
                  {
                    matcher: 'attachment-type',
                    name: t('type', 'SmartRules Attachment'),
                    clazz: TextItemMatcher,
                    matcherType: 'ATTACHMENT_TYPE',
                    dsl: 'any attachment type is {0}'
                  },
                  {
                    matcher: 'attachment-name',
                    name: t('name', 'SmartRules Attachment'),
                    clazz: TextItemMatcher,
                    matcherType: 'ATTACHMENT_NAME',
                    dsl: 'any attachment name is {0}'
                  },
                  {
                    matcher: 'unscannable',
                    name: t('content is unscannable'),
                    clazz: NoMatcher,
                    dsl: 'any attachment content is unscannable'
                  }
                ]
              },
              {
                name: 'every',
                label: t('Every attachment'),

                matchers: [
                  {
                    matcher: 'contains',
                    name: t('contains'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'every attachment contains {0}'
                  },
                  {
                    matcher: 'does-not-contain',
                    name: t('does not contain'),
                    clazz: ContentMatcher,
                    matcherType: 'CONTENT',
                    dsl: 'every attachment does not contain {0}'
                  },
                  {
                    matcher: 'attachment-type',
                    name: t('type', 'SmartRules Attachment'),
                    clazz: TextItemMatcher,
                    matcherType: 'ATTACHMENT_TYPE',
                    dsl: 'every attachment type is {0}'
                  },
                  {
                    matcher: 'attachment-name',
                    name: t('name', 'SmartRules Attachment'),
                    clazz: TextItemMatcher,
                    matcherType: 'ATTACHMENT_NAME',
                    dsl: 'every attachment name is {0}'
                  },
                  {
                    matcher: 'unscannable',
                    name: t('content is unscannable'),
                    clazz: NoMatcher,
                    dsl: 'every attachment content is unscannable'
                  }
                ]
              },
              {
                name: 'attachments',
                label: t('Number of attachments'),
                matchers: [
                  {
                    matcher: 'between',
                    name: t('is between'),
                    clazz: BetweenMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of attachments {0}'
                  },
                  {
                    matcher: 'equals',
                    name: t('is equal to'),
                    clazz: EqualsMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of attachments {0}'
                  },
                  {
                    matcher: 'less',
                    name: t('is less than'),
                    matcherType: 'NUMBER',
                    clazz: LteMatcher,
                    dsl: 'number of attachments {0}'
                  },
                  {
                    matcher: 'greater',
                    name: t('is greater than'),
                    clazz: GteMatcher,
                    matcherType: 'NUMBER',
                    dsl: 'number of attachments {0}'
                  }
                ]
              }
            ]
          ]
        }
      ]
    }

    this.callParent([config])
  }
})

module.exports = Attachment
