import React, { PureComponent } from 'react'

import Format from 'admin/util/Format'
import classNames from 'classnames'
import Highlight from 'components/Highlight'
import deepmerge from 'deepmerge'
import makeRegexForHighlighting from 'mail/makeRegexForHighlighting'
import PropTypes from 'prop-types'
import t from 'translate'

import Table from './Table'

const SELECT_COLUMN_WIDTH = 26
const stopPropagation = (e) => e.stopPropagation()

class Rows extends PureComponent {
  static propTypes = {
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    enableHighlighting: PropTypes.bool,
    resultsQuery: PropTypes.string,

    scrollToIndex: PropTypes.number,
    firstRowNumber: PropTypes.number,

    onRowClick: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    setColumnWidth: PropTypes.func.isRequired,

    rows: PropTypes.array.isRequired,
    columns: PropTypes.object,

    maxHeight: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,

    loading: PropTypes.bool
  }

  constructor(props) {
    super(props)

    // to alter their default widths, do that in the db.es of the
    // calling namespace instead since widths are app states.
    // otherwise flexGrow will pick their default widths responsively.
    this.defaultColumns = {
      sender: {
        renderCell: this.renderSender,
        isResizable: true,
        flexGrow: 3
      },
      recipients: {
        renderCell: this.renderRecipients,
        isResizable: true,
        flexGrow: 4
      },
      subject: {
        renderCell: this.renderSubject,
        isResizable: true,
        flexGrow: 5
      },
      size: {
        renderCell: this.renderSize,
        isResizable: true,
        flexGrow: 1
      },
      received: {
        isResizable: true,
        flexGrow: 2
      },
      released: {
        renderCell: this.renderReleased,
        isResizable: false,
        align: 'right',
        width: 70
      }
    }

    this.columns = this.buildColumns(this.props.columns)
  }

  renderCellData(cellData, keyword) {
    if (this.props.enableHighlighting) {
      const regex = makeRegexForHighlighting(
        this.props.resultsQuery,
        keyword,
        this.props.availableKeywords
      )

      return <Highlight regex={regex}>{cellData}</Highlight>
    }

    return cellData
  }

  highlight(cellData, keyword) {
    return <div>{cellData && this.renderCellData(cellData, keyword)}</div>
  }

  allSelected = () => {
    if (this.props.rows.length < 1) return false
    return this.props.rows.every((r) => r.selected)
  }

  renderSender = (cellData) => this.highlight(cellData || t('[null sender]'), 'sender')

  renderRecipients = (cellData) => this.highlight(cellData, 'recipient')

  renderSubject = (cellData) => this.highlight(cellData, 'subject')

  renderReleased = (cellData) => (cellData ? t('Yes') : t('No'))

  renderSize = (cellData) => Format.bytes(cellData || 0)

  handleSelectAllChange = () => this.props.onSelect(this.props.rows, !this.allSelected())

  handleSelectChange = (rowData, checked) => this.props.onSelect([rowData], checked)

  renderSelectAll = () => {
    return (
      <input
        type='checkbox'
        checked={this.allSelected()}
        onClick={stopPropagation}
        onChange={this.handleSelectAllChange}
        className='select-all'
      />
    )
  }

  // eslint-disable-next-line default-param-last
  renderSelect = (cellData = false, columnName, rowData) => {
    return (
      <input
        type='checkbox'
        checked={cellData}
        onClick={stopPropagation}
        onChange={(e) => this.handleSelectChange(rowData, e.target.checked)}
        className='select'
      />
    )
  }

  buildColumns = (columns) => {
    // this filters out default columns based on given column and merges them
    return Object.keys(columns).reduce(
      (newColumns, key) => {
        const column = columns[key]
        const defaultColumn = this.defaultColumns[key]

        if (defaultColumn) {
          newColumns[key] = deepmerge(column, defaultColumn)
        }

        return newColumns
      },
      {
        // first column is always about selections, hence fixed here, non-customisable.
        selected: {
          align: 'center',
          width: SELECT_COLUMN_WIDTH,
          renderHeader: this.renderSelectAll,
          renderCell: this.renderSelect,
          onHeaderClick: this.onSelectAllToggle,
          onCellClick: (e, rowData) => {
            e.stopPropagation()
            this.handleSelectChange(rowData, !rowData.selected)
          }
        }
      }
    )
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.columns !== this.props.columns) {
      // we are "calculating" columns here only when necessary,
      // and this not in the render method for best performance
      this.columns = this.buildColumns(nextProps.columns)
    }
  }

  render() {
    return (
      <Table
        maxHeight={this.props.maxHeight}
        width={this.props.width}
        columns={this.columns}
        rowClassName='mail-result-row'
        rows={this.props.rows}
        scrollToIndex={this.props.scrollToIndex}
        firstRowNumber={this.props.firstRowNumber}
        onRowClick={this.props.onRowClick}
        setColumnWidth={this.props.setColumnWidth}
        className={classNames('mail-search-table', { busy: this.props.loading })}
      />
    )
  }
}

export default Rows
