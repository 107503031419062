const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const TemplateManagementPanel = require('smartrules/TemplateManagementPanel')
const TemplateRecord = require('smartrules/TemplateRecord')
const User = require('admin/core/User')

const TemplateManagement = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.SmartRules.TemplateManagement',
  translations: {
    new: t('New Template'),
    open: t('Open'),
    list: t('Templates')
  },
  name: t('Content Templates'),
  code: 'TEMPLATE',
  resourceId: 'TEMPLATES',
  resourceName: 'templates',

  layout: {
    openResource: {
      width: 725,
      height: 'auto'
    }
  },

  listPanel: TemplateManagementPanel,

  record: TemplateRecord,

  canCreate() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canSave() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  },

  canRemove() {
    return User.isTechnical() && this.superclass().canSave.call(this)
  }
})

module.exports = TemplateManagement
