import React from 'react'

import { Col, ControlLabel } from 'react-bootstrap'

export default function Label(label, labelWidth = 3) {
  return (
    <Col xs={labelWidth} xsOffset={0} componentClass={ControlLabel}>
      {label}
    </Col>
  )
}
