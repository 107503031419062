import React, { Component } from 'react'

import Field from 'components/form/Field'
import PropTypes from 'prop-types'
import t from 'translate'

class DurationField extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    info: PropTypes.object.isRequired
  }

  render() {
    return (
      <Field
        name='duration'
        label={t('Every')}
        componentClass='select'
        labelWidth={5}
        inputWidth={6}
        disabled={this.props.disabled}
        info={this.props.info}
      >
        <option value='PT15M'>15m</option>
        <option value='PT30M'>30m</option>
        <option value='PT1H'>1h</option>
        <option value='PT4H'>4h</option>
        <option value='PT6H'>6h</option>
        <option value='PT8H'>8h</option>
        <option value='PT12H'>12h</option>
        <option value='PT24H'>24h</option>
      </Field>
    )
  }
}
export default DurationField
