const Ext = require('ext')

/*
Extended solely to provide hacked event support.
*/
const HtmlEditor = Ext.define(null, {
  extend: Ext.form.HtmlEditor,

  initComponent() {
    this.addEvents(
      // HtmlEditor is really an IFrame, not a TextField, so normal events don't
      // work! Here we hook into the internal event hackery to generate a special
      // editorevent on focus, click, mousedown etc. So a click generates
      // multiple events!
      'editorevent'
    )
    this.callParent()
    return this.on(
      'initialize',
      function () {
        try {
          // use the size of the area this is being rendered into
          const size =
            this.ownerCt != null ? this.ownerCt.getLayoutTarget().getSize() : undefined
          return this.onResize(size.width, size.height)
        } catch (e) {
          // ignore
        }
      },
      // do nothing
      this
    )
  },

  onEditorEvent(e) {
    this.callParent([e])
    return this.fireEvent('editorevent', this)
  },

  // If colour is (e.g.) grey on initialisation it stays grey even when underlying el enabled
  fixColor() {
    if (this.rendered) {
      const dbody = this.getEditorBody()
      const styles = this.el.getStyles(['color', 'background-color'])
      if (dbody) {
        return Ext.DomHelper.applyStyles(dbody, styles)
      }
    }
  },

  disable() {
    this.callParent()
    return this.fixColor()
  },

  enable() {
    this.callParent()
    return this.fixColor()
  },

  defaultCharCode: 8203,
  defaultValue: '&#8203;',

  cleanHtml(html) {
    if (html == null) {
      html = ''
    }
    html = String(html)

    // Strip safari nonsense
    if (Ext.isWebKit) {
      html = html.replace(/\sclass="(?:Apple-style-span|khtml-block-placeholder)"/gi, '')
    }

    return html.replace(new RegExp(`${String.fromCharCode(this.defaultCharCode)}`), '')
  },

  markInvalid(msg) {
    Ext.fly(this.iframe).addClass('x-form-invalid')
    const invalidImage = require('default/grid/invalid_line.gif')
    const anim = Ext.fly(this.getEditorBody())

    if (anim) {
      anim.applyStyles({
        'background-image': invalidImage,
        'background-position': 'bottom',
        'background-repeat': 'repeat-x'
      })
    }

    this.iframe.qtip = msg
    this.iframe.qclass = 'x-form-invalid-tip'
  },

  clearInvalid() {
    Ext.fly(this.iframe).removeClass('x-form-invalid')
    const anim = Ext.fly(this.getEditorBody())
    if (anim) {
      anim.applyStyles({
        'background-image': ''
      })
    }
    this.iframe.qtip = ''
  },

  // Private
  onResize(w, h) {
    this.callParent(arguments)
    const iframe = Ext.fly(this.iframe)
    if (this.el && this.iframe) {
      if (Ext.isNumber(w)) {
        const aw = w - this.wrap.getFrameWidth('lr') - iframe.getBorderWidth('lr')
        this.el.setWidth(aw)
        this.tb.setWidth(aw)
        this.iframe.style.width = `${Math.max(aw, 0)}px`
      }
      if (Ext.isNumber(h)) {
        const ah =
          h -
          this.wrap.getFrameWidth('tb') -
          this.tb.el.getHeight() -
          iframe.getBorderWidth('tb')
        this.el.setHeight(ah)
        this.iframe.style.height = `${Math.max(ah, 0)}px`
        const body = this.getEditorBody()

        if (body) {
          body.style.height = `${Math.max(ah - this.iframePad * 2, 0)}px`
        }
      }
    }
  }
})

module.exports = HtmlEditor
