const Ext = require('ext')
const t = require('translate')
const CompanyDetailPanel = require('admin/view/CompanyDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const config = require('theming/config')

const VendorDetailPanel = Ext.define(null, {
  extend: CompanyDetailPanel,

  title: t('Details'),
  cls: 'vendor-detail-panel',
  tabCls: 'vendor-detail-tab',

  module() {
    return require('admin/business/VendorResource')
  },

  getDetailItems() {
    return [
      {
        fieldLabel: t('Vendor Name'),
        name: 'displayName',
        xtype: 'textfield'
      },
      {
        title: t('Primary Address'),
        width: 'auto',
        height: 'auto',
        defaults: {
          width: config.panel.fieldWidth
        },
        items: [
          {
            fieldLabel: t('Attention Name'),
            name: 'primaryPostalAddress.attnName',
            xtype: 'textfield'
          },
          {
            fieldLabel: t('Address Line 1'),
            name: 'primaryPostalAddress.address1',
            xtype: 'textfield'
          },
          {
            fieldLabel: t('Address Line 2'),
            name: 'primaryPostalAddress.address2',
            xtype: 'textfield'
          },
          {
            fieldLabel: t('City / Town'),
            name: 'primaryPostalAddress.city',
            xtype: 'textfield'
          },
          new ReadOnlyTextField({
            fieldLabel: t('Country'),
            name: 'primaryPostalAddress.countryGeoName'
          }),
          {
            fieldLabel: t('Postal Code'),
            name: 'primaryPostalAddress.postalCode',
            xtype: 'textfield'
          }
        ],
        xtype: 'fieldset'
      }
    ]
  },

  getViewPanel(cfg) {
    return new RecordFormPanel({
      record: this.record,
      module: this.module,
      items: this.getViewItems(cfg)
    })
  }
})

module.exports = VendorDetailPanel
