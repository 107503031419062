const Ext = require('ext')
const Q = require('q')

// For records with domain config endpoints (/members,/availablemembers,/attributes).

Ext.ns('SMX.data')

const DomainConfigMixin = {
  // Returns promise of config object with a member flag and availablemembers merged.
  getConfig() {
    return this.requestRel('members', { json: true })
      .then((members) => {
        return this.requestRel('availablemembers', { json: true }).then(
          (availableMembers) => {
            const membersData = members.members
              .map((m) => ({ ...m, member: true }))
              .concat(availableMembers.map((m) => ({ ...m, member: false })))
            const configObject = {
              global: members.global,
              members: membersData,
              attributes: null
            }

            if (this.hasAttributes) {
              return this.requestRel('attributes', { json: true }).then((attributes) => {
                configObject.attributes = attributes
                return configObject
              })
            }

            return configObject
          }
        )
      })
      .catch((error) => {
        throw error
      })
  },
  // Save config in the same format we provide them, returns promise.
  saveConfig(config) {
    const memberIds = config.members.reduce((memberIds, member) => {
      if (member.member) {
        memberIds.push(member.id)
      }
      return memberIds
    }, [])

    let params

    if (config.attributes?.length > 0) {
      params = config.attributes.reduce((params, attribute) => {
        params[attribute.attribute] = attribute.enabled
        return params
      }, {})
    }

    return Q.all([
      this.requestRel('members', {
        method: 'PUT',
        params: {
          global: config.global,
          id: memberIds
        }
      }),
      params &&
        this.requestRel('attributes', {
          method: 'PUT',
          params
        })
    ]).then(() => {
      // Get updated global and contactMechCount
      return this.fetch()
    })
  }
}

module.exports = DomainConfigMixin
