const Record = require('admin/core/Record')
const DomainStatus = require('admin/util/DomainStatus')
const HostingDomainResource = require('admin/business/HostingDomainResource')

// todo: migrate to React
const HostingDomainRecord = Record.define(null, {
  module() {
    return HostingDomainResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'wildcard',
      type: 'string'
    },
    {
      name: 'hostingPlatform',
      type: 'string'
    },
    {
      name: 'hostingDescription',
      type: 'string'
    },
    {
      name: 'status',
      type: 'string',
      mapping: 'currentStatus.statusItem.code',
      convert: DomainStatus.translate
    },
    {
      name: 'isMailboxCapable',
      type: 'bool'
    }
  ],

  getName() {
    return this.get('name')
  },

  getDescription() {
    return this.get('hostingDescription')
  },

  getParams() {
    const params = {
      name: this.get('name'),
      hostingPlatform: this.get('hostingPlatform')
    }
    // Wildcard attribute only valid on updates.
    if (this.get('id')) {
      params.wildcard = this.get('wildcard')
    }
    return params
  },

  isWildcardCapable() {
    return this.get('isMailboxCapable')
  }
})

module.exports = HostingDomainRecord
