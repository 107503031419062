const Record = require('admin/core/Record')

const TrustedIpRecord = Record.define(null, {
  module() {
    return require('admin/business/TrustedIpResource')
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'url',
      type: 'string'
    }
  ],

  getName() {
    return this.get('name')
  },

  getDescription() {
    return this.get('name')
  },

  getParams() {
    return { ipAddress: this.get('name') }
  }
})

module.exports = TrustedIpRecord
