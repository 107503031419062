import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Grid from 'components/Grid'
import MembershipForm from 'archiving/membership/views/Form'

// todo: move these constants to CSS, not good having these here in JS
const TOOLBAR_MIN_HEIGHT = 70

class Tab extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired
  }

  calcOptimalHeightForChildren() {
    return this.props.height - TOOLBAR_MIN_HEIGHT
  }

  render() {
    return (
      <Grid className='archiving-config'>
        <MembershipForm
          companyKey={this.props.companyKey}
          height={this.calcOptimalHeightForChildren()}
        />
      </Grid>
    )
  }
}

export default Tab
