const Ext = require('ext')

const AutoSizingTextArea = Ext.define(null, {
  extend: Ext.form.TextArea,

  // necessary to assign an xtype to ensure it can be validated in a form
  xtype: 'smx-auto-sizing-field',
  preventScrollbars: true,
  grow: true,
  growMin: 20,
  growAppend: '&#160;&#160;',
  growPadding: '5px 3px 3px',
  lineHeight: '14px',

  onRender(ct, position) {
    this.callParent([ct, position])

    Ext.DomHelper.applyStyles(this.textSizeEl, {
      'line-height': this.lineHeight,
      padding: this.growPadding
    })

    this.ownerCt?.on(
      'afterlayout',
      function () {
        // since SCL-3560, needed for smart rules text matcher
        if (this.initialConfig.autoWidth && this.ownerCt.getWidth() < 1) {
          this.ownerCt.setWidth('auto')
        }

        this.autoSize()
      },
      this
    )
  }
})

module.exports = AutoSizingTextArea
