import * as hal from 'hal'
import {
  TENANCY_RENAME_REL,
  TENANCY_SUSPEND_REL,
  TENANCY_UNSUSPEND_REL
} from 'product/resources'
import compareStrings from 'strings/compare/lexical'
import createCompanySelector from 'system/selector/createCompanySelector'

import { createSelector } from '@reduxjs/toolkit'

// this returns a list of links by given relation
const reduceLinkSelections = (rows, rel = null) => {
  return rows.reduce((links, row) => {
    const link = rel ? hal.href(row, rel) : hal.self(row)
    if (!link) return links
    return links.concat([link])
  }, [])
}

const company = createCompanySelector('tenancy')
const formDb = (db) => db.form

export const tenancyForm = createSelector(formDb, (formDb) => formDb.tenancyForm)

export const uri = createSelector(company, (company) => company?.uri)

export const list = createSelector(company, (company) => company?.list)

export const sortedList = createSelector(list, (list) => {
  if (!list) return list
  return list
    .slice()
    .sort((tenancy1, tenancy2) => compareStrings(tenancy1.name, tenancy2.name))
})

export const loading = createSelector(company, (company) => Boolean(company?.loading))

export const creating = createSelector(company, (company) => Boolean(company?.creating))

export const renaming = createSelector(company, (company) =>
  Boolean(company?.renameTarget)
)

export const size = createSelector(list, (list) => list?.length)

export const showResults = createSelector(size, (size) => size > 0)

export const showNoResults = createSelector(
  loading,
  list,
  size,
  (loading, list, size) => !loading && list && size < 1
)

export const selected = createSelector(list, (list) =>
  list?.some((tenancy) => tenancy.selected)
)

export const selectedTenancy = createSelector(list, (list) =>
  list?.find((tenancy) => tenancy.selected)
)

export const selectedTenancyName = createSelector(
  selectedTenancy,
  (selectedTenancy) => selectedTenancy?.name
)

const selectedTenancies = createSelector(list, (list) =>
  list?.filter((tenancy) => tenancy.selected)
)

export const selectedTenancyLink = createSelector(selectedTenancy, (selectedTenancy) => {
  return selectedTenancy && hal.self(selectedTenancy)
})

export const selectedTenanciesLinks = createSelector(
  selectedTenancies,
  (selectedTenancies) => {
    if (!selectedTenancies) return

    return reduceLinkSelections(selectedTenancies)
  }
)

export const selectedTenanciesSuspendLinks = createSelector(
  selectedTenancies,
  (selectedTenancies) => {
    if (!selectedTenancies) return

    return reduceLinkSelections(selectedTenancies, TENANCY_SUSPEND_REL)
  }
)
export const selectedTenanciesUnsuspendLinks = createSelector(
  selectedTenancies,
  (selectedTenancies) => {
    if (!selectedTenancies) return

    return reduceLinkSelections(selectedTenancies, TENANCY_UNSUSPEND_REL)
  }
)

export const selectedCount = createSelector(selectedTenancies, (selectedTenancies) => {
  if (!selectedTenancies) return 0
  return selectedTenancies.length
})

export const renameTarget = createSelector(company, (company) => company?.renameTarget)

export const renameTargetName = createSelector(
  renameTarget,
  (renameTarget) => renameTarget?.name
)

export const renameTargetLink = createSelector(renameTarget, (renameTarget) => {
  if (!renameTarget) return
  const renameTargetJS = renameTarget
  return hal.href(renameTargetJS, TENANCY_RENAME_REL)
})

export const confirmingDeletion = createSelector(company, (company) =>
  Boolean(company?.confirmingDeletion)
)

export const addButtonDisabled = createSelector(
  loading,
  confirmingDeletion,
  (loading, confirmingDeletion) => loading || confirmingDeletion
)

export const renameButtonDisabled = createSelector(
  loading,
  confirmingDeletion,
  selectedTenancies,
  (loading, confirmingDeletion, selectedTenancies) => {
    if (loading) return true
    if (confirmingDeletion) return true
    if (!selectedTenancies) return true
    if (selectedTenancies.length !== 1) return true

    const first = selectedTenancies[0]

    return !hal.link(first, TENANCY_RENAME_REL)
  }
)

export const deleteButtonDisabled = createSelector(
  loading,
  selectedTenancy,
  confirmingDeletion,
  confirmingDeletion,
  (loading, selectedTenancy, confirmingDeletion) =>
    loading || !selectedTenancy || confirmingDeletion || confirmingDeletion
)

export const suspendButtonDisabled = createSelector(
  loading,
  selectedTenancy,
  confirmingDeletion,
  (loading, selectedTenancy, confirmingDeletion) => {
    return Boolean(
      loading ||
        !selectedTenancy ||
        confirmingDeletion ||
        !hal.link(selectedTenancy, TENANCY_SUSPEND_REL)
    )
  }
)

export const unsuspendButtonDisabled = createSelector(
  loading,
  selectedTenancy,
  confirmingDeletion,
  (loading, selectedTenancy, confirmingDeletion) => {
    return Boolean(
      loading ||
        !selectedTenancy ||
        confirmingDeletion ||
        !hal.link(selectedTenancy, TENANCY_UNSUSPEND_REL)
    )
  }
)

export const refreshButtonDisabled = createSelector(
  loading,
  confirmingDeletion,
  (loading, confirmingDeletion) => loading || confirmingDeletion
)

export const names = createSelector(list, (list) => list?.map((target) => target.name))

export const formDisabled = createSelector(loading, (loading) => loading)

export const hasModalDialog = createSelector(
  confirmingDeletion,
  creating,
  renaming,
  (confirmingDeletion, creating, renaming) => {
    return creating || renaming || confirmingDeletion
  }
)
