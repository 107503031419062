import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import React, { Component } from 'react'
import {
  addButtonDisabled,
  configureButtonDisabled,
  deleteButtonDisabled,
  loading,
  refreshButtonDisabled,
  selected,
  selectedQuarantineSetLink,
  size,
  uri
} from '../../subs'
import {
  configureQuarantineSet,
  refresh,
  startConfirmingDeletion,
  startCreating
} from '../../actions'

import Indicator from 'components/Indicator'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'translate'

class Toolbar extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  configure = () => {
    this.props.configureQuarantineSet(this.props.selectedQuarantineSetLink)
  }

  delete = () => {
    this.props.startConfirmingDeletion(this.props.uri)
  }

  refresh = () => {
    this.props.refresh(this.props.uri)
  }

  renderLeftButtons() {
    return (
      <ButtonToolbar className='pull-left'>
        <Button
          disabled={this.props.addButtonDisabled}
          onClick={this.props.startCreating}
          className='add' // just for unit tests
        >
          {t('New')}
        </Button>
        <Button disabled={this.props.configureButtonDisabled} onClick={this.configure}>
          {t('Configure')}
        </Button>
        <Button disabled={this.props.deleteButtonDisabled} onClick={this.delete}>
          {t('Delete')}
        </Button>
      </ButtonToolbar>
    )
  }

  render() {
    return (
      <div className='toolbar clearfix'>
        <Row className='no-gutter'>
          <Col className='no-gutter'>{this.renderLeftButtons()}</Col>
          <Col className='no-gutter'>
            <div className='pull-right'>
              <Indicator
                loading={this.props.loading}
                size={this.props.size}
                noun='policy'
              />
              <ButtonToolbar className='right-button-toolbar'>
                <Button
                  disabled={this.props.refreshButtonDisabled}
                  onClick={this.refresh}
                >
                  {t('Refresh')}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDbToProps = (db, props) => ({
  size: size(db, props.companyKey),
  loading: loading(db, props.companyKey),
  selected: selected(db, props.companyKey),
  selectedQuarantineSetLink: selectedQuarantineSetLink(db, props.companyKey),
  addButtonDisabled: addButtonDisabled(db, props.companyKey),
  configureButtonDisabled: configureButtonDisabled(db, props.companyKey),
  deleteButtonDisabled: deleteButtonDisabled(db, props.companyKey),
  refreshButtonDisabled: refreshButtonDisabled(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  configureQuarantineSet: (link) => dispatch(configureQuarantineSet(companyKey, link)),
  startConfirmingDeletion: () => dispatch(startConfirmingDeletion(companyKey)),
  refresh: (uri) => dispatch(refresh(companyKey, uri)),
  startCreating: () => dispatch(startCreating(companyKey))
})

export default connect(mapDbToProps, mapActionsToProps)(Toolbar)
