const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ComboBox = require('admin/component/form/ComboBox')
const validators = require('validators').default
const ListTypes = require('smartrules/ListTypes')
const Tooltip = require('admin/util/Tooltip')

const ListManagementDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'listmanagement-detail-panel',
  closeOnSave: true,

  module() {
    return require('smartrules/ListManagement')
  },

  getFormPanel(cfg) {
    const panel = new RecordFormPanel({
      fieldWidth: 'auto',
      record: this.record,
      module: this.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
    panel.on('afterlayout', this.module.updateFieldSize, this.module)
    return panel
  },

  getDetailItems(cfg) {
    let typeRecord
    const store = this.module.getDataTypeStore()

    const typeRecordIndex = store.find('value', cfg.record.get('dataSetType'))

    if (typeRecordIndex !== -1) {
      typeRecord = store.getAt(typeRecordIndex)
    }

    const listType = typeRecord != null ? typeRecord.get('value') : undefined

    const explanationField = new Ext.form.DisplayField({
      style: 'padding: 8px 0 4px',
      value: ListTypes.getExplanation(listType),
      htmlEncode: true
    })

    const dataSetType = new ComboBox({
      disabled: true,
      allowBlank: false,
      displayField: 'description',
      editable: false,
      helpText: (typeRecord != null ? typeRecord.get('example') : undefined) || '',
      hiddenName: 'dataSetType',
      mode: 'local',
      fieldLabel: t('List Type'),
      listeners: {
        scope: this,
        select(combo, record) {
          Tooltip.register(combo.container.child('.helptext'), record.get('example'))
          return items.validate()
        }
      },
      store: this.module.getDataTypeStore(),
      triggerAction: 'all',
      valueField: 'value'
    })

    const items = new Ext.form.TextArea({
      height: 250,
      name: 'items',
      fieldLabel: t('Items'),
      validator(v) {
        if (dataSetType.value === ListTypes.RULE_ADDRESS) {
          if (validators.linebrokenEmailAndGroupGlobs(v)) {
            return true
          }
          return t('Address lists can contain email addresses or groups.')
        }
        return true
      },
      xtype: 'textarea'
    })
    return [
      {
        name: 'name',
        maxLength: 255,
        allowBlank: false,
        fieldLabel: t('Name'),
        xtype: 'textfield',
        validator(v) {
          if (v.match(/^\w[\w -]*$/)) {
            return true
          }
          return 'Names can only have alphanumeric characters and spaces'
        }
      },
      dataSetType,
      explanationField,
      items
    ]
  },

  getViewPanel(cfg) {
    const panel = new RecordFormPanel({
      fieldWidth: 'auto',
      record: this.record,
      module: this.module,
      operation: 'save',
      items: this.getViewItems(cfg)
    })
    panel.on('afterlayout', this.module.updateFieldSize, this.module)
    return panel
  }
})

module.exports = ListManagementDetailPanel
