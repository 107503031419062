const Ext = require('ext')
const compareStrings = require('../../strings/compare/lexical')
const Clause = require('smartrules/designer/Clause')
const JoinWord = require('smartrules/designer/JoinWord')
const DeleteButton = require('smartrules/designer/DeleteButton')
const DraggingButton = require('smartrules/designer/DraggingButton')
const ConditionCombo = require('smartrules/designer/ConditionCombo')
const MatcherCombo = require('smartrules/designer/MatcherCombo')
const ConditionGroup = require('smartrules/designer/ConditionGroup')
const SmartRule = require('smartrules/designer/SmartRule')

// A SmartRules "if" condition clause which can be composed by multiple steps containing
// multiple conditions within
const ConditionClause = Ext.define(
  null,
  (function () {
    const getAllMatchers = function (steps) {
      const allConditions = getAllConditions(steps)
      const matchers = allConditions.map((condition) => getMatchersByCondition(condition))

      // beware that this only can flatten matchers up to two dimensions not more
      // which isn't a problem for now unless we extend to more complex combinations
      // in future.
      return [].concat(...matchers).filter(Boolean)
    }

    const getMatcherByKey = function (steps, matcherKey) {
      const allMatchers = getAllMatchers(steps)
      return allMatchers.find((matcher) => matcher.matcher === matcherKey)
    }

    const conditionHasMatcherKey = function (condition, matcherKey) {
      const conditionMatchers = getMatchersByCondition(condition)

      return conditionMatchers.some((matcher) => matcher.matcher === matcherKey)
    }

    const getStepByMatcherKey = (steps, matcherKey) =>
      steps.find((step) =>
        step.conditions.some((condition) => conditionHasMatcherKey(condition, matcherKey))
      )

    const getConditionIdByMatcherKey = function (steps, matcherKey) {
      const condition = getAllConditions(steps).find((condition) =>
        conditionHasMatcherKey(condition, matcherKey)
      )

      return condition && condition.id
    }

    const getAllConditions = (steps) => {
      const conditions = steps.map((step) => step.conditions)
      return [].concat(...conditions)
    }

    const getStepByName = (steps, stepName) =>
      steps.find((step) => step.name === stepName)

    const getConditionById = (steps, conditionId) =>
      getAllConditions(steps).find((condition) => condition.id === conditionId)

    const getMatchersByCondition = (condition) => condition.matchers || []

    // just a convenient function delegating to getMatchersByCondition()
    const getMatchersByConditionId = function (steps, conditionId) {
      const condition = getConditionById(steps, conditionId)
      return getMatchersByCondition(condition)
    }

    const optimizeDsl = function (dsl) {
      dsl = dsl.replace(/\(\s*\(/g, '(')
      dsl = dsl.replace(/(\)+)/g, ')')

      return dsl
    }

    // checking for look-aheads: these make it possible to re-arrange the order
    // of DSLs within steps (aka look-aheads). this enables us to build DSL's in Lisp syntax
    const lookAhead = function (steps, step, dsl) {
      let stepDsl
      let optimize = false

      const lookahead = dsl.match(/\{(\w+)\}/)

      const lookaheadString = lookahead && lookahead[0]
      const lookaheadStepName = lookahead && lookahead[1]

      if (lookaheadString && lookaheadStepName) {
        const lookaheadStep = getStepByName(steps, lookaheadStepName)

        if (lookaheadStep) {
          // recursion! grabs dsl again from another step but setting the skipping to false
          // avoids bad loops
          stepDsl = stepToDsl(steps, lookaheadStep, false)

          if (step.matcherCombo && step.matcherCombo.skipLookAhead(stepDsl)) {
            stepDsl = ''
            optimize = true
          } else if (stepDsl === '') {
            stepDsl = null
          }
        }
      }

      if (stepDsl != null) {
        dsl = dsl.replace(lookaheadString, stepDsl) // this replaces { ... } with the real dsl
      }

      if (optimize) {
        // now optimize dsl by replacing any unnecessary brackets, i.E.
        // ( (customer-list "Ed Nib Test")) becomes (customer-list "Ed Nib Test")
        dsl = optimizeDsl(dsl)
      }

      return dsl
    }

    const isStepHidden = (step) =>
      (step.conditionCombo && step.conditionCombo.isHidden()) ||
      (step.matcherCombo && step.matcherCombo.isHidden())

    const stepToDsl = function (steps, step, allowSkipping) {
      let dsl

      if (allowSkipping == null) {
        allowSkipping = true
      }

      if (isStepHidden(step)) {
        dsl = false

        // needed when it should be skipped, i.E. for look-aheads
      } else if (allowSkipping && step.dsl === false) {
        dsl = false
      } else if (step.matcherCombo) {
        dsl = step.matcherCombo.toDsl()
      } else if (step.conditionCombo) {
        dsl = step.conditionCombo.toDsl()
      } else if (step.name) {
        dsl = step.name
      }

      // only perform look aheads when dsl is a string with the match() function
      if (dsl && dsl.match) {
        dsl = lookAhead(steps, step, dsl)
      }

      return dsl
    }

    const getSelectedCondition = function (cfg, step) {
      let selectedCondition
      if (cfg.selected) {
        selectedCondition = step.conditions.find(
          (condition) => cfg.selected === condition.name || cfg.selected === condition.id
        )
      } else if (step.defaultCondition) {
        selectedCondition = step.conditions.find(
          (condition) => condition.name === step.defaultCondition
        )
      }

      // automatically pick first one if no condition is selected
      if (!step.allowBlank) {
        if (!selectedCondition) {
          selectedCondition = step.conditions[0]
        }
      }

      return selectedCondition
    }

    const generateConditionId = (stepNumber, conditionGroupIndex, conditionIndex) =>
      `s${stepNumber}g${conditionGroupIndex}c${conditionIndex}`

    const correctAndSortConditions = (step, stepNumber) =>
      step.conditions.map((conditionGroup, conditionGroupIndex) =>
        conditionGroup
          .map(function (condition, conditionIndex) {
            // pass on default dsl from step to condition when necessary
            if (step.dsl && !condition.dsl) {
              condition.dsl = step.dsl
            }

            // automatically generates an unique ID for each condition.
            // this is required for fetching the correct matchers by a given condition id.
            // because the condition names aren't always unique.
            condition.id = generateConditionId(
              stepNumber,
              conditionGroupIndex,
              conditionIndex
            )

            return condition
          })
          .sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1

            return 0
          })
      )

    // ensures that it becomes one array, not a nested one anymore
    // it also places a flag for separators in-between groups
    const flattenConditions = (conditionGroups) =>
      conditionGroups.reduce(function (memo, conditionGroup) {
        if (memo.length > 0) {
          conditionGroup.unshift({
            addSeparatorOnNextCondition: true // that flag is required for populateStepStore()
          })
        }

        return memo.concat(conditionGroup)
      }, [])

    // some conditions should be excluded, i.E. when name is not defined or
    // when some other custom items in other conditions want to dynamically skip it
    const excludeCondition = function (condition, options = {}) {
      if (options.excludeConditions != null && condition.name != null) {
        // respect the excludeCondition option saying which condition to skip
        // this is needed for custom items being able to hide a given condition dynamically
        return options.excludeConditions.indexOf(condition.name) > -1
      }
      // only add those conditions with a name in them
      return condition.name == null
    }

    // populates the store for this step with all conditions for the given step
    const populateStepStore = function (step, stepStore, options = {}) {
      let addSeparatorOnNextCondition = false

      return stepStore.add(
        step.conditions
          .filter((condition) => !excludeCondition(condition, options))
          .map(function (condition) {
            if (addSeparatorOnNextCondition) {
              condition.separator = true
              addSeparatorOnNextCondition = false

              // special treatments for separator flags
            } else if (condition.addSeparatorOnNextCondition) {
              addSeparatorOnNextCondition = true
            }

            const RecordTypeClass = stepStore.recordType

            return new RecordTypeClass(condition)
          })
      )
    }

    const createStepStore = function (step, options = {}) {
      const stepStore = new Ext.data.JsonStore({
        data: [],
        fields: ['condition', 'label']
      })

      populateStepStore(step, stepStore, options)

      return stepStore
    }

    const createConditionCombo = (cfg, stepStore, step, conditionComboValue) =>
      new ConditionCombo({
        store: stepStore,
        width: step.width || cfg.defaultStepWidth || 100,
        value: conditionComboValue,
        emptyText: step.emptyText,
        hideRestWhenBlank: step.hideRestWhenBlank,
        allowBlank: step.allowBlank,
        postLabel: step.postLabel,
        postLabelWidth: step.postLabelWidth,
        validator: step.validator
      })

    const createMatcherCombo = function (cfg, step, selectedCondition, clause) {
      const matcher =
        cfg.matcher || selectedCondition.defaultMatcher || step.defaultMatcher
      const matcherValue =
        cfg.matcherValue || selectedCondition.defaultValue || step.defaultValue
      const valueType =
        cfg.valueType || selectedCondition.defaultValueType || step.defaultValueType

      // a matcher combo comes with an operator followed by a matcher
      return new MatcherCombo({
        listStores: cfg.listStores,
        negated: cfg.negated,
        valueType,

        // the matcher and its value can be configured on different levels
        // 1. right from cfg (which will apply it for all subsequent steps/conditions) or
        // 2. from a given step (which will apply for all subsequent conditions) or
        // 3. within a single condition
        // todo i think first level configs like cfg.xxx are obsolete and can be moved into steps
        matcherValue,
        matcher,
        defaultDsl: step.dsl,

        matchers: selectedCondition.matchers,
        matcherOptions: selectedCondition.matcherOptions,

        width: selectedCondition.width,

        operatorWidth: step.operatorWidth || cfg.defaultOperatorWidth,
        scope: clause,

        // apply post label to matcher combo if it wasn't assigned to the
        // condition combo
        postLabel: !step.conditionCombo ? step.postLabel : undefined,
        postLabelWidth: !step.conditionCombo ? step.postLabelWidth : undefined
      })
    }

    const populateMatcherCombo = function (steps, step, conditionId, force) {
      // only repopulate when condition has matchers. there are cases where it hasn't.
      if (step.matcherCombo) {
        const matchers = getMatchersByConditionId(steps, conditionId)

        step.matcherCombo.regenerateStore(matchers, force)
      }
    }

    const buildSteps = function (cfg) {
      const steps = cfg.steps || []

      return steps.map((step, stepNumber) => {
        if (step.conditions) {
          // first sort conditions within steps and flatten them into one array
          step.conditions = flattenConditions(correctAndSortConditions(step, stepNumber))

          const stepStore = createStepStore(step)

          // each step can have 1 ... n conditions.
          // then, each condition shares the same store and can have one or two combos.
          // whenever the condition combo changes,
          // we will fetch new matchers and repopulate the latter combo.

          const selectedCondition = getSelectedCondition(cfg, step)

          // only add condition combo when there are more than one condition because
          // if it is only one, then it is just static, not interactive
          if ((step.conditions != null ? step.conditions.length : undefined) > 1) {
            let conditionComboValue
            if (selectedCondition) {
              conditionComboValue = selectedCondition.value || selectedCondition.name
            }

            step.conditionCombo = createConditionCombo(
              cfg,
              stepStore,
              step,
              conditionComboValue
            )
          } else if ((step.conditions && step.conditions.length) === 1) {
            if (selectedCondition != null ? selectedCondition.label : undefined) {
              step.label = selectedCondition.label
            }
          }

          if (selectedCondition != null ? selectedCondition.matchers : undefined) {
            // a matcher combo comes with an operator followed by a matcher
            step.matcherCombo = createMatcherCombo(cfg, step, selectedCondition, this)
          }
        }

        return step
      })
    }

    const hideStep = function (step) {
      if (step.conditionCombo) {
        step.conditionCombo.cover()
      }

      step.matcherCombo && step.matcherCombo.cover()
    }

    const showStep = function (step) {
      if (step.conditionCombo) {
        step.conditionCombo.unCover()
      }

      step.matcherCombo && step.matcherCombo.unCover()
    }

    const getStepsAfter = (steps, step) => steps.slice(steps.indexOf(step) + 1)

    const hideStepsAfter = (steps, step) => getStepsAfter(steps, step).forEach(hideStep)

    const showStepsAfter = (steps, step) => getStepsAfter(steps, step).forEach(showStep)

    const restoreMatcherComboOnFirstSelect = function (
      steps,
      step,
      conditionId,
      anyConditionRecord,
      matcherKey
    ) {
      const anyCondition = getConditionById(steps, anyConditionRecord.get('id'))

      if (anyCondition.hideRest) {
        return hideStepsAfter(steps, step)

        // figure out carefully when to restore back to original state or not.
        // do this only when both are different conditions
      } else if (compareStrings(anyCondition.id, conditionId) !== 0) {
        const matcherToReload = getMatcherByKey(steps, matcherKey)
        const stepToRepopulate = getStepByMatcherKey(steps, matcherKey)
        const matcherConditionId = getConditionIdByMatcherKey(steps, matcherKey)

        matcherToReload.customItems =
          step.origCustomItems != null ? step.origCustomItems[matcherKey] : undefined
        return populateMatcherCombo(steps, stepToRepopulate, matcherConditionId, true)
      }
    }

    // this one populates matcher combo boxes which can hide custom items in other matcher combos
    const poplulateMatcherComboHidingCustomItems = function (
      steps,
      step,
      conditionId,
      hideParameters
    ) {
      const matcherKey = hideParameters.matcher
      const matcherToReload = getMatcherByKey(steps, matcherKey)
      const stepToRepopulate = getStepByMatcherKey(steps, matcherKey)

      // todo avoid mutability :( the code below mutates steps and matchers, ugh

      // special treatment for matchers hiding items
      // important to back them up first under origCustomItems so that they can be
      // restored in the .once() event handler few lines further down
      if (matcherToReload.customItems) {
        step.origCustomItems = step.origCustomItems || {}
        step.origCustomItems[matcherKey] = matcherToReload.customItems
        matcherToReload.customItems = null

        // this removes any selected custom item in the matcher combo that is going to be reloaded
        const item = stepToRepopulate.matcherCombo.getCustomItem()
        item && item.unselect()
      } else if (
        (step.origCustomItems != null ? step.origCustomItems[matcherKey] : undefined) !=
        null
      ) {
        matcherToReload.customItems = step.origCustomItems[matcherKey]
      }

      const matcherConditionId = getConditionIdByMatcherKey(steps, matcherKey)
      populateMatcherCombo(steps, stepToRepopulate, matcherConditionId, true)

      // .once() event which restores matcher combo back to its original state
      step.conditionCombo.on(
        'select',
        (combo, anyConditionRecord) =>
          restoreMatcherComboOnFirstSelect(
            steps,
            step,
            conditionId,
            anyConditionRecord,
            matcherKey
          ),
        this,
        { single: true }
      )
    }

    // with this, we can populate other combo boxes dynamically depending on parameters
    const populateOtherMatcherCombos = (steps, step, condition) =>
      condition.hides.forEach(function (hideParameters) {
        if (hideParameters.allCustomItems) {
          return poplulateMatcherComboHidingCustomItems(
            steps,
            step,
            condition.id,
            hideParameters
          )
        }
      })

    const populateOtherConditionCombo = function (steps, options) {
      const storeOptions = {}
      let stepToRefresh = null

      if (
        (options.hideConditions != null ? options.hideConditions.step : undefined) != null
      ) {
        stepToRefresh = getStepByName(steps, options.hideConditions.step)
        storeOptions.excludeConditions = options.hideConditions.conditions
      } else if (options.unHideConditions != null) {
        stepToRefresh = getStepByName(steps, options.unHideConditions.step)
      }

      if ((stepToRefresh != null ? stepToRefresh.conditionCombo : undefined) != null) {
        const stepStore = createStepStore(stepToRefresh, storeOptions)
        return stepToRefresh.conditionCombo.setStore(stepStore)
      }
    }

    const processConditionComboHidingOthers = function (steps, step) {
      if (step.conditionCombo.hideRest()) {
        return hideStepsAfter(steps, step)
      }
      return showStepsAfter(steps, step)
    }

    const processConditionSets = function (steps, condition) {
      if (condition && condition.sets) {
        return condition.sets.forEach((setConfig) => setMatcherType(steps, setConfig))
      }
    }

    const onConditionComboSelect = function (steps, step, conditionRecord) {
      // the order of the following code lines is crucial and must be kept

      const condition = getConditionById(steps, conditionRecord.get('id'))

      processConditionComboHidingOthers(steps, step)
      processConditionSets(steps, condition)

      // very special, circular (ugh) treatment for conditions wanting
      // to hide combo box entries for any other conditions
      if (condition.hides != null) {
        populateOtherMatcherCombos(steps, step, condition)
      }

      return populateMatcherCombo(steps, step, conditionRecord.get('id'))
    }

    const setMatcherType = function (steps, setConfig) {
      const step = getStepByMatcherKey(steps, setConfig.matcher)
      const matcherCombo = step != null && step.matcherCombo

      return (
        matcherCombo &&
        matcherCombo.setMatcherType(setConfig.matcher, setConfig.matcherType)
      )
    }

    return {
      extend: Clause,

      steps: [],

      constructor(cfg = {}) {
        const items = []

        // todo: revise some of these configurations. some are probably obsolete or can be improved
        cfg = Ext.applyIf(cfg, {
          autoHeight: true,
          autoWidth: true,
          border: false,
          cls: 'smartrules-condition smartrule',
          label: '',
          items,
          layout: 'hbox',
          padding: '0 4',

          defaults: {
            border: false,
            margins: '2'
          },

          layoutConfig: {
            padding: 0,
            align: 'top'
          }
        })

        this.joinWord = new JoinWord()

        items.push(this.joinWord)

        this.steps = buildSteps(cfg, items)

        // now, add event handling and add ui elements for each step
        this.steps.forEach((step) => {
          if (step.label) {
            items.push({
              xtype: 'label',
              margins: '7 4',
              text: step.label
            })
          }
          if (step.conditionCombo) {
            this.initializeConditionEvents(cfg, step)
            items.push(step.conditionCombo)
          }

          if (step.matcherCombo) {
            this.initializeMatcherEvents(step)
            return items.push(step.matcherCombo)
          }
        })

        items.push(new DraggingButton({ width: 40, clause: this }), new DeleteButton())

        this.callParent([cfg])
        this.on('render', this.setupEvents, this, { single: true })
      },

      // initialize selected condition in this step accordingly to its parameters.
      // most of it is also set during the 'select' event of its combo box below
      initializeConditionEvents(cfg, step) {
        const selectedCondition = getSelectedCondition(cfg, step)

        // order is crucial here, leave this one here before anything else below
        processConditionSets(this.steps, selectedCondition)

        if (step.conditionCombo.hideRest()) {
          hideStepsAfter(this.steps, step)
        } else if (selectedCondition?.hides != null) {
          populateOtherMatcherCombos(this.steps, step, selectedCondition)
        }

        return step.conditionCombo.on('select', (combo, conditionRecord) => {
          onConditionComboSelect(this.steps, step, conditionRecord)
          this.doLayout()
        })
      },

      initializeMatcherEvents(step) {
        const hideConditions = step.matcherCombo.getHideConditions()

        // upon initialization, already make sure that certain condition items get hidden
        if (hideConditions) {
          populateOtherConditionCombo(this.steps, hideConditions)
        }

        return step.matcherCombo.on('refreshConditions', (options) => {
          return populateOtherConditionCombo(this.steps, options)
        })
      },

      // here we build the source code for the condition clause
      toDsl() {
        const dsl = this.steps.reduce(
          (memo, step) => {
            const stepDsl = stepToDsl(this.steps, step)

            if (stepDsl.length > 0) {
              if (memo.code.length > 0) {
                memo.code += ' '
              }

              memo.code += stepDsl
            }

            return memo
          },
          { code: '' }
        )

        return dsl.code
      },

      getMatcherComboByStepName(stepName) {
        return getStepByName(this.steps, stepName).matcherCombo
      },

      removeSelf(noEvent) {
        const group = this.findParentBy((n) => n instanceof ConditionGroup)
        const smartrule = group.findParentBy((n) => n instanceof SmartRule)

        this.steps = []
        this.destroy()

        if (!noEvent && smartrule) {
          if (group.countClauseTreeMembers() === 0) {
            group.dndHint.show()
          }
          smartrule.doLayout()
          smartrule.fireEvent('smartrules-drop')
          smartrule.markDirty()
        }
      },

      setJoinWord(word) {
        return this.joinWord.update(word)
      },

      isValid() {
        const items = this.findByType(Ext.form.Field)
        return items.every(function (item) {
          // do not validate the hidden ones, otherwise save button won't get enabled
          // (extjs skips validation for the disabled ones only at the moment)
          if (item.isVisible()) {
            return item.validate()
          }
          // the hidden ones are always valid
          return true
        })
      },

      setupEvents() {
        return this.getEl().hover(
          function () {
            if (!this.eventsSuspended) {
              this.addClass('hover')
            }
          },
          function () {
            if (!this.eventsSuspended) {
              this.removeClass('hover')
            }
          },
          this
        )
      }
    }
  })()
)

module.exports = ConditionClause
