import React, { Component } from 'react'

import Grid from 'components/Grid'
import Detail from 'mail/details/views/Panel'
import SearchForm from 'mail/search/form/views/Form'
import ReleaseConfirmation from 'mail/search/release/views/Confirmation'
import ReleasePrompt from 'mail/search/release/views/Prompt'
import Rows from 'mail/search/views/Rows'
import Toolbar from 'mail/search/views/Toolbar'
import PropTypes from 'prop-types'
import t from 'translate'

// TODO has grown, bloated with bad state/event handling - rewrite in redux

const BOOTSTRAP_PADDING = 5
const TOOLBAR_MIN_HEIGHT = 95 + 2 * BOOTSTRAP_PADDING

class Search extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
    onDateRangeShow: PropTypes.func.isRequired,
    onDateRangeHide: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onTimeZoneNameChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onAttachmentPanelToggle: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDirectionChange: PropTypes.func,
    direction: PropTypes.string,

    rows: PropTypes.array,

    // note that we have columns defined in app state under e.g. quarantine/search/db.es or
    // archiving/views/Archiving.es which have so called "custom properties" of columns
    // such as width which can change over time
    // ... whereas other column props that never change are defined under
    // mail/search/views/Rows.es like flex grow or cell alignment.
    //
    // both custom and default columns get merged in that Rows.es component
    //
    // that said, if you add a new default column in Rows.es but do not add
    // it in the app state, it won't show up. must be in both.
    //
    // todo this is too much implementation detail. in near future think of better solutions
    // to give consumers (archiving + quarantine) full control of columns. we didn't change
    // that in SCL-2709 because of not enough time.
    columns: PropTypes.object,

    // Download features
    onDownload: PropTypes.func,

    // Release features
    onRelease: PropTypes.func,
    onOpenConfirmingRelease: PropTypes.func,
    onCloseConfirmingRelease: PropTypes.func,
    confirmingRelease: PropTypes.bool,

    releaseSize: PropTypes.number,
    releaseSuccessful: PropTypes.number,
    releaseSkipped: PropTypes.number,
    releaseFailed: PropTypes.number,

    onRecipientsValidityChange: PropTypes.func,
    recipientsValid: PropTypes.bool,
    originalRecipients: PropTypes.arrayOf(PropTypes.string),

    onSourceHide: PropTypes.func,
    onViewSource: PropTypes.func.isRequired,
    onHighlightingChange: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
    query: PropTypes.string,
    resultsQuery: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    fromDate: PropTypes.instanceOf(Date),
    thruDate: PropTypes.instanceOf(Date),
    mailToView: PropTypes.object,
    viewing: PropTypes.bool,
    preview: PropTypes.object,
    releasable: PropTypes.bool,
    released: PropTypes.bool,
    source: PropTypes.string,
    attachmentPanelExpanded: PropTypes.bool,
    enableHighlighting: PropTypes.bool,
    timeZoneName: PropTypes.string,
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    width: PropTypes.number,
    height: PropTypes.number,
    authorized: PropTypes.bool,
    loading: PropTypes.bool,
    currentResultNumber: PropTypes.number,
    hasNext: PropTypes.bool,
    hasPrev: PropTypes.bool,
    showNoResults: PropTypes.bool,
    showResults: PropTypes.bool,
    endReached: PropTypes.bool,
    scrollToIndex: PropTypes.number,
    firstRowNumber: PropTypes.number,
    lastRowNumber: PropTypes.number,
    selectedCount: PropTypes.number,
    total: PropTypes.number,
    setColumnWidth: PropTypes.func.isRequired,
    sourceAvailable: PropTypes.bool,
    downloadable: PropTypes.bool
  }

  calcWidth() {
    return this.props.width - BOOTSTRAP_PADDING * 3
  }

  calcHeight() {
    return this.props.height - TOOLBAR_MIN_HEIGHT - BOOTSTRAP_PADDING * 4
  }

  componentDidMount() {
    this.props.onMount && this.props.onMount()
  }

  componentWillUnmount() {
    this.props.onUnmount && this.props.onUnmount()
  }

  renderDetail() {
    return (
      <Detail
        loading={this.props.loading}
        mail={this.props.mailToView}
        preview={this.props.preview}
        attachmentPanelExpanded={this.props.attachmentPanelExpanded}
        onAttachmentPanelToggle={this.props.onAttachmentPanelToggle}
        resultsQuery={this.props.resultsQuery}
        availableKeywords={this.props.availableKeywords}
        enableHighlighting={this.props.enableHighlighting}
        authorized={this.props.authorized}
        onBack={this.props.onBack}
        onSingleMessageAuthorization={this.props.onSingleMessageAuthorization}
        onSessionAuthorization={this.props.onSessionAuthorization}
      />
    )
  }

  renderRows() {
    return (
      <Rows
        width={this.calcWidth()}
        maxHeight={this.calcHeight()}
        resultsQuery={this.props.resultsQuery}
        availableKeywords={this.props.availableKeywords}
        enableHighlighting={this.props.enableHighlighting}
        onSelect={this.props.onSelect}
        onRowClick={this.props.onRowClick}
        setColumnWidth={this.props.setColumnWidth}
        scrollToIndex={this.props.scrollToIndex}
        firstRowNumber={this.props.firstRowNumber}
        rows={this.props.rows}
        columns={this.props.columns}
        loading={this.props.loading}
      />
    )
  }

  renderNoResults() {
    return <div className='no-results'>{t('No results')}</div>
  }

  renderReleaseConfirmation() {
    const props = {
      onSubmit: this.props.onRelease,
      onCancel: this.props.onCloseConfirmingRelease,
      disabled: this.props.loading,
      released: this.props.released
    }

    if (this.props.viewing || this.props.selectedCount === 1) {
      return (
        <ReleasePrompt
          {...props}
          onRecipientsValidityChange={this.props.onRecipientsValidityChange}
          recipientsValid={this.props.recipientsValid}
          originalRecipients={this.props.originalRecipients}
        />
      )
    }

    return (
      <ReleaseConfirmation
        {...props}
        releaseSize={this.props.releaseSize}
        releaseSuccessful={this.props.releaseSuccessful}
        releaseSkipped={this.props.releaseSkipped}
        releaseFailed={this.props.releaseFailed}
      />
    )
  }

  render() {
    return (
      <Grid className='mail-search'>
        <SearchForm
          onDirectionChange={this.props.onDirectionChange}
          direction={this.props.direction}
          availableKeywords={this.props.availableKeywords}
          loading={this.props.loading}
          timeZoneName={this.props.timeZoneName}
          query={this.props.query}
          onSearch={this.props.onSearch}
          onDateRangeChange={this.props.onDateRangeChange}
          onDateRangeShow={this.props.onDateRangeShow}
          onDateRangeHide={this.props.onDateRangeHide}
          onQueryChange={this.props.onQueryChange}
          onTimeZoneNameChange={this.props.onTimeZoneNameChange}
          startDate={this.props.startDate}
          fromDate={this.props.fromDate}
          thruDate={this.props.thruDate}
        />
        <Toolbar
          source={this.props.source}
          onSourceHide={this.props.onSourceHide}
          viewing={this.props.viewing}
          releasable={this.props.releasable}
          currentResultNumber={this.props.currentResultNumber}
          loading={this.props.loading}
          enableHighlighting={this.props.enableHighlighting}
          onBack={this.props.onBack}
          onPrev={this.props.onPrev}
          onNext={this.props.onNext}
          downloadable={this.props.downloadable}
          onDownload={this.props.onDownload}
          onOpenConfirmingRelease={this.props.onOpenConfirmingRelease}
          onViewSource={this.props.onViewSource}
          onHighlightingChange={this.props.onHighlightingChange}
          endReached={this.props.endReached}
          firstRowNumber={this.props.firstRowNumber}
          hasNext={this.props.hasNext}
          hasPrev={this.props.hasPrev}
          lastRowNumber={this.props.lastRowNumber}
          total={this.props.total}
          sourceAvailable={this.props.sourceAvailable}
        />
        {this.props.confirmingRelease && this.renderReleaseConfirmation()}
        {this.props.viewing && this.props.mailToView && this.renderDetail()}
        {this.props.showResults && this.renderRows()}
        {this.props.showNoResults && this.renderNoResults()}
      </Grid>
    )
  }
}

export default Search
