const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const SupportRecord = require('admin/data/SupportRecord')

const SupportStore = Ext.define(null, {
  extend: ResourceStore,

  baseUrl: '/api/system',
  resourceName: 'support',

  record: SupportRecord
})

module.exports = SupportStore
