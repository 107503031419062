export default function (urlStr = window.location.href, opts = {}) {
  const url = new URL(urlStr, window.location.href)

  let state, dev

  if (opts.keepState && url.searchParams.has('state')) {
    state = url.searchParams.get('state')
  }

  if (opts.keepDev && url.searchParams.has('dev')) {
    dev = url.searchParams.get('dev')
  }

  url.searchParams.keys().forEach((key) => url.searchParams.delete(key))

  if (opts.keepState && state) {
    url.searchParams.set('state', state)
  }

  if (opts.keepDev && dev) {
    url.searchParams.set('dev', dev)
  }

  return url.toString()
}
