export default {
  uri: null,
  global: null,
  originalGlobal: null,
  types: null,
  originalTypes: null,
  membersChanged: false,
  loading: false,
  mutating: false,
  globalLink: null,
  enableLink: null
}
