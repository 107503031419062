import React, { Component } from 'react'

import { refresh as refreshMailboxes } from 'mailboxes/actions'
import { refreshButtonDisabled, uri as mailboxesUri } from 'mailboxes/subs'
import { refresh as refreshCatalogue } from 'mailhosting/catalogue/actions'
import { uri as catalogueUri } from 'mailhosting/catalogue/subs'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import t from 'translate'

class Refresh extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  refresh = () => {
    this.props.refreshMailboxes(this.props.mailboxesUri)
    this.props.refreshCatalogue(this.props.catalogueUri)
  }

  render() {
    return (
      <Button disabled={this.props.refreshButtonDisabled} onClick={this.refresh}>
        {t('Refresh')}
      </Button>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  mailboxesUri: mailboxesUri(db, companyKey),
  catalogueUri: catalogueUri(db, companyKey),
  refreshButtonDisabled: refreshButtonDisabled(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  refreshMailboxes: (catalogueUri) => {
    dispatch(refreshMailboxes(companyKey, catalogueUri))
  },
  refreshCatalogue: (catalogueUri) => {
    dispatch(refreshCatalogue(companyKey, catalogueUri))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Refresh)
