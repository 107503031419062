const Ext = require('ext')
const t = require('translate')
const ResourceListPanel = require('admin/view/ResourceListPanel')
const requestPromise = require('admin/util/Promise').default
const logger = require('system/logger').default

const SharedServiceListPanel = Ext.define(null, {
  extend: ResourceListPanel,

  autoExpandColumn: 'name',

  viewConfig: {
    // we dont want to see that little red triangle indicating dirty cells
    markDirty: false
  },

  module() {
    return require('admin/business/SharedServiceResource')
  },

  initComponent() {
    this.on('rowclick', this.handleRowClick)
    this.store.on('update', this.onUpdate, this)

    this.bbar = this.createBottomToolbar()

    this.callParent()
  },

  onDestroy() {
    this.store.un('update', this.onUpdate, this)
    this.callParent()
  },

  createColumnModel() {
    return new Ext.grid.ColumnModel([
      {
        dataIndex: 'enabled',
        header: t('Apply'),
        id: 'enabled',
        noHtmlEncode: true,
        // because we want to deal it ourselves with handleRowClick() below
        doNotProcessEvent: true,
        xtype: 'checkcolumn',
        width: 60,
        align: 'center'
      },
      {
        dataIndex: 'name',
        header: t('Name'),
        id: 'name',
        width: 240,
        sortable: true
      }
    ])
  },

  rowOutOfBounds(rowIndex) {
    return rowIndex < 0 || rowIndex > this.store.getCount() - 1
  },

  handleRowClick(grid, rowIndex) {
    if (this.rowOutOfBounds(rowIndex)) {
      return
    }

    const record = this.store.getAt(rowIndex)
    return record.set('enabled', !record.get('enabled'))
  },

  isDirty() {
    const modifiedRecords = this.store.getModifiedRecords()
    return modifiedRecords.length && modifiedRecords.some((record) => record.dirty)
  },

  onUpdate() {
    const dirty = this.isDirty()

    this.saveButton.setDisabled(!dirty)
    return this.resetButton.setDisabled(!dirty)
  },

  save() {
    const { url } = this.getStore()
    const params = this.store.getRange().map((r) => ({
      name: r.data.name,
      enabled: r.data.enabled
    }))

    Ext.MessageBox.wait(t('Saving...'))

    return requestPromise({
      method: 'PUT',
      url,
      disableCaching: false,
      params: Ext.util.JSON.encode(params),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        this.store.commitChanges()
        return Ext.MessageBox.hide()
      })
      .catch(function (err) {
        Ext.MessageBox.hide()
        return logger.error(err)
      })
      .done()
  },

  createBottomToolbar() {
    return new Ext.Toolbar({
      style: {
        padding: '10px'
      },
      items: [
        (this.saveButton = new Ext.Button({
          text: t('Save'),
          disabled: true,
          cls: 'primary',
          handler: () => {
            return this.save()
          }
        })),
        (this.resetButton = new Ext.Button({
          text: t('Reset'),
          disabled: true,
          handler: this.refreshStore.bind(this)
        }))
      ]
    })
  }
})

module.exports = SharedServiceListPanel
