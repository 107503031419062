const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const ResellerAgreementRecord = require('admin/data/ResellerAgreementRecord')
const ResellerAgreementResource = require('admin/business/ResellerAgreementResource')
const User = require('admin/core/User')

const ResellerAgreementStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'agreements',
  groupField: 'status',
  sortInfo: {
    field: 'fromDate',
    direction: 'DESC'
  },
  hasMultiSort: true,
  multiSortInfo: {
    direction: 'ASC',
    sorters: [
      { field: 'status', direction: 'ASC' },
      { field: 'fromDate', direction: 'DESC' }
    ]
  },
  baseParams: {
    maxProductCount: 2
  },

  record: ResellerAgreementRecord,

  module() {
    return ResellerAgreementResource
  },

  constructor() {
    this.callParent(arguments)

    return this.on('add', function () {
      // Adding an agreement probably affects product tabs so reload the parent record.
      if (this.parentRecord != null) {
        this.parentRecord.fetch()
      }

      // New record is incomplete so just reload the whole store.
      return this.reload()
    })
  },

  canCreate() {
    return this.parentRecord.get('id') !== User.getRootId()
  }
})

module.exports = ResellerAgreementStore
