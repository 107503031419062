import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import t from 'translate'
import Dialog from 'components/dialog/Confirmation'

import {
  stopConfirmingDeletion,
  deleteQuarantineSet
} from 'quarantine/quarantine_sets/actions'

import {
  loading,
  uri,
  selectedQuarantineSetName,
  selectedQuarantineSetLink
} from 'quarantine/quarantine_sets/subs'

class Delete extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.deleteQuarantineSet(this.props.uri, this.props.selectedQuarantineSetLink)
  }

  render() {
    const title = t('Delete Quarantine Policy {0}?', [
      this.props.selectedQuarantineSetName
    ])

    const warning = t('Are you sure you want to delete Quarantine Policy {0}?', [
      this.props.selectedQuarantineSetName
    ])

    return (
      <Dialog
        icon='exclamation'
        modalTitle={title}
        headerText={warning}
        submitButtonText={t('Delete')}
        cancelDisabled={this.props.loading}
        submitDisabled={this.props.loading}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  loading: loading(db, props.companyKey),
  selectedQuarantineSetName: selectedQuarantineSetName(db, props.companyKey),
  selectedQuarantineSetLink: selectedQuarantineSetLink(db, props.companyKey),
  uri: uri(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  onCancel: () => dispatch(stopConfirmingDeletion(initialProps.companyKey)),
  deleteQuarantineSet: (uri, selectedLink) => {
    dispatch(deleteQuarantineSet(initialProps.companyKey, uri, selectedLink))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(Delete)
