const Ext = require('ext')
const t = require('translate')
const logger = require('system/logger').default
const confirmDiscardChanges = require('admin/util/confirmDiscardChanges')
const TextRow = require('admin/util/TextRow')
const LimitedTextArea = require('admin/component/form/LimitedTextArea')
const HtmlEditor = require('admin/component/form/HtmlEditor')
const isAscii = require('is-ascii')

// see https://tools.ietf.org/html/rfc5322#section-2.1.1
const PLAIN_TEXT_MAX_LENGTH = 78
const HTML_TEXT_MAX_LENGTH = 998

const SignatureFormPanel = Ext.define(null, {
  extend: Ext.form.FormPanel,

  onSaveClick() {
    if (!isAscii(this.textEditor.getValue()) || !isAscii(this.htmlEditor.getValue())) {
      return this.displayUnicodeConfirmation()
    }
    return this.doSave()
  },

  displayUnicodeConfirmation() {
    return Ext.Msg.show({
      title: t('Warning'),
      msg: t(
        'This footer may not be applied to some emails because it contains some non-ASCII characters.'
      ),
      buttons: {
        ok: t('Continue'),
        cancel: t('Cancel')
      },
      fn(btn) {
        if (btn === 'ok') {
          return this.doSave()
        }
      },
      scope: this,
      icon: Ext.Msg.WARNING,
      minWidth: Ext.Msg.minWidth
    })
  },

  doSave() {
    const record = this.signatures.getSelectionModel().getSelected()
    record.set('text', this.textEditor.getValue())
    if (this.htmlEditor.isDirty()) {
      record.set('html', this.htmlEditor.getValue())
    }

    Ext.MessageBox.wait(t('Saving...'))
    return record.save(null, {
      success() {
        return record.store.reload()
      },
      error(record, response, options, err) {
        return logger.error(err)
      },
      complete() {
        return Ext.MessageBox.hide()
      }
    })
  },

  constructor(cfg = {}) {
    this.resetButton = new Ext.Button({
      disabled: true,
      text: t('Reset'),
      scope: this,
      handler() {
        const record = this.signatures.getSelectionModel().getSelected()
        this.textEditor.setValue(record.get('text'))
        return this.htmlEditor.setValue(record.get('html'))
      }
    })

    this.saveButton = new Ext.Button({
      disabled: true,
      formBind: true,
      handler: this.onSaveClick,
      scope: this,
      text: t('Save'),
      cls: 'primary'
    })

    this.module = cfg.module

    const updateLineCharCount = () => {
      const coordinates = this.textEditor.getCoordinates()

      if (coordinates) {
        return this.plainTextPanel.setTitle(this.getPlainTextPanelTitle(coordinates))
      }
    }

    const maxTextAreaWidth = TextRow.computeWidth(PLAIN_TEXT_MAX_LENGTH, [
      'x-form-textarea',
      'x-form-field',
      'has-line-max-length'
    ])

    const maxLine = new Ext.BoxComponent({
      width: maxTextAreaWidth,
      cls: 'textarea-max-line'
    })

    this.textEditor = new LimitedTextArea({
      lineMaxLength: PLAIN_TEXT_MAX_LENGTH,
      validator() {
        updateLineCharCount()

        if (this.isDirty() && this.isOverflown()) {
          return t('Max line width is {0} characters', [this.lineMaxLength])
        }
        return true
      }
    })

    this.htmlEditor = new HtmlEditor({
      listeners: {
        render() {
          return (this.originalValue = this.getValue())
        }
      },
      // all lines shorter than 999 characters
      validateValue(v) {
        if (v == null) {
          v = ''
        }
        return v.split(/\s/).every(function (line) {
          if (line == null) {
            line = ''
          }
          return line.length <= HTML_TEXT_MAX_LENGTH
        })
      },
      validate() {
        if (this.disabled) {
          this.clearInvalid()
          return true
        }
        if (this.validateValue(this.processValue(this.getRawValue()))) {
          Ext.fly(this.iframe).removeClass('x-form-invalid')
          return true
        }
        Ext.fly(this.iframe).addClass('x-form-invalid')
        return false
      }
    })

    this.htmlEditor.on('beforesync', (editor) => editor.validate())

    this.plainTextPanel = new Ext.Panel({
      title: this.getPlainTextPanelTitle(),
      flex: 0.8,
      layout: 'fit',
      border: false,
      bodyCfg: {
        autoScroll: false,
        style: {
          position: 'relative'
        }
      },
      items: [this.textEditor, maxLine]
    })

    this.htmlPanel = new Ext.Panel({
      title: t('HTML Template'),
      flex: 1,
      layout: 'fit',
      border: false,
      items: this.htmlEditor
    })

    cfg = Ext.applyIf(cfg, {
      layout: 'vbox',
      loadMask: true,
      monitorValid: true,
      trackResetOnLoad: true,
      layoutConfig: {
        align: 'stretch',
        pack: 'start'
      },
      items: [this.plainTextPanel, this.htmlPanel],
      buttonAlign: 'center',
      buttons: [this.resetButton, this.saveButton]
    })

    this.callParent([cfg])
    this.on(
      'clientvalidation',
      function (form, valid) {
        const isDirty = form.getForm().isDirty()
        if (isDirty) {
          form.addClass('smx-dirty')
        } else {
          form.removeClass('smx-dirty')
        }
        valid = valid && isDirty
        form.resetButton.setDisabled(!isDirty)
        return form.saveButton.setDisabled(!valid)
      },
      this
    )

    this.signatures.getSelectionModel().on(
      'selectionchange',
      function (selectionModel) {
        const selected = selectionModel.getSelected()
        if (!selected || selected.get('id') === -1) {
          this.htmlEditor.setValue('')
          this.textEditor.setValue('')
          return this.toggleSignaturePanel(true)
        }
        const html = selected.get('html')
        const text = selected.get('text')
        this.htmlEditor.setValue(html)
        this.htmlEditor.originalValue = this.htmlEditor.getValue()
        this.textEditor.setValue(text)
        this.textEditor.originalValue = this.textEditor.getValue()
        return this.toggleSignaturePanel(false)
      },
      this
    )

    return this.signatures.getStore().on(
      'datachanged',
      function () {
        const model = this.getSelectionModel()
        return model.fireEvent('selectionchange', model)
      },
      this.signatures
    )
  },

  getPlainTextPanelTitle(coordinates) {
    const title = 'Plain Text Template'

    if (coordinates) {
      const [line, column] = Array.from(coordinates)
      // + 1 because column number is one above length,
      // they start with 1, so will end with 79 when length is 78
      const titleParams = [title, line, column, PLAIN_TEXT_MAX_LENGTH + 1]
      return t(
        '<span class="right-hint">Line {1}, Column {2} (of {3})</span> {0}',
        titleParams
      )
    }
    return t(title)
  },

  isDirty() {
    return this.form != null ? this.form.isDirty() : undefined
  },

  toggleSignaturePanel(forceDisable) {
    const els = [this.saveButton, this.resetButton, this.textEditor, this.htmlEditor]
    const operation = !forceDisable && this.module.canSave() ? 'enable' : 'disable'

    return els.forEach((el) => el[operation]())
  },

  toggleButtons(flag) {
    this.saveButton.setDisabled(!flag)
    return this.resetButton.setDisabled(!flag)
  },

  preventClose(retry) {
    if (!this.allowClose && this.isDirty()) {
      confirmDiscardChanges(() => {
        this.allowClose = true
        return retry()
      })
      return true
    }
  }
})

module.exports = SignatureFormPanel
