const Ext = require('ext')
const t = require('translate')

const EventDetailRecipientsToolbar = Ext.define(null, {
  extend: Ext.Toolbar,

  border: true,

  constructor(cfg) {
    this.addClass('recipients-toolbar')

    this.items = [
      `<b>${t('Recipients')}</b>`,
      '->',
      new Ext.Button({
        text: t('Expand All'),
        handler: cfg.expandAll
      }),
      ' ',
      new Ext.Button({
        text: t('Collapse All'),
        handler: cfg.collapseAll
      })
    ]

    this.callParent()
  }
})

module.exports = EventDetailRecipientsToolbar
