const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const InvoiceDetailPanel = require('admin/view/InvoiceDetailPanel')
const InvoiceNewPanel = require('admin/view/InvoiceNewPanel')
const InvoiceListPanel = require('admin/view/InvoiceListPanel')
const InvoiceRecord = require('admin/data/InvoiceRecord')
const InvoiceRunResource = require('admin/business/InvoiceRunResource')

const InvoiceResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Invoice',
  translations: {
    title: t('Invoice'),
    plural: t('Invoices'),
    new: t('New Invoice'),
    open: t('Open Invoice'),
    list: t('List Invoice')
  },
  name: t('Invoice'),
  code: 'INVOICE',
  resourceName: 'invoices',
  searchable: true,

  operations: {
    create: false,
    open: true,
    remove: false,
    list: true,
    save: false
  },

  detailPanel: InvoiceDetailPanel,
  newPanel: InvoiceNewPanel,
  listPanel: InvoiceListPanel,

  record: InvoiceRecord,

  getParentResources() {
    return [InvoiceRunResource]
  }
})

module.exports = InvoiceResource
