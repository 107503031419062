import { MAILHOSTING_DOMAIN_REL } from 'product/resources'
import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('mailhosting')

export const domainDb = createSelector(
  company,
  (company) => company && company[MAILHOSTING_DOMAIN_REL]
)

const list = createSelector(domainDb, (domainDb) => domainDb?.list)

export const loaded = createSelector(list, (list) => !!list)

export const loading = createSelector(domainDb, (domainDb) => Boolean(domainDb?.loading))

export const empty = createSelector(loaded, list, (loaded, list) =>
  Boolean(loaded && list.length < 1)
)

export const names = createSelector(list, (list) => {
  if (!list) return
  return list.map((domain) => domain.name)
})

export const uri = createSelector(
  domainDb,
  // TODO have backend return the link to the mailhost catalogue see SSV-3537
  (domainDb) => domainDb?.customerUrl + '/mailhost/hostingdomains'
)
