import Ext from 'ext'
import t from 'translate'

const Unavailable = Ext.define(null, {
  extend: Ext.Viewport,

  layout: 'fit',

  onRetry: null,

  retryPanel: null,

  initComponent: function () {
    this.retryPanel = new Ext.Panel({
      border: false,
      id: 'retry-panel',
      items: [
        {
          bodyCssClass: 'logo-panel',
          border: false,
          height: 195,
          xtype: 'panel'
        },
        {
          border: false,
          html: '<h1>Login is temporarily unavailable</h1>'
        },
        new Ext.Button({
          cls: 'primary retry-button',
          text: t('Try again'),
          width: 100,
          handler: () => {
            window.location.reload()
          }
        }),
        {
          border: false,
          html: '<p>If this persists, please contact Technical Support.</p>'
        }
      ]
    })

    this.items = new Ext.Panel({
      autoScroll: true,
      id: 'center-login-unavailable',
      border: false,
      items: [this.retryPanel]
    })

    this.on('resize', this.center, this)

    this.getParentInstance().initComponent.call(this)
  },

  getParentInstance: function () {
    return Unavailable.superclass
  },

  afterRender: function () {
    this.getParentInstance().afterRender.call(this)
    this.center()
  },

  center: function () {
    const el = this.retryPanel.getEl()
    if (el) {
      el.alignTo(document, 'c-c', [0, -100])
    }
  },

  destroy: function () {
    this.getEl().removeClass(this.cls)
    this.getParentInstance().destroy.call(this)
  }
})

export default Unavailable
