const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const CatalogueRecord = require('admin/data/CatalogueRecord')

const CatalogueResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.Catalogue',
  translations: {
    title: t('Catalogue'),
    new: t('New Catalogue'),
    open: t('Open Catalogue'),
    list: t('List Catalogues')
  },
  name: t('Catalogue'),
  code: 'PRICE_COMPONENT',
  resourceName: 'catalogue',

  record: CatalogueRecord
})

module.exports = CatalogueResource
