const Ext = require('ext')

// Overrides the search field trigger configuration to be a div tag instead of an
// img tag so that font-based icons can be used.
const SearchField = Ext.define(null, {
  extend: Ext.form.TriggerField,

  initComponent() {
    Ext.apply(this, {
      enableKeyEvents: true,
      triggerConfig: {
        tag: 'div',
        cls: 'x-form-trigger x-form-search-trigger primary x-btn'
      }
    })

    this.callParent(arguments)
  }
})

module.exports = SearchField
