const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const DateRangeFieldSet = require('admin/component/form/DateRangeFieldSet')
const ComboBox = require('admin/component/form/ComboBox')
const User = require('admin/core/User')
const CurrencyAndPriceField = require('admin/component/form/CurrencyAndPriceField')
const config = require('theming/config')
const escape = require('html-escaper').escape

const AgreementDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'agreement-detail-panel',
  closeOnSave: true,

  module() {
    return require('admin/business/AgreementResource')
  },

  getFormPanel(cfg) {
    const customer = cfg.record.store.parentRecord

    // We don't want the cached store
    const CatalogueStore = require('admin/data/CatalogueStore')
    const catalogueStore = new CatalogueStore({
      parentRecord: customer
    })

    catalogueStore.on('load', () =>
      require('admin/business/AgreementResource').filterCatalogue(catalogueStore)
    )
    cfg.catalogueStore = catalogueStore
    catalogueStore.load()

    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg)
    })
  },

  getDetailItems(cfg) {
    const quantity = this.module.createQuantityField()

    const dateRange = new DateRangeFieldSet({
      minValue: cfg.record?.get('fromDate'),
      // Names need to be mapped to record keys
      from: {
        name: 'fromDate',
        allowBlank: false
      },
      thru: {
        name: 'thruDate',
        allowBlank: true
      },
      both: {
        fieldWidth: config.panel.fieldWidth
      }
    })

    const billTo = new Ext.form.Hidden({
      name: 'billTo'
    })

    const billableProduct = new ComboBox({
      fieldLabel: t('Billable Product'),
      triggerAction: 'all',
      lastQuery: '',
      // For read only version use display value from record because
      // it prevents delayed rendering (catalogueStore loads async)
      readOnlyName: 'productName',
      hiddenName: 'product',
      valueField: 'code',
      displayField: 'name',
      store: cfg.catalogueStore,
      editable: false,
      allowBlank: false,
      lazyInit: false,
      listWidth: 430,
      listeners: {
        select(el, product) {
          billTo.setValue(product.get('billToId'))
          return changedTip.showBy(el.id, 'tl-tr')
        }
      },
      /* eslint-disable no-multi-str */
      tpl: new Ext.XTemplate(
        '\
<div style="padding: 2px; font-weight: bold"> \
<div style="float: left; width: 250px;">{[this.escape(this.t("Product"))]}</div> \
<div style="float: left; width: 150px;">{[this.escape(this.t("Billed To"))]}</div> \
</div> \
<div style="clear: both;"</div> \
<tpl for="."> \
<div class="x-combo-list-item"> \
<div style="float: left; width: 250px;">{[this.escape(values["name"])]}</div> \
<div style="float: left; width: 150px;">{[this.escape(values["billToName"])]}</div> \
</div> \
</tpl>\
',
        { escape, t }
      )
    })
    const changedTip = new Ext.ToolTip({
      anchor: 'left',
      dismissDelay: 10000,
      height: 20,
      cls: 'hint',
      html: t('The billable product has changed. Check price, quantity still correct.')
    })
    const items = [
      billTo,
      billableProduct,
      new ComboBox({
        fieldLabel: t('Product Type'),
        hiddenName: 'agreementType',
        width: config.panel.fieldWidth,
        listeners: {
          select(combo, record) {
            if (
              record.get('field1') === 'SERVICE' &&
              cfg.record.get('agreementType') === 'TRIAL' &&
              cfg.record.get('thruDate')
            ) {
              // was TRIAL and had thruDate
              return dateRange.setThruValue('')
            }
          }
        },
        store: [
          ['SERVICE', t('Service')],
          ['TRIAL', t('Trial')]
        ],
        editable: false,
        allowBlank: false,
        triggerAction: 'all'
      }),
      quantity
    ]

    if (User.hasPermission('PRICE_COMPONENT', 'GET')) {
      const currencyAndPrice = new CurrencyAndPriceField({
        currencyComboName: 'basePrice.priceCurrencyUom',
        priceAmountFieldName: 'basePrice.priceAmount'
      })
      const description = new Ext.form.TextField({
        fieldLabel: t('Price Description'),
        name: 'basePrice.description',
        allowBlank: false
      })
      items.push(currencyAndPrice, description)
    }

    items.push(dateRange)

    return items.filter(Boolean)
  }
})

module.exports = AgreementDetailPanel
