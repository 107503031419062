import Ext from 'ext'
import analytics from 'system/ga'
import logger from 'system/logger'

// TODO massive rewrite, use HTML5 history API instead
// https://developer.mozilla.org/en-US/docs/Web/API/History_API

// caches window titles by URLs for speed since ExtJS History API
// doesn't offer extra parameters to pass them around
const titles = { '': 'Home' }

const triggerPageView = (title, path) => {
  if (!title) {
    title = '(Untitled)'
    logger.warn(`No title given for path: ${path}`)
  }

  // Parameters can be any from page data, see:
  // https://github.com/DavidWells/analytics/blob/master/packages/analytics-core/src/modules/page.js#L54
  // any missing ones will be automatically derived from location or document
  analytics.page({
    title,
    path
  })
}

const afterHashRegexp = /^#?!/

const History = Ext.define(null, {
  singleton: true,

  debounces: {},
  previousState: null,

  init(openFn, hash = '') {
    // ExtJS stores token in a hidden field, yuck
    // TODO rework this
    Ext.getBody().createChild({
      tag: 'form',
      cls: 'x-hidden',
      children: [
        {
          tag: 'input',
          id: Ext.History.fieldId,
          type: 'hidden'
        },
        {
          tag: 'iframe',
          id: Ext.History.iframeId
        }
      ]
    })

    this.updateState(openFn, hash)

    Ext.History.init()

    Ext.History.on('change', () => {
      this.updateState(openFn, window.location.hash)
    })
  },

  // try to extract title from whatever has been opened.
  // can be a window or a resource
  figureTitle(hash, openFn, cb) {
    const path = hash.replace(afterHashRegexp, '')
    const target = openFn(path)

    let title = target?.title
    if (title) return cb(title)

    title = target?.getTitle()
    if (title) return cb(title)

    if (target instanceof Ext.data.Record) {
      // no data yet for a title? so let's wait until record is loaded,
      // then grab title from there
      const onSync = function () {
        title = target.getTitle()
        target.un('sync', onSync)

        cb(title)
      }

      target.on('sync', onSync)
    } else {
      logger.warn(`Unable to figure title for: ${path}`)

      // at last just use the path as a title
      cb(path)
    }
  },

  // processes desktop application state changes for
  // - triggering GA page views
  updateState(openFn, hash = '') {
    let title

    if (hash in titles) {
      // already cached
      title = titles[hash]
    }

    if (title) {
      // we already have a title, track page now
      triggerPageView(title, hash)
    }

    // already debouncing, do nothing further
    if (this.debounces[hash]) return

    if (afterHashRegexp.test(hash)) {
      this.debounces[hash] = true

      if (!title) {
        this.figureTitle(hash, openFn, (title) => {
          titles[hash] = title
          triggerPageView(title, hash)
        })
      }

      return function () {
        delete this.debounces[hash]
      }.defer(1000, this)
    }
  },

  savePreviousState() {
    this.previousState = {
      title: document.title,
      path: window.location.pathname + window.location.search + window.location.hash
    }
  },

  restorePreviousState() {
    if (this.previousState) {
      const { title, path } = this.previousState
      window.history.pushState({}, title, path)
      triggerPageView(title, path)
      this.previousState = null
    }
  },

  clearHash() {
    if (this.previousState) {
      this.restorePreviousState()
    } else {
      window.history.pushState({}, 'Home', '/') // Default state
      triggerPageView('Home', '/')
    }
  },

  replaceCurrentUrl(newUrl) {
    // replaces current URL without adding it to history entries
    window.history.replaceState({}, document.title, newUrl)
  }
})

export default History
