import { extractRootId, extractRootType } from 'url/record'

const getRecordById = function (type, id, url) {
  const RecordConstructor = (function () {
    switch (type) {
      case 'customer':
        return require('admin/data/company/CustomerRecord')
      case 'distributor':
        return require('admin/data/company/DistributorRecord')
      case 'mailrelayhosts':
        return require('admin/data/MailRelayHostRecord')
      case 'notes':
        return require('admin/data/NoteRecord')
      case 'policysets':
        return require('admin/data/PolicySetRecord')
      case 'reseller':
        return require('admin/data/company/ResellerRecord')
      case 'scrubbingdomains':
        return require('admin/data/ScrubbingDomainRecord')
      case 'subscription':
        return require('reporting/SubscriptionRecord')
      case 'templates':
        return require('smartrules/TemplateRecord')
      case 'trustedips':
        return require('admin/data/TrustedIpRecord')
      case 'user':
        return require('admin/data/UserRecord')
      case 'vendor':
        return require('admin/data/company/VendorRecord')
    }
  })()

  if (RecordConstructor) {
    return new RecordConstructor({ id, url, type })
  }

  return null
}

export const getRecordByUrl = function (url) {
  const type = extractRootType(url)
  const id = extractRootId(url)

  return getRecordById(type, id, url)
}

export const openRecordByUrl = function (url) {
  const record = getRecordByUrl(url)

  if (!record) return // 404

  if (typeof record.open === 'function') {
    record.open()
    return record
  }
}
