const Ext = require('ext')

const ReadOnlyCheckBox = Ext.define(null, {
  extend: Ext.form.TextField,

  constructor(cfg = {}) {
    cfg.readOnly = true
    if (cfg.clone) {
      Ext.applyIf(cfg, {
        helpText: cfg.clone.helpText,
        hideLabel: cfg.clone.hideLabel,
        boxLabel: cfg.clone.boxLabel,
        fieldLabel: cfg.clone.fieldLabel,
        name: cfg.clone.name,
        width: cfg.clone.width,
        height: 24
      })
    }

    cfg.data = {}
    cfg.tpl = new Ext.Template('<span class="readonly-checkbox-suffix"></span>')
    cfg.tplWriteMode = 'insertAfter'
    this.callParent([cfg])
  },

  setValue(value) {
    this.value = value
    const setState = function () {
      const el = this.getEl()
      if (el) {
        if (value) {
          el.removeClass('readonly-checkbox')
          return el.addClass('readonly-checkbox-checked')
        }
        el.removeClass('readonly-checkbox-checked')
        return el.addClass('readonly-checkbox')
      }
    }

    if (this.rendered) {
      return setState.call(this, value)
    }
    return this.on('render', setState, this, true)
  },

  getValue() {
    return this.value
  }
})

module.exports = ReadOnlyCheckBox
