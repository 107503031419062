const Ext = require('ext')
const t = require('translate')
const CompanyListPanel = require('admin/view/CompanyListPanel')
const User = require('admin/core/User')

const DistributorListPanel = Ext.define(null, {
  extend: CompanyListPanel,

  module() {
    return require('admin/business/DistributorResource')
  },

  createColumnModel() {
    let columns = []

    if (User.isTechnicalAdmin()) {
      columns.push({
        id: 'id',
        header: t('ID'),
        width: 60,
        sortable: true,
        dataIndex: 'id'
      })
    }

    columns = columns.concat([
      {
        id: 'name',
        header: t('Distributor Name'),
        width: 200,
        sortable: true,
        dataIndex: 'displayName'
      }
    ])

    return new Ext.grid.ColumnModel(columns)
  }
})

module.exports = DistributorListPanel
