import { MAILHOSTING_CATALOGUE_REL } from 'product/resources'
import createCompanySelector from 'system/selector/createCompanySelector'
import { createSelector } from '@reduxjs/toolkit'

const company = createCompanySelector('mailhosting')

export const catalogueDb = createSelector(
  company,
  (company) => company && company[MAILHOSTING_CATALOGUE_REL]
)

export const list = createSelector(catalogueDb, (catalogueDb) => catalogueDb?.list)

export const loaded = createSelector(list, (list) => !!list)

export const loading = createSelector(catalogueDb, (catalogueDb) =>
  Boolean(catalogueDb?.loading)
)

export const uri = createSelector(
  catalogueDb,
  // TODO have backend return the link to the mailhost catalogue see SSV-3537
  (catalogueDb) => catalogueDb?.customerUrl + '/mailhost/catalogue'
)
