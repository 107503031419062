const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')
const validators = require('validators').default

const ReplaceDomain = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Replace matched recipient domains'),
      noSpacer: true,
      type: 'smartrules-replace-domain',
      nestable: true
    })

    const textfield = new Ext.form.TextField({
      allowBlank: false,
      flex: 1,
      name: 'value',
      ref: '../textfield',
      value: cfg.value,
      validator: validators.domain.withMsg(t('You must enter a valid DNS Name')),
      xtype: 'textfield'
    })
    this.callParent([cfg])
    this.insert(3, {
      flex: 1,
      layout: 'hbox',
      items: textfield
    })
  },

  toDsl() {
    return String.format(
      'replace matched recipient domains with "{0}"',
      this.textfield.getValue().replace(/(["\\])/g, '\\$1')
    )
  }
})

module.exports = ReplaceDomain
