import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import {
  disableAddButton,
  disableDeleteButton,
  disableEditButton,
  disablePurgeButton,
  disableRestoreButton,
  disableSuspendAllButton,
  disableUnsuspendAllButton,
  disableUpgradeButton,
  loading,
  selectedMailboxLink,
  selectedMailboxUpgradeCatalogueLink,
  selectedMailboxesRestoreLinks,
  size,
  suspendAllLink,
  unsuspendAllLink,
  uri
} from 'mailboxes/subs'
import {
  edit,
  editProduct,
  restore,
  startConfirmingDeletion,
  startConfirmingPurge,
  startCreating,
  suspendAll,
  unsuspendAll
} from 'mailboxes/actions'

import Indicator from 'components/Indicator'
import PropTypes from 'prop-types'
import React from 'react'
import Refresh from 'mailboxes/views/toolbar/Refresh'
import User from 'admin/core/User'
import { connect } from 'react-redux'
import t from 'translate'

class Toolbar extends React.Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  isTechAdmin = User.isTechnicalAdmin()
  isVendor = User.isVendor()

  edit = () => {
    this.props.edit(this.props.selectedMailboxLink)
  }

  restore = () => {
    this.props.restore(this.props.uri, this.props.selectedMailboxesRestoreLinks)
  }

  editProduct = () => {
    this.props.editProduct(
      this.props.selectedMailboxLink,
      this.props.selectedMailboxUpgradeCatalogueLink
    )
  }

  suspendAll = () => {
    this.props.suspendAll(this.props.uri, this.props.suspendAllLink)
  }

  unsuspendAll = () => {
    this.props.unsuspendAll(this.props.uri, this.props.unsuspendAllLink)
  }

  renderUpgradeButton() {
    return (
      <Button disabled={this.props.disableUpgradeButton} onClick={this.editProduct}>
        {t('Upgrade')}
      </Button>
    )
  }

  renderPurgeButton() {
    return (
      <Button
        disabled={this.props.disablePurgeButton}
        onClick={this.props.startConfirmingPurge}
      >
        {t('Purge')}
      </Button>
    )
  }

  renderRestoreButton = () => {
    return (
      <Button disabled={this.props.disableRestoreButton} onClick={this.restore}>
        {t('Restore')}
      </Button>
    )
  }

  renderSuspendAllButton = () => {
    return (
      <Button disabled={this.props.disableSuspendAllButton} onClick={this.suspendAll}>
        {t('Suspend All')}
      </Button>
    )
  }

  renderUnsuspendAllButton = () => {
    return (
      <Button disabled={this.props.disableUnsuspendAllButton} onClick={this.unsuspendAll}>
        {t('Unsuspend All')}
      </Button>
    )
  }

  renderLeftButtonToolbar = () => {
    return (
      <ButtonToolbar className='pull-left'>
        <Button disabled={this.props.disableAddButton} onClick={this.props.startCreating}>
          {t('New')}
        </Button>
        <Button disabled={this.props.disableEditButton} onClick={this.edit}>
          {t('Edit')}
        </Button>
        {this.isTechAdmin && this.renderUpgradeButton()}
        <Button
          disabled={this.props.disableDeleteButton}
          onClick={this.props.startConfirmingDeletion}
        >
          {t('Delete')}
        </Button>
        {this.isTechAdmin && this.renderRestoreButton()}
        {this.isTechAdmin && this.isVendor && this.renderPurgeButton()}
        {this.isTechAdmin && this.renderSuspendAllButton()}
        {this.isTechAdmin && this.renderUnsuspendAllButton()}
      </ButtonToolbar>
    )
  }

  render() {
    return (
      <div className='toolbar clearfix'>
        <Row className='no-gutter'>
          <Col xs={7} className='no-gutter'>
            {this.renderLeftButtonToolbar()}
          </Col>
          <Col xs={5} className='no-gutter'>
            <div className='pull-right'>
              <Indicator
                loading={this.props.loading}
                size={this.props.size}
                noun='mailbox'
              />
              <ButtonToolbar className='right-button-toolbar'>
                <Refresh companyKey={this.props.companyKey} />
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  loading: loading(db, companyKey),
  size: size(db, companyKey),
  uri: uri(db, companyKey),
  disableAddButton: disableAddButton(db, companyKey),
  disableEditButton: disableEditButton(db, companyKey),
  disableDeleteButton: disableDeleteButton(db, companyKey),
  disableUnsuspendAllButton: disableUnsuspendAllButton(db, companyKey),
  disableSuspendAllButton: disableSuspendAllButton(db, companyKey),
  disablePurgeButton: disablePurgeButton(db, companyKey),
  disableRestoreButton: disableRestoreButton(db, companyKey),
  disableUpgradeButton: disableUpgradeButton(db, companyKey),
  selectedMailboxLink: selectedMailboxLink(db, companyKey),
  selectedMailboxUpgradeCatalogueLink: selectedMailboxUpgradeCatalogueLink(
    db,
    companyKey
  ),
  selectedMailboxesRestoreLinks: selectedMailboxesRestoreLinks(db, companyKey),
  suspendAllLink: suspendAllLink(db, companyKey),
  unsuspendAllLink: unsuspendAllLink(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  startCreating: () => dispatch(startCreating(companyKey)),
  edit: (link) => dispatch(edit(companyKey, link)),
  suspendAll: (uri, link) => dispatch(suspendAll(companyKey, uri, link)),
  unsuspendAll: (uri, link) => dispatch(unsuspendAll(companyKey, uri, link)),
  restore: (uri, links) => dispatch(restore(companyKey, uri, links)),
  editProduct: (link, catalogueLink) =>
    dispatch(editProduct(companyKey, link, catalogueLink)),
  startConfirmingDeletion: () => dispatch(startConfirmingDeletion(companyKey)),
  startConfirmingPurge: () => dispatch(startConfirmingPurge(companyKey))
})

export default connect(mapDbToProps, mapActionsToProps)(Toolbar)
