const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const EventReleaseRecord = require('admin/data/EventReleaseRecord')

const EventReleaseStore = Ext.define(null, {
  extend: ResourceStore,

  record: EventReleaseRecord
})

module.exports = EventReleaseStore
