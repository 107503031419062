const Record = require('admin/core/Record')
const BillableRecord = require('admin/data/company/BillableRecord')
const CompanyStore = require('admin/data/company/CompanyStore')
const BillableFields = require('admin/data/company/fields/billable').default

// clone default company fields and concat more for the reseller record
const fields = BillableFields.concat([
  {
    name: 'defaultURI',
    type: 'string'
  },
  {
    name: 'companyRelationships'
  },
  {
    name: 'vendorName',
    type: 'string',
    mapping: 'companyRelationships',
    convert(input) {
      return CompanyStore.extractParentName(input, 'RESELLER')
    }
  },
  {
    name: 'parentName'
  },
  {
    name: 'partyBillings[0].defaultCurrency',
    type: 'string',
    mapping: 'partyBillings[0].defaultCurrency.code'
  }
])

const ResellerRecord = Record.define(null, {
  extend: BillableRecord,
  fields,

  module() {
    return require('admin/business/ResellerResource')
  },

  getCurrency() {
    return this.get('partyBillings[0].defaultCurrency') || this.callParent()
  },

  isReseller() {
    return true
  }
})

module.exports = ResellerRecord
