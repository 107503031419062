const t = require('translate')
const Ext = require('ext')
const Record = require('admin/core/Record')
const BillableRecord = require('admin/data/company/BillableRecord')
const CompanyStore = require('admin/data/company/CompanyStore')
const BillableFields = require('admin/data/company/fields/billable').default

const HostingDomainStore = require('admin/data/HostingDomainStore')
const PolicySetStore = require('admin/data/PolicySetStore')
const SignatureStore = require('admin/data/SignatureStore')
const MailRelayHostStore = require('admin/data/MailRelayHostStore')
const ScrubbingDomainStore = require('admin/data/ScrubbingDomainStore').default
const TrustedIpStore = require('admin/data/TrustedIpStore')
const SharedServiceStore = require('admin/data/SharedServiceStore')

const store = require('store').default
const { isCapableOfSelectableCustomerTypes } = require('system/capability/subs')

// clone default company fields and concat more for the customer record
const fields = BillableFields.concat([
  {
    name: 'url',
    type: 'string'
  },
  {
    name: 'billToList',
    type: 'string'
  },
  {
    name: 'customerTypeName',
    type: 'string'
  },
  {
    name: 'parentName'
  },
  {
    name: 'customerType',
    type: 'string'
  },
  {
    name: 'products'
  },
  {
    name: 'resources'
  },
  {
    name: 'billing.purchaseOrder',
    type: 'string'
  },
  {
    name: 'billing.directDebit',
    type: 'string'
  },
  {
    name: 'billing.directDebitName',
    type: 'string',
    convert(input) {
      if (input === 'true') {
        return t('Yes')
      }
      return t('No')
    }
  },
  {
    name: 'billing.termValue',
    type: 'string'
  },
  {
    name: 'billing.defaultCurrency',
    type: 'string'
  },
  {
    name: 'vendorName',
    type: 'string',
    mapping: 'companyRelationships',
    convert(input) {
      return CompanyStore.extractParentName(input, 'CUSTOMER')
    }
  }
])

const CustomerRecord = Record.define(null, {
  extend: BillableRecord,
  fields,

  module() {
    return require('admin/business/CustomerResource')
  },

  getTermValue() {
    // default it to 1 because backend does not like to have an undefined one.
    // and everywhere else in the app it is defaulted to 1,
    // hence better have this default define here in one place.
    return this.get('billing.termValue') || 1
  },

  getCurrency() {
    return this.get('billing.defaultCurrency') || this.callParent()
  },

  getParams(options = {}) {
    // for customers we always save both to the same resource
    // todo push backend to split this into two different resources,
    // this would get rid of those flags and make things easier on ui side.
    options.billing = true
    options.details = true

    const params = {
      'partyBillings[0].purchaseOrder': this.get('billing.purchaseOrder'),
      'partyBillings[0].termValue': this.getTermValue(),
      'partyBillings[0].directDebit': this.get('billing.directDebit'),
      'partyBillings[0].termType': 'INVOICING_TERM',
      'partyBillings[0].termUom': 'MTHS'
    }

    if (this.isNew() && isCapableOfSelectableCustomerTypes(store.getState())) {
      params.customerType = this.get('customerType')
    }

    return Ext.apply(this.callParent([options]), params)
  },

  getHostingDomainStore() {
    const product = this.getHostingProduct()

    if (!product) return

    if (!this.hostingDomainStore) {
      this.hostingDomainStore = new HostingDomainStore({
        url: product.resources.HOSTING_DOMAINS.url,
        parentRecord: this,
        product
      })
    }

    return this.hostingDomainStore
  },

  getPolicySetStore(product) {
    if (this.policySetStores == null) {
      this.policySetStores = {}
    }

    if (this.policySetStores[product.productCode] != null) {
      return this.policySetStores[product.productCode]
    }

    this.policySetStores[product.productCode] = new PolicySetStore({
      url: product.resources.POLICY_SETS.url,
      parentRecord: this,
      product
    })

    return this.policySetStores[product.productCode]
  },

  getSignatureStore(product) {
    if (this.signatureStores == null) {
      this.signatureStores = {}
    }

    if (this.signatureStores[product.productCode] != null) {
      return this.signatureStores[product.productCode]
    }

    this.signatureStores[product.productCode] = new SignatureStore({
      url: product.resources.SIGNATURE_MANAGEMENT.url,
      parentRecord: this,
      product
    })

    return this.signatureStores[product.productCode]
  },

  getMailRelayHostStore() {
    if (!this.mailRelayHostStore) {
      this.mailRelayHostStore = new MailRelayHostStore({
        url: this.get('products').INBOUND_SCRUB.resources.MAIL_RELAY_HOSTS.url,
        parentRecord: this
      })
    }
    return this.mailRelayHostStore
  },

  getScrubbingDomainStore() {
    if (!this.scrubbingDomainStore) {
      this.scrubbingDomainStore = new ScrubbingDomainStore({
        url: this.get('products').INBOUND_SCRUB.resources.SCRUBBING_DOMAINS.url,
        parentRecord: this,
        product: this.get('products').INBOUND_SCRUB
      })
    }
    return this.scrubbingDomainStore
  },

  getTrustedIpStore() {
    if (!this.trustedIpStore) {
      this.trustedIpStore = new TrustedIpStore({
        url: this.get('products').OUTBOUND_SCRUB.resources.TRUSTED_IPS.url,
        parentRecord: this,
        product: this.get('products').OUTBOUND_SCRUB
      })
    }
    return this.trustedIpStore
  },

  getSharedServiceStore() {
    if (!this.sharedServiceStore) {
      this.sharedServiceStore = new SharedServiceStore({
        url: this.get('products').SHARED_SERVICES.url,
        parentRecord: this
      })
    }
    return this.sharedServiceStore
  },

  hasProductKey(productKey) {
    const products = this.get('products')
    if (!products) return false
    return !!products[productKey]
  },

  isCustomer() {
    return true
  },

  setEventTags(eventTags) {
    this.eventTags = eventTags
  },

  getEventTags() {
    return this.eventTags
  }
})

module.exports = CustomerRecord
