const Ext = require('ext/ext-base')

Ext.override(Ext.menu.Item, {
  onRender(container, position) {
    if (!this.itemTpl) {
      this.itemTpl = Ext.menu.Item.prototype.itemTpl = new Ext.XTemplate(
        '<a id="{id}" class="{cls} x-unselectable" hidefocus="true" unselectable="on" href="{href}"',
        '<tpl if="hrefTarget">',
        ' target="{hrefTarget}"',
        '</tpl>',
        '>',
        '<div class="x-menu-item-icon {iconCls}"></div>',
        '<span class="x-menu-item-text">{text}</span>',
        '</a>'
      )
    }

    const a = this.getTemplateArgs()
    this.el = position
      ? this.itemTpl.insertBefore(position, a, true)
      : this.itemTpl.append(container, a, true)

    this.iconEl = this.el.child('div.x-menu-item-icon')
    this.textEl = this.el.child('.x-menu-item-text')

    if (!this.href) {
      this.mon(this.el, 'click', Ext.emptyFn, null, { preventDefault: true })
    }

    return Ext.menu.Item.superclass.onRender.call(this, container, position)
  }
})
