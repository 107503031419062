// note, you only can upgrade mailboxes on SBM.
// because on NZ there is no mapping from cos to product, upgrading
// functionality is disabled (backend not returning such a link)
//
// to see this in action, use the http://spdsec01.smxemail.com:8181 proxy

import { Form, Modal } from 'react-bootstrap'
import React, { Component } from 'react'
import { disableForm, mailboxForm, upgradeLink, upgradeTarget, uri } from 'mailboxes/subs'
import { stopUpgrading, upgrade } from 'mailboxes/actions'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Catalogue from 'mailboxes/views/form/Catalogue'
import Grid from 'components/Grid'
import Mask from 'components/Mask'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import t from 'translate'

const FORM_NAME = 'mailboxForm'

class MailboxUpgradeDialog extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired
  }

  disableSubmit = () => {
    // for their meanings see http://redux-form.com/6.8.0/docs/api/Props.md/
    return Boolean(this.props.pristine || this.props.invalid || this.props.disableForm)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.upgrade(
      this.props.uri,
      this.props.upgradeLink,
      this.props.mailboxForm.values
    )
  }

  render() {
    const title = t('Upgrade mailbox {0}?', [this.props.upgradeTarget.address])

    return (
      <Mask>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Grid>
                <Catalogue
                  companyKey={this.props.companyKey}
                  catalogue={this.props.upgradeTarget.catalogue}
                />
              </Grid>
            </Modal.Body>

            <Modal.Footer>
              <Grid>
                <CancelSubmitButtons
                  disableCancel={this.props.disableForm}
                  disableSubmit={this.disableSubmit()}
                  onCancel={this.props.stopUpgrading}
                  submitText='Upgrade Mailbox'
                />
              </Grid>
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  mailboxForm: mailboxForm(db),
  uri: uri(db, companyKey),
  upgradeLink: upgradeLink(db, companyKey),
  disableForm: disableForm(db, companyKey),
  upgradeTarget: upgradeTarget(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  stopUpgrading: () => dispatch(stopUpgrading(companyKey)),
  upgrade: (uri, upgradeLink, values) =>
    dispatch(upgrade(companyKey, uri, upgradeLink, values))
})

const MailboxUpgradeForm = reduxForm({ form: FORM_NAME })(MailboxUpgradeDialog)

export default connect(mapDbToProps, mapActionsToProps)(MailboxUpgradeForm)
