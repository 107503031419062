import User from 'admin/core/User'
import Desktop from 'admin/desktop/Desktop'
import { openRecordByUrl } from 'admin/desktop/openRecord'
import WindowMgr from 'admin/desktop/WindowMgr'
import { extractRootId, extractRootType } from 'url/record'
import toPath from 'url/toPath'

// shall return an object containing a title + url for the History class
// to be able to extract its title for google analytics
const openUri = function (uri) {
  let record, win
  const apiPath = toPath(uri)
  const openAsRoot =
    User.getRootType() !== 'VENDOR' && User.getRootUrl().indexOf(apiPath) >= 0

  if (WindowMgr.exists(apiPath) || openAsRoot) {
    win = WindowMgr.selectByUrl(apiPath)
    record = win?.getRecord()

    // only return when urls really match.
    // sometimes it's an uri ending with `/create` but the record
    // is just an empty new one. in that case, skip and leave it to
    // the Desktop below.
    if (record && toPath(record.getUrl()) === apiPath) return record
  }

  // or it could be an uri without api for creation like e.g.
  // /customer/2000074/templates/create/
  if (WindowMgr.exists(uri)) {
    win = WindowMgr.selectByUrl(uri)
    if (win) return win
  }

  const rootType = extractRootType(apiPath)
  const rootId = extractRootId(apiPath)
  const rootApiPath = toPath(`/${rootType}/${rootId}`)

  record = openRecordByUrl(rootApiPath)
  if (record) return record

  // must be something else like a support form or a top level
  // navigation element
  return Desktop.openUri(uri)
}

export default openUri
