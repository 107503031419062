const Ext = require('ext')

// Overrides the date field trigger configuration to be a div tag instead of an
// img tag so that font-based icons can be used.
const DateField = Ext.define(null, {
  extend: Ext.form.DateField,

  xtype: 'smx-datefield',
  validateMinMax: true,

  initComponent() {
    const invalidText = "{0} is not a valid date - it must be in the format 'YYYY/MM/DD'"

    Ext.apply(this, {
      triggerConfig: {
        tag: 'div',
        cls: `x-form-trigger ${this.triggerClass} primary x-btn`
      },

      format: 'Y/m/d',
      altFormats: 'Y-m-d|Y/m/j|Y-m-j|Y/n/d|Y-n-d|Y/n/j|Y-n-j',

      invalidText,

      maskRe: /[-/0-9]/
    })

    this.callParent(arguments)
  },

  parseDate(value) {
    if (!value || Ext.isDate(value)) {
      return value
    }

    let v = Date.parseDate(value, this.format)

    if (v?.format(this.format) !== value) {
      v = null
    }

    if (!v && this.altFormats) {
      if (!this.altFormatsArray) {
        this.altFormatsArray = this.altFormats.split('|')
      }

      for (const altFormat of Array.from(this.altFormatsArray)) {
        v = Date.parseDate(value, altFormat)

        if (v?.format(altFormat) !== value) {
          v = null
        }
      }
    }

    return v
  },

  // Override default error handling of DateField
  // This only validates min/max if @validateMinMax is true
  // This was added so a parent DateTimeField component can handle min/max
  // validation for both DateTime and TimePicker
  getErrors(value) {
    const errors = Ext.form.DateField.superclass.getErrors.apply(this, arguments)
    value = this.formatDate(value || this.processValue(this.getRawValue()))

    if (value.length < 1) {
      return errors
    }

    const svalue = value
    value = this.parseDate(value)

    if (!value) {
      errors.push(String.format(this.invalidText, svalue, this.format))
      return errors
    }

    if (this.validateMinMax) {
      const time = value.getTime()

      if (this.minValue) {
        const minValueTime = this.minValue.clearTime().getTime()

        if (time < minValueTime) {
          const formattedMinValue = this.formatDate(this.minValue)
          const error = String.format(this.minText, formattedMinValue)

          errors.push(error)
        }
      }

      if (this.maxValue) {
        const maxValueTime = this.maxValue.clearTime().getTime()

        if (time > maxValueTime) {
          const formattedMaxValue = this.formatDate(this.maxValue)
          const error = String.format(this.maxText, formattedMaxValue)

          errors.push(error)
        }
      }
    }

    if (this.disabledDays) {
      const day = value.getDay()
      for (const d of Array.from(this.disabledDays)) {
        if (day === d) {
          errors.push(this.disabledDaysText)
          break
        }
      }
    }

    const fvalue = this.formatDate(value)
    if (this.disabledDatesRE && this.disabledDatesRE.test(fvalue)) {
      errors.pushString.format(this.disabledDatesText, fvalue)
    }

    return errors
  }
})

Date.patterns = { SHORT: 'Y/m/d' }

module.exports = DateField
