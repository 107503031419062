const t = require('translate')
const Record = require('admin/core/Record')
const humanize = require('underscore.string/humanize')

const SearchRecord = Record.define(null, {
  fields: [
    {
      name: 'id',
      type: 'string'
    },
    {
      name: 'resourceId',
      type: 'int'
    },
    {
      name: 'url',
      type: 'string'
    },
    {
      name: 'type',
      type: 'string'
    },
    {
      name: 'expired',
      type: 'boolean'
    },
    {
      name: 'resourceType',
      type: 'string'
    },
    {
      name: 'typeName',
      type: 'string',
      mapping: 'type',
      convert(input) {
        return t(humanize(input))
      }
    },
    {
      name: 'resourceTypeName',
      type: 'string',
      mapping: 'resourceType',
      convert(input) {
        return t(humanize(input))
      }
    },
    {
      name: 'description',
      type: 'string',
      convert(value, record) {
        return String.format(
          '{0}{1}',
          value,
          record.expired ? ' [' + t('Cancelled') + ']' : ''
        )
      }
    }
  ]
})

module.exports = SearchRecord
