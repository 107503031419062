const Ext = require('ext')
const t = require('translate')
const FormPanel = require('admin/component/form/FormPanel')
const User = require('admin/core/User')
const { logoutQuietly } = require('system/logout')
const logger = require('system/logger').default

const UserRolesPanel = Ext.define(null, {
  extend: FormPanel,

  title: t('Roles'),
  monitorValid: false,
  autoHeight: false,
  cls: 'user-roles-panel',
  tabCls: 'user-roles-tab',
  padding: '10px 6px 0px',

  initComponent() {
    this.record
      .getRoles()
      .then(({ roles, editable }) => {
        // Keep ref so we can use again when saving.
        this.roles = roles

        const rolesManagedElsewhere = this.record.isDelegated() && !editable

        if (rolesManagedElsewhere) {
          this.add(
            new Ext.form.Label({
              text: t("This user's roles are managed under {0}.", [
                this.record.getHumanAuthenticationSource()
              ]),
              style: { padding: '8px' }
            })
          )

          this.add(new Ext.Spacer({ height: 10 }))
        }

        const checkboxes = Object.values(roles).map((r) => {
          return new Ext.form.Checkbox({
            fieldLabel: r.description,
            name: r.code,
            width: 100,
            checked: r.assigned,
            disabled:
              rolesManagedElsewhere || !r.assignable || !User.hasRole('MANAGE_USERS'),
            listeners: {
              check: () => this.updateButtons()
            }
          })
        })
        this.add(checkboxes)
        this.doLayout()
      })
      .catch((error) => logger.error(error))
      .done()

    if (User.hasRole('MANAGE_USERS')) {
      this.buttons = [
        (this.resetButton = new Ext.Button({
          cls: 'e2e-user-role-panel-cancel',
          text: t('Reset'),
          disabled: true,
          handler: () => this.form.reset()
        })),
        (this.saveButton = new Ext.Button({
          cls: 'e2e-user-role-panel-save primary',
          text: t('Save'),
          disabled: true,
          handler: () => this.handleSave()
        }))
      ]
    }

    this.callParent()
  },

  updateButtons() {
    this.saveButton.setDisabled(!this.form.isDirty())
    this.resetButton.setDisabled(!this.form.isDirty())
  },

  isOwnProfile() {
    return this.record.isOwnProfile()
  },

  handleSave() {
    if (this.isOwnProfile()) {
      return Ext.Msg.confirm(
        t('Confirm Logout'),
        t(
          'Changing permissions will immediately cause a logout. Are you sure you want to do this?'
        ),
        (btn) => {
          if (btn === 'yes') {
            this.doSave()
          }
        }
      )
    }
    this.doSave()
  },

  doSave() {
    this.mask()
    const values = this.form.getFieldValues()
    for (const [code, assigned] of Object.entries(values)) {
      const role = Object.values(this.roles).find((role) => role.code === code)
      role.assigned = assigned
    }
    this.record
      .saveRoles(this.roles)
      .then(
        () => {
          this.form.setValues(values)
          if (this.isOwnProfile()) {
            Ext.util.Cookies.set('changedpermissions', true, new Date().add(Date.DAY, 1))
            logoutQuietly()
          }
        },
        (err) => {
          logger.error(err)
          this.form.reset()
          throw err
        }
      )
      .finally(() => {
        this.unmask()
        this.updateButtons()
      })
      .done()
  }
})

module.exports = UserRolesPanel
