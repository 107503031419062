const Ext = require('ext')

const ReadOnlyDateField = Ext.define(null, {
  extend: Ext.form.TextField,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      cls: 'readonly-datefield',
      xtype: 'readonly-datefield'
    })

    cfg.readOnly = true

    if (cfg.clone) {
      Ext.applyIf(cfg, {
        helpText: cfg.clone.helpText,
        hideLabel: cfg.clone.hideLabel,
        fieldLabel: cfg.clone.fieldLabel,
        format: cfg.clone.format,
        name: cfg.clone.name,
        width: cfg.clone.width
      })
    }

    if (cfg.locked) {
      cfg.cls += ' locked-textfield'
    }
    this.callParent([cfg])
    this.format = cfg.clone?.format
  },

  setValue(value) {
    if (!value) return

    if (value.format && this.format) {
      // value is Ext Date object
      return this.callParent([value.format(this.format)])
    } else if (value.format) {
      return this.callParent([value.format('Y/m/d H:i')])
    }
    return this.callParent([value])
  },

  highlighted: false,

  highlight() {
    this.highlighted = true
  },

  unHighlight() {
    this.highlighted = false
  },

  isHighlighted() {
    return this.highlighted
  }
})

module.exports = ReadOnlyDateField
