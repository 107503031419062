const Ext = require('ext')
const ResourceStore = require('admin/data/ResourceStore')
const CountryRecord = require('admin/data/CountryRecord')

const CountryStore = Ext.define(null, {
  extend: ResourceStore,

  baseUrl: '/api/system',
  resourceName: 'countries',

  record: CountryRecord
})

module.exports = CountryStore
