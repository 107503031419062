const Ext = require('ext')
const t = require('translate')
const sprites = require('sprites')
const Clause = require('smartrules/designer/Clause')
const JoinWord = require('smartrules/designer/JoinWord')
const DraggingButton = require('smartrules/designer/DraggingButton')
const DeleteButton = require('smartrules/designer/DeleteButton')
const NestingButton = require('smartrules/designer/NestingButton')

// A SmartRules "then" action
const ActionClause = Ext.define(null, {
  extend: Clause,

  constructor(cfg = {}) {
    let panelButtons

    const items = []
    cfg = Ext.applyIf(cfg, {
      border: false,
      cls: `smartrules-action smartrule ${cfg.type ? cfg.type : ''}`,
      defaults: {
        border: false,
        margins: '2'
      },
      height: 'auto',
      nestable: false,
      items,
      layout: 'hbox',
      minHeight: 38,
      padding: '0 4',
      flex: 1,
      layoutConfig: {
        padding: '0',
        align: 'top'
      }
    })
    this.nestable = cfg.nestable
    this.inHeader = false
    this.joinWord = new JoinWord()
    items.push(this.joinWord)
    items.push(
      // Icon in the then area
      {
        border: false,
        html: this.getIconHtml(cfg.type),
        margins: '0 6 0 0',
        xtype: 'displayfield'
      },
      // Label after the icon
      {
        text: cfg.label,
        margins: '0 4 0 0',
        xtype: 'label'
      }
    )
    if (!cfg.noSpacer) {
      let spacer
      this.spacer = spacer = new Ext.Spacer({
        flex: 1
      })
      items.push(spacer)
    }
    this.panelButtons = panelButtons = [
      new DraggingButton({
        width: 40,
        clause: this
      }),
      new DeleteButton()
    ]
    if (cfg.nestable) {
      panelButtons.unshift(new NestingButton())
    }
    items.push(panelButtons)
    if (cfg.combo) {
      items.splice(3, 0, cfg.combo)
    }
    this.callParent([cfg])
    this.on('render', this.setupEvents, this, { single: true })
  },

  getIconHtml(type) {
    const svg = this.getSvg(type)
    return `<div class="smartrules-actions"> \
<div class="${type} smartrules-icon"> \
${svg} \
</div> \
</div>`
  },

  getSvg(type) {
    if (sprites.hasSvg(type)) {
      return sprites.toHtml(type)
    }
    return ''
  }, // leave it to the css selector to decorate with an icon (using :before)

  /**
  @method adaptToGrouping
  @param inHeader
  Hides or shows parts of the component either as a consequent clause or an antecedent clause in a group.
  A consequent clause (whether top-level or nested) is operated on by its own buttons.
  An antecedent clause cannot be operated on individually; operations are applied to the group that depends on it,
  so the group supplies its own panel of buttons.
  */
  adaptToGrouping(inHeader = false) {
    if (inHeader) {
      this.panelButtons.forEach((b) => b.hide())
      this.joinWord.hide()
      if (this.spacer) {
        this.spacer.hide()
      }
      this.inHeader = true
    } else {
      this.panelButtons.forEach((b) => b.show())

      const button = this.panelButtons.find((n) => n instanceof NestingButton)

      button && button.toggle(false)

      this.joinWord.show()
      if (this.spacer) {
        this.spacer.show()
      }
      this.inHeader = false
    }
    this.doLayout()
  },

  /**
  @method offerNest
  Displays a nested, retractable drop-zone under this clause, by rewriting the clause tree to wrap this clause in a new group.
  */
  offerNest() {
    const ActionGroup = require('smartrules/designer/ActionGroup')
    const DropZone = require('smartrules/designer/DropZone')

    const parentGroup = this.getParentGroup()
    const position = parentGroup.groupPanel.items.findIndex('id', this.id)
    const newGroup = new ActionGroup({
      groupHeaderItem: this,
      firstJoinWord: t('Then'),
      defaultJoinWord: t('And', 'SmartRules Actions')
    })

    parentGroup.groupPanel.insert(position, newGroup)

    const dropZone = new DropZone({
      html: t('Drag sub-actions here'),
      isValid() {
        return true
      }
    })

    newGroup.groupPanel.insert(0, dropZone)
    newGroup.dndHint = dropZone
    const nestingButton = newGroup.findBy(
      (n) => n instanceof NestingButton && n.ownerCt === newGroup.groupHeader
    )
    if (nestingButton[0]) {
      nestingButton[0].toggle(true)
    }

    this.adaptToGrouping(true)
    parentGroup.updateJoinWords()
    parentGroup.doLayout()
  },

  addSubAction(subAction) {
    const parentGroup = this.getParentGroup()
    if (this.inHeader) {
      parentGroup.groupPanel.add(subAction)
    } else {
      const ActionGroup = require('smartrules/designer/ActionGroup')

      const position = parentGroup.groupPanel.items.findIndex('id', this.id)
      const newGroup = new ActionGroup({
        groupHeaderItem: this,
        firstJoinWord: t('Then'),
        defaultJoinWord: t('And', 'SmartRules Actions')
      })
      parentGroup.groupPanel.insert(position, newGroup)
      newGroup.groupPanel.add(subAction)
      const nestingButton = newGroup.findBy(
        (n) => n instanceof NestingButton && n.ownerCt === newGroup.groupHeader
      )
      if (nestingButton[0] != null) {
        nestingButton[0].toggle(true)
      }
      this.adaptToGrouping(true)
    }
    parentGroup.updateJoinWords()
    parentGroup.doLayout()
    return subAction
  },

  setJoinWord(word) {
    return this.joinWord.update(word)
  },

  isValid() {
    const items = this.findByType(Ext.form.Field)
    return items.every((item) => item.validate())
  },

  setupEvents() {
    this.getEl().hover(
      function () {
        if (!this.eventsSuspended) {
          this.addClass('hover')
        }
      },
      function () {
        if (!this.eventsSuspended) {
          this.removeClass('hover')
        }
      },
      this
    )
  }
})

module.exports = ActionClause
