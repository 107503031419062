const Record = require('admin/core/Record')
const Time = require('admin/time/Time')
const DomainConfigMixin = require('admin/data/DomainConfigMixin')

const SignatureRecord = Record.define(null, {
  module() {
    return require('admin/business/SignatureManagementResource')
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'version',
      type: 'int'
    },
    {
      name: 'policySetType',
      type: 'string'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'priority',
      type: 'int'
    },
    {
      name: 'global',
      type: 'boolean'
    },
    {
      name: 'contactMechCount',
      type: 'int'
    },
    {
      name: 'html',
      type: 'string'
    },
    {
      name: 'text',
      type: 'string'
    }
  ],

  getParams() {
    return {
      global: this.get('global'),
      html: this.get('html'),
      name: this.get('name'),
      policySetType: this.get('policySetType'),
      priority: this.get('priority'),
      text: this.get('text')
    }
  },

  // this ensures that after every save operation a subsequent GET
  // for the same resource is made, see SCL-2483
  isPersistentOnSave() {
    return true
  },

  getConfig: DomainConfigMixin.getConfig,
  saveConfig: DomainConfigMixin.saveConfig
})

module.exports = SignatureRecord
