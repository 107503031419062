const Record = require('admin/core/Record')

const SupportRecord = Record.define(null, {
  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'typeOfQuery',
      type: 'string'
    },
    {
      name: 'firstName',
      type: 'string'
    },
    {
      name: 'lastName',
      type: 'string'
    },
    {
      name: 'emailAddress',
      type: 'string'
    },
    {
      name: 'body',
      type: 'string'
    }
  ],

  getParams() {
    return {
      typeOfQuery: this.get('typeOfQuery'),
      firstName: this.get('firstName'),
      lastName: this.get('lastName'),
      emailAddress: this.get('emailAddress'),
      body: this.get('body')
    }
  }
})

module.exports = SupportRecord
