import { SYSTEM_CAPABILITY_SET_LIST } from './actions'
import { createNextState } from '@reduxjs/toolkit'
import initialState from 'system/capability/db'

const reducers = {
  [SYSTEM_CAPABILITY_SET_LIST]: (capabilityDb, action) => {
    return Object.assign(capabilityDb, { list: action.list })
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
