const Ext = require('ext')
const User = require('admin/core/User')
const TabPanel = require('admin/view/TabPanel').default
const EventDetailTab = require('admin/view/EventDetailTab')
const EventPreviewTab = require('admin/view/EventPreviewTab').default
const ProductResources = require('product/resources')

const EventDetailPanel = Ext.define(null, {
  extend: Ext.Panel,

  anchor: '100% 100%',
  layout: 'fit',
  border: false,

  initComponent() {
    // beware that we have a status for quarantine and a resource for quarantine
    // both must be checked, have different semantics, and both do
    // influence appearance of ui elements
    let eventPreviewTab
    const isQuarantineVisible = User.hasRole('QUARANTINE') && this.record.isQuarantined()

    const eventDetailTab = new EventDetailTab(
      Object.assign(
        {
          releaseLink: this.customer.getUriByRel(ProductResources.QUARANTINE_RELEASE_REL),
          isQuarantineVisible
        },
        this.initialConfig
      )
    )

    if (isQuarantineVisible) {
      eventPreviewTab = new EventPreviewTab({
        preview: this.preview,
        timeZoneName: this.timeZoneName,
        authorized: true
      })
    }

    this.items = new TabPanel({
      items: [eventDetailTab, eventPreviewTab],
      bodyStyle: { padding: '5px' }
    })

    this.items.on('tabchange', function (me, tab) {
      if (tab === eventPreviewTab) {
        eventPreviewTab.onResize(eventDetailTab.getWidth(), eventDetailTab.getHeight())
      }
    })

    this.callParent()
  }
})

module.exports = EventDetailPanel
