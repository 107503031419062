const Ext = require('ext')
const StoreDataProxy = require('admin/data/StoreDataProxy')

/*
Proxy for Store where filtering and sorting are performed here rather than on the backing store.
*/
const ProxyStore = Ext.define(null, {
  extend: Ext.data.Store,

  constructor(store) {
    this.callParent([
      {
        proxy: new StoreDataProxy(store),
        resourceConnection: store.resourceConnection
      }
    ])
    this.load()
    this.proxy.storeProxy.on('load', this.load, this)
    this.proxy.storeProxy.on('add', this.reload, this)
    this.proxy.storeProxy.on('datachanged', this.reload, this)
    this.proxy.storeProxy.on('remove', this.reload, this)
  },

  destroy() {
    if (this.proxy != null ? this.proxy.storeProxy : undefined) {
      this.proxy.storeProxy.un('load', this.load, this)
      this.proxy.storeProxy.un('add', this.reload, this)
      this.proxy.storeProxy.un('datachanged', this.reload, this)
      this.proxy.storeProxy.un('remove', this.reload, this)
    }
    this.proxy = null
    this.callParent()
  },

  // Called as a callback by the Reader during a load operation.
  // Duplicated from Ext.data.Store due to not wanting to have the records join this datastore
  loadRecords(o, options, success) {
    if (!o || success === false) {
      if (success !== false) {
        this.fireEvent('load', this, [], options)
      }

      if (options.callback != null) {
        options.callback.call(options.scope || this, [], options, false)
      }

      return
    }

    const r = o.records
    const t = o.totalRecords || r.length
    if (!options || options.add !== true) {
      if (this.pruneModifiedRecords) {
        this.modified = []
      }
      if (this.snapshot) {
        this.data = this.snapshot
        delete this.snapshot
      }
      this.data.clear()
      this.data.addAll(r)
      this.totalLength = t
      this.applySort()
      this.fireEvent('datachanged', this)
    } else {
      this.totalLength = Math.max(t, this.data.length + r.length)
      this.add(r)
    }
    this.fireEvent('load', this, r, options)

    if (options.callback) {
      return options.callback.call(options.scope || this, r, options, true)
    }
  }
})

module.exports = ProxyStore
