import Api from 'api'
import _s from 'underscore.string'

// Clever workaround to hide auth tokens and to
// respect content type and filename returned from backend
// before saving file locally without opening in browser.
export async function download(url, name, ext, params = null) {
  let fullUrl = url

  if (ext && !_s.endsWith(url, ext)) {
    fullUrl += '.' + ext
  }

  if (params) {
    fullUrl += '?' + params
  }

  // disable timeout to allow larger file downloads, see DPT-5885
  // and to avoid any irritating network inactivity dialogue.
  // for the record, apache will timeout after 2 mins anyway.
  const response = await Api.get(fullUrl, { responseType: 'arraybuffer', timeout: 0 })

  let filename
  const contentDisposition = response.headers['content-disposition']
  const contentType = response.headers['content-type'] || 'application/' + ext

  if (contentDisposition) {
    filename = contentDisposition
      .split(';')[1]
      .split('=')[1]
      .replace('"', '')
      .replace('"', '')
  } else if (name && ext) {
    filename = `${name}.${ext}`
  } else {
    filename = fullUrl.substring(fullUrl.lastIndexOf('/') + 1)
  }

  const blob = new Blob([response.data], { type: contentType })

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename

  document.body.appendChild(link)
  link.click()

  // cleanup
  document.body.removeChild(link)
}
