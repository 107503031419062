const t = require('translate')
const Record = require('admin/core/Record')

const isThreat = function (v) {
  return (
    ['THREAT', 'MCAFEE_VIRUS', 'CLAMAV_VIRUS', 'SYMANTEC_VIRUS', 'SYMANTEC_WORM'].indexOf(
      v
    ) > -1
  )
}

// This is an aggregate of events from the same message.
// Differing properties are nested in recipients.
const EventDetailRecord = Record.define(null, {
  fields: [
    'details',
    'direction',
    'fromHost',
    'msgId',
    'receivedDate',
    'recipients',
    'sender',
    'sid',
    'size',
    'subject',
    'tags',
    'connectionTlsCipher',
    'connectionTlsProtocol',
    'deliveryTlsCipher',
    'deliveryTlsProtocol'
  ],

  eventStatus(recipient) {
    const EventDetailStore = require('admin/data/EventDetailStore')

    if (EventDetailStore.EVENT_STATUSES[recipient.eventStatus]) {
      return EventDetailStore.EVENT_STATUSES[recipient.eventStatus]
    } else if (recipient.response) {
      return t('Rejected By Recipient')
    }

    const et = recipient.eventType
    if (recipient.deliveredDate) {
      return t('Delivered')
    } else if (
      et.indexOf('SPAM') !== -1 ||
      (et.indexOf('DENY') !== -1 && et.indexOf('POLICY') !== -1)
    ) {
      return t('Rejected')
    } else if (isThreat(et)) {
      return t('Rejected')
    }

    return recipient.eventStatus
  },

  eventType(recipient) {
    const v = recipient.eventType

    // TODO rework this, should be a switch case block with proper string comparison
    if (v.indexOf('CLEAN') !== -1) {
      return t('Clean')
    } else if (v === 'SPF_SPAM') {
      return t('SPF')
    } else if (v === 'DMARC_SPAM') {
      return t('DMARC')
    } else if (v === 'MAIL_FROM_CHANGE_SPAM') {
      return t('Spam (MFC)')
    } else if (v === 'RATE_LIMIT_SPAM') {
      return t('Spam (RLS)')
    } else if (v.indexOf('SPAM') !== -1) {
      return t('Spam')
    } else if (isThreat(v)) {
      return t('Threat')
    } else if (v.indexOf('ALLOW') !== -1 && v.indexOf('POLICY') !== -1) {
      return t('Whitelist')
    } else if (v.indexOf('DENY') !== -1 && v.indexOf('POLICY') !== -1) {
      return t('Blacklist')
    } else if (v.indexOf('RBL') !== -1) {
      return t('RBL')
    }

    return t('Unknown')
  },

  isQuarantined() {
    const EventDetailStore = require('admin/data/EventDetailStore')
    const recipients = this.get('recipients')
    const eventStatuses = recipients?.map(this.eventStatus)

    if (!eventStatuses) return

    return eventStatuses.indexOf(EventDetailStore.getQuarantineStatus()) > -1
  }
})

module.exports = EventDetailRecord
