// Temporary utility to get component constructor from string.
// TODO: Remove / rewrite
const getComponent = function (component) {
  component = component.replace(/^smartrules-/, '')

  switch (component) {
    case 'action':
      return require('smartrules/designer/ActionClause')
    case 'actions':
      return require('smartrules/designer/ActionClauseTree')
    case 'action-group':
      return require('smartrules/designer/ActionGroup')
    case 'addressable-action':
      return require('smartrules/designer/actions/AddressableActionClause')
    case 'prepend-preamble':
      return require('smartrules/designer/actions/PrependPreamble')
    case 'append-footer':
      return require('smartrules/designer/actions/AppendFooter')
    case 'bcc-to':
      return require('smartrules/designer/actions/BccTo')
    case 'cc-to':
      return require('smartrules/designer/actions/CcTo')
    case 'delete-message':
      return require('smartrules/designer/actions/DeleteMessage')
    case 'redirect-to':
      return require('smartrules/designer/actions/RedirectTo')
    case 'refuse-message':
      return require('smartrules/designer/actions/RefuseMessage')
    case 'quarantine':
      return require('smartrules/designer/actions/QuarantineMessage')
    case 'replace-domain':
      return require('smartrules/designer/actions/ReplaceDomain')
    case 'replace-sender':
      return require('smartrules/designer/actions/ReplaceSender')
    case 'send-notification':
      return require('smartrules/designer/actions/SendNotification')
    case 'stop-processing':
      return require('smartrules/designer/actions/StopProcessing')
    case 'strip-all-attachments':
      return require('smartrules/designer/actions/StripAllAttachments')
    case 'strip-matched-attachments':
      return require('smartrules/designer/actions/StripMatchedAttachments')
    case 'whitelist':
      return require('smartrules/designer/actions/Whitelist')
    case 'condition':
      return require('smartrules/designer/ConditionClause')
    case 'conditions':
      return require('smartrules/designer/ConditionClauseTree')
    case 'condition-group':
      return require('smartrules/designer/ConditionGroup')
    case 'addressable-condition':
      return require('smartrules/designer/conditions/AddressableConditionClause')
    case 'headers':
      return require('smartrules/designer/conditions/Headers')
    case 'addressed-from':
      return require('smartrules/designer/conditions/AddressedFrom')
    case 'addressed-to':
      return require('smartrules/designer/conditions/AddressedTo')
    case 'attachment':
      return require('smartrules/designer/conditions/Attachment')
    case 'message':
      return require('smartrules/designer/conditions/Message')
    case 'size-of':
      return require('smartrules/designer/conditions/SizeOf')
    case 'suspected-whaling':
      return require('smartrules/designer/conditions/SuspectedWhaling')
    case 'group-header':
      return require('smartrules/designer/GroupHeader')
    case 'icon':
      return require('smartrules/designer/Icon')
    case 'matcher':
      return require('smartrules/designer/Matcher')
    case 'matcher-combo':
      return require('smartrules/designer/MatcherCombo')
    case 'address-matcher':
      return require('smartrules/designer/matchers/AddressMatcher')
    case 'between-matcher':
      return require('smartrules/designer/matchers/BetweenMatcher')
    case 'content-matcher':
      return require('smartrules/designer/matchers/ContentMatcher')
    case 'equals-matcher':
      return require('smartrules/designer/matchers/EqualsMatcher')
    case 'gte-matcher':
      return require('smartrules/designer/matchers/GteMatcher')
    case 'list-matcher':
      return require('smartrules/designer/matchers/ListMatcher')
    case 'lte-matcher':
      return require('smartrules/designer/matchers/LteMatcher')
    case 'no-matcher':
      return require('smartrules/designer/matchers/NoMatcher')
    case 'null-sender-matcher':
      return require('smartrules/designer/matchers/NullSenderMatcher')
    case 'pattern-matcher':
      return require('smartrules/designer/matchers/PatternMatcher')
    case 'regexp-matcher':
      return require('smartrules/designer/matchers/RegexpMatcher')
    case 'size-matcher':
      return require('smartrules/designer/matchers/SizeMatcher')
    case 'text-item-matcher':
      return require('smartrules/designer/matchers/TextItemMatcher')
    case 'text-matcher':
      return require('smartrules/designer/matchers/TextMatcher')
  }
}

module.exports = getComponent
