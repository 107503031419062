const Ext = require('ext')
const t = require('translate')
const DomainFormPanel = require('admin/component/form/DomainFormPanel')
const User = require('admin/core/User')
const RuleSets = require('smartrules/RuleSets')
const RuleListPanel = require('smartrules/RuleListPanel')

// @filter will be one of INBOUND_RULE_SET or OUTBOUND_RULE_SET.
// Contains RuleSets and RuleListPanel.
const RuleManagementPanel = Ext.define(null, {
  extend: Ext.Panel,

  layout: 'border',

  helpContent: {
    en: require('SmartRuleHelp_en.html')
  },

  initComponent() {
    this.items = [
      (this.ruleSets = new RuleSets({
        region: 'west',
        border: true,
        width: 235,
        customer: this.customer,
        filter: (record) => {
          return record.get('ruleSetType') === this.filter
        },
        url: this.product.resources.RULE_SETS.url,
        ruleSetType: this.filter,
        vendor: this.vendor
      })),
      new Ext.TabPanel({
        region: 'center',
        activeTab: 0,
        border: false,
        bodyCssClass: 'left-border',
        items: [
          (this.rules = new RuleListPanel({
            title: t('Rules'),
            tooltip: t('Rules assigned to the currently selected rule set'),
            border: true,
            customer: this.customer,
            // Store is manipulated on ruleset change so shouldn't be cached.
            store: new (require('smartrules/RuleStore'))({
              parentRecord: this.customer || this.vendor
            }),
            vendor: this.vendor
          })),
          this.customer &&
            (this.configPanel = new DomainFormPanel({
              disabled: !User.isTechnical(),
              tooltip: t('Domains assigned to the currently selected rule set'),
              border: true,
              removable: true,
              useMailServerStrings:
                !this.hasHosting() && this.filter === 'OUTBOUND_RULE_SET'
            })),
          new Ext.Panel({
            title: t('Help'),

            // removes any ext css class names as we style them with bootstrap (Bs)
            unstyled: true,
            cls: 'Bs',

            // these class names are the same as the react based help containers
            // we have for archiving or quarantine.
            bodyCssClass: 'help panel',
            // ensures full width is used
            autoWidth: true,

            layout: 'fit',
            border: false,
            autoScroll: true,
            // same padding as .Bs .tab-pane to ensure consistency
            style: 'padding: 10px 5px',
            html: this.helpContent[window.LOCALE]
          })
        ]
      })
    ]

    // We need to listen to load to catch new rule sets created inline.
    this.ruleSets.store.on('load', this.updateRules, this)
    this.ruleSets.getSelectionModel().on('selectionchange', this.updateRules, this)

    this.ruleSets.getSelectionModel().on('beforerowselect', () => {
      // Prevent changing rule set while config is dirty.
      return !(this.configPanel && this.configPanel.isDirty())
    })

    this.callParent()
  },

  hasHosting() {
    return (this.customer || this.vendor).hasHosting()
  },

  updateRules() {
    const selected = this.ruleSets.getSelectionModel().getSelected()
    this.rules.setRuleSet(selected)
    this.configPanel && this.configPanel.setParentRecord(selected)
  }
})

module.exports = RuleManagementPanel
