import { toString } from 'admin/time/Time'

// This is a data converter for converting quarantine search results to the format
// that is acceptable by mail/search which currently supports archiving search
// data format

const COLUMN_NAME_TRANSLATIONS = {
  'has-smartrule': 'hasSmartRule',
  'message-id': 'messageId',
  'received-date': 'received'
}

export const toMailSearchResults = (events, options) => {
  if (!events) return

  return events.map((event) => {
    // TODO: saving original event for further processing
    const converted = { orig: event }

    for (const k in event) {
      const key = COLUMN_NAME_TRANSLATIONS[k] || k
      const value = event[k]
      switch (k) {
        case 'received-date':
          converted[key] = toString(value, options.timeZoneName)
          break
        default:
          converted[key] = value
      }
    }
    return converted
  })
}

const EVENT_STATUS_QUARANTINED = 'QUARANTINED'

export const toMailSearchDetail = (
  { recipients, sid, fromHost },
  status = EVENT_STATUS_QUARANTINED
) => {
  return {
    sid,
    recipients: recipients
      .filter((r) => r.eventStatus === status)
      .map((r) => r.recipient),
    senderMtaIp: fromHost
  }
}
