const Format = require('admin/util/Format')

const TYPES = {
  CLEAN: 'Clean',
  SPAM: 'Spam',
  THREAT: 'Threat',
  ALLOW_POLICY: 'Whitelist',
  DENY_POLICY: 'Blacklist'
}

const PRECISION = 1

const percentage = function (n) {
  if (n !== 0 && !n) return ''

  return `${Format.number(n * 100, PRECISION)}%`
}

const MailStats = {
  getTotal(data) {
    if (!data) return 0

    if (data.ALL?.total) return data.ALL.total

    return Object.values(data).reduce((total, data) => total + data.total, 0)
  },

  buildRatios(data) {
    const total = this.getTotal(data)

    let cleanedRatio = 1

    const ratios = Object.keys(TYPES).reduce(function (memo, type) {
      if (data && data[type]) {
        let ratio
        let val = (ratio = 0)

        if (TYPES[type] !== TYPES.CLEAN) {
          val = data[type].total || 0
        }

        if (total > 0) {
          ratio = (val / total).toFixed(PRECISION + 2)
        }

        cleanedRatio = (cleanedRatio - ratio).toFixed(PRECISION + 2)

        memo[type] = ratio
      }

      return memo
    }, {})

    if (data && data.CLEAN) {
      ratios.CLEAN = cleanedRatio.toString()
    }

    return ratios
  },

  interpolate(data) {
    const ratios = this.buildRatios(data)

    return Object.keys(TYPES).map((type) => {
      return {
        label: TYPES[type],

        // important, must remain unchanged!
        value: Format.number((data && data[type] && data[type].total) || 0),

        percentage: percentage(ratios && ratios[type])
      }
    })
  }
}

module.exports = MailStats
