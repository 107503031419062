const Record = require('admin/core/Record')
const Time = require('admin/time/Time')
const DomainConfigMixin = require('admin/data/DomainConfigMixin')

const PolicySetRecord = Record.define(null, {
  module() {
    return require('admin/business/PolicyManagementResource')
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'version',
      type: 'int'
    },
    {
      name: 'policySetType',
      type: 'string'
    },
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'fromDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'thruDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'priority',
      type: 'int'
    },
    {
      name: 'global',
      type: 'boolean'
    },
    {
      name: 'contactMechCount',
      type: 'int'
    }
  ],

  getParams() {
    return {
      name: this.get('name'),
      global: this.get('global'),
      // unfortuantely backend needs the direction in uppercase
      // todo: have the backend accept it in lowercase
      policySetType: this.get('policySetType').toUpperCase(),
      priority: this.get('priority')
    }
  },

  hasAttributes: true,
  getConfig: DomainConfigMixin.getConfig,
  saveConfig: DomainConfigMixin.saveConfig,

  isPersistentOnSave() {
    return true
  }
})

module.exports = PolicySetRecord
