import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import t from 'translate'

class Download extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  render() {
    return (
      <Button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className='mail-button-download' // needed for unit tests
      >
        {t('Download')}
      </Button>
    )
  }
}

export default Download
