const Ext = require('ext')
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const Operator = require('smartrules/designer/Operator')
const MatcherCombo = require('smartrules/designer/MatcherCombo')
const ListMatcher = require('smartrules/designer/matchers/ListMatcher')

const TextItemMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg = {}) {
    let listType
    this.defaultCfg = Ext.applyIf(cfg, {
      cls: 'text-item-matcher',
      matcherValue: cfg.value
    })

    this.listType = listType = 'RULE_' + this.defaultCfg.matcherType
    this.systemListType = listType + '_SYSTEM'

    this.store = new Ext.data.JsonStore({
      data: [],
      fields: ['matcher', 'name', 'clazz', 'matcherType', 'negated', 'system'],
      idProperty: 'matcher'
    })

    this.regenerateStore()

    this.operator = new Operator({
      matcherWidth: this.defaultCfg.matcherWidth,
      scope: this,
      selectMatcher: this.selectMatcher,
      store: this.store
    })

    this.defaultCfg.items = [{ width: 4, xtype: 'spacer' }, this.operator]

    this.callParent([this.defaultCfg])

    this.on('matcherSelect', this.regenerateStore, this)

    if (this.store.getCount() !== 0) {
      return this.updateValue()
    }
  },

  regenerateStore() {
    // do not move this line to the top, next to all other require() calls, because
    // it is a circular dependency. this class TextItemMatcher is required in TextMatcher :(
    const TextMatcher = require('smartrules/designer/matchers/TextMatcher')

    const matchers = [
      {
        matcher: 'is',
        name: t('is'),
        clazz: TextMatcher,
        matcherType: 'TEXT'
      },
      {
        matcher: 'is-in',
        name: t('is in list'),
        clazz: ListMatcher,
        matcherType: this.listType
      },
      {
        matcher: 'is-in-system',
        name: t('is in system list'),
        clazz: ListMatcher,
        matcherType: this.systemListType
      },
      {
        matcher: 'is-not',
        name: t('is not'),
        clazz: TextMatcher,
        matcherType: 'TEXT',
        negated: true
      },
      {
        matcher: 'is-not-in',
        name: t('is not in list'),
        clazz: ListMatcher,
        matcherType: this.listType,
        negated: true
      },
      {
        matcher: 'is-not-in-system',
        name: t('is not in system list'),
        clazz: ListMatcher,
        matcherType: this.systemListType,
        negated: true
      }
    ]

    this.store.removeAll()

    return matchers.forEach(function (matcher) {
      const RecordTypeClass = this.store.recordType
      return this.store.add(new RecordTypeClass(matcher))
    }, this)
  },

  selectMatcher: MatcherCombo.prototype.selectMatcher,
  getListStore: MatcherCombo.prototype.getListStore,
  getMatcher: MatcherCombo.prototype.getMatcher,

  hasTextMatcher() {
    return true
  },

  updateValue() {
    let index = this.store.find(this.operator.valueField, this.valueType)
    if (index < 0) {
      index = 0
    }
    const record = this.store.getAt(index)

    if (record) {
      this.operator.fireEvent('select', this, record, index)
      return this.operator.setValue(record.get('matcher'))
    }
  },

  getValue(options) {
    if (options == null) {
      options = {}
    }
    let { negated } = options
    const matcher = this.getMatcher()
    negated = negated || (matcher != null ? matcher.negated : undefined)
    const value = matcher != null ? matcher.getValue(options) : undefined

    if (value) {
      return String.format('{0}{1}', negated ? 'not ' : '', value)
    }
  }
})

module.exports = TextItemMatcher
