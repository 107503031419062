const Ext = require('ext/ext-base')

function isFunction(func) {
  if (func && typeof func === 'function') {
    return true
  }
  return false
}

/*
Overrides many ExtJS functions to make them async.
Necessary for token renewal to work correctly in beforerequest events
*/
export default function () {
  Ext.util.Event.prototype.fire = async function () {
    const len = this.listeners.length

    if (len > 0) {
      this.firing = true

      const args = Array.prototype.slice.call(arguments, 0)

      let i = 0

      for (; i < len; i++) {
        const l = this.listeners[i]

        if (l) {
          const resp = await l.fireFn.apply(l.scope || this.obj || window, args)

          if (resp === false) {
            this.firing = false
            return
          }
        }
      }

      this.firing = false
    }

    return true
  }

  Ext.data.Connection.prototype.request = Ext.util.Observable.prototype.request =
    async function (o) {
      const beforeRequestResp = await this.fireEvent('beforerequest', this, o)

      if (beforeRequestResp) {
        if (o.el) {
          if (!Ext.isEmpty(o.indicatorText)) {
            this.indicatorText =
              '<div class="loading-indicator">' + o.indicatorText + '</div>'
          }

          if (this.indicatorText) {
            Ext.getDom(o.el).innerHTML = this.indicatorText
          }

          o.success = (
            isFunction(o.success) ? o.success : function () {}
          ).createInterceptor(function (response) {
            Ext.getDom(o.el).innerHTML = response.responseText
          })
        }

        let p = o.params
        let url = o.url || this.url
        const cb = {
          success: this.handleResponse,
          failure: this.handleFailure,
          scope: this,
          argument: { options: o },
          timeout: Ext.num(o.timeout, this.timeout)
        }

        if (isFunction(p)) {
          p = p.call(o.scope || window, o)
        }

        p = Ext.urlEncode(this.extraParams, Ext.isObject(p) ? Ext.urlEncode(p) : p)

        if (isFunction(url)) {
          url = url.call(o.scope || window, o)
        }

        const form = Ext.getDom(o.form)

        if (form) {
          url = url || form.action

          if (o.isUpload || /multipart\/form-data/i.test(form.getAttribute('enctype'))) {
            return this.doFormUpload(this, o, p, url)
          }

          const serForm = Ext.lib.Ajax.serializeForm(form)
          p = p ? p + '&' + serForm : serForm
        }

        const method =
          o.method || this.method || (p || o.xmlData || o.jsonData ? 'POST' : 'GET')

        if (
          (method === 'GET' && this.disableCaching && o.disableCaching !== false) ||
          o.disableCaching === true
        ) {
          const dcp = o.disableCachingParam || this.disableCachingParam
          url = Ext.urlAppend(url, dcp + '=' + new Date().getTime())
        }

        o.headers = Ext.applyIf(o.headers || {}, this.defaultHeaders || {})

        if (o.autoAbort === true || this.autoAbort) {
          this.abort()
        }

        if ((method === 'GET' || o.xmlData || o.jsonData) && p) {
          url = Ext.urlAppend(url, p)
          p = ''
        }

        this.transId = Ext.lib.Ajax.request(method, url, cb, p, o)

        return this.transId
      }

      return o.callback ? o.callback.apply(o.scope, [o, undefined, undefined]) : null
    }

  // another necessary hack to make the datefield show events work again
  // because of the above async overrides (see SCL-3376)
  Ext.menu.Menu.prototype.showAt = async function (xy, parentMenu) {
    const beforeShowSuccess = await this.fireEvent('beforeshow', this)

    if (beforeShowSuccess) {
      this.parentMenu = parentMenu

      !this.el && this.render()

      if (this.enableScrolling) {
        this.el.setXY(xy)

        xy[1] = this.constrainScroll(xy[1])
        xy = [this.el.adjustForConstraints(xy)[0], xy[1]]
      } else {
        xy = this.el.adjustForConstraints(xy)
      }

      this.el.setXY(xy)
      this.el.show()
      Ext.menu.Menu.superclass.onShow.call(this)

      this.hidden = false
      this.focus()

      await this.fireEvent('show', this)
    }
  }

  Ext.Window.prototype.close = async function () {
    const beforeCloseSuccess = await this.fireEvent('beforeclose', this)

    if (beforeCloseSuccess) {
      if (this.hidden) {
        this.doClose()
      } else {
        this.hide(null, this.doClose, this)
      }
    }
  }

  Ext.form.ComboBox.prototype.onSelect = async function (record, index) {
    const beforeSelectSuccess = await this.fireEvent('beforeselect', this, record, index)

    if (beforeSelectSuccess) {
      this.setValue(record.data[this.valueField || this.displayField])
      this.collapse()
      await this.fireEvent('select', this, record, index)
    }
  }

  Ext.grid.GridView.prototype.insertRows = async function (
    dm,
    firstRow,
    lastRow,
    isUpdate
  ) {
    const last = dm.getCount() - 1
    if (!isUpdate && firstRow === 0 && lastRow >= last) {
      await this.fireEvent('beforerowsinserted', this, firstRow, lastRow)
      this.refresh()
      await this.fireEvent('rowsinserted', this, firstRow, lastRow)
    } else {
      if (!isUpdate) {
        await this.fireEvent('beforerowsinserted', this, firstRow, lastRow)
      }

      const html = this.renderRows(firstRow, lastRow)
      const before = this.getRow(firstRow)

      if (before) {
        if (firstRow === 0) {
          Ext.fly(this.getRow(0)).removeClass(this.firstRowCls)
        }
        Ext.DomHelper.insertHtml('beforeBegin', before, html)
      } else {
        const r = this.getRow(last - 1)
        if (r) {
          Ext.fly(r).removeClass(this.lastRowCls)
        }
        Ext.DomHelper.insertHtml('beforeEnd', this.mainBody.dom, html)
      }

      if (!isUpdate) {
        this.processRows(firstRow)
        await this.fireEvent('rowsinserted', this, firstRow, lastRow)
      } else if (firstRow === 0 || firstRow >= last) {
        Ext.fly(this.getRow(firstRow)).addClass(
          firstRow === 0 ? this.firstRowCls : this.lastRowCls
        )
      }
    }
    this.syncFocusEl(firstRow)
  }

  Ext.ux.grid.RowEditor.prototype.startEditing = async function (rowIndex, doFocus) {
    if (this.editing) {
      if (this.isDirty()) {
        this.showTooltip(this.commitChangesText)
      } else {
        this.stopEditing(false)
      }
      return
    }

    if (Ext.isObject(rowIndex)) {
      rowIndex = this.grid.getStore().indexOf(rowIndex)
    }

    if ((await this.fireEvent('beforeedit', this, rowIndex)) !== false) {
      this.editing = true
      const g = this.grid
      const view = g.getView()
      const row = view.getRow(rowIndex)
      const record = g.store.getAt(rowIndex)

      this.record = record
      this.rowIndex = rowIndex
      this.values = {}

      if (!this.rendered) {
        this.render(view.getEditorParent())
      }

      const w = Ext.fly(row).getWidth()
      this.setSize(w)

      if (!this.initialized) {
        this.initFields()
      }

      const cm = g.getColumnModel()
      const fields = this.items.items
      let f
      let val

      for (let i = 0, len = cm.getColumnCount(); i < len; i++) {
        val = this.preEditValue(record, cm.getDataIndex(i))
        f = fields[i]
        f.setValue(val)
        this.values[f.id] = Ext.isEmpty(val) ? '' : val
      }

      this.verifyLayout(true)

      if (!this.isVisible()) {
        this.setPagePosition(Ext.fly(row).getXY())
      } else {
        this.el.setXY(Ext.fly(row).getXY(), { duration: 0.15 })
      }

      if (!this.isVisible()) {
        this.show().doLayout()
      }

      if (doFocus !== false) {
        this.doFocus.defer(this.focusDelay, this)
      }
    }
  }

  Ext.ux.grid.RowEditor.prototype.stopEditing = async function (saveChanges) {
    this.editing = false
    if (!this.isVisible()) return

    if (saveChanges === false || !this.isValid()) {
      this.hide()
      await this.fireEvent('canceledit', this, saveChanges === false)
      return
    }

    const changes = {}
    const r = this.record
    let hasChange = false
    const cm = this.grid.colModel
    const fields = this.items.items

    for (let i = 0, len = cm.getColumnCount(); i < len; i++) {
      if (!cm.isHidden(i)) {
        const dindex = cm.getDataIndex(i)
        if (!Ext.isEmpty(dindex)) {
          const oldValue = r.data[dindex]
          const value = this.postEditValue(fields[i].getValue(), oldValue, r, dindex)
          if (String(oldValue) !== String(value)) {
            changes[dindex] = value
            hasChange = true
          }
        }
      }
    }

    if (
      hasChange &&
      (await this.fireEvent('validateedit', this, changes, r, this.rowIndex)) !== false
    ) {
      r.beginEdit()
      Ext.iterate(changes, function (name, value) {
        r.set(name, value)
      })
      r.endEdit()
      await this.fireEvent('afteredit', this, changes, r, this.rowIndex)
    }

    this.hide()
  }

  Ext.data.Store.prototype.add = async function (records) {
    let i, len, record

    records = [].concat(records)
    if (records.length < 1) {
      return
    }

    for (i = 0, len = records.length; i < len; i++) {
      record = records[i]

      record.join(this)

      if (record.dirty || record.phantom) {
        this.modified.push(record)
      }
    }

    const index = this.data.length
    this.data.addAll(records)

    if (this.snapshot) {
      this.snapshot.addAll(records)
    }

    return await this.fireEvent('add', this, records, index)
  }

  Ext.data.Store.prototype.afterCommit = async function (record) {
    this.modified.remove(record)
    await this.fireEvent('update', this, record, Ext.data.Record.COMMIT)
  }
}
