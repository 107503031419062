import { parse, rezone } from 'admin/time/Time'
import { getBrowserTimeZoneName, getServerTimeZoneName } from 'admin/time/TimeZone'
import { DateTime } from 'luxon'

export const daysAgo = function (days) {
  return parse(DateTime.now()).minus({ days })
}

export const atMidnight = function (
  anything = DateTime.now(),
  timeZoneName = null,
  cfg = null
) {
  if (!anything) return
  return rezone(anything, timeZoneName, cfg).startOf('day')
}

// this normalizes any given date respecting browser time zone into a
// calendar date of given time zone defaulting to NZ, pointing
// to the beginning of today, used for submitting from dates in agreements,
// date ranges, mail searches, archiving, quarantine and continuity.
export const normalizeFromDate = function (
  anything,
  timeZoneName = getServerTimeZoneName()
) {
  if (!anything) return

  return rezone(anything, timeZoneName, {
    // changes timestamp leaving local time untouched
    keepLocalTime: true
  }).startOf('day')
}

// converts any saved from dates from backend which are always in our server
// time zone to the beginning of the day this date is in the given time zone
// which is defaulted to the browser time zone.
//
// this is used for displaying from dates in agreements.
export const denormalizeFromDate = function (
  anything,
  timeZoneName = getBrowserTimeZoneName()
) {
  if (!anything) return

  const serverTimeZoneName = getServerTimeZoneName()

  return parse(anything, serverTimeZoneName)
    .setZone(timeZoneName, {
      // changes timestamp leaving local time untouched
      keepLocalTime: true
    })
    .startOf('day')
}

// this normalizes any given date respecting browser time zone into a
// calendar date of given time zone defaulting to NZ, pointing
// to the beginning of tomorrow, used for saving thru dates in agreements and mail search
//
// like that we can be sure all events of chosen thru date
// will be always shown regardless of timezone and current time.
export const normalizeThruDate = function (
  anything,
  timeZoneName = getServerTimeZoneName()
) {
  if (!anything) return

  return rezone(anything, timeZoneName, {
    // changes timestamp leaving local time untouched
    keepLocalTime: true
  })
    .plus({ days: 1 })
    .startOf('day')
}

// converts any saved thru dates from backend which are always in our server
// time zone to the end of the day this date is in the given time zone
// which is defaulted to the browser time zone.
//
// this is used for displaying thru dates in agreements.
export const denormalizeThruDate = function (
  anything,
  timeZoneName = getBrowserTimeZoneName()
) {
  if (!anything) return

  const serverTimeZoneName = getServerTimeZoneName()

  return parse(anything, serverTimeZoneName)
    .setZone(timeZoneName, {
      // changes timestamp leaving local time untouched
      keepLocalTime: true
    })
    .minus({ days: 1 })
    .endOf('day')
}
