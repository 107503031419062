const Ext = require('ext')
const store = require('store').default
const ResourceStore = require('admin/data/ResourceStore')
const TrustedIpRecord = require('admin/data/TrustedIpRecord')
const TrustedIpResource = require('admin/business/TrustedIpResource')
const { isArchivingProvisioningCapabilityFlagEnabled } = require('system/capability/subs')
const refreshMembership = require('archiving/membership/actions').refresh

const TrustedIpStore = Ext.define(null, {
  extend: ResourceStore,

  resourceName: 'trustedips',
  sortInfo: {
    field: 'name',
    direction: 'ASC'
  },

  record: TrustedIpRecord,

  module() {
    return TrustedIpResource
  },

  constructor() {
    this.callParent(arguments)

    this.on('add', function () {
      return this.reloadArchivingMembership()
    })

    this.on('remove', function () {
      return this.reloadArchivingMembership()
    })

    return this.on('change', function () {
      return this.reloadArchivingMembership()
    })
  },

  reloadArchivingMembership() {
    if (!isArchivingProvisioningCapabilityFlagEnabled(store.getState())) return
    if (!this.parentRecord) return // unit testing
    if (!this.parentRecord.hasArchiving) return
    if (typeof this.parentRecord.hasArchiving !== 'function') return
    if (!this.parentRecord.hasArchiving()) return

    return store.dispatch(
      refreshMembership(
        this.parentRecord.getCompanyKey(),
        [this.parentRecord.url, 'archiving', 'membership'].join('/')
      )
    )
  },

  canCreate() {
    return this.module.canCreate() && this.product.current
  }
})

module.exports = TrustedIpStore
