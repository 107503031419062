import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import t from 'translate'

import Dialog from './Dialog'
import { createQuarantineSet, stopCreating } from 'quarantine/quarantine_sets/actions'

import { availableTargets, uri } from 'quarantine/quarantine_sets/subs'

class CreateForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired
  }

  handleSubmit = (values) => {
    this.props.createQuarantineSet(this.props.uri, values)
  }

  handleCancel = () => {
    this.props.stopCreating(this.props.availableTargets)
  }

  render() {
    return (
      <Dialog
        title={t('Create New Policy')}
        store={this.props.store}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        submitButtonText={t('Create')}
        companyKey={this.props.companyKey}
        targets={this.props.availableTargets}
      />
    )
  }
}

const mapDbToProps = (db, props) => ({
  uri: uri(db, props.companyKey),
  availableTargets: availableTargets(db, props.companyKey)
})

const mapActionsToProps = (dispatch, initialProps) => ({
  stopCreating: (availableTargets) => {
    // since targets are a special form input we do not go through redux-form
    // we will have to reset any changes here (SCL-3043)
    availableTargets && availableTargets.reset()
    dispatch(stopCreating(initialProps.companyKey))
  },

  createQuarantineSet: (uri, values) => {
    dispatch(createQuarantineSet(initialProps.companyKey, uri, values))
  }
})

export default connect(mapDbToProps, mapActionsToProps)(CreateForm)
