const Ext = require('ext')
const t = require('translate')
const User = require('admin/core/User')
const Time = require('admin/time/Time')
const TimeZone = require('admin/time/TimeZone')
const Format = require('admin/util/Format')
const Alert = require('admin/util/Alert').default
const EventToolbar = require('admin/component/EventToolbar')
const RowSelectionModel = require('admin/component/grid/RowSelectionModel')
const EventDetailPanel = require('admin/view/EventDetailPanel')
const EventSearchStore = require('admin/data/EventSearchStore')
const parseTemplate = require('url-template').parseTemplate
const ProductResources = require('product/resources')
const logger = require('system/logger').default
const WindowMgr = require('admin/desktop/WindowMgr').default
const escape = require('html-escaper').escape

// Always use this because putting unencoded HTML in an attribute
// enables script injection
// c.f. http://youtrack.smxemail.com/issue/SMX3-3383
const QTipTemplate = new Ext.XTemplate('<div>{[this.escape(values.value)]}</div>', {
  escape
})

const isQuarantineEnabled = (customer, record) =>
  customer.hasProductKey('QUARANTINE') &&
  User.hasRole('QUARANTINE') &&
  record.isQuarantined()

const EventGridPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,
  constructor(cfg = {}) {
    this.customer = cfg.customer
    this.setTimeZoneName(cfg.timeZone?.zoneName || TimeZone.getDefaultTimeZoneName())
    const items = [
      {
        id: 'received',
        header: t('Received'),
        width: 80,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'receivedDate',
        renderer: (receivedDate) => {
          let formattedDate = ''

          if (receivedDate) {
            formattedDate = Time.toString(receivedDate, this.timeZoneName, {
              format: 'MMM dd HH:mm'
            })
          }

          return QTipTemplate.apply({ value: formattedDate })
        }
      },
      {
        id: 'from',
        header: t('From'),
        width: 90,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'sender',
        renderer(sender) {
          return QTipTemplate.apply({ value: sender })
        }
      },
      {
        id: 'to',
        header: t('To'),
        width: 90,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'recipient',
        renderer(recipient) {
          return QTipTemplate.apply({ value: recipient })
        }
      },
      {
        id: 'subject',
        header: t('Subject'),
        width: 100,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'subject',
        renderer(subject) {
          return QTipTemplate.apply({ value: subject })
        }
      },
      {
        id: 'size',
        header: t('Size'),
        width: 50,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'size',
        renderer(v) {
          return QTipTemplate.apply({ value: Format.bytes(v) || '' })
        }
      },
      {
        id: 'eventStatus',
        header: t('Status'),
        width: 60,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'eventStatus',
        renderer(v = '', meta, record) {
          const qtip = QTipTemplate.apply({
            value: `${v} (${record.get('eventType')})`
          })

          const status =
            record.get('eventStatusCode') === 'RECIPIENT_MTA_REJECTED' ? `<b>${v}</b>` : v

          return `<div qtip="${qtip}">${status}</div>`
        }
      }
    ]

    if (cfg.hasSmartRules) {
      items.push({
        id: 'hasSmartRule',
        header: `<div title="${t('SmartRule')}" class="hasSmartRule"></div>`,
        width: 28,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'hasSmartRule',
        renderer(v) {
          if (v) {
            const qtip = QTipTemplate.apply({
              value: t('SmartRule {0} was applied to this message', [' '])
            })
            return `<div class="hasSmartRule" qtip="${qtip}"></div>`
          }
          return ''
        }
      })
    }

    if (cfg.hasEncryption) {
      items.push({
        id: 'hasEncryption',
        header: `<div title="${t(
          'SMX.Event.field.hasEncryption'
        )}" class="hasEncryption"></div>`,
        width: 28,
        sortable: false,
        menuDisabled: true,
        dataIndex: 'hasEncryption',
        renderer(v) {
          if (v) {
            const qtip = QTipTemplate.apply({
              value: t('SMX.Event.field.hasEncryption.tooltip', [' '])
            })
            return `<div class="hasEncryption" qtip="${qtip}"></div>`
          }
          return ''
        }
      })
    }

    const { store } = cfg
    cfg = Ext.applyIf(cfg, {
      autoExpandColumn: 'subject',
      autoScroll: true,
      bbar: new EventToolbar({
        paramNames: {
          start: 'offset',
          limit: 'limit'
        },
        store
      }),
      cm: new Ext.grid.ColumnModel(items),
      enableColumnHide: false,
      enableColumnMove: false,
      sm: new RowSelectionModel({ singleSelect: true }),
      viewConfig: {
        deferEmptyText: true,
        emptyText: t('No events found'),
        getRowClass(record) {
          return `eventtype-${record.get('eventTypeClassification')}`
        }
      }
    })

    this.callParent([cfg])

    store.on('load', () => this.unmask())
    store.on('beforeload', (store, options) => {
      options.mask = this.mask.bind(this)
      options.unmask = this.unmask.bind(this)
      this.mask()
    })

    this.on('rowcontextmenu', this.handleRowContextMenu)

    this.on(
      'rowdblclick',
      function (grid, rowIndex) {
        this.openDetail(grid, rowIndex)
      },
      this
    )

    this.on('beforeclick', (node, event) => !event.hasModifier())

    store.on('exception', () => this.unmask())

    this.eventSearchStore = new EventSearchStore({
      url: this.product.resources.EVENTS_SEARCH.url,
      product: this.product
    })
    this.eventSearchStore.on('exception', () => {
      Alert.alert(
        t('Error'),
        t('Mail reporting is currently unavailable. Please try again later.')
      )
      this.unmask()
    })

    const EventDetailStore = require('admin/data/EventDetailStore')
    this.detailStore = new EventDetailStore()
    this.eventSearchStore.on('searchready', this.onSearchSave, this)
  },

  handleRowContextMenu(grid, rowIndex, event) {
    grid.getSelectionModel().selectRow(rowIndex)

    const menu = new Ext.menu.Menu({
      items: [
        {
          text: t('Open Message Details'),
          iconCls: 'open-SMX-message',
          handler: () => this.openDetail(grid, rowIndex)
        }
      ]
    })

    menu.on('itemclick', menu.destroy)
    menu.on('hide', menu.destroy)

    event.stopEvent()
    menu.render()
    const xy = event.getXY()
    xy[1] -= menu.el.getHeight()
    menu.showAt(xy)
  },

  mask() {
    this.el.mask(Ext.LoadMask.prototype.msg, 'x-mask-loading')
  },
  unmask() {
    this.el.unmask()
  },

  openDetail(grid, rowIndex) {
    const record = grid.getStore().getAt(rowIndex)
    this.mask()
    const EventDetailRecord = require('admin/data/EventDetailRecord')
    const detailRecord = new EventDetailRecord(null, {
      url: record.getUrl()
    })
    detailRecord.fetch({
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this),
      success: () => {
        const createDetailWindow = (preview) => {
          const windowId = 'eventDetails-' + detailRecord.get('sid')

          if (!WindowMgr.select(windowId)) {
            WindowMgr.create({
              id: windowId,
              title: `${t('Message Details')}: ${detailRecord.get('subject')}`,
              taskbar: detailRecord.getName(),
              url: detailRecord.getUrl(),
              width: 700,
              height: 665,
              minHeight: 665,
              layout: 'anchor',
              items: new EventDetailPanel({
                record: detailRecord,
                customer: this.customer,
                timeZoneName: this.timeZoneName,
                preview
              })
            })
          }
        }

        let previewLink, quarantineId

        if (isQuarantineEnabled(this.customer, detailRecord)) {
          const quarantineDetail = detailRecord.get('details').QUARANTINE_DETAIL[0]
          quarantineId = quarantineDetail.quarantineId
          const sid = quarantineDetail.sid

          // since https://youtrack.smxemail.com/issue/SCL-2742
          const previewUriTemplate = this.customer.getUriTemplateByRel(
            ProductResources.QUARANTINE_PREVIEW_REL
          )

          const parsedPreviewUriTemplate = parseTemplate(previewUriTemplate)

          previewLink = parsedPreviewUriTemplate.expand({
            customerId: this.customer.getId(),
            sid
          })
        }

        if (quarantineId && previewLink) {
          const EventQuarantineRecord = require('admin/data/EventQuarantineRecord')
          const previewRecord = new EventQuarantineRecord({
            id: quarantineId,
            previewLink
          })

          return previewRecord.fetch({
            success: () => {
              createDetailWindow(previewRecord.data)
              this.unmask()
            },
            error: (me, response, options, err) => {
              if (response.status === 404) {
                // be robust and just show details without preview
                // cos quarantined messages have a ttl and might expire
                createDetailWindow()
              } else {
                logger.error(err)
              }
              this.unmask()
            }
          })
        }
        try {
          createDetailWindow()
        } catch (exc) {
          logger.error(exc)
        }
        this.unmask()
      },
      error: () => this.unmask()
    })
  },

  onSearchSave(record) {
    if (this.isDestroyed) return

    if (record && this.currentRecord !== record) {
      this.currentRecord = record
      this.store.url = record.getUrl()
      this.getBottomToolbar().pageSize = parseInt(record.get('limit'), 10)

      return this.store.load({
        params: {
          offset: 0,
          limit: parseInt(record.get('limit'), 10)
        }
      })
    }
  },

  getTimeZoneName() {
    return this.timeZoneName
  },

  setTimeZoneName(timeZoneName) {
    this.timeZoneName = timeZoneName
  }
})

module.exports = EventGridPanel
