import _s from 'underscore.string'

const getPathSegment = (urlStr, number) => {
  const parsedUrl = new URL(urlStr, window.location.href)
  const segments = _s.rtrim(parsedUrl.pathname, '/').split('/')

  if (number < 0) {
    number = segments.length + number
  }

  return segments[number]
}

export default getPathSegment
