const Ext = require('ext')
const t = require('translate')
const DateTimeField = require('admin/component/form/DateTimeField')

/**
Wrapper for from & thru DateTime fields that adjusts min/max values
based on current field states and master min/maxValue.
Use get & set methods instead of accessing DateTime fields directly.

From and thru fields are configured by passing on sub-configs
in cfg.both, cfg.from and cfg.thru e.g.
daterange = new DateTimeRangeFieldSet(
  minValue: [date]
  maxValue: [date]
  from:
    name: 'fromDate'
    value: [date]
  thru:
    name: 'thruDate'
    allowBlank: true
  both:
    disableTime: true
    fieldWidth: 100
)
*/

const DateTimeRangeFieldSet = Ext.define(null, {
  extend: Ext.Panel,

  constructor(cfg = {}) {
    // Default config is overridden by cfg.both then cfg.from
    this.fromField = new DateTimeField(
      Ext.apply(
        {
          fieldLabel: t('From Date'),
          listeners: {
            change: () => this.fireEvent('change', this),
            scope: this
          },
          allowBlank: cfg.allowBlank,
          width: cfg.width
        },
        cfg.from,
        cfg.both
      )
    )

    // Default config is overridden by cfg.both then cfg.thru
    this.thruField = new DateTimeField(
      Ext.apply(
        {
          fieldLabel: t('Thru Date'),
          listeners: {
            change: () => this.fireEvent('change', this),
            scope: this
          }
        },
        cfg.thru,
        cfg.both
      )
    )

    Ext.apply(this, {
      layout: 'form',
      border: false,
      items: [this.fromField, this.thruField]
    })
    this.callParent([cfg])

    this.addEvents('change')
    // setMinMax needs to be called after callParent so widget is rendered
    this.setMinMax()
    this.on('change', this.setMinMax)
  },

  setFromValue(value) {
    this.fromField.setValue(value)
  },

  setThruValue(value) {
    this.thruField.setValue(value)
  },

  getFromValue() {
    return this.fromField.getValue()
  },

  getThruValue() {
    return this.thruField.getValue()
  },

  // Smartly set min/max for fields based on provided and current values.
  setMinMax() {
    const fromValue = this.getFromValue()
    const thruValue = this.getThruValue()

    if (this.minValue) {
      this.fromField.setMinValue(this.minValue)
    }

    if (thruValue || this.maxValue) {
      const newMaxValue =
        thruValue && this.maxValue
          ? new Date(Math.min(thruValue, this.maxValue))
          : thruValue || this.maxValue

      this.fromField.setMaxValue(newMaxValue)
    }

    if (fromValue || this.minValue) {
      const newMinValue =
        fromValue && this.minValue
          ? new Date(Math.max(fromValue, this.minValue))
          : fromValue || this.minValue

      this.thruField.setMinValue(newMinValue)
    }

    if (this.maxValue) {
      return this.thruField.setMaxValue(this.maxValue)
    }
  }
})

module.exports = DateTimeRangeFieldSet
