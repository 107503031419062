const Ext = require('ext')
const t = require('translate')

const TaskButtonsPanel = Ext.define(null, {
  extend: Ext.Toolbar,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, { enableOverflow: true })
    this.cmenu = new Ext.menu.Menu({
      items: [
        {
          cls: 'minimiseAll',
          disabled: true,
          text: t('Minimize all'),
          handler() {
            this.find('button').forEach((button) => button.win.minimize())
            return this.updateContextMenus()
          },
          scope: this
        },
        {
          cls: 'closeAll',
          disabled: true,
          text: t('Close all'),
          handler: this.closeAll,
          scope: this
        }
      ]
    })
    this.callParent([cfg])
    this.on(
      'render',
      function () {
        this.el.on(
          'contextmenu',
          function (e) {
            e.stopEvent()
            if (!this.cmenu.rendered) {
              this.cmenu.render()
            }
            let [x, y] = Array.from(e.getXY())
            y -= this.cmenu.getHeight()
            this.cmenu.showAt([x, y])
          },
          this
        )
      },
      this
    )
  },

  setActiveWindow(win) {
    const button = this.findButton(win)
    button?.toggle(true)
  },

  deactivateWindow(win) {
    const button = this.findButton(win)
    button?.toggle(false)
  },

  addButton(win) {
    const TaskButton = require('admin/component/TaskButton')

    this.callParent([
      new TaskButton({
        taskbar: this,
        win
      })
    ])
    this.updateContextMenus()
  },

  findButton(win) {
    return this.find('button').find((button) => button.win === win)
  },

  // Remove the taskbar button matching the passed window
  removeWindowButton(win) {
    const button = this.findButton(win)
    if (button) {
      button.destroy()
      this.doLayout()
    }
  },

  updateContextMenus() {
    const buttonCount = this.items.getCount()
    this.items.getRange().forEach(function (button) {
      const { cmenu } = button
      const closeOthersButton = cmenu.findButton('closeOthers')
      closeOthersButton?.setDisabled(buttonCount === 1)
    })
    this.cmenu.get(0).setDisabled(buttonCount === 0 || this.allMinimised()) // Minimise all
    this.cmenu.get(1).setDisabled(buttonCount === 0)
  }, // Close all

  allMinimised() {
    return this.items
      .getRange()
      .every((button) => button.win.minimizable && button.win.minimized)
  },

  minimiseAll() {
    this.find('button').forEach((button) => button.win.minimize())
    this.updateContextMenus()
  },

  closeAll() {
    this.closeOthers(null)
  },

  closeOthers(win) {
    this.find('button').forEach(function (button) {
      if (button.win !== win) {
        button.win.close()
      }
    })
    this.updateContextMenus()
  }
})

module.exports = TaskButtonsPanel
