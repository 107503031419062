import Api from 'api'
import * as hal from 'hal'
import {
  ARCHIVING_CONFIGURATION_ENABLE_REL,
  ARCHIVING_CONFIGURATION_GLOBAL_REL
} from 'product/resources'
import logger from 'system/logger'

export const ARCHIVING_MEMBERSHIP_START_LOADING = 'ARCHIVING_MEMBERSHIP_START_LOADING'
export const ARCHIVING_MEMBERSHIP_STOP_LOADING = 'ARCHIVING_MEMBERSHIP_STOP_LOADING'
export const ARCHIVING_MEMBERSHIP_START_MUTATING = 'ARCHIVING_MEMBERSHIP_START_MUTATING'
export const ARCHIVING_MEMBERSHIP_STOP_MUTATING = 'ARCHIVING_MEMBERSHIP_STOP_MUTATING'
export const ARCHIVING_MEMBERSHIP_INIT_DB = 'ARCHIVING_MEMBERSHIP_INIT_DB'
export const ARCHIVING_MEMBERSHIP_SET_CONFIGURATION =
  'ARCHIVING_MEMBERSHIP_SET_CONFIGURATION'
export const ARCHIVING_MEMBERSHIP_SET_GLOBAL = 'ARCHIVING_MEMBERSHIP_SET_GLOBAL'
export const ARCHIVING_MEMBERSHIP_SET_TYPES = 'ARCHIVING_MEMBERSHIP_SET_TYPES'

const startLoading = (companyKey) => ({
  type: ARCHIVING_MEMBERSHIP_START_LOADING,
  companyKey
})

const stopLoading = (companyKey) => ({
  type: ARCHIVING_MEMBERSHIP_STOP_LOADING,
  companyKey
})

const startMutating = (companyKey) => ({
  type: ARCHIVING_MEMBERSHIP_START_MUTATING,
  companyKey
})

const stopMutating = (companyKey) => ({
  type: ARCHIVING_MEMBERSHIP_STOP_MUTATING,
  companyKey
})

/// ========= INIT ========= ///

const initDb = (companyKey, uri) => ({
  type: ARCHIVING_MEMBERSHIP_INIT_DB,
  companyKey,
  uri
})

export const setCompany = (companyKey, uri) => (dispatch) => {
  dispatch(initDb(companyKey, uri))
  dispatch(refresh(companyKey, uri))
}

/// ========= LIST SET/REFRESH ========= ///

export const setConfiguration = (companyKey, global, types, globalLink, enableLink) => ({
  type: ARCHIVING_MEMBERSHIP_SET_CONFIGURATION,
  companyKey,
  global,
  types,
  globalLink,
  enableLink
})

export const refresh = (companyKey, uri) => async (dispatch) => {
  dispatch(startLoading(companyKey))

  try {
    const response = await Api.get(uri)

    dispatch(
      setConfiguration(
        companyKey,
        response.data.global,
        response.data.types,
        hal.href(response.data, ARCHIVING_CONFIGURATION_GLOBAL_REL),
        hal.href(response.data, ARCHIVING_CONFIGURATION_ENABLE_REL)
      )
    )
    dispatch(stopLoading(companyKey))
  } catch (err) {
    logger.error(err)
    dispatch(stopLoading(companyKey))
  }
}

/// ========= FORM ========= ///

export const setGlobal = (companyKey, global) => ({
  type: ARCHIVING_MEMBERSHIP_SET_GLOBAL,
  companyKey,
  global
})

export const setTypes = (companyKey, types) => ({
  type: ARCHIVING_MEMBERSHIP_SET_TYPES,
  companyKey,
  types
})

export const save = (
  companyKey,
  uri,
  global,
  globalLink,
  enabledMemberNames,
  enableLink
) => {
  return async (dispatch) => {
    dispatch(startMutating(companyKey))

    const globalResponse = Api.post(
      globalLink,
      { enabled: global },
      { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
    )

    const enabledResponse = Api.post(
      enableLink,
      { name: enabledMemberNames },
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }
    )

    Promise.all([globalResponse, enabledResponse])
      .then(() => {
        dispatch(refresh(companyKey, uri))
      })
      .catch((err) => {
        logger.error(err)
        dispatch(stopMutating(companyKey))
      })
  }
}
