const Ext = require('ext')
const t = require('translate')
const token = require('api/token')
const Sentry = require('system/sentry').default

// Login also handles password reset.
const Login = Ext.define(null, {
  extend: Ext.Viewport,

  layout: 'fit',
  maskMsg: Ext.LoadMask.prototype.msg,
  maskMsgCls: 'x-mask-loading',

  // Passed in by parent.
  onLogin() {},

  initComponent() {
    this.items = new Ext.Panel({
      autoScroll: true,
      id: 'center-login',
      border: false,
      items: (this.form = new Ext.form.FormPanel({
        border: false,
        buttonAlign: 'left',
        id: 'login-form',
        cls: 'x-left-form-panel',
        labelAlign: 'right',
        labelWidth: 98,
        monitorValid: true,
        width: 400,
        bindHandler: () => {
          this.login.setDisabled(this.form.submitting)
          if (!this.form.submitting) {
            return Ext.form.FormPanel.prototype.bindHandler.call(this.form)
          }
        },
        keys: [
          {
            key: [Ext.EventObject.ENTER],
            fn: () => {
              if (this.form.form != null ? this.form.form.isValid() : undefined) {
                return this.doSubmit()
              }
            }
          }
        ],
        items: [
          {
            bodyCssClass: 'logo-panel',
            border: false,
            height: 225,
            xtype: 'panel'
          },
          (this.username = new Ext.form.TextField({
            allowBlank: false,
            fieldLabel: t('Email'),
            name: 'username',
            width: 240,
            value: Ext.util.Cookies.get('username')
          })),
          (this.password = new Ext.form.TextField({
            allowBlank: false,
            fieldLabel: t('Password'),
            inputType: 'password',
            name: 'password',
            width: 240
          })),
          (this.login = new Ext.Button({
            cls: 'login-button primary',
            formBind: true,
            text: t('Login'),
            minWidth: 80,
            iconCls: 'icon-login',
            handler: () => this.doSubmit()
          })),
          new Ext.ux.Link({
            cls: 'reset-link',
            text: t('Reset password'),
            handler: () => {
              this.onForgotPassword()
              return false
            }
          })
        ]
      }))
    })

    this.on('resize', this.center, this)

    this.callParent()
  },

  afterRender() {
    this.callParent()

    this.center()
    this.focusForm()

    // TODO: These cookies won't be needed when IE9 support is removed.
    // We will be able to remove query strings without reloading the page
    // so can keep variables locally.
    if (this.getCookie('expired')) {
      this.setMessage({ text: t('Session expired. Please log in again to continue.') })
    } else if (this.getCookie('timedout')) {
      this.setMessage({
        text: t('Session closed due to inactivity.<br/>Please log in again to continue.')
      })
    } else if (this.getCookie('changedpermissions')) {
      this.setMessage({
        text: t('You have been logged out due to changing your permissions.')
      })
    } else if (this.getCookie('forgotpassword')) {
      this.onForgotPassword()
    }

    const passwordResetResult = this.getCookie('passwordreset')
    if (passwordResetResult === 'error') {
      return this.setErrorMessage({
        text: t(
          'There was a problem resetting your password.<br/>Please try again or contact support.'
        )
      })
    } else if (passwordResetResult === 'success') {
      return this.setMessage({ text: t('We have just emailed you a new password.') })
    }
  },

  center() {
    const el = this.form.getEl()
    if (!el) return
    el.alignTo(document, 'c-c', [0, -100])
  },

  doSubmit() {
    this.form.submitting = true
    const username = Ext.util.Format.trim(this.username.getValue())
    this.mask()

    return Ext.Ajax.request({
      url: '/api/login',
      method: 'POST',
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this),
      enableForm: () => {
        this.form.submitting = false
      },
      params: {
        username,
        password: this.password.getValue().replace(/\s+$/, '')
      },
      success: (response) => {
        try {
          this.unmask()
          this.form.submitting = false

          const respTxt = JSON.parse(response.responseText)
          const newToken = respTxt && respTxt.token

          if (newToken) {
            token.set(newToken)

            Ext.util.Cookies.set('username', username, new Date().add(Date.MONTH, 1))

            return this.onLogin()
          }

          this.onFailedLogin()
        } catch (exc) {
          Sentry.addBreadcrumb({
            category: 'response',
            message: response.responseText
          })
          Sentry.captureException(exc)
          this.onFailedLogin()
        }
      },
      failure: (response) => {
        this.unmask()
        this.form.submitting = false
        this.onFailedLogin(response.status)
      }
    })
  },

  getCookie(cookie) {
    const c = Ext.util.Cookies.get(cookie)
    Ext.util.Cookies.clear(cookie)
    return c
  },

  setMessage(cfg = {}) {
    let { text, title, cls } = cfg

    if (cls == null) {
      cls = 'information'
    }

    if (title) {
      text = `${title} — ${text}`
    }

    this.clearMessages()
    this.form.insert(1, {
      border: false,
      ctCls: 'smx-msg',
      html: `<div class='${cls}'><span class='message-icon'></span><p class='message-text'>${text}</p></div>`
    })
    return this.form.doLayout()
  },

  setErrorMessage(cfg = {}) {
    cfg.cls = 'warning'
    return this.setMessage(cfg)
  },

  clearMessages() {
    return this.form.find('ctCls', 'smx-msg').forEach((msg) => msg.destroy())
  },

  onFailedLogin(status) {
    if (status === 401) {
      this.setErrorMessage({
        text: t('Email or password is invalid.<br/>Please check them and try again.')
      })
      this.password.setValue()
    } else {
      this.setErrorMessage({
        title: t('Authentication error'),
        text: t('An error has occurred authenticating the user. Please try again.')
      })
    }

    return this.password.focus()
  },

  onForgotPassword() {
    return Ext.MessageBox.show({
      msg: t(
        "Please enter your username and we'll send you instructions on how to reset your password:"
      ),
      multiline: false,
      prompt: true,
      title: t('Reset password'),
      value: this.username.getValue(),
      width: 400,
      height: 130,
      buttons: Ext.MessageBox.OKCANCEL,
      fn: (btn, text) => {
        if (btn === 'ok') {
          const username = text.trim()
          if (username === '') {
            return Ext.MessageBox.alert(
              t('Empty username'),
              t('You must enter a username.'),
              () => this.onForgotPassword()
            )
          }
          return this.resetPassword(username)
        }
        return this.focusForm()
      }
    })
  },

  resetPassword(username) {
    this.mask()
    return Ext.Ajax.request({
      url: '/api/system/passwordreset',
      method: 'POST',
      params: {
        username
      },
      mask: this.mask.bind(this),
      unmask: this.unmask.bind(this),
      success: () => {
        this.unmask()
        // Success is returned regardless of existence of user otherwise it leaks existence.
        return this.setMessage({
          title: t('Request completed'),
          text: t(
            'If we found your user, we have just emailed you password reset instructions.'
          )
        })
      },
      failure: (response) => {
        this.unmask()
        // 409s are for when a reset email has already been sent recently, see SCL-2324
        // display a general success message otherwise it leaks existence of user
        if (response.responseText && response.status === 409) {
          return this.setMessage({
            title: t('Request completed'),
            text: t(
              'If we found your user, we have just emailed you password reset instructions.'
            )
          })
          // a 410 means the password reset token has already been used.
          // display the response text the backend has returned.
        } else if (response.responseText && response.status === 410) {
          return this.setErrorMessage({ text: response.responseText })
        }
        // otherwise just display a general error
        return this.setErrorMessage({
          title: t('An error has occurred'),
          text: t(
            'If we found your user, we were unable to email you password reset instructions. Please try again later.'
          )
        })
      }
    })
  },

  focusForm() {
    if (this.username.getValue()) {
      return this.password.focus(100)
    }
    return this.username.focus(100)
  },

  destroy() {
    // Viewport doesn't remove its own class on destroy.
    this.getEl().removeClass(this.cls)
    this.callParent()
  }
})

module.exports = Login
