const Ext = require('ext')
const t = require('translate')
const CompanyResource = require('admin/business/CompanyResource')
const ResellerDetailPanel = require('admin/view/ResellerDetailPanel')
const ResellerNewPanel = require('admin/view/ResellerNewPanel')
const ResellerListPanel = require('admin/view/ResellerListPanel')
const ResellerRecord = require('admin/data/company/ResellerRecord')
const VendorResource = require('admin/business/VendorResource')
const DistributorResource = require('admin/business/DistributorResource')

const ResellerResource = Ext.define(null, {
  extend: CompanyResource,
  singleton: true,

  id: 'SMX.Reseller',
  translations: {
    title: t('Reseller'),
    plural: t('Resellers'),
    new: t('New Reseller'),
    open: t('Open Reseller'),
    list: t('List Resellers')
  },
  name: t('Reseller'),
  code: 'RESELLER',
  resourceName: '/reseller',

  operations: {
    create: true,
    open: true,
    remove: false,
    list: true,
    save: true
  },

  layout: {
    newResource: {
      width: 400,
      height: 'auto'
    },
    openResource: {
      width: 725,
      height: 570,
      minHeight: 570
    }
  },

  detailPanel: ResellerDetailPanel,
  newPanel: ResellerNewPanel,
  listPanel: ResellerListPanel,

  record: ResellerRecord,

  getParentResources() {
    return [VendorResource, DistributorResource]
  }
})

module.exports = ResellerResource
