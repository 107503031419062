const Ext = require('ext')
const t = require('translate')
const Component = require('smartrules/designer/Component')

const JoinWord = Ext.define(null, {
  extend: Component,

  border: false,
  constructor(cfg = {}) {
    cfg = Ext.applyIf({
      cls: 'smartrules-joinword',
      html: t('and'),
      width: 35
    })

    this.callParent([cfg])
  },

  update(word) {
    if (this.rendered) {
      return Ext.Panel.prototype.update.call(this, word)
    }

    this.html = word
  }
})

module.exports = JoinWord
