const Ext = require('ext')
const t = require('translate')
const ResourceDetailPanel = require('admin/view/ResourceDetailPanel')
const RecordFormPanel = require('admin/component/form/RecordFormPanel')
const ProxyStore = require('admin/data/ProxyStore')
const User = require('admin/core/User')
const lowercaseListeners = require('admin/util/lowercaseListeners')
const ComboBox = require('admin/component/form/ComboBox')
const ReadOnlyTextField = require('admin/component/form/ReadOnlyTextField')
const DomainStatus = require('admin/util/DomainStatus')
const validators = require('validators').default

const ScrubbingDomainDetailPanel = Ext.define(null, {
  extend: ResourceDetailPanel,

  cls: 'scrubbingdomain-detail-panel',

  module() {
    return require('admin/business/ScrubbingDomainResource')
  },

  constructor(cfg = {}) {
    const customer = cfg.record.store.parentRecord
    this.mailRelayHostStore = customer.getMailRelayHostStore()
    this.mailRelayHostStore.reload()

    // Refresh the store in case someone created a mail relay host in this window.
    cfg.record.on(
      'sync',
      function () {
        return this.mailRelayHostStore.reload()
      },
      this,
      { single: true }
    )

    this.callParent([cfg])
  },

  getFormPanel(cfg) {
    const ScrubbingDomainResource = require('admin/business/ScrubbingDomainResource')

    Ext.applyIf(cfg, {
      editableView:
        ScrubbingDomainResource.operations.save ||
        require('admin/business/MailRelayHostResource').operations.create
    })

    return new RecordFormPanel({
      record: cfg.record,
      module: cfg.module,
      operation: 'save',
      items: this.getDetailItems(cfg),
      validateForm: ScrubbingDomainResource.validateScrubbingDomainForm
    })
  },

  getDetailItems() {
    // ComboBox modifies the store so we need a proxy.
    const proxyStore = new ProxyStore(this.mailRelayHostStore)

    const fields = []
    if (User.can('APPROVAL', 'PUT') && this.module.canSave()) {
      fields.push({
        fieldLabel: t('Domain Name'),
        enableKeyEvents: true,
        listeners: lowercaseListeners,
        name: 'name',
        xtype: 'textfield',
        allowBlank: false,
        validator: validators.domain.withMsg(t('You must enter a valid DNS Name'))
      })
    } else {
      fields.push(
        new ReadOnlyTextField({
          fieldLabel: t('Domain Name'),
          name: 'name'
        })
      )
    }

    const mailRelayHost = new ComboBox({
      allowBlank: false,
      fieldLabel: t('Mail Relay Host'),
      hiddenName: 'relayTo',
      displayField: 'name',
      store: proxyStore,
      triggerAction: 'all'
      // disabled since SCL-3433 due to recursion issues (async and performance)
      // validator: validators.domainOrIp.withMsg(t('You must enter a valid DNS Name or IP Address'))
    })

    // Force lower case
    mailRelayHost.on('change', function () {
      return (this.getEl().dom.value = (this.el.dom.value || '').toLowerCase())
    })

    fields.push(mailRelayHost)

    if (User.can('APPROVAL', 'PUT')) {
      fields.push(
        new ComboBox({
          allowBlank: false,
          editable: false,
          fieldLabel: t('Approval Status'),
          hiddenName: 'approvalStatus',
          store: [
            ['APPROVED', DomainStatus.translateApproval('APPROVED')],
            ['PENDING', DomainStatus.translateApproval('PENDING')],
            ['REJECTED', DomainStatus.translateApproval('REJECTED')]
          ],
          triggerAction: 'all'
        })
      )
    } else {
      fields.push(
        new ReadOnlyTextField({
          fieldLabel: t('Approval Status'),
          value: DomainStatus.translateApproval(this.record.get('approvalStatus'))
        })
      )
    }

    return fields
  }
})

module.exports = ScrubbingDomainDetailPanel
