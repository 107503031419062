import React, { Component } from 'react'

import classNames from 'classnames'
import AttachmentInfo from 'mail/details/attachment/views/Info'
import AttachmentList from 'mail/details/attachment/views/List'
import Guard from 'mail/details/guard/views/Panel'
import ContentPreview from 'mail/details/preview/views/Content'
import HeaderPreview from 'mail/details/preview/views/Header'
import ContentWarning from 'mail/details/preview/views/Warning'
import Summary from 'mail/details/summary/views/Panel'
import PropTypes from 'prop-types'
import { Alert, Panel, PanelGroup } from 'react-bootstrap'
import t from 'translate'

class MailPanel extends Component {
  static propTypes = {
    preview: PropTypes.object,
    attachmentPanelExpanded: PropTypes.bool,
    onAttachmentPanelToggle: PropTypes.func,
    mail: PropTypes.object,
    resultsQuery: PropTypes.string,
    availableKeywords: PropTypes.arrayOf(PropTypes.string),
    onBack: PropTypes.func,
    onSingleMessageAuthorization: PropTypes.func,
    onSessionAuthorization: PropTypes.func,
    enableHighlighting: PropTypes.bool,
    loading: PropTypes.bool,
    authorized: PropTypes.bool
  }

  shouldInitiallyExpandAttachments() {
    return (
      this.props.preview &&
      this.props.preview.attachments &&
      this.props.preview.attachments.length < 4
    )
  }

  shouldShowSummary() {
    return !!this.props.mail
  }

  renderSummary() {
    return (
      <Panel defaultExpanded className='collapsible mail-summary-panel'>
        <Panel.Heading>
          <Panel.Title>{t('Summary')}</Panel.Title>
          <Panel.Title toggle className='toggler' />
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <Summary
              result={this.props.mail}
              resultsQuery={this.props.resultsQuery}
              availableKeywords={this.props.availableKeywords}
              enableHighlighting={this.props.enableHighlighting}
            />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    )
  }

  renderAttachments() {
    return (
      <Panel
        className='collapsible attachments-panel'
        expanded={this.props.attachmentPanelExpanded}
        onToggle={this.props.onAttachmentPanelToggle}
      >
        <Panel.Heading>
          <Panel.Title toggle>
            <AttachmentInfo attachments={this.props.preview.attachments} />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <AttachmentList attachments={this.props.preview.attachments} />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    )
  }

  handlePreviewCollapse = () => {
    if (this.props.onAttachmentPanelToggle) {
      // when we collapse preview, always also collapse attachment panel
      this.props.onAttachmentPanelToggle(false)
    }
  }

  hasRemoteContent() {
    if (this.props.preview['html-body']) {
      // todo display only when it really has remote contents
      // for now we agreed to a static mail
      return true
    }

    return false
  }

  renderContentWarning() {
    return <ContentWarning />
  }

  guardEnabled = () => {
    return this.props.onSingleMessageAuthorization && this.props.onSessionAuthorization
  }

  shouldShowGuard() {
    // only use a guard when there are authorization handlers
    // otherwise just suppress it, i.E. for quarantine
    return this.guardEnabled() && !this.props.authorized
  }

  renderGuard() {
    return (
      <Guard
        onCancel={this.props.onBack}
        onSingleMessageAuthorization={this.props.onSingleMessageAuthorization}
        onSessionAuthorization={this.props.onSessionAuthorization}
      />
    )
  }

  renderPreviewPanel() {
    if (this.shouldShowGuard()) {
      return this.renderGuard()
    }

    return (
      <Panel defaultExpanded className='collapsible mail-preview-panel'>
        <Panel.Heading>
          <Panel.Title>
            <HeaderPreview
              resultsQuery={this.props.resultsQuery}
              preview={this.props.preview}
              availableKeywords={this.props.availableKeywords}
              enableHighlighting={this.props.enableHighlighting}
            />
          </Panel.Title>
          <Panel.Title toggle className='toggler' />
          {this.props.preview.attachments && this.renderAttachments()}
        </Panel.Heading>
        {this.hasRemoteContent() && this.renderContentWarning()}
        <Panel.Collapse onExiting={this.handlePreviewCollapse}>
          <Panel.Body>
            <ContentPreview preview={this.props.preview} />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    )
  }

  hasPreview() {
    return !!this.props.preview
  }

  getPanelGroupId() {
    // it is unfortunate that backend is not consistent with naming in their APIs.
    if (this.props.preview.id) {
      return this.props.preview.id
    } else if (this.props.preview['message-id']) {
      return this.props.preview['message-id']
    }

    return this.props.mail.sid
  }

  renderPreview() {
    return (
      <PanelGroup
        id={this.getPanelGroupId()}
        className={classNames('mail-detail', { busy: this.props.loading })}
      >
        {this.shouldShowSummary() && this.renderSummary()}
        {this.renderPreviewPanel()}
      </PanelGroup>
    )
  }

  renderNoPreview() {
    return (
      <Alert bsStyle='warning'>{t('Mail is currently unavailable for preview')}</Alert>
    )
  }

  render() {
    return <div>{this.hasPreview() ? this.renderPreview() : this.renderNoPreview()}</div>
  }
}

export default MailPanel
