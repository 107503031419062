const Ext = require('ext')

const KeyboardShortcut = Ext.define(null, {
  singleton: true,

  id: 'SMX.util.KeyboardShortcut',

  keyMap: new Ext.KeyMap(document),

  addShortcut(config) {
    return this.keyMap.addBinding(Ext.applyIf(config, { stopEvent: true }))
  }
})

module.exports = KeyboardShortcut
