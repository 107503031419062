import React, { Component } from 'react'

import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { Tabs } from 'react-bootstrap'

class TabsComponent extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    tabsId: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      tabbedPaneWidth: null,
      tabbedPaneHeight: null
    }
  }

  // once the height of the tabbed nav bar is known, we can calculate the correct
  // height for the tab panels themselves automatically
  distributeHeights = (nextProps) => {
    const props = nextProps || this.props
    // eslint-disable-next-line react/no-find-dom-node
    const wholePanel = ReactDOM.findDOMNode(this.inner)
    const navBar = wholePanel.querySelector('ul.nav-tabs')

    // Want to be careful with this, otherwise you'll get stuck in an infinite render loop
    // when used with componentDidUpdate
    this.setState({
      tabbedPaneWidth: props.width,
      tabbedPaneHeight: props.height - navBar.clientHeight
    })
  }

  componentDidMount() {
    this.distributeHeights()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.distributeHeights(nextProps)
  }

  resizeChildren(children) {
    return React.Children.map(children, (child) => {
      if (!child) return

      // then, also set the width/height in all components inside the tabbed panels
      // set these as properties and as a style both,
      // so that it will work in both environments: develop and production
      const grandChildren = React.Children.map(child.props.children, (grandChild) => {
        const grandChildNewStyle = Object.assign(
          {
            width: this.state.tabbedPaneWidth,
            height: this.state.tabbedPaneHeight
          },
          grandChild.props.style
        )

        return React.cloneElement(grandChild, {
          width: this.state.tabbedPaneWidth,
          height: this.state.tabbedPaneHeight,
          style: grandChildNewStyle
        })
      })

      const newStyle = Object.assign(
        {
          width: this.state.tabbedPaneWidth,
          height: this.state.tabbedPaneHeight
        },
        child.props.style
      )

      return React.cloneElement(
        child,
        {
          width: this.state.tabbedPaneWidth,
          height: this.state.tabbedPaneHeight,
          style: newStyle
        },
        grandChildren
      )
    })
  }

  // todo: optimize this class because it causes an unnecessary render after the initial render.
  // this because of the above componentDidMount() calling distributeHeights()
  // which changes the state, causing another render()
  render() {
    let children = this.props.children

    if (this.state.tabbedPaneWidth && this.state.tabbedPaneHeight) {
      // first, loop through all tabbed panels and set their widths/heights
      children = this.resizeChildren(children)
    }

    return (
      <Tabs
        ref={(input) => (this.inner = input)}
        id={this.props.tabsId}
        defaultActiveKey={this.props.defaultActiveKey}
      >
        {children}
      </Tabs>
    )
  }
}

export default TabsComponent
