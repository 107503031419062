import { Col, Form, FormGroup, Modal, Row } from 'react-bootstrap'
import React, { Component } from 'react'

import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Field from 'components/form/Field'
import Mask from 'components/Mask'
import PropTypes from 'prop-types'
import User from 'admin/core/User'
import { authenticationFormDisabled } from '../../subs'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import t from 'translate'

class AuthenticationDialog extends Component {
  static propTypes = {
    modalTitle: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  disableSubmit() {
    // for their meanings see http://redux-form.com/6.8.0/docs/api/Props.md/
    return this.props.pristine || this.props.invalid || this.props.disabled
  }

  render() {
    return (
      <Mask>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>{this.props.modalTitle}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.props.onSubmit}>
            <Modal.Body>
              <Row>
                <FormGroup>
                  <div className='icon-password col-xs-2 col-xs-offset-1 control-label' />
                  <h1 className='col-xs-8'>{this.props.headerText}</h1>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup>
                  <label className='col-xs-2 col-xs-offset-1 control-label'>
                    {t('Username')}
                  </label>
                  <Col xs={8}>
                    <p className='form-control'>{User.getEmail()}</p>
                  </Col>
                </FormGroup>
              </Row>
              <Field
                autoFocus
                name='password'
                store={this.props.store}
                disabled={this.props.disabled}
                label={t('Password')}
                type='password'
              />
            </Modal.Body>

            <Modal.Footer>
              <CancelSubmitButtons
                disableCancel={this.props.disabled}
                disableSubmit={this.disableSubmit()}
                onCancel={this.props.onCancel}
                submitText={this.props.submitButtonText}
              />
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, props) => ({
  disabled: authenticationFormDisabled(db, props.companyKey)
})

const AuthenticationForm = reduxForm({ form: 'providerAuthenticationForm' })(
  AuthenticationDialog
)

export default connect(mapDbToProps)(AuthenticationForm)
