const Record = require('admin/core/Record')
const Time = require('admin/time/Time')
const InvoiceResource = require('admin/business/InvoiceResource')

const InvoiceRecord = Record.define(null, {
  module() {
    return InvoiceResource
  },

  fields: [
    {
      name: 'id',
      type: 'int'
    },
    {
      name: 'invoiceRun.id',
      type: 'int'
    },
    {
      name: 'party.id',
      type: 'int'
    },
    {
      name: 'party.groupName',
      type: 'string'
    },
    {
      name: 'displayName',
      type: 'string'
    },
    {
      name: 'invoiceType',
      type: 'string',
      mapping: 'invoiceType.description'
    },
    {
      name: 'invoiceReference',
      type: 'string'
    },
    {
      name: 'invoiceDate',
      type: 'date',
      convert: Time.toJS
    },
    {
      name: 'description',
      type: 'string'
    },
    {
      name: 'invoiceMessage',
      type: 'string'
    },
    {
      name: 'amount',
      type: 'float'
    },
    {
      name: 'currencyUom',
      type: 'string',
      mapping: 'currencyUom.code'
    },
    {
      name: 'status',
      type: 'string',
      mapping: 'status.description'
    },
    {
      name: 'statusCode',
      type: 'string',
      mapping: 'status.code'
    },
    {
      name: 'invoiceClassificationName',
      type: 'string'
    },
    {
      name: 'billingClassificationName',
      type: 'string'
    },
    {
      name: 'directDebit',
      type: 'boolean'
    },
    {
      name: 'domestic',
      type: 'boolean'
    },
    {
      name: 'firstInvoice',
      type: 'boolean'
    },
    {
      name: 'changedInvoice',
      type: 'boolean'
    },
    {
      name: 'mixedParty',
      type: 'boolean'
    }
  ],

  getName() {
    return this.get('displayName')
  },

  getDescription() {
    return `${this.get('party.groupName')}:${this.get('id')}`
  },

  getParams() {
    return {
      description: this.get('description'),
      invoiceMessage: this.get('invoiceMessage')
    }
  }
})

module.exports = InvoiceRecord
