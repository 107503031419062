const Record = require('admin/core/Record')
const SharedServiceResource = require('admin/business/SharedServiceResource')

const SharedServiceRecord = Record.define(null, {
  module() {
    return SharedServiceResource
  },

  fields: [
    {
      name: 'name',
      type: 'string'
    },
    {
      name: 'enabled',
      type: 'boolean'
    }
  ],

  getName() {
    return this.get('name')
  },

  getParams() {
    return {
      name: this.get('name'),
      enabled: this.get('enabled')
    }
  }
})

module.exports = SharedServiceRecord
