const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')

const StopProcessing = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Stop processing this ruleset'),
      type: 'smartrules-stop-processing'
    })
    this.callParent([cfg])
  },

  toDsl() {
    return 'do not process any additional rules'
  }
})

module.exports = StopProcessing
