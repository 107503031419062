import React, { Component } from 'react'
import CancelSubmitButtons from 'components/form/CancelSubmitButtons'
import Field from 'components/form/Field'
import Grid from 'components/Grid'
import PasswordInput from 'components/legacy/PasswordInput'
import Mask from 'components/Mask'
import _ from 'lodash'
import {
  disableCatalogue,
  disableEmailInput,
  disableForm,
  editing,
  forwardingStyle,
  forwardValid,
  mailboxForm,
  password,
  passwordValid,
  requireForward,
  requirePassword,
  requireRepeatPassword,
  showPasswordInput
} from 'mailboxes/subs'
import Catalogue from 'mailboxes/views/form/Catalogue'
import { list as catalogue } from 'mailhosting/catalogue/subs'
import { refresh as refreshDomains } from 'mailhosting/domain/actions'
import {
  empty as domainsEmpty,
  loaded as domainsLoaded,
  names as domainNames,
  uri as domainsUri
} from 'mailhosting/domain/subs'
import PropTypes from 'prop-types'
import { Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { change, reduxForm, stopAsyncValidation, touch } from 'redux-form'
import t from 'translate'
import validators from 'validators'

const FORM_NAME = 'mailboxForm'

const FORWARD_STYLES = {
  DISABLED: 'Disable Forwarding',
  FORWARD: 'Forward',
  STORE_AND_FORWARD: 'Store and Forward'
}

class MailboxDialog extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    companyKey: PropTypes.string.isRequired,
    mailbox: PropTypes.object,
    submitButtonText: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      alias: props.mailboxForm?.values?.alias || '',
      forward: props.mailboxForm?.values?.forward || '',
      forwardError: undefined
    }
  }

  UNSAFE_componentWillMount() {
    this.props.refreshDomains(this.props.domainsUri)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mailboxForm?.values?.alias !== this.props.mailboxForm?.values?.alias) {
      this.setState(
        { alias: this.props.mailboxForm.values.alias || '' },
        this.validateForwards
      )
    }
  }

  disableSubmit = () => {
    if (!this.props.mailboxForm || !this.props.mailboxForm.values) {
      return true
    }
    if (this.hasDuplicatesInAliases(this.props.mailboxForm.values.forward)) {
      return true
    }
    return Boolean(
      !this.props.forwardValid ||
        (this.props.requirePassword && !this.props.password) ||
        !this.props.passwordValid ||
        this.props.disableForm ||
        this.props.pristine ||
        this.props.invalid
    )
  }

  hasDuplicatesInAliases = (fwds) => {
    const aliases =
      (this.state.alias && this.state.alias.split('\n').map((item) => item.trim())) || []
    const forwards = (fwds && fwds.split('\n').map((item) => item.trim())) || []
    return aliases.some((alias) => forwards.includes(alias))
  }

  validateForwards = () => {
    const { forward } = this.state
    let forwardError

    if (!forward) {
      forwardError = undefined
    } else if (this.hasDuplicatesInAliases(forward)) {
      forwardError = 'An alias and a forward cannot have the same email'
    } else {
      forwardError = undefined
    }
    this.setState({ forwardError })
    return t(forwardError)
  }

  handleForwardChange = (e) => {
    this.setState({ forward: e.target.value }, this.validateForwards)
  }

  values() {
    const values = _.omit(this.props.mailboxForm.values, 'repeatPassword')
    return values
  }

  validateDomain = (value) => {
    if (!this.props.domainsLoaded) return
    if (value === undefined) return

    if (this.props.domainsEmpty) {
      return t('Please add a mail hosting domain first.')
    }

    const emails = validators.linebrokenEmails(value)

    if (validators.invalidDomain(emails, this.props.domainNames)) {
      return t('One address contains unknown hosting domain.')
    }
  }

  onPasswordChange = (newPassword, error) => {
    // unfortunately we will have to set the password manually since
    // the react component is still legacy and needs to be ported to redux.
    this.props.changePassword(newPassword)

    // workaround to manually invalidate a field
    // TODO change to updateSyncErrors later when upgrading redux-form
    this.props.dispatch(stopAsyncValidation(FORM_NAME, error))
  }

  onRepeatPasswordChange = (newRepeatPassword, error) => {
    this.props.changeRepeatPassword(newRepeatPassword)
    this.props.dispatch(stopAsyncValidation(FORM_NAME, error))
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.onSubmit(this.values())
  }

  renderPasswordInput() {
    return (
      <PasswordInput
        requirePassword={this.props.requirePassword}
        requireRepeatPassword={this.props.requireRepeatPassword}
        onPasswordChange={this.onPasswordChange}
        onRepeatPasswordChange={this.onRepeatPasswordChange}
        disabled={this.props.disableForm}
      />
    )
  }

  renderHostingPlatform() {
    return <Field disabled name='hostingDescription' label={t('Hosting Platform')} />
  }

  renderCatalogue() {
    return (
      <Catalogue
        companyKey={this.props.companyKey}
        disabled={this.props.disableCatalogue}
        catalogue={this.props.catalogue}
      />
    )
  }

  render() {
    const dialogClassName =
      this.props.submitButtonText && this.props.submitButtonText.toLowerCase()

    return (
      <Mask>
        <Modal.Dialog className={dialogClassName}>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Form horizontal onSubmit={this.handleSubmit}>
            <Modal.Body className='large'>
              <Grid>
                <Field
                  required
                  autoFocus
                  name='address'
                  autoComplete='off'
                  disabled={this.props.disableEmailInput}
                  label={t('Email Address')}
                  help={t('Address must contain valid hosting domain.')}
                  validate={this.validateDomain}
                  type='email'
                  tabIndex={0}
                />
                <Field
                  required
                  name='firstName'
                  label={t('First Name')}
                  disabled={this.props.disableForm}
                />
                <Field
                  required
                  name='lastName'
                  label={t('Last Name')}
                  disabled={this.props.disableForm}
                />
                {this.props.editing && this.renderHostingPlatform()}
                {this.props.catalogue && this.renderCatalogue()}
                {this.props.showPasswordInput && this.renderPasswordInput()}
                <Field
                  multiple
                  name='alias'
                  type='email'
                  rows={4}
                  componentClass='textarea'
                  disabled={this.props.disableForm}
                  label='Aliases'
                  help={t(
                    'Assign forwards for this mailbox, one external email address for each line.'
                  )}
                />
                <Field
                  name='forwardingStyle'
                  type='radio'
                  items={FORWARD_STYLES}
                  // redux-form seems to have bugs with radio groups
                  // hence we have to set it manually here
                  // TODO bump redux-form and see if that fixes it
                  checked={this.props.forwardingStyle}
                  disabled={this.props.disableForm}
                />
                <Field
                  multiple
                  name='forward'
                  type='email'
                  rows={4}
                  componentClass='textarea'
                  label={t('Forwards')}
                  help={
                    this.state.forwardError
                      ? this.state.forwardError
                      : t(
                          'Assign forwards for this mailbox, one external email address for each line.'
                        )
                  }
                  disabled={!this.props.requireForward}
                  required={this.props.requireForward}
                  onChange={this.handleForwardChange}
                />
              </Grid>
            </Modal.Body>

            <Modal.Footer>
              <Grid>
                <CancelSubmitButtons
                  disableCancel={this.props.disableForm}
                  disableSubmit={this.disableSubmit()}
                  onCancel={this.props.onCancel}
                  submitText={this.props.submitButtonText}
                />
              </Grid>
            </Modal.Footer>
          </Form>
        </Modal.Dialog>
      </Mask>
    )
  }
}

const mapDbToProps = (db, { mailbox, companyKey }) => ({
  mailboxForm: mailboxForm(db),
  editing: editing(db, companyKey),
  disableCatalogue: disableCatalogue(db, companyKey),
  requirePassword: requirePassword(db, companyKey),
  requireRepeatPassword: requireRepeatPassword(db, companyKey),
  requireForward: requireForward(db),
  forwardingStyle: forwardingStyle(db),
  initialValues: mailbox,
  disableForm: disableForm(db, companyKey),
  disableEmailInput: disableEmailInput(db, companyKey),
  forwardValid: forwardValid(db, companyKey),
  password: password(db, companyKey),
  passwordValid: passwordValid(db, companyKey),
  domainNames: domainNames(db, companyKey),
  domainsLoaded: domainsLoaded(db, companyKey),
  domainsEmpty: domainsEmpty(db, companyKey),
  domainsUri: domainsUri(db, companyKey),
  showPasswordInput: showPasswordInput(db, companyKey),
  catalogue: catalogue(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  changePassword: (password) => {
    dispatch(change(FORM_NAME, 'password', password))
    dispatch(touch(FORM_NAME, 'password'))
  },
  changeRepeatPassword: (repeatPassword) => {
    dispatch(change(FORM_NAME, 'repeatPassword', repeatPassword))
    dispatch(touch(FORM_NAME, 'repeatPassword'))
  },
  refreshDomains: (domainsUri) => {
    dispatch(refreshDomains(companyKey, domainsUri))
  }
})

const MailboxForm = reduxForm({ form: FORM_NAME })(MailboxDialog)

export default connect(mapDbToProps, mapActionsToProps)(MailboxForm)
