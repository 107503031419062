const Ext = require('ext')
const t = require('translate')
const User = require('admin/core/User')
const TabPanel = require('admin/view/TabPanel').default
const DomainSummaryGridPanel = require('admin/component/grid/DomainSummaryGridPanel')
const MailStatsPanel = require('admin/view/MailStatsPanel')
const SharedServiceListPanel = require('admin/view/SharedServiceListPanel')
const TrustedIpListPanel = require('admin/view/TrustedIpListPanel')
const PolicyManagementListPanel = require('admin/view/PolicyManagementListPanel')
const SignatureManagementPanel = require('admin/view/SignatureManagementPanel')

const OutboundScrubbingTabPanel = Ext.define(null, {
  extend: TabPanel,

  title: t('Outbound Scrubbing'),
  tabCls: 'outbound-scrubbing-tab',
  cls: 'outbound-scrubbing-panel',

  constructor(cfg = {}) {
    this.customer = cfg.customer
    this.product = cfg.product

    if (!this.product.current) {
      this.tabCls = this.tabCls + ' expired-group-tab'
    }

    const showSharedServices =
      this.customer.hasSharedServices() && (User.isTechnical() || User.isTechnicalAdmin())

    const EventSearchPanel = require('admin/view/EventSearchPanel')

    this.items = [
      new DomainSummaryGridPanel({ customer: this.customer, product: this.product }),
      new TrustedIpListPanel({
        customer: this.customer,
        product: this.product,
        store: this.customer.getTrustedIpStore()
      }),

      showSharedServices &&
        new SharedServiceListPanel({ store: this.customer.getSharedServiceStore() }),

      new PolicyManagementListPanel({
        customer: this.customer,
        product: this.product
      }),
      new SignatureManagementPanel({
        customer: this.customer,
        product: this.product
      }),
      new EventSearchPanel({
        customer: this.customer,
        product: this.product
      }),
      new MailStatsPanel({ customer: this.customer, product: this.product })
    ]

    this.callParent()
  }
})

module.exports = OutboundScrubbingTabPanel
