import React, { Component } from 'react'

import Field from 'components/form/Field'
import Grid from 'components/Grid'
import PropTypes from 'prop-types'
import {
  disableForm,
  enablePeriodicQuarantineDigestsForEndUser,
  endUserForm,
  selectedAction
} from 'quarantine/notification/subs'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import t from 'translate'

import DurationField from './DurationField'
import StartingField from './StartingField'

export const FORM_NAME = 'endUserConfigurationForm'

class EndUserConfiguration extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    notification: PropTypes.object
  }

  render() {
    return (
      <fieldset className='endUser'>
        <legend>End-user Notifications</legend>
        <Grid>
          <h5>Quarantine Digest</h5>
          <Grid>
            <Row>
              <Col xs={3}>
                <Field
                  disabled={this.props.disableForm}
                  name='enabled'
                  type='checkbox'
                  label={false}
                  info={
                    <p>
                      Enable / disable quarantine digests emails for end-users. Users will
                      receive digests reporting on inbound emails which have been
                      quarantined.
                    </p>
                  }
                >
                  {t('Periodic quarantine digests')}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <StartingField
                  info={
                    <p>
                      The starting time when the digest emails will be sent to your users.
                    </p>
                  }
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <DurationField
                  info={<p>The minimum frequency between quarantine digests emails</p>}
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <Row>
                  <p>Quarantine digests include:</p>
                </Row>
                <Field
                  name='digestTypes'
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                  label={false}
                  type='checkbox'
                  items={{
                    GRAY: 'Graymail',
                    SPAM: 'Spam',
                    DLP: 'DLP (SmartRules)'
                  }}
                />
              </Col>
              <Col xs={7}>
                <Row>
                  <p>Allowed user actions:</p>
                </Row>
                <Field
                  required
                  name='actions'
                  disabled={!this.props.enablePeriodicQuarantineDigests}
                  label={false}
                  type='radio'
                  checked={this.props.selectedAction}
                  items={{
                    REVIEW: 'Review only',
                    RELEASE: 'Release only after review',
                    UNREVIEWED_RELEASE: 'Release with or without review'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <p>Enabled addresses:</p>
            </Row>
            <Field
              multiple
              name='allowedRecipients'
              type='email'
              rows={4}
              componentClass='textarea'
              label={false}
              disabled={!this.props.enablePeriodicQuarantineDigests}
              help={t(
                'Define specific addresses to receive digest emails on each line - typically used for testing purposes. Remove defined addresses enable end-user digests for all recipients.'
              )}
            />
          </Grid>
        </Grid>
      </fieldset>
    )
  }
}

const mapDbToProps = (db, { companyKey, notification }) => ({
  endUserForm: endUserForm(db, companyKey),
  disableForm: disableForm(db, companyKey),
  enablePeriodicQuarantineDigests: enablePeriodicQuarantineDigestsForEndUser(
    db,
    companyKey
  ),
  selectedAction: selectedAction(db, companyKey),
  initialValues: notification
})

const EndUserForm = reduxForm()(EndUserConfiguration)

export default connect(mapDbToProps, null, null, { forwardRef: true })(EndUserForm)
