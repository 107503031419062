const Ext = require('ext')
const Component = require('smartrules/designer/Component')
const Tooltip = require('admin/util/Tooltip')

const Matcher = Ext.define(null, {
  extend: Component,

  constructor(cfg = {}) {
    this.defaultCfg = Ext.applyIf(cfg, {
      border: false,
      cls: 'smartrules-matcher',
      height: 'auto',
      invalidClass: 'x-matcher-invalid',
      layout: 'hbox',
      flex: 1,
      defaults: {
        border: false,
        margins: '0'
      }
    })

    this.callParent([this.defaultCfg])
    this.addEvents('heightfix')
    this.on('heightfix', this.fixHeight, this)
  },

  fixHeight() {
    const h = this.items.getRange().reduce((memo, item) => {
      const itemHeight = item.getHeight()
      return Math.max(itemHeight, memo)
    }, 0)

    this.setHeight(h + 1)
  },

  markInvalid(msg) {
    const cls = 'smartrules-matcher-invalid'
    const fields = this.findByType(Ext.form.Field)

    if (msg) {
      if (!this.el.hasClass(cls)) {
        this.el.addClass(cls)
        Tooltip.register(fields, msg, {
          cls: 'x-form-invalid-tip'
        })
      }
    } else {
      if (this.el.hasClass(cls)) {
        this.el.removeClass(cls)
        fields.forEach((field) => Ext.QuickTips.unregister(field))
      }
    }
  },

  hasTextMatcher() {
    return false
  },

  getHiddenConditionsOptions() {
    return false
  },

  getDsl() {
    return this.defaultCfg.dsl
  }
})

module.exports = Matcher
