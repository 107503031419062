const Record = require('admin/core/Record')

const CountryRecord = Record.define(null, {
  fields: [
    { name: 'id', type: 'int' },
    { name: 'abbreviation', type: 'string' },
    { name: 'code', type: 'string' },
    { name: 'geoName', type: 'string' }
  ],

  getName() {
    return this.get('geoName')
  },

  getDescription() {
    return this.get('geoName')
  },

  getUrl() {
    return this.get('url') || '/api/system/countries/' + this.get('code')
  }
})

module.exports = CountryRecord
