import {
  MAILHOSTING_CATALOGUE_INIT_DB,
  MAILHOSTING_CATALOGUE_SET_CUSTOMER_URL,
  MAILHOSTING_CATALOGUE_SET_LIST,
  MAILHOSTING_CATALOGUE_START_LOADING,
  MAILHOSTING_CATALOGUE_STOP_LOADING
} from 'mailhosting/catalogue/actions'

import { MAILHOSTING_CATALOGUE_REL } from 'product/resources'
import { createNextState } from '@reduxjs/toolkit'
import initialCatalogueState from 'mailhosting/catalogue/db'
import initialState from 'mailhosting/db'
import setIn from 'lodash/set'

const getBaseKeyPath = (companyKey) => [
  'companies',
  companyKey,
  MAILHOSTING_CATALOGUE_REL
]
const getKeyPath = (companyKey, subKey) => getBaseKeyPath(companyKey).concat([subKey])
const getListKeyPath = (companyKey) => getKeyPath(companyKey, 'list')

const reducers = {
  [MAILHOSTING_CATALOGUE_START_LOADING]: (mailhostingDb, action) => {
    return setIn(mailhostingDb, getKeyPath(action.companyKey, 'loading'), true)
  },

  [MAILHOSTING_CATALOGUE_STOP_LOADING]: (mailhostingDb, action) => {
    return setIn(mailhostingDb, getKeyPath(action.companyKey, 'loading'), false)
  },

  [MAILHOSTING_CATALOGUE_SET_LIST]: (mailhostingDb, action) => {
    return setIn(mailhostingDb, getListKeyPath(action.companyKey), action.list)
  },

  [MAILHOSTING_CATALOGUE_INIT_DB]: (mailhostingDb, action) => {
    return setIn(mailhostingDb, getBaseKeyPath(action.companyKey), initialCatalogueState)
  },

  [MAILHOSTING_CATALOGUE_SET_CUSTOMER_URL]: (mailhostingDb, action) => {
    return setIn(
      mailhostingDb,
      getKeyPath(action.companyKey, 'customerUrl'),
      action.customerUrl
    )
  }
}

export default function (moduleDb = initialState, action = {}) {
  return createNextState(moduleDb, (draftState) => {
    const reducer = reducers[action.type]

    if (!reducer) return draftState

    return reducer(draftState, action)
  })
}
