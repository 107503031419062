const Ext = require('ext')
const t = require('translate')
const Calendar = require('admin/time/Calendar')
const DashboardMailStatistics = require('admin/view/DashboardMailStatistics')

const DashboardPanel = Ext.define(null, {
  extend: Ext.Panel,

  title: t('Dashboard'),
  border: false,
  layout: 'hbox',
  layoutConfig: {
    align: 'stretch'
  },
  boxMinWidth: 640,
  boxMinHeight: 360,

  initComponent() {
    this.items = [
      {
        flex: 1,
        layout: 'vbox',
        layoutConfig: {
          align: 'stretch'
        },
        padding: 9,
        border: false,
        items: [
          new DashboardMailStatistics({
            flex: 1,
            legend: t('Last 24 Hours'),
            record: this.record,
            period: 'hourly',
            fromDate: Calendar.daysAgo(1).toMillis()
          }),
          new Ext.Spacer({ height: 26 }),
          new DashboardMailStatistics({
            flex: 1,
            legend: t('Last 7 Days'),
            record: this.record,
            period: 'hourly',
            fromDate: Calendar.daysAgo(7).toMillis()
          })
        ]
      }
    ]

    this.callParent()
  }
})

module.exports = DashboardPanel
