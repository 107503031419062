const Ext = require('ext')
const Matcher = require('smartrules/designer/Matcher')

const NullSenderMatcher = Ext.define(null, {
  extend: Matcher,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      cls: 'null-sender-matcher',
      items: [
        {
          xtype: 'label',
          html: '',
          height: 22
        }
      ]
    })
    this.callParent([cfg])
  },

  getValue() {
    return ''
  }
})

module.exports = NullSenderMatcher
