import React, { Component } from 'react'
import classNames from 'classnames'
import { Grid } from 'react-bootstrap'
import PropTypes from 'prop-types'

class GridComponent extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  }

  render() {
    return (
      <Grid
        fluid
        className={classNames(this.props.className, 'grid')}
        style={this.props.style}
      >
        {this.props.children}
      </Grid>
    )
  }
}

export default GridComponent
