const _s = require('underscore.string')
const Ext = require('ext')
const t = require('translate')
const Matcher = require('smartrules/designer/Matcher')
const ListStore = require('smartrules/designer/ListStore')
const ComboBox = require('admin/component/form/ComboBox')
const SmartRule = require('smartrules/designer/SmartRule')
const Tooltip = require('admin/util/Tooltip')
const isObject = require('is-plain-obj')

const titleize = require('strings/titleize')
const compareStrings = require('strings/compare/lexical')
const { isEnvelope } = require('smartrules/designer/util/isEnvelope')
const hasOr = require('smartrules/designer/util/hasOr').default
const wordsToRegex = require('smartrules/designer/util/wordsToRegex').default
const trimWords = require('smartrules/designer/util/trimWords').default
const regexToWords = require('smartrules/designer/util/regexToWords').default

const DEFAULT_POST_LABEL_WIDTH = 20

// note: do not add spaces after html attributes (extjs template parser is not that tolerant)
/* eslint-disable no-multi-str */
const COMBO_ITEM_TEMPLATE = new Ext.XTemplate(
  '<tpl for="."> \
<tpl if="values.separator"><hr/></tpl> \
<div class="x-combo-list-item">{values.name}</div> \
</tpl>'
)

const deepCloneObject = (obj) => Object.assign({}, obj)

const isRegexType = (value, valueType) =>
  value != null && valueType != null && compareStrings(valueType, 'regex') === 0

const matcherHasValueType = (matcher, valueType) =>
  valueType != null && matcher === valueType

const valueIsInList = function (customItem, cfg, isRegex) {
  const value = isRegex ? regexToWords(cfg.value, cfg) : cfg.value
  const index = cfg.listItemStore.find('name', value)

  if (index >= 0) return

  // since SCL-3558, envelopes never can be regexes so stop here
  if (isEnvelope(customItem.value)) return

  const { valueType } = cfg

  // otherwise if it's a regex it always can be in any editable list
  return isRegexType(value, valueType)
}

// this tells whether to select the custom item according to the selected value + its type
const selectCustomItem = function (customItem, cfg, isRegex) {
  const { value, valueType } = cfg

  if (matcherHasValueType(customItem.matcher, valueType)) return true
  if (value != null && customItem.value === value) return true

  return valueIsInList(customItem, cfg, isRegex)
}

const EditableCustomList = Ext.define(null, {
  extend: Matcher,

  statics: {
    requiresListItems: true
  },

  constructor(cfg) {
    let layout
    this.negated = cfg.negated
    this.lowerCaseValue = cfg.lowerCaseValue
    this.listStores = cfg.listStores
    this.isRegex = cfg.valueType && compareStrings(cfg.valueType, 'regex') === 0

    let comboItemToSelect = null

    let { value } = cfg
    const { listItemStore } = cfg
    const blankText = cfg.blankText || t('Please select a list item here')
    const emptyText = cfg.emptyText || t('Select a list item')

    if (cfg.customItems) {
      this.customItems = cfg.customItems

      let useSeparator = true

      this.customItems.forEach((customItem) => {
        if (!comboItemToSelect && selectCustomItem(customItem, cfg, this.isRegex)) {
          comboItemToSelect = customItem.name
          customItem.value = value

          // keep track of currently hidden conditions so that
          // refreshConditions() works properly
          this.previouslyHiddenConditions = customItem.hideConditions
        }

        // add a separator for the first custom item
        customItem.separator = useSeparator

        useSeparator = false

        const RecordTypeClass = listItemStore.recordType

        listItemStore.add(new RecordTypeClass(customItem))
      })
    }

    this.combo = new ComboBox({
      allowBlank: false,
      displayField: 'name',
      tpl: COMBO_ITEM_TEMPLATE,
      editable: cfg.editable,
      blankText,
      emptyText,
      flex: 1,
      forceSelection: !cfg.editable,
      listEmptyText: !cfg.editable ? t('List not found') : undefined,
      mode: 'local',
      store: listItemStore,
      triggerAction: 'all',
      valueField: 'name',
      enableKeyEvents: true,
      validator: cfg.validator
    })

    // fix for http://youtrack.smxemail.com/issue/SCL-1394
    // keep original isValid() and redirect that call to special treatment,
    // see @isValid() below
    this.combo.isValidOriginal = this.combo.isValid
    this.combo.isValid = this.isValid.bind(this)

    const registerQuickTipForCombo = (customQuickTip) => {
      // only register when quick tip is initialized.
      // during jasmine tests this isn't the case.
      if (Ext.QuickTips.getQuickTip() != null) {
        if (!customQuickTip || _s.isBlank(customQuickTip)) {
          customQuickTip = blankText || emptyText
        }

        Tooltip.register(this.combo.getEl(), customQuickTip)
      }
    }

    registerQuickTipForCombo()

    // todo: skip processing new values when already the same one is selected (performance)
    const processNewValue = (value) => {
      registerQuickTipForCombo(value)
      this.setValue(value) // due to some extjs bugs we need to manually set the value
      this.refreshMatcher(value)
      this.refreshConditions()
    }

    this.combo.on('select', (combo, record) => processNewValue(record.get('name')))
    this.combo.on('keyup', (combo, e) => processNewValue(e.target.value))

    if (!comboItemToSelect) {
      if (this.isRegex) {
        value = regexToWords(value, cfg)
      }

      const titleizeOptions = {}

      if (cfg.convertOrToRegex) {
        titleizeOptions.excludeWord = 'or'
      }

      if (cfg.titleize) {
        value = titleize(value, titleizeOptions)
      }

      comboItemToSelect = value
    }

    const items = [this.combo]

    const index = this.getIndexForValue(comboItemToSelect)

    if (index >= 0 || cfg.editable) {
      this.setValue(comboItemToSelect)
    }

    if (this.customItems) {
      this.customMatcher = this.createCustomMatcher(comboItemToSelect, { value })
      if (this.customMatcher) {
        items.push(this.customMatcher)
      }
    }

    if (cfg.postLabel) {
      layout = 'hbox'
      items.push({
        xtype: 'label',
        margins: '4 4',
        width: cfg.postLabelWidth || DEFAULT_POST_LABEL_WIDTH,
        text: cfg.postLabel,
        cls: 'postLabel'
      })
    }

    this.callParent([
      {
        value,
        items,
        layout,
        editable: cfg.editable,
        matcherType: cfg.matcherType,
        dsl: cfg.dsl,
        quoteValue: cfg.quoteValue,
        customItemsEditable: cfg.customItemsEditable,
        convertOrToRegex: cfg.convertOrToRegex,
        convertGlobToRegex: cfg.convertGlobToRegex,
        trim: cfg.trim
      }
    ])

    // this is fired when other ui components than this one have changed
    this.combo.on(
      'change',
      function () {
        const parent = this.findParentByType(SmartRule)
        parent?.fireEvent('dirty')
      },
      this
    )
  },

  getIndexForValue(value) {
    return this.combo.store.find(this.combo.valueField, value)
  },

  // here it is about to get ugly
  refreshMatcher(value) {
    let changed = false
    let editStateChanged = false

    if (this.customMatcher) {
      // first remove any existing ones
      // don't autodestroy. which means: destroy them all now!
      this.customMatcher.removeAll(false)

      this.remove(this.customMatcher)

      delete this.customMatcher

      changed = true
    }

    this.customMatcher = this.createCustomMatcher(value)

    // special treatment for editable state. can happen that some items aren't editable like others
    // the additional checks make sure the edit state is only changed when necessary because
    // it involves some resource-hungry re-rendering
    if (
      this.customMatcher &&
      this.combo.editable === !this.defaultCfg.customItemsEditable
    ) {
      this.combo.editable = this.defaultCfg.customItemsEditable
      editStateChanged = true
    } else if (this.combo.editable === !this.defaultCfg.editable) {
      this.combo.editable = this.defaultCfg.editable
      editStateChanged = true
    }

    if (editStateChanged) {
      this.combo.updateEditState()
    }

    if (this.customMatcher) {
      // figure out the index to place the new matcher next to the combobox
      // then add the new one
      this.insert(this.items.indexOf(this.combo) + 1, this.customMatcher)

      changed = true
    }

    // at last, layout all again but only if something has changed
    if (changed) {
      this.doLayout()
    }
  },

  getHiddenConditionsOptions() {
    let options = null

    if (this.previouslyHiddenConditions) {
      options = { unHideConditions: { step: this.previouslyHiddenConditions.step } }
    }

    return options
  },

  // currently they only work for custom items
  // todo maybe expand later for anything in the combo box
  refreshConditions() {
    let options = {}
    const customItem = this.getCustomItem()
    let changed = true

    if (this.combo.previouslySelectedIndex != null) {
      changed = this.combo.previouslySelectedIndex === !this.combo.customSelectedIndex
    }

    if (changed) {
      if (customItem) {
        this.previouslyHiddenConditions = options.hideConditions =
          customItem.getHideConditions()
      } else if (this.previouslyHiddenConditions) {
        options = this.getHiddenConditionsOptions()
        this.previouslyHiddenConditions = null
      }

      this.previouslySelectedIndex = this.combo.customSelectedIndex

      this.fireEvent('refreshConditions', options)
    }
  },

  getHideConditions() {
    const item = this.getCustomItem()
    return {
      hideConditions: item?.getHideConditions()
    }
  },

  createCustomMatcher(value, additionalMatcherParameters = {}) {
    let customMatcher = null
    const customItem = this.getCustomItem(value)
    const CustomItemMatcherClazz = customItem?.getMatcherClazz()

    // then check if a new matcher is given
    if (CustomItemMatcherClazz) {
      let listStore
      const matcherType = customItem.getMatcherType()

      const exists = this.listStores[matcherType] != null

      // avoid NPE, see http://youtrack.smxemail.com/issue/SCL-1368
      if (exists) {
        listStore = this.listStores[matcherType]
      } else {
        listStore = new ListStore()
      }

      additionalMatcherParameters.padding = '0 0 0 5'
      additionalMatcherParameters.listStore = listStore

      const matcherParameters = Ext.apply(
        customItem.getMatcherParameters(),
        additionalMatcherParameters
      )

      // instantiate the new one
      customMatcher = new CustomItemMatcherClazz(matcherParameters)
    }

    return customMatcher
  },

  // returns an immutable wrapper for a custom item
  getCustomItem(name = this.getComboValue()) {
    if (!this.customItems) return

    let tempCustomItems = deepCloneObject(this.customItems)

    // populate keys first without overwriting the name/value
    tempCustomItems = Object.values(tempCustomItems).map(function (customItem) {
      customItem.key = customItem.name

      return customItem
    })

    const customItemMatch = Object.values(tempCustomItems).find((tempCustomItem) => {
      let tempCustomItemName = tempCustomItem.name
      let tempCustomItemValue = tempCustomItem.value

      if (this.lowerCaseValue) {
        tempCustomItemName = tempCustomItemName.toLowerCase()
        tempCustomItemValue = tempCustomItemValue?.toLowerCase() // not all have values
      }

      return tempCustomItemName === name || tempCustomItemValue === name
    })

    if (customItemMatch) {
      return {
        getName() {
          return customItemMatch.name
        },

        getValue: (options = {}) => {
          if (this.customMatcher) {
            return this.customMatcher.getValue(options)
          } else if (customItemMatch.value) {
            return customItemMatch.value
          }
          return customItemMatch.name
        },

        getDsl() {
          return customItemMatch.dsl
        },

        getMatcherClazz() {
          return customItemMatch.matcherClazz
        },

        setMatcherInstance(newMatcherInstance) {
          customItemMatch.matcherInstance = newMatcherInstance
        },

        getMatcherInstance() {
          return customItemMatch.matcherInstance
        },

        getMatcherType() {
          return customItemMatch.matcherType
        },

        getMatcherParameters() {
          return customItemMatch.matcherParameters || {}
        },

        getHideConditions() {
          return customItemMatch.hideConditions
        },

        unselect: () => {
          this.reset()
        }
      }
    }
  },

  getComboValue() {
    let value

    if (this.combo.editable && this.combo.customSelectedIndex < 0) {
      // lastQuery contains the value the user has entered directly
      value = this.combo.customLastQuery
    } else {
      value = this.combo.getValue()
    }

    if (isObject(value) && 'value' in value) {
      value = value.value
    }

    if (!value) {
      value = ''
    }

    if (this.lowerCaseValue) {
      value = value.toLowerCase()
    }

    return value
  },

  reset() {
    this.setValue('')
  },

  setValue(v) {
    const customItem = this.getCustomItem(v)

    if (customItem) {
      v = customItem.getName()
    }

    this.combo.setValue(v)

    // fixes another extjs but where selected index is not updated when
    // it is manually/edited
    // for stability, not going to overwrite any extjs internals
    // such as .selectedIndex or .lastQuery
    this.combo.customSelectedIndex = this.getIndexForValue(v)
    this.combo.customLastQuery = v
  },

  getValue(options = {}) {
    let value = this.getComboValue()
    const raw = options.raw || false // raw means unvarnished, without any formatting
    const isNotBlank = !_s.isBlank(value)

    // special treatment for custom items. they can come with special values.
    // compare them by name and when it matches, use their custom value
    const customItem = this.getCustomItem(value)

    // if one is found, use its custom value instead
    if (customItem) {
      value = customItem.getValue(options)
    } else if (!raw) {
      if (this.lowerCaseValue && isNotBlank) {
        value = value.toLowerCase()
      }

      // hasOr detects "OR" key word in any value. this tells whether to convert to a regex or not
      if ((this.isRegex || hasOr(value)) && isNotBlank) {
        value = wordsToRegex(value, this.defaultCfg)
      } else {
        if (this.defaultCfg.trim && isNotBlank) {
          value = trimWords(value)
        }

        if (this.defaultCfg.quoteValue) {
          value = `"${value}"`
        }
      }
    }

    return value
  },

  // fix for http://youtrack.smxemail.com/issue/SCL-1394
  // unfortunately extjs also validates ui components which are not visible,
  // hence this overrides the combo.isValid() check, this to make sure, invisible elements
  // are always valid
  isValid() {
    let valid = false
    const visible = this.isVisible()

    if (!visible) {
      valid = true
    } else if (this.combo) {
      valid = this.combo.isValidOriginal()
    }

    return valid
  }
})

module.exports = EditableCustomList
