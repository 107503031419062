const t = require('translate')
const Record = require('admin/core/Record')
const EventDetailStore = require('admin/data/EventDetailStore')

const EventSearchResultRecord = Record.define(null, {
  fields: [
    {
      name: 'msgId',
      type: 'string'
    },
    {
      name: 'size',
      type: 'int'
    },
    {
      name: 'eventStatusCode',
      type: 'string',
      mapping: 'eventStatus'
    },
    {
      name: 'eventStatus',
      type: 'string',
      convert(v, r) {
        if (v in EventDetailStore.EVENT_STATUSES) {
          return EventDetailStore.EVENT_STATUSES[v]
        } else if (r.response) {
          return t('Rejected By Recipient')
        }
        const et = r.eventType
        if (r.deliveredDate) {
          return t('Delivered')
        } else if (
          et.indexOf('SPAM') !== -1 ||
          (et.indexOf('DENY') !== -1 && et.indexOf('POLICY') !== -1)
        ) {
          return t('Rejected')
        } else if (
          et === 'THREAT' ||
          et === 'MCAFEE_VIRUS' ||
          et === 'CLAMAV_VIRUS' ||
          et === 'SYMANTEC_VIRUS' ||
          et === 'SYMANTEC_WORM'
        ) {
          return t('Rejected')
        }
        return ''
      }
    },
    {
      name: 'eventTypeCode',
      mapping: 'eventType',
      type: 'string'
    },
    {
      name: 'eventType',
      type: 'string',
      convert(v) {
        if (v.indexOf('CLEAN') !== -1) {
          return t('Clean')
        } else if (v === 'SPF_SPAM') {
          return t('SPF')
        } else if (v === 'DMARC_SPAM') {
          return t('DMARC')
        } else if (v === 'MAIL_FROM_CHANGE_SPAM') {
          return t('Spam (MFC)')
        } else if (v === 'RATE_LIMIT_SPAM') {
          return t('Spam (RLS)')
        } else if (v.indexOf('SPAM') !== -1) {
          return t('Spam')
        } else if (
          [
            'THREAT',
            'MCAFEE_VIRUS',
            'CLAMAV_VIRUS',
            'SYMANTEC_VIRUS',
            'SYMANTEC_WORM'
          ].indexOf(v) >= 0
        ) {
          return t('Threat')
        } else if (v.indexOf('ALLOW') !== -1 && v.indexOf('POLICY') !== -1) {
          return t('Whitelist')
        } else if (v.indexOf('DENY') !== -1 && v.indexOf('POLICY') !== -1) {
          return t('Blacklist')
        }
        return t('Unknown')
      }
    },
    {
      name: 'eventTypeClassification',
      mapping: 'eventType',
      type: 'string',
      convert(v) {
        if (v.indexOf('CLEAN') !== -1) {
          return 'clean'
        }
        if (v.indexOf('SPAM') !== -1) {
          return 'spam'
        }
        if (
          v === 'THREAT' ||
          v === 'MCAFEE_VIRUS' ||
          v === 'CLAMAV_VIRUS' ||
          v === 'SYMANTEC_VIRUS' ||
          v === 'SYMANTEC_WORM'
        ) {
          return 'threat'
        }
        if (v.indexOf('ALLOW') !== -1 && v.indexOf('POLICY') !== -1) {
          return 'whitelist'
        }
        if (v.indexOf('DENY') !== -1 && v.indexOf('POLICY') !== -1) {
          return 'blacklist'
        }
        return 'unknown'
      }
    },
    {
      name: 'subject',
      type: 'string'
    },
    {
      name: 'receivedDate',
      type: 'int'
    },
    {
      name: 'recipient',
      type: 'string'
    },
    {
      name: 'sender',
      type: 'string'
    },
    {
      name: 'url',
      type: 'string'
    },
    {
      name: 'hasSmartRule',
      type: 'boolean'
    },
    {
      name: 'hasEncryption',
      type: 'boolean'
    }
  ],

  getName() {
    return this.get('subject')
  },

  getDescription() {
    return this.get('subject')
  }
})

module.exports = EventSearchResultRecord
