// the common class for vendor, distributor, reseller and customer tab
// minor changes can be overridden by functions in sub classes

import User from 'admin/core/User'
import AgreementListPanel from 'admin/view/AgreementListPanel'
import BillingDetailPanel from 'admin/view/BillingDetailPanel'
import CustomerListPanel from 'admin/view/CustomerListPanel'
import DistributorListPanel from 'admin/view/DistributorListPanel'
import IntegrationTab from 'admin/view/IntegrationTabPanel'
import InvoiceRunListPanel from 'admin/view/InvoiceRunListPanel'
import NoteListPanel from 'admin/view/NoteListPanel'
import ResellerAgreementListPanel from 'admin/view/ResellerAgreementListPanel'
import ResellerListPanel from 'admin/view/ResellerListPanel'
import TabPanel from 'admin/view/TabPanel'
import Ext from 'ext'
import * as ProductResources from 'product/resources'
import ReportsListPanel from 'reporting/ReportsListPanel'
import store from 'store'
import { isCapableOfInvoicing, isCapableOfReporting } from 'system/capability/subs'
import { authenticateWithKeycloak } from 'system/keycloak'

const AbstractCompanyTabPanel = Ext.define(null, {
  extend: TabPanel,

  constructor(cfg = {}) {
    this.record = cfg.record
    this.items = this.getItems()

    AbstractCompanyTabPanel.superclass.constructor.call(this, cfg)

    this.record.on('sync', this.updateItems, this)
    this.on('destroy', () => this.record.un('sync', this.updateItems, this))

    if (module.hot) {
      module.hot.accept('admin/view/IntegrationTabPanel.js', this.reloadTenancies)
    }
  },

  getItems() {
    // We may not have the full record yet
    if (!this.record.getType()) return []

    if (!this.dashboardTab && this.showDashboardTab()) {
      this.dashboardTab = this.buildDashboardTab()
    }

    this.detailTab = this.detailTab || this.buildDetailPanel()

    if (!this.invoiceTab && this.showInvoiceTab()) {
      this.invoiceTab = new InvoiceRunListPanel({
        record: this.record,
        store: this.record.getInvoiceRunStore()
      })
    }

    if (!this.billingTab && this.showBillingDetailPanel()) {
      this.billingTab = this.buildBillingDetailPanel()
    }

    if (!this.distributorTab && this.showDistributorTab()) {
      this.distributorTab = new DistributorListPanel({
        record: this.record,
        store: this.record.getDistributorStore()
      })
    }

    if (!this.resellerAgreementTab && this.showResellerAgreementTab()) {
      this.resellerAgreementTab = new ResellerAgreementListPanel({
        record: this.record,
        store: this.record.getResellerAgreementStore()
      })
    }

    if (!this.resellerTab && this.showResellerTab()) {
      this.resellerTab = new ResellerListPanel({
        record: this.record,
        store: this.record.getResellerStore()
      })
    }

    if (!this.customerTab && this.showCustomerTab()) {
      this.customerTab = new CustomerListPanel({
        record: this.record,
        store: this.record.getCustomerStore()
      })
    }

    if (!this.customerAgreementTab && this.showCustomerAgreementTab()) {
      this.customerAgreementTab = new AgreementListPanel({
        record: this.record,
        store: this.record.getAgreementStore()
      })
    }

    // cannot move this to the imports on top due to cyclic deps
    const UserListPanel = require('admin/view/UserListPanel')
    this.userTab =
      this.userTab ||
      new UserListPanel({
        record: this.record,
        store: this.record.getUserStore()
      })

    if (authenticateWithKeycloak()) {
      this.integrationTab = this.integrationTab || this.buildIntegrationTab()
    }

    if (!this.reportTab && this.showReportTab()) {
      this.reportTab = new ReportsListPanel({
        record: this.record,
        store: this.record.getReportStore()
      })
    }

    if (!this.noteTab && this.showNotesTab()) {
      this.noteTab = new NoteListPanel({
        record: this.record,
        store: this.record.getNoteStore()
      })
    }

    if (!this.statusTab && this.showStatusTab()) {
      this.statusTab = this.buildStatusTab()
    }

    return [
      this.dashboardTab,
      this.detailTab,
      this.invoiceTab,
      this.billingTab,
      this.distributorTab,
      this.resellerAgreementTab,
      this.resellerTab,
      this.customerTab,
      this.customerAgreementTab,
      this.userTab,
      this.integrationTab,
      this.reportTab,
      this.noteTab,
      this.statusTab
    ].filter(Boolean)
  },

  showDashboardTab() {
    return false
  },

  buildDashboardTab() {},

  buildDetailPanel() {},

  buildBillingDetailPanel() {
    return new BillingDetailPanel({
      record: this.record,
      store: this.record.getInvoiceStore()
    })
  },

  showBillingDetailPanel() {
    if (!isCapableOfInvoicing(store.getState())) return false
    if (this.record.isCustomer()) return true

    return !this.record.isVendor() && User.isVendor()
  },

  showInvoiceTab() {
    return this.record.isVendor() && isCapableOfInvoicing(store.getState())
  },

  showDistributorTab() {
    return this.record.isVendor()
  },

  showResellerAgreementTab() {
    return this.record.isDistributor() || this.record.isReseller()
  },

  showResellerTab() {
    return this.record.isVendor() || this.record.isDistributor()
  },

  showCustomerTab() {
    return !this.record.isCustomer()
  },

  showCustomerAgreementTab() {
    return this.record.isCustomer()
  },

  showReportTab() {
    return isCapableOfReporting(store.getState())
  },

  showNotesTab() {
    return true
  },

  showStatusTab() {
    // Shown for technical admins only since http://youtrack.smxemail.com/issue/SCL-891
    return this.record.isCustomer() && User.isTechnicalAdmin()
  },

  buildStatusTab() {},

  async reloadTenancies() {
    const NewIntegrationTab = await import('admin/view/IntegrationTabPanel')
    this.integrationTab = this.replaceTab(
      this.integrationTab,
      this.buildIntegrationTab(true, NewIntegrationTab.default)
    )
  },

  buildIntegrationTab(hotReloading = false, Class = IntegrationTab) {
    const tenancyListUri = this.record.getUriByRel(ProductResources.TENANCY_LIST_REL)
    if (tenancyListUri) {
      return new Class({
        companyKey: this.record.getCompanyKey(),
        tenancyListUri,
        hotReloading,
        hasProductSmx365: this.record.isCustomer() && this.record.hasProductKey('SMX_365')
      })
    }
  },

  destroy: function () {
    if (module.hot) {
      // dirty hack to remove them since webpack doesn't offer an API to remove these listener
      delete module.hot._acceptedDependencies[
        './src/modules/admin/view/IntegrationTabPanel.js'
      ]
    }

    AbstractCompanyTabPanel.superclass.destroy.call(this)
  }
})

export default AbstractCompanyTabPanel
