const Ext = require('ext')
const t = require('translate')
const ResettableTask = require('admin/util/ResettableTask')

const SourcePanel = Ext.define(null, {
  extend: Ext.Panel,

  border: false,
  title: t('Source'),

  constructor(cfg = {}) {
    this.onChange = new ResettableTask({
      activated: false,
      callback() {
        const val = this.textarea.getValue()
        if (val !== this.previousValue) {
          this.fireEvent('smartrules-source-changed', val)
        }
        this.previousValue = val
        return this.onChange.reset()
      },
      delayby: 0.1,
      scope: this
    })

    this.textarea = new Ext.form.TextArea({
      readOnly: cfg.readOnly,
      enableKeyEvents: true,
      height: 250,
      listeners: {
        destroy() {
          return this.onChange.deactivate()
        },
        blur() {
          return this.onChange.deactivate()
        },
        focus() {
          return this.onChange.activate()
        },
        scope: this
      },
      name: 'source',
      width: '100%'
    })

    if (!cfg.readOnly) {
      this.status = new Ext.Panel({
        border: false,
        cls: 'smartrules-source-status',
        height: 28,
        html: ''
      })
    }

    this.items = [this.textarea, this.status]

    this.callParent(arguments)

    return this.addEvents('smartrules-source-changed')
  },

  getValue() {
    return this.textarea.getValue()
  },

  setValue(s) {
    this.textarea.setValue(s)
    this.textarea.originalValue = this.previousValue = s
    return this.fireEvent('smartrules-source-changed')
  },

  setStatus(s) {
    if (this.status != null ? this.status.rendered : undefined) {
      return this.status.update(s)
    }
  },

  isValid() {
    return !!this.valid
  },

  isDirty() {
    return this.textarea.isDirty()
  }
})

module.exports = SourcePanel
