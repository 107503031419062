const Ext = require('ext')
const LinkHeader = require('http-link-header')

const ResourceReader = Ext.define(null, {
  extend: Ext.data.JsonReader,

  constructor(recordType) {
    return ResourceReader.superclass.constructor.call(
      this,
      {
        root: 'resources',
        id: 'id'
      },
      recordType
    )
  },

  // Default implementation is identity
  convert(data) {
    return data
  },

  read(response) {
    const json = response.responseText
    const o = Ext.decode(json)
    if (!o) {
      throw new Error('JsonReader.read: Json object not found')
    }
    return this.readRecords(o, response)
  },

  readRecords(data, response) {
    let etag
    if (response) {
      etag = response.getResponseHeader('ETag')
    }

    const records = ResourceReader.superclass.readRecords.call(this, {
      resources: this.convert(data, etag)
    })

    if (response) {
      const header = response.getResponseHeader('Link')

      if (header != null) {
        const links = LinkHeader.parse(header)
        records.records.forEach((record) => (record.links = links))
      }
    }

    return records
  }
})

module.exports = ResourceReader
