const Ext = require('ext')

/*
A DataProxy that uses a store as its data source.
*/
const StoreDataProxy = Ext.define(null, {
  extend: Ext.data.DataProxy,

  constructor(store) {
    this.storeProxy = store
    this.callParent()
  },

  load(params, reader, callback, scope, arg) {
    return callback.call(
      scope,
      {
        success: true,
        records: this.storeProxy.getRange(),
        totalRecords: this.storeProxy.getTotalCount()
      },
      arg,
      true
    )
  }
})

module.exports = StoreDataProxy
