const Ext = require('ext')
const t = require('translate')
const ActionClause = require('smartrules/designer/ActionClause')
const ComboBox = require('admin/component/form/ComboBox')

const AppendFooter = Ext.define(null, {
  extend: ActionClause,

  constructor(cfg = {}) {
    cfg = Ext.applyIf(cfg, {
      label: t('Append footer'),
      noSpacer: true,
      type: 'smartrules-append-footer',
      nestable: true
    })
    const { templateStore } = cfg
    if (templateStore?.getCount() === 0) {
      templateStore.load()
    }

    const templateSelect = new ComboBox({
      allowBlank: false,
      displayField: 'name',
      editable: false,
      flex: 1,
      forceSelection: true,
      listEmptyText: `<div class="x-combo-list-item">${t('No templates found.')}</div>`,
      mode: 'local',
      name: 'template',
      ref: '../template',
      store: templateStore,
      triggerAction: 'all',
      value: cfg.value,
      valueField: 'name',
      minWidth: 300
    })

    const wrap = new Ext.Panel({
      flex: 1,
      layout: 'hbox',
      items: [templateSelect]
    })

    this.callParent([cfg])
    this.insert(3, wrap)
  },

  toDsl() {
    return `append [${this.template.getValue()}] footer`
  }
})

module.exports = AppendFooter
