export const links = (doc) => doc?._links

export const link = (doc, name) => {
  if (!doc || !name) return

  const _links = links(doc)
  if (!_links) return

  return _links[name]
}

export const first = (anything) => {
  if (Array.isArray(anything)) {
    return anything.find(() => true) // safer than [0]
  }

  return anything
}

export const href = (doc, name) => {
  const res = first(link(doc, name))
  if (res) return res.href
  return null
}

export const next = (doc) => href(doc, 'next')
export const self = (doc) => href(doc, 'self')

export const resource = (doc, name, rel = null) => {
  let resource

  if (doc && doc._embedded && doc._embedded[name]) {
    resource = doc._embedded[name]
  }

  if (rel && Array.isArray(resource)) {
    const resources = resource.reduce((memo, entry) => {
      if (entry[rel]) {
        memo.push(entry[rel])
      }
      return memo
    }, [])
    return [].concat(...resources)
  }

  return resource
}
