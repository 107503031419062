const Record = require('admin/core/Record')
const Time = require('admin/time/Time')
const User = require('admin/core/User')

const NoteRecord = Record.define(null, {
  module() {
    return require('admin/business/NoteResource')
  },

  fields: [
    {
      name: 'id',
      type: 'int',
      mapping: 'id'
    },
    {
      name: 'noteType',
      type: 'string',
      mapping: 'noteType.code'
    },
    {
      name: 'noteTypeName',
      type: 'string',
      mapping: 'noteType.code',
      convert(value, record) {
        const NoteStore = require('admin/data/NoteStore')
        value = NoteStore.NOTE_TYPES[value] || NoteStore.NOTE_TYPES.UNKNOWN
        return String.format('{0} [{1}]', record.partyName, value)
      }
    },
    {
      name: 'noteName',
      type: 'string',
      mapping: 'noteName'
    },
    {
      name: 'noteDate',
      type: 'date',
      mapping: 'noteDate',
      convert: Time.toJS
    },
    {
      name: 'noteInfo',
      type: 'string',
      mapping: 'noteInfo'
    },
    {
      name: 'party',
      type: 'string',
      mapping: 'partyName'
    },
    {
      name: 'partyId',
      type: 'int',
      mapping: 'partyId'
    }
  ],

  getName() {
    return this.get('id')
  },

  isUserNote() {
    return this.get('noteType') === 'USER_NOTE'
  },

  isMyNote() {
    return this.get('partyId') === User.getId()
  },

  canSave() {
    return this.isUserNote() && this.isMyNote()
  },

  getParams() {
    return {
      noteName: this.get('noteName'),
      noteInfo: this.get('noteInfo')
    }
  },

  isPersistentOnSave() {
    return true
  }
})

module.exports = NoteRecord
