import defaultHeaders from 'api/defaultHeaders'
import onBeforeRequest from 'api/onBeforeRequest'
import onCompleteRequest from 'api/onCompleteRequest'
import onRequestException from 'api/onRequestException'
import timeout from 'api/timeout'
import axios from 'axios'
import encodeForm from 'url/encodeForm'

axios.interceptors.request.use(onBeforeRequest)
axios.interceptors.response.use(
  (response) => {
    // isUserProfile check is needed here because the call for user profile
    // goes thru axios, not extjs
    const isUserProfile = response?.config?.url === '/api/userprofile'

    if (!isUserProfile) {
      onCompleteRequest(response)
    }

    return response
  },
  (error) => {
    if (!axios.isCancel(error)) {
      // axios doesn't generate a response/request objects when network
      // is disabled in dev console, hence that default {} is needed.
      error.response = error.response || {}
      error.request = error.request || {}

      onRequestException(error.response, error.request)
    }

    return Promise.reject(error)
  }
)

axios.defaults.timeout = timeout
axios.defaults.headers = {
  ...axios.defaults.headers,
  ...defaultHeaders,
  ...{
    // so that backend won't ask for a auth challenge when token is invalid
    // and we can silently redirect to login page
    'x-requested-with': 'axios'
  }
}

const transformRequest = [
  (data, headers) => {
    if (headers['content-type'] === 'application/x-www-form-urlencoded') {
      return encodeForm(data)
    }

    return data
    // don't overwrite, but concat to axios defaults, see
    // https://github.com/axios/axios/issues/1910#issuecomment-498704197
  },
  ...axios.defaults.transformRequest
]

class Api {
  static get(url, config) {
    return axios.get(url, config)
  }

  static post(url, data, config = {}) {
    config.transformRequest = transformRequest
    return axios.post(url, data, config)
  }

  // todo change headers to config for consistency
  static put(url, data, headers) {
    return axios.put(url, data, {
      transformRequest,
      headers
    })
  }

  // todo change headers to config for consistency
  static delete(url, headers = {}) {
    return axios.delete(url, {
      headers
    })
  }

  static head(url, config) {
    return axios.head(url, config)
  }

  static CancelToken = axios.CancelToken
  static isCancel = axios.isCancel
}

export default Api
