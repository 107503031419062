// from now onwards use this fn instead of Ext.urlencode()
// for slowly moving away from ExtJS

import formurlencoded from 'form-urlencoded'

export default (obj) =>
  formurlencoded(obj, {
    useDot: true,
    skipIndex: true,
    skipBracket: true
  })
