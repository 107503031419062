const Record = require('admin/core/Record')
const Time = require('admin/time/Time')
const formatMoney = require('money/format')
const SubscriptionStore = require('reporting/SubscriptionStore')

const TYPES = {
  DATE: 'date',
  TIMESTAMP: 'date',
  NUMBER: 'int',
  COUNT: 'int',
  FORMATTED_COUNT: 'int',
  TEXT: 'string',
  MONEY: 'string',
  BOOLEAN: 'boolean'
}

const getType = (type) => TYPES[type]

const CONVERTERS = {
  DATE: Time.toJS,
  TIMESTAMP: Time.toJS,
  MONEY(value, record, field) {
    let currencyField = 'currency'
    let priceField = null

    currencyField = field.fields != null ? field.fields[0].name : undefined
    priceField = field.fields != null ? field.fields[1].name : undefined

    if (priceField || priceField === 0) {
      return formatMoney(record[currencyField], record[priceField])
    }

    return value
  }
}

const getConverter = function (field) {
  return (
    (CONVERTERS[field.type] != null
      ? CONVERTERS[field.type].createDelegate(this, [field], true)
      : undefined) || null
  )
}

const ReportRecord = Record.define(null, {
  module() {
    return require('reporting/Report')
  },

  fields: [
    {
      name: 'id',
      type: 'string'
    },
    {
      name: 'title',
      type: 'string'
    },
    {
      name: 'fields'
    },
    {
      name: 'options'
    },
    {
      name: 'reportTypes'
    },
    {
      name: 'reportFormats'
    }
  ],

  getName() {
    return this.get('title')
  },

  getDescription() {
    return this.get('title')
  },

  canOpen() {
    return require('reporting/Report').canOpen() && this.isRunnable()
  },

  hasReportingOptions() {
    return this.get('options').some((option) => !option.provided)
  },

  isExportable() {
    return this.get('reportTypes').indexOf('EXPORTABLE') > -1
  },

  isSchedulable() {
    return this.get('reportTypes').indexOf('SCHEDULABLE') > -1
  },

  isTemporal() {
    return this.get('reportTypes').indexOf('TEMPORAL') > -1
  },

  isRunnable() {
    return this.get('reportTypes').indexOf('RUNNABLE') > -1
  },

  getGroupBy() {
    const field = this.get('fields').find((field) => field.group === true)
    return field && field.name
  },

  getReportItemRecordConstructor() {
    return Record.define(null, {
      fields: [
        {
          name: 'resourceTypeName',
          type: 'string',
          mapping: 'resource.resourceTypeName'
        },
        {
          name: 'url',
          type: 'string',
          mapping: 'resource.resourceUrl'
        },
        {
          name: 'resourceId',
          type: 'int',
          mapping: 'resource.resourceId'
        },
        {
          name: 'links'
        }
      ].concat(
        this.get('fields').map((field) => ({
          name: field.name,
          type: getType(field.type),
          convert: getConverter(field)
        }))
      )
    })
  },

  getSubscriptionStore() {
    if (!this.subscriptionStore) {
      this.subscriptionStore = new SubscriptionStore({ parentRecord: this })
    }

    return this.subscriptionStore
  }
})

module.exports = ReportRecord
