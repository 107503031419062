const Ext = require('ext')
const t = require('translate')

const DomainStatus = Ext.define(null, {
  statics: {
    approvalValues: {
      APPROVED: t('Approved'),
      CREATED: t('Created'),
      PENDING: t('Pending'),
      REJECTED: t('Rejected')
    },
    values: {
      AVAILABLE: t('Available'),
      DISABLED: t('Service Disabled'),
      INVALIDSPF: t('Invalid SPF Record'),
      MISSINGSPF: t('Missing SPF Record'),
      NOTAPPLICABLE: t('Not Applicable'),
      UNAVAILABLE: t('Unavailable')
    },
    translate(v) {
      return DomainStatus.values[v] || 'UNKNOWN'
    },
    translateApproval(v) {
      return DomainStatus.approvalValues[v] || 'UNKNOWN'
    }
  }
})

module.exports = DomainStatus
