const Ext = require('ext')
const t = require('translate')
const Format = require('admin/util/Format')
const ProductDirection = require('product/direction')
const GridSummary = require('admin/component/grid/GridSummary')
const DomainSummaryResultStore = require('admin/data/DomainSummaryResultStore')
const LazyGridView = require('admin/component/grid/LazyGridView')

const DomainSummaryGridPanel = Ext.define(null, {
  extend: Ext.grid.GridPanel,

  title: t('Summary'),
  autoScroll: true,
  enableColumnHide: false,
  enableColumnMove: false,
  loadMask: true,
  stripeRows: true,
  autoExpandColumn: 'name',

  constructor(cfg = {}) {
    this.product = cfg.product
    this.direction = ProductDirection.getDirection(cfg.product.productCode)

    // when no direction is set, set a default one and offer a switch
    if (!this.direction) {
      this.direction = ProductDirection.DIRECTION_INBOUND

      // Use a unique name so radios don't interact with other customers.
      const radioGroupName = 'domain-summary-direction-' + cfg.product.url

      const updateDirection = () => {
        // TODO Hack to reset URL to initial state (see search() in
        // DomainSummaryResultStore) to fix SCL-1109
        this.store.url = this.product.resources.DOMAINS_SEARCH.url
        this.store.direction = this.direction
        return this.store.search()
      }

      this.tbar = new Ext.Toolbar({
        cls: 'direction-toolbar',
        items: [
          new Ext.form.Label({
            text: t('Direction')
          }),
          new Ext.form.Radio({
            boxLabel: t('Outbound'),
            name: radioGroupName,
            inputValue: ProductDirection.DIRECTION_OUTBOUND,
            checked: ProductDirection.isOutbound(this.direction),
            handler: (checkbox, checked) => {
              if (checked) {
                this.direction = checkbox.inputValue
                return updateDirection()
              }
            }
          }),
          new Ext.form.Radio({
            boxLabel: t('Inbound'),
            name: radioGroupName,
            inputValue: ProductDirection.DIRECTION_INBOUND,
            checked: ProductDirection.isInbound(this.direction),
            handler: (checkbox, checked) => {
              if (checked) {
                this.direction = checkbox.inputValue
                return updateDirection()
              }
            }
          })
        ]
      })
    }

    this.summary = new GridSummary()
    this.plugins = [this.summary]

    this.store = new DomainSummaryResultStore({
      url: this.product.resources.DOMAINS_SEARCH.url,
      direction: this.direction
    })

    // Use lazy grid view to prevent unnecessary rendering of 10k rows.
    this.view = new LazyGridView()

    const numberRenderer = function (value) {
      if (value !== 0 && !value) {
        return ''
      }
      return Format.number(value)
    }

    this.cm = new Ext.grid.ColumnModel([
      {
        id: 'name',
        header: t('Domain'),
        width: 100,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'name',
        summaryType: 'count',
        summaryRenderer: this.totalDomains
      },
      {
        id: 'cleanCount',
        header: t('Clean'),
        width: 65,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'cleanCount',
        summaryType: 'sum',
        summaryRenderer: this.totalCleanCount,
        renderer: numberRenderer,
        align: 'right'
      },
      {
        id: 'cleanSize',
        header: t('Clean Traffic'),
        width: 95,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'cleanSize',
        renderer(bytes) {
          return Format.bytes(bytes)
        },
        summaryType: 'sum',
        summaryRenderer: this.totalCleanTraffic.createDelegate(this),
        align: 'right'
      },
      {
        id: 'spamCount',
        header: t('Spam'),
        width: 65,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'spamCount1',
        summaryType: 'sum',
        summaryRenderer: this.totalSpamCount,
        renderer: numberRenderer,
        align: 'right'
      },
      {
        id: 'spamSize',
        header: t('Spam Traffic'),
        width: 95,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'spamSize',
        renderer(bytes) {
          return Format.bytes(bytes)
        },
        summaryType: 'sum',
        summaryRenderer: this.totalSpamTraffic.createDelegate(this),
        align: 'right'
      },
      {
        id: 'spamPercentage',
        header: t('Spam (%)'),
        width: 80,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'spamCount2',
        summaryType: 'sum',
        summaryRenderer: this.totalSpamPercentage,
        align: 'right'
      },
      {
        id: 'threatCount',
        header: t('Threats'),
        width: 65,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'threatCount',
        summaryType: 'sum',
        summaryRenderer: this.threatCount,
        renderer: numberRenderer,
        align: 'right'
      },
      {
        id: 'threatSize',
        header: t('Threats Traffic'),
        width: 100,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'threatSize',
        renderer(bytes) {
          return Format.bytes(bytes)
        },
        summaryType: 'sum',
        summaryRenderer: this.totalSpamTraffic.createDelegate(this),
        align: 'right'
      },
      {
        id: 'allowPolicyCount',
        header: t('Whitelist'),
        width: 70,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'allowPolicyCount',
        summaryType: 'sum',
        summaryRenderer: this.totalAllowPolicyCount,
        renderer: numberRenderer,
        align: 'right'
      },
      {
        id: 'allowPolicySize',
        header: t('Whitelist Traffic'),
        width: 105,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'allowPolicySize',
        renderer(bytes) {
          return Format.bytes(bytes)
        },
        summaryType: 'sum',
        summaryRenderer: this.totalSpamTraffic.createDelegate(this),
        align: 'right'
      },
      {
        id: 'denyPolicyCount',
        header: t('Blacklist'),
        width: 70,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'denyPolicyCount',
        summaryType: 'sum',
        summaryRenderer: this.totalDenyPolicyCount,
        renderer: numberRenderer,
        align: 'right'
      },
      {
        id: 'denyPolicySize',
        header: t('Blacklist Traffic'),
        width: 100,
        sortable: true,
        menuDisabled: true,
        dataIndex: 'denyPolicySize',
        renderer(bytes) {
          return Format.bytes(bytes)
        },
        summaryType: 'sum',
        summaryRenderer: this.totalBlacklistTraffic.createDelegate(this),
        align: 'right'
      }
    ])

    this.callParent()
  },

  totalDomains(v, params) {
    params.attr = 'ext:qtip=""' + t('Total') + '"'
    return String.format('<b>{0}: {1}</b>', t('Total'), Format.number(v))
  },

  totalCleanCount(v, params) {
    params.attr = 'ext:qtip="' + t('Total no. of clean emails') + '"'
    return String.format('<b>{0}</b>', Format.number(v))
  },

  totalSpamCount(v, params) {
    params.attr = 'ext:qtip="' + t('Total no. of spam emails') + '"'
    return String.format('<b>{0}</b>', Format.number(v))
  },

  totalSpamTraffic(v, params) {
    params.attr = 'ext:qtip="' + t('Total spam') + '"'
    return String.format('<b>{0}</b>', Format.bytes(v))
  },

  totalBlacklistTraffic(v, params) {
    params.attr = 'ext:qtip="' + t('Total blacklist traffic') + '"'
    return String.format('<b>{0}</b>', Format.bytes(v))
  },

  totalSpamPercentage(v, params, data) {
    const d = data.data

    let total =
      d.cleanCount + d.spamCount1 + d.allowPolicyCount + d.denyPolicyCount + d.threatCount

    if (total > 0) {
      total = Math.round((d.spamCount1 / total) * 1000) / 10
    } else {
      total = 'N/A'
    }

    params.attr = 'ext:qtip="' + t('Total Spam Percentage') + '"'
    return String.format('<b>{0}</b>', total)
  },

  totalAllowPolicyCount(v, params) {
    params.attr = 'ext:qtip="' + t('Total no. of Allow Policies') + '"'
    return '<b>' + Format.number(v) + '</b>'
  },

  totalDenyPolicyCount(v, params) {
    params.attr = 'ext:qtip="' + t('Total no. of Deny Policies') + '"'
    return String.format('<b>{0}</b>', Format.number(v))
  },

  threatCount(v, params) {
    params.attr = 'ext:qtip="' + t('Total no. of Threats') + '"'
    return String.format('<b>{0}</b>', Format.number(v))
  },

  totalCleanTraffic(v, params) {
    params.attr = 'ext:qtip="' + t('Total clean Traffic') + '"'
    return String.format('<b>{0}</b>', Format.bytes(v))
  },

  afterRender() {
    this.callParent()
    return this.store.search()
  }
})

module.exports = DomainSummaryGridPanel
