import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class Field extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string
  }

  renderLabel() {
    return <span className='key'>{this.props.label}</span>
  }

  render() {
    return (
      <div className={classNames('field', this.props.className)}>
        {this.props.label && this.renderLabel()}
        <span className='children'>{this.props.children}</span>
      </div>
    )
  }
}

export default Field
