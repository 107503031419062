import React, { Component } from 'react'
import { disableSelection, loading, membership } from 'archiving/membership/subs'

import { Form } from 'react-bootstrap'
import MembershipList from 'membership/views/List'
import MembershipToolbar from 'archiving/membership/views/Toolbar'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setTypes } from 'archiving/membership/actions'

class MembershipForm extends Component {
  static propTypes = {
    companyKey: PropTypes.string.isRequired,
    height: PropTypes.number
  }

  onMembersChange = () => this.props.setTypes(this.props.membership.getTypes())

  render() {
    return (
      <Form horizontal className='membership'>
        <MembershipToolbar companyKey={this.props.companyKey} />
        <MembershipList
          collapsible
          height={this.props.height}
          membership={this.props.membership}
          disabled={this.props.disableSelection}
          loading={this.props.loading}
          onChange={this.onMembersChange}
        />
      </Form>
    )
  }
}

const mapDbToProps = (db, { companyKey }) => ({
  loading: loading(db, companyKey),
  membership: membership(db, companyKey),
  disableSelection: disableSelection(db, companyKey)
})

const mapActionsToProps = (dispatch, { companyKey }) => ({
  setTypes: (types) => dispatch(setTypes(companyKey, types))
})

export default connect(mapDbToProps, mapActionsToProps)(MembershipForm)
