const Ext = require('ext')
const t = require('translate')
const Resource = require('admin/core/Resource').default
const TemplateManagementPanel = require('smartrules/TemplateManagementPanel')

const TemplateManagementResource = Ext.define(null, {
  extend: Resource,
  singleton: true,

  id: 'SMX.TemplateManagement',
  translations: {
    title: t('Template Management'),
    new: t('Template Management'),
    open: t('Template Management'),
    list: t('Template Management')
  },
  name: t('Template Management'),
  resourceName: 'template-management',
  submitOnEnter: false,

  layout: {
    newResource: {
      width: 600,
      height: 400
    }
  },

  newPanel: TemplateManagementPanel
})

module.exports = TemplateManagementResource
